import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFundsByFundManager } from '../store/funds';

function filterByMinVintage(minVintage) {
  return function (fund) {
    if (minVintage === null) return true;
    return fund.vintage >= minVintage;
  };
}

function useFundManagerFunds({ fundManagerId, minVintage = null }) {
  const fundsByFundManager = useSelector(state => getFundsByFundManager(state));
  const [funds, setFunds] = useState(null);

  useEffect(() => {
    if (!fundManagerId) return setFunds([]);
    if (!fundsByFundManager) return setFunds([]);

    const managerFunds = fundsByFundManager[fundManagerId] || [];

    setFunds(managerFunds.filter(filterByMinVintage));
  }, [fundManagerId, fundsByFundManager, minVintage]);

  return funds;
}

export default useFundManagerFunds;
