const DISPLAY = {
  grid: 'grid',
  timeSeries: 'time-series',
  list: 'list',
};

const TYPES = {
  JCURVE: 'jCurve',
  NAV: 'Net Asset Value',
};

const QUARTILE_OPTIONS = {
  TOP: 'Top Quartile',
  ALL: 'Mean Cashflow',
  BOTTOM: 'Bottom Quartile',
};

const QUARTILE_NAMES = {
  TOP: 'Top Quartile',
  ALL: 'Mean',
  BOTTOM: 'Bottom Quartile',
};

const TIME_RANGES = {
  3: { label: '1Q', value: 3 },
  6: { label: '2Q', value: 6 },
  12: { label: '1Y', value: 12 },
  36: { label: '3Y', value: 36 },
  60: { label: '5Y', value: 60 },
  120: { label: '10Y', value: 120 },
};

const CURVE_HISTORY_SORT_TYPES = {
  [TYPES.JCURVE]: {
    none: 'Default',
    asc: 'Quickest payback to longest',
    desc: 'Longest payback to shortest',
  },
  [TYPES.NAV]: {
    none: 'Default',
    asc: 'Quickest asset ramp-up to longest',
    desc: 'Longest asset ramp-up to shortest',
  },
};

export { DISPLAY, TIME_RANGES, TYPES, QUARTILE_OPTIONS, QUARTILE_NAMES, CURVE_HISTORY_SORT_TYPES };
