import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lg } from 'src/config/layout';
import { modal } from 'src/config/colors';
import Header from './Header';
import Body from './Body';
import Actions from './Actions';
import Action from './Action';

const Layout = styled.div`
  color: ${modal.body.TEXT_COLOR};
  background-color: ${modal.body.BACKGROUND_COLOR};
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.68);

  font-size: 0.857142857em;
  max-width: 240px;
  --modal-padding: 15px;
  --modal-v-spacing: 10px;

  @media (min-width: ${lg.BREAKPOINT}px) and (min-height: ${lg.V_BREAKPOINT}px) {
    font-size: 1em;
    max-width: 300px;
    --modal-padding: 25px;
    --modal-v-spacing: 20px;
  }
`;

function Modal({ className, children }) {
  return <Layout className={className}>{children}</Layout>;
}

Modal.defaultProps = {};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Actions = Actions;
Modal.Action = Action;

export default Modal;
