import { select, extent, scaleLinear, scaleQuantile, easeCubicInOut, axisLeft, axisBottom, quantile } from 'd3';
import { currency, abbreviations, upperCase, round, percent } from 'src/formatters';
import { strings } from 'src/strings';
import { lookup } from 'src/lookup';

const yFormat = currency(abbreviations.billion)({
  minimumFractionDigits: 2,
});

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function getPointColor(d) {
  return d.color ? `${d.color}de` : 'rgba(111, 110, 110, 0.47)';
}

function getPointRadius(d) {
  return d.color ? 11 : 5;
}

function getQuantileText(scale, metricKey) {
  return function (d) {
    const rank = scale(d[metricKey]) / 100;

    if (rank < 0.25) return 4;
    if (rank < 0.5) return 3;
    if (rank < 0.75) return 2;
    return 1;
  };
}

function showFundPerformance(fund) {
  const thresholdYear = new Date().getFullYear() - 3 + 1;
  return fund.vintage < thresholdYear;
}

function create({ id, margin }) {
  const prefixedId = `#${id}`;

  const root = select(prefixedId)
    .append('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  root.append('g').attr('class', 'x-axis').style('shape-rendering', 'crispEdges');

  root.append('g').attr('class', 'y-axis').style('shape-rendering', 'crispEdges');

  // add X axis label
  root
    .append('text')
    .attr('class', 'x-axis-label')
    .style('font-family', 'Roboto')
    .style('fill', '#808080')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px');

  // add Y axis label
  root
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('dy', '1em')
    .attr('class', 'y-axis-label')
    .style('font-family', 'Roboto')
    .style('fill', '#808080')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .text(upperCase(strings.labels.fundSize));

  // quartile labels
  root
    .append('text')
    .attr('class', 'bottom-quartile-label')
    .style('font-family', 'Roboto')
    .style('fill', 'rgb(0, 171, 212)')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .text('25%');

  root
    .append('text')
    .attr('class', 'median-label')
    .style('fill', 'rgb(0, 171, 212)')
    .style('font-family', 'Roboto')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .text('MED.');

  root
    .append('text')
    .attr('class', 'top-quartile-label')
    .style('font-family', 'Roboto')
    .style('fill', 'rgb(0, 171, 212)')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .text('75%');

  root
    .append('text')
    .attr('class', 'no-benchmark-warning')
    .style('font-family', 'Roboto')
    .style('fill', '#0d8bab')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .text('No benchmark provided as fund is too young.');

  root.append('g').attr('class', 'peer-funds').attr('style', 'z-index: 1');
  root.append('g').attr('class', 'subject-fund').attr('style', 'z-index: 2');

  return root;
}

function draw({ id, fund, data, metric, size, margin, mouseEventRefs, showOutliers }) {
  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const metricKey = metric.key;
  const useableDataSet = data.filter(d => d[metricKey] != null).sort((a, b) => a[metricKey] - b[metricKey]);

  const visibleDataSet = useableDataSet.filter(d => {
    if (showOutliers) return true;
    return !d[`${metric.key}Outlier`];
  });

  let root = select(`#${id} .root`);
  if (!root.node()) {
    root = create({ id, margin });
  }

  // set the dimensions and margins of the graph
  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;

  const topQuartileValue = quantile(useableDataSet, 0.75, d => d[metric.key]);
  const bottomQuartileValue = quantile(useableDataSet, 0.25, d => d[metric.key]);
  const medianValue = quantile(useableDataSet, 0.5, d => d[metric.key]);

  // create the quantile rank
  const quantileRank = scaleQuantile()
    .domain(useableDataSet.map(d => d[metricKey]).sort())
    .range(new Array(101).fill(0).map((_, i) => i));

  // create the X axis
  const xExtent = extent(visibleDataSet, d => d[metricKey]);

  const xScale = scaleLinear()
    .domain([xExtent[0], xExtent[1]])
    .range([10, svgWidth - 10]);

  // create the Y axis
  const yExtent = extent(visibleDataSet, d => d.size);
  const yScale = scaleLinear()
    .domain(yExtent)
    .range([svgHeight - 10, 10]);

  // add the X axis
  const xAxis = axisBottom(xScale)
    .tickValues([bottomQuartileValue, medianValue, topQuartileValue].filter(Number))
    //.ticks(0)
    .tickSize(-(svgHeight - 4))
    .tickFormat(metric.key === 'irr' ? percent(1) : round(2));

  const yAxis = axisLeft(yScale).ticks(4).tickSize(-svgWidth).tickFormat(yFormat);

  const t = root.transition().duration(500).ease(easeCubicInOut);

  // position the x-axis
  root
    .select('.x-axis')
    .attr('transform', `translate(0,${svgHeight})`)
    .attr('opacity', showFundPerformance(fund) ? 1 : 0)
    .transition(t)
    .call(xAxis)
    .call(g => g.selectAll('.domain').attr('opacity', 0))
    .call(g => g.selectAll('line').style('color', 'rgb(0, 171, 212)').style('stroke-dasharray', '5 5'))
    .call(g => g.selectAll('text').style('color', '#808080'));

  // position the y-axis
  root
    .select('.y-axis')
    .transition(t)
    .call(yAxis)
    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', '#808080'))
    .call(g => g.selectAll('.domain').attr('opacity', 0));

  // position the x-axis label
  root
    .select('.x-axis-label')
    .attr('y', svgHeight + margin.bottom - 20)
    .attr('x', svgWidth / 2)
    .transition(t)
    .text(metric === lookup.financialMetric.tvpi ? 'NET TVPI (X)' : 'NET IRR (%)');

  // position the y-axis label
  root
    .select('.y-axis-label')
    .attr('y', 0 - margin.left)
    .attr('x', 0 - svgHeight / 2)
    .transition(t);

  // position the quartile labels
  root
    .select('.bottom-quartile-label')
    .transition(t)
    .attr('x', xScale(bottomQuartileValue) ?? 0)
    .attr('y', 0)
    .attr('opacity', bottomQuartileValue && visibleDataSet.length > 1 && showFundPerformance(fund) ? 1 : 0);

  root
    .select('.median-label')
    .transition(t)
    .attr('x', xScale(medianValue) ?? 0)
    .attr('y', 0)
    .attr('opacity', medianValue && visibleDataSet.length > 1 && showFundPerformance(fund) ? 1 : 0);

  root
    .select('.top-quartile-label')
    .transition(t)
    .attr('x', xScale(topQuartileValue) ?? 0)
    .attr('y', 0)
    .attr('opacity', topQuartileValue && visibleDataSet.length > 1 && showFundPerformance(fund) ? 1 : 0);

  root
    .select('.no-benchmark-warning')
    .transition(t)
    .attr('x', svgWidth - 100)
    .attr('y', 0)
    .attr('opacity', !showFundPerformance(fund) ? 1 : 0);

  const points = root
    .select('.peer-funds')
    .selectAll('.point')
    .data(
      visibleDataSet.filter(d => !d.color),
      d => d.fundId
    );

  // remove old points
  points.exit().transition(t).attr('opacity', 0).attr('cy', 0).remove();

  // update existing points
  points
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('r', d => getPointRadius(d))
    .attr('cx', d => xScale(d[metricKey]))
    .attr('cy', d => yScale(d.size) ?? svgHeight)
    .attr('fill', d => getPointColor(d));

  // add new points
  points
    .enter()
    .append('circle')
    .attr('class', 'point')
    .attr('cx', d => xScale(d[metricKey]))
    .attr('cy', 0)
    .attr('r', d => getPointRadius(d))
    .attr('fill', d => getPointColor(d))
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('fill', d => getPointColor(d))
    .attr('cy', d => yScale(d.size) ?? svgHeight);

  const fundPoint = root
    .select('.subject-fund')
    .selectAll('.fund-point')
    .data(
      visibleDataSet.filter(d => d.color),
      d => d.fundId
    );

  // remove old points
  fundPoint.exit().transition(t).attr('opacity', 0).attr('cy', 0).remove();

  // update existing points
  fundPoint
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('r', d => getPointRadius(d))
    .attr('cx', d => xScale(d[metricKey]))
    .attr('cy', d => yScale(d.size) ?? svgHeight)
    .attr('opacity', 1)
    .attr('fill', d => getPointColor(d));

  // add new points
  fundPoint
    .enter()
    .append('circle')
    .attr('class', 'fund-point')
    .attr('cx', d => xScale(d[metricKey]))
    .attr('cy', 0)
    .attr('r', d => getPointRadius(d))
    .attr('opacity', 0)
    .attr('fill', d => getPointColor(d))
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('opacity', 1)
    .attr('fill', d => getPointColor(d))
    .attr('cy', d => yScale(d.size) ?? svgHeight);

  const fundQuartileRankingLabel = root
    .select('.subject-fund')
    .selectAll('.fund-quartile-ranking-label')
    .data(
      visibleDataSet.filter(d => d.color),
      d => d.fundId
    );

  // remove old points
  fundQuartileRankingLabel.exit().transition(t).attr('opacity', 0).attr('dy', 0).remove();

  // update existing points
  fundQuartileRankingLabel
    .transition(t)
    .attr('dx', d => xScale(d[metricKey]))
    .attr('dy', d => yScale(d.size) ?? svgHeight)
    .attr('opacity', d => (showFundPerformance(d) ? 1 : 0))
    .attr('fill', '#ffffff')
    .attr('font-size', '12px')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('pointer-events', 'none')
    .text(getQuantileText(quantileRank, metricKey));

  // add new points
  fundQuartileRankingLabel
    .enter()
    .append('text')
    .attr('class', 'fund-quartile-ranking-label')
    .attr('dx', d => xScale(d[metricKey]))
    .attr('dy', 0)
    .attr('opacity', 0)
    .attr('fill', '#ffffff')
    .attr('font-size', '12px')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('pointer-events', 'none')
    .text(getQuantileText(quantileRank, metricKey))
    .transition(t)
    .attr('opacity', d => (showFundPerformance(d) ? 1 : 0))
    .attr('dy', d => yScale(d.size) ?? svgHeight);
}

export default draw;
