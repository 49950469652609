import React from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { AppLayout } from 'src/components';
import { Layout, Header, Title, CloseButton, IconAdd, Content } from './Styled';
import Form from './Form';

function FundAddEditDialog({ open, onClose, fundId, formPresets, viewFundAfterSave }) {
  const { setExpandedId } = useContext(AppLayout.Context);

  function handleClose() {
    setExpandedId(null);
    onClose();
  }

  if (!open) return null;

  return (
    <Layout open={open} fullWidth={true}>
      <Header>
        <Title>
          {fundId ? (
            <span>Edit Fund</span>
          ) : (
            <span>
              <IconAdd /> Add a Fund
            </span>
          )}
        </Title>
        <CloseButton aria-label="close" onClick={handleClose} />
      </Header>

      <Content>
        {open && (
          <Form
            onCloseClick={handleClose}
            fundId={fundId}
            formPresets={formPresets}
            viewFundAfterSave={viewFundAfterSave}
          />
        )}
      </Content>
    </Layout>
  );
}

FundAddEditDialog.defaultProps = {
  open: false,
  onClose: () => {},
  viewFundAfterSave: true,
};

FundAddEditDialog.propTypes = {
  open: PropTypes.bool,
  fundId: PropTypes.string,
  onClose: PropTypes.func,
  viewFundAfterSave: PropTypes.bool,
};

export default FundAddEditDialog;
