const valueChange = current => prev => {
  return (current - prev) / Math.abs(prev);
};

function distPct({ dpi, calledPct }) {
  return dpi * calledPct;
}

function navPct({ rvpi, calledPct }) {
  return rvpi * calledPct;
}

function netCashflow({ dpi, calledPct }) {
  return dpi * calledPct - calledPct;
}

function netAssetValue({ rvpi, calledPct }) {
  return rvpi * calledPct;
}

function spotDistribution({ dpi, calledPct }, i, group) {
  const prevItem = group[i - 1];
  if (!prevItem) return distPct({ dpi, calledPct });
  return distPct({ dpi, calledPct }) - distPct(prevItem);
}

function spotContribution({ calledPct }, i, group) {
  const prevItem = group[i - 1];
  if (!prevItem) return calledPct;
  return calledPct - prevItem.calledPct;
}

const calculators = {
  valueChange,
  distPct,
  navPct,
  netCashflow,
  ncf: netCashflow,
  netAssetValue,
  nav: netAssetValue,
  spotDistribution,
  spotContribution,
};

export { valueChange };
export { distPct };
export { navPct };
export { netCashflow };
export const ncf = netCashflow;
export { netAssetValue };
export const nav = netAssetValue;
export { spotDistribution };
export { spotContribution };

export default calculators;
