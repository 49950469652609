import * as d3 from 'd3';
import { formatBigNumber } from 'src/formatters/currency';

function getYAxis({ data, height, width, range, formatter, normalizeYAxisData }) {
  const tickFormat = typeof formatter === 'function' ? formatter : formatBigNumber();

  const yDomain = data
    .map(d => [
      d.subjectCurve,
      d.forecastCurve,
      d.subjectBarRise,
      d.subjectBarFall,
      d.forecastBarRise,
      d.forecastBarFall,
      d.navMarker,
      d.errorHigh,
      d.errorLow,
      0,
      ...(normalizeYAxisData || []),
    ])
    .flat();

  const yScale = d3.scaleLinear().domain(d3.extent(yDomain)).rangeRound(range);

  const yAxis = d3
    .axisRight(yScale)
    .tickSize(width)
    .tickFormat(tickFormat)
    .ticks(height > 200 ? 8 : 4);

  return { yScale, yAxis };
}

export default getYAxis;
