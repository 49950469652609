import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFundsBySubStrategy } from '../store/funds';
import useBookmarkedFundIds from './useBookmarkedFundIds';

function useSubStrategyFunds({ subStrategyId }) {
  const fundsBySubStrategy = useSelector(state => getFundsBySubStrategy(state));
  const [funds, setFunds] = useState(null);
  const bookmarkedFundIds = useBookmarkedFundIds();

  useEffect(() => {
    if (subStrategyId && fundsBySubStrategy && fundsBySubStrategy[subStrategyId] && bookmarkedFundIds) {
      setFunds(
        fundsBySubStrategy[subStrategyId].map(fund => ({
          ...fund,
          bookmarked: Boolean(bookmarkedFundIds[fund.fundId]),
        }))
      );
    }
  }, [bookmarkedFundIds, fundsBySubStrategy, subStrategyId]);

  return funds;
}

export default useSubStrategyFunds;
