import { useMemo } from 'react';
import { useList as useListFundPeerGroupFilters } from 'src/services/filter-api/userFundPeerGroupFilters/queries';

function useFundPeerGroupFiltersMap() {
  const { data } = useListFundPeerGroupFilters();

  return useMemo(
    () =>
      data?.reduce(
        (acc, filter) =>
          Object.assign(acc, {
            [filter.fundId]: filter,
          }),
        {}
      ) ?? {},
    [data]
  );
}

export default useFundPeerGroupFiltersMap;
