import React, { useState, useCallback } from 'react';
import { VimeoThumbnail, TutorialDialog } from 'src/components';
import { Layout, Grid, VideoPreview, VideoInformation, PlayIcon, Title } from './styled';
import tutorials from 'src/config/tutorials';

function Tutorials() {
  const [selectedTutorial, setSelectedTutorial] = useState();

  const handleTutorialClick = useCallback(
    evt => setSelectedTutorial(tutorials[evt.currentTarget.dataset.video]),
    [setSelectedTutorial]
  );

  const handleTutorialClose = useCallback(() => {
    setSelectedTutorial(null);
  }, [setSelectedTutorial]);

  return (
    <Layout>
      <Grid>
        {tutorials.map(({ title, videoId }, i) => (
          <VideoPreview key={i} data-video={i} onClick={handleTutorialClick}>
            <VimeoThumbnail videoId={videoId} />
            <VideoInformation>
              <PlayIcon />
              <Title>{title}</Title>
            </VideoInformation>
          </VideoPreview>
        ))}
      </Grid>
      <TutorialDialog open={Boolean(selectedTutorial)} onClose={handleTutorialClose} {...selectedTutorial} />
    </Layout>
  );
}

export default Tutorials;
