import { useState, useEffect } from 'react';
import { getStrategies } from 'src/lookup/strategies';

function useStrategies() {
  const [strategies, setStrategies] = useState(null);

  useEffect(() => {
    async function load() {
      setStrategies(await getStrategies());
    }
    load();
  }, []);

  return strategies;
}

export default useStrategies;
