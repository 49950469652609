import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function ConfirmDialog({ message, open, onClose }) {
  return (
    <div>
      <Dialog open={open} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description">
        <DialogTitle id="confirm-dialog-title">FundFilter</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose(false)} color="primary">
            cancel
          </Button>
          <Button variant="contained" onClick={() => onClose(true)} color="primary" autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.defaultProps = {
  message: '',
  open: false,
  onClose: () => {},
};

ConfirmDialog.propTypes = {
  message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ConfirmDialog;
