import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconCheckboxUnchecked(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M18,83 L83,83 L83,18 L18,18 L18,83 Z M23,23 L78,23 L78,78 L23,78 L23,23 Z" />
    </Svg>
  );
}
