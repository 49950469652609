import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconScatterPlot(props) {
  return (
    <Svg viewBox="0 0 110 110" {...props}>
      <g>
        <path d="M88,90c0,0.553-0.447,1-1,1H12c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1h75c0.553,0,1,0.447,1,1V90z" />
        <path d="M15,90c0,0.553-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1V15c0-0.552,0.447-1,1-1h2c0.553,0,1,0.448,1,1V90z" />
      </g>
      <circle cx="71.833" cy="29.333" r="3" />
      <circle cx="28.583" cy="66.084" r="5.417" />
      <circle cx="65.584" cy="71.417" r="6.75" />
      <circle cx="43.709" cy="46.875" r="10.375" />
    </Svg>
  );
}
