import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import { indexName } from 'src/formatters';
import draw from './draw';

const Container = styled.div`
  height: ${props => props.height || '100%'};

  .x-axis .tick text {
    transform: translateX(-10px) rotate(-45deg);
  }
`;

const Empty = styled.div`
  height: ${props => props.height || '100%'};
  display: grid;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;

  & > div {
    max-width: 300px;
  }
`;

const FundsRelativePerformanceHint = styled.div`
  font-size: 12px;
  text-align: center;
  padding-top: 16px;
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  display: block;

  .x-axis .tick.hidden {
    /* display: none; */
    color: pink;
    opacity: 0.5;
  }
`;

function FundManagerFundPerformance({
  funds,
  fundPeerGroupFilters,
  peers,
  calcs,
  index,
  metric,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  ...rest
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    draw({
      id: id.current,
      funds: funds ?? [],
      fundPeerGroupFilters: fundPeerGroupFilters ?? {},
      metric,
      peers,
      calcs,
      index,
      size: { width, height },
      margin: { top: 0, right: 20, bottom: 20, left: 40 },
      mouseEventRefs,
    });
  }, [funds, fundPeerGroupFilters, metric, peers, calcs, index, width, height, mouseEventRefs]);

  if (funds.length < 1)
    return (
      <Empty>
        <div>
          <p>There is currently no fund performance data for this fund&nbsp;manager</p>
          <p>You can add a fund by clicking the yellow text&nbsp;below</p>
        </div>
      </Empty>
    );

  return (
    <Container ref={targetRef} {...rest} expanded={null}>
      {metric.isPme && (
        <FundsRelativePerformanceHint>
          Fund&apos;s Relative Performance {index && `to the ${indexName(index)}`}
        </FundsRelativePerformanceHint>
      )}
      <Svg id={id.current} />
    </Container>
  );
}

FundManagerFundPerformance.defaultProps = {
  expanded: false,
  width: 0,
  height: 0,
  targetRef: { current: null },
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

FundManagerFundPerformance.propTypes = {
  funds: PropTypes.array.isRequired,
  fundPeerGroupFilters: PropTypes.object,
  peers: PropTypes.object.isRequired,
  calcs: PropTypes.object,
  index: PropTypes.string,
  metric: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default withResizeDetector(FundManagerFundPerformance);
