import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, Truncator, IconInfoOutlined, FundPreview } from 'src/components';

const TooltipLayout = styled.div`
  padding: 0.5rem 1rem;
  font-size: 12px;
`;

const AlertMessage = styled.p`
  position: relative;

  svg {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0rem;
    left: -1rem;
  }
`;

const FundName = styled.span`
  color: #00abd4;
  font-weight: bold;
`;

function ToolipContent({ fund, alert }) {
  return (
    <TooltipLayout>
      <>
        {alert && (
          <AlertMessage>
            <IconInfoOutlined />
            <FundName>{fund.name} </FundName>
            {alert}
          </AlertMessage>
        )}
        <FundPreview fund={fund} />
      </>
    </TooltipLayout>
  );
}

function FundNameCell({ value: fund }) {
  if (!fund) return;

  return (
    <div>
      <Tooltip interactive placement="right" title={<ToolipContent fund={fund} alert={fund.disabledReason} />}>
        <span>
          <Truncator value={fund.name} keepLast={5} />
        </span>
      </Tooltip>
    </div>
  );
}

FundNameCell.defaultProps = {};

FundNameCell.propTypes = {
  value: PropTypes.object,
};

export default FundNameCell;
