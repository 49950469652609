import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.div`
  position: relative;
  height: 100%;
  background-color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function NewsArticleNotFound({ className }) {
  return <Layout className={className}>The selected news article could not be found</Layout>;
}

NewsArticleNotFound.defaultProps = {};

NewsArticleNotFound.propTypes = {
  children: PropTypes.any,
};

export default NewsArticleNotFound;
