import React from 'react';
import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFundManagerBookmarks, useLayoutActions } from 'src/hooks';
import { NewsArticles, PlaceholderTable } from 'src/components';
import { useGetMany as useGetFundManagerNews } from 'src/services/filter-api/fundManagerNews/queries';

function DashboardNews({ showPlot }) {
  const fundManagerIds = useFundManagerBookmarks();
  const newsQueries = useGetFundManagerNews(fundManagerIds);

  const { data: articles, isLoading } = useMemo(() => {
    return {
      data: newsQueries.flatMap(query => query.data ?? []).sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1)),
      isLoading: newsQueries.some(query => query.isLoading),
    };
  }, [newsQueries]);

  const { expandPanel } = useLayoutActions();

  const handleNewsArticleClick = useCallback(
    function () {
      expandPanel();
    },
    [expandPanel]
  );

  if (isLoading) return <PlaceholderTable />;

  return (
    <NewsArticles
      title={''}
      showSentimentGraph={showPlot}
      sentimentGraphLayout="full"
      articles={articles}
      onSelectedArticle={handleNewsArticleClick}
    />
  );
}

DashboardNews.defaultProps = {
  showPlot: false,
};

DashboardNews.propTypes = {
  showPlot: PropTypes.bool.isRequired,
};

export default DashboardNews;
