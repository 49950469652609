import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function IconEmvChip(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M21,3 C22.6568542,3 24,4.34314575 24,6 L24,18 C24,19.6568542 22.6568542,21 21,21 L3,21 C1.34314575,21 0,19.6568542 0,18 L0,6 C0,4.34314575 1.34314575,3 3,3 L21,3 Z M23,15 L1,15 L1,18 C1,19.0543618 1.81587779,19.9181651 2.85073766,19.9945143 L3,20 L9.33197021,20 L11.999,17.299 L12,15.0023193 L13,15.0023193 L12.998,17.29 L13.2071068,17.5 L13.204,17.502 L15.6558838,20 L21,20 C22.0543618,20 22.9181651,19.1841222 22.9945143,18.1492623 L23,18 L23,15 Z M12.499,18.207 L10.707,20 L14.292,20 L12.499,18.207 Z M7,9 L1,9 L1,14 L7,14 L7,9 Z M23,9 L17,9 L17,14 L23,14 L23,9 Z M21,4 L15.7633057,4 L12.999,6.708 L13,9 L8,9 L8,14 L16,14 L16,8 L23,8 L23,6 C23,4.9456382 22.1841222,4.08183488 21.1492623,4.00548574 L21,4 Z M9.33422852,4 L3,4 C1.9456382,4 1.08183488,4.81587779 1.00548574,5.85073766 L1,6 L1,8 L11.999,8 L11.999,6.7 L9.33422852,4 Z M14.291,4 L10.707,4 L12.499,5.792 L14.291,4 Z" />
    </Svg>
  );
}

IconEmvChip.defaultProps = {
  width: '0.75em',
  height: '0.75em',
};

export default IconEmvChip;
