import { createReducer, createAction } from '@reduxjs/toolkit';
import { groupBy } from 'src/lib/lodash';
import { filterApi } from '../services';
import { auth } from 'src/services/filter-api/authentication';
import { parseISO, subtractMonths, monthDiff, isEqual } from 'src/utils/date';

const fetchFundFinancialHistoryPending = createAction('FETCH_FUND_FINANCIAL_HISTORY_PENDING');
const fetchFundFinancialHistoryError = createAction('FETCH_FUND_FINANCIAL_HISTORY_ERROR');
const fetchFundFinancialHistorySuccess = createAction('FETCH_FUND_FINANCIAL_HISTORY_SUCCESS');
const fetchFundsFinancialHistoryPending = createAction('FETCH_FUNDS_FINANCIAL_HISTORY_PENDING');
const fetchFundsFinancialHistoryError = createAction('FETCH_FUNDS_FINANCIAL_HISTORY_ERROR');
const fetchFundsFinancialHistorySuccess = createAction('FETCH_FUNDS_FINANCIAL_HISTORY_SUCCESS');

const setUserFundFinancialHistoryPending = createAction('SET_USER_FUND_FINANCIAL_HISTORY_PENDING');
const setUserFundFinancialHistoryError = createAction('SET_USER_FUND_FINANCIAL_HISTORY_ERROR');
const setUserFundFinancialHistorySuccess = createAction('SET_USER_FUND_FINANCIAL_HISTORY_SUCCESS');
const deleteUserFundFinancialHistoryPending = createAction('DELETE_USER_FUND_FINANCIAL_HISTORY_PENDING');
const deleteUserFundFinancialHistoryError = createAction('DELETE_USER_FUND_FINANCIAL_HISTORY_ERROR');
const deleteUserFundFinancialHistorySuccess = createAction('DELETE_USER_FUND_FINANCIAL_HISTORY_SUCCESS');

function isUserFund(fundId) {
  if (typeof fundId === 'string') return fundId.substr(0, 2) === 'u_';
  throw new Error(`Expected fundId to be a string, but got ${typeof fundId}`);
}

export function fetchFundFinancialHistory({ fundId }) {
  if (!fundId) return;
  return async function (dispatch, getState) {
    const { byId } = getState().fund.financialHistory;
    const user = auth.currentUser;
    if (!user) return;
    if (byId[fundId]) return;

    dispatch(fetchFundFinancialHistoryPending({ fundId }));

    try {
      let fundFinancialHistory;
      if (!isUserFund(fundId)) {
        fundFinancialHistory = await filterApi.getFundFinancialHistory({
          fundId,
        });
      } else {
        fundFinancialHistory = await filterApi.getUserFundFinancialHistory({
          userId: user.uid,
          fundId,
        });
      }

      dispatch(
        fetchFundFinancialHistorySuccess({
          fundId,
          fundFinancialHistory,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        fetchFundFinancialHistoryError({
          fundId,
          error: error.message,
        })
      );
    }
  };
}

function fetchFundsFinancialHistoryOfType({ fundIds: allIds = [], fetcher }) {
  return async function (dispatch, getState) {
    const user = auth.currentUser;
    const { byId } = getState().fund.financialHistory;

    const fundIds = allIds.filter(fundId => !byId[fundId]);

    if (!user) return;
    if (fundIds < 1) return;

    dispatch(fetchFundsFinancialHistoryPending({ fundIds }));

    try {
      const response = await fetcher({
        fundIds,
        userId: user.uid,
      });
      const responseIds = response.map(fund => fund.fundId);
      const notFoundIds = [...fundIds].filter(x => !responseIds.includes(x));

      dispatch(
        fetchFundsFinancialHistorySuccess({
          fundsFinancialHistory: response,
        })
      );

      dispatch(
        fetchFundsFinancialHistoryError({
          fundIds: notFoundIds,
          error: 'Not found',
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        fetchFundsFinancialHistoryError({
          fundIds,
          error: error.message,
        })
      );
    }
  };
}

export function fetchFundsFinancialHistory({ fundIds }) {
  return async function (dispatch, getState) {
    if (!fundIds) return;

    const systemFundIds = (fundIds || []).filter(x => !isUserFund(x));
    const userFundIds = (fundIds || []).filter(isUserFund);

    fetchFundsFinancialHistoryOfType({
      fundIds: systemFundIds,
      fetcher: filterApi.getFundsFinancialHistory,
    })(dispatch, getState);

    fetchFundsFinancialHistoryOfType({
      fundIds: userFundIds,
      fetcher: filterApi.getUserFundsFinancialHistory,
    })(dispatch, getState);
  };
}

export function setUserFundFinancialHistory({ fundId, fundFinancialHistory }) {
  return async function (dispatch) {
    const user = auth.currentUser;
    if (!user) return;
    try {
      dispatch(setUserFundFinancialHistoryPending({ fundId }));
      const res = await filterApi.setUserFundFinancialHistory({
        userId: user.uid,
        fundId,
        fundFinancialHistory,
      });
      dispatch(
        setUserFundFinancialHistorySuccess({
          fundId,
          fundFinancialHistory: res,
        })
      );
    } catch (error) {
      dispatch(
        setUserFundFinancialHistoryError({
          error: error.message,
          fundId,
        })
      );
    }
  };
}

export function deleteUserFundFinancialHistory({ fundId }) {
  return async function (dispatch) {
    const user = auth.currentUser;
    if (!user) return;
    try {
      dispatch(deleteUserFundFinancialHistoryPending({ fundId }));
      await filterApi.deleteUserFundFinancialHistory({
        userId: user.uid,
        fundId,
      });
      dispatch(deleteUserFundFinancialHistorySuccess({ fundId }));
    } catch (error) {
      dispatch(
        deleteUserFundFinancialHistoryError({
          error: error.message,
          fundId,
        })
      );
    }
  };
}

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [fetchFundFinancialHistoryPending]: (state, action) => {
    const { fundId } = action.payload;
    const history = state.byId[fundId] || {};
    history.fetching = true;
    history.fundId = fundId;
    state.byId[fundId] = history;
  },

  [fetchFundFinancialHistoryError]: (state, action) => {
    const { fundId, error } = action.payload;
    const history = state.byId[fundId] || {};
    history.error = error;
    history.fetching = false;
    state.byId[fundId] = history;
    console.error(error);
  },

  [fetchFundFinancialHistorySuccess]: (state, action) => {
    const { fundId, fundFinancialHistory } = action.payload;

    /**
     * fill in missing rows by interpolating between
     * existing values
     */
    function ensureCompleteHistory(history) {
      if (history.length < 1) return;

      const lastDate = history[0].date;
      const firstDate = history[history.length - 1].date;
      if (isEqual(parseISO(firstDate))(parseISO(lastDate))) return history;

      const newItems = [];
      [...history].forEach((curr, index) => {
        const prev = history[index + 1];
        if (!prev) return;

        const prevDate = parseISO(prev.date);
        const currDate = parseISO(curr.date);
        const diff = monthDiff(prevDate)(currDate);

        if (diff <= 3) return;

        // more than 1 QTR apart
        const missingCount = (diff - 3) / 3;
        const tvpiSpread = curr.tvpi - prev.tvpi;
        const tvpiDelta = tvpiSpread / (missingCount + 1);
        const irrSpread = curr.irr - prev.irr;
        const irrDelta = irrSpread / (missingCount + 1);
        for (let i = 1; i <= missingCount; i++) {
          newItems.push({
            tvpi: curr.tvpi - i * tvpiDelta,
            irr: curr.irr - i * irrDelta,
            date: subtractMonths(i * 3)(currDate).toISOString(),
            isGenerated: true,
          });
        }
      });

      return [...history, ...newItems].sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });
    }

    state.byId[fundId] = {
      fetching: false,
      fetched: true,
      error: null,
      fundId,
      items: ensureCompleteHistory(fundFinancialHistory),
    };
  },

  [fetchFundsFinancialHistoryError]: (state, action) => {
    const { fundIds, error } = action.payload;
    fundIds.forEach(fundId => {
      const history = state.byId[fundId] || {};
      history.fetching = false;
      history.error = error;
      history.fundId = fundId;
      state.byId[fundId] = history;
    });
  },

  [fetchFundsFinancialHistoryPending]: (state, action) => {
    const { fundIds } = action.payload;
    fundIds.forEach(fundId => {
      const history = state.byId[fundId] || {};
      if (!history.fetching) {
        history.fetching = true;
        history.fundId = fundId;
        state.byId[fundId] = history;
      }
    });
  },

  [fetchFundsFinancialHistorySuccess]: (state, action) => {
    const { fundsFinancialHistory } = action.payload;
    const historyItemsById = groupBy(fundsFinancialHistory, 'fundId');

    Object.keys(historyItemsById).forEach(fundId => {
      state.byId[fundId] = {
        fetching: false,
        fetched: true,
        fundId: fundId,
        error: null,
        items: historyItemsById[fundId],
      };
    });
  },

  [setUserFundFinancialHistoryPending]: (state, action) => {
    const { fundId } = action.payload;
    const existing = state.byId[fundId] || {};
    existing.updating = true;
    existing.fundId = fundId;
    state.byId[fundId] = existing;
  },

  [setUserFundFinancialHistoryError]: (state, action) => {
    const { fundId, error } = action.payload;
    const existing = state.byId[fundId] || {};
    existing.error = error;
    existing.updating = false;
    existing.updated = false;
    state.byId[fundId] = existing;
    console.error(error);
  },

  [setUserFundFinancialHistorySuccess]: (state, action) => {
    const { fundId, fundFinancialHistory } = action.payload;
    state.byId[fundId] = {
      updating: false,
      fetching: false,
      updated: true,
      fetched: true,
      fundId,
      items: fundFinancialHistory,
    };
  },

  [deleteUserFundFinancialHistoryPending]: (state, action) => {
    const { fundId } = action.payload;
    const existing = state.byId[fundId] || {};
    existing.deleting = true;
    state.byId[fundId] = existing;
  },

  [deleteUserFundFinancialHistoryError]: (state, action) => {
    const { fundId, error } = action.payload;
    const existing = state.byId[fundId] || {};
    existing.error = error;
    existing.deleting = false;
    existing.deleted = false;
    state.byId[fundId] = existing;
    console.error(error);
  },

  [deleteUserFundFinancialHistorySuccess]: (state, action) => {
    const { fundId } = action.payload;
    state.byId = Object.values(state.byId).reduce((acc, item) => {
      if (item.fundId !== fundId) {
        acc[item.fundId] = item;
      }
      return acc;
    }, {});
  },
});
