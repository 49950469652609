import { select, axisBottom, axisLeft, scaleLinear, brush, format, easeCubicInOut } from 'd3';
import { currency, abbreviations } from 'src/formatters';
import { lookup } from 'src/lookup';

const formatDecimal1 = format('.1f');
const formatDecimal3 = format('.3f');
const formatPercent0 = format('.0%');

const yFormat = currency(abbreviations.billion)({
  minimumFractionDigits: 2,
});

const TRANSITION_DURATION = 500;
const MAX_ZOOM_FACTOR = 50;
const MIN_POINT_RADIUS = 2;
const MAX_POINT_RADIUS = 50;

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function getPointRadius(zoom) {
  if (!Number.isFinite(zoom)) return MIN_POINT_RADIUS;

  const scale = zoom > 1 ? zoom ** 0.5 : zoom;
  const radius = Math.max(MIN_POINT_RADIUS, MIN_POINT_RADIUS * scale);

  return Math.min(radius, MAX_POINT_RADIUS);
}

function drawXAxis({ svg, xScale, canvas, metric, margin, t, zoom }) {
  const xAxis = svg
    .selectAll('.x-axis')
    .data([xScale.ticks()])
    .join('g')
    .attr('class', 'x-axis')
    .style('transform', `translate(${canvas.left}px,${canvas.bottom}px)`);

  const tvpiFormat = zoom < 30 ? formatDecimal1 : formatDecimal3;
  const tickFormat = metric.key === 'tvpi' ? tvpiFormat : formatPercent0;

  xAxis.transition().duration(1000).call(axisBottom(xScale).ticks(4).tickSize(-canvas.height).tickFormat(tickFormat));

  xAxis
    .selectAll('.x-axis-label')
    .data([metric.key])
    .join('text')
    .attr('class', 'x-axis-label')
    .attr('y', margin.bottom / 2)
    .attr('x', canvas.width / 2)
    .transition(t)
    .style('fill', 'currentColor')
    .style('alignment-baseline', 'middle')
    .text(metric === lookup.financialMetric.tvpi ? 'NET TVPI (X)' : 'NET IRR (%)');

  return xAxis;
}

function drawYAxis({ svg, yScale, canvas, margin }) {
  const yAxis = svg
    .selectAll('.y-axis')
    .data([yScale.ticks()])
    .join('g')
    .attr('class', 'y-axis')
    .style('transform', `translate(${canvas.left}px,${canvas.top}px)`);

  yAxis.transition().duration(1000).call(axisLeft(yScale).ticks(4).tickSize(-canvas.width).tickFormat(yFormat));

  yAxis
    .selectAll('.y-axis-label')
    .data(['FUND SIZE'])
    .join('text')
    .attr('class', 'y-axis-label')
    .attr('y', 0)
    .attr('x', 0)
    .style('fill', 'currentColor')
    .style('text-anchor', 'middle')
    .style('alignment-baseline', 'hanging')
    .style('transform', `translate(${-margin.left}px,${canvas.height / 2}px) rotate(-90deg)`)
    .text(d => d);

  return yAxis;
}

function draw({ data, metric, mouseEventRefs, onChangeZoom, onResetZoom, zoom, extents }) {
  return function ({ svg, canvas, margin }) {
    if (canvas.width < 1 || canvas.height < 1) return;

    const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

    const pointRadius = getPointRadius(zoom) ?? 0;

    function handleMouseOver(_, d) {
      const elem = select(this);
      elem.style('fill', '#00cbffff');
      itemOver.current(this, d);
    }

    function handleMouseOut(_, d) {
      const elem = select(this);
      elem.style('fill', '#00cbff91');
      itemOut.current(this, d);
    }

    const t = svg.transition().duration(TRANSITION_DURATION).ease(easeCubicInOut);

    const xScale = scaleLinear().domain(extents.x).range([0, canvas.width]);

    svg
      .selectAll('defs')
      .data([0])
      .join('defs')
      .call(defs =>
        defs
          .selectAll('#clip')
          .data([0])
          .join('clipPath')
          .attr('id', 'clip')
          .attr('data-cy', 'clipping-path-element')
          .call(clip =>
            clip
              .selectAll('#clip-rect')
              .data([0])
              .join('rect')
              .attr('id', 'clip-rect')
              .attr('data-cy', 'clipping-path-rectangle-element')
              .attr('x', -5)
              .attr('y', -5)
              .attr('width', canvas.width + 5)
              .attr('height', canvas.height + 5)
          )
      );

    drawXAxis({
      svg,
      xScale,
      canvas,
      metric,
      margin,
      zoom,
      t,
    });

    const yScale = scaleLinear().domain(extents.y).range([canvas.height, 0]);

    drawYAxis({
      svg,
      yScale,
      canvas,
      metric,
      margin,
      t,
    });

    svg
      .selectAll('.zoom-label')
      .data([Math.round(zoom)])
      .join('text')
      .attr('class', 'zoom-label')
      .attr('x', canvas.left)
      .attr('y', margin.top / 2)
      .html(d => (d > 1 ? `ZOOM ${d}&#x00d7;` : ''))
      .style('font-size', '10px')
      .style('fill', 'currentColor')
      .style('alignment-baseline', 'middle')
      .style('cursor', 'pointer')
      .on('click', onResetZoom);

    const brushControls = svg
      .selectAll('.brush-controls')
      .data([null])
      .join('g')
      .attr('class', 'brush-controls')
      .style('transform', `translate(${canvas.left}px,${canvas.top}px)`);

    const root = svg
      .selectAll('.root')
      .data([data])
      .join('g')
      .attr('class', 'root')
      .attr('data-cy', 'root-element')
      .attr('clip-path', 'url(#clip)')
      .style('transform', `translate(${canvas.left}px,${canvas.top}px)`);

    const zoomBrush = brush().extent([
      [0, 0],
      [canvas.width, canvas.height],
    ]);

    zoomBrush.on('end', ({ selection }) => {
      if (!selection) return;

      brushControls.select('.brush').call(zoomBrush.clear, null);

      const x1 = xScale.invert(selection[0][0]);
      const x2 = xScale.invert(selection[1][0]);
      const y1 = yScale.invert(selection[1][1]);
      const y2 = yScale.invert(selection[0][1]);

      onChangeZoom([x1, x2], [y1, y2]);
    });

    brushControls
      .selectAll('.brush')
      .data([zoom < MAX_ZOOM_FACTOR].filter(Boolean))
      .join('g')
      .attr('class', 'brush')
      .call(zoomBrush)
      .on('dblclick', onResetZoom);

    brushControls
      .selectAll('.zoom-out-control')
      .data([zoom >= MAX_ZOOM_FACTOR].filter(Boolean))
      .join('rect')
      .attr('class', 'zoom-out-control')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', canvas.width)
      .attr('height', canvas.height)
      .style('fill', 'transparent')
      .style('cursor', 'zoom-out')
      .on('click', onResetZoom);

    function getDelay(_, i) {
      return Math.round(i / 10);
    }

    root
      .selectAll('.point')
      .data(data, d => d.fundId)
      .join(
        function enter(point) {
          point
            .append('circle')
            .attr('class', 'point')
            .attr('data-cy', 'rendered-point')
            .attr('cx', 0)
            .attr('cy', 0)
            .attr('r', pointRadius)
            .style('fill', '#00cbff91')
            .style('opacity', 1)
            .style('cursor', 'pointer')
            .on('mouseover', handleMouseOver)
            .on('mouseout', handleMouseOut)
            .on('click', handleEvent(itemClick))
            .on('touchstart', handleEvent(itemTouchStart))
            .on('touchend', handleEvent(itemTouchEnd))
            .transition(t)
            .delay(getDelay)
            .duration(1000)
            .attr('cx', d => xScale(d[metric.key]))
            .attr('cy', d => yScale(d.size));
        },
        function update(point) {
          point
            .on('mouseover', handleMouseOver)
            .on('mouseout', handleMouseOut)
            .on('click', handleEvent(itemClick))
            .on('touchstart', handleEvent(itemTouchStart))
            .on('touchend', handleEvent(itemTouchEnd))
            .transition(t)
            .delay(getDelay)
            .duration(1000)
            .attr('cx', d => xScale(d[metric.key]))
            .attr('cy', d => yScale(d.size))
            .attr('r', pointRadius);
        },
        function exit(point) {
          point.transition(t).delay(getDelay).duration(1000).attr('opacity', 0).attr('cy', 0).attr('cx', 0).remove();
        }
      );
  };
}

export default draw;
