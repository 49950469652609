import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogActions, IconButton } from '@material-ui/core';
import IconAdd from '../IconAdd';

const IFrame = styled.iframe`
  height: 618px;
  max-height: 100%;
  width: 1100px;
  max-width: 100%;
`;

const IconClose = styled(IconAdd)`
  stroke-width: 10px;
  transform: rotate(45deg);
  font-size: 2.513777778em;
`;

const CloseButton = styled(IconButton).attrs({
  children: <IconClose />,
})`
  font-size: 16px;
`;

const Title = styled.h2`
  flex: 1;
  padding-left: 1em;
`;

function TutorialDialog({ open, title, videoId, onClose }) {
  const name = useMemo(() => {
    if (!title) return 'Tutorial';
    return `Tutorial: ${title}`;
  }, [title]);

  return (
    <Dialog onClose={onClose} open={Boolean(open)} maxWidth="lg">
      <DialogActions>
        <Title>{name}</Title>
        <CloseButton onClick={onClose} />
      </DialogActions>
      <DialogContent>
        <IFrame
          title={videoId}
          src={`https://player.vimeo.com/video/${videoId}?portrait=0&byline=0&title=0&autoplay=1`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </DialogContent>
    </Dialog>
  );
}

TutorialDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  videoId: PropTypes.number,
  onClose: PropTypes.func,
};

TutorialDialog.defaultProps = {
  open: false,
  onClose: () => null,
};

export default TutorialDialog;
