import { scaleLinear } from 'd3';

const colors = ['#00CBFF', '#105161'];

function colorScale(series) {
  return scaleLinear()
    .domain([0, series.length - 1])
    .range(colors);
}

export default colorScale;
