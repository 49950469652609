import React from 'react';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import { Box, PeerGroupScatter, FinancialMetricSelection, Checkbox, IncompleteDataMessage } from 'src/components';

const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 0 24px;
  }
`;

function PlotChartSection({
  className,
  financialMetric,
  setFinancialMetric,
  fund,
  localPeers,
  handleItemClick,
  showOutliers,
  setShowOutliers,
}) {
  return (
    <Container className={className}>
      <Box display="flex" flexDirection="row" alignItems="center" height="26px" justifyContent="space-between">
        <FinancialMetricSelection
          financialMetric={financialMetric}
          onMetricChange={setFinancialMetric}
          disableTvpi={fund.tvpi == null}
          disableIrr={fund.irr == null}
          hidePme
        />

        <Checkbox checked={showOutliers} size="small" onChange={event => setShowOutliers(event.target.checked)}>
          Show Outliers
        </Checkbox>
        {/* <CheckboxLabel
          control={<CheckboxSmall checked={showOutliers} />}
          label="Show Outliers"
          onChange={event => setShowOutliers(event.target.checked)}
        /> */}
      </Box>

      {fund[financialMetric.key] === null && (
        <div
          css={`
            margin-top: 0.75rem;
          `}
        >
          <IncompleteDataMessage entity="fund" />
        </div>
      )}
      <PeerGroupScatter
        width="100%"
        height="calc(100% - 18px)"
        metric={financialMetric}
        fund={fund}
        data={localPeers}
        onItemClick={handleItemClick}
        onItemTouchStart={handleItemClick}
        showOutliers={showOutliers}
      />
    </Container>
  );
}

PlotChartSection.defaultProps = {};

PlotChartSection.propTypes = {};

export default PlotChartSection;
