// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { TooltipContent, TooltipHeader, TooltipTitle, TooltipArrowLeft } from '../..';

// eslint-disable-next-line no-unused-vars
function FundBenchmarkChangeTooltip({ open, anchorEl, onClickAway, label }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="right"
        modifiers={{
          offset: {
            enabled: true,
            offset: '-4,15',
          },
          arrow: {
            enabled: true,
            element: '.user-fund-benchmark-tooltip-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <div>
              <TooltipArrowLeft
                className="user-fund-benchmark-tooltip-arrow"
                color="#3f89a8"
                css={`
                  margin-top: -4px;
                `}
              />
              <TooltipContent
                css={`
                  background-color: #3f89a8;
                  padding: 16px 20px 20px 20px;
                `}
              >
                <TooltipHeader>
                  <TooltipTitle
                    css={`
                      color: #fff;
                      font-weight: 600;
                      font-size: 14px;
                    `}
                  >
                    Benchmark change
                  </TooltipTitle>
                </TooltipHeader>
                <div
                  css={`
                    color: #fff;
                    max-width: 165px;
                    font-size: 11px;
                    line-height: 1.45;
                  `}
                >
                  This fund&apos;s benchmarking criteria was changed by you from the system default. To revert click
                  Reset in the Filter button
                </div>
              </TooltipContent>
            </div>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}

FundBenchmarkChangeTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  label: 'fund',
};

FundBenchmarkChangeTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  label: PropTypes.string,
  onClickAway: PropTypes.func,
};

export default FundBenchmarkChangeTooltip;
