import React from 'react';
import styled from 'styled-components';
import ButtonLarge from '../ButtonLarge';
import IconCopy from '../IconCopy';

const Icon = styled(IconCopy)`
  color: #a6a6a6;
  width: 17px;
`;

const ButtonLargeCopy = props => {
  return (
    <ButtonLarge {...props}>
      <Icon />
      Copy
    </ButtonLarge>
  );
};

ButtonLargeCopy.defaultProps = {};

ButtonLargeCopy.propTypes = {};

export default ButtonLargeCopy;
