export const round =
  (fractiondigits = 1) =>
  value => {
    if (value == null) {
      return null;
    }

    // eslint-disable-next-line no-restricted-globals
    if (!isFinite(value)) {
      return '-';
    }
    return parseFloat(value).toFixed(fractiondigits);
  };
