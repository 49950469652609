import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFundsWithPeers, useFundsTableColumnPresets } from 'src/hooks';
import { VirtualizedGridTable } from 'src/components';
import BookmarkPopover from 'src/components/molecules/FundBookmarkControl/Popover';

const TABLE_SORTS = {
  name: { id: 'name', desc: false },
  vintage: {
    id: 'vintage',
    desc: true,
  },
  undefined: { id: 'name', desc: false },
};

function DashboardFundsBookmarkedFundsTable({ funds }) {
  const { columns: presetColumns, tableStateReducer, tableCss, bookmarkPopoverState } = useFundsTableColumnPresets();

  const columns = useMemo(() => presetColumns, [presetColumns]);
  const fundsWithPeers = useFundsWithPeers({ funds });
  const data = useMemo(() => {
    if (!fundsWithPeers) return [];
    return fundsWithPeers;
  }, [fundsWithPeers]);
  const initialSortBy = useMemo(() => [TABLE_SORTS.name], []);

  return (
    <>
      <VirtualizedGridTable
        columns={columns}
        data={data}
        stateReducer={tableStateReducer}
        initialSortBy={initialSortBy}
        css={tableCss}
      />
      <BookmarkPopover {...bookmarkPopoverState} />
    </>
  );
}

DashboardFundsBookmarkedFundsTable.defaultProps = {
  vintages: [],
  strategies: [],
  sizes: [],
  regions: [],
  funds: [],
};

DashboardFundsBookmarkedFundsTable.propTypes = {
  vintages: PropTypes.array,
  strategies: PropTypes.array,
  sizes: PropTypes.array,
  regions: PropTypes.array,
  initialSortBy: PropTypes.oneOf(Object.keys(TABLE_SORTS)),
};

export default React.memo(DashboardFundsBookmarkedFundsTable);
