import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconArrowLeft(props) {
  return (
    <Svg viewBox="0 0 29.998 26.7675" {...props}>
      <g>
        <path d="M11.707,1.414L10.293,0l-10,10c-0.391,0.391-0.391,1.023,0,1.414l10,10L11.707,20l-8.293-8.293h21.584v-2H3.414   L11.707,1.414z" />
        <rect x="27.998" y="2.707" width="2" height="16" />
      </g>
    </Svg>
  );
}
