import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function IconBookmark({ variation, ...rest }) {
  return (
    <Svg viewBox="0 0 24 24" {...rest}>
      {variation === 'outline' ? (
        <path d="M18.75,3.25 L18.75,22 L12.1777344,15.7816324 C12.1273032,15.7357237 12.0637161,15.7127047 12,15.7127047 C11.9575226,15.7127047 11.9151025,15.7229354 11.8765996,15.7433584 L11.8222656,15.7816324 L5.25,22 L5.25,3.25 L18.75,3.25 Z M17.25,4.75 L6.75,4.75 L6.75,18.515 L10.8125078,14.6724033 C11.0991364,14.4114785 11.4594876,14.2552154 11.8372165,14.2202265 L12,14.2127047 C12.3815441,14.2127047 12.7537597,14.3363522 13.0744782,14.5801913 L13.2086531,14.6920423 L17.25,18.516 L17.25,4.75 Z" />
      ) : (
        <path d="M5,22 L11.8222656,15.7816324 C11.8726968,15.7357237 11.9362839,15.7127047 12,15.7127047 C12.0637161,15.7127047 12.1273032,15.7357237 12.1777344,15.7816324 L19,22 L19,3 L5,3 L5,22 Z" />
      )}
    </Svg>
  );
}

export default IconBookmark;
