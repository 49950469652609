import { select, range, scaleLinear, scaleBand } from 'd3';

function drawRecentPeriodChart({ svgId, value, benchmarkValue, width, height, accentColor, labelFormat }) {
  if (!svgId) return;
  if (!width || !height) return;
  // if (typeof value !== 'number') return;

  const MARGIN = [16, 21, 0, 21];
  const svgHeight = Math.max(height - (MARGIN[0] + MARGIN[2]), 0);
  const svgWidth = Math.max(width - (MARGIN[1] + MARGIN[3]), 0);

  const dataSet = [
    { value, color: accentColor, type: 'subject' },
    { value: benchmarkValue, color: '#4e4e4e', type: 'peer' },
  ];

  const xScale = scaleBand().domain(range(dataSet.length)).range([0, svgWidth]).paddingInner(0.2);

  const yScale = scaleLinear()
    .domain([Math.min(0, Math.min(value || 0, benchmarkValue || 0)), Math.max(value || 0, benchmarkValue || 0)])
    .range([0, svgHeight]);

  const root = select(`#${svgId}`)
    .selectAll('.root')
    .data([value])
    .join('g')
    .attr('class', 'root')
    .attr('transform', `translate(${MARGIN[3]}, 0)`);

  root
    .selectAll('.bar')
    .data(dataSet)
    .join('rect')
    .attr('class', 'bar')
    .attr('fill', d => d.color)
    .attr('transform', (_d, i) => `translate(${xScale(i)}, 0)`)
    .attr('height', d => {
      if (d.type === 'subject' && value === null) return height - 1;
      return yScale(d.value ?? 0);
    })
    .attr('width', xScale.bandwidth())
    .attr('y', d => {
      if (d.type === 'peer' && value === null) return height / 2;
      if (d.type === 'subject' && value === null) return height - 1;
      if (value === 0 && benchmarkValue === 0) return height - 1;
      return height - yScale(d.value);
    });

  root
    .selectAll('.label')
    .data(dataSet)
    .join('text')
    .attr('class', 'label')
    .attr('fill', 'currentColor')
    .text(d => labelFormat(d.value))
    .attr('text-anchor', 'middle')
    .attr('y', d => {
      if (d.type === 'peer' && value === null) return height / 2;
      if (d.type === 'subject' && value === null) return height - 4;
      if (value === 0 && benchmarkValue === 0) return height - 4;
      return height - yScale(d.value) - 3;
    })
    .attr('transform', (_d, i) => `translate(${xScale(i)}, 0)`)
    .attr('x', xScale.bandwidth() / 2 + 3)
    .attr('dy', '-3px');
}

export default drawRecentPeriodChart;
