import { useMemo, useCallback } from 'react';
import { useList as useListFundPeerGroupFilters } from 'src/services/filter-api/userFundPeerGroupFilters/queries';
import {
  useCreate as useCreateFundPeerGroupFilter,
  useUpdate as useUpdateFundPeerGroupFilter,
  useDelete as useDeleteFundPeerGroupFilter,
} from 'src/services/filter-api/userFundPeerGroupFilters/mutations';

function useUserFundPeerGroupFilter(fundId) {
  const { mutateAsync: createFundPeerGroupFilter, isLoading: isCreating } = useCreateFundPeerGroupFilter();
  const { mutateAsync: updateFundPeerGroupFilter, isLoading: isUpdating } = useUpdateFundPeerGroupFilter();
  const { mutateAsync: deleteFundPeerGroupFilter, isLoading: isDeleting } = useDeleteFundPeerGroupFilter();
  const persisting = isCreating || isUpdating || isDeleting;

  const { data: fundPeerGroupFilters } = useListFundPeerGroupFilters();
  const fundPeerGroupFilter = useMemo(() => {
    if (!fundPeerGroupFilters) return;
    if (!fundId) return;

    return fundPeerGroupFilters.find(b => b.fundId === fundId);
  }, [fundPeerGroupFilters, fundId]);

  const saveUserPeerGroupFilter = useCallback(
    async settings => {
      if (fundPeerGroupFilter) {
        const { filterId } = fundPeerGroupFilter;
        return updateFundPeerGroupFilter({ filterId, settings });
      } else if (fundId) {
        return createFundPeerGroupFilter({ fundId, settings });
      }
    },
    [fundId, fundPeerGroupFilter, createFundPeerGroupFilter, updateFundPeerGroupFilter]
  );

  const resetUserPeerGroupFilter = useCallback(() => {
    if (!fundPeerGroupFilter) return;

    const { filterId } = fundPeerGroupFilter;
    return deleteFundPeerGroupFilter({ filterId });
  }, [fundPeerGroupFilter, deleteFundPeerGroupFilter]);

  return useMemo(() => {
    const { selectedVintageIds, selectedRegionIds, selectedSecondRegionIds, selectedSizeIds, selectedStrategyIds } =
      fundPeerGroupFilter?.settings ?? {};
    return {
      filterId: fundPeerGroupFilter?.filterId,
      persisting,
      selectedVintageIds,
      selectedRegionIds,
      selectedSecondRegionIds,
      selectedSizeIds,
      selectedStrategyIds,
      saveUserPeerGroupFilter,
      resetUserPeerGroupFilter,
    };
  }, [persisting, fundPeerGroupFilter, saveUserPeerGroupFilter, resetUserPeerGroupFilter]);
}

export default useUserFundPeerGroupFilter;
