import styled from 'styled-components';

const ModalBody = styled.section`
  padding: var(--modal-v-spacing) var(--modal-padding);

  p {
    margin: 0;
  }

  p + p {
    margin-top: calc(var(--modal-v-spacing) / 2);
  }
`;

export default ModalBody;
