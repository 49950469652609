import React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { uniqueId } from 'src/utils';
import { getQuarter } from 'src/utils/date';
import draw from './draw';

const Layout = styled.div`
  opacity: ${p => (p.$isLoading ? 0.2 : 1)};
  flex: 3;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.714285714em;

  svg .label {
    font-size: 10px;
  }
`;

const ChartSection = styled.div`
  flex: 1;
  flex-basis: 100%;
  position: relative;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;

const Title = styled.h3`
  font-size: 1.4em;
  font-weight: 400;
  margin: 0 0 14px 0;
`;

const Footer = styled.p`
  font-size: 1em;
  margin: 0.375em 0;
`;

function getTwoDigitYear(dateString) {
  const year = new Date(dateString).getFullYear();
  return year.toString().substr(-2);
}

function RecentPeriodChart({
  className,
  title,
  value,
  labelFormat,
  date,
  accentColor,
  benchmarkValue,
  hasMoreHistory,
  targetRef,
  width,
  height,
  isLoading,
}) {
  const { current: svgId } = useRef(uniqueId());

  useEffect(
    function () {
      draw({
        svgId,
        value: isLoading ? 0 : value,
        benchmarkValue: isLoading ? 0 : benchmarkValue,
        width,
        height,
        accentColor,
        labelFormat,
      });
    },
    [isLoading, svgId, value, benchmarkValue, width, height, accentColor, labelFormat]
  );

  const label = isLoading
    ? "Current - Q '  "
    : hasMoreHistory
    ? `Current - Q${getQuarter(date)}'${getTwoDigitYear(date)}`
    : `Q${getQuarter(date)} ${new Date(date).getFullYear()}`;

  return (
    <Layout className={className} $isLoading={isLoading}>
      <Title>{title}</Title>
      <ChartSection ref={targetRef}>
        <Svg id={svgId} width={width} height={height} />
      </ChartSection>
      <Footer>{label}</Footer>
    </Layout>
  );
}

RecentPeriodChart.defaultProps = {
  width: 0,
  height: 0,
  accentColor: '#b0b0b0',
  labelFormat: x => {
    if (x === null) return 'N/A';
    return `${parseFloat(x).toFixed(1)}⨯`;
  },
  isLoading: true,
};

RecentPeriodChart.propTypes = {
  accentColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  benchmarkValue: PropTypes.number,
  labelFormat: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
};

export default withResizeDetector(RecentPeriodChart, {
  refreshMode: 'throttle',
  refreshRate: 50,
});
