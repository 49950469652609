import React from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow } from '@material-ui/core';
import { TooltipArrowLeft, TooltipContent, TooltipCloseIcon, TooltipHeader } from 'src/components';

const paragraph = css`
  color: #888;
  line-height: 1.2;
  margin: 0;
`;

function FundsInMarketInfoTooltip({ open, anchorEl, onClose }) {
  return (
    <Popper
      style={{
        zIndex: 100,
        maxWidth: 600,
      }}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="right"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0,15',
        },
        arrow: {
          enabled: true,
          element: '.create-portfolio-info-arrow',
        },
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          <div
            css={`
              overflow: auto;
            `}
          >
            <TooltipArrowLeft className="create-portfolio-info-arrow" />
            <TooltipContent padding="20px" color="#000" maxWidth="100px" fontSize="12px">
              <TooltipHeader>
                <h3
                  css={`
                    margin: 5px 0;
                    padding: 0;
                  `}
                >
                  Fund Raising by Vintage Year
                </h3>
                <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
              </TooltipHeader>
              <div
                css={`
                  width: 200px;
                `}
              >
                <p css={paragraph}>
                  The Fund-raising panel demonstrates funds currently in the market raising funds for that particular
                  vintage. Once such a fund has announced its final close, we then note that fund as being Closed.
                  FundFilter defines vintage year by the first year of capital called up by the GP. A fund&apos;s
                  raising status is informed by varied sources, including Form D filings, press releases, LP
                  documentation, etc.
                </p>
              </div>
            </TooltipContent>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

FundsInMarketInfoTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};

FundsInMarketInfoTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default FundsInMarketInfoTooltip;
