import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconIdCard(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M40.876,29.837c0-5.44,4.411-9.851,9.851-9.851s9.851,4.41,9.851,9.851s-4.41,9.851-9.851,9.851   S40.876,35.277,40.876,29.837z M40.655,54.195c0-2.175,2.026-2.281,2.026-2.281v15.572h16.094c0-3.775,0-15.572,0-15.572   s2.027,0.152,2.027,2.281c0,1.687,0,10.244,0,13.291h6.607V52.738c0-6.432-6.236-11.645-12.668-11.645l-3.594,4.393l3.596,16.631   l-4.016,4.102l-4.015-4.102l3.596-16.631l-3.596-4.395c-6.432,0-12.666,5.215-12.666,11.646v14.748h6.607   C40.655,64.445,40.655,55.917,40.655,54.195z M71.549,74.631H28.451v4.127h43.098V74.631z M71.549,85.902H28.451v4.128h43.098   V85.902z M83.382,11.556v84.05c0,2.424-1.972,4.395-4.395,4.395H21.012c-2.423,0-4.395-1.971-4.395-4.395v-84.05   c0-2.423,1.972-4.395,4.395-4.395h17.637L48.79,0.368c0.732-0.491,1.688-0.491,2.421,0l10.141,6.793h17.636   C81.41,7.161,83.382,9.133,83.382,11.556z M47.586,5.77c0,1.334,1.081,2.414,2.414,2.414s2.414-1.08,2.414-2.414   c0-1.332-1.081-2.413-2.414-2.413S47.586,4.438,47.586,5.77z M78.499,12.044H21.5v83.073h56.999V12.044z" />
    </Svg>
  );
}
