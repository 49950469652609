import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { percent } from 'src/formatters/percent';
import {
  TooltipContent,
  TooltipCloseIcon,
  TooltipHeader,
  TooltipRow,
  TooltipTitle,
  TooltipData,
  TooltipLabel,
  TooltipLink,
  TooltipArrowRight,
} from 'src/components';

const TooltipWrapper = styled.div``;

function FundInMarketTooltip({ data, open, anchorEl, onClickAway, onClose, onViewFullList }) {
  if (!data) return null;
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="left"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: '.funds-in-market-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <TooltipWrapper>
              <TooltipArrowRight className="funds-in-market-arrow" />
              <TooltipContent>
                <TooltipHeader>
                  <TooltipTitle>{data.label}</TooltipTitle>
                  <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
                </TooltipHeader>
                <TooltipRow>
                  <TooltipLabel>Year</TooltipLabel>
                  <TooltipData>{data.year}</TooltipData>
                </TooltipRow>
                <TooltipRow>
                  <TooltipLabel>TOTAL</TooltipLabel>
                  {data.allCount ? (
                    <TooltipData>{percent()(data.count / data.allCount)}</TooltipData>
                  ) : (
                    <TooltipData>{data.count}</TooltipData>
                  )}
                </TooltipRow>
                <TooltipRow marginTop="10px">
                  <TooltipLink
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      onViewFullList(data);
                    }}
                  >
                    View full list
                  </TooltipLink>
                </TooltipRow>
              </TooltipContent>
            </TooltipWrapper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
FundInMarketTooltip.defaultProps = {
  data: null,
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewFullList: () => {},
};

FundInMarketTooltip.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
  onViewFullList: PropTypes.func,
};

export default FundInMarketTooltip;
