import { db } from 'src/config/firebase';

const dbFunds = db.collection('funds');

async function getFunds() {
  const results = await dbFunds.orderBy('name', 'asc').get();
  const data = results.docs.map(d => d.data());
  return data;
}

export { getFunds };
