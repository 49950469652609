import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { navigate } from '@reach/router';
import styled, { css } from 'styled-components';
import { Transition, TransitionGroup } from 'react-transition-group';
import { Box, IconLeftCaret, IconRightCaret, FooterArticle } from 'src/components';
import { useTickerNews, useDedupeNewsArticles } from 'src/hooks';

const articleDisplayCount = 3;
const articleDisplayTime = 10000;
const transitionTime = 200;

const Root = styled(Box)`
  display: flex;
  align-items: center;
  height: 56px;
  padding: ${props => props.theme.spacing(0.5, 1)};
  background-color: ${props => props.theme.palette.common.black};
  z-index: ${props => props.theme.zIndex.appBar};
`;

const caretStyles = css`
  color: ${props => props.theme.palette.text.secondary};
  cursor: pointer;
  width: 48px;
  height: 48px;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
`;

const LeftCaret = styled(IconLeftCaret)`
  ${caretStyles};
`;

const RightCaret = styled(IconRightCaret)`
  ${caretStyles};
`;

const defaultStyle = {
  transition: `transform ${transitionTime}ms ease-in-out`,
};

const transitionStyles = {
  entering: { transform: 'translateY(100px)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(100px)' },
  exited: { transform: 'translateY(100px)' },
};

function Footer(props) {
  const tickerNews = useTickerNews();
  const news = useDedupeNewsArticles({ articles: tickerNews });
  const newsRef = useRef(null);
  const [currentNewsDisplay, setCurrentNewsDisplay] = useState([]);
  const articleIndex = useRef(0);
  const interval = useRef(null);

  function handleArticleSelect(article) {
    navigate(`/news/${article.articleId}`);
  }

  function incrementDecrementArticles(direction = 'increment', restartInterval = true) {
    if (restartInterval) {
      clearInterval(interval.current);
      interval.current = setInterval(() => {
        incrementDecrementArticles('increment', false);
      }, articleDisplayTime);
    }
    if (direction === 'increment') {
      articleIndex.current += articleDisplayCount;
      if (articleIndex.current >= newsRef.current.length - 1) {
        articleIndex.current = 0;
      }
    } else {
      articleIndex.current -= articleDisplayCount;
      if (articleIndex.current <= 0) {
        articleIndex.current = 0;
      }
    }

    setCurrentNewsDisplay(newsRef.current.slice(articleIndex.current, articleIndex.current + articleDisplayCount));
  }

  useEffect(() => {
    if (news) {
      newsRef.current = news;
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
      setCurrentNewsDisplay(newsRef.current.slice(articleIndex.current, articleIndex.current + articleDisplayCount));
      interval.current = setInterval(() => {
        incrementDecrementArticles('increment', false);
      }, articleDisplayTime);
    }
    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news]);

  return (
    <Root {...props}>
      <LeftCaret onClick={() => incrementDecrementArticles('decrement', true)} />
      <TransitionGroup
        css={`
          width: calc(100% - 100px);
          position: relative;
          height: 100%;
        `}
      >
        {currentNewsDisplay &&
          currentNewsDisplay.map((article, index) => (
            <Transition key={article.articleId} timeout={transitionTime}>
              {state => (
                <FooterArticle
                  article={article}
                  css={`
                    width: ${`calc(${100 / currentNewsDisplay.length}% - 25px)`};
                    left: ${`${(100 / currentNewsDisplay.length) * index}%}`};
                    position: absolute;
                  `}
                  onSelect={handleArticleSelect}
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                />
              )}
            </Transition>
          ))}
      </TransitionGroup>
      <RightCaret onClick={() => incrementDecrementArticles('increment', true)} />
    </Root>
  );
}

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
