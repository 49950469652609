import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagerEmployees } from '../store/employees';
import useEmployeeBookmarks from './useEmployeeBookmarks';

function mapSchools(employee) {
  let lastSchoolName = '';
  let lastSchoolId = '';
  let lastParentSchoolName = '';
  let lastParentSchoolId = '';
  let prevSchoolName = '';
  let prevSchoolId = '';
  let prevParentSchoolName = '';
  let prevParentSchoolId = '';

  if (employee.education) {
    if (employee.education.length > 0) {
      const school = employee.education[0];
      lastSchoolName = school.schoolName;
      lastSchoolId = school.schoolId;
      lastParentSchoolName = school.parentSchoolName;
      lastParentSchoolId = school.parentSchoolId;
    }
    if (employee.education.length > 1) {
      const school = employee.education[1];
      prevSchoolName = school.schoolName;
      prevSchoolId = school.schoolId;
      prevParentSchoolName = school.parentSchoolName;
      prevParentSchoolId = school.parentSchoolId;
    }
  }

  return {
    lastSchoolName,
    lastSchoolId,
    lastParentSchoolName,
    lastParentSchoolId,
    prevSchoolName,
    prevSchoolId,
    prevParentSchoolId,
    prevParentSchoolName,
  };
}

export default function useFundManagerCurrentEmployees({ fundManagerId }) {
  const dispatch = useDispatch();
  const fundManagerCurrentEmployees = useSelector(state => state.employee.all.byFundManagerId);
  const allEmployees = useSelector(state => state.employee.all.byId);
  const employeeBookmarks = useEmployeeBookmarks({
    asObjectOrArray: 'object',
  });
  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    if (fundManagerId && !fundManagerCurrentEmployees[fundManagerId]) {
      dispatch(fetchFundManagerEmployees({ fundManagerId }));
    }
  }, [dispatch, fundManagerCurrentEmployees, fundManagerId]);

  useEffect(() => {
    if (
      fundManagerId &&
      fundManagerCurrentEmployees[fundManagerId] &&
      fundManagerCurrentEmployees[fundManagerId].fetched &&
      employeeBookmarks
    ) {
      const emps = fundManagerCurrentEmployees[fundManagerId].employeeIds
        .map(id => {
          const employee = allEmployees[id];
          const schools = mapSchools(employee);
          const bookmarked = employeeBookmarks[id];
          return {
            ...employee,
            ...schools,
            bookmarked,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      setEmployees(emps);
    }
  }, [allEmployees, employeeBookmarks, fundManagerCurrentEmployees, fundManagerId]);

  return employees;
}
