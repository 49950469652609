import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import routes from 'src/config/routes';
import { useGet as useGetAuth } from 'src/services/filter-api/authentication';
import { useAuthenticatedPageView } from 'src/hooks';
import { InitialLoadDialog, AppLayout, AuthLayout } from 'src/components';

function Route({ public: isPublicRoute, navigate, Component, path, ...rest }) {
  const auth = useGetAuth();
  const dataLoaded = useSelector(state => state.loading);
  const isDataLoading = !dataLoaded.funds && !dataLoaded.fundManagers;

  useAuthenticatedPageView();

  const isAccountRoute = path?.startsWith(routes.ACCOUNT);

  const isAnonymousExpiredRedirect = [
    !isAccountRoute,
    auth?.isAuthenticated,
    auth?.isAnonymous,
    auth?.isAnonymousExpired,
  ].every(Boolean);

  const isUnauthenticatedRedirect = [
    path !== routes.USER_SIGN_IN,
    path !== routes.USER_SIGN_UP,
    !auth?.isAuthenticated,
  ].every(Boolean);

  const isEmailUnverifiedRedirect = [
    path !== routes.USER_EMAIL_VERIFICATION,
    auth?.isAuthenticated,
    !auth?.isAnonymous,
    !auth?.isVerified,
  ].every(Boolean);

  if (isPublicRoute) {
    return <Component path={path} {...rest} />;
  }

  if (!auth || auth.isLoading) {
    return null;
  }

  if (isUnauthenticatedRedirect) {
    navigate(routes.USER_SIGN_IN);
    return <AuthLayout />;
  }

  if (isAnonymousExpiredRedirect) {
    navigate(routes.ACCOUNT);
    return <AppLayout />;
  }

  if (isEmailUnverifiedRedirect) {
    navigate(routes.USER_EMAIL_VERIFICATION);
    return <AuthLayout />;
  }

  if (isAccountRoute) {
    return <Component path={path} {...rest} />;
  }

  return (
    <>
      <Component path={path} {...rest} />
      {isDataLoading && <InitialLoadDialog loading={dataLoaded} />}
    </>
  );
}

Route.defaultProps = {
  public: false,
};

Route.propTypes = {
  Component: PropTypes.func.isRequired,
  default: PropTypes.bool,
  path: function (props, propName, componentName) {
    if (props['default'] === true) return;
    if (typeof props[propName] === 'string') return;

    return new Error(`
      Invalid prop \`${propName}\` of type \`${typeof props[propName]}\`
      supplied to \`${componentName}(${props['Component'].name})\`,
      expected \`string\`
    `);
  },
};

export default Route;
