/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { css } from 'styled-components';
import { navigate, Match } from '@reach/router';
import {
  List,
  ListItem,
  ListItemText,
  IconFunnel,
  IconCoinStack,
  IconIdCard,
  IconFire,
  IconBook,
  IconLifePreserver,
  IconChat,
  IconInfo,
  IconBookmark,
} from '../..';

const iconCss = css`
  margin-right: 8px;
`;

const sectionCss = css`
  margin-top: 32px;
`;

const ListItemCss = css`
  padding-left: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #e1e1e1;
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: rgba(114, 114, 114, 0.4);
  }
`;

const policyLinkCss = css`
  color: #9a9a9a;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const copyRightCss = css`
  color: #9a9a9a;
`;

// eslint-disable-next-line react/prop-types
const NavItem = ({ path, exact, children, ...rest }) => (
  <Match path={exact ? path : `${path}/*`}>
    {({ match }) => (
      <ListItem button onClick={() => navigate(path)} selected={!!match} {...rest} css={ListItemCss}>
        {children}
      </ListItem>
    )}
  </Match>
);

export default function SideNav(props) {
  return (
    <div
      {...props}
      css={`
        background-color: #3f3f3f;
        height: 100%;
      `}
    >
      <img
        src="/assets/img/logo_with_brand.svg"
        onClick={() => navigate('/')}
        alt="FundFilter"
        css={`
          height: 30px;
          cursor: pointer;
          margin: 32px 0 0 32px;
        `}
      />
      <List
        component="nav"
        aria-label="main navigation"
        css={`
          margin-top: 16px;
        `}
      >
        <NavItem path="/" exact>
          <IconFunnel css={iconCss} />
          <ListItemText primary="Market Filter" />
        </NavItem>
        <NavItem path="/funds">
          <IconCoinStack css={iconCss} />
          <ListItemText primary="Funds" />
        </NavItem>
        <NavItem path="/managers">
          <IconIdCard css={iconCss} />
          <ListItemText primary="Fund Managers" />
        </NavItem>
        <NavItem path="/news">
          <IconFire css={iconCss} />
          <ListItemText primary="News" />
        </NavItem>
        <NavItem path="/saved">
          <IconBookmark css={iconCss} />
          <ListItemText primary="Saved" />
          {/* <Badge badgeContent={3} color="primary">
            <ListItemText primary="My Dashboard"  />
          </Badge> */}
        </NavItem>

        <NavItem path="/tutorials" css={sectionCss}>
          <IconBook css={iconCss} />
          <ListItemText primary="Tutorials" />
        </NavItem>
        <NavItem path="/help">
          <IconLifePreserver css={iconCss} />
          <ListItemText primary="Help" />
        </NavItem>
        <NavItem path="/contact">
          <IconChat css={iconCss} />
          <ListItemText primary="Contact" />
        </NavItem>
        <NavItem path="/about">
          <IconInfo css={iconCss} />
          <ListItemText primary="About" />
        </NavItem>
      </List>
      <List
        css={`
          position: absolute;
          bottom: 0;
          left: 20px;
        `}
      >
        <ListItem>
          <a href="https://altman.ai/terms-of-service" target="_blank" rel="noreferrer noopener" css={policyLinkCss}>
            Terms of Service
          </a>
        </ListItem>
        <ListItem>
          <a href="https://altman.ai/privacy-policy" target="_blank" rel="noreferrer noopener" css={policyLinkCss}>
            Privacy Policy
          </a>
        </ListItem>
        <ListItem
          css={`
            margin-top: 50px;
            ${copyRightCss}
          `}
        >
          {`© ${new Date().getFullYear()} Altman Inc.`}
          <br />
          All Rights Reserved
        </ListItem>
      </List>
    </div>
  );
}
