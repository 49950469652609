const filtering = { title: 'Filtering', videoId: 640376325 };
const addFund = { title: 'Add A Fund', videoId: 640380541 };
const strategyForecasting = { title: 'Strategy Forecasting', videoId: 640409283 };
const portfolioForecasting = { title: 'Portfolio Forecasting', videoId: 640516824 };
const fundPerformance = { title: 'Fund Performance', videoId: 640382049 };
const compareToPeers = { title: 'Compare To Peers', videoId: 640533510 };
const fundManagerSummary = { title: 'Fund Manager Summary', videoId: 640503288 };
const teams = { title: 'Teams', videoId: 640551283 };

const tutorials = [
  filtering,
  addFund,
  strategyForecasting,
  portfolioForecasting,
  fundPerformance,
  compareToPeers,
  fundManagerSummary,
  teams,
];

export { filtering };
export { addFund };
export { strategyForecasting };
export { portfolioForecasting };
export { fundPerformance };
export { compareToPeers };
export { fundManagerSummary };
export { teams };
export default tutorials;
