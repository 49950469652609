import React from 'react';
import { useMemo, useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { uniqBy, countBy, sortBy } from 'src/lib/lodash';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { setFundManagerId } from 'src/store/last-selected';
import {
  useFundManager,
  useFundManagerFunds,
  useFundManagerLocation,
  useCountries,
  useFilteredFundPeers,
  useFundPeerGroupFiltersMap,
  useFundManagerPerformanceFilter,
} from 'src/hooks';
import { lookup } from 'src/lookup';
import { AppPanel } from 'src/components';
import Component from './Component';

const VENTURE_CAPITAL_ID = '3MfCGH1108bGe4Eq6EpJ';
const NASDAQ_ID = 'nasdaq_composite';

const INDEX_ORDER = ['sp_500', 'russell_3000', 'barclays_aggregate', 'ftse_nareit', 'nasdaq_composite'];

function FundManagerVisualization({ fundManagerId }) {
  const dispatch = useDispatch();
  const [financialMetric, setFinancialMetric] = useState(lookup.financialMetric.tvpi);
  const fundPeerGroupFilters = useFundPeerGroupFiltersMap();
  const fundManager = useFundManager({ fundManagerId });
  const fundManagerLocation = useFundManagerLocation({ fundManagerId });
  const fundManagerFunds = useFundManagerFunds({ fundManagerId });
  const [displayFunds, setDisplayFunds] = useState();
  const [fundAddEditOpen, setFundAddEditOpen] = useState(false);
  const [benchmarkChangedTooltipOpen, setBenchmarkChangedTooltipOpen] = useState(false);
  const [benchmarkChangedTooltipAnchorEl, setBenchmarkChangedTooltipAnchorEl] = useState(null);
  const [benchmarkChangedTooltipLabel, setBenchmarkChangedTooltipLabel] = useState('fund manager');
  const [visibleToYouTooltipOpen, setVisibleToYouTooltipOpen] = useState(false);
  const [visibleToYouTooltipAnchorEl, setVisibleToYouTooltipAnchorEl] = useState(null);
  const [visibleToYouTooltipLabel, setVisibleToYouTooltipLabel] = useState('fund manager');
  const [chartTooltipOpen, setChartTooltipOpen] = useState(false);
  const [chartTooltipAnchorEl, setChartTooltipAnchorEl] = useState(null);
  const [chartTooltipData, setChartTooltipData] = useState(null);
  const [addressTooltipOpen, setAddressTooltipOpen] = useState(false);
  const [addressTooltipAnchorEl, setAddressTooltipAnchorEl] = useState(null);
  const [addressTooltipData, setAddressTooltipData] = useState(null);
  const [selectedStrategy, setSelectedStrategy] = useState();
  const [selectedCalcs, setSelectedCalcs] = useState(lookup.pmeCalcs.da);
  const [selectedIndex, setSelectedIndex] = useState();
  const dataGeo = useCountries();
  const addedFundSubStrategyId = useRef();
  const userManagerAnchorEl = useRef();
  const { isExpanded } = useContext(AppPanel.Context);

  const fundManagerStrategies = useMemo(() => {
    if (!fundManagerFunds) return [];

    const strategies = uniqBy(
      fundManagerFunds.map(f => ({
        strategyId: f.strategyId,
        strategy: f.strategy,
        subStrategyId: f.subStrategyId,
        subStrategy: f.subStrategy,
      })),
      s => s.subStrategyId
    );

    const countSubStrategies = countBy(fundManagerFunds, s => s.subStrategyId);
    return sortBy(strategies, [s => -countSubStrategies[s.subStrategyId]]);
  }, [fundManagerFunds]);

  const fundManagerIndexes = useMemo(() => {
    if (!fundManagerFunds) return [];

    const indexNames = fundManagerFunds
      .filter(s => s.subStrategyId === selectedStrategy?.subStrategyId)
      .map(f => f.pmeValues?.map(p => p.indexName) ?? [])
      .flat()
      .sort((a, b) => {
        const idxA = INDEX_ORDER.findIndex(s => s === a);
        const idxB = INDEX_ORDER.findIndex(s => s === b);

        if (idxA === -1) return 1;
        if (idxB === -1) return -1;
        return idxA > idxB ? 1 : -1;
      });

    return uniqBy(indexNames);
  }, [fundManagerFunds, selectedStrategy]);

  const {
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
  } = useFundManagerPerformanceFilter({
    fundManagerStrategies,
    fundManagerSelectedStrategy: selectedStrategy,
  });

  const fundManagerFundPeers = useFilteredFundPeers({
    funds: displayFunds,
    vintages: vintagesFilter,
    regions: regionsFilter,
    secondRegions: secondRegionsFilter,
    sizes: sizesFilter,
    strategies: strategiesFilter,
    sortField: financialMetric.key,
  });

  useEffect(() => {
    if (fundManager && !fundManager.userId) {
      dispatch(setFundManagerId({ fundManagerId }));
    }
  }, [fundManagerId, fundManager, dispatch]);

  useEffect(() => {
    if (fundManagerStrategies && fundManagerStrategies.length > 0) {
      if (addedFundSubStrategyId.current) {
        const addedFundStrategy = fundManagerStrategies.find(s => s.subStrategyId === addedFundSubStrategyId.current);
        if (addedFundStrategy) {
          setSelectedStrategy(addedFundStrategy);
          addedFundSubStrategyId.current = null;
          return;
        }
        return;
      }
      if (selectedStrategy && fundManagerStrategies.find(s => s.subStrategyId === selectedStrategy.subStrategyId)) {
        setSelectedStrategy(fundManagerStrategies.find(s => s.subStrategyId === selectedStrategy.subStrategyId));
        return;
      }
      setSelectedStrategy(fundManagerStrategies[0]);
    }
  }, [fundManagerStrategies, selectedStrategy]);

  useEffect(() => {
    if (!fundManagerFunds) return setDisplayFunds([]);
    if (selectedStrategy) {
      setDisplayFunds(fundManagerFunds.filter(s => s.subStrategyId === selectedStrategy.subStrategyId));
    } else {
      setDisplayFunds(fundManagerFunds);
    }
  }, [fundManagerFunds, selectedStrategy]);

  useEffect(() => {
    if (!selectedStrategy) return;
    if (fundManagerIndexes.length < 1) return;
    if (selectedStrategy.strategyId !== VENTURE_CAPITAL_ID) {
      return setSelectedIndex(fundManagerIndexes[0]);
    }

    const nasdaqIndex = fundManagerIndexes.find(i => i === NASDAQ_ID);
    setSelectedIndex(nasdaqIndex || fundManagerIndexes[0]);
  }, [fundManagerIndexes, selectedStrategy]);

  function closeTooltips() {
    setChartTooltipOpen(false);
    setChartTooltipAnchorEl(null);
    setAddressTooltipOpen(false);
    setAddressTooltipAnchorEl(null);
    setVisibleToYouTooltipOpen(false);
    setVisibleToYouTooltipAnchorEl(null);
    setBenchmarkChangedTooltipOpen(false);
    setBenchmarkChangedTooltipAnchorEl(null);
  }

  function showChartTooltip(node, d) {
    closeTooltips();
    setChartTooltipData(d);
    setChartTooltipAnchorEl(node);
    setChartTooltipOpen(true);
  }

  function showAddressTooltip(node, d) {
    closeTooltips();
    const boundingClientRect = node.getBoundingClientRect();
    setAddressTooltipData(d);
    setAddressTooltipAnchorEl({
      clientWidth: boundingClientRect.width,
      clientHeight: boundingClientRect.height,
      getBoundingClientRect: () => boundingClientRect,
    });
    setAddressTooltipOpen(true);
  }

  function showUserFundTooltip(node) {
    closeTooltips();
    const boundingClientRect = node.getBoundingClientRect();
    setVisibleToYouTooltipAnchorEl({
      clientWidth: boundingClientRect.width,
      clientHeight: boundingClientRect.height,
      getBoundingClientRect: () => boundingClientRect,
    });
    setVisibleToYouTooltipLabel('fund');
    setVisibleToYouTooltipOpen(true);
  }

  function showUserBenchmarkTooltip(node) {
    closeTooltips();
    const boundingClientRect = node.getBoundingClientRect();
    setBenchmarkChangedTooltipAnchorEl({
      clientWidth: boundingClientRect.width,
      clientHeight: boundingClientRect.height,
      getBoundingClientRect: () => boundingClientRect,
    });
    setBenchmarkChangedTooltipLabel('fund');
    setBenchmarkChangedTooltipOpen(true);
  }

  function handleItemClick(node, d) {
    if (node.classList.contains('user-benchmark-point')) {
      showUserBenchmarkTooltip(node);
    } else if (node.classList.contains('user-fund-point')) {
      showUserFundTooltip(node);
    } else {
      showChartTooltip(node, d);
    }
  }

  function handleLocationClick(node, d) {
    showAddressTooltip(node, d);
  }

  function handleClickAway(e) {
    if (
      !e.target.classList.contains('point') &&
      !e.target.classList.contains('rect') &&
      !e.target.classList.contains('address-point') &&
      !e.target.classList.contains('user-benchmark-point') &&
      !e.target.classList.contains('user-fund-point')
    ) {
      closeTooltips();
    }
  }

  function handleMouseLeave(e) {
    if (e.target.classList.contains('MuiBox-root') || e.target.classList.contains('vt-row')) {
      closeTooltips();
    }
  }

  function handleViewFund(fund) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundManagerFundSelect);
    navigate(`/funds/${fund.fundId}`);
  }

  function handleUserManagerMarkerClick() {
    setVisibleToYouTooltipAnchorEl(userManagerAnchorEl.current);
    setVisibleToYouTooltipLabel('fund manager');
    setVisibleToYouTooltipOpen(true);
  }

  function handleFundAddEditClose() {
    setFundAddEditOpen(false);
  }

  function handleFinancialMetricChange(metric) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundManagerMetric);
    setFinancialMetric(metric);
  }

  function handleStrategyChange(strategy) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundManagerStrategy);
    setSelectedStrategy(strategy);
  }

  function handleCalcsChange(index) {
    setSelectedCalcs(index);
  }

  function handleIndexChange(index) {
    setSelectedIndex(index);
  }

  return (
    <Component
      addressTooltipAnchorEl={addressTooltipAnchorEl}
      addressTooltipData={addressTooltipData}
      addressTooltipOpen={addressTooltipOpen}
      benchmarkChangedTooltipOpen={benchmarkChangedTooltipOpen}
      benchmarkChangedTooltipAnchorEl={benchmarkChangedTooltipAnchorEl}
      benchmarkChangedTooltipLabel={benchmarkChangedTooltipLabel}
      chartTooltipAnchorEl={chartTooltipAnchorEl}
      chartTooltipData={chartTooltipData}
      chartTooltipOpen={chartTooltipOpen}
      closeTooltips={closeTooltips}
      dataGeo={dataGeo}
      displayFunds={displayFunds}
      expanded={isExpanded}
      financialMetric={financialMetric}
      fundAddEditOpen={fundAddEditOpen}
      fundPeerGroupFilters={fundPeerGroupFilters}
      fundManager={fundManager}
      fundManagerFundPeers={fundManagerFundPeers}
      fundManagerFunds={fundManagerFunds}
      fundManagerLocation={fundManagerLocation}
      fundManagerStrategies={fundManagerStrategies}
      fundManagerIndexes={fundManagerIndexes}
      handleClickAway={handleClickAway}
      handleFinancialMetricChange={handleFinancialMetricChange}
      handleFundAddEditClose={handleFundAddEditClose}
      handleItemClick={handleItemClick}
      handleLocationClick={handleLocationClick}
      handleMouseLeave={handleMouseLeave}
      handleStrategyChange={handleStrategyChange}
      handleCalcsChange={handleCalcsChange}
      handleIndexChange={handleIndexChange}
      handleUserManagerMarkerClick={handleUserManagerMarkerClick}
      handleViewFund={handleViewFund}
      selectedStrategy={selectedStrategy}
      selectedCalcs={selectedCalcs}
      selectedIndex={selectedIndex}
      setFundAddEditOpen={setFundAddEditOpen}
      setVisibleToYouTooltipOpen={setVisibleToYouTooltipOpen}
      userManagerAnchorEl={userManagerAnchorEl}
      visibleToYouTooltipAnchorEl={visibleToYouTooltipAnchorEl}
      visibleToYouTooltipLabel={visibleToYouTooltipLabel}
      visibleToYouTooltipOpen={visibleToYouTooltipOpen}
      defaultRegionsFilter={defaultRegionsFilter}
      defaultSecondRegionsFilter={defaultSecondRegionsFilter}
      defaultVintagesFilter={defaultVintagesFilter}
      defaultSizesFilter={defaultSizesFilter}
      defaultStrategiesFilter={defaultStrategiesFilter}
      regionsFilter={regionsFilter}
      secondRegionsFilter={secondRegionsFilter}
      vintagesFilter={vintagesFilter}
      sizesFilter={sizesFilter}
      strategiesFilter={strategiesFilter}
      setRegionsFilter={setRegionsFilter}
      setSecondRegionsFilter={setSecondRegionsFilter}
      setVintagesFilter={setVintagesFilter}
      setSizesFilter={setSizesFilter}
      setStrategiesFilter={setStrategiesFilter}
    />
  );
}

FundManagerVisualization.defaultProps = {};

FundManagerVisualization.propTypes = {
  fundManagerId: PropTypes.string.isRequired,
};

export default FundManagerVisualization;
