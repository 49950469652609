import React from 'react';

export default function SmallCircle(props) {
  return (
    <div
      css={`
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: #e1b72a;
      `}
      {...props}
    />
  );
}
