import React from 'react';
import { useState, useEffect } from 'react';
import { usageTrackingChannel, USAGE_TRACKING_TOPICS } from 'src/services';
import { AppPanel, DashboardFollowing } from 'src/components';

const VIEWS = {
  funds: 'funds',
  managers: 'managers',
  people: 'people',
};

function DashboardBookmarksPanel({ spanX, spanY, minHeight }) {
  const [view, setView] = useState(VIEWS.funds);

  useEffect(
    function handleDisplayChange() {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.savedFollowingDataSet);
    },
    [view]
  );

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title="Following"
      controls={
        <>
          <div
            css={`
              flex: 1;
              display: flex;
              justify-content: center;
            `}
          >
            <AppPanel.Button selected={view === VIEWS.funds} onClick={() => setView(VIEWS.funds)}>
              Funds
            </AppPanel.Button>
            <AppPanel.Button selected={view === VIEWS.managers} onClick={() => setView(VIEWS.managers)}>
              Managers
            </AppPanel.Button>
            <AppPanel.Button selected={view === VIEWS.people} onClick={() => setView(VIEWS.people)}>
              People
            </AppPanel.Button>
          </div>
          <div
            css={`
              flex: 1;
            `}
          />
        </>
      }
      expandable
    >
      <DashboardFollowing
        showFunds={view === VIEWS.funds}
        showManagers={view === VIEWS.managers}
        showPeople={view === VIEWS.people}
      />
    </AppPanel>
  );
}

DashboardBookmarksPanel.defaultProps = {};

DashboardBookmarksPanel.propTypes = {};

export default DashboardBookmarksPanel;
