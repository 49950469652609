import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';
import IconSummary from '../IconSummary';

const BG_COLOR1 = '#ffd449';
const BG_COLOR2 = '#353535';
const PULSE_INTERVAL = 2500;

const StyledButtonRounded = styled(ButtonRounded)`
  transition: background-color ${PULSE_INTERVAL}ms ease;
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiButton-startIcon {
    margin-right: 4px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    left: 2px;
  }
`;

const ButtonSummary = ({ onClick, article, ...rest }) => {
  const [bgColor, setBgColor] = useState(BG_COLOR1);
  const interval = useRef();

  useEffect(() => {
    if (article) {
      interval.current = setInterval(() => {
        setBgColor(prev => (prev === BG_COLOR1 ? BG_COLOR2 : BG_COLOR1));
      }, PULSE_INTERVAL);
    }
    return () => clearInterval(interval.current);
  }, [article]);

  function handleClick(e) {
    clearInterval(interval.current);
    setBgColor(BG_COLOR1);
    onClick(e);
  }

  return (
    <StyledButtonRounded
      startIcon={<IconSummary />}
      onClick={handleClick}
      css={`
        background-color: ${bgColor};
      `}
      {...rest}
    >
      Summary
    </StyledButtonRounded>
  );
};

ButtonSummary.defaultProps = {
  onClick: () => {},
  article: null,
};

ButtonSummary.propTypes = {
  onClick: PropTypes.func,
  article: PropTypes.object,
};

export default ButtonSummary;
