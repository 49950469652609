import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { filterApi } from 'src/services';
import { useCurrentSubscription } from 'src/hooks';
import { Button, IconExclaim } from 'src/components';
import ConfirmPasswordDialog from './ConfirmPasswordDialog';
import { useDelete as useDeleteAuth } from 'src/services/filter-api/authentication';

function AccountUserPasswordForm() {
  const { data: currentSubscription, refetch: refetchCurrentSubscription } = useCurrentSubscription();
  const [isEditing, setIsEditing] = useState(false);
  const [requirePassword, setRequirePassword] = useState(false);
  const [showFinalWarning, setShowFinalWarning] = useState(false);
  const deleteAuth = useDeleteAuth().mutateAsync;

  async function handleSubmitForm() {
    try {
      await filterApi.stripeCancelSubscription({
        subscriptionId: currentSubscription.id,
      });
      await refetchCurrentSubscription();
    } catch (cancelSubscriptionError) {
      console.error(cancelSubscriptionError);
    }

    try {
      await deleteAuth();

      setIsEditing(false);
      setRequirePassword(false);
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setRequirePassword(true);
      } else {
        throw new Error(error);
      }
    }
  }

  function handleClickCancel() {
    setIsEditing(false);
    setRequirePassword(false);
    setShowFinalWarning(false);
  }

  return (
    <>
      <form>
        <section>
          <header>
            <h3>Delete Account</h3>
            <Button size="small" variant="outlined" disabled={isEditing} onClick={() => setIsEditing(true)}>
              <IconExclaim />
              Delete
            </Button>
          </header>
          <span />
          <div>
            This will close your FundFilter account permanently. All of your personalized data will be deleted. This
            cannot be undone.
          </div>
          <span />
          <footer
            css={`
              color: white;
            `}
          >
            <Button
              disabled={!isEditing}
              disableElevation
              variant="contained"
              onClick={() => setShowFinalWarning(true)}
              color="danger"
            >
              Confirm Delete
            </Button>
            <Button disabled={!isEditing} onClick={handleClickCancel}>
              Cancel
            </Button>
          </footer>
        </section>
      </form>

      <Dialog open={showFinalWarning} onClose={handleClickCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The operation you are about to perform will <i>permanently delete</i> your account and all of your data.
            This is a non-recoverable action that cannot be undone by either the FundFilter support or engineering
            teams.
          </DialogContentText>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCancel} color="default">
            Keep My Account
          </Button>
          <Button variant="contained" onClick={handleSubmitForm} color="danger">
            Delete My Account and All My Data
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmPasswordDialog open={requirePassword} onSuccess={handleSubmitForm} onFailure={handleClickCancel} />
    </>
  );
}

AccountUserPasswordForm.defaultProps = {};

AccountUserPasswordForm.propTypes = {
  children: PropTypes.any,
};

export default AccountUserPasswordForm;
