import { useState, useEffect } from 'react';
import { getRegions } from '../lookup/regions';

export default function useRegions() {
  const [regions, setRegions] = useState(null);

  useEffect(() => {
    async function load() {
      setRegions(await getRegions());
    }
    load();
  }, []);

  return regions;
}
