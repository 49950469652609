import React from 'react';
import { useEffect, useRef, useCallback } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { uniqueId } from 'src/utils';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { Box } from 'src/components';
import draw from './draw';

const Container = styled(Box)`
  height: ${props => props.height || '100%'};
`;

function DashboardHierarchy({ className, data, expanded, onClick, onClickNode, width, height, targetRef }) {
  const id = useRef(uniqueId());
  const handleItemClick = useCallback(
    (_e, node) => {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.savedFollowingWheel);

      return onClickNode({
        type: node.data.type,
        id: node.data.id,
        parentId: node.depth > 0 && node.data.parentId,
        isSelected: node.data.isSelected,
      });
    },
    [onClickNode]
  );

  useEffect(() => {
    if (!width || !height) return;
    if (!data) return;

    draw({
      id: id.current,
      data,
      size: { width, height },
      margin: { top: 20, right: 20, bottom: 20, left: 20 },
      expanded,
      handleItemClick,
    });
  }, [data, expanded, handleItemClick, height, width]);

  return (
    <Container ref={targetRef} className={className}>
      {width && height && (
        <svg
          id={id.current}
          onClick={onClick}
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
          }}
        />
      )}
    </Container>
  );
}

DashboardHierarchy.defaultProps = {
  expanded: false,
  onClick: () => {},
  onItemClick: () => {},
  width: 0,
  height: 0,
};

DashboardHierarchy.propTypes = {
  data: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  onItemClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  targetRef: PropTypes.object,
};

export default withResizeDetector(DashboardHierarchy);
