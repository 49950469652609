import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { ErrorMessage, Button } from 'src/components';
import { useResetPassword } from 'src/services/filter-api/authentication';
import routes from 'src/config/routes';
import { message } from 'src/config/colors';
import Form from './Form';
import AuthLayout from 'src/components/templates/AuthLayout';
import { lg } from 'src/config/layout';

const Section = styled.section`
  padding: 2em;
  width: calc(100vw - 2em);
  max-width: 24rem;

  @media (min-width: ${lg.BREAKPOINT}px) {
    max-width: 32rem;
    padding: 2rem 5rem;
  }
`;

const Footer = styled.footer`
  min-height: 2.5em;
  font-size: 14px;
  margin-top: 7px;
`;

const SuccessMessage = styled(ErrorMessage)`
  color: ${message.success.TEXT_COLOR};
`;

function UserPasswordResetPage() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const sendPasswordReset = useResetPassword().mutateAsync;

  async function handleSubmit(email) {
    setSuccess(null);
    setError(null);

    try {
      await sendPasswordReset(email);
      setSuccess({
        message: 'Weʼve sent you an email containing a link to reset your password',
      });
    } catch (e) {
      setError(e);
    }
  }

  return (
    <AuthLayout>
      <AuthLayout.Card>
        <Section>
          <h3>Ready to sign in?</h3>
          <Button variant="contained" color="primary" $block onClick={() => navigate(routes.USER_SIGN_IN)}>
            Sign in
          </Button>
        </Section>

        <Section>
          <h3>Reset your password:</h3>
          <Form onSubmit={handleSubmit} />
          <Footer>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            {success && <SuccessMessage>{success.message}</SuccessMessage>}
          </Footer>
        </Section>
      </AuthLayout.Card>
    </AuthLayout>
  );
}

export default UserPasswordResetPage;
