import { useMemo } from 'react';
import { useList as useListPortfolios } from 'src/services/filter-api/userPortfolios/queries';

function includeByPortfolioId(portfolioId) {
  return function (portfolio) {
    if (!portfolioId) return true;
    return portfolio.portfolioId === portfolioId;
  };
}

function fundBookmarks(portfolio) {
  return Object.values(portfolio.bookmarks || {});
}

function useBookmarkedFundIds(portfolioId) {
  const listPortfolios = useListPortfolios({ portfolioId });
  return useMemo(() => {
    if (!listPortfolios.data) return [];
    return listPortfolios.data
      .filter(includeByPortfolioId(portfolioId))
      .flatMap(fundBookmarks)
      .map(b => b.fundId)
      .filter(Boolean);
  }, [listPortfolios.data, portfolioId]);
}

export default useBookmarkedFundIds;
