import React from 'react';
import styled from 'styled-components';
import IconBarChart from '../IconBarChart';
import ButtonRounded from '../ButtonRounded';

const StyledButtonRounded = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 2px;
    left: 2px;
  }
`;

const ButtonBar = props => {
  return (
    <StyledButtonRounded startIcon={<IconBarChart />} {...props}>
      Bar
    </StyledButtonRounded>
  );
};

ButtonBar.defaultProps = {};

ButtonBar.propTypes = {};

export default ButtonBar;
