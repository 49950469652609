import React from 'react';
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { lookup } from 'src/lookup';
import { currency, abbreviations, multiple, percent } from 'src/formatters';
import { IconButton } from '@material-ui/core';
import useFundPeerGroupFiltersMap from './useFundPeerGroupFiltersMap';
import {
  TableHeadCellSelect,
  Truncator,
  FundLink,
  IconInfoOutlined,
  DefinitionsTooltip,
  FundDistributionChart,
} from 'src/components';
import BookmarkTrigger from 'src/components/molecules/FundBookmarkControl/Trigger';

const tableCss = css`
  .th.info {
    overflow: visible;
  }
  .info {
    padding: 0;
  }

  .vintage,
  .peers,
  .save {
    text-align: center;
  }

  .size,
  .dpi,
  .rvpi,
  .irr,
  .tvpi,
  .calledPct {
    text-align: right;
  }
`;

function DelayRender({ t, children }) {
  const [ready, setReady] = useState(false);
  const firstRender = useRef(true);

  useEffect(
    function () {
      if (!firstRender.current) return;
      const timer = setTimeout(() => setReady(true), t);
      firstRender.current = false;
      return () => {
        clearTimeout(timer);
        setReady(false);
      };
    },
    [t]
  );

  if (!ready) return null;

  return children;
}

function formatValue(formatFn) {
  return ({ value }) => formatFn(value);
}

const Button = styled(IconButton)`
  transform: translate(-1em, -1px);
  position: relative;
  z-index: 99;

  .MuiSvgIcon-root {
    width: 0.625em;
    height: 0.625em;
  }
`;

const ButtonInfo = ({ className }) => {
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button ref={anchorRef} size="small" className={className} onClick={() => setOpen(state => !state)}>
        <IconInfoOutlined />
      </Button>
      <DefinitionsTooltip open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} />
    </>
  );
};

function useBookmarkPopoverState() {
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [fundId, setFundId] = useState(null);
  const [title, setTitle] = useState(null);
  const onOpen = useCallback(({ target, fund }) => {
    const { top, left } = target.getBoundingClientRect();
    setAnchorPosition({ top, left });
    setFundId(fund.fundId);
    setTitle(fund.name);
  }, []);
  const onClose = useCallback(() => {
    setAnchorPosition(null);
    setFundId(null);
    setTitle(null);
  }, []);

  return useMemo(() => {
    return {
      fundId,
      title,
      anchorPosition,
      open: Boolean(anchorPosition),
      onOpen,
      onClose,
    };
  }, [anchorPosition, fundId, onClose, onOpen, title]);
}

function useFundsTableColumnPresets({ onOpenBookmarkPopover: _ } = {}) {
  const bookmarkPopover = useBookmarkPopoverState();

  const fundPeerGroupFilters = useFundPeerGroupFiltersMap();
  const [distributionChartMetric, setDistributionChartMetric] = useState(lookup.financialMetric.tvpi);

  // const handleClickBookmark = useCallback(
  //   ({ fund, target }) => {
  //     if (onOpenBookmarkPopover)
  //       onOpenBookmarkPopover({ target, fund });
  //   },
  //   [onOpenBookmarkPopover],
  // );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Fund Name',
        accessor: row => row,
        Cell: ({ value: fund }) => {
          const requiredKeys = ['calledPct', 'tvpi', 'dpi', 'irr', 'rvpi'];
          const keys = Object.keys(fund);

          if (fund.hasFinancialHistory) return <FundLink fund={fund} />;
          if (requiredKeys.some(key => keys.includes(key))) {
            return <FundLink fund={fund} fundPeerGroupFilter={fundPeerGroupFilters?.[fund?.fundId]} />;
          }
          return <FundLink fund={fund} fundPeerGroupFilter={fundPeerGroupFilters?.[fund?.fundId]} href={null} />;
        },
        width: 'minmax(10em, 2fr)',
        minDisplayWidth: 0,
        sortType: (rowA, rowB, id) => rowA.original[id].localeCompare(rowB.original[id]),
      },
      {
        id: 'info',
        Header: <ButtonInfo />,
        accessor: () => '',
        width: '0px',
        minDisplayWidth: 0,
        disableSortBy: true,
      },
      {
        id: 'vintage',
        Header: 'Vintage',
        accessor: 'vintage',
        width: '4.5em',
        minDisplayWidth: 0,
      },
      {
        id: 'size',
        Header: 'Fund Size',
        accessor: 'size',
        Cell: formatValue(currency(abbreviations.million)()),
        width: '5em',
        minDisplayWidth: 0,
      },
      {
        id: 'strategy',
        Header: 'Strategy',
        accessor: 'strategy',
        Cell: Truncator,
        width: 'minmax(6em, 1fr)',
        minDisplayWidth: 800,
      },
      {
        id: 'subStrategy',
        Header: 'Sub-Strategy',
        accessor: 'subStrategy',
        Cell: Truncator,
        width: 'minmax(6em, 1fr)',
        minDisplayWidth: 800,
      },
      {
        id: 'region1',
        Header: 'Region I',
        accessor: 'mainRegionName',
        Cell: Truncator,
        width: 'minmax(6em, 1fr)',
        minDisplayWidth: 960,
      },
      {
        id: 'region2',
        Header: 'Region II',
        accessor: 'otherRegionName',
        Cell: Truncator,
        width: 'minmax(6em, 1fr)',
        minDisplayWidth: 960,
      },
      {
        id: 'dpi',
        Header: 'DPI',
        accessor: 'dpi',
        Cell: formatValue(multiple(2)),
        width: '4em',
        minDisplayWidth: 620,
        sortType: 'basic',
      },
      {
        id: 'rvpi',
        Header: 'RVPI',
        accessor: 'rvpi',
        Cell: formatValue(multiple(2)),
        width: '4em',
        minDisplayWidth: 620,
        sortType: 'basic',
      },
      {
        id: 'irr',
        Header: 'IRR',
        accessor: 'irr',
        Cell: formatValue(percent()),
        width: '4em',
        minDisplayWidth: 500,
        sortType: 'basic',
      },
      {
        id: 'tvpi',
        Header: 'TVPI',
        accessor: 'tvpi',
        Cell: formatValue(multiple(2)),
        width: '4em',
        minDisplayWidth: 500,
        sortType: 'basic',
      },
      {
        id: 'calledPct',
        Header: 'CALLED',
        accessor: 'calledPct',
        Cell: formatValue(percent()),
        width: '4em',
        minDisplayWidth: 400,
        sortType: 'basic',
      },
      {
        id: 'peers',
        disableSortBy: true,
        Header: ({ state }) => {
          const { label } = state.distributionChartMetric;
          return (
            <span>
              Relative Performance
              <TableHeadCellSelect
                startAdornment="("
                endAdornment=")"
                value={label}
                onChange={value => setDistributionChartMetric(lookup.financialMetric[value])}
                options={[
                  {
                    label: 'TVPI',
                    value: lookup.financialMetric.tvpi.key,
                  },
                  {
                    label: 'IRR',
                    value: lookup.financialMetric.irr.key,
                  },
                ]}
              />
            </span>
          );
        },
        width: 'minmax(12em, 2fr)',
        minDisplayWidth: 1200,
        accessor: fund => fund,
        Cell: ({ value, state }) => {
          const { fundId, peers } = value;

          return (
            <DelayRender t={50}>
              <FundDistributionChart
                funds={peers}
                fundPeerGroupFilters={fundPeerGroupFilters}
                subjectFundId={fundId}
                metric={state.distributionChartMetric}
                onChangeMetric={setDistributionChartMetric}
              />
            </DelayRender>
          );
        },
      },
      {
        id: 'save',
        Header: 'Save',
        disableSortBy: true,
        accessor: ({ fundId, name }) => ({ fundId, name }),
        Cell: React.memo(({ value: fund }) => {
          if (fund.fundId.startsWith('u_')) return null;

          return (
            <BookmarkTrigger
              id={`bookmark-${fund.fundId}`}
              fundId={fund.fundId}
              onClick={({ target }) =>
                // handleClickBookmark({ target, fund })
                bookmarkPopover.onOpen({ target, fund })
              }
            />
          );
        }),
        width: '3em',
        minDisplayWidth: 0,
      },
    ],
    [bookmarkPopover, fundPeerGroupFilters]
  );

  const tableStateReducer = useCallback(
    function (state) {
      return {
        ...state,
        distributionChartMetric,
      };
    },
    [distributionChartMetric]
  );

  return {
    columns,
    tableStateReducer,
    tableCss,
    bookmarkPopoverState: bookmarkPopover,
    // bookmarkPopoverEl,
    // bookmarkPopoverFundId,
    // setBookmarkPopoverEl,
  };
}

export default useFundsTableColumnPresets;
