function defaultDrawFn({ svg, width, height, canvas }) {
  const data = [];

  const root = svg.selectAll('.root').data([data]).join('g').attr('class', 'root');

  root
    .selectAll('.border')
    .data([data])
    .join('rect')
    .attr('class', 'border')
    .attr('stroke', 'magenta')
    .attr('fill', 'none')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', width)
    .attr('height', height);

  root
    .selectAll('.canvas')
    .data([data])
    .join('rect')
    .attr('class', 'canvas')
    .attr('stroke', 'cyan')
    .attr('fill', 'none')
    .attr('x', canvas.left)
    .attr('y', canvas.top)
    .attr('width', canvas.width)
    .attr('height', canvas.height);

  root
    .selectAll('.canvas-label')
    .data([0])
    .join('text')
    .attr('class', 'canvas-label')
    .attr('fill', 'cyan')
    .attr('text-anchor', 'start')
    .attr('alignment-baseline', 'hanging')
    .attr('font-size', 10)
    .attr('x', canvas.left + 4)
    .attr('y', canvas.top + 4)
    .text(`Canvas Area`);

  root
    .selectAll('.breakeven-label')
    .data([0])
    .join('text')
    .attr('class', 'breakeven-label')
    .attr('fill', 'silver')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('x', canvas.width / 2 + canvas.left)
    .attr('y', canvas.height / 2)
    .text(`Draw something here`);
}

export default defaultDrawFn;
