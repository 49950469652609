import React from 'react';
import { useCallback } from 'react';
import { navigate, Match } from '@reach/router';

function NavLink(props) {
  const { className, path, exact, children, ...rest } = props;

  const onClickHandler = useCallback(
    function (event) {
      event.preventDefault();
      navigate(path);
    },
    [path]
  );

  return (
    <Match path={exact ? path : `${path}/*`}>
      {({ match }) => (
        <a href={path} onClick={onClickHandler} className={match ? `${className} active` : className} {...rest}>
          {children}
        </a>
      )}
    </Match>
  );
}

export default NavLink;
