/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchRecentNewsPending = createAction('FETCH_RECENT_NEWS_PENDING');
const fetchRecentNewsError = createAction('FETCH_RECENT_NEWS_ERROR');
const fetchRecentNewsSuccess = createAction('FETCH_RECENT_NEWS_SUCCESS');

export function fetchRecentNews() {
  return async function (dispatch, getState) {
    try {
      const { fetching, fetched } = getState().news.recent;
      if (fetched || fetching) return;
      dispatch(fetchRecentNewsPending());
      const articles = await filterApi.getRecentNews();
      dispatch(
        fetchRecentNewsSuccess({
          articles,
        })
      );
    } catch (error) {
      dispatch(fetchRecentNewsError({ error: error.message }));
    }
  };
}

const initialState = { fetching: false, fetched: false, error: null, articles: null };

export default createReducer(initialState, {
  [fetchRecentNewsPending]: state => {
    state.fetching = true;
  },
  [fetchRecentNewsError]: (state, action) => {
    const { error } = action.payload;
    state.fetching = true;
    state.error = error;
    console.error(error);
  },
  [fetchRecentNewsSuccess]: (state, action) => {
    const { articles } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.articles = articles;
  },
});
