function encodeToken(obj) {
  return btoa(JSON.stringify(obj));
}

function decodeToken(str) {
  try {
    return JSON.parse(atob(str));
  } catch (error) {
    throw new Error('Invalid token');
  }
}

export { encodeToken, decodeToken };
