import React from 'react';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useFundBookmarkControls from './useFundBookmarkControls';
import Trigger from './Trigger';
import Popover from './Popover';

function FundBookmarkControl({ fundId, title, label, className }) {
  const [anchorPosition, setAnchorPosition] = useState(null);

  const handleClickBookmark = useCallback(({ target }) => {
    const { top, left } = target.getBoundingClientRect();
    setAnchorPosition({ top, left });
  }, []);

  if (!fundId) return null;
  return (
    <>
      <Trigger fundId={fundId} label={label} onClick={handleClickBookmark} className={className} />
      <Popover fundId={fundId} title={title} anchorPosition={anchorPosition} onClose={() => setAnchorPosition(null)} />
    </>
  );
}

FundBookmarkControl.propTypes = {
  fundId: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
};

FundBookmarkControl.useControls = useFundBookmarkControls;
FundBookmarkControl.Popover = Popover;
FundBookmarkControl.Trigger = Trigger;

export default FundBookmarkControl;
