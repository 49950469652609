import { functions } from 'src/config/firebase';
import { isString, isBoolean } from 'src/utils/types';

const exec = functions.httpsCallable('cashflowForecastGet');

async function getFundCashflowForecast(args) {
  isString(args.fundId, 'error');
  isString(args.date, 'error');
  isBoolean(args.debug, 'warn');

  const res = await exec(args);
  return res.data;
}

export { getFundCashflowForecast };
