import React from 'react';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { SmallCircle, FundBenchmarkChangeTooltip } from 'src/components';

const UserFundBenchmarkCircle = styled(SmallCircle)`
  margin-left: ${p => p.ml};
  margin-bottom: ${p => p.mb};
  background-color: #3f89a8;
`;

function UserFundBenchmarkIndicator({ ml, mb }) {
  const anchorEl = useRef();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div ref={anchorEl} onMouseOver={() => setTooltipOpen(true)} onMouseOut={() => setTooltipOpen(false)}>
      <UserFundBenchmarkCircle ml={ml} mb={mb} />
      <FundBenchmarkChangeTooltip open={tooltipOpen} anchorEl={anchorEl && anchorEl.current} />
    </div>
  );
}

UserFundBenchmarkIndicator.defaultProps = {
  ml: '10px',
  mb: '8px',
};

UserFundBenchmarkIndicator.propTypes = {
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UserFundBenchmarkIndicator;
