import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Popover, TextField, InputAdornment, DialogTitle } from '@material-ui/core';
import { ArrowDropDown, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { getCurrentQuarter, formatDate } from 'src/utils/date';

const Option = styled(Button).attrs({
  size: 'large',
  variant: 'outlined',
})`
  background-color: ${p => (p.$selected ? '#ffffff33' : 'transparent')};
  color: ${p => (p.$selected ? '#ffffff99' : 'white')};
`;

function QuarterSelect({
  className,
  value,
  onChange,
  disabledValues,
  InputComponent,
  label,
  disabled,
  minDate,
  maxDate,
  ...rest
}) {
  const [selectedYear, setSelectedYear] = useState(Number.parseInt(value.slice(0, 4), 10));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDecrementYear = () => {
    setSelectedYear(year => {
      if (year - 1 < minDate.getFullYear()) return year;
      return year - 1;
    });
  };

  const handleIncrementYear = () => {
    setSelectedYear(year => {
      if (year + 1 > maxDate.getFullYear()) return year;
      return year + 1;
    });
  };

  const handleOptionClick = optionValue => () => {
    const date = new Date(`${optionValue}-15`);
    const dateString = getCurrentQuarter(date).toISOString();
    onChange(dateString);
    handleClose();
  };

  useEffect(() => {
    if (disabled) setAnchorEl(null);
  }, [disabled]);

  const options = [
    { value: `${selectedYear}-03`, label: 'Q1' },
    { value: `${selectedYear}-06`, label: 'Q2' },
    { value: `${selectedYear}-09`, label: 'Q3' },
    { value: `${selectedYear}-12`, label: 'Q4' },
  ];

  const displayYear = parseInt(value.slice(0, 4), 10);
  const displayMonth = parseInt(value.slice(5, 7), 10);
  const displayValue = `Q${displayMonth / 3} ${displayYear}`;

  return (
    <>
      <InputComponent
        className={className}
        value={displayValue}
        readOnly
        onClick={handleOpen}
        label={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown />
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        {...rest}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DialogTitle
          css={`
            text-align: center;
          `}
        >
          <Button onClick={handleDecrementYear}>{selectedYear <= minDate.getFullYear() ? '' : <ChevronLeft />}</Button>
          {selectedYear}
          <Button onClick={handleIncrementYear}>{selectedYear >= maxDate.getFullYear() ? '' : <ChevronRight />}</Button>
        </DialogTitle>

        <div
          css={`
            display: grid;
            grid-template-columns: repeat(4, auto);
            gap: 10px;
            padding: 1em;
          `}
        >
          {options.map(option => {
            const isSelected = value.startsWith(option.value);
            const isDisabled = disabledValues.map(val => val.slice(0, 7)).includes(option.value);

            const isOutOfRange =
              option.value < formatDate('yyyy-MM')(minDate) || option.value > formatDate('yyyy-MM')(maxDate);

            return (
              <Option
                key={option.value}
                value={option.value}
                $selected={isSelected}
                disabled={isDisabled || isOutOfRange}
                onClick={handleOptionClick(option.value)}
              >
                {option.label}
              </Option>
            );
          })}
        </div>
      </Popover>
    </>
  );
}

QuarterSelect.defaultProps = {
  onChange: () => console.info('TODO: provide onChange fn'),
  disabledValues: [],
  InputComponent: TextField,
  maxDate: new Date(9999, 0, 1),
  minDate: new Date(0, 0, 1),
};

QuarterSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabledValues: PropTypes.arrayOf(PropTypes.string),
};

export default QuarterSelect;
