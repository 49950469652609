import { useMemo } from 'react';
import { useFundForecastQueries, useStrategyTimeseriesQueries } from 'src/hooks';
import { getRecentQuarter, parseISO, quarterDiff } from 'src/utils/date';

const FORECAST_START_DATE = getRecentQuarter()();
const PLACEHOLDER_DATA = new Array(88).fill(0).map((_, q) => ({ q }));

function useFundForecasts(forecastItems) {
  const visibleFunds = useMemo(() => {
    return forecastItems.filter(fi => !fi.hidden && fi.fundId);
  }, [forecastItems]);

  const funds = forecastItems
    .filter(f => f.fundId)
    .map(f => {
      return { fundId: f.fundId, date: f.date, name: f.name };
    });

  const fundForecastQueries = useFundForecastQueries(funds, {
    truncate: 88,
  });

  const fundForecastData = useMemo(() => {
    if (visibleFunds.length < 1) return [];
    return visibleFunds.flatMap(fund => {
      const query = fundForecastQueries.find(q => {
        if (!q?.data) return false;
        return q.data.fundId === fund.fundId;
      });

      if (!query?.data?.history) return [];

      const { commitmentAmount, date, name } = fund;
      const { forecastStart, history } = query.data;

      const lastQuarterDate = parseISO(date);
      const offsetQuarterCount = quarterDiff(lastQuarterDate)(FORECAST_START_DATE);

      return history
        .filter(d => d.q >= forecastStart + offsetQuarterCount)
        .map((d, q) => {
          const { q: _q, ...rest } = d;
          return {
            q,
            ...rest,
            commitmentAmount,
            highlight: true,
            fundName: name,
          };
        });
    });
  }, [fundForecastQueries, visibleFunds]);

  return [
    fundForecastData,
    {
      isFetching: fundForecastQueries.some(q => q.isFetching),
      isLoading: fundForecastQueries.some(q => q.isLoading),
    },
  ];
}

function useTargetFundForecasts(forecastItems) {
  const visibleTargetFunds = useMemo(() => {
    return forecastItems.filter(fi => !fi.hidden && fi.targetFundId);
  }, [forecastItems]);

  const targetForecastQueries = useStrategyTimeseriesQueries(
    visibleTargetFunds.map(({ targetFundId, strategyId }) => ({
      queryId: targetFundId,
      strategyId,
      type: 'mean',
    }))
  );

  const data = useMemo(() => {
    if (visibleTargetFunds.length < 1) return [];
    return visibleTargetFunds.flatMap(targetFund => {
      const query = targetForecastQueries.find(q => {
        if (!q?.data) return false;
        return q.data.strategyId === targetFund.strategyId;
      });

      if (!query?.data?.series) return [];

      if (!targetFund) return [];

      const { commitmentAmount, name } = targetFund;
      const startDate = parseISO(targetFund.startDate);
      const offsetQuarterCount = Math.max(0, quarterDiff(FORECAST_START_DATE)(startDate));

      const series = [...new Array(offsetQuarterCount).fill(0).map((_, q) => ({ q })), ...query.data.series];

      return series.map((d, q) => {
        const { q: _q, ...rest } = d;
        return {
          q,
          ...rest,
          commitmentAmount,
          highlight: true,
          fundName: name,
        };
      });
    });
  }, [targetForecastQueries, visibleTargetFunds]);

  const isFetching = targetForecastQueries.some(q => q.isFetching);
  const isLoading = targetForecastQueries.some(q => q.isLoading);

  return [data, { isFetching, isLoading }];
}

function usePortfolioForecastData(forecastItems) {
  const [fundForecasts, fundActivity] = useFundForecasts(forecastItems);

  const [targetFundForecasts, targetFundActivity] = useTargetFundForecasts(forecastItems);

  const { data, activity } = useMemo(() => {
    const hasForecastableData = fundForecasts.length > 0 || targetFundForecasts.length > 0;
    const forecastData = hasForecastableData
      ? [...fundForecasts, ...targetFundForecasts]
          .filter(({ q }) => q < 28)
          .sort((a, b) => {
            if (!a.fundName || !b.fundName) return 0;
            return a.fundName.localeCompare(b.fundName);
          })
      : PLACEHOLDER_DATA;

    // const forecastsCount = unique(
    //   forecastData.map(({ fundName }) => fundName),
    // ).length;

    return {
      data: forecastData,
      activity: [fundActivity, targetFundActivity],
    };
  }, [fundActivity, fundForecasts, targetFundActivity, targetFundForecasts]);

  return {
    isFetching: activity.some(q => q.isFetching),
    isLoading: activity.some(q => q.isLoading),
    data,
  };
}

export default usePortfolioForecastData;
