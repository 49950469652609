import { useState, useEffect } from 'react';
import useRegions from './useRegions';

function useTopLevelRegions() {
  const [topLevelRegions, setTopLevelRegions] = useState(null);
  const regions = useRegions();

  useEffect(() => {
    if (regions) {
      setTopLevelRegions(regions.filter(r => !r.parentId));
    }
  }, [regions]);

  return topLevelRegions;
}

export default useTopLevelRegions;
