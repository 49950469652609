import { createGlobalStyle } from 'styled-components';
import { typography } from './typography';

export const GlobalStyles = createGlobalStyle`;
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #666666;
  border: none;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999999;
}
::-webkit-scrollbar-thumb:active {
 background: #aaaaaa;
}
::-webkit-scrollbar-track {
  background: #444444;
  border: none;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  // background: #666666;
}
::-webkit-scrollbar-track:active {
  // background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

svg * {
  font-family: ${typography.fontFamily};
}

`;
