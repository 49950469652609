import { useQuery } from 'react-query';
import queryKeyFactory from '../queryKeyFactory';
import * as client from './client';

const queryKeys = queryKeyFactory(client.collection.id);

const defaultOptions = {
  enabled: true,
  initialData: [],
  refetchOnWindowFocus: false,
};

function useList(filters = {}, options = {}) {
  const queryKey = queryKeys.list(filters);
  const queryFn = async () => {
    return await client.list(filters);
  };
  return useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
  });
}

export { queryKeys, useList };
