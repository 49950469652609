import { useQuery } from 'react-query';
import queryKeyFactory from '../queryKeyFactory';
import * as client from './client';

const queryKeys = queryKeyFactory(client.collection.id);

const defaultOptions = {
  enabled: true,
  refetchOnWindowFocus: false,
};

function useList(options = {}) {
  const queryKey = queryKeys.list();
  const queryFn = async () => {
    return await client.list();
  };
  return useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
  });
}

function useGet(fundId, { enabled: optEnabled, ...options } = {}) {
  const enabled = optEnabled === undefined ? Boolean(fundId) : optEnabled;
  const queryKey = queryKeys.detail(fundId);
  const queryFn = async () => await client.get(fundId);
  return useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
    enabled,
  });
}

export { queryKeys, useList, useGet };
