import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { NewsSentimentScatter } from 'src/components';
import ArticleQuickSummary from './ArticleQuickSummary';
import ToolTip from './ToolTip';

function NewsArticlesSentimentScatter({ className, hidden, articles, onClickViewArticle, onItemClick }) {
  const [articletTooltipOpen, setArticleTooltipOpen] = useState(false);
  const [articleTooltipAnchorEl, setArticleTooltipAnchorEl] = useState(null);
  const [articleTooltipData, setArticleTooltipData] = useState(null);

  function closeTooltips() {
    setArticleTooltipOpen(false);
    setArticleTooltipAnchorEl(null);
  }

  function handleViewArticle(article) {
    closeTooltips();
    onClickViewArticle(article);
  }

  function handleClickAway(e) {
    if (e.target.classList.contains('point')) return;
    closeTooltips();
  }

  function handleItemClick(node, d) {
    closeTooltips();
    const boundingClientRect = node.getBoundingClientRect();
    setArticleTooltipData(d);
    setArticleTooltipAnchorEl({
      clientWidth: boundingClientRect.width,
      clientHeight: boundingClientRect.height,
      getBoundingClientRect: () => boundingClientRect,
    });
    setArticleTooltipOpen(true);
    onItemClick();
  }

  if (hidden) return null;

  return (
    <>
      <NewsSentimentScatter className={className} articles={articles} showTitle={false} onItemClick={handleItemClick} />
      <ToolTip
        onClickAway={handleClickAway}
        open={articletTooltipOpen}
        anchorEl={articleTooltipAnchorEl}
        onClose={closeTooltips}
      >
        <ArticleQuickSummary onClickViewArticle={handleViewArticle} article={articleTooltipData} />
      </ToolTip>
    </>
  );
}

NewsArticlesSentimentScatter.defaultProps = {
  articles: [],
  onItemClick: () => {},
  onClickViewArticle: () => {},
  hidden: false,
};

NewsArticlesSentimentScatter.propTypes = {
  articles: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onClickViewArticle: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};

export default NewsArticlesSentimentScatter;
