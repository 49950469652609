import React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { parseISO, formatDurationToNow } from 'src/utils/date';
import { useFundManager } from 'src/hooks';
import {
  TooltipContent,
  TooltipTitle,
  TooltipCloseIcon,
  TooltipHeader,
  TooltipRow,
  TooltipData,
  TooltipLabel,
  TooltipLink,
  TooltipArrowUp,
} from 'src/components';

const StyledToolTipContent = styled(TooltipContent)`
  max-width: 300px;
`;

const StyledToolTipLabel = styled(TooltipLabel)`
  flex: 0 0 45px;
`;

function NewsArticleTooltip({ article, open, anchorEl, onClickAway, onClose, onViewArticle, className }) {
  const handleClickViewArticle = useCallback(
    function (event) {
      event.preventDefault();
      onViewArticle(article);
    },
    [article, onViewArticle]
  );

  const fundManager = useFundManager({
    fundManagerId: article ? article.fundManagerId : null,
  });

  const timestamp = article.publishedAt ? formatDurationToNow(parseISO(article.publishedAt)) : '-';

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        className={className}
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: '.news-article-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <div>
              <TooltipArrowUp className="news-article-arrow" marginLeft="2px" />
              <StyledToolTipContent>
                <TooltipHeader>
                  <TooltipTitle>{(fundManager || {}).name}</TooltipTitle>
                  <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
                </TooltipHeader>

                <TooltipRow>
                  <StyledToolTipLabel>TITLE</StyledToolTipLabel>
                  <TooltipData>{article.title}</TooltipData>
                </TooltipRow>

                <TooltipRow>
                  <StyledToolTipLabel>SOURCE</StyledToolTipLabel>
                  <TooltipData>{article.source}</TooltipData>
                </TooltipRow>

                <TooltipRow>
                  <StyledToolTipLabel>DATE</StyledToolTipLabel>
                  <TooltipData>{timestamp}</TooltipData>
                </TooltipRow>

                <TooltipRow marginTop="10px">
                  <TooltipLink href="#" onClick={handleClickViewArticle}>
                    View article
                  </TooltipLink>
                </TooltipRow>
              </StyledToolTipContent>
            </div>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
NewsArticleTooltip.defaultProps = {
  article: null,
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewArticle: () => {},
};

NewsArticleTooltip.propTypes = {
  article: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
  onViewArticle: PropTypes.func,
};

export default NewsArticleTooltip;
