import React from 'react';
import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputAdornment } from '@material-ui/core';
import { IconClose, QuarterSelect } from 'src/components';
import RevertIcon from '@material-ui/icons/Replay';
import { round, isNumber } from '@fundfilter/core/src/utils/number';
import { SectionRow, TextField as TextFieldBase, ValidRowIcon } from '../Styled';

const DataRow = styled(SectionRow)`
  position: relative;
  grid-template-columns: 8fr repeat(5, 4fr) 1fr 1fr;

  & > .MuiFormControl-root {
    background-color: ${p => (p.$updateIndicator ? '#ffd63211' : 'transparent')};
    transition: background-color ease 1s;
    opacity: ${p => p.$deletedIndicator && 0.1};
    opacity: ${p => p.$disabledIndicator && 0.5};
  }
`;

const TextField = styled(TextFieldBase)`
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0.125em;
  }

  .MuiInputBase-input {
    text-align: right;
    padding-right: 0.25em;
    font-size: 0.875em;
  }
`;

const DeleteRowIcon = styled(IconClose)`
  color: #979797;
  opacity: ${p => (p.$disabled ? 0 : 1)};
  pointer-events: ${p => p.$disabled && 'none'};
  cursor: ${p => !p.$disabled && 'pointer'};
  font-size: 0.625rem;
`;

const RevertRowIcon = styled(DeleteRowIcon).attrs({
  as: RevertIcon,
})`
  font-size: 1.125em;
  display: block;
`;

const UserEditedIcon = styled.span.attrs({ children: '●' })`
  color: #e1b82a;
  position: absolute;
  left: -1em;
  cursor: help;
`;

function displayValue(val) {
  if (!isNumber(val)) return '';
  const value = round(2)(val);
  const rem = value % 1;

  if (rem === 0) return value.toFixed(1);
  return value;
}

function FundDataField({
  isUserFund,
  historyItem,
  onChange,
  usedDates,
  onDeleteRow,
  onRestoreRow,
  onRevertRow,
  showLabel,
  isUpdated,
  isDeleted,
  fieldValidations,
  disabled,
  ...rest
}) {
  const [rowIsValid, setRowIsValid] = useState(false);
  const [rowIsUpdated, setRowIsUpdated] = useState(false);
  const [dpi, setDpi] = useState();
  const [rvpi, setRvpi] = useState();
  const [irr, setIrr] = useState();
  const [tvpi, setTvpi] = useState();
  const [calledPct, setCalledPct] = useState();

  const handleChangeQuarter = useCallback(
    function (value) {
      const newHistoryItem = { ...historyItem, date: value };
      onChange({ target: { value } }, newHistoryItem);
    },
    [historyItem, onChange]
  );

  const handleChangeField = useCallback(
    function (fieldKey) {
      return function (event) {
        const { value } = event.target;

        const newHistoryItem = {
          ...historyItem,
          [fieldKey]: value,
        };

        onChange(event, newHistoryItem);
      };
    },
    [historyItem, onChange]
  );

  useEffect(
    function () {
      const isValid = Object.keys(fieldValidations).every(key => {
        return !fieldValidations[key](historyItem[key]);
      });

      setRowIsValid(isValid);
    },
    [historyItem, fieldValidations]
  );

  useEffect(
    function () {
      setRowIsUpdated(isUpdated);
      const timer = setTimeout(() => setRowIsUpdated(false), 350);

      return () => clearTimeout(timer);
    },
    [isUpdated]
  );

  return (
    <DataRow {...rest} $updateIndicator={rowIsUpdated} $deletedIndicator={isDeleted} $disabledIndicator={disabled}>
      {historyItem.isUserEdited && <UserEditedIcon title="Youʼve edited data in this row" />}
      <QuarterSelect
        disabled={disabled || isDeleted}
        value={historyItem.date}
        disabledValues={usedDates}
        onChange={handleChangeQuarter}
        InputComponent={TextField}
        label={showLabel ? 'Quarter' : ''}
      />

      <TextField
        disabled={disabled || isDeleted}
        value={dpi ?? displayValue(historyItem.dpi)}
        onChange={evt => setDpi(evt.target.value)}
        onBlur={evt => {
          setDpi(displayValue(evt?.target?.value));
          handleChangeField('dpi')(evt);
        }}
        label={showLabel && 'DPI'}
        placeholder="0.0"
        InputProps={{
          endAdornment: <InputAdornment position="end">×</InputAdornment>,
        }}
      />
      <TextField
        disabled={disabled || isDeleted}
        value={rvpi ?? displayValue(historyItem.rvpi)}
        onChange={evt => setRvpi(evt.target.value)}
        onBlur={evt => {
          setRvpi(displayValue(evt.target.value));
          handleChangeField('rvpi')(evt);
        }}
        label={showLabel && 'RVPI'}
        placeholder="0.0"
        InputProps={{
          endAdornment: <InputAdornment position="end">×</InputAdornment>,
        }}
      />
      <TextField
        disabled={disabled || isDeleted}
        value={irr ?? displayValue(historyItem.irr)}
        onChange={evt => setIrr(evt.target.value)}
        onBlur={evt => {
          setIrr(displayValue(evt.target.value));
          handleChangeField('irr')(evt);
        }}
        label={showLabel && 'IRR'}
        placeholder="0.0"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <TextField
        disabled={disabled || isDeleted}
        value={tvpi ?? displayValue(historyItem.tvpi)}
        onChange={evt => setTvpi(evt.target.value)}
        onBlur={evt => {
          setTvpi(displayValue(evt.target.value));
          handleChangeField('tvpi')(evt);
        }}
        label={showLabel && 'TVPI'}
        placeholder="0.0"
        InputProps={{
          endAdornment: <InputAdornment position="end">×</InputAdornment>,
        }}
      />
      <TextField
        disabled={disabled || isDeleted}
        value={calledPct ?? displayValue(historyItem.calledPct)}
        onChange={evt => setCalledPct(evt.target.value)}
        onBlur={evt => {
          setCalledPct(displayValue(evt.target.value));
          handleChangeField('calledPct')(evt);
        }}
        label={showLabel && '% Called'}
        placeholder="0.0"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />

      <ValidRowIcon $valid={rowIsValid} />

      <div>
        {isUserFund ? (
          isDeleted ? (
            <RevertRowIcon onClick={onRestoreRow} />
          ) : (
            <DeleteRowIcon onClick={onDeleteRow} $disabled={!(usedDates && usedDates.length > 1)} />
          )
        ) : (
          historyItem.isUserEdited && <RevertRowIcon onClick={onRevertRow} />
        )}
      </div>
    </DataRow>
  );
}

FundDataField.defaultProps = {
  showLabel: true,
};

FundDataField.propTypes = {
  children: PropTypes.any,
};

export default FundDataField;
