import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Button } from 'src/components';
import { useReauthenticate } from 'src/services/filter-api/authentication';

function ConfirmPasswordDialog({ open, onClose, onSuccess, onFailure }) {
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const reauthenticate = useReauthenticate().mutateAsync;

  async function handleClickSubmit() {
    setIsLoading(true);

    try {
      await reauthenticate({ password });
      onSuccess();
    } catch (error) {
      console.error(error);
      onFailure({ reason: error.code, ...error });
    }

    setIsLoading(false);
  }

  function handleClickCancel() {
    onFailure({ reason: 'user-canceled' });
  }

  return (
    <Dialog
      open={Boolean(open)}
      onClose={(evt, reason) => {
        if (reason === 'backdropClick') return;
        onClose(evt);
      }}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">Confirm Your Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The operation you are about to perform requires that you confirm your password before continuing.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="currentPassword"
          label="Current Password"
          type="password"
          fullWidth
          onChange={event => setPassword(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel} color="default" disabled={isLoading}>
          Cancel
        </Button>
        <Button $isLoading={isLoading} variant="contained" onClick={handleClickSubmit} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmPasswordDialog.defaultProps = {};

ConfirmPasswordDialog.propTypes = {
  children: PropTypes.any,
};

export default ConfirmPasswordDialog;
