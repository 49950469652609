import PropTypes from 'prop-types';

function funcStub(name) {
  return function (...args) {
    console.info('TODO: implement', name, args);
  };
}

export { funcStub };
export default PropTypes;
