import React from 'react';
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { groupBy } from 'src/lib/lodash';
import { D3Canvas } from 'src/components';
import drawDiversificationChart from './drawDiversificationChart';
import drawCalendarChart from './drawCalendarChart';
import { formatBigNumber } from 'src/formatters/currency';

function DiversificationChart({ forecastItems }) {
  const margin = useMemo(() => {
    return {
      top: '1em',
      right: '1em',
      bottom: '2em',
      left: '1em',
    };
  }, []);

  const data = useMemo(() => {
    const groups = groupBy(forecastItems, 'strategy');
    const colorDistance = Number.parseInt('01161e', 16);
    const startColor = Number.parseInt('14a9d1', 16);

    return Object.keys(groups).map((key, i) => {
      return {
        name: key,
        value: d3.sum(groups[key], d => d.commitmentAmount),
        colorHex: (startColor - colorDistance * i).toString(16),
      };
    });
  }, [forecastItems]);

  return <D3Canvas margin={margin} draw={drawDiversificationChart({ data })} />;
}

function CalendarChart({ forecastItems, yAxisFormatter }) {
  const margin = useMemo(() => {
    return {
      top: '2em',
      right: '1em',
      bottom: '2em',
      left: '1em',
    };
  }, []);

  const data = useMemo(() => {
    const groups = groupBy(forecastItems, 'vintage');

    return Object.keys(groups)
      .map(key => {
        return {
          vintage: key,
          totalCommitmentAmount: d3.sum(groups[key], d => d.commitmentAmount),
          items: [...groups[key]],
        };
      })
      .sort((a, b) => a.vintage.localeCompare(b.vintage));
  }, [forecastItems]);

  return (
    <D3Canvas
      margin={margin}
      draw={drawCalendarChart({ data, yAxisFormatter })}
      css={`
        .x-axis {
          .domain,
          .tick line {
            display: none;
          }
        }

        .y-axis {
          .domain {
            display: none;
          }
          .tick line {
            opacity: 0.35;
          }
        }
      `}
    />
  );
}

function StrategyBreakdownChart({ className, forecastItems, chartType }) {
  const ComponentType = {
    calendar: CalendarChart,
    diversification: DiversificationChart,
  }[chartType];

  const yAxisFormatter = {
    calendar: formatBigNumber(),
    diversification: null,
  }[chartType];

  return (
    <div
      as={ComponentType}
      className={className}
      forecastItems={forecastItems}
      yAxisFormatter={yAxisFormatter}
      css={`
        flex: 1;
      `}
    />
  );
}

function Title({ onChange }) {
  const [chartType, setChartType] = useState('calendar');

  useEffect(() => {
    onChange(chartType);
  }, [chartType, onChange]);

  return (
    <Select
      css={`
        font-size: 1em;
      `}
      value={chartType}
      onChange={event => setChartType(event.target.value)}
    >
      <MenuItem value="diversification">Portfolio Diversification</MenuItem>
      <MenuItem value="calendar">Commitment Calendar</MenuItem>
    </Select>
  );
}

StrategyBreakdownChart.Title = Title;

StrategyBreakdownChart.defaultProps = {};

StrategyBreakdownChart.propTypes = {
  children: PropTypes.any,
};

export default StrategyBreakdownChart;
