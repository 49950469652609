import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagerLocation } from 'src/store/fund-managers-locations';

export default function useFundManagerLocation({ fundManagerId }) {
  const dispatch = useDispatch();
  const locations = useSelector(state => state.fundManager.locations);
  const [location, setLocation] = useState();

  useEffect(() => {
    if (fundManagerId && !locations.byId[fundManagerId]) {
      dispatch(fetchFundManagerLocation({ fundManagerId }));
    }
  }, [dispatch, fundManagerId, locations.byId]);

  useEffect(() => {
    if (fundManagerId && locations.byId[fundManagerId] && locations.byId[fundManagerId].fetched) {
      setLocation(locations.byId[fundManagerId]);
    }
  }, [location, fundManagerId, locations.byId]);

  return location;
}
