import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const VisualizationPanelRoot = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${props => `${props.theme.spacing(0.75)}px`};
  overflow: hidden;
  height: 100%;
`;
const Header = styled.div`
  height: 40px;
`;

const Content = styled.div`
  height: calc(100% - 40px);
`;

const VisualizationPanel = ({ header, content, ...rest }) => {
  return (
    <VisualizationPanelRoot className="visualization-panel" {...rest}>
      <Header>{header}</Header>
      <Content>{content}</Content>
    </VisualizationPanelRoot>
  );
};

VisualizationPanel.defaultProps = { content: null };

VisualizationPanel.propTypes = {
  header: PropTypes.node.isRequired,
  content: PropTypes.node,
};

export default VisualizationPanel;
