import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useFilterSelectedIds from './useFilterSelectedIds';

const peerSort = field => (a, b) => {
  return a[field] - b[field];
};

export default function useFilteredFundPeers({
  funds,
  vintages,
  regions,
  secondRegions,
  sizes,
  strategies,
  sortField,
}) {
  const peerIdsByFundId = useSelector(state => state.fund.all.peerIdsByFundId);
  const allFunds = useSelector(state => state.fund.all);

  const selectedStrategies = useFilterSelectedIds(strategies, 'strategyId');
  const selectedRegions = useFilterSelectedIds(regions, 'regionId');
  const selectedSecondRegions = useFilterSelectedIds(secondRegions, 'regionId');
  const selectedSizes = useFilterSelectedIds(sizes, 'sizeId');
  const selectedVintages = useFilterSelectedIds(vintages, 'vintageId');

  return useMemo(() => {
    if (funds && peerIdsByFundId) {
      const sort = peerSort(sortField);
      const peers = funds.reduce((accumulator, fund) => {
        const peerVintages = selectedVintages.map(n => fund.vintage + n);
        const filtered = Object.values(peerIdsByFundId[fund.fundId].map(id => allFunds.byId[id]))
          .filter(peer => peerVintages.includes(peer.vintage) || selectedVintages.includes(peer.vintage))
          .filter(peer => selectedRegions.includes(peer.mainRegionId))
          .filter(
            peer =>
              selectedSecondRegions.length === 0 ||
              selectedSecondRegions.includes(peer.otherRegionId ?? peer.mainRegionId) ||
              selectedSecondRegions.includes(peer.mainRegionId)
          )
          .filter(peer => selectedSizes.includes(peer.sizeId))
          .filter(peer => selectedStrategies.includes(peer.subStrategyId))
          .sort(sort);
        accumulator[fund.fundId] = filtered;
        return accumulator;
      }, {});

      return peers;
    }
    return {};
  }, [
    sortField,
    peerIdsByFundId,
    funds,
    allFunds,
    selectedVintages,
    selectedRegions,
    selectedSecondRegions,
    selectedSizes,
    selectedStrategies,
  ]);
}
