import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'src/lib/lodash';
import { pipe } from 'src/utils/fp';
import { fetchFunds } from 'src/store/funds';

function maybeExcludeLastThreeYears(bool) {
  return function (funds) {
    if (!funds) return;
    if (!bool) return funds;
    const cutoff = new Date().getUTCFullYear() - 3;
    return funds.filter(f => f.vintage <= cutoff);
  };
}

function maybeExcludeNullTvpi(bool) {
  return function (funds) {
    if (!funds) return;
    if (!bool) return funds;
    return funds.filter(f => f.tvpi != null);
  };
}

function maybeExcludeNullIrr(bool) {
  return function (funds) {
    if (!funds) return;
    if (!bool) return funds;
    return funds.filter(f => f.irr != null);
  };
}

function maybeExcludeNullCalledPct(bool) {
  return function (funds) {
    if (!funds) return;
    if (!bool) return funds;
    return funds.filter(f => f.calledPct != null);
  };
}

function maybeExcludeZeroCalledPct(bool) {
  return function (funds) {
    if (!funds) return;
    if (!bool) return funds;
    return funds.filter(f => f.calledPct !== 0);
  };
}

function maybeExcludeNullSize(bool) {
  return function (funds) {
    if (!funds) return;
    if (!bool) return funds;
    return funds.filter(f => Number.isFinite(f.size));
  };
}

function maybePickProps(pickProps) {
  return function (funds) {
    if (!funds) return;
    if (!pickProps) return funds;

    const propList = pickProps.filter(Boolean);
    if (propList.length < 1) return funds;
    return funds.map(fund => pick(fund, propList));
  };
}

function useAllFunds({
  excludeLatestThreeYears = false,
  excludeNullTvpi = false,
  excludeNullIrr = false,
  excludeNullCalledPct = false,
  excludeZeroCalledPct = false,
  excludeNullSize = false,
  pickProps,
} = {}) {
  const dispatch = useDispatch();
  const allFunds = useSelector(state => state.fund.all);

  const fundsById = useMemo(() => {
    if (allFunds.fetching) return;
    if (allFunds.fetched) return allFunds.byId;

    dispatch(fetchFunds());
  }, [allFunds.byId, allFunds.fetched, allFunds.fetching, dispatch]);

  return useMemo(() => {
    if (!fundsById) return;

    return pipe(
      maybeExcludeLastThreeYears(excludeLatestThreeYears),
      maybeExcludeNullTvpi(excludeNullTvpi),
      maybeExcludeNullIrr(excludeNullIrr),
      maybeExcludeNullCalledPct(excludeNullCalledPct),
      maybeExcludeZeroCalledPct(excludeZeroCalledPct),
      maybeExcludeNullSize(excludeNullSize),
      maybePickProps(pickProps)
    )(Object.values(fundsById));
  }, [
    fundsById,
    excludeLatestThreeYears,
    excludeNullTvpi,
    excludeNullIrr,
    excludeNullCalledPct,
    excludeZeroCalledPct,
    excludeNullSize,
    pickProps,
  ]);
}

export default useAllFunds;
