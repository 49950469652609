import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconSortNumericDesc(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="m19.4941406 13v8.5605469h-1.4179687v-6.8496094l-2.0917969.7148437v-1.2011718l3.328125-1.2246094zm-10.4941406-8v10.01h3l-4 3.99-4-3.99h3v-10.01zm9.3046875-3.08789062c.859375 0 1.5410156.3203125 2.0449219.9609375.5039062.640625.7558594 1.49804687.7558594 2.57226562v.3984375l-.0046355.30294271c-.0432639 1.39100694-.389375 2.46385417-1.0383333 3.21854167-.6953125.80859372-1.7324219 1.21875002-3.1113281 1.23046872h-.1699219v-1.17773435h.2050781l.2460938-.00922852c.7207031-.04482421 1.2832031-.24389648 1.6875-.59721679.4492187-.39257813.7011719-.99902344.7558593-1.81933594-.4765624.50390625-1.0332031.75585938-1.6699218.75585938-.75 0-1.3476563-.26269532-1.7929688-.78808594-.4453125-.52539063-.6679687-1.21582032-.6679687-2.07128906 0-.5625.1132812-1.07226563.3398437-1.52929688s.5488282-.8125.9667969-1.06640625.9023437-.38085937 1.453125-.38085937zm-.0058594 1.1484375c-.4101562 0-.7373047.16894531-.9814453.50683593-.2441406.33789063-.3662109.76464844-.3662109 1.28027344 0 .53515625.1230469.96679687.3691406 1.29492187s.5742187.4921875.984375.4921875c.2851563 0 .5527344-.08203124.8027344-.24609374s.4433593-.390625.5800781-.6796875v-.55664063l-.0060425-.23007202c-.0281982-.52337647-.1550903-.95254517-.3806763-1.28750611-.2578124-.3828125-.5917968-.57421874-1.0019531-.57421874z" />
    </Svg>
  );
}
