const VIEW_STATE = {
  employeeByTitle: 'employeeByTitle',
  employeeBySchool: 'employeeBySchool',
  employeeByPriorFirm: 'employeeByPriorFirm',
  schoolByFundManager: 'schoolByFundManager',
  priorFirmByFundManager: 'priorFirmByFundManager',
};

const NODE_TYPE = {
  root: 'root',
  title: 'title',
  school: 'school',
  priorFirm: 'priorFirm',
  employee: 'employee',
  fundManager: 'fundmanager',
};

const MAX_FUND_MANAGER_NODE_LENGTH = 75;

const TABLE_WIDTH = {
  narrow: '410px',
  wide: '100%',
};

const OTHER_FUND_MANAGER_IDS = ['xm8WWjdMnudk1DvssIfB'];

export { VIEW_STATE, NODE_TYPE, TABLE_WIDTH, MAX_FUND_MANAGER_NODE_LENGTH, OTHER_FUND_MANAGER_IDS };
