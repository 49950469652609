import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { formatDate } from 'src/utils/date';
import { useLayoutActions } from 'src/hooks';
import { percent, multiple } from 'src/formatters';
import {
  TooltipContent,
  TooltipCloseIcon,
  TooltipRow,
  TooltipData,
  TooltipHeader,
  TooltipTitle,
  TooltipLabel,
  TooltipLink,
} from 'src/components';

const multiple2 = multiple(2);
const percent1 = percent();

const Tooltip = styled.div`
  width: 300px;
  position: absolute;

  left: ${p => p.$offset.left}px;
  top: ${p => p.$offset.top}px;

  transform: translate3d(-50%, -100%, 0);

  &::before {
    content: '⬥';
    font-size: 3em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 50%, 0);
    color: #eeeeee;
  }
`;

function FundSummaryScatter({
  className,
  fund,

  parentEl,
  targetEl,
  onClose,
}) {
  const [offset, setOffset] = useState({
    top: 0,
    left: 0,
  });

  useEffect(
    function () {
      if (!parentEl) return;
      if (!targetEl) return;

      const { top: offsetParentTop, left: offsetParentLeft } = parentEl.getBoundingClientRect();

      const { top: offsetChildTop, left: offsetChildLeft } = targetEl.getBoundingClientRect();

      const top = offsetChildTop - offsetParentTop + 26;
      const left = offsetChildLeft - offsetParentLeft + 46 / 2;

      setOffset({ top, left });
    },
    [parentEl, targetEl]
  );

  const { collapsePanel } = useLayoutActions();

  function handleTooltipClose() {
    onClose(null);
  }

  const handleViewFund = useCallback(
    function (newFundId) {
      collapsePanel();
      navigate(`/funds/${newFundId}`);
    },
    [collapsePanel]
  );

  if (!fund) return null;

  return (
    <Tooltip $offset={offset} className={className}>
      <TooltipContent>
        <TooltipHeader>
          <TooltipTitle>{fund.name}</TooltipTitle>
          <TooltipCloseIcon margin="0 0 0 8px" onClick={handleTooltipClose} />
        </TooltipHeader>
        <TooltipRow>
          <TooltipLabel>YEAR</TooltipLabel>
          <TooltipData>{fund.vintage}</TooltipData>
        </TooltipRow>
        <TooltipRow>
          <TooltipLabel>STRATEGY</TooltipLabel>
          <TooltipData>{fund.strategy}</TooltipData>
        </TooltipRow>
        <TooltipRow>
          <TooltipLabel>TVPI</TooltipLabel>
          <TooltipData>{multiple2(fund.tvpi)}</TooltipData>
        </TooltipRow>
        <TooltipRow>
          <TooltipLabel>IRR</TooltipLabel>
          <TooltipData>{percent1(fund.irr)}</TooltipData>
        </TooltipRow>
        <TooltipRow>
          <TooltipLabel>LATEST REPORTING DATE</TooltipLabel>
          <TooltipData>{formatDate()(fund.date, { utc: true })}</TooltipData>
        </TooltipRow>
        {fund.foiaSourceAcronym && (
          <TooltipRow>
            <TooltipLabel>DATA SOURCE</TooltipLabel>
            <TooltipData>{fund.foiaSourceAcronym}</TooltipData>
          </TooltipRow>
        )}

        <TooltipRow marginTop="10px">
          <TooltipLink
            href="#"
            onClick={e => {
              e.preventDefault();
              handleViewFund(fund.fundId);
            }}
          >
            View fund
          </TooltipLink>
        </TooltipRow>
      </TooltipContent>
    </Tooltip>
  );
}

FundSummaryScatter.defaultProps = {};

FundSummaryScatter.propTypes = {
  fund: PropTypes.object,
  parentEl: PropTypes.instanceOf(Element),
  targetEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func,
};

export default FundSummaryScatter;
