import { chunk } from 'src/lib/lodash';
import { db } from 'src/config/firebase';

const dbUserFundFinancialHistory = db.collection('userFundFinancialHistory');

async function getUserFundFinancialHistory({ fundId, userId }) {
  return (
    await dbUserFundFinancialHistory
      .orderBy('date', 'desc')
      .where('userId', '==', userId)
      .where('fundId', '==', fundId)
      .get()
  ).docs.map(d => d.data());
}

async function deleteUserFundFinancialHistory({ userId, fundId }) {
  const refs = await dbUserFundFinancialHistory
    .orderBy('date', 'desc')
    .where('userId', '==', userId)
    .where('fundId', '==', fundId)
    .get();
  const batch = db.batch();
  refs.forEach(doc => batch.delete(doc.ref));
  return batch.commit();
}

async function setUserFundFinancialHistory({ userId, fundId, fundFinancialHistory }) {
  const now = new Date().toISOString();
  const updatedHistory = fundFinancialHistory.map(item => ({
    ...item,
    userId,
    fundId,
    lastUpdateDate: now,
  }));

  for (const item of updatedHistory) {
    const docId = `${userId}_${fundId}_${item.date}`;
    if (item.deleted) {
      await dbUserFundFinancialHistory.doc(docId).delete();
    } else {
      await dbUserFundFinancialHistory.doc(docId).set(item);
    }
  }

  return updatedHistory.filter(d => !d.deleted);
}

async function getUserFundsFinancialHistory({ fundIds, userId }) {
  const fundIdChunks = chunk(fundIds, 10);
  const fundFinancialHistoryChunks = await Promise.all(
    fundIdChunks.map(fundChunkIds =>
      dbUserFundFinancialHistory
        .where('fundId', 'in', fundChunkIds)
        .where('userId', '==', userId)
        .orderBy('date', 'desc')
        .get()
    )
  );
  return fundFinancialHistoryChunks
    .map(historyChunk => historyChunk.docs.map(doc => doc.data()))
    .reduce((acc, history) => [...acc, ...history], []);
}

export { getUserFundFinancialHistory };
export { deleteUserFundFinancialHistory };
export { setUserFundFinancialHistory };
export { getUserFundsFinancialHistory };
