import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'src/components';

function FormSubmitButton({ isSubmitting, children, ...rest }) {
  return (
    <Button $isLoading={isSubmitting} {...rest}>
      {children}
    </Button>
  );
}

FormSubmitButton.defaultProps = {
  children: 'Save Changes',
  onClick: () => {},
  disabled: false,
  isSubmitting: false,
  variant: 'contained',
  color: 'primary',
  type: 'submit',
  disableElevation: true,
};

FormSubmitButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default FormSubmitButton;
