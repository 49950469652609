import { useEffect, useState, useCallback } from 'react';

function fib(n, a = 1, b = 0) {
  if (n === 0) return b;
  return fib(n - 1, b, a + b);
}

function useExponentialBackoff(min = 500, max = 5000, callback) {
  const [index, setIndex] = useState(0);
  const handleCallback = useCallback(() => callback(), [callback]);
  const wait = fib(index) * min;

  useEffect(() => {
    if (wait >= max) return;

    const timer = setTimeout(() => {
      handleCallback();
      setIndex(c => c + 1);
    }, wait);

    return () => clearTimeout(timer);
  }, [index, handleCallback, max, min, wait]);

  return [index, wait];
}

export default useExponentialBackoff;
