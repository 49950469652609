import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from 'src/services';
import { subtractDays } from 'src/utils/date';

const fetchPortfolioNewsPending = createAction('FETCH_PORTFOLIO_NEWS_PENDING');
const fetchPortfolioNewsError = createAction('FETCH_PORTFOLIO_NEWS_ERROR');
const fetchPortfolioNewsSuccess = createAction('FETCH_PORTFOLIO_NEWS_SUCCESS');

export function fetchPortfolioNews({ fundManagerIds }) {
  return async function (dispatch, getState) {
    try {
      const { fetching, fetched } = getState().news.portfolio;
      if (fetching || fetched) return;
      dispatch(fetchPortfolioNewsPending());
      const articles = await filterApi.getNewsByFundManagers({
        fundManagerIds,
        minDateTime: subtractDays(7)(new Date()).toISOString(),
      });
      dispatch(fetchPortfolioNewsSuccess({ articles }));
    } catch (error) {
      dispatch(fetchPortfolioNewsError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  articles: null,
};

export default createReducer(initialState, {
  [fetchPortfolioNewsPending]: state => {
    state.fetching = true;
  },
  [fetchPortfolioNewsError]: (state, action) => {
    const { error } = action.payload;
    state.fetching = true;
    state.error = error;
    console.error(error);
  },
  [fetchPortfolioNewsSuccess]: (state, action) => {
    const { articles } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.articles = articles;
  },
});
