import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { getLastQuarter } from 'src/utils/date';
import { useExportCsvCallback, useCurrentUser } from 'src/hooks';
import { CashflowDataVisualization } from 'src/components';

const { TYPES, VIEWS } = CashflowDataVisualization.CONSTANTS;

const ChartBlock = styled.div`
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CSV_DISCLAIMER = `"Information displayed on this page is based on models and/or tools which use publicly available historical information to predict future outcomes or results. These models and/or tools are intended for informational use only, and you acknowledge and agree that FundFilter (or its owner Altman Inc) cannot and does not guarantee any particular outcomes or results, and that past or historical results are not indicative of and do not guarantee future performance, outcomes or results. Always obtain relevant and specific independent professional advice before making any investment or other decision."`;

function ForecastChart({
  type,
  view,
  data,
  navTarget,
  startDate,
  className,
  downloadType,
  onDownloadComplete,
  downloadFilename,
  margin,
}) {
  const currentUser = useCurrentUser();
  const [tableData, setTableData] = useState([]);

  const handleDownloadCsv = useExportCsvCallback({
    filename: downloadFilename,
    title: [
      ['FundFilter', CSV_DISCLAIMER],
      [`USER: ${currentUser?.name}`, `DATE: ${new Date().toISOString()}`],
    ],
  });

  useEffect(() => {
    if (downloadType !== type) return;
    handleDownloadCsv(tableData);
    onDownloadComplete();
  }, [downloadType, handleDownloadCsv, onDownloadComplete, tableData, type]);

  return (
    <CashflowDataVisualization
      type={type}
      view={view}
      forecastTimeseries={data}
      navTarget={navTarget}
      startDate={startDate}
      margin={margin}
      css={`
        background-color: #1c1c1c;
        flex: 1;
      `}
      onChangeTableRows={setTableData}
      className={className}
    />
  );
}

ForecastChart.ChartBlock = ChartBlock;

ForecastChart.defaultProps = {
  data: [],
  view: VIEWS.CHART,
  startDate: getLastQuarter(),
  onClickExpand: funcStub('onClickExpand'),
  isExpandable: true,
};

ForecastChart.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  view: PropTypes.oneOf(Object.values(VIEWS)).isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  navTarget: PropTypes.number,
  onClickExpand: PropTypes.func,
};

export default ForecastChart;
