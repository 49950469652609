import React from 'react';
import PropTypes, { arrayOf, oneOfType, node } from 'prop-types';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';

const ITEM_PADDING = 8;
const ITEM_HEIGHT = 38;

const ListBox = styled.div``;

function renderRow(props) {
  const { data, index, style } = props;

  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + ITEM_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const { onScroll, ...outerProps } = React.useContext(OuterElementContext);
  return <ListBox ref={ref} onWheel={onScroll} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent({ children, ...rest }, ref) {
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={rest}>
        <FixedSizeList
          itemData={itemData}
          height={315}
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={ITEM_HEIGHT}
          overscanCount={5}
          itemCount={itemData.length}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.defaultProps = {
  children: null,
  onScroll: () => console.info('TODO: handle onScroll'),
};

ListboxComponent.propTypes = {
  children: oneOfType([arrayOf(PropTypes.node), node]),
};

export default ListboxComponent;
