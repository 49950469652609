import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import { upperCase, currency, abbreviations } from '../../../formatters';
import BaseVirtualTable from '../VirtualizedTable';

const millions = currency(abbreviations.million)();

//#region listdefs
const listDefWide = [
  {
    width: 200,
    sortable: true,
    headerStyle: { padding: '0 0 0 12px' },
    cellStyle: { padding: '0 0 0 12px', color: '#0d8bab' },
    property: 'name',
    key: 'name',
    label: upperCase('fund name'),
    isNumeric: false,
  },
  {
    width: 70,
    sortable: true,
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    property: 'vintage',
    key: 'vintage',
    label: upperCase('vintage'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 80,
    sortable: true,
    headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'size',
    key: 'size',
    formatter: millions,
    label: upperCase('fund size'),
    align: 'right',
    isNumeric: true,
  },
  /* {
    width: 100,
    sortable: true,
    headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'targetSize',
    key: 'targetSize',
    formatter: millions,
    label: upperCase('target amount'),
    align: 'right',
    isNumeric: true,
  }, */
  {
    width: 150,
    sortable: true,
    //headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    //cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'strategyName1',
    key: 'strategyName1',
    label: upperCase('strategy'),
    isNumeric: false,
  },
  /* {
    width: 150,
    sortable: true,
    // headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    // cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'regionName1',
    key: 'regionName1',
    label: upperCase('region'),
    isNumeric: false,
  }, */
  {
    width: 150,
    sortable: true,
    // headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    // cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'fundStatus',
    key: 'fundStatus',
    label: upperCase('status'),
    isNumeric: false,
  },
];

//#endregion

const VirtualizedTable = styled(BaseVirtualTable)`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  padding: ${props => props.padding || '10px'};
`;

function FundsRaisingTable({
  data,
  expanded,
  theme: _theme,
  enableHover,
  rowColor,
  rowBackgroundColor,
  rowHoverColor,
  rowHoverBackgroundColor,
  onListDefCreated,
  onColumnClick,
  onColumnMouseOver,
  onColumnMouseOut,
  onRowClick,
  onScroll,
  ...rest
}) {
  const [listDef, setListDef] = useState(null);

  useEffect(() => {
    onListDefCreated(listDefWide);
    // give the parent component a chance to update the listDef
    setListDef(listDefWide);
  }, [expanded, onListDefCreated]);

  return (
    <VirtualizedTable
      data={data}
      listDef={listDef}
      onColumnClick={onColumnClick}
      onColumnMouseOver={onColumnMouseOver}
      onColumnMouseOut={onColumnMouseOut}
      onRowClick={onRowClick}
      onScroll={onScroll}
      enableHover={enableHover}
      rowColor={rowColor}
      rowBackgroundColor={rowBackgroundColor}
      rowHoverColor={rowHoverColor}
      rowHoverBackgroundColor={rowHoverBackgroundColor}
      {...rest}
    />
  );
}

FundsRaisingTable.defaultProps = {
  data: [],
  expanded: false,
  enableHover: false,
  listDefWide,
  rowColor: null,
  rowBackgroundColor: null,
  rowHoverColor: null,
  rowHoverBackgroundColor: null,
  onListDefCreated: () => {},
  onColumnClick: () => {},
  onColumnMouseOver: () => {},
  onColumnMouseOut: () => {},
  onRowClick: () => {},
  onScroll: () => {},
};

FundsRaisingTable.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  enableHover: PropTypes.bool,
  listDefWide: PropTypes.array,
  rowColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onListDefCreated: PropTypes.func,
  onColumnClick: PropTypes.func,
  onColumnMouseOver: PropTypes.func,
  onColumnMouseOut: PropTypes.func,
  onRowClick: PropTypes.func,
  onScroll: PropTypes.func,
};

export default withTheme(FundsRaisingTable);
