import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from 'src/services';
import { auth } from 'src/services/filter-api/authentication';

const fetchFundManagerPending = createAction('FETCH_MANAGER_PENDING');
const fetchFundManagerError = createAction('FETCH_MANAGER_ERROR');
const fetchFundManagerSuccess = createAction('FETCH_MANAGER_SUCCESS');
const fetchFundManagersPending = createAction('FETCH_MANAGERS_PENDING');
const fetchFundManagersError = createAction('FETCH_MANAGERS_ERROR');
const fetchFundManagersSuccess = createAction('FETCH_MANAGERS_SUCCESS');

const setUserFundManagerPending = createAction('ADD_USER_FUND_MANAGER_PENDING');
const setUserFundManagerError = createAction('ADD_USER_FUND_MANAGER_ERROR');
export const setUserFundManagerSuccess = createAction('ADD_USER_FUND_MANAGER_SUCCESS');

function isUserFundManager(fundManagerId) {
  return fundManagerId.substr(0, 2) === 'u_';
}

export function fetchFundManager({ fundManagerId }) {
  return async function (dispatch, getState) {
    const { byId } = getState().fundManager.all;
    const user = auth.currentUser;
    if (!user) return;
    if (byId[fundManagerId]) return;
    dispatch(fetchFundManagerPending({ fundManagerId }));
    try {
      let fundManager;
      if (!isUserFundManager(fundManagerId)) {
        fundManager = await filterApi.getFundManager({ fundManagerId });
      } else {
        fundManager = await filterApi.getUserFundManager({
          userId: user.uid,
          fundManagerId,
        });
      }

      dispatch(fetchFundManagerSuccess({ fundManager }));
    } catch (error) {
      console.error(error);
      dispatch(fetchFundManagerError({ error: error.message, fundManagerId }));
    }
  };
}

export function fetchFundManagers({ fundManagerIds }) {
  return async function (dispatch, getState) {
    const { byId } = getState().fundManager.all;
    const user = auth.currentUser;

    const notAlreadyFetching = fundManagerIds.filter(fundManagerId => !byId[fundManagerId]);

    const needsFetch = notAlreadyFetching.length > 0;

    if (!user) return;
    if (!needsFetch) return;

    dispatch(fetchFundManagersPending({ fundManagerIds: notAlreadyFetching }));

    try {
      const systemFundManagerIds = notAlreadyFetching.filter(id => !isUserFundManager(id));
      const userFundManagerIds = notAlreadyFetching.filter(id => isUserFundManager(id));
      const fundManagers = await filterApi.getFundManagers({
        fundManagerIds: systemFundManagerIds,
      });
      dispatch(fetchFundManagersSuccess({ fundManagers }));
      const userFundManagers = await filterApi.getUserFundManagersById({
        fundManagerIds: userFundManagerIds,
        userId: user.uid,
      });
      dispatch(fetchFundManagersSuccess({ fundManagers: userFundManagers }));
    } catch (error) {
      dispatch(
        fetchFundManagersError({
          fundManagerIds,
          error: error.message,
        })
      );
    }
  };
}

export function setUserFundManager({ fundManager }) {
  return async function (dispatch) {
    const { fundManagerId } = fundManager;
    const user = auth.currentUser;
    if (!user) return;
    try {
      dispatch(setUserFundManagerPending({ fundManagerId }));
      const updated = await filterApi.setUserFundManager({
        userId: user.uid,
        ...fundManager,
      });
      dispatch(setUserFundManagerSuccess({ fundManager: updated }));
    } catch (error) {
      dispatch(
        setUserFundManagerError({
          error: error.message,
          fundManagerId,
        })
      );
    }
  };
}

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [fetchFundManagerPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    const manager = state.byId[fundManagerId] || {};
    manager.fetching = true;
    state.byId[fundManagerId] = manager;
  },
  [fetchFundManagerError]: (state, action) => {
    const { error, fundManagerId } = action.payload;
    const manager = state.byId[fundManagerId] || {};
    manager.fetching = false;
    manager.error = error;
    state.byId[fundManagerId] = manager;
    console.error(error);
  },
  [fetchFundManagerSuccess]: (state, action) => {
    const { fundManager } = action.payload;
    fundManager.error = null;
    fundManager.fetching = false;
    fundManager.fetched = true;
    state.byId[fundManager.fundManagerId] = fundManager;
  },

  [fetchFundManagersPending]: (state, action) => {
    const { fundManagerIds } = action.payload;
    fundManagerIds.forEach(fundManagerId => {
      const manager = state.byId[fundManagerId] || {};
      if (!manager.fetching) {
        manager.fetching = true;
        manager.fundManagerId = fundManagerId;
        state.byId[fundManagerId] = manager;
      }
    });
  },
  [fetchFundManagersError]: (state, action) => {
    const { fundManagerIds, error } = action.payload;
    fundManagerIds.forEach(fundManagerId => {
      const manager = state.byId[fundManagerId] || {};
      manager.fetching = false;
      manager.error = error;
      manager.fundManagerId = fundManagerId;
      state.byId[fundManagerId] = manager;
      console.error(error);
    });
  },

  [fetchFundManagersSuccess]: (state, action) => {
    const { fundManagers } = action.payload;
    fundManagers.forEach(fundManager => {
      state.byId[fundManager.fundManagerId] = {
        ...fundManager,
        fetching: false,
        fetched: true,
        error: null,
      };
    });
  },

  [setUserFundManagerPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    const manager = state.byId[fundManagerId] || {};
    manager.fetching = true;
    state.byId[fundManagerId] = manager;
  },
  [setUserFundManagerError]: (state, action) => {
    const { fundManagerId, error } = action.payload;
    const manager = state.byId[fundManagerId] || {};
    manager.error = error;
    manager.fetching = false;
    manager.fetched = false;
    state.byId[fundManagerId] = manager;
    console.error(error);
  },
  [setUserFundManagerSuccess]: (state, action) => {
    const { fundManager } = action.payload;
    fundManager.error = null;
    fundManager.fetching = false;
    fundManager.fetched = true;
    state.byId[fundManager.fundManagerId] = fundManager;
  },
});
