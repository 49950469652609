import { useEffect, useState, useCallback } from 'react';
import { sizes } from 'src/lookup/sizes';

const useSizesFilterDefaultOptions = {
  coreAttributeSizeIds: null,
  defaultSelectedSizeIds: [1, 2, 3],
};

/**
 * @param defaultSelectedIds: memoized:Array[<sizeId>]
 * @example useSizesFilter(useMemo(() => [2], []))
 */
function useSizesFilter(options) {
  const { coreAttributeSizeIds, defaultSelectedSizeIds } = {
    ...useSizesFilterDefaultOptions,
    ...options,
  };

  const [state, setState] = useState();

  const getDefaultSelectedState = useCallback(
    sizeId => {
      if (!defaultSelectedSizeIds) return true;
      return defaultSelectedSizeIds.includes(sizeId);
    },
    [defaultSelectedSizeIds]
  );

  useEffect(() => {
    if (!sizes) return;

    return setState(
      sizes.map(({ label, sizeId, ...rest }) => ({
        ...rest,
        label: coreAttributeSizeIds?.includes(sizeId) ? `${label} *` : label,
        sizeId,
        disabled: false,
        selected: getDefaultSelectedState(sizeId),
      }))
    );
  }, [getDefaultSelectedState, coreAttributeSizeIds]);

  return [state, setState];
}

export default useSizesFilter;
