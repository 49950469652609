import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function IconUnlock(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <title>Lock Closed</title>
      <path d="M18,9 L17,9 L17,7 C17,4.24 14.76,2 12,2 C9.24,2 7,4.24 7,7 L7,9 L6,9 C4.9,9 4,9.9 4,11 L4,21 C4,22.1 4.9,23 6,23 L18,23 C19.1,23 20,22.1 20,21 L20,11 C20,9.9 19.1,9 18,9 Z M15.1,9 L8.9,9 L8.9,7 C8.9,5.29 10.29,3.9 12,3.9 C13.71,3.9 15.1,5.29 15.1,7 L15.1,9 Z" />
    </Svg>
  );
}

export default IconUnlock;
