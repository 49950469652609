import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
  fill: none;
`;

function IconUnlock(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      {...props}
    >
      <title>Lock Closed</title>
      <path d="M336 208v-95a80 80 0 00-160 0v95" />
      <rect x="96" y="208" width="320" height="272" rx="48" ry="48" />
    </Svg>
  );
}

export default IconUnlock;
