import { db, firestoreTimestamp, firestoreFieldValue } from 'src/config/firebase';
const dbUserFunds = db.collection('userFunds');
const dbUserPortfolios = db.collection('userPortfolios');

async function getUserFunds({ userId }) {
  return (await dbUserFunds.where('userId', '==', userId).orderBy('name', 'asc').get()).docs.map(d => d.data());
}

async function getUserFund({ userId, fundId }) {
  return (await dbUserFunds.doc(`${userId}_${fundId}`).get()).data();
}

async function deleteUserFund({ userId, fundId }) {
  await dbUserFunds.doc(`${userId}_${fundId}`).delete();

  try {
    const snapshot = await dbUserPortfolios.where('userId', '==', userId).get();

    if (snapshot.empty) return;

    for (const doc of snapshot.docs) {
      await dbUserPortfolios.doc(doc.id).update({
        [`bookmarks.fundId_${fundId}`]: firestoreFieldValue.delete(),
      });
    }
  } catch (e) {
    console.warn('There was a problem deleting a bookmark to a user fund');
    console.error(e);
  }
}

async function setUserFund({
  date,
  userId,
  fundId,
  fundManagerId,
  fundManagerName,
  name,
  vintage,
  size,
  calledPct,
  tvpi,
  irr,
  rvpi,
  dpi,
  strategyId,
  subStrategyId,
  subStrategy,
  strategy,
  mainRegionName,
  mainRegionId,
}) {
  const now = new Date().toISOString();
  const fund = {
    date,
    userId,
    fundId,
    fundManagerId,
    fundManagerName,
    name,
    vintage,
    tvpi,
    irr,
    rvpi,
    dpi,
    calledPct,
    strategyId,
    subStrategyId,
    subStrategy,
    strategy,
    mainRegionName,
    mainRegionId,
    size,
    lastUpdate: now,
    updatedAt: firestoreTimestamp(),
  };
  await dbUserFunds.doc(`${userId}_${fundId}`).set(fund);
  return fund;
}

export { getUserFunds };
export { getUserFund };
export { deleteUserFund };
export { setUserFund };
