import React from 'react';
import styled from 'styled-components';
import IconScatterPlot from '../IconScatterPlot';
import ButtonRounded from '../ButtonRounded';

const StyledButtonRounded = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    left: 2px;
  }
`;

const ButtonPlot = props => {
  return (
    <StyledButtonRounded startIcon={<IconScatterPlot />} {...props}>
      Plot
    </StyledButtonRounded>
  );
};

ButtonPlot.defaultProps = {};

ButtonPlot.propTypes = {};

export default ButtonPlot;
