import React from 'react';
import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md, lg } from 'src/config/layout';
import {
  useFundManagerFunds,
  useFundManagerFundsRaising,
  useFundManagerFundsInMarketSize,
  useLayoutActions,
} from 'src/hooks';
import {
  AppPanel,
  FundsRaisedTotals,
  FundsRaisedTooltip,
  UserFundOrManagerMessage,
  StackedBarChart,
  SimpleChartLegend,
} from 'src/components';
import CurrentFundPerformanceTable from './CurrentFundPerformanceTable';

const SectionTitle = styled.h3`
  color: #808080;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
`;

const FundTablesSection = styled.div`
  display: none;
  flex-direction: column;
  flex: 1;

  @media (min-height: ${lg.V_BREAKPOINT}px) {
    display: flex;
    display: ${p => (p.$hidden ? 'none' : null)};
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    display: flex;
    display: ${p => (p.$hidden ? 'none' : null)};
    flex-direction: row;
  }
`;

const Empty = styled.div`
  height: ${props => props.height || '100%'};
  display: grid;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  flex: 1;

  & > div {
    max-width: 300px;
  }
`;

export function FundManagerfundRaisingVisualization({ fundManager }) {
  const { isExpanded } = useContext(AppPanel.Context);

  const { fundManagerId } = fundManager;
  const fundManagerFunds = useFundManagerFunds({
    fundManagerId,
  });
  const fundManagerFundsRaising = useFundManagerFundsRaising({
    fundManagerId,
  });
  const fundManagerFundsInMarket = useFundManagerFundsInMarketSize({
    fundManagerId,
  });

  const strategiesData = useMemo(() => {
    if (!fundManagerFundsInMarket) return [];

    return (
      fundManagerFundsInMarket
        // Don't include anything below 2008
        .filter(d => +d.year >= 2008)
        .flatMap(({ year, strategies }) => {
          return strategies.map(strategy => {
            return {
              ...strategy,
              x: +year,
              y: strategy.size,
              z: strategy.strategyId,
              zLabel: strategy.strategyName,
            };
          });
        })
    );
  }, [fundManagerFundsInMarket]);

  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [tableFunds, setTableFunds] = useState([]);

  useEffect(() => {
    if (!fundManagerFunds) return;
    if (!fundManagerFundsRaising) return;

    const fundIds = fundManagerFunds.map(f => f.fundId);
    let timer;

    if (timer) return;

    timer = setTimeout(() => {
      setTableFunds(
        [...fundManagerFunds, ...fundManagerFundsRaising.filter(fund => !fundIds.includes(fund.fundId))].filter(
          f => f.vintage >= 2008
        )
      );
    }, 500);

    return () => clearTimeout(timer);
  }, [fundManagerFundsRaising, fundManagerFunds]);

  function closeTooltip() {
    setTooltipAnchorEl(null);
  }

  function handleClickAway(e) {
    if (!e.target) return closeTooltip();
    if (e.target.classList.contains('bar')) return;
    if (e.target.classList.contains('arc')) return;
    closeTooltip();
  }

  const { expandPanel } = useLayoutActions();

  const empty = fundManagerFundsInMarket && fundManagerFundsInMarket.length < 1;

  const handleItemHoverIn = useCallback(
    (node, item) => {
      if (!isExpanded) return;
      setTooltipAnchorEl(node);
      setTooltipData({
        size: item.size || item.y,
        label: item.label || item.zLabel,
        year: item.x,
      });
    },
    [isExpanded]
  );

  const handleItemHoverOut = useCallback(() => {
    setTooltipAnchorEl(null);
    setTooltipData(null);
  }, []);

  const handleSetupLegend = useCallback(({ legendData: data }) => {
    setLegendData(data);
  }, []);

  const [clear, setClear] = useState(false);

  useEffect(() => {
    if (!clear) return;

    setTimeout(() => {
      setClear(false);
    }, 1000);
  }, [clear]);

  const [legendData, setLegendData] = useState([]);

  if (!fundManager) return null;

  if (empty) {
    return (
      <Empty>
        <div>
          <p>There is currently no fund raising data available for this fund&nbsp;manager</p>
        </div>
      </Empty>
    );
  }

  if (fundManager.userId)
    return (
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        `}
      >
        <UserFundOrManagerMessage label="fund manager" />
      </div>
    );

  return (
    <>
      <div
        css={`
          display: grid;

          @media (min-width: ${isExpanded ? 0 : 99999}px) {
            gap: 1.5rem;
            grid-template-rows: 1fr 1fr;
          }
        `}
      >
        <div
          css={`
            display: grid;
            grid-template-rows: 1fr auto;
          `}
        >
          <div
            onClick={() => !isExpanded && expandPanel()}
            css={`
              display: grid;

              @media (min-width: ${isExpanded ? 0 : 99999}px) {
                grid-template-columns: 3fr 1fr;
                grid-template-rows: 1fr;
                gap: 2rem;
              }
            `}
          >
            <div
              css={`
                display: grid;
                grid-template-rows: auto;

                @media (min-width: ${isExpanded ? 0 : 99999}px) {
                  grid-template-rows: auto 1fr;
                }
              `}
            >
              <SectionTitle
                css={`
                  display: ${!isExpanded && 'none'};
                `}
              >
                Funds Raised Over Time
              </SectionTitle>
              <StackedBarChart
                marginLeft={80}
                onDraw={handleSetupLegend}
                data={strategiesData}
                expanded={isExpanded}
                onItemMouseOver={handleItemHoverIn}
                onItemMouseOut={handleItemHoverOut}
                yAxisLabel="FUNDS RAISED"
              />
            </div>

            <div
              css={`
                display: ${isExpanded ? 'grid' : 'none'};
              `}
            >
              <SectionTitle>Funds Raised by Strategy</SectionTitle>
              <FundsRaisedTotals
                data={legendData}
                onItemClick={handleItemHoverIn}
                onItemTouchStart={handleItemHoverIn}
                onItemOver={handleItemHoverIn}
                onItemOut={handleItemHoverOut}
              />
            </div>
          </div>

          <SimpleChartLegend
            data={legendData}
            css={`
              margin-top: 0.5rem;
            `}
          />
        </div>

        <FundTablesSection $hidden={!isExpanded || empty}>
          <CurrentFundPerformanceTable funds={tableFunds} />
        </FundTablesSection>
      </div>

      <FundsRaisedTooltip
        onClickAway={handleClickAway}
        data={tooltipData}
        open={Boolean(tooltipAnchorEl)}
        anchorEl={tooltipAnchorEl}
      />
    </>
  );
}

FundManagerfundRaisingVisualization.defaultProps = {};

FundManagerfundRaisingVisualization.propTypes = {
  fundManager: PropTypes.object.isRequired,
};

export default FundManagerfundRaisingVisualization;
