import { db, firestoreTimestamp } from 'src/config/firebase';

const dbBookmarks = db.collection('bookmarks');

async function getBookmarks({ userId }) {
  const result = await dbBookmarks.where('userId', '==', userId).get();
  return result.docs.map(d => d.data());
}

async function getBookmarksByTags({ userId, tags = [] }) {
  const result = await dbBookmarks.where('userId', '==', userId).where('tags', 'array-contains-any', tags).get();

  return result.docs.map(d => d.data());
}

async function getFundBookmarks({ userId, tags = [] }) {
  let query = dbBookmarks.where('userId', '==', userId);

  if (tags.length > 0) {
    query = query.where('tags', 'array-contains-any', tags);
  }

  const result = await query.get();
  return result.docs.map(d => d.data());
}

async function getFundBookmark({ userId, fundId }) {
  const doc = dbBookmarks.doc(`${userId}_${fundId}`);
  const result = await doc.get();
  return result.data();
}

async function getEmployeeBookmarks({ userId }) {
  const result = await dbBookmarks.where('userId', '==', userId).where('employeeId', '>', '').get();
  return result.docs.map(d => d.data());
}

async function setFundBookmark({ userId, fundId, ...data }) {
  const createdAt = firestoreTimestamp();
  const bookmarkId = `${userId}_${fundId}`;

  return dbBookmarks.doc(bookmarkId).set({ bookmarkId, userId, fundId, createdAt, ...data });
}

async function updateFundBookmark({ userId, fundId, ...rest }) {
  const bookmarkId = `${userId}_${fundId}`;
  const docRef = dbBookmarks.doc(bookmarkId);
  const updatedAt = firestoreTimestamp();
  const data = { bookmarkId, userId, fundId, updatedAt, ...rest };

  try {
    return await docRef.update(data);
  } catch (error) {
    // Permission denied in this context could mean the document
    // doesn't exist, so let's try that before bailing.
    if (error.code === 'permission-denied') return await docRef.set(data);
    throw error;
  }
  // return docRef.update({ userId, fundId, ...rest });
}

async function setEmployeeBookmark({ userId, employeeId }) {
  return dbBookmarks.doc(`${userId}_${employeeId}`).set({ userId, employeeId });
}

async function deleteFundBookmark({ userId, fundId }) {
  return dbBookmarks.doc(`${userId}_${fundId}`).delete();
}

async function deleteEmployeeBookmark({ userId, employeeId }) {
  return dbBookmarks.doc(`${userId}_${employeeId}`).delete();
}

export { getBookmarks };
export { getFundBookmarks };
export { getFundBookmark };
export { setFundBookmark };
export { updateFundBookmark };
export { getEmployeeBookmarks };
export { setEmployeeBookmark };
export { deleteFundBookmark };
export { deleteEmployeeBookmark };
export { getBookmarksByTags };
