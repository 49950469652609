import { strings } from '../strings';

export const lookup = {
  timeRange: {
    oneQuarter: {
      label: strings.labels.oneQuarter,
      value: 3,
    },
    threeQuarters: {
      label: strings.labels.threeQuarters,
      value: 9,
    },
    oneYear: {
      label: strings.labels.oneYear,
      value: 12,
    },
    threeYears: {
      label: strings.labels.threeYears,
      value: 36,
    },
    /* fiveYears: {
      label: strings.labels.fiveYears,
      value: 60,
    }, */
  },
  newsTimeRange: {
    oneDay: {
      label: strings.labels.oneDay,
      value: 0,
    },
    oneMonth: {
      label: strings.labels.oneMonth,
      value: 1,
    },
    sixMonths: {
      label: strings.labels.sixMonths,
      value: 6,
    },
    oneYear: {
      label: strings.labels.oneYear,
      value: 12,
    },
  },
  financialMetric: {
    tvpi: {
      label: strings.labels.tvpi,
      labelChart: strings.labels.tvpix,
      key: 'tvpi',
      value: 0,
    },
    irr: {
      label: strings.labels.irr,
      labelChart: strings.labels.netIrrPct,
      key: 'irr',
      value: 1,
    },
    pme: {
      label: 'pme',
      labelChart: strings.labels.pme,
      key: {
        ksPmeValue: 'ksPmeValue',
        daPmeValue: 'daPmeValue',
      },
      keys: ['ksPmeValue', 'daPmeValue'],
      value: 2,
      isPme: true,
    },
  },
  pmeCalcs: {
    ks: {
      label: 'KS PME',
      key: 'ksPmeValue',
    },
    da: {
      label: 'Direct Alpha PME',
      key: 'daPmeValue',
    },
  },
  sizes: {
    lessThan500Mn: {
      label: '< $0.5Bn',
      value: 1,
      from: 0,
      to: 499999999,
    },
    point5BnTo1Point5Bn: {
      label: '$0.5Bn - $1.5Bn',
      value: 2,
      from: 500000000,
      to: 1499999999,
    },
    onePoint5BnTo5Bn: {
      label: '$1.5Bn - $5Bn',
      value: 3,
      from: 1500000000,
      to: 4999999999,
    },
    greaterThen5Bn: {
      label: '> 5Bn',
      value: 4,
      from: 5000000000,
      to: 10e20 - 1,
    },
  },
  sentiments: {
    negative: { color: '#ff5c00', value: -1, label: 'Negative' },
    neutral: {
      color: '#4e4e4e',
      colorScatter: '#4e4e4e1f',
      value: 0,
      label: 'Neutral',
    },
    positive: { color: '#68c11a', value: 1, label: 'Positive' },
  },
};

export function getLookup(type, value, key = 'value') {
  if (type) {
    const entries = Object.entries(type);
    if (entries) {
      const lookupObj = entries.find(e => e[1][key] === value);
      if (lookupObj) {
        return lookupObj[1];
      }
    }
  }
  return null;
}
