import { upperCase, currency, abbreviations } from 'src/formatters';
import { formatDateCompact } from 'src/utils/date';

const millions = currency(abbreviations.million)();

const listDefNarrow = [
  {
    width: 200,
    sortable: true,
    headerStyle: { padding: '0 0 0 12px' },
    cellStyle: { padding: '0 0 0 12px', color: '#0d8bab' },
    property: 'name',
    key: 'name',
    label: upperCase('fund name'),
    isNumeric: false,
  },
  {
    width: 200,
    sortable: true,
    headerStyle: { padding: '0 0 0 12px' },
    cellStyle: { padding: '0 0 0 12px' },
    property: 'fundManagerName',
    key: 'fundManagerName',
    label: upperCase('manager name'),
    isNumeric: false,
  },
  {
    width: 70,
    sortable: true,
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    property: 'vintage',
    key: 'vintage',
    label: upperCase('vintage'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 80,
    sortable: true,
    headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'size',
    key: 'size',
    formatter: millions,
    label: upperCase('fund size'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 100,
    sortable: true,
    headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'targetSize',
    key: 'targetSize',
    formatter: millions,
    label: upperCase('target amount'),
    align: 'right',
    isNumeric: true,
  },
];

const listDefWide = [
  {
    width: 200,
    sortable: true,
    headerStyle: { padding: '0 0 0 12px' },
    cellStyle: { padding: '0 0 0 12px', color: '#0d8bab' },
    property: 'name',
    key: 'name',
    label: upperCase('fund name'),
    isNumeric: false,
  },
  {
    width: 200,
    sortable: true,
    headerStyle: { padding: '0 0 0 12px' },
    cellStyle: { padding: '0 0 0 12px' },
    property: 'fundManagerName',
    key: 'fundManagerName',
    label: upperCase('manager name'),
    isNumeric: false,
  },
  {
    width: 70,
    sortable: true,
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    property: 'vintage',
    key: 'vintage',
    label: upperCase('vintage'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 80,
    sortable: true,
    headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'size',
    key: 'size',
    formatter: millions,
    label: upperCase('fund size'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 100,
    sortable: true,
    headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'targetSize',
    key: 'targetSize',
    formatter: millions,
    label: upperCase('target amount'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 150,
    sortable: true,
    property: 'strategyName1',
    key: 'strategyName1',
    label: upperCase('strategy'),
    isNumeric: false,
  },
  {
    width: 150,
    sortable: true,
    property: 'regionName1',
    key: 'regionName1',
    label: upperCase('region'),
    isNumeric: false,
  },
  {
    width: 150,
    sortable: true,
    property: 'fundStatus',
    key: 'fundStatus',
    label: upperCase('Status'),
    isNumeric: false,
  },
  {
    width: 150,
    sortable: true,
    property: 'filingDate',
    key: 'filingDate',
    label: upperCase('filing date'),
    isNumeric: false,
    formatter: formatDateCompact,
  },
];

const listDefs = { narrow: listDefNarrow, wide: listDefWide };

export { listDefNarrow, listDefWide };
export default listDefs;
