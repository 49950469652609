import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { getFundCashflowForecast } from 'src/services/filter-api';

const QUERY_OPTS = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  staleTime: 1000 * 60 * 60,
};

function useFundForecastQueries(funds, { truncate = 28 } = {}) {
  const queries = useQueries(
    funds.map(fund => {
      const { fundId, date } = fund;

      return {
        queryKey: ['fundForecast', fundId, date],
        queryFn: async () => await getFundCashflowForecast({ fundId, date, debug: true }),
        ...QUERY_OPTS,
        placeholderData: { fundId, history: [] },
      };
    })
  );

  return useMemo(() => {
    return queries.map(query => {
      if (!query.data) return query;
      if (truncate === false) return query;

      const { forecastStart, history } = query.data;
      const start = forecastStart - 1;
      const end = Number.isFinite(truncate) ? start + truncate : Infinity;

      return {
        ...query,
        data: {
          ...query.data,
          history: history.filter(d => {
            return d.q >= start && d.q < end;
          }),
        },
      };
    });
  }, [queries, truncate]);
}

export default useFundForecastQueries;
