import { useRef } from 'react';
import { chunk, first, last } from 'src/lib/lodash';

const START_YEAR = new Date().getFullYear();
const END_YEAR = 1999;

function generateVintages() {
  const length = START_YEAR - END_YEAR + 1;
  const result = new Array(length).fill(START_YEAR).map((n, i) => {
    const year = n - i;

    return {
      label: year.toString(),
      vintageId: year,
      parentId: Math.floor(i / 5) * 6 + 1,
    };
  });

  return chunk(result, 5)
    .map(group => {
      group.unshift({
        label: `${last(group).label} – ${first(group).label}`,
        vintageId: first(group).parentId,
        parentId: null,
      });
      return group;
    })
    .flat();
}

function useVintages() {
  const vintages = useRef(generateVintages());
  return vintages.current;
}

export default useVintages;
