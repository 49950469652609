import React from 'react';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';
import IconGlobe from '../IconGlobe';

const Button = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    left: 0;
  }
`;

const ButtonRegion = props => {
  return (
    <Button startIcon={<IconGlobe />} {...props}>
      Geography
    </Button>
  );
};

ButtonRegion.defaultProps = {};

ButtonRegion.propTypes = {};

export default ButtonRegion;
