const visualization = {
  fundManager: {
    YEARS_BACK: 10,
  },
};

const news = {
  article: {
    SUMMARY_LENGTH: 5,
  },
};

export { visualization, news };
