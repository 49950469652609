import styled, { css } from 'styled-components';

const baseLayout = css`
  width: 100%;
  height: 100%;
  min-height: 80px;
  background-color: #222;
`;

const Placeholder = styled.div`
  ${baseLayout};
`;

const Thumbnail = styled.div`
  ${baseLayout};

  height: ${p => p.height}px;
  background-image: url(${p => p.src});
  background-size: cover;
`;

export { Placeholder, Thumbnail };
