/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchFundManagerFundsInMarketPending = createAction('FETCH_FUND_MANAGER_FUNDS_IN_MARKET_PENDING');
const fetchFundManagerFundsInMarketError = createAction('FETCH_FUND_MANAGER_FUNDS_IN_MARKET_ERROR');
const fetchFundManagerFundsInMarketSuccess = createAction('FETCH_FUND_MANAGER_FUNDS_IN_MARKET_SUCCESS');

export function fetchFundManagerFundsInMarket({ fundManagerId }) {
  return async function (dispatch) {
    dispatch(fetchFundManagerFundsInMarketPending({ fundManagerId }));
    try {
      const fundsInMarket = await filterApi.getFundManagerFundsInMarket({ fundManagerId });
      dispatch(fetchFundManagerFundsInMarketSuccess({ fundsInMarket }));
    } catch (error) {
      dispatch(fetchFundManagerFundsInMarketError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  fundManagerId: null,
  all: null,
};

export default createReducer(initialState, {
  [fetchFundManagerFundsInMarketPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    state.fetching = true;
    state.fundManagerId = fundManagerId;
  },
  [fetchFundManagerFundsInMarketError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [fetchFundManagerFundsInMarketSuccess]: (state, action) => {
    const { fundsInMarket } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.all = fundsInMarket;
  },
});
