import { format } from 'd3';
import { abbreviate } from './abbreviate';

export const formatBigNumber =
  (pattern = '.3~s') =>
  number =>
    format(pattern)(number).replace('G', 'B').replace('k', 'K');

export function currency(abbreviation) {
  return function (formatOptions = {}) {
    return function (value) {
      if (!Number.isFinite(value)) return '-';

      const defaultFormatOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
      };

      try {
        const abbreviated = abbreviation ? abbreviate(abbreviation)(value) : { value };
        const formatted = Intl.NumberFormat(window.navigator.language, {
          ...defaultFormatOptions,
          ...formatOptions,
        }).format(abbreviated.value);

        return abbreviation ? `${formatted}${abbreviated.abbr}` : formatted;
      } catch (e) {
        return '-';
      }
    };
  };
}
