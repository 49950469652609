import React from 'react';
import { useState } from 'react';
import { navigate } from '@reach/router';
import { md, lg } from 'src/config/layout';
import {
  useGet as useUserAgreementsQuery,
  useSet as useUserAgreementsMutation,
} from 'src/services/filter-api/userAgreements';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { AppLayout, AppPanel, AgreeToContinueDialog, Responsive } from 'src/components';
import { strategyForecasting, portfolioForecasting } from 'src/config/tutorials';
import { AGREEMENT_TYPES } from '@fundfilter/core/src/constants';
import TargetFund from './TargetFund';
import Portfolio from './Portfolio';

function CashflowsPage() {
  const [portfolioPanelControls, setPortfolioPanelControls] = useState('');
  const [portfolioId, setPortfolioId] = useState();
  const userAgreementsQuery = useUserAgreementsQuery();
  const userAgreementMutation = useUserAgreementsMutation();
  const tosAccepted = userAgreementsQuery.isLoading || userAgreementsQuery.data?.[AGREEMENT_TYPES.CFF.key];

  function handleConfirmUserAgreement() {
    userAgreementMutation.mutate(AGREEMENT_TYPES.CFF.key);
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.cashflowForecastAcceptTos);
  }

  return (
    <AppLayout>
      <AppPanel
        spanX="full"
        spanY={5}
        title="Cash Flow Forecast"
        isLoading={userAgreementsQuery.isLoading}
        tutorial={strategyForecasting}
        css={`
          min-height: 820px;

          @media (min-width: ${md.BREAKPOINT}px) {
            min-height: 660px;
          }

          @media (min-width: ${lg.BREAKPOINT}px) {
            min-height: 400px;
          }
        `}
      >
        <TargetFund portfolioId={portfolioId} />
      </AppPanel>
      <AppPanel
        spanX="full"
        spanY={7}
        title="Portfolio"
        controls={<Responsive.OnAtLeastLaptop>{portfolioPanelControls}</Responsive.OnAtLeastLaptop>}
        tutorial={portfolioForecasting}
        isLoading={userAgreementsQuery.isLoading}
        expandable
        css={`
          min-height: 1800px;

          @media (min-width: ${md.BREAKPOINT}px) {
            min-height: 1400px;
          }

          @media (min-width: ${lg.BREAKPOINT}px) {
            min-height: 300px;
          }
        `}
      >
        <Portfolio
          portfolioId={portfolioId}
          onChangePortfolio={setPortfolioId}
          setPanelControls={setPortfolioPanelControls}
        />
      </AppPanel>
      <AgreeToContinueDialog
        open={!tosAccepted}
        onClickConfirm={handleConfirmUserAgreement}
        onClickCancel={() => navigate(-1)}
        isBusy={!userAgreementMutation.isIdle}
        title="Cashflow Forecasting Terms of Service"
      >
        <p>
          Information displayed on this screen is based on models and/or tools which use publicly available historical
          information to predict future outcomes or results. These models and/or tools are intended for informational
          use only, and you acknowledge and agree that FundFilter (nor Altman Inc) cannot and does not guarantee any
          particular outcomes or results, and that past or historical results are not indicative of and do not guarantee
          future performance, outcomes or results. Always obtain relevant and specific independent professional advice
          before making any investment or other decision.
        </p>
      </AgreeToContinueDialog>
    </AppLayout>
  );
}

export default CashflowsPage;
