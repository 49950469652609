import React from 'react';
import { useRef } from 'react';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import download from 'downloadjs';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ButtonImageDownload, IconClose } from 'src/components';
import { toPng } from 'html-to-image';

const LOGO_IMAGE = '/assets/img/logo_with_brand.svg';
const LOGO_DIMENSIONS = { width: 150, height: 25 };

const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  color: white;
  border: none;
  opacity: 0.5;
`;

function ImageDownloadDialog({ open, onClose, children, width, height, fileName }) {
  const targetRef = useRef();
  const [isGenerating, setIsGenerating] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDownload = useCallback(
    async function () {
      if (!targetRef.current) return;

      setIsProcessing(true);

      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundPeerDownload);
      const dataUrl = await toPng(targetRef.current, {
        cacheBust: true,
        backgroundColor: '#141414',
        pixelRatio: 1,
        style: {
          opacity: 1,
        },
      });

      await download(dataUrl, `FundFilter-${fileName}.png`);

      setTimeout(() => {
        setIsProcessing(false);
        onClose();
      }, 2500);
    },
    [onClose, targetRef, fileName]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsGenerating(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      css={`
        .MuiDialog-paper {
          width: ${width}px;
          height: ${height}px;
        }
      `}
    >
      <DialogTitle>
        <div
          css={`
            display: flex;
            justify-content: space-between;
          `}
        >
          <span></span>
          <span
            css={`
              font-size: 32px;
              font-weight: 500;
            `}
          >
            Share
          </span>
          <CloseButton onClick={onClose}>
            <IconClose />
          </CloseButton>
        </div>
      </DialogTitle>
      <DialogContent
        css={`
          background-color: #141414;
          margin: 0 1rem;

          &::before {
            content: ${isGenerating ? "'Generating image...'" : "''"};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      >
        <div
          ref={targetRef}
          css={`
            width: 100%;
            height: 100%;
            padding: 1rem 0.75rem;
            pointer-events: none;
            opacity: ${isGenerating ? 0 : 1};
            transition: opacity 0.5s ease-in-out;
            position: relative;
            * {
              overflow: hidden !important;
            }
          `}
        >
          {children}
          <img
            src={LOGO_IMAGE}
            alt="FundFilter Logo"
            css={`
              width: ${LOGO_DIMENSIONS.width}px;
              height: ${LOGO_DIMENSIONS.height}px;
              position: absolute;
              bottom: 10px;
              right: 10px;
            `}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div
          css={`
            flex: 1;
            display: flex;
            justify-content: center;
          `}
        >
          <ButtonImageDownload
            busy={isGenerating ? 'Generating Image' : isProcessing ? 'Downloading Image' : false}
            disabled={isGenerating || isProcessing}
            onClick={handleDownload}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

ImageDownloadDialog.defaultProps = {};

ImageDownloadDialog.propTypes = {
  children: PropTypes.any,
};

export default ImageDownloadDialog;
