import useViewportWidth from './useViewportWidth';
import useViewportHeight from './useViewportHeight';

function useViewportSize() {
  const width = useViewportWidth();
  const height = useViewportHeight();
  const aspectRatio = width / height;
  const isLandscape = aspectRatio > 1;
  const isPortrait = aspectRatio < 1;
  const isSquare = aspectRatio === 1;
  const orientation = isSquare ? 'square' : isLandscape ? 'landscape' : 'portrait';

  return {
    width,
    height,
    aspectRatio,
    isLandscape,
    isPortrait,
    isSquare,
    orientation,
  };
}

export default useViewportSize;
