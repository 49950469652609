import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.div`
  font-size: 0.9375rem;
  padding: 1.733333333em;
  background-color: white;
  border-radius: 0.4em;
  color: #4b4b4b;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.733333333em;
  max-width: 37em;
  margin: 8.5em auto 3em auto;
`;

const Title = styled.div`
  font-size: 1.333333333em;
  font-weight: bold;
  line-height: 1.2;
  color: #343434;
`;

const Img = styled.img`
  width: 100%;
  display: block;
`;

const List = styled.ul`
  margin-left: 0em;
  padding-left: 0em;
  line-height: 1.6;
  list-style: none;
`;

function MyComponent({ className }) {
  return (
    <Layout className={className}>
      <Img alt="" src="/assets/img/radar-chart.webp" />
      <div>
        <Title>Benefits of Adding Your Funds:</Title>
        <List>
          <li>Generate a Cash Flow J-Curve</li>
          <li>Generate Net Asset Value Curve</li>
          <li>Benchmark to Peer Group</li>
        </List>

        <List>
          <li>
            <span
              css={`
                font-weight: bold;
                font-style: italic;
                color: #141414;
                background-color: #e1b82a;
                padding: 0.375em 0.5em;
              `}
            >
              Data is visible only to you
            </span>
          </li>
        </List>
      </div>
    </Layout>
  );
}

MyComponent.defaultProps = {};

MyComponent.propTypes = {
  children: PropTypes.any,
};

export default MyComponent;
