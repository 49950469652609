function isArray(arr) {
  return Array.isArray(arr);
}

function extent(arr) {
  const first = arr[0];
  const last = [...arr].pop();
  return [first, last];
}

function transpose(input) {
  return input[0].map((_, i) => input.map(row => row[i]));
}

function unionByKey(key) {
  return function (arr1, arr2) {
    const arrByKey = [...arr1, ...arr2].map(f => [f[key], f]);

    return Array.from(new Map(arrByKey).values());
  };
}

function unique(arr) {
  return [...new Set(arr)];
}

function splitInto(size = 1) {
  return function (arr) {
    const copy = [...arr];
    const len = Math.floor(arr.length / size);
    const r = (arr.length / size) % 1;
    let carry = r;

    return new Array(size).fill(0).map((_, i) => {
      const isLast = i === size - 1;
      const hasLeftovers = isLast && copy.length > len;
      const forceCarry = Boolean(Math.round(carry));
      const shouldCarry = forceCarry || hasLeftovers;

      const end = shouldCarry ? len + 1 : len;
      carry = shouldCarry ? 0 : carry + r;

      return copy.splice(0, end);
    });
  };
}

/**
 * Take the specified `size` of items from an array, distributing
 * the results with roughly equal space between
 * @param  {number} size - how many items do you want to take
 * @returns {(arr: array) => array} function - a function accepting the array to take from
 */
function takeWithSpaceBetween(size) {
  return function (arr) {
    const head = arr.slice(0, -1);
    const tail = arr.slice(-1);

    return [...splitInto(size)(head).flatMap(d => d[0]), ...tail];
  };
}

/**
 * Take the specified `size` of items from an array, distributing the results with the specified `method`
 * @param  {number} size - how many items do you want to take
 * @param  {('space-between')} [method='space-between'] - the distribution method
 * @returns {(arr: array) => array} function - a function accepting the array to take from
 */
function takeDistributed(size, method = 'space-between') {
  switch (method) {
    case 'space-bewteen':
      return takeWithSpaceBetween(size);

    /*
    case 'space-around':
    return takeWithSpaceAround(size);
    */

    default:
      return takeWithSpaceBetween(size);
  }
}

function newSequential(n, start = 0) {
  return new Array(n).fill(0).map((_, i) => i + start);
}

const arrayUtils = {
  transpose,
  unionByKey,
  extent,
  splitInto,
  takeDistributed,
  takeWithSpaceBetween,
  unique,
  newSequential,
};

export {
  transpose,
  unionByKey,
  extent,
  splitInto,
  takeDistributed,
  takeWithSpaceBetween,
  unique,
  newSequential,
  isArray,
};
export default arrayUtils;
