import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconBarChart(props) {
  return (
    <Svg viewBox="0 0 32 40" {...props}>
      <path d="M2,10h8V30H2ZM12,30h8V2H12ZM22,15V30h8V15Z" />
    </Svg>
  );
}
