import React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { valueOf, valueOfPropType } from './utils';

const Cell = styled.div`
  flex: ${props => props.flex};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
`;

const rowHoverStyles = css`
  &:hover {
    background-color: ${props => props.rowHoverBackgroundColor};
    color: ${props => props.rowHoverColor};

    ${Cell} {
      color: inherit !important;
    }
  }
`;

const Row = styled.div`
  &.-alt {
    background-color: rgba(255, 255, 255, 0.035);
  }

  &.vt-row {
    display: flex;
    align-items: center;
    width: ${props => props.width};
    background-color: ${props => props.rowBackgroundColor};
    cursor: ${props => (props.enableHover ? 'pointer' : null)};
    color: rgba(255, 255, 255, 0.5);
    color: ${props => props.rowColor};

    ${props => props.enableHover && rowHoverStyles};

    ${Cell} {
      color: ${props => props.rowColor};
    }
  }
`;

function RowItem(props) {
  const { index, style, data } = props;

  const {
    localData,
    enableHover,
    rowColor,
    rowBackgroundColor,
    rowHoverColor,
    rowHoverBackgroundColor,
    onRowClick,
    onRowMouseOver,
    onRowMouseOut,
    listDef,
    onColumnMouseOver,
    onColumnMouseOut,
    onColumnClick,
    className,
    rowClassName,
  } = data;

  const item = localData[index];

  const classNames = ['vt-row', index % 2 === 0 && '-alt', className, valueOf(rowClassName, item)]
    .filter(x => Boolean(x))
    .join(' ');

  const handleOnRowClick = useCallback(
    function (event) {
      onRowClick(event, { data: item, index });
    },
    [onRowClick, item, index]
  );

  const handleOnRowMouseOver = useCallback(
    function (event) {
      onRowMouseOver(event, { data: item, index });
    },
    [onRowMouseOver, item, index]
  );

  const handleOnRowMouseOut = useCallback(
    function (event) {
      onRowMouseOut(event, { data: item, index });
    },
    [onRowMouseOut, item, index]
  );

  const handleOnColumnClick = useCallback(
    function (column) {
      return function (event) {
        onColumnClick(event, { column, data: item, index });
      };
    },
    [onColumnClick, item, index]
  );

  const handleOnColumnMouseOver = useCallback(
    function (column) {
      return function (event) {
        onColumnMouseOver(event, { column, data: item, index });
      };
    },
    [onColumnMouseOver, item, index]
  );

  const handleOnColumnMouseOut = useCallback(
    function (column) {
      return function (event) {
        onColumnMouseOut(event, { column, data: item, index });
      };
    },
    [onColumnMouseOut, item, index]
  );

  const renderChildren = useCallback(
    column => {
      if (column.component) return column.component(item);
      if (column.formatter) return column.formatter(item[column.property]);
      return item[column.property];
    },
    [item]
  );

  return (
    <Row
      style={style}
      index={index}
      enableHover={enableHover}
      rowColor={valueOf(rowColor, item)}
      rowBackgroundColor={valueOf(rowBackgroundColor, item)}
      rowHoverColor={valueOf(rowHoverColor, item)}
      rowHoverBackgroundColor={valueOf(rowHoverBackgroundColor, item)}
      onClick={handleOnRowClick}
      onMouseOver={handleOnRowMouseOver}
      onMouseOut={handleOnRowMouseOut}
      className={classNames}
    >
      {listDef.map(column => {
        return (
          <Cell
            flex={`0 0 ${column.width}px`}
            style={valueOf(column.cellStyle, item)}
            key={`${column.key}:${index}`}
            data={item}
            onMouseOver={handleOnColumnMouseOver(column)}
            onMouseOut={handleOnColumnMouseOut(column)}
            onClick={handleOnColumnClick(column)}
            className={`vt-cell ${column.key}`}
          >
            {renderChildren(column)}
          </Cell>
        );
      })}
    </Row>
  );
}

RowItem.defaultProps = {};

RowItem.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.shape({
    localData: PropTypes.array.isRequired,
    enableHover: PropTypes.bool.isRequired,
    listDef: PropTypes.array.isRequired,
    rowColor: valueOfPropType,
    rowBackgroundColor: valueOfPropType,
    rowHoverColor: valueOfPropType,
    rowHoverBackgroundColor: valueOfPropType,
    onRowClick: PropTypes.func.isRequired,
    onColumnMouseOver: PropTypes.func.isRequired,
    onColumnMouseOut: PropTypes.func.isRequired,
    onColumnClick: PropTypes.func.isRequired,
  }).isRequired,
};

export default React.memo(RowItem);
