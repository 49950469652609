import React from 'react';
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { chunk, first, last } from 'src/lib/lodash';
import { animation, sm, md } from 'src/config/layout';
import { createHierarchy } from 'src/utils';
import { useSystemDefaultBenchmark } from 'src/hooks';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Button, TreeviewCheckbox } from 'src/components';
import FilterTreeView from '../FilterTreeView';
import FilterStrategySelector from '../FilterStrategySelector';

const VINTAGES_PER_GROUP = 5;

const Layout = styled.div`
  background-color: rgba(35, 35, 35, 0.85);
  padding: 16px;
  overflow-y: auto;
  z-index: 1;

  transition: transform ${animation.TIMING}ms ${animation.EASING};

  ${({ popover, open }) =>
    !popover &&
    `
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, ${open ? 0 : -110}%, 0);
  `}

  footer {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  gap: 2px;

  & > div:first-child {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  & > div:last-child {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  section + section {
    margin-top: 8px;
  }

  section header {
    display: flex;
    flex-direction: row;
    color: #fff;
    font-size: 14px;
    padding-bottom: 4px;
    margin-bottom: 8px;
    margin-left: 4px;
    border-bottom: 1px solid #ffffff33;

    .MuiFormControlLabel-label {
      font-size: inherit;
    }

    .MuiFormControlLabel-root {
      flex: 1;
      margin: 0;
      display: flex;
      height: 24px;
      align-items: center;
    }
  }

  @media (min-width: ${sm.BREAKPOINT}px) {
    grid-template-columns: 1fr repeat(4, auto) 1fr;

    & > div {
      display: contents;

      &:first-child {
        section:first-child {
          grid-column-start: 2;
          grid-column-end: 3;
        }

        section:last-child {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }

      &:last-child {
        section:first-child {
          grid-column-start: 4;
          grid-column-end: 5;
        }

        section:last-child {
          grid-column-start: 4;
          grid-column-end: 5;
        }
      }
    }

    section + section {
      margin-top: 0;
    }
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    grid-template-columns: 1fr repeat(5, auto) 1fr;

    & > div {
      display: contents;

      &:first-child {
        section:first-child {
          grid-column-start: 2;
          grid-column-end: 3;
        }

        section:last-child {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }

      &:last-child {
        section:first-child {
          grid-column-start: 4;
          grid-column-end: 5;
        }

        section:last-child {
          grid-column-start: 6;
          grid-column-end: 7;
        }
      }
    }

    section + section {
      margin-top: 0;
    }
  }
`;

const CheckBox = styled(TreeviewCheckbox)`
  margin-right: ${({ theme }) => `${theme.spacing(0.5)}px`};
  /* margin-top: ${({ theme }) => `${theme.spacing(-0.5)}px`}; */
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const CloseButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
})`
  font-weight: 400;
  color: #fff;
  padding: 3px 14px;
`;

const ResetButton = styled(CloseButton).attrs({
  variant: 'contained',
  color: 'default',
})`
  color: #fff9;
  background-color: #545454;
  margin-right: 0.5em;
`;

const SystemDefaultBenchmarkLabel = styled(Box)`
  display: flex;
  flex: 1;
  font-style: italic;
  font-size: 0.85em;
  align-items: center;
  color: #9b9b9b;
`;

const FundCoreAttributesIndicatorLabel = styled(Box)`
  display: flex;
  flex: 1;
  font-size: 0.85em;
  align-items: center;
  color: #9b9b9b;
  justify-content: flex-end;
  padding-bottom: 14px;
`;

const treeCss = css`
  overflow: auto;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

function FilterControls({
  className,
  onDoneButtonClick,
  onResetButtonClick,
  popover,
  open,
  persisting,
  persistingButtonText,
  setOpen,
  resetButtonText,
  closeButtonText,
  showFundCoreAttributesHint,
  defaultRegionsFilter,
  regionsFilter,
  setRegionsFilter,
  defaultSecondRegionsFilter,
  secondRegionsFilter,
  setSecondRegionsFilter,
  defaultSizesFilter,
  sizesFilter,
  setSizesFilter,
  defaultStrategiesFilter,
  strategiesFilter,
  setStrategiesFilter,
  defaultVintagesFilter,
  vintagesFilter,
  setVintagesFilter,
}) {
  const isSystemDefaultBenchmark = useSystemDefaultBenchmark({
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
  });

  const strategyHierarchy = useMemo(() => {
    return createHierarchy('strategyId')(strategiesFilter);
  }, [strategiesFilter]);

  const vintageHierarchy = useMemo(() => {
    if (!vintagesFilter || vintagesFilter.length <= VINTAGES_PER_GROUP) {
      return vintagesFilter ?? [];
    }

    return chunk(vintagesFilter, VINTAGES_PER_GROUP).map((group, index) => {
      return {
        label: `${last(group).label} – ${first(group).label}`,
        vintageId: index,
        parentId: null,
        selected: group.every(child => Boolean(child.selected)),
        children: group.map(item => ({ ...item, parentId: index })),
      };
    });
  }, [vintagesFilter]);

  const handleClickNode = (setState, idProp) => {
    return function (item, newState) {
      if (setState) {
        setState(state =>
          state.map(v => {
            if (v[idProp] !== item[idProp]) return v;
            return { ...v, ...item, ...newState };
          })
        );
      }
    };
  };

  useEffect(() => {
    const disabled = regionsFilter?.every(region => !region.selected) ?? true;
    setSecondRegionsFilter(filter =>
      filter?.map(region => ({
        ...region,
        disabled,
      }))
    );
  }, [regionsFilter, setSecondRegionsFilter]);

  if (!strategyHierarchy) return null;
  if (!vintageHierarchy) return null;
  if (!regionsFilter) return null;
  if (!secondRegionsFilter) return null;
  if (!sizesFilter) return null;

  return (
    <Layout className={className} open={open} popover={popover}>
      {showFundCoreAttributesHint && (
        <FundCoreAttributesIndicatorLabel>* Subject Fund&apos;s core attributes</FundCoreAttributesIndicatorLabel>
      )}
      <Grid>
        <div>
          <section
            css={`
              min-width: 10em;
            `}
          >
            <header>
              <span className="MuiFormControlLabel-root">Strategy</span>
            </header>
            <FilterStrategySelector
              onClickNode={handleClickNode(setStrategiesFilter, 'strategyId')}
              strategies={strategyHierarchy}
              css={`
                max-height: 100%;
              `}
            />
          </section>
          <section>
            <header>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={vintagesFilter.every(f => f.selected)}
                    onChange={event => {
                      if (!setVintagesFilter) return;

                      setVintagesFilter(state =>
                        state.map(f => ({
                          ...f,
                          selected: event.target.checked,
                        }))
                      );
                    }}
                    name="vintage"
                    indeterminate={
                      !vintagesFilter.every(f => f.selected) &&
                      vintagesFilter.some(f => f.selected) &&
                      !vintagesFilter.every(f => !f.selected)
                    }
                  />
                }
                label="Vintage"
              />
            </header>
            <FilterTreeView
              data={vintageHierarchy}
              idProp="vintageId"
              labelProp="label"
              labelFormat={value => <NoWrap>{value}</NoWrap>}
              onClickNode={handleClickNode(setVintagesFilter, 'vintageId')}
              css={treeCss}
            />
          </section>
        </div>
        <div>
          <section>
            <header>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={regionsFilter.every(f => f.selected)}
                    onChange={event => {
                      setRegionsFilter(state =>
                        state.map(f => ({
                          ...f,
                          selected: event.target.checked,
                        }))
                      );
                    }}
                    name="Region1"
                    indeterminate={
                      !regionsFilter.every(f => f.selected) &&
                      regionsFilter.some(f => f.selected) &&
                      !regionsFilter.every(f => !f.selected)
                    }
                  />
                }
                label="Region I"
              />
            </header>
            <FilterTreeView
              data={regionsFilter}
              idProp="regionId"
              labelProp="regionName"
              labelFormat={value => <NoWrap>{value}</NoWrap>}
              onClickNode={handleClickNode(setRegionsFilter, 'regionId')}
              css={treeCss}
            />
          </section>

          {/*<section>
            <header>
              <FormControlLabel
                control={
                  <CheckBox
                    disabled={secondRegionsFilterDisabled}
                    checked={secondRegionsFilter.every(f => f.selected)}
                    onChange={event => {
                      setSecondRegionsFilter(state =>
                        state.map(f => ({
                          ...f,
                          selected: event.target.checked,
                        }))
                      );
                    }}
                    name="Region2"
                    indeterminate={
                      !secondRegionsFilter.every(f => f.selected) &&
                      secondRegionsFilter.some(f => f.selected) &&
                      !secondRegionsFilter.every(f => !f.selected)
                    }
                  />
                }
                label="Region II"
              />
            </header>
            <FilterTreeView
              disabled={secondRegionsFilterDisabled}
              data={secondRegionsFilter}
              idProp="regionId"
              labelProp="regionName"
              labelFormat={value => <NoWrap>{value}</NoWrap>}
              onClickNode={handleClickNode(setSecondRegionsFilter, 'regionId')}
              css={treeCss}
            />
          </section>
          */}
          <section>
            <header>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={sizesFilter.every(f => f.selected)}
                    onChange={event => {
                      setSizesFilter(state =>
                        state.map(f => ({
                          ...f,
                          selected: event.target.checked,
                        }))
                      );
                    }}
                    name="size"
                    indeterminate={
                      !sizesFilter.every(f => f.selected) &&
                      sizesFilter.some(f => f.selected) &&
                      !sizesFilter.every(f => !f.selected)
                    }
                  />
                }
                label="Size"
              />
            </header>

            <FilterTreeView
              data={sizesFilter}
              idProp="sizeId"
              labelProp="label"
              labelFormat={value => <NoWrap>{value}</NoWrap>}
              onClickNode={handleClickNode(setSizesFilter, 'sizeId')}
              css={treeCss}
            />
          </section>
        </div>
      </Grid>
      <footer>
        {isSystemDefaultBenchmark && (
          <SystemDefaultBenchmarkLabel>System Default Benchmark</SystemDefaultBenchmarkLabel>
        )}
        <ResetButton
          disabled={persisting}
          onClick={async () => {
            if (setRegionsFilter) setRegionsFilter(defaultRegionsFilter);
            if (setSecondRegionsFilter) setSecondRegionsFilter(defaultSecondRegionsFilter);
            if (setSizesFilter) setSizesFilter(defaultSizesFilter);
            if (setStrategiesFilter) setStrategiesFilter(defaultStrategiesFilter);
            if (setVintagesFilter) setVintagesFilter(defaultVintagesFilter);
            await onResetButtonClick();
            setOpen(false);
          }}
        >
          {resetButtonText}
        </ResetButton>
        <CloseButton
          disabled={persisting}
          onClick={async () => {
            await onDoneButtonClick();
            setOpen(false);
          }}
        >
          {persisting ? persistingButtonText : closeButtonText}
        </CloseButton>
      </footer>
    </Layout>
  );
}

FilterControls.defaultProps = {
  open: false,
  popover: false,
  persisting: false,
  setOpen: () => {},
  resetButtonText: 'Reset',
  closeButtonText: 'Done',
  persistingButtonText: 'Updating...',
  showFundCoreAttributesHint: false,
  onChangeStrategySelect: () => console.info('TODO, implement onStrategySelectionChange'),
  onChangeStrategyCheck: () => console.info('TODO, implement onStrategyCheckChange'),
  onChangeTreeNodeCheck: () => console.info('TODO, implement onChangeTreeNodeCheck'),
  onTreeNodeExpandToggle: () => console.info('TODO, implement onTreeNodeExpandToggle'),
  onResetButtonClick: () => {},
  onDoneButtonClick: () => {},
};

FilterControls.propTypes = {
  popover: PropTypes.bool,
  persisting: PropTypes.bool,
  persistingButtonText: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  resetButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  showFundCoreAttributesHint: PropTypes.bool,
  onDoneButtonClick: PropTypes.func,
  onResetButtonClick: PropTypes.func,
  defaultRegionsFilter: PropTypes.array,
  defaultSecondRegionsFilter: PropTypes.array,
  defaultSizesFilter: PropTypes.array,
  defaultStrategiesFilter: PropTypes.array,
  defaultVintagesFilter: PropTypes.array,
  regionsFilter: PropTypes.array,
  secondRegionsFilter: PropTypes.array,
  sizesFilter: PropTypes.array,
  strategiesFilter: PropTypes.array,
  vintagesFilter: PropTypes.array,
  setSecondRegionsFilter: PropTypes.func,
  setRegionsFilter: PropTypes.func,
  setSizesFilter: PropTypes.func,
  setStrategiesFilter: PropTypes.func,
  setVintagesFilter: PropTypes.func,
};

export default FilterControls;
