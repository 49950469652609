import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiPopover from '@material-ui/core/Popover';
import Button from '../Button';
import IconClose from '../IconClose';

const CloseButton = styled(Button)`
  position: absolute;
  font-size: 0.75em;
  min-width: unset;
  top: 5px;
  right: 5px;
  opacity: 0.5;

  svg {
    font-size: unset;
  }
`;

function Popover({
  className,
  open,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  children,
  useCloseButton,
  anchorReference,
  anchorPosition,
}) {
  return (
    <MuiPopover
      className={className}
      open={open}
      anchorReference={anchorReference}
      anchorPosition={anchorPosition}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {useCloseButton && (
        <CloseButton onClick={onClose}>
          <IconClose />
        </CloseButton>
      )}
      {children}
    </MuiPopover>
  );
}

Popover.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  useCloseButton: false,
  anchorReference: 'anchorEl',
  anchorPosition: {
    left: 0,
    top: 0,
  },
};

Popover.propTypes = {
  children: PropTypes.any,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func,
  anchorReference: PropTypes.oneOf(['anchorEl', 'anchorPosition']),
  anchorPosition: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
  }),
};

export default Popover;
