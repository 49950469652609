import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useFundsWithPeers({
  funds,
  includeCurrentVintage = true,
  includePrevVintage = true,
  includeNextVintage = true,
}) {
  const allFunds = useSelector(state => state.fund.all);
  const peerIdsByFundId = useSelector(state => state.fund.all.peerIdsByFundId);
  const [fundsWithPeers, setFundsWithPeers] = useState(null);

  useEffect(() => {
    if (!peerIdsByFundId) return;
    if (!funds) return;
    if (!allFunds.fetched) return;

    const withPeers = funds.map(fund => {
      const peers = peerIdsByFundId[fund.fundId].map(id => allFunds.byId[id]);
      const fundVintage = fund.vintage;
      const vintages = [];
      if (includeCurrentVintage) {
        vintages.push(fundVintage);
      }
      if (includePrevVintage) {
        vintages.push(fundVintage - 1);
      }
      if (includeNextVintage) {
        vintages.push(fundVintage + 1);
      }

      return {
        ...fund,
        peers: peers.filter(peer => {
          return vintages.indexOf(peer.vintage) > -1;
        }),
      };
    });
    setFundsWithPeers(withPeers);
  }, [
    allFunds.byId,
    allFunds.fetched,
    funds,
    includeCurrentVintage,
    includeNextVintage,
    includePrevVintage,
    peerIdsByFundId,
  ]);

  return fundsWithPeers;
}
