export const light = {
  type: 'light',
  primary: {
    main: '#0D8BAB',
  },
  secondary: {
    main: '#E1B72A',
  },
  error: {
    main: '#FF1654',
  },
  custom: {
    visualization: {
      header: { background: '#aaa' },
      body: { background: '#efefef' },
      heatMap: ['#1f2e1c', '#6bdb15'],
    },
  },
};
