import React from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IntercomProvider } from 'react-use-intercom';
import { withTheme } from 'src/components/themes/with-theme';
import {
  MarketFilter,
  Funds,
  FundManagers,
  Dashboard,
  NotFound,
  News,
  Contact,
  Subscribed,
  UserSignUp,
  UserSignIn,
  UserPasswordReset,
  UserEmailVerification,
  DevSandbox,
  Account,
  Tutorials,
  Cashflows,
} from 'src/components';
import routes from 'src/config/routes';
import { Router } from '@reach/router';
import Route from './Route';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const queryClient = new QueryClient();

function getPageTitle() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  return environment === 'development' || environment === 'staging' ? `[${environment}]` : '';
}

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getPageTitle()} FundFilter</title>
        <link rel="canonical" href="https://fundfilter.com" />
      </Helmet>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <Router id="router">
            {/** Protected routes */}
            <Route Component={MarketFilter} path={routes.MARKET_FILTER} />
            <Route Component={Funds} path={routes.FUNDS} />
            <Route Component={Funds} path={routes.FUND} />
            <Route Component={FundManagers} path={routes.FUND_MANAGERS} />
            <Route Component={FundManagers} path={routes.FUND_MANAGER} />
            <Route Component={News} path={routes.NEWS} />
            <Route Component={News} path={routes.NEWS_ARTICLE} />
            <Route Component={Dashboard} path={routes.DASHBOARD} />
            <Route Component={Contact} path={routes.CONTACT} />
            <Route Component={Subscribed} path={routes.SUBSCRIBED} />
            <Route Component={Account} path={routes.ACCOUNT} />
            <Route Component={Cashflows} path={routes.CASHFLOWS} />
            <Route Component={UserEmailVerification} path={routes.USER_EMAIL_VERIFICATION} />

            {/** Public routes */}
            <Route public Component={UserSignUp} path={routes.USER_SIGN_UP} />
            <Route public Component={UserSignIn} path={routes.USER_SIGN_IN} />
            <Route public Component={UserPasswordReset} path={routes.USER_PASSWORD_RESET} />
            <Route public Component={DevSandbox} path="/dev/sandbox" />
            <Route public Component={Tutorials} path={routes.TUTORIALS} />
            <Route public default Component={NotFound} />
          </Router>
        </QueryClientProvider>
      </IntercomProvider>
    </>
  );
}

export default withTheme(App, 'dark');
