import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HeaderFieldLabel } from 'src/components';
import { sm } from 'src/config/layout';
import Table from './Table';

const Layout = styled.div`
  height: 100%;
`;

const StyledNewsTable = styled(Table)`
  .source {
    flex: 0 0 130px;
  }

  .publishedAt {
    flex: 0 0 80px;
    text-align: center;
    &::before {
      content: unset;
    }
  }
`;

const TableTitle = styled(HeaderFieldLabel)`
  font-size: 10px;
  padding: 1em;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  @media (min-width: ${sm.BREAKPOINT}px) {
    display: none;
  }
`;

function NewsVisualizationArticlesList({
  className,
  articles,
  onRowClick,
  selectedArticleId,
  onListDefCreated,
  forceCondensedView,
}) {
  return (
    <Layout className={className}>
      <TableTitle>Latest News</TableTitle>
      <StyledNewsTable
        onListDefCreated={onListDefCreated}
        articles={articles}
        selectedArticleId={selectedArticleId}
        highlightSelectedArticle={!forceCondensedView}
        onRowClick={onRowClick}
      />
    </Layout>
  );
}

NewsVisualizationArticlesList.defaultProps = {
  articles: [],
};

NewsVisualizationArticlesList.propTypes = {
  articles: PropTypes.array.isRequired,
  selectedArticle: PropTypes.object,
  onRowClick: PropTypes.func,
  onListDefCreated: PropTypes.func,
};

export default NewsVisualizationArticlesList;
