import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { throttle } from 'src/lib/lodash';
import { stateStorage } from '../services';
import rootReducer from './root-reducer';
import Bugsnag from '@bugsnag/js';

function getSavedState() {
  const saved = stateStorage.loadState();
  const lastSelected = (saved && saved.lastSelected) || {
    fundId: 'XViLQ5NhT2HE6QC99LsQ', // ABRY Partners VII
    fundManagerId: 'V3kce4oJg2wZUvsswi2F', // ABRY Partners
  };
  return { lastSelected };
}

export const errorLoggerMiddleware = () => next => async action => {
  const isPayloadError = action.payload && action.payload.error;
  const isErrorType = /[A-Z]*Error/i.test(action.type);

  if (!isPayloadError && !isErrorType) return next(action);

  const message = isPayloadError ? action.payload.error : action.type;
  Bugsnag.notify(new Error(message), function (event) {
    event.addMetadata('redux', { action });
  });

  next(action);
};

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'development' ? false : process.env.REACT_APP_SKIP_REDUX_DEVTOOLS ? false : true,
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    errorLoggerMiddleware,
  ],
  preloadedState: getSavedState(),
});

store.subscribe(
  throttle(() => {
    const state = store.getState();
    const { lastSelected } = state;
    stateStorage.saveState({ lastSelected });
  }, 10000)
);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./root-reducer', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./root-reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
