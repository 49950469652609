import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';
import { message } from 'src/config/colors';
import { TextField } from 'src/components';

const Error = styled.div`
  color: ${message.error.TEXT_COLOR};

  &:before {
    content: '⚠ ';
  }
`;

function FormTextField({ register, errors, name, validations, ...rest }) {
  return (
    <>
      <TextField
        margin="dense"
        inputProps={register(name, validations)}
        name={name}
        {...rest}
        css={`
          border-color: transparent;
          .MuiInputBase-input.Mui-disabled {
            background-color: transparent;
          }
        `}
      />
      <span>
        <ErrorMessage errors={errors} name={name} as={Error} />
      </span>
    </>
  );
}

FormTextField.defaultProps = {};

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.object,
};

export default FormTextField;
