import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEmployeeBookmarks } from '../store/employee-bookmarks';

export default function useEmployeeBookmarks({ asObjectOrArray = 'object' }) {
  const dispatch = useDispatch();
  const bookmarks = useSelector(state => state.employee.bookmarks);
  const [state, setState] = useState(null);

  useEffect(() => {
    if (!bookmarks.fetching && !bookmarks.fetched && !bookmarks.error) {
      dispatch(fetchEmployeeBookmarks());
    }
  }, [bookmarks.error, bookmarks.fetched, bookmarks.fetching, dispatch]);

  useEffect(() => {
    if (bookmarks.fetched) {
      if (asObjectOrArray === 'object') {
        setState(
          Object.values(bookmarks.byEmployeeId)
            .filter(b => b.bookmarked)
            .map(b => b.employeeId)
            .reduce((acc, employeeId) => ({ ...acc, [employeeId]: true }), {})
        );
      } else {
        setState(
          Object.values(bookmarks.byEmployeeId)
            .filter(b => b.bookmarked)
            .map(b => b.employeeId)
        );
      }
    }
  }, [asObjectOrArray, bookmarks.byEmployeeId, bookmarks.fetched]);

  return state;
}
