import React from 'react';
import PropTypes from 'prop-types';

function IncompleteDataMessage({ entity }) {
  return (
    <span
      css={`
        color: #3e89a8;
      `}
    >
      Due to incomplete data entered for this {entity}, certain metrics are not available.
    </span>
  );
}

IncompleteDataMessage.defaultProps = {
  entity: 'fund',
};

IncompleteDataMessage.propTypes = {
  entity: PropTypes.string,
};

export default IncompleteDataMessage;
