import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { formatDate } from 'src/utils/date';
import {
  TooltipContent,
  TooltipCloseIcon,
  TooltipHeader,
  TooltipRow,
  TooltipTitle,
  TooltipData,
  TooltipLabel,
  TooltipLink,
  TooltipArrowRight,
} from 'src/components';
import { percent, multiple } from 'src/formatters';

const multiple2 = multiple(2);
const percent1 = percent();

const modifiers = {
  offset: {
    enabled: true,
    offset: '0,15',
  },
  arrow: {
    enabled: true,
    element: '.fund-performance-arrow',
  },
};

const StyledPopper = styled(Popper)`
  &[x-placement*='top'] .fund-performance-arrow {
    bottom: -10px;
    transform: rotate(90deg);
  }

  &[x-placement*='bottom'] .fund-performance-arrow {
    top: -10px;
    transform: rotate(-90deg);
  }
`;

const StyledTooltip = styled.div`
  max-width: 300px;
`;

const TooltipHint = styled.span`
  font-style: italic;
`;

function FundPerformanceTooltip({ fund, open, calcs, index, anchorEl, onClickAway, onClose, onViewFund }) {
  const pmeValue = useMemo(() => {
    const value = fund?.pmeValues?.find(p => p.indexName === index)?.[calcs.key];
    if (!(typeof value === 'number')) return 0;

    return calcs?.key === 'daPmeValue' ? (value * 100)?.toFixed(2) : value.toFixed(2);
  }, [fund, calcs, index]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <StyledPopper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="top"
        modifiers={modifiers}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <StyledTooltip>
              <TooltipArrowRight className="fund-performance-arrow" marginLeft="2px" />
              <TooltipContent>
                <TooltipHeader>
                  <TooltipTitle>{fund.name}</TooltipTitle>
                  <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
                </TooltipHeader>
                <TooltipRow>
                  <TooltipLabel>YEAR</TooltipLabel>
                  <TooltipData>{fund.vintage}</TooltipData>
                </TooltipRow>
                <TooltipRow>
                  <TooltipLabel>STRATEGY</TooltipLabel>
                  <TooltipData>{fund.strategy}</TooltipData>
                </TooltipRow>
                <TooltipRow>
                  <TooltipLabel>TVPI</TooltipLabel>
                  <TooltipData>{multiple2(fund.tvpi)}</TooltipData>
                </TooltipRow>
                <TooltipRow>
                  <TooltipLabel>IRR</TooltipLabel>
                  <TooltipData>{percent1(fund.irr)}</TooltipData>
                </TooltipRow>
                {Boolean(pmeValue) && (
                  <TooltipRow>
                    <TooltipLabel>PME</TooltipLabel>
                    <TooltipData>
                      {pmeValue}
                      {calcs.key === 'ksPmeValue' ? 'x' : '%'} <TooltipHint>({calcs.label})</TooltipHint>
                    </TooltipData>
                  </TooltipRow>
                )}
                <TooltipRow>
                  <TooltipLabel>LATEST REPORTING DATE</TooltipLabel>
                  <TooltipData>{formatDate()(fund.date, { utc: true })}</TooltipData>
                </TooltipRow>
                {fund.foiaSourceAcronym && (
                  <TooltipRow>
                    <TooltipLabel>DATA SOURCE</TooltipLabel>
                    <TooltipData>{fund.foiaSourceAcronym}</TooltipData>
                  </TooltipRow>
                )}
                <TooltipRow marginTop="10px">
                  <TooltipLink
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      onViewFund(fund);
                    }}
                  >
                    View fund
                  </TooltipLink>
                </TooltipRow>
              </TooltipContent>
            </StyledTooltip>
          </Grow>
        )}
      </StyledPopper>
    </ClickAwayListener>
  );
}
FundPerformanceTooltip.defaultProps = {
  fund: null,
  open: false,
  calcs: null,
  index: null,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewFund: () => {},
};

FundPerformanceTooltip.propTypes = {
  fund: PropTypes.object,
  open: PropTypes.bool,
  calcs: PropTypes.object,
  index: PropTypes.string,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
  onViewFund: PropTypes.func,
};

export default FundPerformanceTooltip;
