import { useMemo } from 'react';
import useFilterSelectedIds from './useFilterSelectedIds';

function useIsFilterUpdated(defaultFilter, userFilter, idProp) {
  const defaultFilterIds = useFilterSelectedIds(defaultFilter, idProp);
  const userFilterIds = useFilterSelectedIds(userFilter, idProp);

  return useMemo(
    () => !(userFilterIds.length === defaultFilterIds.length && defaultFilterIds.every(f => userFilterIds.includes(f))),
    [defaultFilterIds, userFilterIds]
  );
}

function useSystemDefaultBenchmark({
  defaultRegionsFilter,
  defaultSecondRegionsFilter,
  defaultVintagesFilter,
  defaultSizesFilter,
  defaultStrategiesFilter,
  regionsFilter,
  secondRegionsFilter,
  vintagesFilter,
  sizesFilter,
  strategiesFilter,
} = {}) {
  return [
    useIsFilterUpdated(defaultVintagesFilter, vintagesFilter, 'vintageId'),
    useIsFilterUpdated(defaultRegionsFilter, regionsFilter, 'regionId'),
    useIsFilterUpdated(defaultSecondRegionsFilter, secondRegionsFilter, 'regionId'),
    useIsFilterUpdated(defaultSizesFilter, sizesFilter, 'sizeId'),
    useIsFilterUpdated(defaultStrategiesFilter, strategiesFilter, 'strategyId'),
  ].every(updated => !updated);
}

export default useSystemDefaultBenchmark;
