import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useCurrentUser } from 'src/hooks';
import * as client from './client';

function getQueryKey(userId) {
  return ['userAgreements', userId];
}

function useGet() {
  const currentUser = useCurrentUser();
  const userId = currentUser?.userId;

  return useQuery({
    enabled: Boolean(userId),
    queryKey: getQueryKey(userId),
    async queryFn() {
      return await client.getUserAgreements(userId);
    },
    refetchOnWindowFocus: false,
    initialData: {
      cffTermsOfService: true,
    },
  });
}

function useSet() {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const userId = currentUser?.userId;

  return useMutation({
    async mutationFn(key, agree = true) {
      await client.setUserAgreement(userId, key, agree);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey(userId));
    },
  });
}

export { useGet, useSet };
