import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lookup } from 'src/lookup';
import {
  BookmarkedFundsGrid,
  ButtonRounded,
  ButtonAddFund,
  SingleSelect,
  SelectPortfolioControl,
} from 'src/components';
const { SORT_KEYS } = BookmarkedFundsGrid.constants;
const sortLabels = {
  [SORT_KEYS.NUMERIC_DESC]: 'Top Performing',
  [SORT_KEYS.NUMERIC_ASC]: 'Bottom Performing',
  [SORT_KEYS.ALPHA_DESC]: 'A → Z',
  [SORT_KEYS.ALPHA_ASC]: 'Z → A',
};

const Layout = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 0.5)};
  display: grid;
  grid-template-columns: auto 1fr 1fr auto;
  align-items: center;
  gap: 0.5em;
`;

const Section = styled.div`
  display: flex;
  justify-content: ${p => p.$centeredContent && 'center'};
  visibility: ${p => p.$hidden && 'hidden'};
`;

const TimeRangeButton = styled(ButtonRounded)`
  margin-right: 4px;
  min-width: unset;
`;

function FundsCollectionHeader({
  timeRangeValue,
  onChangeTimeRange,
  showTimeRange,
  showSort,
  sortValue,
  onChangeSort,
  selectedPortfolioId,
  onChangeSelectedPortfolioId,
  onClickAddFund,
}) {
  return (
    <Layout>
      <Section $hidden={!showTimeRange}>
        {Object.values(lookup.timeRange).map(tr => (
          <TimeRangeButton key={tr.value} selected={tr === timeRangeValue} onClick={() => onChangeTimeRange(tr)}>
            {tr.label}
          </TimeRangeButton>
        ))}
      </Section>
      <Section $centeredContent $hidden={!showSort}>
        <SingleSelect
          disabled
          value={sortValue}
          label={'Sort'}
          items={Object.keys(sortLabels)}
          displayValue={sortLabels[sortValue]}
          renderLabel={item => sortLabels[item]}
          onChange={onChangeSort}
          variant="compact"
        />
      </Section>
      <Section $centeredContent>
        <SelectPortfolioControl value={selectedPortfolioId} onChange={id => onChangeSelectedPortfolioId(id)} />
      </Section>

      <Section>
        <ButtonAddFund
          label=""
          onClick={onClickAddFund}
          css={`
            font-size: 14px;
            padding: 2px 5px;
          `}
        />
      </Section>
    </Layout>
  );
}

FundsCollectionHeader.defaultProps = {
  showTimeRange: true,
  showSort: true,
};
FundsCollectionHeader.propTypes = {
  timeRangeValue: PropTypes.object,
  onChangeTimeRange: PropTypes.func,
  showTimeRange: PropTypes.bool,
  showSort: PropTypes.bool,
  sortValue: PropTypes.string,
  onChangeSort: PropTypes.func,
  selectedPortfolioId: PropTypes.string,
  onChangeSelectedPortfolioId: PropTypes.func,
  onClickAddFund: PropTypes.func,
};

export default React.memo(FundsCollectionHeader);
