import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import Truncator from '../Truncator';

const Link = styled.a`
  display: flex;
  align-items: center;

  color: #0d8bab;
  text-decoration: none;
  cursor: ${p => p.$cursor};
`;

function FundLink({ className, fund, ...rest }) {
  const { href = `/funds/${fund.fundId}` } = rest;

  return (
    <Link
      $cursor={href ? 'pointer' : 'unset'}
      className={className}
      href={href ? href : ''}
      onClick={event => {
        event.preventDefault();
        if (href) navigate(href);
      }}
    >
      <Truncator value={fund.name} keepLast={5} />
    </Link>
  );
}

FundLink.defaultProps = {};

FundLink.propTypes = {
  fund: PropTypes.object.isRequired,
};

export default FundLink;
