export function sentenceSplit(str) {
  const prefixes = /(Mr|St|Mrs|Ms|Dr)[.]/g;
  const websites = /[.](com|net|org|io|gov)/g;
  const alphabets1 = /\s([A-Za-z])[.] /g;
  const acronyms1 =
    /([A-Z][.][A-Z][.](?:[A-Z][.])?) (Mr|Mrs|Ms|Dr|He\s|She\s|It\s|They\s|Their\s|Our\s|We\s|But\s|However\s|That\s|This\s|Wherever)/g;
  const alphabets2 = /([A-Za-z])[.]([A-Za-z])[.]([A-Za-z])[.]/g;
  const alphabets3 = /([A-Za-z])[.]([A-Za-z])[.]/g;
  const suffixes1 =
    / (Inc|Ltd|Jr|Sr|Co|Corp|Plc|Calif|Jan|Feb|Mar|Apr|Jun|Jul|Aug|Sep|Sept|Oct|Nov|Dec)[.](Mr|Mrs|Ms|Dr|He\s|She\s|It\s|They\s|Their\s|Our\s|We\s|But\s|However\s|That\s|This\s|Wherever)/g;
  const suffixes2 = / (Inc|Ltd|Jr|Sr|Co|Corp|Plc|Calif|Jan|Feb|Mar|Apr|Jun|Jul|Aug|Sep|Sept|Oct|Nov|Dec)[.]/g;
  const alphabets4 = / ([A-Za-z])[.]/g;

  let text = ` ${str}  `;

  text = text.replace(/\[...\]/g, '###');
  text = text.replace('.” ###', '"<prd><stop>');
  text = text.replace('." ###', '"<prd><stop>');
  text = text.replace(/[.]\s+###/g, '<prd><stop>');
  text = text.replace(/\s+###/g, '<prd><stop>');

  text = text.replace(/\n/g, ' ');
  text = text.replace(prefixes, '$1<prd>');
  text = text.replace(websites, '<prd>$1');

  if (text.includes('Ph.D')) {
    text = text.replace(/Ph.D./g, 'Ph<prd>D<prd>');
  }
  text = text.replace(alphabets1, ' $1<prd> ');
  text = text.replace(acronyms1, '$1<stop> $2');
  text = text.replace(alphabets2, '$1<prd>$2<prd>$3<prd>');
  text = text.replace(alphabets3, '$1<prd>$2<prd>');
  text = text.replace(suffixes1, ' $1<stop> $2');
  text = text.replace(suffixes2, ' $1<prd>');
  text = text.replace(alphabets4, ' $1<prd>');

  text = text.replace(/(\w+)[.](\w+)[.](\w+)/g, '$1<prd>$2<prd>$3');
  text = text.replace(/(\w+)[.](\w+)/g, '$1<prd>$2');

  if (text.includes('"')) {
    text = text.replace('."', '".');
  }
  if (text.includes('!')) {
    text = text.replace('!"', '"!');
  }
  if (text.includes('?')) {
    text = text.replace('?"', '"?');
  }
  text = text.replace(/[.]/g, '.<stop>');
  text = text.replace(/[?]/g, '?<stop>');
  text = text.replace(/[!]/g, '!<stop>');
  text = text.replace(/<prd>/g, '.');

  let sentences = text.split('<stop>');
  if (sentences[sentences.length - 1].length < 3) {
    sentences = sentences.slice(0, sentences.length - 1);
  }
  for (let i = 0; i < sentences.length; i++) {
    sentences[i] = sentences[i].slice(1);
    if (sentences[i].includes('".')) {
      sentences[i] = sentences[i].replace('".', '."');
    }
    if (sentences[i].includes('"?')) {
      sentences[i] = sentences[i].replace('"?', '?"');
    }
    if (sentences[i].includes('"!')) {
      sentences[i] = sentences[i].replace('"!', '!"');
    }
  }

  for (let i = 0; i < sentences.length - 1; i++) {
    let nQuotes = (sentences[i].match(/"/g) || []).length;
    if (nQuotes % 2 === 1) {
      for (let j = i + 1; j < sentences.length; j++) {
        nQuotes = (sentences[j].match(/"/g) || []).length;
        if (nQuotes % 2 === 1) {
          for (let k = i + 1; k < j + 1; k++) {
            sentences[i] = `${sentences[i]} ${sentences[k]}`;
          }
          sentences.splice(i + 1, j - i);
        }
      }
    }
  }

  const nQuotesLast = (sentences[sentences.length - 1].match(/"/g) || []).length;
  if (nQuotesLast % 2 === 1) {
    sentences[sentences.length - 1] = sentences[sentences.length - 1].concat('"');
  }

  return sentences.filter(s => s.length > 2).map(s => s && s.trim());
}
