import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from 'src/services';

const fetchFundManagerLocationPending = createAction('FETCH_MANAGER_LOCATION_PENDING');
const fetchFundManagerLocationError = createAction('FETCH_MANAGER_LOCATION_ERROR');
const fetchFundManagerLocationSuccess = createAction('FETCH_MANAGER_LOCATION_SUCCESS');

export function fetchFundManagerLocation({ fundManagerId }) {
  return async function (dispatch, getState) {
    const { byId } = getState().fundManager.locations;
    if (byId[fundManagerId]) return;
    dispatch(fetchFundManagerLocationPending({ fundManagerId }));
    try {
      let location = await filterApi.getFundManagerLocation({ fundManagerId });
      if (!location) location = { addressMeta: [], fundManagerId };
      dispatch(fetchFundManagerLocationSuccess({ fundManagerId, location }));
    } catch (error) {
      dispatch(fetchFundManagerLocationError({ error: error.message, fundManagerId }));
    }
  };
}

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [fetchFundManagerLocationPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    const location = state.byId[fundManagerId] || {};
    location.fetching = true;
    state.byId[fundManagerId] = location;
  },
  [fetchFundManagerLocationError]: (state, action) => {
    const { error, fundManagerId } = action.payload;
    const location = state.byId[fundManagerId] || {};
    location.fetching = false;
    location.error = error;
    state.byId[fundManagerId] = location;
    console.error(error);
  },
  [fetchFundManagerLocationSuccess]: (state, action) => {
    const { location } = action.payload;
    location.error = null;
    location.fetching = false;
    location.fetched = true;
    state.byId[location.fundManagerId] = location;
  },
});
