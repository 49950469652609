import React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BookmarkBorderSharpIcon from '@material-ui/icons/BookmarkBorderSharp';
import BookmarkSharpIcon from '@material-ui/icons/BookmarkSharp';
import { useGetDefault as useGetDefaultPortfolio } from 'src/services/filter-api/userPortfolios/queries';
import BaseButton from 'src/components/atoms/Button';
import useFundBookmarkControls from './useFundBookmarkControls';

const Button = styled(BaseButton)`
  min-width: unset;
  &.MuiButton-text {
    padding: 4px;
  }
  font-size: 1.5em;
`;

const IconBookmark = styled.i.attrs(p => {
  return {
    as: p.$active ? BookmarkSharpIcon : BookmarkBorderSharpIcon,
  };
})`
  max-width: 20px;
  max-height: 20px;
  opacity: ${p => (p.active ? 1 : 0.5)};

  &.MuiSvgIcon-root {
    font-size: inherit;
  }
`;

function FundBookmarkControlTrigger({ className, fundId, label, onClick }) {
  const { isBookmarked, addBookmark } = useFundBookmarkControls(fundId);
  const { data: defaultPortfolio } = useGetDefaultPortfolio();

  const handleCreateBookmark = useCallback(
    evt => {
      evt.stopPropagation();
      evt.preventDefault();
      onClick({ target: evt.currentTarget, fundId });

      if (isBookmarked) return;
      if (!defaultPortfolio) return;

      addBookmark({
        fundId,
        portfolioId: defaultPortfolio.portfolioId,
        commitmentAmount: 1_000_000,
      });
    },
    [addBookmark, defaultPortfolio, fundId, isBookmarked, onClick]
  );

  return (
    <Button onClick={handleCreateBookmark} className={className}>
      <IconBookmark $active={isBookmarked} />
      {label && typeof label === 'function' ? label({ isBookmarked }) : label}
    </Button>
  );
}

FundBookmarkControlTrigger.defaultProps = {
  isBookmarked: false,
  label: '',
  title: '',
};

FundBookmarkControlTrigger.propTypes = {
  fundId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  title: PropTypes.string,
};

export default React.memo(FundBookmarkControlTrigger);
