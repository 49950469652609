import React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatDateComplete } from 'src/utils/date';
import { useFundManager } from 'src/hooks';

const Layout = styled.div`
  width: 100%;
  color: #424242;
  font-size: 0.9375rem;
  max-width: 300px;
  display: grid;
  gap: 5px;
  grid-template-columns: auto 1fr;
`;

const Header = styled.header`
  grid-column: span 2;
`;

const Footer = styled.footer`
  grid-column: span 2;
`;

const Title = styled.h2`
  font-size: 1em;
  font-weight: 300;
  margin: 0;
`;

const Label = styled.span`
  font-size: ${11 / 15}em;
  opacity: 0.5;
  flex: 0 0 45px;
  ::after {
    content: ': ';
  }
`;

const Data = styled.span`
  font-size: ${11 / 15}em;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  text-decoration: none;
  ::after {
    content: ' »';
  }
`;

const PlaceholderValue = styled.span`
  @keyframes pulse {
    0% {
      opacity: 0.2;
    }

    100% {
      opacity: 0.3;
    }
  }
  animation: pulse 1s ease alternate infinite;

  display: inline-block;
  width: 80%;
  background-color: currentColor;
  height: 1.43ex;
`;

function Value({ value, format }) {
  if (value === 'PLACEHOLDER') return <PlaceholderValue />;
  if (typeof format === 'function') return format(value);
  return value || '';
}

function NewsArticleQuickSummary({ article, onClickViewArticle, fundManager, className }) {
  const handleClickViewArticle = useCallback(
    function (event) {
      event.preventDefault();
      onClickViewArticle(article);
    },
    [article, onClickViewArticle]
  );

  const articleFundManager =
    useFundManager({
      fundManagerId: article ? article.fundManagerId : null,
    }) || fundManager;

  return (
    <Layout className={className}>
      <Header>
        <Title>
          <Value value={articleFundManager.name} />
        </Title>
      </Header>

      <Label>TITLE</Label>
      <Data>
        <Value value={article.title} />
      </Data>

      <Label>SOURCE</Label>
      <Data>
        <Value value={article.source} />
      </Data>

      <Label>DATE</Label>
      <Data>
        <Value value={article.publishedAt} format={formatDateComplete} />
      </Data>

      <Footer>
        {!article.placeholder && (
          <Link href="#" onClick={handleClickViewArticle}>
            View article
          </Link>
        )}
      </Footer>
    </Layout>
  );
}
NewsArticleQuickSummary.defaultProps = {
  fundManager: {
    name: 'PLACEHOLDER',
  },
  article: {
    placeholder: true,
    title: 'PLACEHOLDER',
    source: 'PLACEHOLDER',
    publishedAt: 'PLACEHOLDER',
  },
};

NewsArticleQuickSummary.propTypes = {
  article: PropTypes.object,
  onClickViewArticle: PropTypes.func.isRequired,
};

export default NewsArticleQuickSummary;
