import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const Container = styled.div`
  height: ${props => props.height || '100%'};
  padding: ${props => props.padding};

  .tick {
    line {
      color: #2e2e2e;
    }
    text {
      font-size: 10px;
      fill: #808080;
    }
  }

  .y-axis-label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
  }
  .domain {
    opacity: 0;
  }
  .shadow {
    filter: drop-shadow(0px 3px 8px #000);
    -webkit-filter: drop-shadow(0px 3px 8px #000);
    -moz-filter: drop-shadow(0px 3px 8px #000);
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const MARGIN = { top: 10, right: 60, bottom: 25, left: 60 };

function StrategyPerformanceIRRTimeSeries({
  data,
  publicIndex,
  topQuartileVisible,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  ...rest
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    draw({
      id: id.current,
      data,
      publicIndex,
      topQuartileVisible,
      size: { width, height },
      margin: MARGIN,
      mouseEventRefs,
    });
  }, [data, width, height, publicIndex, topQuartileVisible, mouseEventRefs]);

  return (
    <Container ref={targetRef} {...rest}>
      <svg id={id.current} />
    </Container>
  );
}

StrategyPerformanceIRRTimeSeries.defaultProps = {
  expanded: false,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

StrategyPerformanceIRRTimeSeries.propTypes = {
  data: PropTypes.object.isRequired,
  publicIndex: PropTypes.string,
  topQuartileVisible: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(StrategyPerformanceIRRTimeSeries);
