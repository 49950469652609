import React from 'react';
import { useMemo } from 'react';
import { css } from 'styled-components';
import VirtualizedGridTable from 'src/components/molecules/VirtualizedGridTable';
import useTableColumns from './useTableColumns';
import { groupBy, sumBy } from 'src/lib/lodash';

const tableCss = css`
  .th.info {
    overflow: visible;
  }
  .info {
    padding: 0;
  }

  .number {
    text-align: right;
  }

  .multiple {
    text-align: right;
  }
`;

function sumItemsBy(items, key) {
  return sumBy(
    items.filter(item => Number.isFinite(item[key])),
    key
  );
}

function TimeseriesCollectionTable({ type, data, startDate, onChangeRows }) {
  const columns = useTableColumns(type, startDate);

  const dataWithAggregates = useMemo(() => {
    const isSummable = Object.values(groupBy(data, 'q')).some(group => group.length > 1);

    const sums = isSummable
      ? Object.entries(groupBy(data, 'q')).map(([q, items]) => {
          const fundName = 'Total';
          const highlight = items.every(item => item.highlight);
          const calledPct = sumItemsBy(items, 'calledPct');
          const commitmentAmount = sumItemsBy(items, 'commitmentAmount');
          const contribution = sumItemsBy(items, 'contribution');
          const distribution = sumItemsBy(items, 'distribution');
          const contributions = sumItemsBy(items, 'contributions');
          const distributions = sumItemsBy(items, 'distributions');
          const dpi = sumItemsBy(items, 'dpi');
          const nav = sumItemsBy(items, 'nav');
          const ncf = sumItemsBy(items, 'ncf');
          const rvpi = sumItemsBy(items, 'rvpi');
          const tvpi = sumItemsBy(items, 'tvpi');
          const portfolioSum = true;

          return {
            q: +q,
            fundName,
            highlight,
            calledPct,
            commitmentAmount,
            contribution,
            distribution,
            contributions,
            distributions,
            dpi,
            nav,
            ncf,
            rvpi,
            tvpi,
            portfolioSum,
          };
        })
      : [];

    return [...data, ...sums];
  }, [data]);

  return (
    <VirtualizedGridTable columns={columns} data={dataWithAggregates} css={tableCss} onChangeRows={onChangeRows} />
  );
}

TimeseriesCollectionTable.defaultProps = {};

TimeseriesCollectionTable.propTypes = {};

export default TimeseriesCollectionTable;
