import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip as MuiTooltip } from '@material-ui/core';

const BaseTooltip = withStyles({
  arrow: {
    color: '#353535',
  },
  tooltip: {
    color: '#b9b9b9',
    backgroundColor: '#282828',
    border: '1px solid #353535',
    fontSize: 11,
    fontWeight: 400,
  },
})(MuiTooltip);

const DarkTooltip = withStyles({
  arrow: {
    color: '#353535',
  },
  tooltip: {
    color: '#b9b9b9',
    backgroundColor: '#282828',
    border: '1px solid #353535',
  },
})(BaseTooltip);

const LightTooltip = withStyles({
  arrow: {
    color: '#ebeff1',
  },
  tooltip: {
    color: '#28343f',
    backgroundColor: '#ffffff',
    border: '1px solid #ebeff1',
  },
})(BaseTooltip);

function Tooltip({ variant, title, children, arrow, ...rest }) {
  const TooltipVariant = useMemo(() => {
    return variant === 'light' ? LightTooltip : DarkTooltip;
  }, [variant]);

  if (!title) return children;

  return (
    <TooltipVariant title={title} arrow={arrow} {...rest}>
      {children}
    </TooltipVariant>
  );
}

Tooltip.defaultProps = {
  arrow: true,
  variant: 'light',
};

Tooltip.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
  arrow: PropTypes.bool,
  children: PropTypes.any,
};

export default Tooltip;
