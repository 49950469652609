import 'd3-transition';
import {
  select,
  scaleLinear,
  scaleOrdinal,
  rgb,
  treemapResquarify,
  treemap,
  hierarchy,
} from 'd3';
import { flatten, uniq } from 'src/lib/lodash';
import { visitEveryNode } from 'src/utils/hierarchy';

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function draw({
  id,
  data,
  width,
  height,
  margin,
  colors,
  mouseEventRefs,
}) {
  // https://bl.ocks.org/JacquesJahnichen/42afd0cde7cbf72ecb81
  // https://bl.ocks.org/ganeshv/6a8e9ada3ab7f2d88022
  // https://gist.github.com/tkafka/6d00c44d5ae52182f548a18e8db44811

  if (!width || !height) return;

  const svgWidth = width;
  const svgHeight = height + margin.top;
  const prefixedId = `#${id}`;
  let transitioning;

  select(prefixedId).selectAll('*').remove();

  const svg = select(prefixedId)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .style('shape-rendering', 'crispEdges');

  const x = scaleLinear().domain([0, svgWidth]).range([0, svgWidth]);

  const y = scaleLinear()
    .domain([0, svgHeight - margin.top * 2 - 1])
    .range([0, svgHeight - margin.top * 2 - 1]);

  if (window.currentNode) {
    x.domain([
      window.currentNode.x0,
      window.currentNode.x0 +
        (window.currentNode.x1 - window.currentNode.x0),
    ]);
    y.domain([
      window.currentNode.y0,
      window.currentNode.y0 +
        (window.currentNode.y1 - window.currentNode.y0),
    ]);
  }

  const ids = [];
  visitEveryNode(data.children, node => {
    ids.push(node.strategyId || node.regionId || node.sizeId);
  });

  const uniqIds = uniq(flatten(ids));

  const semiTransparentColors = colors.map(c => {
    c = rgb(c);
    c.opacity = 0.6;
    return c;
  });

  const color = scaleOrdinal()
    .domain(uniqIds)
    .range(semiTransparentColors);

  const breadcrumb = svg.append('g').attr('class', 'grandparent');

  breadcrumb
    .append('rect')
    .attr('y', -margin.top)
    .attr('width', svgWidth)
    .attr('height', margin.top)
    .attr('fill', 'transparent');

  const backLinkClickTarget = breadcrumb
    .append('rect')
    .attr('class', 'click-target')
    .attr('y', -margin.top)
    .attr('width', 50)
    .attr('height', margin.top)
    .attr('fill', 'transparent');

  const title = breadcrumb
    .append('text')
    .attr('class', 'title')
    .attr('x', '50%')
    .attr('y', 6 - margin.top)
    .attr('dy', '.75em')
    .attr('text-anchor', 'middle');

  const backLink = breadcrumb
    .append('text')
    .attr('class', 'back')
    .attr('x', '0')
    .attr('y', 6 - margin.top)
    .attr('dy', '.75em')
    .attr('fill', '#0D8BAB')
    .attr('font-size', '12px')
    .text('‹ BACK');

  const viewListClickTarget = breadcrumb
    .append('rect')
    .attr('class', 'click-target')
    .attr('y', -margin.top)
    .attr('x', width - 100)
    .attr('width', 100)
    .attr('height', margin.top)
    .attr('fill', 'transparent');

  breadcrumb
    .append('text')
    .attr('class', 'view-list')
    .attr('x', width - 70)
    .attr('y', 6 - margin.top)
    .attr('dy', '.75em')
    .attr('fill', '#0D8BAB')
    .attr('font-size', '12px')
    .text('VIEW LIST »');

  const tm = treemap()
    .tile(
      treemapResquarify.ratio(
        (svgHeight / svgWidth) * 0.5 * (1 + Math.sqrt(5)),
      ),
    )
    .size([svgWidth - 1, svgHeight - margin.top * 2 - 1])
    .round(false);
  // .paddingInner(1);

  const root = hierarchy(data)
    .eachBefore(d => {
      d.id = (d.parent ? `${d.parent.id}.` : '') + d.data.label;
    })
    .sum(d => d.size)
    .sort((a, b) => b.height - a.height || b.value - a.value);

  initialize(root);
  accumulate(root);
  layout(root);
  tm(root);
  display(window.currentNode || root);

  function initialize(node) {
    node.x = 0;
    node.y = 0;
    node.x1 = svgWidth;
    node.y1 = svgHeight;
    node.depth = 0;
  }

  // Aggregate the values for internal nodes. This is normally done by the
  // treemap layout, but not here because of our custom implementation.
  // We also take a snapshot of the original children (_children) to avoid
  // the children being overwritten when when layout is computed.
  function accumulate(d) {
    // eslint-disable-next-line no-cond-assign
    if ((d._children = d.children)) {
      // eslint-disable-next-line no-return-assign
      return (d.value = d.children.reduce((p, v) => {
        return p + accumulate(v);
      }, 0));
    }
    return d.value;
  }

  // Compute the treemap layout recursively such that each group of siblings
  // uses the same size (1×1) rather than the dimensions of the parent cell.
  // This optimizes the layout for the current zoom state. Note that a wrapper
  // object is created for the parent node for each group of siblings so that
  // the parent’s dimensions are not discarded as we recurse. Since each group
  // of sibling was laid out in 1×1, we must rescale to fit using absolute
  // coordinates. This lets us use a viewport to zoom.
  function layout(d) {
    if (d._children) {
      d._children.forEach(c => {
        c.x0 = d.x0 + c.x0 * d.x1;
        c.y0 = d.y0 + c.y0 * d.y1;
        c.x1 *= d.x1;
        c.y1 *= d.y1;
        c.parent = d;
        layout(c);
      });
    }
  }

  function getLabel(d) {
    const { strategyName, regionName, label } = d.data;
    return strategyName || regionName || label;
  }

  function display(node) {
    window.currentNode = node;
    title.datum(node.parent).text(name(node) || 'All Strategies');
    backLink.datum(node.parent).text(d => (d ? '‹ BACK' : ''));
    backLinkClickTarget.datum(node.parent).on('click', transition);
    viewListClickTarget
      .datum(node)
      .on('click', (d, n) => transition(d, { ...n, viewList: true }));

    const g1 = svg
      .insert('g', '.grandparent')
      .datum(node)
      .attr('class', 'depth');

    const g = g1
      .selectAll('g')
      .data(node._children ?? 0)
      .enter()
      .append('g');

    g.filter(d => d._children)
      .classed('children', true)
      .on('click', transition);

    const children = g
      .selectAll('.child')
      .data(d => d._children || [d])
      .enter()
      .append('g');

    children
      .append('rect')
      .attr('class', 'child')
      .style('fill', d =>
        color(d.data.strategyId || d.data.regionId || d.data.sizeId),
      )
      .call(rect);

    g.append('rect')
      .attr('class', 'parent')
      .on('mouseover', handleEvent(mouseEventRefs.itemOver))
      .on('mouseout', handleEvent(mouseEventRefs.itemOut))
      .on('click', transition)
      .style('fill', d =>
        color(d.data.strategyId || d.data.regionId || d.data.sizeId),
      )
      .call(rect);

    const t = g
      .append('text')
      .attr('class', 'ptext')
      .attr('dx', '.25em')
      .attr('dy', '1em');

    t.append('tspan').attr('class', 'label').text(getLabel);

    t.call(text);

    function transition(event, d) {
      if (transitioning || !d) return;
      if (!d._children)
        return handleEvent(mouseEventRefs.itemClick)(event, d);

      transitioning = true;
      const g2 = display(d);
      const t1 = g1.transition().duration(750);
      const t2 = g2.transition().duration(750);

      // Update the domain only after entering new elements.
      x.domain([d.x0, d.x0 + (d.x1 - d.x0)]);
      y.domain([d.y0, d.y0 + (d.y1 - d.y0)]);

      // Enable anti-aliasing during the transition.
      svg.style('shape-rendering', null);

      // Draw child nodes on top of parent nodes.
      svg.selectAll('.depth').sort((a, b) => {
        return a.depth - b.depth;
      });

      // Fade-in entering text.
      g2.selectAll('text').style('fill-opacity', 0);

      // Transition to the new view.
      t1.selectAll('.ptext').call(text).style('fill-opacity', 0);
      t2.selectAll('.ptext').call(text).style('fill-opacity', 0.85);
      t1.selectAll('rect').call(rect);
      t2.selectAll('rect').call(rect);

      // Remove the old node when the transition is finished.
      t1.remove().on('end', () => {
        svg.style('shape-rendering', 'crispEdges');

        handleEvent(mouseEventRefs.itemClick)(event, d);
        transitioning = false;
      });
    }
    return g;
  }

  function text(selection) {
    selection
      .selectAll('tspan')
      .attr('x', d => x(d.x0) + 5)
      .attr('y', d => y(d.y0) + 5)
      .style('opacity', function setOpacity(d) {
        const w = x(d.x1) - x(d.x0);
        return this.getComputedTextLength() < w - 6 ? 1 : 0;
      });
  }

  function rect(selection) {
    selection
      .attr('x', d => x(d.x0))
      .attr('y', d => y(d.y0))
      .attr('width', d => x(d.x1) - x(d.x0))
      .attr('height', d => y(d.y1) - y(d.y0));
  }

  function name(node) {
    const { data: d } = node;
    const { strategyName, regionName, label } = d;
    const displayLabel = strategyName || regionName || label;
    return node.parent
      ? `${name(node.parent)} ${
          name(node.parent) ? '·' : ''
        } ${displayLabel}`
      : displayLabel;
  }
}

export default draw;
