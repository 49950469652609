import React from 'react';
import styled from 'styled-components';
import ButtonLarge from '../ButtonLarge';
import IconPaperPlane from '../IconPaperPlane';

const Icon = styled(IconPaperPlane)`
  color: #a6a6a6;
  width: 17px;
`;

const ButtonLargeShare = props => {
  return (
    <ButtonLarge {...props}>
      <Icon />
      Share
    </ButtonLarge>
  );
};

ButtonLargeShare.defaultProps = {};

ButtonLargeShare.propTypes = {};

export default ButtonLargeShare;
