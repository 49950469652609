import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStrategyPerformance } from '../store/strategy-performance';

function useSubStrategyPerformance() {
  const dispatch = useDispatch();
  const strategyPerformance = useSelector(state => state.strategyPerformance);
  const [subStrategyPerfomance, setSubStrategyPerfomance] = useState(null);

  useEffect(() => {
    if (!strategyPerformance.fetched && !strategyPerformance.fetching) {
      dispatch(fetchStrategyPerformance());
    }
  }, [dispatch, strategyPerformance.fetched, strategyPerformance.fetching]);

  useEffect(() => {
    if (strategyPerformance.fetched) {
      setSubStrategyPerfomance(strategyPerformance.byId);
    }
  }, [strategyPerformance.byId, strategyPerformance.fetched]);

  return subStrategyPerfomance;
}

export default useSubStrategyPerformance;
