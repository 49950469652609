export function getFaviconUrl(url) {
  try {
    const pathArray = url.split('/');
    const protocol = pathArray[0];
    const host = pathArray[2];
    const domain = `${protocol}//${host}`;
    return `https://www.google.com/s2/favicons?domain=${domain}`;
  } catch (e) {
    return null;
  }
}
