import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popover } from 'src/components';

const Layout = styled(Popover)`
  .MuiPaper-root {
    color: #fffc;
    width: 340px;
    padding-bottom: 1.5em;
  }
`;

function FundBookmarkControlPopover({ children, anchorEl, onClose, className }) {
  if (!anchorEl) return null;
  return (
    <Layout
      className={className}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      useCloseButton
    >
      {children}
    </Layout>
  );
}

FundBookmarkControlPopover.defaultProps = {};

FundBookmarkControlPopover.propTypes = {
  children: PropTypes.any,
};

export default FundBookmarkControlPopover;
