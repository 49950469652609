import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { IconUnlock } from 'src/components';
import Modal from 'src/components/molecules/Modal';
import routes from 'src/config/routes';
import { navigate } from '@reach/router';

const noWrapCss = css`
  overflow: hidden;
  white-space: nowrap;
`;

const fadeInCss = css`
  opacity: ${p => (p.$visible ? 1 : 0)};
  transition: opacity 500ms ease 500ms;
`;

const liteModalCss = css`
  z-index: 1;
  max-width: 360px;
  text-align: center;
`;

function PaywallMessage({ className }) {
  return (
    <div className={className} css={liteModalCss}>
      <p>
        Your free trial has ended. Subscribe to get unlimited access to all companies, funds and functionality
        in&nbsp;FundFilter.
      </p>
    </div>
  );
}

const compactModalCss = css`
  text-align: center;
  max-width: 360px;
  font-size: 12px;
  padding-top: 15px;

  --modal-padding: 15px;
  --modal-v-spacing: 10px;
`;

const inlineSectionCss = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
`;

function handleClick() {
  navigate(routes.ACCOUNT);
}

function PaywallModalCompact({ className }) {
  return (
    <Modal className={className} css={compactModalCss}>
      <Modal.Body>
        <PaywallMessage />
      </Modal.Body>
      <Modal.Actions css={inlineSectionCss}>
        <Modal.Action primary onClick={handleClick}>
          <IconUnlock />
          Subscribe
        </Modal.Action>
        <Modal.Action onClick={handleClick}>Plans & Pricing »</Modal.Action>
      </Modal.Actions>
    </Modal>
  );
}

function PaywallModalFull({ className }) {
  return (
    <Modal
      className={className}
      css={`
        text-align: center;
      `}
    >
      <Modal.Header css={noWrapCss}>Subscriber Access</Modal.Header>
      <Modal.Body>
        <PaywallMessage />
      </Modal.Body>
      <Modal.Actions>
        <Modal.Action primary onClick={handleClick}>
          <IconUnlock />
          Subscribe
        </Modal.Action>
        <Modal.Action css={noWrapCss} onClick={handleClick}>
          Plans & Pricing »
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
}

function PaywallModal({ lite, compact, className }) {
  const [visible, setVisible] = useState(false);

  useEffect(function () {
    const timeout = setTimeout(() => setVisible(true), 0);
    return () => clearTimeout(timeout);
  }, []);

  if (lite) {
    return <PaywallMessage className={className} css={fadeInCss} $visible={visible} />;
  }

  if (compact) {
    return <PaywallModalCompact className={className} css={fadeInCss} $visible={visible} />;
  }

  return <PaywallModalFull className={className} css={fadeInCss} $visible={visible} />;
}

PaywallModal.defaultProps = {};

PaywallModal.propTypes = {
  lite: PropTypes.bool,
  compact: PropTypes.bool,
  full: PropTypes.bool,
  children: PropTypes.any,
};

export default PaywallModal;
