export const abbreviations = {
  thousand: { value: 1e3, abbeviation: 'K' },
  million: { value: 1e6, abbeviation: 'M' },
  billion: { value: 1e9, abbeviation: 'B' },
  trillion: { value: 1e12, abbeviation: 'T' },
};

export const abbreviate = abbreviation => value => {
  return {
    value: (value / abbreviation.value).toFixed(3),
    abbr: abbreviation.abbeviation,
  };
};
