import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSchoolEmployees } from '../store/school-employees';
import useEmployeeBookmarks from './useEmployeeBookmarks';

function mapSchools(employee) {
  let lastSchoolName = '';
  let lastSchoolId = '';
  let lastParentSchoolName = '';
  let lastParentSchoolId = '';
  let prevSchoolName = '';
  let prevSchoolId = '';
  let prevParentSchoolName = '';
  let prevParentSchoolId = '';

  if (employee.education) {
    if (employee.education.length > 0) {
      const school = employee.education[0];
      lastSchoolName = school.schoolName;
      lastSchoolId = school.schoolId;
      lastParentSchoolName = school.parentSchoolName;
      lastParentSchoolId = school.parentSchoolId;
    }
    if (employee.education.length > 1) {
      const school = employee.education[1];
      prevSchoolName = school.schoolName;
      prevSchoolId = school.schoolId;
      prevParentSchoolName = school.parentSchoolName;
      prevParentSchoolId = school.parentSchoolId;
    }
  }

  return {
    lastSchoolName,
    lastSchoolId,
    lastParentSchoolName,
    lastParentSchoolId,
    prevSchoolName,
    prevSchoolId,
    prevParentSchoolId,
    prevParentSchoolName,
  };
}

export default function useSchoolEmployees({ schoolId }) {
  const dispatch = useDispatch();
  const schoolEmployees = useSelector(state => state.employee.school);
  const [employees, setEmployees] = useState(null);
  const employeeBookmarks = useEmployeeBookmarks({
    asObjectOrArray: 'object',
  });

  useEffect(() => {
    if (schoolId && !schoolEmployees.byId[schoolId]) {
      dispatch(fetchSchoolEmployees({ schoolId }));
    }
  }, [dispatch, schoolEmployees.byId, schoolId]);

  useEffect(() => {
    if (schoolId && schoolEmployees.byId[schoolId] && schoolEmployees.byId[schoolId].fetched && employeeBookmarks) {
      const emps = schoolEmployees.byId[schoolId].employees
        .map(employee => {
          const schools = mapSchools(employee);
          const bookmarked = employeeBookmarks[employee.employeeId];
          return {
            ...employee,
            ...schools,
            bookmarked,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      setEmployees(emps);
    }
  }, [employeeBookmarks, schoolEmployees.byId, schoolId]);

  return employees;
}
