import {
  SUBJECT_DISTRIBUTION_BAR_COLOR,
  SUBJECT_CONTRIBUTION_BAR_COLOR,
  FORECAST_DISTRIBUTION_BAR_COLOR,
  FORECAST_CONTRIBUTION_BAR_COLOR,
  LINE_WIDTH,
} from './constants';

function joinBarFns({ className, accessor, color, xScale, yScale, animate, dashed }) {
  return [
    function enter(selection) {
      selection
        .append('line')
        .attr('class', className || accessor)
        .attr('x1', d => xScale(d.date))
        .attr('x2', d => xScale(d.date))
        .attr('y1', yScale(0))
        .attr('y2', d => yScale(0) - 2 * Math.sign(d[accessor]))
        .style('stroke', color)
        .style('stroke-dasharray', dashed)
        .transition(animate)
        .attr('y1', d => {
          return yScale(d[accessor]);
        });
    },
    function update(selection) {
      selection
        .transition(animate)
        .attr('x1', d => xScale(d.date))
        .attr('y1', d => yScale(d[accessor], 0))
        .attr('x2', d => xScale(d.date))
        .attr('y2', d => yScale(0) - 2 * Math.sign(d[accessor]));
    },
    function exit(selection) {
      selection
        .transition(animate)
        .attr('x1', d => xScale(d.date))
        .attr('x2', d => xScale(d.date))
        .attr('y1', yScale(0))
        .attr('y2', d => yScale(0) - 2 * Math.sign(d[accessor]))
        .remove();
    },
  ];
}

function drawBars(data, opts) {
  const { root, xScale, yScale, animate, colors } = opts;

  const group = root
    .selectAll('.net-cashflow')
    .data([data])
    .join('g')
    .attr('class', 'net-cashflow')
    .style('stroke-width', LINE_WIDTH);

  group
    .selectAll('.forecastBarRise-highlight')
    .data(data.filter(d => Number.isFinite(d.forecastBarRise) && d.highlight))
    .join(
      ...joinBarFns({
        className: 'forecastBarRise-highlight',
        accessor: 'forecastBarRise',
        color: colors?.forecastDistributionBarColor ?? FORECAST_DISTRIBUTION_BAR_COLOR,
        xScale,
        yScale,
        animate,
      })
    );

  group
    .selectAll('.forecastBarFall-highlight')
    .data(data.filter(d => Number.isFinite(d.forecastBarFall) && d.highlight))
    .join(
      ...joinBarFns({
        className: 'forecastBarFall-highlight',
        accessor: 'forecastBarFall',
        color: colors?.forecastContributionBarColor ?? FORECAST_CONTRIBUTION_BAR_COLOR,
        xScale,
        yScale,
        animate,
      })
    );

  group
    .selectAll('.forecastBarRise-extended')
    .data(data.filter(d => Number.isFinite(d.forecastBarRise) && !d.highlight))
    .join(
      ...joinBarFns({
        className: 'forecastBarRise-extended',
        accessor: 'forecastBarRise',
        color: colors?.forecastDistributionBarColor ?? FORECAST_DISTRIBUTION_BAR_COLOR + 'cc',
        dashed: '3 3',
        xScale,
        yScale,
        animate,
      })
    );

  group
    .selectAll('.forecastBarFall-extended')
    .data(data.filter(d => Number.isFinite(d.forecastBarFall) && !d.highlight))
    .join(
      ...joinBarFns({
        className: 'forecastBarFall-extended',
        accessor: 'forecastBarFall',
        color: colors?.forecastContributionBarColor ?? FORECAST_CONTRIBUTION_BAR_COLOR + 'cc',
        dashed: '3 3',
        xScale,
        yScale,
        animate,
      })
    );

  group
    .selectAll('.subjectBarRise')
    .data(data.filter(d => Number.isFinite(d.subjectBarRise)))
    .join(
      ...joinBarFns({
        accessor: 'subjectBarRise',
        color: colors?.subjectDistributionBarColor ?? SUBJECT_DISTRIBUTION_BAR_COLOR,
        xScale,
        yScale,
        animate,
      })
    );

  group
    .selectAll('.subjectBarFall')
    .data(data.filter(d => Number.isFinite(d.subjectBarFall)))
    .join(
      ...joinBarFns({
        accessor: 'subjectBarFall',
        color: colors?.subjectContributionBarColor ?? SUBJECT_CONTRIBUTION_BAR_COLOR,
        xScale,
        yScale,
        animate,
      })
    );

  return root;
}

export default drawBars;
