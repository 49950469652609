import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, ButtonRounded } from 'src/components';
import { TIME_RANGES } from './constants';

const ButtonContainer = styled(Box)``;
const TimeRangeButton = styled(ButtonRounded)`
  min-width: unset;
  margin-right: ${({ theme }) => `${theme.spacing(0.5)}px`};
`;

function TimeRangeButtons({ selectedValue, show3And5YButtons, onTimeRangeSelect }) {
  return (
    <ButtonContainer>
      <TimeRangeButton aria-label="1Q" selected={selectedValue === 3} onClick={() => onTimeRangeSelect(TIME_RANGES[3])}>
        1Q
      </TimeRangeButton>
      <TimeRangeButton aria-label="2Q" selected={selectedValue === 6} onClick={() => onTimeRangeSelect(TIME_RANGES[6])}>
        2Q
      </TimeRangeButton>
      <TimeRangeButton
        aria-label="1Y"
        selected={selectedValue === 12}
        onClick={() => onTimeRangeSelect(TIME_RANGES[12])}
      >
        1Y
      </TimeRangeButton>
      {show3And5YButtons && (
        <>
          <TimeRangeButton
            aria-label="3Y"
            selected={selectedValue === 36}
            onClick={() => onTimeRangeSelect(TIME_RANGES[36])}
          >
            3Y
          </TimeRangeButton>
          <TimeRangeButton
            aria-label="5Y"
            selected={selectedValue === 60}
            onClick={() => onTimeRangeSelect(TIME_RANGES[60])}
          >
            5Y
          </TimeRangeButton>
        </>
      )}
    </ButtonContainer>
  );
}

TimeRangeButtons.defaultProps = {
  selectedValue: 3,
  show3And5YButtons: false,
  onTimeRangeSelect: () => {},
};

TimeRangeButtons.propTypes = {
  selectedValue: PropTypes.number,
  show3And5YButtons: PropTypes.bool,
  onTimeRangeSelect: PropTypes.func,
};

export { TimeRangeButtons };
