import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { md } from 'src/config/layout';
import { useFund, useAllFunds, useFundPeerGroupFilter, useFilteredFundPeers } from 'src/hooks';
import { lookup } from 'src/lookup';
import { FundPMEIndexChart, FundPerformanceTooltip, Select } from 'src/components';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  padding: 0.75rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 0;
  }
`;

const SelectInput = styled(Select)`
  font-size: inherit;

  & .MuiInputBase-root {
    height: 28px;
  }
`;

const InsufficientDataMessage = styled.div`
  margin: 0 auto;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  text-align: center;
  text-transform: uppercase;
`;

function formatPmeCalcs({ label }) {
  return label;
}

function FundPMEViews({ fundId }) {
  const [chartTooltipOpen, setChartTooltipOpen] = useState(false);
  const [chartTooltipAnchorEl, setChartTooltipAnchorEl] = useState(null);
  const [chartTooltipData, setChartTooltipData] = useState(null);
  const [chartTooltipIndex, setChartTooltipIndex] = useState(null);
  const [selectedCalcs, setSelectedCalcs] = useState(lookup.pmeCalcs.da.label);

  const fund = useFund({ fundId });
  const funds = useAllFunds();

  const { regionsFilter, secondRegionsFilter, vintagesFilter, sizesFilter, strategiesFilter } =
    useFundPeerGroupFilter(fund);

  const peers = useFilteredFundPeers({
    funds,
    vintages: vintagesFilter,
    regions: regionsFilter,
    secondRegions: secondRegionsFilter,
    sizes: sizesFilter,
    strategies: strategiesFilter,
  });

  const calcs = useMemo(() => Object.values(lookup.pmeCalcs).find(o => o.label === selectedCalcs), [selectedCalcs]);

  const insufficientData = funds?.length === 0 || fund?.pmeValues?.length === 0 || !fund?.pmeValues;

  function handleClickAway(e) {
    if (!e.target.classList.contains('rect')) {
      closeTooltips();
    }
  }

  function closeTooltips() {
    setChartTooltipOpen(false);
    setChartTooltipAnchorEl(null);
  }

  function handleItemClick(node, d) {
    closeTooltips();
    setChartTooltipData(fund);
    setChartTooltipAnchorEl(node);
    setChartTooltipOpen(true);
    setChartTooltipIndex(d.index);
  }

  function handleViewFund(f) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundManagerFundSelect);
    navigate(`/funds/${f.fundId}`);
  }

  return (
    <Layout>
      <Header>
        <SelectInput
          preset="compact"
          labelType="inline"
          label="PME Calcs:"
          variant="outlined"
          size="small"
          items={Object.values(lookup.pmeCalcs).map(formatPmeCalcs)}
          onChange={event => setSelectedCalcs(event.target.value)}
          value={selectedCalcs}
        />
      </Header>
      {insufficientData && (
        <InsufficientDataMessage>
          There are no PME calculations for this fund due to unavailable index data.
        </InsufficientDataMessage>
      )}
      {!insufficientData && (
        <>
          <FundPMEIndexChart
            fund={fund}
            peers={peers}
            calcs={calcs}
            onItemClick={handleItemClick}
            onItemTouchStart={handleItemClick}
          />
          <FundPerformanceTooltip
            onClickAway={handleClickAway}
            fund={chartTooltipData}
            open={chartTooltipOpen}
            calcs={calcs}
            index={chartTooltipIndex}
            anchorEl={chartTooltipAnchorEl}
            onClose={closeTooltips}
            onViewFund={handleViewFund}
          />
        </>
      )}
    </Layout>
  );
}

FundPMEViews.defaultProps = {};

FundPMEViews.propTypes = {
  fundId: PropTypes.string.isRequired,
};

export default FundPMEViews;
