import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { navigate } from '@reach/router';
import Vimeo from '@u-wave/react-vimeo';
import { parse } from 'query-string';
import { decodeToken } from '@fundfilter/core/src/utils/object';
import { useCreateUserAccount } from 'src/hooks';
import { useGet as useGetAuth } from 'src/services/filter-api/authentication';
import { Button, ErrorMessage, IconYoutubePlay, NarrowViewportMessage, LoadingIndicator } from 'src/components';
import Form from './Form';
import routes from 'src/config/routes';
import { sm, md, lg } from 'src/config/layout';
import AuthLayout from 'src/components/templates/AuthLayout';
import DevHelper from './DevHelper';

const isEmulated = Boolean(process.env.REACT_APP_FIREBASE_EMULATED);

const VIDEO_ID = '640532035';
const VIDEO_WIDTH = 800;
const VIDEO_HEIGHT = 600;

const CardLayout = styled(AuthLayout.Card)`
  max-width: 54rem;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: flex;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    max-width: 64em;
  }
`;

const MarketingSection = styled.section`
  display: none;

  h3 {
    text-align: center;
  }

  p {
    font-weight: 300;
    color: #b1b1b1;
    margin: 0;
  }

  p + p {
    margin-top: 1.3333em;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 2em;
    background-color: rgba(255, 255, 255, 0.09);
    border-radius: 6px 0 0 6px;
  }
`;

const Heading = styled.h3`
  & span {
    display: none;
    margin-left: 5px;
    font-size: 0.7em;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    & span {
      display: inline;
    }
  }
`;

const FormSection = styled.section`
  flex: 2;

  padding: 2rem 1rem;

  @media (min-width: ${sm.BREAKPOINT}px) {
    padding: 2rem;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    padding: 2rem 5rem;

    & > section + section {
      margin-top: 3em;
    }
  }
`;

const VideoSection = styled.section`
  display: none;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: block;
  }

  iframe {
    display: block;
  }
`;

const Logo = styled.img`
  height: 38px;
`;

const VideoThumb = styled.div`
  width: 414px;
  height: 239px;
  margin: 36px auto;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  background-image: url('/assets/img/promo-video-thumb.webp');
  background-size: cover;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 100;

  svg {
    width: 3em;
    height: auto;
  }

  span {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 50%, 0);
    bottom: 25%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  left: 0;
  font-size: 1.5em;
  width: 1.5em;
  height: 1.5em;
  line-height: 1;
  background: black;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 1px 2px 5px black, inset 0 0 0 2px white;
  border-radius: 50%;
  font-weight: 100;
`;

const Footer = styled.footer`
  font-size: 14px;
  margin-top: 7px;
`;

const HomeLink = styled.a`
  display: block;
  margin: 0 auto;
  padding: 5px 14px;
  color: #afafaf;
  font-size: 0.9em;
  text-decoration: none;
  border: 1px solid #0d8bab;
  border-radius: 5px;
`;

function UserSignUpPage() {
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAutoSubmitted, setIsAutoSubmitted] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const createUserAccount = useCreateUserAccount();
  const { isLoading, isAuthenticated } = useGetAuth();

  const handleSubmit = useCallback(
    async function ({ email, password, name, remember, educational, isAnonymous }) {
      try {
        setIsSubmitted(true);
        setIsAutoSubmitted(isAnonymous);

        await createUserAccount({
          email,
          password,
          name,
          remember,
          educational,
          isAnonymous,
        });

        setIsSubmitted(false);
        setIsAutoSubmitted(false);
        navigate(routes.MARKET_FILTER);
      } catch (err) {
        setIsSubmitted(false);
        setIsAutoSubmitted(false);
        setError(err.message);
      }
    },
    [createUserAccount]
  );

  const playVideo = useCallback(
    function () {
      if (!isVideoPaused) return;

      setIsVideoPaused(false);
    },
    [isVideoPaused]
  );

  const pauseVideo = useCallback(
    function () {
      if (isVideoPaused) return;

      setIsVideoPaused(true);
    },
    [isVideoPaused]
  );

  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated) navigate(routes.MARKET_FILTER);
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    const query = parse(window.location.search || '');
    if (!Object.keys(query).includes('token')) return;

    try {
      const params = decodeToken(query.token);
      if (params.method !== 'anonymous') return;

      handleSubmit({
        name: params.name,
        email: params.email,
        password: undefined,
        educational: params.edu,
        remember: true,
        isAnonymous: true,
      });
    } catch (err) {
      if (err.message.toLowerCase() === 'invalid token') {
        setError('Could not sign you in automatically, please use the signup form above to create your account');
      } else {
        setError(err.message);
      }
    }
  }, [handleSubmit]);

  if (isAutoSubmitted) {
    return (
      <AuthLayout flipped={false}>
        <CardLayout>
          <MarketingSection>
            <h3>
              <Logo src="/assets/img/logo_with_brand.svg" alt="FundFilter" />
            </h3>
            <div
              css={`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 13px;
              `}
            >
              <p>Creating your account, please wait...</p>
              <br />
              <LoadingIndicator display={true} />
            </div>
          </MarketingSection>
        </CardLayout>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout flipped={isFlipped}>
      <CardLayout>
        <div
          css={`
            position: relative;
          `}
        >
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={isFlipped ? 'back' : 'front'}
              initial={{ width: '100%', opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <VideoSection
                css={`
                  visibility: ${!isFlipped && 'hidden'};
                  position: absolute;
                  top: 0;
                  left: 0;
                `}
              >
                <CloseButton
                  onClick={() => {
                    pauseVideo();
                    setIsFlipped(false);
                  }}
                >
                  &times;
                </CloseButton>

                <Vimeo
                  video={VIDEO_ID}
                  width={VIDEO_WIDTH}
                  height={VIDEO_HEIGHT}
                  onPause={pauseVideo}
                  onPlay={playVideo}
                  paused={isVideoPaused}
                />
              </VideoSection>

              <div
                css={`
                  display: flex;
                  visibility: ${isFlipped ? 'hidden' : 'visible'};
                `}
              >
                <MarketingSection>
                  <h3>
                    <Logo src="/assets/img/logo_with_brand.svg" alt="FundFilter" />
                  </h3>
                  <p>
                    Welcome! We’ve built a new way to analyze thousands of private market funds and fund managers,
                    utilizing data visualization and deep analysis.{' '}
                  </p>

                  <p>Create a free account today to see how FundFilter makes private market data more accessible.</p>

                  <VideoThumb
                    onClick={() => {
                      setIsFlipped(true);
                      playVideo();
                    }}
                  >
                    <IconYoutubePlay />
                    <span>Two Minute Intro</span>
                  </VideoThumb>

                  <HomeLink href="https://fundfilter.com">Back to Home</HomeLink>
                </MarketingSection>

                <FormSection>
                  {isEmulated && <DevHelper />}

                  <NarrowViewportMessage />

                  <section>
                    <Heading>
                      Start your 14 Day Free Trial in Seconds <span>(No credit card required)</span>
                    </Heading>
                    <Form onSubmit={handleSubmit} isSubmitted={isSubmitted} />
                    <Footer>{error && <ErrorMessage>{error}</ErrorMessage>}</Footer>
                  </section>

                  <section>
                    <h3>Already have an account?</h3>
                    <Button
                      variant="contained"
                      color="primary"
                      $block
                      disabled={isSubmitted}
                      onClick={() => navigate(routes.USER_SIGN_IN)}
                    >
                      Sign in
                    </Button>
                  </section>
                </FormSection>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </CardLayout>
    </AuthLayout>
  );
}

export default UserSignUpPage;
