import * as d3 from 'd3';
import {
  FAILED_BREAKEVEN_SUBJECT_LINE_COLOR,
  FAILED_BREAKEVEN_FORECAST_LINE_COLOR,
  SUBJECT_LINE_COLOR,
  FORECAST_LINE_COLOR,
  LINE_WIDTH,
} from './constants';

function joinCurveFns({ accessor, className, xScale, yScale, animate, color, dashed }) {
  const flatLine = d3
    .line()
    .x(d => xScale(d.date))
    .y(yScale(0));

  const curveLineFn = {
    forecastCurve: d3
      .line()
      .defined(d => {
        return Number.isFinite(d.forecastCurve);
      })
      .x(d => xScale(d.date))
      .y(d => yScale(d.forecastCurve)),

    forecastCurveHighlight: d3
      .line()
      .defined(d => {
        if (d.placeholder) return false;
        return Number.isFinite(d.forecastCurve) && d.highlight;
      })
      .x(d => xScale(d.date))
      .y(d => yScale(d.forecastCurve)),

    subjectCurve: d3
      .line()
      .defined(d => Number.isFinite(d.subjectCurve))
      .x(d => xScale(d.date))
      .y(d => yScale(d.subjectCurve)),
  }[accessor];

  function getColor(d) {
    function getColorFn(x) {
      if (typeof color === 'string') return color;
      return color(x);
    }

    return getColorFn(d);
  }

  return [
    function enter(selection) {
      selection
        .append('path')
        .attr('class', className || accessor)
        .attr('stroke', getColor)
        .attr('d', flatLine)
        .style('stroke-dasharray', dashed)
        .transition(animate)
        .attr('d', curveLineFn);
    },
    function update(selection) {
      selection.transition(animate).attr('d', curveLineFn).attr('stroke', getColor);
    },
    function exit(selection) {
      selection.transition(animate).attr('d', flatLine);
    },
  ];
}

function getForecastLineColor(colors, isFallBelowBreakeven) {
  return isFallBelowBreakeven
    ? colors?.failedBreakevenForecastLineColor ?? FAILED_BREAKEVEN_FORECAST_LINE_COLOR
    : colors?.forecastLineColor ?? FORECAST_LINE_COLOR;
}

function getSubjectLineColor(colors, isFallBelowBreakeven) {
  return isFallBelowBreakeven
    ? colors?.failedBreakevenSubjectLineColor ?? FAILED_BREAKEVEN_SUBJECT_LINE_COLOR
    : colors?.subjectLineColor ?? SUBJECT_LINE_COLOR;
}

function drawCurves(data, opts) {
  const { root, uiScale, xScale, yScale, colors, animate, isUnavailable, isFallBelowBreakeven } = opts;

  const group = root
    .selectAll('.curves')
    .data([data])
    .join('g')
    .attr('class', 'curves')
    .style('fill', 'none')
    .style('stroke-width', LINE_WIDTH);

  group
    .selectAll('.forecastCurve')
    .data([data])
    .join(
      ...joinCurveFns({
        accessor: 'forecastCurve',
        color: isUnavailable ? 'gray' : getForecastLineColor(colors, isFallBelowBreakeven) + 88,
        uiScale,
        xScale,
        yScale,
        animate,
        dashed: '3 3',
      })
    );

  group
    .selectAll('.forecastCurve-highlight')
    .data([data.filter(d => Number.isFinite(d.forecastCurve) && d.highlight)])
    .join(
      ...joinCurveFns({
        accessor: 'forecastCurveHighlight',
        className: 'forecastCurve-highlight',
        color: getForecastLineColor(colors, isFallBelowBreakeven),
        uiScale,
        xScale,
        yScale,
        animate,
      })
    );

  group
    .selectAll('.subjectCurve')
    .data([data])
    .join(
      ...joinCurveFns({
        accessor: 'subjectCurve',
        color: getSubjectLineColor(colors, isFallBelowBreakeven),
        uiScale,
        xScale,
        yScale,
        animate,
      })
    );

  return root;
}

export default drawCurves;
