import React from 'react';
import { useMemo } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { TextField, InputAdornment, InputLabel } from '@material-ui/core';
import { NumberField, SelectPortfolioControl } from 'src/components';
import { useList as useListPortfolios } from 'src/services/filter-api/userPortfolios/queries';
import { useUpdate as useUpdatePortfolio } from 'src/services/filter-api/userPortfolios/mutations';

function ForecastItemsHeader({ className, portfolioId, onChangePortfolio }) {
  const listPortfolios = useListPortfolios();
  const updatePortfolio = useUpdatePortfolio();

  const portfolio = useMemo(() => {
    if (!portfolioId) return null;
    if (!listPortfolios.data) return null;
    return listPortfolios.data.find(p => p.portfolioId === portfolioId);
  }, [listPortfolios.data, portfolioId]);

  const handleUpdateNavTarget = event => {
    const value = event.target.value.replaceAll(',', '');
    const number = Number.parseInt(value, 10);
    const navTarget = Number.isFinite(number) ? number : null;

    if (!portfolio) return;

    updatePortfolio.mutate({ portfolioId, navTarget });
  };

  const isPortfolioIdle = useMemo(() => {
    if (listPortfolios.isFetching) return false;
    if (listPortfolios.isLoading) return false;
    if (updatePortfolio.isLoading) return false;
    const { isIdle, isSuccess, isError } = updatePortfolio;
    return isIdle || isSuccess || isError;
  }, [listPortfolios.isFetching, listPortfolios.isLoading, updatePortfolio]);

  return (
    <header
      className={className}
      css={`
        display: grid;
        grid-auto-flow: column;
        gap: 20px;
        margin-bottom: 0.75em;
      `}
    >
      <div>
        <InputLabel
          css={`
            margin-bottom: 4px;
          `}
          shrink
        >
          Select Portfolio
        </InputLabel>
        <SelectPortfolioControl value={portfolioId} onChange={id => onChangePortfolio(id)} />
      </div>

      <TextField
        label={<>Portfolio&nbsp;Target&nbsp;NAV</>}
        variant="standard"
        value={portfolio?.navTarget ?? ''}
        onBlur={handleUpdateNavTarget}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disabled: !portfolio || !isPortfolioIdle,
          inputComponent: NumberField,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </header>
  );
}

ForecastItemsHeader.defaultProps = {
  selectedTags: [],
  setSelectedTags: funcStub('setNavTarget'),
  onChangeNavTarget: funcStub('setNavTarget'),
};

ForecastItemsHeader.propTypes = {
  selectedTags: PropTypes.array,
  setSelectedTags: PropTypes.func,
  navTarget: PropTypes.number,
  onChangeNavTarget: PropTypes.func,
};

export default ForecastItemsHeader;
