import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parseUrl } from 'src/utils';
import { Typography, Link, HeaderField, HeaderFieldLabel as Label, HeaderFieldValue as Value } from 'src/components';
import { useFundManagerFundsRaising } from 'src/hooks';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr;
  gap: 10px;
`;

const Header = styled.header`
  flex: 1;
  grid-row: span 2;
`;

const Name = styled(Typography).attrs({
  variant: 'h2',
})`
  cursor: pointer;
  color: #0d8bab;
  font-size: 20px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 4px;
  grid-column: span 4;
`;

const Description = styled(Typography).attrs({
  variant: 'body2',
})`
  padding-right: 4px;
  max-height: 7em;
  font-size: 11px;
  font-weight: 300;
  color: #939393;
  overflow-y: auto;
`;

const Field = styled(HeaderField)`
  margin: 0;
`;

function FundFundManagerSummary({ fundManager, location, onFundManagerSelect, ...rest }) {
  const fundManagerFundsRaising = useFundManagerFundsRaising(fundManager);
  const { websiteUrl, isRaising, active } = useMemo(
    () => ({
      websiteUrl: parseUrl(fundManager.websiteUrl),
      isRaising: Boolean(fundManagerFundsRaising?.find(f => f.fundStatus === 'Raising')),
      active: fundManager.description !== 'This fund manager is no longer active.',
    }),
    [fundManager, fundManagerFundsRaising]
  );

  return (
    <Layout column {...rest}>
      <Name onClick={() => onFundManagerSelect(fundManager)}>
        <span
          css={`
            color: #ccc;
          `}
        >
          {fundManager.name}
        </span>
        <span
          css={`
            display: block;
          `}
        >
          <span
            css={`
              font-size: 13px;
              color: #00abd4;
              border-bottom: 1px solid;
            `}
          >
            View Fund Manager
          </span>
        </span>
      </Name>
      {fundManager.fetched && !fundManager.userId && (
        <>
          <Header>
            <Description>{fundManager.description}</Description>
          </Header>

          <Field>
            <Label>FOUNDED</Label>
            <Value>{fundManager.dateFounded ? fundManager.dateFounded.substr(0, 4) : null}</Value>
          </Field>

          <Field>
            <Label>LOCATION</Label>
            <Value>{location.addressMeta && (location.addressMeta[0] || {}).city}</Value>
          </Field>

          <Field>
            <Label>EMPLOYEES</Label>
            <Value>{fundManager.employeeCount}</Value>
          </Field>

          <Field>
            <Label>STATUS</Label>
            <Value>{active ? 'Active' : 'Inactive'}</Value>
          </Field>

          <Field>
            <Label>FUNDRAISING</Label>
            <Value>{isRaising ? 'Yes' : 'No'}</Value>
          </Field>

          <Field>
            <Label>WEBSITE</Label>
            <Value>
              <Link color="textPrimary" href={websiteUrl.href} target="_blank" component="a">
                Link »
              </Link>
            </Value>
          </Field>
        </>
      )}
    </Layout>
  );
}

FundFundManagerSummary.defaultProps = {
  location: {},
  onFundManagerSelect: () => {},
};

FundFundManagerSummary.propTypes = {
  fundManager: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onFundManagerSelect: PropTypes.func,
};

export default FundFundManagerSummary;
