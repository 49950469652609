import { useEmployeeBookmarks, useEmployees } from 'src/hooks';

function useBookmarkedFunds() {
  const employeeIds = useEmployeeBookmarks({
    asObjectOrArray: 'array',
  });
  const employees = useEmployees({
    employeeIds,
    asObjectOrArray: 'array',
  });
  return employees;
}

export default useBookmarkedFunds;
