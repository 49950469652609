import { useQueryClient, useQuery, useMutation } from 'react-query';
import queryKeyFactory from '../queryKeyFactory';
import * as client from './client';
import { useIntercom } from 'react-use-intercom';
import fullstory from 'src/services/fullstory';

const QUERY_DEFAULTS = {
  cacheTime: Infinity,
  staleTime: Infinity,
};

const queryKeys = queryKeyFactory('user');

function updateIntercom(intercom, user) {
  try {
    intercom.update({
      userId: user.userId,
      name: user.name,
      email: user.email,
    });
  } catch (error) {
    console.error(error);
  }
}

function updateFullstory(user) {
  try {
    fullstory.identify({
      userId: user.userId,
      name: user.name,
      email: user.email,
    });
  } catch (error) {
    console.error(error);
  }
}

function useGet(userId) {
  const intercom = useIntercom();
  return useQuery({
    queryKey: queryKeys.detail(userId),
    async queryFn() {
      const user = await client.get(userId);
      return user;
    },
    enabled: Boolean(userId),
    onSuccess(user) {
      if (!user) return;

      updateIntercom(intercom, user);
      updateFullstory(user);
    },
    ...QUERY_DEFAULTS,
  });
}

function useCreate({ invalidate = true } = {}) {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.detail();
  const mutationFn = async ({ userId, ...data }) => {
    return await client.create(userId, data);
  };
  return useMutation({
    mutationFn,
    onSettled() {
      if (invalidate) queryClient.invalidateQueries(queryKey);
    },
  });
}

function useUpdate({ invalidate = true } = {}) {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.detail();
  const mutationFn = async ({ userId, ...data }) => {
    return await client.update(userId, data);
  };

  return useMutation({
    mutationFn,
    onSettled() {
      if (invalidate) queryClient.invalidateQueries(queryKey);
    },
  });
}

export { queryKeys, useGet, useCreate, useUpdate };
