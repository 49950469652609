import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { percent, multiple } from 'src/formatters';
import { formatDate } from 'src/utils/date';
import { Match, Link } from '@reach/router';
import { TooltipCloseIcon, TooltipRow, TooltipData, TooltipHeader, TooltipTitle, TooltipLabel } from 'src/components';

const multiple2 = multiple(2);
const percent1 = percent();

function pmeFormatter(values, key) {
  if (!values) return 0;
  const value = values[key];
  return key === 'daPmeValue' ? percent(2)(value) : multiple(2)(value);
}

const TooltipHint = styled.span`
  font-style: italic;
`;

function FundPreview({ fund, pmeCalcs, pmeIndex, onClose }) {
  const fundPath = `/funds/${fund.fundId}`;
  const { label: pmeLabel } = pmeCalcs;
  const pmeValue = useMemo(() => {
    if (!fund) return;
    if (!fund.pmeValues?.length) return;
    if (!pmeCalcs) return;

    const { key } = pmeCalcs;
    const indexValues = fund.pmeValues.find(p => p.indexName === pmeIndex);

    return pmeFormatter(indexValues, key);
  }, [fund, pmeCalcs, pmeIndex]);

  if (!fund) return null;

  return (
    <>
      <TooltipHeader>
        <TooltipTitle>{fund.name}</TooltipTitle>
        {typeof onClose === 'function' && <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />}
      </TooltipHeader>

      <TooltipRow>
        <TooltipLabel>YEAR</TooltipLabel>
        <TooltipData>{fund.vintage}</TooltipData>
      </TooltipRow>
      <TooltipRow>
        <TooltipLabel>STRATEGY</TooltipLabel>
        <TooltipData>{fund.strategy}</TooltipData>
      </TooltipRow>
      <TooltipRow>
        <TooltipLabel>TVPI</TooltipLabel>
        <TooltipData>{multiple2(fund.tvpi)}</TooltipData>
      </TooltipRow>
      <TooltipRow>
        <TooltipLabel>IRR</TooltipLabel>
        <TooltipData>{percent1(fund.irr)}</TooltipData>
      </TooltipRow>
      {Boolean(pmeValue) && (
        <TooltipRow>
          <TooltipLabel>PME</TooltipLabel>
          <TooltipData>
            {pmeValue} <TooltipHint>({pmeLabel})</TooltipHint>
          </TooltipData>
        </TooltipRow>
      )}
      <TooltipRow>
        <TooltipLabel>LATEST REPORTING DATE</TooltipLabel>
        <TooltipData>{formatDate()(fund.date, { utc: true })}</TooltipData>
      </TooltipRow>
      {fund.foiaSourceAcronym && (
        <TooltipRow>
          <TooltipLabel>DATA SOURCE</TooltipLabel>
          <TooltipData>{fund.foiaSourceAcronym}</TooltipData>
        </TooltipRow>
      )}

      <Match path={fundPath}>
        {({ match }) =>
          !match && (
            <TooltipRow marginTop="10px">
              <Link
                onClick={onClose}
                css={`
                  color: #00abd4;
                  text-decoration: underline;
                  font-weight: 500;
                `}
                to={fundPath}
              >
                View fund »
              </Link>
            </TooltipRow>
          )
        }
      </Match>
    </>
  );
}

FundPreview.defaultProps = {
  pmeCalcs: {},
};

FundPreview.propTypes = {
  fund: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onViewFund: PropTypes.func,
  pmeCalcs: PropTypes.object,
};

export default FundPreview;
