import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Popover } from '@material-ui/core';
import { lookup } from 'src/lookup';
import { indexName } from 'src/formatters';
import {
  FinancialMetricSelection,
  ButtonInfo,
  ButtonFilters,
  Dropdown,
  FilterControls,
  FilterControlsTooltip,
  PMEInfoTooltip,
  Responsive,
} from 'src/components';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const PrimaryNav = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const PmeNav = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 8px;
  justify-content: space-between;
`;

const PmeNavSpaceFiller = styled.div`
  width: 145px;
`;

const DropdownLabel = styled.span`
  display: block;
  color: #646464;
  font-weight: 300;
  font-size: 12px;
  margin-right: 5px;
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    transform: translateY(10px) !important;
  }
`;

const DropdownGroup = styled.div`
  display: flex;
  align-items: center;
`;

const pmeDropDownStyle = css`
  margin-right: 10px;
  width: 200px;
  .MuiOutlinedInput-input {
    background-color: transparent;
  }
`;

function formatStrategyName(strategy) {
  return `${strategy.subStrategy} Funds`;
}

function formatPmeCalcs(calcs) {
  return `PME Calcs: ${calcs.label}`;
}

function formatPmeIndexName(index) {
  return indexName(index);
}

export function FundManagerPerformanceNav(props) {
  const {
    financialMetric,
    setFinancialMetric,
    fundManagerStrategies,
    fundManagerIndexes,
    selectedStrategy,
    setSelectedStrategy,
    selectedIndex,
    setSelectedIndex,
    selectedCalcs,
    setSelectedCalcs,
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
    ...rest
  } = props;

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [infoTooltipAnchorEl, setInfoTooltipAnchorEl] = useState(null);
  const [pmeTooltipAnchorEl, setPmeTooltipAnchorEl] = useState(null);

  const handleFilterOpen = evt => {
    setFilterAnchorEl(evt.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleInfoTooltipOpen = evt => {
    setInfoTooltipAnchorEl(evt.currentTarget);
  };

  const handleInfoTooltipClose = () => {
    setInfoTooltipAnchorEl(null);
  };

  const handlePmeTooltipOpen = evt => {
    setPmeTooltipAnchorEl(evt.currentTarget);
  };

  const handlePmeTooltipClose = () => {
    setPmeTooltipAnchorEl(null);
  };

  return (
    <Layout {...rest}>
      <PrimaryNav>
        <div>
          <FinancialMetricSelection financialMetric={financialMetric} onMetricChange={setFinancialMetric} />
          <ButtonInfo
            onClick={handlePmeTooltipOpen}
            css={`
              color: #979797;
              margin-left: 5px;
            `}
            className="info-button"
          />
        </div>
        {fundManagerStrategies && fundManagerStrategies.length > 0 && (
          <DropdownGroup>
            <DropdownLabel>Strategy:</DropdownLabel>
            {fundManagerStrategies.length === 1 ? (
              formatStrategyName(fundManagerStrategies[0])
            ) : (
              <Dropdown
                value={selectedStrategy}
                onChange={setSelectedStrategy}
                items={fundManagerStrategies}
                itemKeyProperty="subStrategyId"
                itemFormat={formatStrategyName}
                css={`
                  margin-right: 10px;
                  width: 200px;
                `}
              />
            )}
          </DropdownGroup>
        )}

        <div>
          <ButtonFilters onClick={handleFilterOpen} />
          <Responsive.OnAtLeastTablet>
            <ButtonInfo
              onClick={handleInfoTooltipOpen}
              css={`
                color: #979797;
                margin-left: 5px;
              `}
              className="info-button"
            />
          </Responsive.OnAtLeastTablet>
        </div>

        <FilterControlsTooltip
          open={Boolean(infoTooltipAnchorEl)}
          onClose={handleInfoTooltipClose}
          anchorEl={infoTooltipAnchorEl}
        />

        <PMEInfoTooltip
          open={Boolean(pmeTooltipAnchorEl)}
          anchorEl={pmeTooltipAnchorEl}
          onClose={handlePmeTooltipClose}
        />

        <StyledPopover
          open={Boolean(filterAnchorEl)}
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <FilterControls
            open
            popover
            setOpen={handleFilterClose}
            regionsFilter={regionsFilter}
            secondRegionsFilter={secondRegionsFilter}
            sizesFilter={sizesFilter}
            strategiesFilter={strategiesFilter}
            vintagesFilter={vintagesFilter}
            defaultRegionsFilter={defaultRegionsFilter}
            defaultSecondRegionsFilter={defaultSecondRegionsFilter}
            defaultSizesFilter={defaultSizesFilter}
            defaultStrategiesFilter={defaultStrategiesFilter}
            defaultVintagesFilter={defaultVintagesFilter}
            setRegionsFilter={setRegionsFilter}
            setSecondRegionsFilter={setSecondRegionsFilter}
            setSizesFilter={setSizesFilter}
            setStrategiesFilter={setStrategiesFilter}
            setVintagesFilter={setVintagesFilter}
          />
        </StyledPopover>
      </PrimaryNav>
      {financialMetric.label === 'pme' && (
        <PmeNav>
          <DropdownGroup>
            <Dropdown
              value={selectedCalcs}
              onChange={setSelectedCalcs}
              items={Object.values(lookup.pmeCalcs)}
              itemKeyProperty="key"
              itemFormat={formatPmeCalcs}
              css={pmeDropDownStyle}
            />
          </DropdownGroup>
          {fundManagerIndexes.length > 0 && (
            <DropdownGroup>
              <DropdownLabel>Index:</DropdownLabel>
              {fundManagerIndexes.length === 1 ? (
                formatPmeIndexName(fundManagerIndexes[0])
              ) : (
                <Dropdown
                  value={selectedIndex}
                  onChange={setSelectedIndex}
                  items={fundManagerIndexes}
                  itemFormat={formatPmeIndexName}
                  css={pmeDropDownStyle}
                />
              )}
            </DropdownGroup>
          )}
          <PmeNavSpaceFiller />
        </PmeNav>
      )}
    </Layout>
  );
}

FundManagerPerformanceNav.defaultProps = {
  financialMetric: {},
  fundManagerStrategies: [],
  fundManagerIndexes: [],
  handleVintageToggle: () => null,
  setFinancialMetric: () => null,
  setSelectedStrategy: () => null,
  setSelectedCalcs: () => null,
  setSelectedIndex: () => null,
  vintages: [],
};

FundManagerPerformanceNav.propTypes = {
  financialMetric: PropTypes.object.isRequired,
  fundManagerStrategies: PropTypes.array.isRequired,
  fundManagerIndexes: PropTypes.array.isRequired,
  handleVintageToggle: PropTypes.func.isRequired,
  setFinancialMetric: PropTypes.func.isRequired,
  setSelectedStrategy: PropTypes.func.isRequired,
  setSelectedCalcs: PropTypes.func.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  vintages: PropTypes.array.isRequired,
};

export default FundManagerPerformanceNav;
