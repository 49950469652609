import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import TextField from 'src/components/atoms/TextField';
import Box from 'src/components/atoms/Box';
import { lookup } from 'src/lookup';

const Dropdown = styled(TextField)`
  margin: 0;
  .MuiSelect-root {
    background-color: #2b2b2b;
    color: #afafaf;
    font-size: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 20px;
    padding-left: 8px;
  }
  svg {
    right: 0px;
  }
  fieldset {
    border: solid 1px #383838;
  }
`;

const BenchmarkLabel = styled.span`
  color: #505050;
  font-size: 10px;
  margin-right: 8px;
`;

const BenchmarkMenuItem = styled(MenuItem)`
  font-size: 10px;
`;

function BenchmarkDropdown({ value, onChange, ...props }) {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <BenchmarkLabel>BENCHMARK</BenchmarkLabel>
      <Dropdown
        select
        margin="dense"
        value={value}
        onChange={event => {
          event.preventDefault();
          onChange(event.target.value);
        }}
      >
        <BenchmarkMenuItem value={lookup.financialMetric.tvpi}>TVPI</BenchmarkMenuItem>
        <BenchmarkMenuItem value={lookup.financialMetric.irr}>IRR</BenchmarkMenuItem>
      </Dropdown>
    </Box>
  );
}

BenchmarkDropdown.defaultProps = {
  onChange: () => {},
};

BenchmarkDropdown.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default BenchmarkDropdown;
