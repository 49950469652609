import { select, format, scaleLinear, extent, easeCubicInOut } from 'd3';

const percentage = format('+.1%');
const COLOR_RANGE = ['#222D19', '#6bdb15'];
const INVALID_VALUE_COLOR = '#333';
const INVALID_VALUE_TEXT = 'Insufficient Historic Data';
const TIMING = 500;

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function isValidValue(d) {
  return !isNaN(d) && isFinite(d) && d != null;
}

function draw({ id, data, mouseEventRefs }) {
  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const domain = extent(
    data.filter(d => isValidValue(d.delta)),
    d => d.delta
  );

  // Build color scale
  const colors = scaleLinear().range(COLOR_RANGE).domain(domain);

  // Build opacity scale
  const opacity = scaleLinear().range([0.35, 1]).domain(domain);

  const t = select(`#${id}`).transition().duration(TIMING).ease(easeCubicInOut);

  const boxes = select(`#${id}`)
    .selectAll('.box')
    .data(data, d => d.fundId)
    .join('div')
    .attr('class', d => `box -${isValidValue(d.delta) ? 'valid' : 'invalid'}`)
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd));

  boxes.transition(t).style('background-color', d => (isValidValue(d.delta) ? colors(d.delta) : INVALID_VALUE_COLOR));

  const textLayers = boxes
    .selectAll('.content')
    .data(d => [d])
    .join('div')
    .attr('class', 'content')
    .style('opacity', d => opacity(d.delta));

  textLayers.transition(t);

  textLayers
    .selectAll('.name')
    .data(d => [d])
    .join('div')
    .attr('class', 'text name')
    .text(d => d.name);

  textLayers
    .selectAll('.value')
    .data(d => [d])
    .join('div')
    .attr('class', 'text value')
    .text(d => (isValidValue(d.delta) ? percentage(d.delta) : INVALID_VALUE_TEXT));

  textLayers
    .selectAll('.user-fund-indicator')
    .data(d => [d])
    .join('div')
    .attr('class', 'user-fund-indicator')
    .style('display', d => (d.userId ? 'initial' : 'none'));
}

export default draw;
