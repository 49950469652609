import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { useLayoutActions } from 'src/hooks';
import { UserFundOrManagerMessage, NewsArticles, PlaceholderTable } from 'src/components';
import { useGet as useGetFundManagerNews } from 'src/services/filter-api/fundManagerNews/queries';

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 100%;
  text-align: center;

  span {
    max-width: 50%;
  }
`;

function FundManagerNews({ fundManager, showPlot }) {
  const { data, isLoading } = useGetFundManagerNews(fundManager?.fundManagerId);

  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const { expandPanel } = useLayoutActions();

  const handleNewsArticleClick = useCallback(
    function () {
      expandPanel();
    },
    [expandPanel]
  );

  useEffect(() => {
    const parsed = parse(window.location.search) || {};
    if (!parsed.articleid) return;
    setTimeout(() => setSelectedArticleId(parsed.articleid), 500);
  }, [setSelectedArticleId]);

  if (fundManager?.userId) {
    return (
      <UserFundOrManagerMessage
        label="fund manager"
        css={`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      />
    );
  }

  if (isLoading) return <PlaceholderTable />;

  if (data?.length < 1) {
    return (
      <EmptyState>
        <span>There is currently no news available for this fund manager.</span>
      </EmptyState>
    );
  }

  return (
    <NewsArticles
      title={''}
      showSentimentGraph={showPlot}
      sentimentGraphLayout="full"
      articles={data}
      defaultSelectedArticleId={selectedArticleId}
      onSelectedArticle={handleNewsArticleClick}
    />
  );
}

FundManagerNews.defaultProps = {
  showPlot: false,
};

FundManagerNews.propTypes = {
  showPlot: PropTypes.bool,
  fundManager: PropTypes.object.isRequired,
};

export default FundManagerNews;
