/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { fetchFundsSuccess, setFundsPeers } from './funds';
import { fetchFundManagerNamesSuccess } from './fund-manager-names';

const initialState = {
  funds: false,
  fundManagers: false,
  fundPeers: false,
};

export default createReducer(initialState, {
  [fetchFundsSuccess]: state => {
    state.funds = true;
  },
  [fetchFundManagerNamesSuccess]: state => {
    state.fundManagers = true;
  },
  [setFundsPeers]: state => {
    state.fundPeers = true;
  },
});
