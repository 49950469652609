import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchTickerNewsPending = createAction('FETCH_TICKER_NEWS_PENDING');
const fetchTickerNewsError = createAction('FETCH_TICKER_NEWS_ERROR');
const fetchTickerNewsSuccess = createAction('FETCH_TICKER_NEWS_SUCCESS');

export function fetchTickerNews() {
  return async function (dispatch, getState) {
    try {
      const { fetching, fetched } = getState().news.ticker;
      if (fetched || fetching) return;
      dispatch(fetchTickerNewsPending());
      const articles = await filterApi.getTickerNews();
      dispatch(
        fetchTickerNewsSuccess({
          articles,
        })
      );
    } catch (error) {
      dispatch(fetchTickerNewsError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  articles: null,
};

export default createReducer(initialState, {
  [fetchTickerNewsPending]: state => {
    state.fetching = true;
  },
  [fetchTickerNewsError]: (state, action) => {
    const { error } = action.payload;
    state.fetching = true;
    state.error = error;
    console.error(error);
  },
  [fetchTickerNewsSuccess]: (state, action) => {
    const { articles } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.articles = articles;
  },
});
