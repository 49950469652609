import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import { useUniqueId, useStrategies } from 'src/hooks';

function StrategySelect({ className, onChange, selectedStrategyId, label, ...props }) {
  const selectId = useUniqueId();
  const strategies = useStrategies();
  const parentStrategies = (strategies || []).filter(s => !s.parentId);
  const defaultStrategyId = parentStrategies && parentStrategies[0]?.strategyId;
  const groups = parentStrategies.map(parent => {
    return {
      ...parent,
      strategies: [...strategies]
        .filter(strategy => strategy.parentId === parent.strategyId)
        .sort((a, b) => a.rank - b.rank),
    };
  });

  const parentAbbrs = parentStrategies.reduce((acc, strategy) => {
    const parts = strategy.strategyName.split(' ');
    const abbr = parts.length > 1 ? parts[0][0] + parts[1][0] : parts[0][0] + parts[0][1];
    return { ...acc, [strategy.strategyId]: abbr.toUpperCase() };
  }, {});

  if (!parentStrategies || !defaultStrategyId)
    return (
      <div className={className}>
        <Skeleton variant="text" />
        <Skeleton variant="rect" height={48} width={142} />
      </div>
    );

  return (
    <div>
      <InputLabel shrink id={selectId}>
        {label}
      </InputLabel>

      <Select
        className={className}
        labelId={selectId}
        value={selectedStrategyId || defaultStrategyId}
        onChange={event => onChange(event.target.value)}
        {...props}
      >
        {groups.map(parent => {
          return [
            <MenuItem key={parent.strategyId} value={parent.strategyId}>
              {parent.strategyName}
            </MenuItem>,
            ...parent.strategies.map(child => {
              return (
                <MenuItem
                  css={`
                    padding-left: 2em;
                  `}
                  className="sub-item"
                  key={child.strategyId}
                  value={child.strategyId}
                >
                  {parentAbbrs[parent.strategyId]} - {child.strategyName}
                </MenuItem>
              );
            }),
          ];
        })}
      </Select>
    </div>
  );
}

StrategySelect.defaultProps = {
  label: 'Fund Strategy',
};

StrategySelect.propTypes = {
  label: PropTypes.string,
  selectedStrategyId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default StrategySelect;
