import React from 'react';
import { useState, useEffect } from 'react';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { AppPanel, IconStrategy, IconGlobe, FundsInMarketVisualization, ButtonInfo } from 'src/components';
import FundsInMarketInfoTooltip from './FundsInMarketInfoTooltip';

const VIEWS = {
  plot: 'plot',
  list: 'list',
};

const CATEGORIES = {
  strategy: 'strategy',
  region: 'region',
};

function groupStrategiesByYear(item) {
  const strategies = item.strategies.reduce((acc, curr) => {
    return { ...acc, [curr.strategyName]: curr };
  }, {});

  return {
    year: item.year,
    ...strategies,
  };
}

function groupRegionsByYear(item) {
  const regions = item.regions.reduce((acc, curr) => {
    return { ...acc, [curr.regionName]: curr };
  }, {});

  return {
    year: item.year,
    ...regions,
  };
}

function MarketFilterFundsInMarketPanel({ className, spanX, spanY, minHeight }) {
  const [view, setView] = useState(VIEWS.plot);
  const [category, setCategory] = useState(CATEGORIES.strategy);
  const [infoTooltipAnchorEl, setInfoTooltipAnchorEl] = useState(null);

  useEffect(
    function () {
      switch (view) {
        case VIEWS.LIST:
          usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterFundRaisingList);
          break;

        case VIEWS.PLOT:
          usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterFundRaisingDataSet);
          break;

        default:
          return;
      }
    },
    [view]
  );

  return (
    <AppPanel
      className={className}
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title={
        <span>
          Fund Raising by Vintage Year{' '}
          <ButtonInfo className="info-button" onClick={evt => setInfoTooltipAnchorEl(evt.currentTarget)} />
          <FundsInMarketInfoTooltip
            open={Boolean(infoTooltipAnchorEl)}
            anchorEl={infoTooltipAnchorEl}
            onClose={() => setInfoTooltipAnchorEl(null)}
          />
        </span>
      }
      controls={
        <div>
          {view === VIEWS.plot && (
            <>
              <AppPanel.Button
                aria-label="strategy"
                startIcon={<IconStrategy />}
                selected={category === CATEGORIES.strategy}
                onClick={() => setCategory(CATEGORIES.strategy)}
              >
                Strategy
              </AppPanel.Button>
              <AppPanel.Button
                aria-label="geography"
                startIcon={<IconGlobe />}
                selected={category === CATEGORIES.region}
                onClick={() => setCategory(CATEGORIES.region)}
              >
                Geography
              </AppPanel.Button>
            </>
          )}
        </div>
      }
      expandable
    >
      <FundsInMarketVisualization
        isPlotView={view === VIEWS.plot}
        isListView={view === VIEWS.list}
        switchToPlotView={() => setView(VIEWS.plot)}
        switchToListView={() => setView(VIEWS.list)}
        categorizationFn={
          {
            [CATEGORIES.strategy]: groupStrategiesByYear,
            [CATEGORIES.region]: groupRegionsByYear,
          }[category]
        }
      />
    </AppPanel>
  );
}

MarketFilterFundsInMarketPanel.defaultProps = {};

MarketFilterFundsInMarketPanel.propTypes = {};

export default MarketFilterFundsInMarketPanel;
