import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNewsArticleSummary } from 'src/hooks';
import { news } from 'src/config/app';
import { NewsArticle } from 'src/components';
import NewsArticleNotFound from './NewsArticleNotFound';

const Layout = styled.div`
  position: relative;
  height: 100%;
`;

function NewsVisualizationSelectedArticle(props) {
  const { selectedArticle, className } = props;

  const [summaryLength, setSummaryLength] = useState(news.article.SUMMARY_LENGTH);

  const summary = useNewsArticleSummary({
    article: selectedArticle,
    summaryLength,
  });

  if (!selectedArticle) return null;
  if (selectedArticle.error === 404) return <NewsArticleNotFound className={className} />;

  return (
    <Layout className={className}>
      <NewsArticle
        article={selectedArticle}
        expanded
        summaryLength={summaryLength}
        summary={summary}
        onSummaryLengthChange={setSummaryLength}
        showSelectedIndicator={false}
      />
    </Layout>
  );
}

NewsVisualizationSelectedArticle.defaultProps = {};

NewsVisualizationSelectedArticle.propTypes = {
  children: PropTypes.any,
};

export default NewsVisualizationSelectedArticle;
