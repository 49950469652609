/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import { cloneDeep } from 'src/lib/lodash';
import { upperCase } from '../../../formatters';
import BaseVirtualTable from '../VirtualizedTable';
import { IconClose } from '../..';

const listDefNarrow = [
  {
    width: 170,
    sortable: true,
    headerStyle: { padding: '0 12px 0 12px' },
    cellStyle: { fontSize: '11px', padding: '0 12px 0 12px' },
    property: 'name',
    key: 'name',
    label: upperCase('name'),
    isNumeric: false,
  },
  {
    width: 180,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'currentFundManagerName',
    key: 'currentFundManagerName',
    label: upperCase('firm'),
    isNumeric: false,
  },
  {
    width: 40,
    key: 'remove',
    headerStyle: { textAlign: 'center', padding: '0 0 0 12px' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 12px',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => (
      <IconClose
        data={data}
        css={`
          color: #afafaf;
          width: 10px;
          height: 10px;
          opacity: 0.2;
          position: relative;
          top: 2px;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        `}
      />
    ),
  },
];

const listDefWide = [
  {
    width: 170,
    sortable: true,
    headerStyle: { padding: '0 12px 0 12px' },
    cellStyle: { fontSize: '11px', padding: '0 12px 0 12px' },
    property: 'name',
    key: 'name',
    label: upperCase('name'),
    isNumeric: false,
  },
  {
    width: 140,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'currentFundManagerName',
    key: 'currentFundManagerName',
    label: upperCase('firm'),
    isNumeric: false,
  },
  {
    width: 240,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'currentTitleName',
    key: 'currentTitleName',
    label: upperCase('title'),
    isNumeric: false,
  },
  {
    width: 40,
    key: 'remove',
    headerStyle: { textAlign: 'center', padding: '0 0 0 12px' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 12px',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => (
      <IconClose
        data={data}
        css={`
          color: #afafaf;
          width: 10px;
          height: 10px;
          opacity: 0.2;
          position: relative;
          top: 2px;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        `}
      />
    ),
  },
];

const VirtualizedTable = styled(BaseVirtualTable)`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
`;

function BookmarkedEmployeesTable({
  data,
  selectedId,
  expanded: _expanded,
  wideTable,
  theme: _theme,
  enableHover,
  listDefNarrow,
  listDefWide,
  rowHoverColor,
  rowHoverBackgroundColor,
  onListDefCreated,
  onColumnClick,
  onRowClick,
  ...rest
}) {
  const [listDef, setListDef] = useState(null);

  useEffect(() => {
    const listDefClone = cloneDeep(wideTable ? listDefWide : listDefNarrow);
    // give the parent component a chance to update the listDef
    onListDefCreated(listDefClone);
    setListDef(listDefClone);
  }, [onListDefCreated, listDefNarrow, listDefWide, wideTable]);

  // eslint-disable-next-line no-shadow
  function handleColumnClick(e, { column, data, index }) {
    onColumnClick(e, { column, data, index });
  }

  // eslint-disable-next-line no-shadow
  function handleRowClick(e, { data, index }) {
    onRowClick(e, { data, index });
  }

  function getRowBackgroundColor(item) {
    return item.employeeId === selectedId ? '#0d8bab' : undefined;
  }

  function getRowColor(item) {
    return item.employeeId === selectedId ? '#fff' : '#afafaf';
  }

  return (
    <VirtualizedTable
      data={data}
      listDef={listDef}
      onColumnClick={handleColumnClick}
      onRowClick={handleRowClick}
      enableHover={enableHover}
      rowColor={getRowColor}
      rowBackgroundColor={getRowBackgroundColor}
      rowHoverColor={rowHoverColor}
      rowHoverBackgroundColor={rowHoverBackgroundColor}
      {...rest}
    />
  );
}

BookmarkedEmployeesTable.defaultProps = {
  selectedId: null,
  expanded: false,
  components: {},
  enableHover: true,
  listDefNarrow,
  listDefWide,
  wideTable: false,
  rowHoverColor: '#fff',
  rowHoverBackgroundColor: '#0a5f75',
  onListDefCreated: () => {},
  onColumnClick: () => {},
  onRowClick: () => {},
};

BookmarkedEmployeesTable.propTypes = {
  data: PropTypes.array.isRequired,
  selectedId: PropTypes.string,
  components: PropTypes.object,
  theme: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  wideTable: PropTypes.bool,
  enableHover: PropTypes.bool,
  listDefNarrow: PropTypes.array,
  listDefWide: PropTypes.array,
  rowHoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onListDefCreated: PropTypes.func,
  onColumnClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default withTheme(BookmarkedEmployeesTable);
