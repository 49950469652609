import enUS from 'date-fns/locale/en-US';
import parseISO from 'date-fns/fp/parseISO';
import add from 'date-fns/fp/add';
import sub from 'date-fns/fp/sub';
import dateFnsIsEqual from 'date-fns/fp/isEqual';
import dateFnsIsBefore from 'date-fns/fp/isBefore';
import dateFnsIsAfter from 'date-fns/fp/isAfter';
import format from 'date-fns/fp/format';
import formatDistanceWithOptions from 'date-fns/fp/formatDistanceWithOptions';
import formatRelative from 'date-fns/fp/formatRelative';
import formatDistanceStrict from 'date-fns/fp/formatDistanceStrict';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import differenceInCalendarQuarters from 'date-fns/differenceInCalendarQuarters';
import getUnixTime from 'date-fns/getUnixTime';
import intervalToDuration from 'date-fns/intervalToDuration';

/**
 * Calculators
 */
function quarterDiff(baseDate) {
  return function (testDate) {
    return differenceInCalendarQuarters(testDate, baseDate);
  };
}

function monthDiff(baseDate) {
  return function (testDate) {
    return differenceInCalendarMonths(testDate, baseDate);
  };
}

function dayDiff(baseDate) {
  return function (testDate) {
    return differenceInCalendarDays(testDate, baseDate);
  };
}

function getDuration(baseDate = new Date()) {
  return function (testDate) {
    return intervalToDuration({ start: baseDate, end: testDate });
  };
}

function formatDurationDecimalYear(baseDate, p = 1) {
  return function (testDate) {
    const duration = getDuration(baseDate)(testDate);

    const { years, months, days } = duration || {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    const decimalMonths = months / 12;
    const decimalDays = days / 365.4;
    const result = +(years + decimalMonths + decimalDays).toFixed(p);

    return result;
  };
}

function addTimeInterval(token) {
  return function (n = 1) {
    return function (date) {
      if (typeof date === 'string') date = parseISO(date);
      return add({ [token]: n })(date);
    };
  };
}

function subtractTimeInterval(token) {
  return function (n = 1) {
    return function (date) {
      if (typeof date === 'string') date = parseISO(date);
      return sub({ [token]: n })(date);
    };
  };
}

const addSeconds = addTimeInterval('seconds');
const subtractSeconds = subtractTimeInterval('seconds');

const addMinutes = addTimeInterval('minutes');
const subtractMinutes = subtractTimeInterval('minutes');

const addHours = addTimeInterval('hours');
const subtractHours = subtractTimeInterval('hours');

const addDays = addTimeInterval('days');
const subtractDays = subtractTimeInterval('days');

const addWeeks = addTimeInterval('weeks');
const subtractWeeks = subtractTimeInterval('weeks');

const addMonths = addTimeInterval('months');
const subtractMonths = subtractTimeInterval('months');

const addQuarters = n => addTimeInterval('months')(n * 3);
const subtractQuarters = n => subtractTimeInterval('months')(n * 3);

const addYears = addTimeInterval('years');
const subtractYears = subtractTimeInterval('years');

/**
 * Finders
 */
function getStartOfDay(date = new Date()) {
  return new Date(date.setUTCHours(0, 0, 0, 0));
}

function getStartOfMonth(date = new Date()) {
  const startOfDay = getStartOfDay(date);
  return new Date(startOfDay.setUTCDate(1));
}

function getStartOfQuarter(date = new Date()) {
  const startOfMonth = getStartOfMonth(date);
  const quarterIndex = Math.floor(date.getUTCMonth() / 3);
  return new Date(startOfMonth.setUTCMonth(quarterIndex * 3));
}

function getRecentQuarter(lookback = 0) {
  return function (date = new Date()) {
    const effectiveDate = subtractMonths(lookback * 3)(date);
    const startOfQuarter = getStartOfQuarter(effectiveDate);
    return subtractDays(1)(startOfQuarter);
  };
}

const getCurrentQuarter = getRecentQuarter(-1);
const getLastQuarter = getRecentQuarter(0);

/**
 * Comparators
 */
function isDate(value) {
  return value instanceof Date || Object.prototype.toString.call(value) === '[object Date]';
}

function isEqual(baseDate) {
  return function (testDate) {
    return dateFnsIsEqual(baseDate)(testDate);
  };
}

function isBefore(baseDate) {
  return function (testDate) {
    return dateFnsIsBefore(baseDate)(testDate);
  };
}

function isBeforeOrEqual(baseDate = new Date()) {
  return function (testDate) {
    if (dateFnsIsEqual(baseDate)(testDate)) return true;
    return dateFnsIsBefore(baseDate)(testDate);
  };
}

function isAfter(baseDate) {
  return function (testDate) {
    return dateFnsIsAfter(baseDate)(testDate);
  };
}

function isAfterOrEqual(baseDate = new Date()) {
  return function (testDate) {
    if (dateFnsIsEqual(baseDate)(testDate)) return true;
    return dateFnsIsAfter(baseDate)(testDate);
  };
}

function isNow(testDate) {
  return dateFnsIsEqual(new Date())(testDate);
}

function isPast(testDate) {
  return dateFnsIsBefore(new Date())(testDate);
}

function isPastOrNow(testDate) {
  if (isNow(testDate)) return true;
  return dateFnsIsBefore(new Date())(testDate);
}

function isFuture(testDate) {
  return dateFnsIsAfter(new Date())(testDate);
}

function isFutureOrNow(testDate) {
  if (isNow(testDate)) return true;
  return dateFnsIsAfter(new Date())(testDate);
}

function sortDates(direction = 'ASC') {
  return (a, b) => {
    const n = direction.toUpperCase() === 'DESC' ? a < b : b < a;
    return n ? 1 : -1;
  };
}

const sortDatesAsc = sortDates('ASC');
const sortDatesDesc = sortDates('DESC');

function sortObjectsByDateAsc(a, b) {
  if (a.date > b.date) return 1;
  if (a.date < b.date) return -1;
  return 0;
}

function sortObjectsByDateDesc(a, b) {
  if (a.date > b.date) return -1;
  if (a.date < b.date) return 1;
  return 0;
}

/**
 * Formatters
 */

function formatUnixTimestamp(date) {
  return getUnixTime(date);
}

function formatDate(fmt = 'MM-dd-yyyy') {
  return function (date, { utc = false } = {}) {
    if (!date) return '';
    if (typeof date === 'string') date = parseISO(date);
    if (utc) date = addMinutes(date.getTimezoneOffset())(date);
    return format(fmt)(date);
  };
}

function formatDateComplete(date) {
  if (!date) return;
  return formatDate('EEEE, MMMM do yyyy, p z')(date);
}

function formatDateLong(date) {
  if (!date) return '';
  return formatDate('MMMM do yyyy, p')(date);
}

function formatDateShort(date) {
  if (!date) return '';
  return formatDate('MMM d yyyy')(date);
}

function formatDateCompact(date) {
  if (!date) return '';
  return formatDate('yyyy-MM-dd')(date);
}

function getQuarter(date) {
  return Math.floor(date.getUTCMonth() / 3 + 1);
}

function formatDateISOString(date) {
  if (typeof date === 'string') date = new Date(date);
  return date.toISOString();
}

function formatDateLongYear(date) {
  if (typeof date === 'string') date = new Date(date);
  return date.getUTCFullYear().toString();
}

function formatDateShortYear(date) {
  if (typeof date === 'string') date = new Date(date);
  return formatDateLongYear(date).substr(-2);
}

function formatDateShortQuarter(date) {
  if (typeof date === 'string') date = new Date(date);
  return `Q${getQuarter(date)}'${formatDateShortYear(date)}`;
}

function formatDateLongQuarter(date) {
  if (typeof date === 'string') date = new Date(date);
  return `Q${getQuarter(date)} ${formatDateLongYear(date)}`;
}

function formatDuration(baseDate, { addSuffix = true, format: formatOptions } = {}) {
  if (formatOptions === 'strict') {
    return formatDistanceStrict(baseDate);
  }

  formatOptions = {
    lessThanXSeconds: {
      one: 'nearly a second',
      other: 'nearly {{count}} seconds',
    },
    xSeconds: {
      one: 'a second',
      other: '{{count}} seconds',
    },
    halfAMinute: 'nearly a minute',
    lessThanXMinutes: {
      one: 'nearly a minute',
      other: 'nearly {{count}} minutes',
    },
    xMinutes: {
      one: 'a minute',
      other: '{{count}} minutes',
    },
    aboutXHours: {
      one: 'an hour',
      other: 'about {{count}} hours',
    },
    xHours: {
      one: 'an hour',
      other: '{{count}} hours',
    },
    xDays: {
      one: 'a day',
      other: '{{count}} days',
    },
    aboutXWeeks: {
      one: 'a week',
      other: 'about {{count}} weeks',
    },
    xWeeks: {
      one: 'a week',
      other: '{{count}} weeks',
    },
    aboutXMonths: {
      one: 'a month',
      other: 'about {{count}} months',
    },
    xMonths: {
      one: 'a month',
      other: '{{count}} months',
    },
    aboutXYears: {
      one: 'a year',
      other: 'about {{count}} years',
    },
    xYears: {
      one: 'a year',
      other: '{{count}} years',
    },
    overXYears: {
      one: 'over a year',
      other: 'over {{count}} years',
    },
    almostXYears: {
      one: 'almost a year',
      other: 'almost {{count}} years',
    },
    ...formatOptions,
  };

  const locale = {
    ...enUS,
    formatDistance: function (token, count, options) {
      options = options || {};

      let result;
      if (typeof formatOptions[token] === 'string') {
        result = formatOptions[token];
      } else if (count === 1) {
        result = formatOptions[token].one;
      } else {
        result = formatOptions[token].other.replace('{{count}}', count);
      }

      if (options.addSuffix) {
        if (options.comparison > 0) {
          return 'in ' + result;
        } else {
          return result + ' ago';
        }
      }

      return result;
    },
  };

  return function (date) {
    return formatDistanceWithOptions({ addSuffix, locale })(baseDate)(date);
  };
}

function formatDurationToNow(date, options) {
  return formatDuration(new Date(), options)(date);
}

function formatDurationAbbreviated(baseDate, options) {
  options = options || {};

  const SECONDS = { one: '1s', other: '{{count}}s' };
  const MINUTES = { one: '1 min', other: '{{count}} mins' };
  const HOURS = { one: '1 hr', other: '{{count}} hrs' };
  const DAYS = { one: '1 day', other: '{{count}} days' };
  const WEEKS = { one: '1 wk', other: '{{count}} wks' };
  const MONTHS = { one: '1 month', other: '{{count}} months' };
  const YEARS = { one: '1 yr', other: '{{count}} yrs' };

  options.format = {
    lessThanXSeconds: SECONDS,
    xSeconds: SECONDS,
    halfAMinute: MINUTES.one,
    lessThanXMinutes: MINUTES,
    xMinutes: MINUTES,
    aboutXHours: HOURS,
    xHours: HOURS,
    xDays: DAYS,
    aboutXWeeks: WEEKS,
    xWeeks: WEEKS,
    aboutXMonths: MONTHS,
    xMonths: MONTHS,
    aboutXYears: YEARS,
    xYears: YEARS,
    overXYears: YEARS,
    almostXYears: YEARS,
    ...options.format,
  };

  return function (date) {
    return formatDuration(baseDate, options)(date);
  };
}

function formatDurationToNowAbbreviated(options) {
  return function (date) {
    return formatDurationAbbreviated(new Date(), options)(date);
  };
}

function formatCalendar(baseDate = new Date()) {
  return function (date) {
    if (typeof baseDate === 'string') baseDate = parseISO(baseDate);
    if (typeof date === 'string') date = parseISO(date);
    return formatRelative(baseDate)(date);
  };
}

export {
  parseISO,
  isDate,
  quarterDiff,
  monthDiff,
  dayDiff,
  addTimeInterval,
  subtractTimeInterval,
  addSeconds,
  subtractSeconds,
  addMinutes,
  subtractMinutes,
  addHours,
  subtractHours,
  addDays,
  subtractDays,
  addWeeks,
  subtractWeeks,
  addMonths,
  subtractMonths,
  addQuarters,
  subtractQuarters,
  addYears,
  subtractYears,
  sortDates,
  sortDatesAsc,
  sortDatesDesc,
  getStartOfDay,
  getStartOfMonth,
  getStartOfQuarter,
  getRecentQuarter,
  getCurrentQuarter,
  getLastQuarter,
  isEqual,
  isBefore,
  isBeforeOrEqual,
  isAfterOrEqual,
  isAfter,
  isNow,
  isPast,
  isPastOrNow,
  isFuture,
  isFutureOrNow,
  sortObjectsByDateAsc,
  sortObjectsByDateDesc,
  formatDate,
  formatDateComplete,
  formatDateLong,
  formatDateShort,
  formatDateCompact,
  getQuarter,
  formatDateISOString,
  formatDateLongYear,
  formatDateShortYear,
  formatDateShortQuarter,
  formatDateLongQuarter,
  formatDuration,
  formatDurationToNow,
  formatDurationAbbreviated,
  formatDurationToNowAbbreviated,
  formatCalendar,
  formatUnixTimestamp,
  getDuration,
  formatDurationDecimalYear,
};
