import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function IconExclaim(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M12,17 C13.1045695,17 14,17.8954305 14,19 C14,20.1045695 13.1045695,21 12,21 C10.8954305,21 10,20.1045695 10,19 C10,17.8954305 10.8954305,17 12,17 Z M14,3 L14,15 L10,15 L10,3 L14,3 Z" />
    </Svg>
  );
}

IconExclaim.defaultProps = {
  width: '0.75em',
  height: '0.75em',
};

export default IconExclaim;
