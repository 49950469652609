import { getScrollbarWidth } from 'src/utils';

function useScrollbarWidth(ref) {
  if (!ref.current) return 0;

  const scrollHeight = ref.current.scrollHeight;
  const height = ref.current.offsetHeight;
  const isScrollable = scrollHeight > height;

  return isScrollable ? getScrollbarWidth() : 0;
}

export default useScrollbarWidth;
