import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingIndicator } from 'src/components';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loading({ isLoading, children }) {
  if (!isLoading) return <>{children}</>;
  return (
    <Layout>
      <LoadingIndicator display={true} />
    </Layout>
  );
}

Loading.defaultProps = {
  isLoading: false,
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
};

export default Loading;
