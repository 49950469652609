import React from 'react';
import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import styled from 'styled-components';

const List = styled.div`
  line-height: ${32 / 18};
`;

const Item = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 1em;
  }
`;

function MatchedList({ className, items, query, onChange, matchLimit, renderItem, matchType, onClickItem, ...rest }) {
  const matchedItems = useMemo(() => {
    if (matchType === 'exclusive' && !query) {
      return items;
    }
    if (!items) return [];
    if (!query) return [];

    const matches = items
      .filter(item => {
        const a = item.value.toLowerCase().trim() || '';
        const b = query.toLowerCase().trim();
        return a.startsWith(b);
      })
      .slice([0, matchLimit ?? items.length]);

    return matches;
  }, [items, matchLimit, matchType, query]);

  useEffect(() => {
    onChange(matchedItems);
  }, [matchedItems, onChange]);

  if (typeof renderItem === 'function') {
    return (
      <List className={className} {...rest}>
        {matchedItems.map(renderItem)}
      </List>
    );
  }

  return (
    <List className={className} {...rest}>
      {matchedItems.map(item => {
        const { id, value, onClick } = item;

        return (
          <Item
            key={id}
            onClick={() => {
              onClick ? onClick(item) : onClickItem(item);
            }}
          >
            <span>{value}</span>
          </Item>
        );
      })}
    </List>
  );
}

MatchedList.defaultProps = {
  onChange: () => null,
  matchType: 'inclusive',
  onClickItem: () => {},
};

MatchedList.propTypes = {
  header: PropTypes.element,
  children: PropTypes.any,
  matchLimit: PropTypes.number,
  onChange: PropTypes.func,
  matchType: PropTypes.oneOf(['inclusive', 'exclusive']),
  onClickItem: PropTypes.func,
};

export default MatchedList;
