import { select, scaleLinear, scaleUtc, extent, easeCubicInOut, axisBottom, axisLeft, area, line } from 'd3';
import { addSeconds, formatDateShortQuarter, getRecentQuarter, getQuarter } from 'src/utils/date';
import { upperCase } from 'src/formatters';
import { valueChange } from 'src/calculators';
import { format } from 'd3';

const percent = format('.1%');
const xformat = formatDateShortQuarter;
const TRANSITION_DURATION = 500;
const GREEN = '#007433';
const GREY = '#333333';

/**
 * get an item from the series where the date is back
 * the amount of time specified by timeAgo
 */
function getComparisonItems(series, timeRange) {
  if (!series) return [];
  if (series.length < 2) return [];

  const quartersAgo = Math.floor(timeRange.value / 3);
  const current = series[series.length - 1];
  const prevDate = getRecentQuarter(quartersAgo)(current.date);

  const previous = series.find(item => item.date.toISOString() === prevDate.toISOString());

  return [current, previous];
}

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function draw({ svgId, data, timeRange, metric, width, height, margin, mouseEventRefs }) {
  const svgWidth = width - margin.left - margin.right;
  const svgHeight = height - margin.top - margin.bottom;
  const metricKey = metric.key;

  // map the ISO date string to a date
  const series = [...data]
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .filter(item => item[metricKey] !== null)
    .map(item => ({ ...item, date: new Date(item.date) }));
  const prefixedId = `#${svgId}`;
  const [currItem, prevItem] = getComparisonItems(series, timeRange);
  const areaOfInterest = series.slice(series.indexOf(prevItem));

  const horizontalLineExtentionLength = 25;

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  // TODO: use the d3 join pattern instead of recreating the svg children everytime
  select(prefixedId).selectAll('*').remove();

  if (!prevItem || !currItem) {
    return false;
  }

  const svg = select(prefixedId).append('g').attr('transform', `translate(${margin.left},${margin.top})`);

  const t = svg.transition().duration(TRANSITION_DURATION).ease(easeCubicInOut);

  const xExtent = extent(series, d => d.date);

  // Build X scale:
  const x = scaleUtc().domain(xExtent).range([0, svgWidth]);

  svg
    .append('g')
    .attr('transform', `translate(0,${svgHeight})`)
    .attr('class', 'x-axis')
    .style('shape-rendering', 'crispEdges')
    .call(
      axisBottom(x)
        .ticks(series.length)
        .tickSizeInner(-(svgHeight - 25))
        .tickFormat(d => xformat(addSeconds(-1)(d)))
    );

  const yExtent = extent(series, d => d[metricKey]);

  // Build Y scale:
  const y = scaleLinear()
    .domain([metricKey === 'tvpi' ? 0 : yExtent[0], yExtent[1]])
    .range([svgHeight, 55]);

  // add the Y axis
  svg
    .append('g')
    .attr('class', 'y-axis')
    .style('shape-rendering', 'crispEdges')
    .call(
      axisLeft(y)
        .ticks(6)
        .tickSize(-svgWidth)
        .tickFormat(metricKey === 'irr' ? percent : null)
    );

  // add classes to the ticks
  svg.selectAll('.x-axis .tick').each(function addClass(value) {
    // const fixedDate = moment(value).add(-1, 's').toDate();
    const fixedDate = addSeconds(-1)(value);
    const tickClass = formatDateShortQuarter(fixedDate).slice(0, 2);
    select(this).attr('class', `tick ${tickClass}`);
  });

  // remove the Q2 and Q4 ticks
  const tickText = svg.selectAll('.x-axis .tick text');
  tickText.each(function removeTick(date) {
    if (getQuarter(date) % 2 !== 0) return;
    select(this).remove();
  });

  // add Y axis label
  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 0 - margin.left)
    .attr('x', 0 - svgHeight / 2)
    .attr('dy', '1em')
    .attr('class', 'axis-label')
    .style('text-anchor', 'middle')
    .text(upperCase(metric.labelChart));

  // add the GREY line
  svg
    .append('path')
    .datum(series)
    .attr('fill', 'none')
    .attr('stroke', GREY)
    .attr('stroke-width', 1)
    .attr(
      'd',
      line()
        .x(d => x(d.date))
        .y(d => y(d[metricKey]))
    );

  // add the GREY circles
  svg
    .selectAll('.circle-1')
    .data(series)
    .enter()
    .append('circle')
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .attr('class', 'circle-1')
    .attr('fill', GREY)
    .attr('stroke', 'none')
    .attr('cx', d => x(d.date))
    .attr('cy', d => y(d[metricKey]))
    .attr('r', 5);

  // Add the GREEN line
  svg
    .append('path')
    .datum(areaOfInterest) // quarters
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1)
    .attr('opacity', 0)
    .attr(
      'd',
      line()
        .x(d => x(d.date))
        .y(_d => svgHeight)
    )
    .transition(t)
    .attr(
      'd',
      line()
        .x(d => x(d.date))
        .y(d => y(d[metricKey]))
    )
    .attr('opacity', 1);

  // Add the GREEN circles
  svg
    .selectAll('.circle-2')
    .data(areaOfInterest) // quarters
    .enter()
    .append('circle')
    .attr('class', '')
    // .attr('cy', d => y(d[metric]))
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .attr('cy', svgHeight)
    .attr('cx', d => x(d.date))
    .attr('stroke', d => (d.isGenerated ? GREEN : null))
    .attr('fill', d => (d.isGenerated ? GREY : GREEN))
    .attr('r', 5)
    .transition(t)
    .attr('data-date', d => d.date)
    .attr('data-period', d => xformat(d.date))
    .attr('cy', d => y(d[metricKey]))
    .attr('opacity', 1);

  // add the GREY area under the GREEN line
  svg
    .append('path')
    .datum(areaOfInterest)
    .attr('fill', '#6a6a6a')
    .attr('fill-opacity', 0.5)
    .attr(
      'd',
      area()
        .x(d => x(d.date))
        .y0(svgHeight)
        .y1(svgHeight)
    )
    .transition(t)
    .attr('fill-opacity', 0.2)
    .attr(
      'd',
      area()
        .x(d => x(d.date))
        .y0(svgHeight)
        .y1(d => y(d[metricKey]))
    );

  // Add the previous items horizontal dashed GREEN the line
  const prevItemHorizontalLineStart = { ...prevItem };
  const prevItemHorizontalLineEnd = { ...currItem };
  prevItemHorizontalLineEnd[metricKey] = prevItemHorizontalLineStart[metricKey];
  const prevItemHorizontalLineSeries = [prevItemHorizontalLineStart, prevItemHorizontalLineEnd];

  // Add the cirrent items horizontal dashed GREEN the line
  const currItemHorizontalLineStart = { ...currItem };
  const currItemHorizontalLineEnd = { ...currItemHorizontalLineStart };
  currItemHorizontalLineEnd[metricKey] = currItemHorizontalLineStart[metricKey];
  const currItemHorizontalLineSeries = [currItemHorizontalLineStart, currItemHorizontalLineEnd];

  const prevItemVerticalLineStart = { ...prevItem };
  const prevItemVerticalLineEnd = { ...prevItemVerticalLineStart };
  [prevItemVerticalLineStart[metricKey]] = yExtent;
  prevItemVerticalLineEnd[metricKey] = yExtent[1];
  const prevItemVerticalLineSeries = [prevItemVerticalLineStart, prevItemVerticalLineEnd];

  const currItemVerticalLineStart = { ...currItem };
  const currItemVerticalLineEnd = { ...currItemVerticalLineStart };
  [currItemVerticalLineStart[metricKey]] = yExtent;
  currItemVerticalLineEnd[metricKey] = yExtent[1];
  const currItemVerticalLineSeries = [currItemVerticalLineStart, currItemVerticalLineEnd];

  svg
    .append('path')
    .datum(prevItemHorizontalLineSeries)
    .attr('class', 'horizontal-dashed')
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? x(d.date) : x(d.date) + horizontalLineExtentionLength))
        .y(_d => svgHeight)
    )
    .transition(t)
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1.5)
    .attr('stroke-dasharray', '6 6')
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? x(d.date) : x(d.date) + horizontalLineExtentionLength))
        .y(d => y(d[metricKey]))
    );
  svg
    .append('path')
    .datum(currItemHorizontalLineSeries)
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? x(d.date) : x(d.date) + horizontalLineExtentionLength))
        .y(_d => 0)
    )
    .transition(t)
    .attr('class', 'horizontal-dashed')
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1.5)
    .attr('stroke-dasharray', '6 6')
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? x(d.date) : x(d.date) + horizontalLineExtentionLength))
        .y(d => y(d[metricKey]))
    );

  svg
    .append('path')
    .datum(prevItemVerticalLineSeries)
    .transition(t)
    .attr('class', 'vertical-solid')
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1)
    .attr(
      'd',
      line()
        .x(d => x(d.date))
        .y((_d, i) => (i === 0 ? 25 : svgHeight))
    );

  svg
    .append('path')
    .datum(currItemVerticalLineSeries)
    .transition(t)
    .attr('class', 'vertical-solid')
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1)
    .attr(
      'd',
      line()
        .x(d => x(d.date))
        .y((_d, i) => (i === 0 ? 25 : svgHeight))
    );

  const currValue = prevItem && currItem[metricKey];
  const prevValue = prevItem && prevItem[metricKey];
  const change = valueChange(currValue)(prevValue);

  // add the tooltip
  const tooltipSize = { width: 54, height: 36 };
  const tooltip = svg
    .append('g')
    .attr(
      'transform',
      `translate(${x(currItem.date) - tooltipSize.width / 2},${y(currValue) - tooltipSize.height - 18})`
    );

  // tooltip rect
  tooltip
    .append('rect')
    .attr('width', tooltipSize.width)
    .attr('height', tooltipSize.height)
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('class', 'shadow')
    .transition(t)
    .attr('stroke', '#33333333')
    .attr('stroke-width', 1)
    .attr('fill', GREEN);

  // tooltip change%
  tooltip
    .append('text')
    .attr('x', tooltipSize.width / 2)
    .attr('y', tooltipSize.height / 2 + 2)
    .attr('opacity', 0)
    .attr('text-align', 'center')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('fill', '#fff')
    .attr('font-size', '14px')
    .transition(t)
    .attr('opacity', 1)
    // eslint-disable-next-line no-restricted-globals
    .text(isFinite(change) ? percent(change) : 'N/A');

  // tooltip triangle
  tooltip
    .append('path')
    .attr('d', 'M 0 0 L 15 0 L 7.5 7.5 z')
    .attr('transform', `translate(${tooltipSize.width / 2 - 7.5} ,${tooltipSize.height / 2 + 17})`)
    .attr('class', 'shadow')
    .attr('fill', GREEN);

  return true;
}

export default draw;
