/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchFundsInMarketPending = createAction('FETCH_FUNDS_IN_MARKET_PENDING');
const fetchFundsInMarketError = createAction('FETCH_FUNDS_IN_MARKET_ERROR');
const fetchFundsInMarketSuccess = createAction('FETCH_FUNDS_IN_MARKET_SUCCESS');

export function fetchFundsInMarket({ year, strategyId, regionId }) {
  return async function (dispatch) {
    dispatch(fetchFundsInMarketPending());
    try {
      const fundsInMarket = await filterApi.getFundsInMarket({ year, strategyId, regionId });
      dispatch(fetchFundsInMarketSuccess({ fundsInMarket }));
    } catch (error) {
      dispatch(fetchFundsInMarketError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  all: null,
};

export default createReducer(initialState, {
  [fetchFundsInMarketPending]: state => {
    state.fetching = true;
  },
  [fetchFundsInMarketError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [fetchFundsInMarketSuccess]: (state, action) => {
    const { fundsInMarket } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.all = fundsInMarket;
  },
});
