import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const bulletCss = css`
  padding: 0 6px;
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

const radioFormControl = css`
  margin-right: 10px;
  color: #929090;
  font-weight: 300;
  .MuiTypography-body1 {
    font-size: 12px;
  }
`;

function NewsArticleBulletPointLength(props) {
  const { value, onChange, className } = props;

  function handleChange(_e, val) {
    onChange(+val);
  }

  return (
    <RadioGroup className={className} aria-label="Summary Length" value={value} onChange={handleChange}>
      <FormControlLabel
        value={3}
        control={<Radio color="primary" css={bulletCss} />}
        label="Short"
        css={radioFormControl}
      />
      <FormControlLabel
        value={5}
        control={<Radio color="primary" css={bulletCss} />}
        label="Medium"
        css={radioFormControl}
      />
      <FormControlLabel
        value={8}
        control={<Radio color="primary" css={bulletCss} />}
        label="Long"
        css={radioFormControl}
      />
    </RadioGroup>
  );
}

NewsArticleBulletPointLength.defaultProps = {
  onChange: () => {},
};

NewsArticleBulletPointLength.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

export default NewsArticleBulletPointLength;
