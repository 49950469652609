import React from 'react';
import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import { currency, abbreviations, multiple, percent } from 'src/formatters';
import { fundPerformance } from 'src/config/tutorials';
import { useFund, useUserFundPeerGroupFilter } from 'src/hooks';
import {
  AppPanel,
  ButtonInfo,
  IconBarChart,
  IconLineChart,
  IconCurveHistory,
  FundDetails,
  PMEInfoTooltip,
  SubscriptionPaywall,
  FundPeerBenchmarks,
  FundForecastCurves,
  FundPMEViews,
} from 'src/components';
import TimeSeriesView from './TimeSeriesView';
import routes from 'src/config/routes';

const VIEWS = {
  TIMESERIES: 'TIMESERIES',
  PEER_BENCHMARK: 'PEER_BENCHMARK',
  JCURVE: 'JCURVE',
  PME: 'PME',
};

const Section = ({ hidden, children }) => {
  if (hidden) return null;
  return (
    <section
      css={`
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 0.75rem;
      `}
    >
      {children}
    </section>
  );
};

const StyledButtonInfo = styled(ButtonInfo)`
  margin-left: 6px;
  opacity: ${p => (p.$active ? 1 : 0)};
  pointer-events: ${p => (p.$active ? 'all' : 'none')};
  color: #979797;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

function FundPerformancePanel({ spanX, spanY, fundId, minHeight }) {
  const fund = useFund({ fundId });
  const fundPeerGroupFilter = useUserFundPeerGroupFilter(fundId);
  const [defaultView, setDefaultView] = useState(VIEWS.JCURVE);
  const [userView, setUserView] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [infoTooltipAnchorEl, setInfoTooltipAnchorEl] = useState();

  const title = useMemo(() => {
    if (!fund || !isExpanded) return 'Fund Performance';
    return `${fund.name} - Performance`;
  }, [fund, isExpanded]);

  const fundMetaData = useMemo(() => {
    if (!fund) return [];

    return [
      {
        label: 'Fund Manager',
        value: fund.fundManagerName,
        link: routes.FUND_MANAGER.replace(':fundManagerId', fund.fundManagerId),
      },
      { label: 'Strategy', value: fund.strategy },
      { label: 'Geography', value: fund.mainRegionName },
      { label: 'Vintage', value: fund.vintage },
      {
        label: 'Fund Size',
        value: currency(abbreviations.million)()(fund.size),
      },
      { label: 'Called', value: percent()(fund.calledPct) },
      { label: 'TVPI', value: multiple(1)(fund.tvpi) },
      { label: 'IRR', value: percent()(fund.irr) },
    ];
  }, [fund]);

  const view = userView || defaultView;

  function handleChangeHistoryData(data) {
    setDefaultView(data.length < 2 ? VIEWS.PEER_BENCHMARK : VIEWS.JCURVE);
  }

  const handleInfoButtonClick = useCallback(
    evt => {
      setInfoTooltipAnchorEl(evt?.currentTarget);
    },
    [setInfoTooltipAnchorEl]
  );

  const handleInfoButtonClose = useCallback(() => {
    setInfoTooltipAnchorEl(null);
  }, [setInfoTooltipAnchorEl]);

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title={title}
      onChangeExpanded={setIsExpanded}
      tutorial={fundPerformance}
      controls={
        <div>
          <AppPanel.Button
            startIcon={<IconLineChart />}
            selected={view === VIEWS.JCURVE}
            onClick={() => setUserView(VIEWS.JCURVE)}
          >
            J‑Curve
          </AppPanel.Button>
          <AppPanel.Button
            startIcon={<IconBarChart />}
            selected={view === VIEWS.PEER_BENCHMARK}
            onClick={() => setUserView(VIEWS.PEER_BENCHMARK)}
          >
            Key&nbsp;Metrics
          </AppPanel.Button>
          <AppPanel.Button
            startIcon={<IconCurveHistory />}
            selected={view === VIEWS.PME}
            onClick={() => setUserView(VIEWS.PME)}
          >
            PME
          </AppPanel.Button>

          <StyledButtonInfo onClick={handleInfoButtonClick} $active={view === VIEWS.PME} />
        </div>
      }
      css={`
        @media (min-width: 0px) and (max-width: ${md.BREAKPOINT}px) {
          min-height: 90vh;
        }
      `}
      expandable
    >
      <PMEInfoTooltip
        open={Boolean(infoTooltipAnchorEl)}
        anchorEl={infoTooltipAnchorEl}
        onClose={handleInfoButtonClose}
      />
      {fund && (
        <Layout as={SubscriptionPaywall}>
          <FundDetails
            fundId={fundId}
            title={fund.name}
            isUserFund={Boolean(fund.userId)}
            isUserFundBenchmark={Boolean(fundPeerGroupFilter.filterId)}
            metaData={fundMetaData}
          />
          <Section hidden={view !== VIEWS.TIMESERIES}>
            <TimeSeriesView fundId={fundId} />
          </Section>
          <Section hidden={view !== VIEWS.PEER_BENCHMARK}>
            <FundPeerBenchmarks fundId={fundId} />
          </Section>
          <Section hidden={view !== VIEWS.JCURVE}>
            <FundForecastCurves fundId={fundId} onChangeHistoryData={handleChangeHistoryData} />
          </Section>
          <Section hidden={view !== VIEWS.PME}>
            <FundPMEViews fundId={fundId} />
          </Section>
        </Layout>
      )}
    </AppPanel>
  );
}

FundPerformancePanel.defaultProps = {};

FundPerformancePanel.propTypes = {
  children: PropTypes.any,
};

export default FundPerformancePanel;
