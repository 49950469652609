import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconSearch(props) {
  return (
    <Svg viewBox="0 0 500 500" {...props}>
      <g>
        <path d="M343.6,201.5c-0.3,58.8-37.4,113.6-93,133.8c-56.9,20.7-121.1,3.3-159.8-43.1     C52.4,246.4,47.9,179,78.6,128c30.6-50.8,91.6-78.3,149.9-66.5c58.3,11.8,103.8,59.1,113.4,117.8     C343.1,186.6,343.5,194.1,343.6,201.5c0.1,12.9,20.1,12.9,20,0c-0.3-68-43.4-129.3-107.1-152.9c-63.4-23.4-137.9-3.2-180.7,49     C32.3,150.6,26,226.9,61.7,285.7c35.5,58.5,105.2,88.6,172.2,75.1c64.9-13.1,117.5-67.6,127.6-133.1c1.3-8.7,2.1-17.4,2.2-26.2     C363.6,188.7,343.6,188.7,343.6,201.5z" />
        <path d="M303.3,317c16,16,32,32,48,48c25.6,25.6,51.3,51.3,76.9,76.9c5.9,5.9,11.7,11.7,17.6,17.6     c9.1,9.1,23.3-5,14.1-14.1c-16-16-32-32-48-48c-25.6-25.6-51.3-51.3-76.9-76.9c-5.9-5.9-11.7-11.7-17.6-17.6     C308.3,293.7,294.2,307.9,303.3,317L303.3,317z" />
      </g>
    </Svg>
  );
}
