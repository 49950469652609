import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFundFinancialHistory } from '../store/fund-financial-history';

function useFundFinancialHistory({ fundId }) {
  const dispatch = useDispatch();
  const allFundFinancialHistories = useSelector(state => state.fund.financialHistory.byId);

  useEffect(() => {
    if (!allFundFinancialHistories) return;
    if (allFundFinancialHistories[fundId]) return;
    dispatch(fetchFundFinancialHistory({ fundId }));
  }, [allFundFinancialHistories, dispatch, fundId]);

  return useMemo(() => {
    if (!allFundFinancialHistories) return { items: [] };
    if (!allFundFinancialHistories[fundId]) return { items: [] };
    return allFundFinancialHistories[fundId];
  }, [allFundFinancialHistories, fundId]);
}

export default useFundFinancialHistory;
