import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getStrategy } from 'src/services/filter-api';

const QUERY_OPTS = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  staleTime: 1000 * 60 * 60,
};

function useStrategyQuery(strategyId) {
  const query = useQuery(
    ['strategies', strategyId],
    async function () {
      if (!strategyId) return null;
      return await getStrategy(strategyId);
    },
    QUERY_OPTS
  );

  return useMemo(() => {
    if (!query.data) return query;

    return {
      ...query,
      data: {
        ...query.data,
        isSubStrategy: query.data.parentId !== null,
      },
    };
  }, [query]);
}

export default useStrategyQuery;
