const routes = {
  MARKET_FILTER: '/',
  FUNDS: '/funds',
  FUND: '/funds/:fundId',
  FUND_MANAGERS: '/managers/',
  FUND_MANAGER: '/managers/:fundManagerId',
  NEWS: '/news',
  NEWS_ARTICLE: '/news/:articleId',
  DASHBOARD: '/saved',
  CONTACT: '/contact',
  SUBSCRIBED: '/subscribed',
  USER_SIGN_UP: '/account/sign-up',
  USER_SIGN_IN: '/account/sign-in',
  USER_PASSWORD_RESET: '/account/forgot-password',
  USER_EMAIL_VERIFICATION: '/account/verify',
  ACCOUNT: '/account',
  CASHFLOWS: '/cashflows',
  TUTORIALS: '/tutorials',
};

export default routes;
