import { scaleLinear, extent, select, quantile } from 'd3';

const ABOVE_MEDIAN_COLOR = '#00cbffaa';
const BELOW_MEDIAN_COLOR = '#ff5c00aa';

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function draw({ svgId, width, height, funds, subjectFundId, metric, margin, mouseEventRefs, isIrrelevant }) {
  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  if (!width || !height) return;
  if (funds.length < 1) return;

  const sortedFunds = funds.slice().sort((a, b) => a[metric.key] - b[metric.key]);

  const peerFunds = sortedFunds.filter(d => d.fundId !== subjectFundId);
  const subjectFunds = sortedFunds.filter(d => d.fundId === subjectFundId);

  const topQuantile = quantile(peerFunds, 0.75, d => d[metric.key]);
  const middleQuantile = quantile(peerFunds, 0.5, d => d[metric.key]);
  const bottomQuantile = quantile(peerFunds, 0.25, d => d[metric.key]);

  const canvasWidth = Math.max(0, width - (margin.left + margin.right));
  const canvasHeight = Math.max(0, height - (margin.top + margin.bottom));

  const xDomain = extent([subjectFunds[0][metric.key], topQuantile, bottomQuantile]);

  const xScale = scaleLinear().domain(xDomain).range([0, canvasWidth]).nice();

  const yScale = canvasHeight / 2;

  const quartileData = {
    high: {
      key: 'high',
      value: topQuantile,
      weight: 1,
    },
    med: {
      key: 'med',
      value: middleQuantile,
      weight: 1,
    },
    low: {
      key: 'low',
      value: bottomQuantile,
      weight: 1,
    },
  };

  const root = select(`#${svgId}`)
    .selectAll('.root')
    .data([sortedFunds])
    .join('g')
    .attr('class', `root ${isIrrelevant ? 'disabled' : ''}`)
    .attr('transform', `translate(${margin.left} ${margin.top})`);

  const subjectFundGroup = root
    .selectAll('.fund-group.-subject')
    .data([subjectFunds])
    .join('g')
    .attr('class', `fund-group -subject`);

  subjectFundGroup
    .selectAll('.fund-dot')
    .data(subjectFunds, d => d.fundId)
    .join('circle')
    .attr('class', 'fund-dot')
    .attr('cx', d => xScale(d[metric.key])?.toFixed(1) ?? 0)
    .attr('cy', yScale)
    .attr('r', 4)
    .attr('fill', function (d) {
      if (isIrrelevant) return 'currentColor';
      return d[metric.key] >= quartileData.med.value ? ABOVE_MEDIAN_COLOR : BELOW_MEDIAN_COLOR;
    })
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd));

  if (isIrrelevant) return;

  root
    .selectAll('.quartile-line')
    .data(Object.values(quartileData))
    .join('line')
    .attr('class', d => `quartile-line -${d.key}`)
    .attr('x1', d => xScale(d.value))
    .attr('x2', d => xScale(d.value))
    .attr('y1', 5)
    .attr('y2', canvasHeight - 5)
    .attr('stroke-width', d => d.weight)
    .attr('stroke', '#4c4c4c');

  root
    .selectAll('.quartile-line-base')
    .data([{ start: topQuantile, end: bottomQuantile }])
    .join('line')
    .attr('class', 'quartile-line-base')
    .attr('x1', d => xScale(d.start))
    .attr('x2', d => xScale(d.end))
    .attr('y1', canvasHeight / 2)
    .attr('y2', canvasHeight / 2)
    .attr('stroke-width', d => d.weight)
    .attr('stroke', 'rgba(255, 255, 255, 0.25)');
}

export default draw;
