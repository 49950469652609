import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'src/formatters';
import { strings } from 'src/strings';
import { TextField, IconSearch } from 'src/components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const Icon = styled(IconSearch)`
  position: absolute;
  font-size: 1.5em;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.4;
`;

const SearchTextField = styled(TextField)`
  flex: 1;
  margin: 0;
  background-color: #444444;

  padding: 0;

  input {
    padding: 0px !important;

    ::placeholder {
      font-weight: 300;
      opacity: 0.5;
      font-size: 1.25em;
    }
  }

  fieldset {
    border: solid 1px #545454;
  }

  .MuiOutlinedInput-input {
    background-color: transparent;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
`;

function SearchField({ isLoading, ...props }) {
  return (
    <Wrapper>
      <SearchTextField
        disabled={isLoading}
        // margin="dense"
        {...props}
        placeholder={isLoading ? 'Loading...' : capitalize(false)(strings.labels.searchPlaceholder)}
      />
      <Icon />
    </Wrapper>
  );
}

SearchField.defaultProps = {};

SearchField.propTypes = {};

export default SearchField;
