import React from 'react';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { useUpdateEffect } from 'react-use';
import { lookup } from 'src/lookup';
import { usageTrackingChannel, USAGE_TRACKING_TOPICS } from 'src/services';
import { useStrategiesFilter, useSubStrategyPerformance, useLayoutActions, useHasSubscription } from 'src/hooks';
import {
  AppPanel,
  StrategyPerformanceGrid,
  StrategyPerformanceTimeSeries,
  StrategyPerformanceIRRTimeSeries,
  StrategyCurveHistoryGrid,
  StrategyCurveHistoryTimeSeries,
  Responsive,
  TvpiChartTooltip,
  FundsTable,
  SubscriptionPaywall,
  Select,
  Box,
  ButtonInfo,
  FinancialMetricSelection,
} from 'src/components';

import { DISPLAY, TYPES, TIME_RANGES, QUARTILE_NAMES, CURVE_HISTORY_SORT_TYPES } from './constants';
import { BreadCrumbNav } from './bread-crumb-nav';
import { TimeRangeButtons } from './time-range-buttons';
import { SortButton } from './sort-button';
import { QuartileSelect } from './quartile-select';
import { PublicIndexSelector } from './public-index-selector';
import { TopQuartileVisibliltyToggle } from './top-quartile-visibility-toggle';

const Header = styled(Box)`
  display: grid;
  gap: 10px;
  margin-bottom: 10px;
`;

const Options = styled(Box)`
  display: flex;
  align-items: center;
  height: 26px;
`;

const ChartChangeInAvg = styled(Box)`
  font-size: 0.9em;
`;

const TypeSelect = styled(Select)`
  font-size: inherit;
  & .MuiInputBase-root {
    height: 24px;
    margin-right: 10px;
  }
`;

const SortDescription = styled.div`
  display: inline-block;
  margin-right: 10px;

  & span {
    color: #535353;
  }
`;

function StrategyPerformanceVisualization({ showPerformanceView, showCurveHistoryView }) {
  const hasSubscription = useHasSubscription();
  const subStrategyPerformance = useSubStrategyPerformance();
  const [selectedSubStrategy, setSelectedSubStrategy] = useState();
  const [strategiesFilter, setStrategiesFilter] = useStrategiesFilter();

  const [type, setType] = useState(TYPES.JCURVE);
  const [quartile, setQuartile] = useState('ALL');
  const [timeRange, setTimeRange] = useState(TIME_RANGES[12]);
  const [display, setDisplay] = useState(DISPLAY.grid);
  const [tvpiTimeSeriesData, setTvpiTimeSeriesData] = useState(null);
  const [irrTimeSeriesData, setIrrTimeSeriesData] = useState(null);
  const tableTooltipBoundaryRef = useRef(null);
  const { isExpanded, setTitle } = useContext(AppPanel.Context);
  const { expandPanel } = useLayoutActions();
  const [customGridSort, setCustomGridSort] = useState('none');
  const [timeSeriesMetric, setTimeSeriesMetric] = useState(lookup.financialMetric.irr);
  const [topQuartileVisible, setTopQuartilevisible] = useState(false);
  const [selectedPublicIndex, setSelectedPublicIndex] = useState();
  const [infoTooltipAnchorEl, setInfoTooltipAnchorEl] = useState(null);

  const toggleCustomGridSort = useCallback(
    function () {
      const next = {
        none: 'asc',
        asc: 'desc',
        desc: 'none',
      }[customGridSort];

      setCustomGridSort(next);
    },
    [customGridSort]
  );

  useEffect(() => {
    if (display === DISPLAY.list && hasSubscription) {
      expandPanel();
    }
  }, [display, expandPanel, hasSubscription]);

  useEffect(() => {
    if (selectedSubStrategy) {
      setTimeSeriesMetric(lookup.financialMetric.tvpi);
      setDisplay(DISPLAY.timeSeries);
      setSelectedPublicIndex(null);
    } else {
      setTimeSeriesMetric(null);
      setDisplay(DISPLAY.grid);
    }
  }, [selectedSubStrategy]);

  useEffect(() => {
    if (selectedSubStrategy) {
      const selected = subStrategyPerformance[selectedSubStrategy.subStrategyId];
      switch (timeSeriesMetric) {
        case lookup.financialMetric.tvpi:
          setTvpiTimeSeriesData(selected.all);
          break;
        case lookup.financialMetric.irr:
          setIrrTimeSeriesData({
            all: selected.all,
            topQuartile: selected.topQuartile,
          });
          break;
        default:
          break;
      }
    } else {
      setTvpiTimeSeriesData(null);
      setIrrTimeSeriesData(null);
    }
  }, [timeSeriesMetric, selectedSubStrategy, subStrategyPerformance]);

  useEffect(() => {
    const { subStrategy } = selectedSubStrategy || {};
    if (!subStrategy) return setTitle('Strategy Performance');
    if (showPerformanceView) return setTitle(`Performance: ${subStrategy}`);
    if (showCurveHistoryView)
      return setTitle(`Strategy Performance: ${subStrategy} – ${QUARTILE_NAMES[quartile]} ${type}`);
  }, [selectedSubStrategy, setTitle, showPerformanceView, showCurveHistoryView, quartile, type]);

  useEffect(() => {
    setStrategiesFilter(state =>
      state?.map(item => {
        return {
          ...item,
          selected: selectedSubStrategy
            ? [item.strategyId, item.parentId].includes(selectedSubStrategy.subStrategyId)
            : true,
        };
      })
    );
  }, [selectedSubStrategy, setStrategiesFilter]);

  useEffect(() => {
    if (display !== DISPLAY.grid) return;
    if (timeRange.value < 36) return;
    setTimeRange(TIME_RANGES[12]);
  }, [display, timeRange.value]);

  useUpdateEffect(() => {
    if (showCurveHistoryView) expandPanel();
  }, [showCurveHistoryView]);

  function handleSetTimeRange(value) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterStrategyPerformanceTime);
    setTimeRange(value);
    setInfoTooltipAnchorEl(null);
  }

  function handleGridItemClick(_node, d) {
    const { strategy, subStrategy, strategyId, subStrategyId } = d;
    const enabledPublicIndexes = Object.keys(d.all[0].publicIndexValues);

    setInfoTooltipAnchorEl(null);
    setSelectedSubStrategy({
      strategy,
      subStrategy,
      strategyId,
      subStrategyId,
      enabledPublicIndexes,
    });
  }

  function handleViewAllFundsClick() {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterStrategyPerformanceList);
    setDisplay(DISPLAY.list);
    setInfoTooltipAnchorEl(null);
  }

  function handleBackClick() {
    setSelectedSubStrategy(null);
    setInfoTooltipAnchorEl(null);
  }

  function handleTableRowClick(_e, { data }) {
    navigate(`/funds/${data.fundId}`);
    setInfoTooltipAnchorEl(null);
  }

  function handleTimeSeriesMetricChange(metric) {
    setTimeSeriesMetric(metric);
  }

  function handleInfoTooltipOpen(evt) {
    setInfoTooltipAnchorEl(evt.currentTarget);
  }

  function handleInfoTooltipClose() {
    setInfoTooltipAnchorEl(null);
  }

  return (
    <>
      <div
        css={`
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
        `}
      >
        <Header>
          {(display === DISPLAY.timeSeries || display === DISPLAY.list) && (
            <BreadCrumbNav
              showAllFundsLink={showPerformanceView && display === DISPLAY.timeSeries}
              onBackClick={handleBackClick}
              onViewAllFundsClick={handleViewAllFundsClick}
            />
          )}

          {(display === DISPLAY.grid || display === DISPLAY.timeSeries) && (
            <Options
              justifyContent={
                display === DISPLAY.timeSeries && timeSeriesMetric === lookup.financialMetric.irr
                  ? 'flex-end'
                  : 'space-between'
              }
            >
              {showPerformanceView &&
                (display === DISPLAY.grid ||
                  (display === DISPLAY.timeSeries && timeSeriesMetric === lookup.financialMetric.tvpi)) && (
                  <TimeRangeButtons
                    selectedValue={timeRange.value}
                    show3And5YButtons={display === DISPLAY.timeSeries}
                    onTimeRangeSelect={handleSetTimeRange}
                  />
                )}
              {showCurveHistoryView && (display === DISPLAY.grid || display === DISPLAY.timeSeries) && (
                <Box display="flex" flexDirection="row">
                  <TypeSelect
                    preset="compact"
                    labelType="inline"
                    variant="outlined"
                    size="small"
                    items={[TYPES.JCURVE, TYPES.NAV]}
                    onChange={event => setType(event.target.value)}
                    value={type}
                  />
                  <QuartileSelect value={quartile} onChange={setQuartile} />
                </Box>
              )}
              {display === DISPLAY.grid && showPerformanceView && (
                <div>
                  {showCurveHistoryView && (
                    <Responsive.OnAtLeastTablet>
                      <SortDescription>
                        <span>Sorted</span>: {CURVE_HISTORY_SORT_TYPES?.[type]?.[customGridSort]}
                      </SortDescription>
                    </Responsive.OnAtLeastTablet>
                  )}
                  <SortButton onToggleSort={toggleCustomGridSort} sort={customGridSort} />
                </div>
              )}
              {display === DISPLAY.timeSeries && timeSeriesMetric === lookup.financialMetric.irr && (
                <>
                  <TopQuartileVisibliltyToggle
                    checked={topQuartileVisible}
                    onCheckChange={checked => setTopQuartilevisible(checked)}
                  />
                  <PublicIndexSelector
                    selectedIndex={selectedPublicIndex}
                    onSelectedIndexChange={setSelectedPublicIndex}
                    enabledIndexes={selectedSubStrategy && selectedSubStrategy.enabledPublicIndexes}
                  />
                </>
              )}
              {showPerformanceView && display === DISPLAY.timeSeries && (
                <ChartChangeInAvg>Change in Avg. TVPI</ChartChangeInAvg>
              )}
              {showPerformanceView &&
                (display === DISPLAY.timeSeries ? (
                  <div>
                    <FinancialMetricSelection
                      hideIrr
                      hidePme
                      financialMetric={timeSeriesMetric}
                      onMetricChange={handleTimeSeriesMetricChange}
                    />
                    <ButtonInfo
                      onClick={handleInfoTooltipOpen}
                      css={`
                        color: #979797;
                        margin-left: 4px;
                      `}
                      className="info-button"
                    />
                    <TvpiChartTooltip
                      open={Boolean(infoTooltipAnchorEl)}
                      anchorEl={infoTooltipAnchorEl}
                      onClose={handleInfoTooltipClose}
                    />
                  </div>
                ) : (
                  <div>{lookup.financialMetric.tvpi.label.toUpperCase()} </div>
                ))}
            </Options>
          )}
        </Header>
        {showCurveHistoryView && subStrategyPerformance && display === DISPLAY.grid && (
          <StrategyCurveHistoryGrid
            css={`
              padding: 0px;
            `}
            strategies={Object.values(subStrategyPerformance).sort((a, b) => a.rank - b.rank)}
            customSort={customGridSort}
            metric={lookup.financialMetric.tvpi}
            type={type}
            quartile={quartile}
            timeRange={timeRange}
            expanded={isExpanded}
            onItemClick={handleGridItemClick}
          />
        )}
        {showCurveHistoryView && selectedSubStrategy && display === DISPLAY.timeSeries && (
          <StrategyCurveHistoryTimeSeries
            height={`calc(100% - ${92}px)`}
            strategyId={selectedSubStrategy?.strategyId}
            subStrategyId={selectedSubStrategy?.subStrategyId}
            quartile={quartile}
            type={type}
            expanded={isExpanded}
          />
        )}
        {showPerformanceView && subStrategyPerformance && display === DISPLAY.grid && (
          <StrategyPerformanceGrid
            css={`
              padding: 0px;
            `}
            strategies={Object.values(subStrategyPerformance).sort((a, b) => a.rank - b.rank)}
            customSort={customGridSort}
            metric={lookup.financialMetric.tvpi}
            timeRange={timeRange}
            expanded={isExpanded}
            onItemClick={handleGridItemClick}
          />
        )}
        {showPerformanceView &&
          timeSeriesMetric === lookup.financialMetric.tvpi &&
          tvpiTimeSeriesData &&
          display === DISPLAY.timeSeries && (
            <StrategyPerformanceTimeSeries
              height={`calc(100% - ${92}px)`}
              data={tvpiTimeSeriesData}
              metric={lookup.financialMetric.tvpi}
              timeRange={timeRange}
              expanded={isExpanded}
            />
          )}
        {showPerformanceView &&
          timeSeriesMetric === lookup.financialMetric.irr &&
          irrTimeSeriesData &&
          display === DISPLAY.timeSeries && (
            <StrategyPerformanceIRRTimeSeries
              height={`calc(100% - ${92}px)`}
              data={irrTimeSeriesData}
              publicIndex={selectedPublicIndex && selectedPublicIndex.name}
              topQuartileVisible={topQuartileVisible}
              expanded={isExpanded}
            />
          )}
        {display === DISPLAY.list && (
          <SubscriptionPaywall
            ref={tableTooltipBoundaryRef}
            css={`
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
            `}
          >
            <FundsTable
              strategies={strategiesFilter}
              width="100%"
              height="calc(100% - 35px)"
              padding="10px"
              expanded={isExpanded}
              enableHover
              rowHoverColor="#fff"
              rowHoverBackgroundColor="#0a5f756a"
              rowColor="#afafaf"
              onRowClick={handleTableRowClick}
            />
          </SubscriptionPaywall>
        )}
      </div>
    </>
  );
}

StrategyPerformanceVisualization.defaultProps = {
  showPerformanceView: true,
  showCurveHistoryView: false,
};

StrategyPerformanceVisualization.propTypes = {
  showPerformanceView: PropTypes.bool,
  showCurveHistoryView: PropTypes.bool,
};

export default StrategyPerformanceVisualization;
