import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, ClickAwayListener } from '@material-ui/core';
import {
  TooltipContent,
  TooltipHeader,
  TooltipRow,
  TooltipTitle,
  TooltipData,
  TooltipLabel,
  TooltipArrowRight,
} from 'src/components';
import { currency, abbreviations } from 'src/formatters';

const millions = currency(abbreviations.million)();

const TooltipWrapper = styled.div``;

function FundsRaisedTooltip({ data, open, anchorEl, onClickAway }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="left"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: '.funds-raised-arrow',
          },
        }}
      >
        {data ? (
          <TooltipWrapper>
            <TooltipArrowRight className="funds-raised-arrow" marginLeft="2px" />
            <TooltipContent>
              <TooltipHeader>
                <TooltipTitle>{data.label}</TooltipTitle>
              </TooltipHeader>
              {data.year && (
                <TooltipRow>
                  <TooltipLabel>YEAR</TooltipLabel>
                  <TooltipData>{data.year}</TooltipData>
                </TooltipRow>
              )}
              {data.size && (
                <TooltipRow>
                  <TooltipLabel>SIZE</TooltipLabel>
                  <TooltipData>{millions(data.size)}</TooltipData>
                </TooltipRow>
              )}
            </TooltipContent>
          </TooltipWrapper>
        ) : (
          <div />
        )}
      </Popper>
    </ClickAwayListener>
  );
}
FundsRaisedTooltip.defaultProps = {
  data: null,
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewFunds: () => {},
};

FundsRaisedTooltip.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
  onViewFunds: PropTypes.func,
};

export default FundsRaisedTooltip;
