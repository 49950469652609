import { layoutGenerator } from 'react-break';
import { sm, md, lg, xl } from 'src/config/layout';

const layout = layoutGenerator({
  mobile: 0,
  phablet: sm.BREAKPOINT,
  tablet: md.BREAKPOINT,
  laptop: lg.BREAKPOINT,
  desktop: xl.BREAKPOINT,
});

const AppBreakpoints = {
  OnMobile: layout.is('mobile'),
  OnDesktop: layout.is('desktop'),

  OnAtMostPhablet: layout.isAtMost('phablet'),
  OnAtLeastPhablet: layout.isAtLeast('phablet'),

  OnAtMostTablet: layout.isAtMost('tablet'),
  OnAtLeastTablet: layout.isAtLeast('tablet'),

  OnAtMostLaptop: layout.isAtMost('laptop'),
  OnAtLeastLaptop: layout.isAtLeast('laptop'),
};

export default AppBreakpoints;
