import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconChat(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M69.3,16H30.7C15.1,16,2.5,28.6,2.5,44.2c0,10.5,5.8,19.7,14.4,24.6c-1.5,3.1-3.3,6.6-5.6,9.6c-2,2.7,0.5,6.4,3.8,5.5    c10.5-2.8,20.1-8.1,25.5-11.5h28.7c15.6,0,28.2-12.6,28.2-28.2S84.9,16,69.3,16z M28.8,50.4c-3.4,0-6.2-2.8-6.2-6.2    c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C35,47.6,32.2,50.4,28.8,50.4z M50,50.4c-3.4,0-6.2-2.8-6.2-6.2    c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2C56.2,47.6,53.4,50.4,50,50.4z M71.2,50.4c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2    c3.4,0,6.2,2.8,6.2,6.2C77.4,47.6,74.6,50.4,71.2,50.4z" />
    </Svg>
  );
}
