import {
  select,
  extent,
  min,
  axisBottom,
  axisLeft,
  scaleLinear,
  scaleQuantile,
  easeCubicInOut,
  format,
  quantile,
} from 'd3';
import { minBy } from 'src/lib/lodash';
import { multiple, percent } from 'src/formatters';

const yTvpiFormat = multiple(1);
const yIrrFormat = percent(0);
const xFormat = format('.0f');
const TRANSITION_DURATION = 500;
const Y_AXIS_PAD = 20;
const QUARTILE_LINE_WIDTH = 30;
const ABOVE_MEDIAN_COLOR = '#00cbffaa';
const BELOW_MEDIAN_COLOR = '#ff5c00aa';
const USER_FUND_COLOR = '#e1b72a';
const USER_BENCHMARK_COLOR = '#3f89a8';
const NO_PERFORMANCE_COLOR = '#00cbffaa';
const QUARTILE_RANKING_COLOR = '#ffffff';

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function quantileScale(subject, group) {
  const domain = [subject, ...group].sort();
  const range = new Array(101).fill(0).map((_, i) => i);

  return scaleQuantile().domain(domain).range(range)(subject) / 100;
}

function getFundValue(key, calcs, index) {
  return function (fund) {
    if (typeof key === 'object') {
      const pmeValue = fund.pmeValues?.find(p => p.indexName === index);
      return pmeValue?.insufficientData ? null : pmeValue?.[key[calcs]];
    }
    return fund[key];
  };
}

function draw({
  id,
  funds: allFunds = [],
  fundPeerGroupFilters,
  peers,
  calcs,
  index,
  metric,
  size = {},
  margin,
  mouseEventRefs,
}) {
  if (!size.width || !size.height) return;

  const isPme = Boolean(metric.isPme);
  const getValue = getFundValue(metric.key, calcs.key, index);

  /**
   * Remove funds whose current metric is not calculable and add quantile rank to funds
   */
  const funds = allFunds
    .filter(f => {
      const value = getValue(f);
      if (isNaN(value)) return false;

      return value !== null && value !== undefined;
    })
    .map(fund => {
      const peerMetrics = peers?.[fund.fundId]?.map(getValue) || [];
      const quantileRank = quantileScale(getValue(fund), peerMetrics);
      const isUserFundBenchmark = Boolean(fundPeerGroupFilters?.[fund.fundId]);

      return { ...fund, peerMetrics, quantileRank, isUserFundBenchmark };
    });

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  function getQuantile(fund, value) {
    const p = peers[fund.fundId];
    if (!p) return 0;

    return quantile(p, value, getValue) || 0;
  }

  const root = select(`#${id}`)
    .selectAll('.root')
    .data([funds])
    .join('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // set the dimensions and margins of the graph
  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;

  const fundWithMinVintage = minBy(funds, f => f.vintage);

  /**
   * https://github.com/altman-ai/fund-filter/issues/784
   * https://github.com/altman-ai/fund-filter/issues/860
   * Show candlestick for all tvpi but
   * only for irr w/ vintage < 2019
   */
  const showPeerPerformance = fund => {
    const thresholdYear = new Date().getFullYear() - 3 + 1;

    return fund.vintage < thresholdYear;
  };

  const sortedPeers = Object.values(peers).map(p => p.sort((a, b) => getValue(a) - getValue(b)));

  // get the top quartile values for every peer group
  const topQuartileValues = sortedPeers
    .filter(peerGroup => peerGroup.length > 0)
    .map(peerGroup => quantile(peerGroup, 0.75, getValue));

  // get the bottom quartile values for ewvery peer group
  const bottomQuartileValues = sortedPeers
    .filter(peerGroup => peerGroup.length > 0)
    .map(peerGroup => quantile(peerGroup, 0.25, getValue));

  // get the max top quartile value
  const topPeerQuartileValue = Math.max(...topQuartileValues);
  // get the min bottom quartile value
  const bottomPeerQuartileValue = Math.min(...bottomQuartileValues);

  // the y extent is calculated across all the top and bottom quartiles and the fund metric values
  const extentValues = funds.map(getValue);

  if (Number.isFinite(topPeerQuartileValue)) extentValues.push(topPeerQuartileValue);

  if (Number.isFinite(bottomPeerQuartileValue)) extentValues.push(bottomPeerQuartileValue);

  const t = root.transition().duration(TRANSITION_DURATION).ease(easeCubicInOut);

  // create the x-axis
  const minVintage = min(funds, d => d.vintage);
  const maxVintage = new Date().getUTCFullYear();

  const xScale = scaleLinear().domain([minVintage, maxVintage]).range([50, svgWidth]);

  const xAxis = axisBottom(xScale)
    .ticks(7)
    .tickFormat(function (value) {
      if (Math.floor(value) !== value) return;
      return xFormat(value);
    });

  root
    .selectAll('.x-axis')
    .data([xScale])
    .join('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${svgHeight})`)
    .transition(t)
    .call(xAxis)

    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', '#808080'))
    .call(g => g.selectAll('.domain').attr('opacity', '0'));

  // Create the y-axis
  const yExtent = extent(extentValues);

  // create the Y axis
  const yScale = scaleLinear()
    .domain([yExtent[0] - (isPme ? 0.4 : 0), yExtent[1] + (isPme ? 0.4 : 0)])
    .range([svgHeight - Y_AXIS_PAD, Y_AXIS_PAD]);

  const yAxis = axisLeft(yScale)
    .ticks(isPme ? 10 : 4)
    .tickSize(-svgWidth)
    .tickFormat(function (value) {
      value = value < 0 ? value : Math.abs(value);
      if (metric.key === 'tvpi') return yTvpiFormat(value);
      if (metric.key === 'irr') return yIrrFormat(value);
      return value.toFixed(2);
    });

  root
    .selectAll('.y-axis')
    .data([yScale])
    .join('g')
    .attr('class', 'y-axis')
    .style('shape-rendering', 'crispEdges')
    .transition(t)
    .call(yAxis)
    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', '#808080'))
    .call(g => g.selectAll('.domain').attr('opacity', '0'));

  // position the y-axis label
  root
    .selectAll('.y-axis-label')
    .data([metric.labelChart])
    .join('text')
    .attr('class', 'y-axis-label')
    .attr('transform', 'rotate(-90)')
    .attr('dy', '1em')
    .style('fill', '#808080')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .style('font-family', 'Roboto')
    .attr('y', 0 - margin.left)
    .attr('x', 0 - svgHeight / 2)
    .transition(t)
    .text(metric.labelChart);

  // draw the range lines
  root
    .selectAll('.range-line')
    .data(funds, d => d.fundId)
    .join(
      enter =>
        enter
          .append('line')
          .attr('class', 'range-line')
          .attr('x1', d => xScale(d.vintage))
          .attr('x2', d => xScale(d.vintage))
          .attr('y1', d => yScale(getValue(d)))
          .attr('y2', d => yScale(getValue(d)))
          .attr('opacity', 0)
          .attr('stroke', '#999999')
          .call(e =>
            e
              .transition(t)
              .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
              .attr('y1', d => yScale(getQuantile(d, 0.75)))
              .attr('y2', d => yScale(getQuantile(d, 0.25)))
          ),
      update =>
        update.call(u =>
          u
            .transition(t)
            .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
            .attr('x1', d => xScale(d.vintage))
            .attr('x2', d => xScale(d.vintage))
            .attr('y1', d => yScale(getQuantile(d, 0.75)))
            .attr('y2', d => yScale(getQuantile(d, 0.25)))
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).remove())
    );

  // draw the median lines
  const medianLines = root.selectAll('.median-line').data(funds, d => d.fundId);

  medianLines.join(
    enter =>
      enter
        .append('line')
        .attr('class', 'median-line')
        .attr('x1', d => xScale(d.vintage))
        .attr('x2', d => xScale(d.vintage))
        .attr('y1', d => yScale(getValue(d)))
        .attr('y2', d => yScale(getValue(d)))
        .attr('opacity', 0)
        .attr('stroke', '#4c4c4c')
        .call(e =>
          e
            .transition(t)
            .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
            .attr('x1', d => xScale(d.vintage) - QUARTILE_LINE_WIDTH / 2)
            .attr('x2', d => xScale(d.vintage) + QUARTILE_LINE_WIDTH / 2)
            .attr('y1', d => yScale(getQuantile(d, 0.5)))
            .attr('y2', d => yScale(getQuantile(d, 0.5)))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
          .attr('x1', d => xScale(d.vintage) - QUARTILE_LINE_WIDTH / 2)
          .attr('x2', d => xScale(d.vintage) + QUARTILE_LINE_WIDTH / 2)
          .attr('y1', d => yScale(getQuantile(d, 0.5)))
          .attr('y2', d => yScale(getQuantile(d, 0.5)))
      ),
    exit =>
      exit.call(ex =>
        ex
          .transition(t)
          .attr('opacity', 0)

          .remove()
      )
  );

  // draw the top quartile lines
  const topQuartileLines = root.selectAll('.top-quartile-line').data(funds, d => d.fundId);

  topQuartileLines.join(
    enter =>
      enter
        .append('line')
        .attr('class', 'top-quartile-line')
        .attr('x1', d => xScale(d.vintage))
        .attr('x2', d => xScale(d.vintage))
        .attr('y1', d => yScale(getValue(d)))
        .attr('y2', d => yScale(getValue(d)))
        .attr('opacity', 0)
        .attr('stroke', '#999999')
        .call(e =>
          e
            .transition(t)
            .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
            .attr('x1', d => xScale(d.vintage) - QUARTILE_LINE_WIDTH / 2)
            .attr('x2', d => xScale(d.vintage) + QUARTILE_LINE_WIDTH / 2)
            .attr('y1', d => yScale(getQuantile(d, 0.75)))
            .attr('y2', d => yScale(getQuantile(d, 0.75)))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
          .attr('x1', d => xScale(d.vintage) - QUARTILE_LINE_WIDTH / 2)
          .attr('x2', d => xScale(d.vintage) + QUARTILE_LINE_WIDTH / 2)
          .attr('y1', d => yScale(getQuantile(d, 0.75)))
          .attr('y2', d => yScale(getQuantile(d, 0.75)))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).remove())
  );

  const topQuartileLabel = root.selectAll('.top-quartile-label').data(funds.length > 0 ? [fundWithMinVintage] : []);

  topQuartileLabel.join(
    enter =>
      enter
        .append('text')
        .attr('class', 'top-quartile-label')
        .attr('x', d => xScale(d.vintage))
        .attr('y', d => yScale(getValue(d)))
        .attr('opacity', 0)
        .attr('fill', '#5d5d5d')
        .attr('font-size', '10px')
        .text('75%')
        .attr('dx', '-2em')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .call(e =>
          e
            .transition(t)
            .attr('opacity', isPme ? 0 : 1)
            .attr('x', d => xScale(d.vintage))
            .attr('y', d => yScale(getQuantile(d, 0.75)))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .attr('opacity', isPme ? 0 : 1)
          .attr('x', d => xScale(d.vintage))
          .attr('y', d => yScale(getQuantile(d, 0.75)))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).remove())
  );

  const medianLabel = root.selectAll('.median-label').data(funds.length > 0 ? [fundWithMinVintage] : []);

  medianLabel.join(
    enter =>
      enter
        .append('text')
        .attr('class', 'median-label')
        .attr('x', d => xScale(d.vintage))
        .attr('y', d => yScale(getValue(d)))
        .attr('opacity', 0)
        .attr('fill', '#5d5d5d')
        .attr('font-size', '10px')
        .text('MED.')
        .attr('dx', '-2em')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .call(e =>
          e
            .transition(t)
            .attr('opacity', isPme ? 0 : 1)
            .attr('x', d => xScale(d.vintage))
            .attr('y', d => yScale(getQuantile(d, 0.5)))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .attr('opacity', isPme ? 0 : 1)
          .attr('x', d => xScale(d.vintage))
          .attr('y', d => yScale(getQuantile(d, 0.5)))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).remove())
  );

  const bottomQuartilelLabel = root
    .selectAll('.bottom-quartile-label')
    .data(funds.length > 0 ? [fundWithMinVintage] : []);

  bottomQuartilelLabel.join(
    enter =>
      enter
        .append('text')
        .attr('class', 'bottom-quartile-label')
        .attr('x', d => xScale(d.vintage))
        .attr('y', d => yScale(getValue(d)))
        .attr('opacity', 0)
        .attr('fill', '#5d5d5d')
        .attr('font-size', '10px')
        .text('25%')
        .attr('dx', '-2em')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .call(e =>
          e
            .transition(t)
            .attr('opacity', isPme ? 0 : 1)
            .attr('x', d => xScale(d.vintage))
            .attr('y', d => yScale(getQuantile(d, 0.25)))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .attr('opacity', isPme ? 0 : 1)
          .attr('x', d => xScale(d.vintage))
          .attr('y', d => yScale(getQuantile(d, 0.25)))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).remove())
  );

  // draw the top quartile lines
  const bottomQuartileLines = root.selectAll('.bottom-quartile-line').data(funds, d => d.fundId);

  bottomQuartileLines.join(
    enter =>
      enter
        .append('line')
        .attr('class', 'bottom-quartile-line')
        .attr('x1', d => xScale(d.vintage))
        .attr('x2', d => xScale(d.vintage))
        .attr('y1', d => yScale(getValue(d)))
        .attr('y2', d => yScale(getValue(d)))
        .attr('opacity', 0)
        .attr('stroke', '#999999')
        .call(e =>
          e
            .transition(t)
            .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
            .attr('x1', d => xScale(d.vintage) - QUARTILE_LINE_WIDTH / 2)
            .attr('x2', d => xScale(d.vintage) + QUARTILE_LINE_WIDTH / 2)
            .attr('y1', d => yScale(getQuantile(d, 0.25)))
            .attr('y2', d => yScale(getQuantile(d, 0.25)))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .attr('opacity', d => (showPeerPerformance(d) && !isPme ? 1 : 0))
          .attr('x1', d => xScale(d.vintage) - QUARTILE_LINE_WIDTH / 2)
          .attr('x2', d => xScale(d.vintage) + QUARTILE_LINE_WIDTH / 2)
          .attr('y1', d => yScale(getQuantile(d, 0.25)))
          .attr('y2', d => yScale(getQuantile(d, 0.25)))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).remove())
  );

  const points = root.selectAll('.point').data(funds, d => d.fundId);

  points.join(
    enter =>
      enter
        .append('circle')
        .attr('class', 'point')
        .attr('cx', d => xScale(d.vintage))
        .attr('cy', 0)
        .attr('r', 11)
        .attr('opacity', 0)
        .attr('cursor', 'pointer')
        .attr('fill', d => {
          if (!showPeerPerformance(d)) return NO_PERFORMANCE_COLOR;
          return getValue(d) >= getQuantile(d, 0.5) ? ABOVE_MEDIAN_COLOR : BELOW_MEDIAN_COLOR;
        })
        //.attr('stroke', d => (d.userId ? 'yellow' : null))
        //.attr('stroke-width', d => (d.userId ? '2px' : null))
        .on('mouseover', handleEvent(itemOver))
        .on('mouseout', handleEvent(itemOut))
        .on('click', handleEvent(itemClick))
        .on('touchstart', handleEvent(itemTouchStart))
        .on('touchend', handleEvent(itemTouchEnd))
        .call(e =>
          e
            .transition(t)
            .attr('opacity', !isPme ? 1 : 0)
            .attr('cy', d => yScale(getValue(d)))
        ),
    update =>
      update.call(u =>
        u
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd))
          .transition(t)
          .attr('fill', d => {
            if (!showPeerPerformance(d)) return NO_PERFORMANCE_COLOR;
            return getValue(d) >= getQuantile(d, 0.5) ? ABOVE_MEDIAN_COLOR : BELOW_MEDIAN_COLOR;
          })
          .attr('opacity', !isPme ? 1 : 0)
          .attr('cx', d => xScale(d.vintage))
          .attr('cy', d => yScale(getValue(d)))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('cy', 0).remove())
  );

  const rects = root.selectAll('.rect').data(funds, d => d.fundId);
  const rectWidth = calcs.key === 'daPmeValue' ? 50 : 40;
  const getRectPoints = ({ x = 0, y = 0, width = rectWidth, height = 22 }) => {
    return [
      [x, y],
      [x + width, y],
      [x + width, y + height],
      [x, y + height],
      [x - 10, y + height / 2],
    ]
      .map(loc => loc.join(','))
      .join(' ');
  };

  rects.join(
    enter =>
      enter
        .append('polygon')
        .attr('class', 'rect')
        .attr('points', d => {
          const x = xScale(d.vintage) - rectWidth / 2;
          const y = yScale(getValue(d)) - 11;
          return getRectPoints({ x, y });
        })
        .attr('opacity', 0)
        .attr('cursor', 'pointer')
        .attr('fill', d => {
          if (calcs.key === 'ksPmeValue') {
            return getValue(d) < 1 ? BELOW_MEDIAN_COLOR : ABOVE_MEDIAN_COLOR;
          }
          return getValue(d) < 0 ? BELOW_MEDIAN_COLOR : ABOVE_MEDIAN_COLOR;
        })
        .on('mouseover', handleEvent(itemOver))
        .on('mouseout', handleEvent(itemOut))
        .on('click', handleEvent(itemClick))
        .on('touchstart', handleEvent(itemTouchStart))
        .on('touchend', handleEvent(itemTouchEnd))
        .call(e =>
          e
            .transition(t)
            .attr('opacity', isPme ? 1 : 0)
            .attr('points', d => {
              const x = xScale(d.vintage) - rectWidth / 2;
              const y = yScale(getValue(d)) - 11;
              return getRectPoints({ x, y });
            })
            .attr('y', d => yScale(getValue(d)) - 11)
        ),
    update =>
      update.call(u =>
        u
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd))
          .transition(t)
          .attr('fill', d => {
            if (calcs.key === 'ksPmeValue') {
              return getValue(d) < 1 ? BELOW_MEDIAN_COLOR : ABOVE_MEDIAN_COLOR;
            }
            return getValue(d) < 0 ? BELOW_MEDIAN_COLOR : ABOVE_MEDIAN_COLOR;
          })
          .attr('opacity', isPme ? 1 : 0)
          .attr('points', d => {
            const x = xScale(d.vintage) - rectWidth / 2;
            const y = yScale(getValue(d)) - 11;
            return getRectPoints({ x, y });
          })
          .attr('x', d => xScale(d.vintage) - rectWidth / 2)
          .attr('y', d => yScale(getValue(d)) - 11)
      ),
    exit =>
      exit.call(ex =>
        ex
          .transition(t)
          .attr('opacity', 0)
          .attr('points', d => {
            const x = xScale(d.vintage) - rectWidth / 2;
            const y = yScale(getValue(d)) - 11;
            return getRectPoints({ x, y });
          })
          .remove()
      )
  );

  const quartileRankingLabels = root.selectAll('.quartile-ranking-label').data(funds, d => d.fundId);

  quartileRankingLabels.join(
    enter =>
      enter
        .append('text')
        .attr('class', 'quartile-ranking-label')
        .attr('dx', d => xScale(d.vintage))
        .attr('dy', 0)
        .attr('opacity', 0)
        .attr('font-size', '12px')
        .attr('fill', QUARTILE_RANKING_COLOR)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'central')
        .attr('pointer-events', 'none')
        .text(d => {
          if (isPme && calcs.key === 'ksPmeValue') return `${getValue(d).toFixed(2)}x`;
          if (isPme && calcs.key === 'daPmeValue') return `${(getValue(d) * 100).toFixed(2)}%`;
          if (d.quantileRank < 0.25) return 4;
          if (d.quantileRank < 0.5) return 3;
          if (d.quantileRank < 0.75) return 2;
          return 1;
        })
        .call(e =>
          e
            .transition(t)
            .attr('opacity', d => (showPeerPerformance(d) || isPme ? 1 : 0))
            .attr('dy', d => yScale(getValue(d)))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .text(d => {
            if (isPme && calcs.key === 'ksPmeValue') return `${getValue(d).toFixed(2)}x`;
            if (isPme && calcs.key === 'daPmeValue') return `${(getValue(d) * 100).toFixed(2)}%`;
            if (d.quantileRank < 0.25) return 4;
            if (d.quantileRank < 0.5) return 3;
            if (d.quantileRank < 0.75) return 2;
            return 1;
          })
          .attr('opacity', d => (showPeerPerformance(d) || isPme ? 1 : 0))
          .attr('dx', d => xScale(d.vintage))
          .attr('dy', d => yScale(getValue(d)))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('dy', 0).remove())
  );

  const userBenchmarkPoints = root.selectAll('.user-benchmark-point').data(
    funds.filter(f => f.isUserFundBenchmark),
    d => d.fundId
  );

  userBenchmarkPoints.join(
    enter =>
      enter
        .append('circle')
        .attr('class', 'user-benchmark-point')
        .attr('cx', d => xScale(d.vintage) + 12)
        .attr('cy', 0)
        .attr('dx', '10')
        .attr('dy', '10')
        .attr('r', 3)
        .attr('opacity', 0)
        .attr('fill', USER_BENCHMARK_COLOR)
        .on('mouseover', handleEvent(itemOver))
        .on('mouseout', handleEvent(itemOut))
        .on('click', handleEvent(itemClick))
        .on('touchstart', handleEvent(itemTouchStart))
        .on('touchend', handleEvent(itemTouchEnd))
        .call(e =>
          e
            .transition(t)
            .attr('opacity', isPme ? 0 : 1)
            .attr('cy', d => yScale(getValue(d)) - 12)
        ),
    update =>
      update.call(u =>
        u
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd))
          .transition(t)
          .attr('fill', USER_BENCHMARK_COLOR)
          .attr('opacity', isPme ? 0 : 1)
          .attr('cx', d => xScale(d.vintage) + 12)
          .attr('cy', d => yScale(getValue(d)) - 12)
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('cy', 0).remove())
  );

  const userFundpoint = root.selectAll('.user-fund-point').data(
    funds.filter(f => f.userId),
    d => d.fundId
  );

  userFundpoint.join(
    enter =>
      enter
        .append('circle')
        .attr('class', 'user-fund-point')
        .attr('cx', d => xScale(d.vintage) + 12)
        .attr('cy', 0)
        .attr('dx', '10')
        .attr('dy', '10')
        .attr('r', 3)
        .attr('opacity', 0)
        .attr('fill', USER_FUND_COLOR)
        .on('mouseover', handleEvent(itemOver))
        .on('mouseout', handleEvent(itemOut))
        .on('click', handleEvent(itemClick))
        .on('touchstart', handleEvent(itemTouchStart))
        .on('touchend', handleEvent(itemTouchEnd))
        .call(e =>
          e
            .transition(t)
            .attr('opacity', isPme ? 0 : 1)
            .attr('cy', d => yScale(getValue(d)) - 12)
        ),
    update =>
      update.call(u =>
        u
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd))
          .transition(t)
          .attr('fill', USER_FUND_COLOR)
          .attr('opacity', isPme ? 0 : 1)
          .attr('cx', d => xScale(d.vintage) + 12)
          .attr('cy', d => yScale(getValue(d)) - 12)
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('cy', 0).remove())
  );
}

export default draw;
