import React from 'react';
import { Button, Box, Typography } from '../..';

function ErrorPage() {
  return (
    <Box display="inline-flex" flexDirection="column" margin="20px 50px">
      <Typography variant="h4">An unexpected error has occured</Typography>
      <br />
      <Typography variant="body1">The error has been logged and sent to our support team</Typography>
      <Button
        color="primary"
        variant="contained"
        style={{ marginTop: '20px' }}
        onClick={() => window.location.replace('/')}
      >
        CLICK TO RELOAD
      </Button>
    </Box>
  );
}

export default ErrorPage;
