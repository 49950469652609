import { useMemo } from 'react';
import { useList as useListPortfolios } from 'src/services/filter-api/userPortfolios/queries';
import { useAddBookmark, useRemoveBookmark } from 'src/services/filter-api/userPortfolios/mutations';

function useFundBookmarkControls(fundId) {
  const { data: portfolios } = useListPortfolios({
    [`bookmarks.fundId_${fundId}.fundId`]: fundId,
  });

  const portfolioIds = useMemo(() => {
    if (!portfolios) return [];
    if (!fundId) return [];

    return portfolios.map(p => p.portfolioId);
  }, [fundId, portfolios]);

  const isBookmarked = portfolioIds.length > 0;
  const addBookmark = useAddBookmark().mutateAsync;
  const removeBookmark = useRemoveBookmark().mutateAsync;

  return {
    portfolioIds,
    isBookmarked,
    addBookmark,
    removeBookmark,
  };
}

export default useFundBookmarkControls;
