import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { currency, abbreviations } from 'src/formatters';
import { formatDateCompact } from 'src/utils/date';
import { useFundsTableColumnPresets } from 'src/hooks';
import { SubscriptionPaywall } from 'src/components';
import VirtualizedGridTable from '../VirtualizedGridTable';
import listDefs from './listDefs';

const INITIAL_TABLE_SORTS = [
  {
    id: 'name',
    desc: false,
  },
];

function FundsInMarketTable({ data }) {
  const { columns: defaultColumns, tableStateReducer, tableCss } = useFundsTableColumnPresets();

  const columns = useMemo(() => {
    return [
      ...defaultColumns.filter(column => {
        return ['name'].includes(column.id);
      }),
      {
        id: 'fundManagerName',
        Header: 'Fund Manager',
        accessor: 'fundManagerName',
        width: 'minmax(5em, 2fr)',
        minDisplayWidth: 0,
      },
      ...defaultColumns.filter(column => {
        return ['vintage', 'size'].includes(column.id);
      }),
      {
        id: 'targetSize',
        Header: 'Target',
        accessor: 'targetSize',
        Cell: ({ value }) => currency(abbreviations.million)()(value),
        width: '10em',
        minDisplayWidth: 0,
      },
      ...defaultColumns.filter(column => {
        return ['strategy', 'geography'].includes(column.id);
      }),
      {
        id: 'status',
        Header: 'Status',
        accessor: 'fundStatus',
        width: '10em',
        minDisplayWidth: 0,
      },
      {
        id: 'filingDate',
        Header: 'Filing Date',
        accessor: 'filingDate',
        Cell: ({ value }) => formatDateCompact(value),
        width: '10em',
        minDisplayWidth: 0,
      },
    ];
  }, [defaultColumns]);

  return (
    <SubscriptionPaywall>
      <VirtualizedGridTable
        columns={columns}
        data={data}
        initialSortBy={INITIAL_TABLE_SORTS}
        stateReducer={tableStateReducer}
        css={`
          .targetSize {
            text-align: right;
          }
          ${tableCss};
        `}
      />
    </SubscriptionPaywall>
  );
}

FundsInMarketTable.defaultProps = {
  expanded: false,
  enableHover: false,
  listDefNarrow: listDefs.narrow,
  listDefWide: listDefs.wide,
  rowColor: null,
  rowBackgroundColor: null,
  rowHoverColor: null,
  rowHoverBackgroundColor: null,
  onListDefCreated: () => {},
  onColumnClick: () => {},
  onColumnMouseOver: () => {},
  onColumnMouseOut: () => {},
  onRowClick: () => {},
  onScroll: () => {},
};

FundsInMarketTable.propTypes = {
  data: PropTypes.array.isRequired,
  expanded: PropTypes.bool,
  enableHover: PropTypes.bool,
  listDefNarrow: PropTypes.array,
  listDefWide: PropTypes.array,
  rowColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onListDefCreated: PropTypes.func,
  onColumnClick: PropTypes.func,
  onColumnMouseOver: PropTypes.func,
  onColumnMouseOut: PropTypes.func,
  onRowClick: PropTypes.func,
  onScroll: PropTypes.func,
};

export default FundsInMarketTable;
