import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import { isObject } from 'src/utils/object';
import { isArray } from 'src/utils/array';

const {
  REACT_APP_FIREBASE_API_KEY: apiKey,
  REACT_APP_FIREBASE_AUTH_DOMAIN: authDomain,
  REACT_APP_FIREBASE_DATABASE_URL: databaseURL,
  REACT_APP_FIREBASE_PROJECT_ID: projectId,
  REACT_APP_FIREBASE_STORAGE_BUCKET: storageBucket,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
  REACT_APP_FIREBASE_APP_ID: appId,
  REACT_APP_FIRESTORE_EMULATOR_HOST: firestoreEmulatorHost,
  REACT_APP_FUNCTIONS_EMULATOR_HOST: functionsEmulatorHost,
  REACT_APP_AUTH_EMULATOR_HOST: authEmulatorHost,
} = process.env;

firebase.initializeApp({
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
});

const functions = firebase.functions();

if (functionsEmulatorHost) {
  // firebase.functions().useEmulator(...functionsEmulatorHost.split(':'));
  /**
   * This is the only way I could get it to work over HTTPS
   * locally, but I'd be willing to bet that this has been
   * deprecated in favor of the line above.
   */
  functions.useFunctionsEmulator(functionsEmulatorHost);
}

function getFirestoreInstance() {
  // TO DEBUG FIRESTORE OPERATIONS
  // firebase.firestore.setLogLevel('debug');

  const db = firebase.firestore();
  if (!firestoreEmulatorHost) return db;
  const [host, port] = firestoreEmulatorHost.split(':');
  db.useEmulator(host, +port);
  return db;
}

function getAuthInstance() {
  const auth = firebase.auth();
  if (!authEmulatorHost) return auth;
  auth.useEmulator(authEmulatorHost);
  return auth;
}

function firestoreTimestamp(at = new Date()) {
  return firebase.firestore.Timestamp.fromDate(at);
}

function isFirestoreTimestamp(value) {
  return value instanceof firebase.firestore.Timestamp;
}

function serializeTimestamps(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (isFirestoreTimestamp(value)) {
      return { ...acc, [key]: value.toDate().toISOString() };
    }
    if (isArray(value)) {
      return { ...acc, [key]: value.map(serializeTimestamps) };
    }
    if (isObject(value)) {
      return { ...acc, [key]: serializeTimestamps(value) };
    }
    return { ...acc, [key]: value };
  }, {});
}

function applyQueryFilters(baseQuery, filters = {}) {
  return Object.entries(filters).reduce((query, [field, value]) => {
    if (['', null, undefined].includes(value)) return query;
    return query.where(field, '==', value);
  }, baseQuery);
}

const firestoreFieldValue = firebase.firestore.FieldValue;

export const auth = getAuthInstance();
export { functions };
export const persistence = firebase.auth.Auth.Persistence;
export const db = getFirestoreInstance();
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export { firestoreTimestamp };
export { firestoreFieldValue };
export { isFirestoreTimestamp };
export { serializeTimestamps };
export { applyQueryFilters };
export default firebase;
