// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { TooltipContent, TooltipHeader, TooltipTitle, TooltipArrowLeft } from '../..';

// eslint-disable-next-line no-unused-vars
function VisibleOnlyToYouTooltip({ open, anchorEl, onClickAway, label }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="right"
        modifiers={{
          offset: {
            enabled: true,
            offset: '-4,15',
          },
          arrow: {
            enabled: true,
            element: '.user-fund-tooltip-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <div>
              <TooltipArrowLeft
                className="user-fund-tooltip-arrow"
                color="#ffd857"
                css={`
                  margin-top: -4px;
                `}
              />
              <TooltipContent
                css={`
                  background-color: #ffd857;
                  padding: 16px 20px 20px 20px;
                `}
              >
                <TooltipHeader>
                  <TooltipTitle
                    css={`
                      color: #000;
                      font-weight: 600;
                      font-size: 14px;
                    `}
                  >
                    Visible only to you
                  </TooltipTitle>
                </TooltipHeader>
                <div
                  css={`
                    color: #4c3f04;
                    max-width: 165px;
                    font-size: 11px;
                    line-height: 1.45;
                  `}
                >
                  This {label} was added by you and is only visible to you.
                </div>
              </TooltipContent>
            </div>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}

VisibleOnlyToYouTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  label: 'fund',
};

VisibleOnlyToYouTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  label: PropTypes.string,
  onClickAway: PropTypes.func,
};

export default VisibleOnlyToYouTooltip;
