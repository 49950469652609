import React from 'react';
import { useState, useCallback } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useAllFunds } from 'src/hooks';

const filter = createFilterOptions({
  limit: 5,
  matchFrom: 'start',
  trim: true,
});

function Input(props) {
  return <TextField {...props} />;
}

Input.defaultProps = {
  label: 'Fund Name',
  InputLabelProps: { shrink: true },
  placeholder: 'Search Funds',
  variant: 'standard',
};

function FundNameField({ label, selectedFundId, onChangeSelectedFund }) {
  const allFunds = useAllFunds() || [];
  const selectedFund = allFunds.find(fund => fund.fundId === selectedFundId);
  const [value, setValue] = useState(selectedFund || { name: '' });

  const handleChange = useCallback(
    (_event, newValue, reason) => {
      if (reason === 'clear') {
        onChangeSelectedFund(null);
        return setValue({ name: '' });
      }

      if (!newValue) return;

      const fund = newValue.fundId ? newValue : { name: newValue.inputValue };

      setValue(fund);
      onChangeSelectedFund(fund);
    },
    [onChangeSelectedFund]
  );

  const getFilterOptions = useCallback((options, params) => {
    const filtered = filter(options, params);
    const normalizedFundNames = filtered.map(fund => fund.name.toLowerCase().trim());
    const normalizedSearchQuery = params.inputValue.toLowerCase().trim();
    const hasExactMatch = normalizedFundNames.includes(normalizedSearchQuery);

    if (!hasExactMatch && params.inputValue.trim()) {
      filtered.push({
        inputValue: params.inputValue,
        name: `Nothing found matching “${params.inputValue}”`,
      });
    }

    return filtered;
  }, []);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      filterOptions={getFilterOptions}
      selectOnFocus
      handleHomeEndKeys
      options={allFunds}
      getOptionLabel={option => option?.name || ''}
      freeSolo
      autoHighlight
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          label={label}
          InputLabelProps={{ shrink: true }}
          placeholder="Search Funds"
          variant="standard"
        />
      )}
    />
  );
}

FundNameField.defaultProps = {
  onChangeSearchInput: funcStub('onChangeSearchInput'),
  onBlurSearchInput: funcStub('onBlurSearchInput'),
  onClickFundName: funcStub('onClickFundName'),
  onChange: funcStub('onChange'),
  onChangeSelectedFund: funcStub('onChangeSelectedFund'),
};

FundNameField.propTypes = {
  onChange: PropTypes.func,
  onChangeSearchInput: PropTypes.func,
  onBlurSearchInput: PropTypes.func,
  onClickFundName: PropTypes.func,
  onChangeSelectedFund: PropTypes.func,
};

export default FundNameField;
