import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconCoinStack(props) {
  return (
    <Svg viewBox="0 0 100 125" {...props}>
      <g>
        <path d="M54.937,64.062v7.639c0,2.596,7.989,6.522,20.031,6.522S95,74.297,95,71.701v-7.639c-4.724,2.885-12.406,4.37-20.032,4.37   C67.343,68.432,59.661,66.947,54.937,64.062z" />
        <path d="M54.937,49.155v8.097c0,2.596,7.989,6.521,20.031,6.521S95,59.848,95,57.251v-8.097c-4.724,2.885-12.406,4.37-20.032,4.37   C67.343,53.525,59.661,52.04,54.937,49.155z" />
        <path d="M74.968,35.823c-12.042,0-20.031,3.925-20.031,6.521c0,2.596,7.989,6.522,20.031,6.522S95,44.941,95,42.344   C95,39.748,87.011,35.823,74.968,35.823z" />
        <path d="M27.36,78.627c12.042,0,20.033-3.925,20.033-6.521h2.328v0h-2.328v-7.639c-4.724,2.885-12.407,4.37-20.033,4.37   c-7.626,0-15.308-1.485-20.031-4.37v7.639H5v0h2.329C7.329,74.702,15.318,78.627,27.36,78.627z" />
        <path d="M54.937,78.512v7.639v0c0,2.596,7.989,6.521,20.031,6.521S95,88.747,95,86.151v0v-7.639   c-4.724,2.885-12.406,4.37-20.032,4.37C67.343,82.882,59.661,81.397,54.937,78.512z" />
        <path d="M27.36,20.371c12.042,0,20.033-3.925,20.033-6.521c0-2.596-7.99-6.522-20.033-6.522c-12.042,0-20.031,3.926-20.031,6.522   C7.329,16.446,15.318,20.371,27.36,20.371z" />
        <path d="M47.393,86.151L47.393,86.151v-7.235c-4.724,2.885-12.407,4.37-20.033,4.37c-7.626,0-15.308-1.485-20.031-4.37v7.235v0   c0,2.596,7.989,6.521,20.031,6.521C39.402,92.672,47.393,88.747,47.393,86.151z" />
        <path d="M27.36,35.278c12.042,0,20.033-3.926,20.033-6.522v-7.919V20.66c-0.099,0.06-0.202,0.118-0.303,0.177   c-4.745,2.768-12.263,4.193-19.729,4.193c-7.467,0-14.984-1.425-19.729-4.193C7.53,20.778,7.427,20.72,7.329,20.66v0.177v7.919   C7.329,31.353,15.318,35.278,27.36,35.278z" />
        <path d="M27.36,49.728c12.042,0,20.033-3.925,20.033-6.521v-7.64c-4.724,2.886-12.407,4.37-20.033,4.37   c-7.626,0-15.308-1.485-20.031-4.37v7.639C7.329,45.803,15.318,49.728,27.36,49.728z" />
        <path d="M27.36,64.178c12.042,0,20.033-3.926,20.033-6.522v-7.639c-4.724,2.885-12.407,4.37-20.033,4.37   c-7.626,0-15.308-1.485-20.031-4.37v7.639C7.329,60.252,15.318,64.178,27.36,64.178z" />
      </g>
    </Svg>
  );
}
