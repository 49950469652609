import { useQuery } from 'react-query';
import queryKeyFactory from '../queryKeyFactory';
import * as client from './client';
import { UnauthorizedError } from '../errors';

const queryKeys = queryKeyFactory(client.collection.id);

const defaultOptions = {
  enabled: true,
  placeholderData: [],
  refetchOnWindowFocus: false,
  staleTime: Number.MAX_SAFE_INTEGER,
};

function useList(filters = {}, options = {}) {
  const queryKey = queryKeys.list(filters);
  const queryFn = async () => {
    return await client.list(filters);
  };

  const query = useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
    retry(_count, error) {
      if (error instanceof UnauthorizedError) return false;
      return true;
    },
  });

  return query;
}

function useListBookmarks(filters = {}, options = {}) {
  const queryKey = queryKeys.list('bookmarks', filters);
  const queryFn = async () => {
    const portfolios = await client.list(filters);
    return portfolios.map(portfolio => portfolio.bookmarks);
  };

  const query = useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
    retry(_count, error) {
      if (error instanceof UnauthorizedError) return false;
      return true;
    },
  });

  return query;
}

function useGet(portfolioId, { enabled: optEnabled, ...options } = {}) {
  const enabled = optEnabled === undefined ? Boolean(portfolioId) : optEnabled;
  const queryKey = queryKeys.detail(portfolioId);
  const queryFn = async () => {
    try {
      const data = await client.get(portfolioId);
      return data;
    } catch (error) {
      console.warn('queries.useGet error thrown', error);
      return null;
    }
  };
  return useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
    enabled,
  });
}

function useGetDefault(options = {}) {
  const queryKey = queryKeys.list({ default: true });
  const queryFn = async () => {
    try {
      const results = await client.list({ default: true });
      return results ? results[0] : [];
    } catch (error) {
      console.warn('queries.useGetDefault error thrown', error);
      return null;
    }
  };

  const query = useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    initialData: {},
    ...options,
  });

  return query;
}

export { queryKeys, useList, useListBookmarks, useGet, useGetDefault };
