import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useCurrentLabel from './use-current-label';

const StyledButton = styled(Button)`
  min-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 4px;

  color: currentColor;
  font-size: inherit;
  font-weight: inherit;
  vertical-align: baseline;

  .MuiButton-endIcon {
    margin-left: 0;
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 100;
`;

function TableHeadCellSelect({ value, options, onChange, startAdornment, endAdornment }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const label = useCurrentLabel(value, options);

  const handleToggle = () => {
    setOpen(prev => !prev);
  };

  const handleClick = evt => {
    if (anchorRef.current && anchorRef.current.contains(evt.target)) {
      return;
    }
    if (evt.target.dataset.value) {
      onChange(evt.target.dataset.value);
    }
    setOpen(false);
  };

  if (!Array.isArray(options) || options.length === 0) {
    return null;
  }

  return (
    <>
      <StyledButton ref={anchorRef} aria-haspopup="true" onClick={handleToggle} endIcon={<ExpandMoreIcon />}>
        {startAdornment}
        {label}
        {endAdornment}
      </StyledButton>
      <StyledPopper open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClick}>
                <MenuList autoFocusItem={open}>
                  {options.map((option, i) => (
                    <MenuItem key={i} onClick={handleClick} data-value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
}

TableHeadCellSelect.propTypes = {
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default TableHeadCellSelect;
