import styled from 'styled-components';

export default styled.span`
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${props => props.color || props.theme.palette.grey[200]};
  right: -10px;
`;
