import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import { draw } from './draw';

function FundManagerTeamHierarchy({
  teamHierarchy,
  expanded,
  width,
  height,
  targetRef,
  onClick,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  ...rest
}) {
  const id = useRef(uniqueId());

  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  const margin = { top: 20, right: 20, bottom: 20, left: 20 };

  useEffect(() => {
    draw({
      id: id.current,
      teamHierarchy,
      width,
      height,
      margin,
      expanded,
      mouseEventRefs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamHierarchy, expanded, width, height]);

  return (
    <div ref={targetRef} {...rest}>
      <svg
        id={id.current}
        onClick={onClick}
        css={`
          display: block;
          width: 100%;
          height: 100%;
          font-family: Roboto, 'Helvetica Neue', sans-serif;
        `}
      />
    </div>
  );
}

FundManagerTeamHierarchy.defaultProps = {
  expanded: false,
  width: 0,
  height: 0,
  targetRef: null,
  onClick: () => {},
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

FundManagerTeamHierarchy.propTypes = {
  teamHierarchy: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default withResizeDetector(FundManagerTeamHierarchy);
