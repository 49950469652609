export const sizes = [
  {
    label: '> $5.0 Billion',
    sizeId: 4,
    parentId: null,
    from: 5000000000,
    to: Infinity,
  },
  {
    label: '$1.5Bn - $5Bn',
    sizeId: 3,
    parentId: null,
    from: 1500000000,
    to: 5000000000,
  },
  {
    label: '$0.5Bn - $1.5Bn',
    sizeId: 2,
    parentId: null,
    from: 500000000,
    to: 1500000000,
  },
  {
    label: '< $0.5Bn',
    sizeId: 1,
    parentId: null,
    from: 0,
    to: 500000000,
  },
];

export function getSizeId(fundSize) {
  const size = sizes.find(s => fundSize >= s.from && fundSize < s.to);
  return size ? size.sizeId : null;
}
