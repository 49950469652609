import { useState, useEffect } from 'react';
import { createHierarchy } from '../utils';
import useVintages from './useVintages';

const createVintageHierarchy = createHierarchy('vintageId', 'parentId');

function useVintageHierarchy() {
  const [vintageHierarchy, setVintageHierarchy] = useState(null);
  const vintages = useVintages();

  useEffect(() => {
    if (!vintages) return;

    setVintageHierarchy(createVintageHierarchy(vintages));
  }, [vintages]);

  return vintageHierarchy;
}

export default useVintageHierarchy;
