import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Box from '../Box';

const StyledButton = styled(Button)`
  width: 45px;
  height: 53px;
  min-width: unset;
  min-height: unset;
  border-radius: 4px;
  border: solid 1px #3d3d3d;
  font-size: 11px;
  font-weight: 300;
  color: #a6a6a6;
  line-height: 1;
  padding: 0;
`;

// eslint-disable-next-line react/prop-types
const ButtonLarge = ({ children, ...props }) => {
  return (
    <StyledButton {...props}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {children}
      </Box>
    </StyledButton>
  );
};

ButtonLarge.defaultProps = {};

ButtonLarge.propTypes = {};

export default ButtonLarge;
