import React from 'react';
import PropTypes from 'prop-types';
import { useThumbnail } from './hooks';
import { Placeholder, Thumbnail } from './styled';

function VimeoThumbnail({ videoId }) {
  const { ref, isLoading, isFetching, data } = useThumbnail(videoId);

  if (isLoading || isFetching || !data?.url) {
    return <Placeholder />;
  }
  return <Thumbnail ref={ref} src={data.url} height={data.height} />;
}

VimeoThumbnail.propTypes = {
  videoId: PropTypes.number.isRequired,
};

export default VimeoThumbnail;
