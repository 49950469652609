import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useExportCsvCallback, useCurrentUser } from 'src/hooks';
import { IconDownload } from 'src/components';
import { lg } from 'src/config/layout';

const CSV_DISCLAIMER = `"Information displayed on this page is based on models and/or tools which use publicly available historical information to predict future outcomes or results. These models and/or tools are intended for informational use only, and you acknowledge and agree that FundFilter (or its owner Altman Inc) cannot and does not guarantee any particular outcomes or results, and that past or historical results are not indicative of and do not guarantee future performance, outcomes or results. Always obtain relevant and specific independent professional advice before making any investment or other decision."`;

const DownloadButton = styled.button`
  display: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 1em;
  color: inherit;
  border: none;

  svg {
    font-size: 1.25em;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    display: initial;
  }
`;

function DownloadCsvButton({ getData, filename }) {
  const currentUser = useCurrentUser();
  const handleDownloadCsv = useExportCsvCallback({
    filename,
    title: [
      ['FundFilter', CSV_DISCLAIMER],
      [`USER: ${currentUser.name}`, `DATE: ${new Date().toISOString()}`],
    ],
  });

  return (
    <DownloadButton onClick={() => handleDownloadCsv(getData())}>
      <IconDownload />
    </DownloadButton>
  );
}

DownloadCsvButton.defaultProps = {};

DownloadCsvButton.propTypes = {
  filename: PropTypes.string,
  data: PropTypes.array,
};

export default DownloadCsvButton;
