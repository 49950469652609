import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconImageDownload(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <g>
        <path d="M54.5,43.3l-4.2,4.1c-0.7,0.7-1.8,0.6-2.4-0.2L37.5,33.3c-0.7-0.9-2-0.8-2.6,0.1L18,60.3c-0.7,1.1,0.1,2.5,1.4,2.5H58     c1.4-3.5,3.6-6.5,6.3-8.8l-7.4-10.4C56.3,42.7,55.2,42.6,54.5,43.3z" />
        <circle cx="59.3" cy="30.6" r="5.9" />
        <path d="M84.6,54V13.1c0-1.4-1.1-2.6-2.6-2.6H5.1c-1.4,0-2.6,1.1-2.6,2.6v60.7c0,1.4,1.1,2.6,2.6,2.6h57c2.2,7.5,9.1,13,17.3,13     c10,0,18.1-8.1,18.1-18.1C97.5,63.2,92,56.2,84.6,54z M61.4,71.3H7.6V15.7h71.8v37.6c0,0,0,0,0,0C69.5,53.3,61.4,61.4,61.4,71.3z      M85.5,74.9l-4.4,5.9c-0.4,0.5-1,0.8-1.7,0.8h0c-0.7,0-1.3-0.3-1.7-0.8l-4.4-5.9c-0.5-0.6-0.6-1.5-0.2-2.2     c0.4-0.7,1.1-1.2,1.9-1.2h2.1v-8.1c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3v8.1h2.1c0.8,0,1.5,0.4,1.9,1.2     C86.1,73.4,86,74.3,85.5,74.9z" />
      </g>
    </Svg>
  );
}
