import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from './Card';

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  padding: 20px;

  background-image: url('/assets/img/app.webp');
  background-size: cover;

  display: grid;
  align-items: center;
`;

function AuthLayout({ children, className }) {
  return <Layout className={className}>{children}</Layout>;
}

AuthLayout.Card = Card;

AuthLayout.defaultProps = {
  $isFlipped: false,
};

AuthLayout.propTypes = {
  children: PropTypes.any,
  isFlipped: PropTypes.bool,
};

export default AuthLayout;
