import { useMemo } from 'react';

function useCurrentLabel(currentValue, options) {
  return useMemo(
    () => options.find(o => o.value === currentValue)?.label || options[0]?.label,
    [currentValue, options]
  );
}

export default useCurrentLabel;
