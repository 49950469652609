import { functions } from 'src/config/firebase';

async function stripeCreateCheckoutSession() {
  const res = await functions.httpsCallable('stripeCreateCheckoutSession')();
  return res.data;
}

async function stripeCreateCustomerPortalSession() {
  const res = await functions.httpsCallable('stripeCreateCustomerPortalSession')();
  return res.data;
}

async function stripeGetCustomer() {
  const res = await functions.httpsCallable('stripeGetCustomer')();
  return res.data;
}

async function stripeGetCustomerSubscriptions() {
  const res = await functions.httpsCallable('stripeGetCustomerSubscriptions')();
  return res.data;
}

async function stripeListPlans() {
  const res = await functions.httpsCallable('stripeListPlans')();
  return res.data;
}

async function stripeCreateSetupIntent({ paymentMethodId, customerId }) {
  const res = await functions.httpsCallable('stripeCreateSetupIntent')({
    paymentMethodId,
    customerId,
  });
  return res;
}

async function stripeConfirmSetupIntent({ paymentMethodId, setupIntentId }) {
  const res = await functions.httpsCallable('stripeConfirmSetupIntent')({
    paymentMethodId,
    setupIntentId,
  });
  return res;
}

async function stripeAttachPaymentMethod({ paymentMethodId, customerId }) {
  const res = await functions.httpsCallable('stripeAttachPaymentMethod')({
    paymentMethodId,
    customerId,
  });
  return res;
}

async function stripeDetachPaymentMethod({ paymentMethodId }) {
  const res = await functions.httpsCallable('stripeDetachPaymentMethod')({
    paymentMethodId,
  });
  return res;
}

async function stripeGetInvoice({ invoiceId }) {
  const res = await functions.httpsCallable('stripeGetInvoice')({
    invoiceId,
  });
  return res;
}

async function stripeCreateSubscription({ customerId, priceId, trialEnd }) {
  try {
    const res = await functions.httpsCallable('stripeCreateSubscription')({
      customerId,
      priceId,
      trialEnd,
    });
    return res;
  } catch (error) {
    console.error(error);
  }
}

async function stripeCancelSubscription({ subscriptionId }) {
  const res = await functions.httpsCallable('stripeCancelSubscription')({ subscriptionId });
  return res;
}

async function stripeListPaymentMethods() {
  const res = await functions.httpsCallable('stripeListPaymentMethods')();

  return res.data;
}

export { stripeCreateCheckoutSession };
export { stripeCreateCustomerPortalSession };
export { stripeGetCustomer };
export { stripeListPlans };
export { stripeAttachPaymentMethod };
export { stripeDetachPaymentMethod };
export { stripeGetInvoice };
export { stripeCreateSubscription };
export { stripeCancelSubscription };
export { stripeListPaymentMethods };
export { stripeGetCustomerSubscriptions };
export { stripeCreateSetupIntent };
export { stripeConfirmSetupIntent };
