import React from 'react';
import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  AppPanel,
  ButtonInfo,
  IconPerformance,
  IconCurveHistory,
  CurveHistoryTooltip,
  StrategyPerformanceVisualization,
} from 'src/components';

const VIEWS = {
  performance: 'performance',
  curveHistory: 'curveHistory',
};

const StyledButtonInfo = styled(ButtonInfo)`
  margin-left: 6px;
  opacity: ${p => (p.$active ? 1 : 0)};
  pointer-events: ${p => (p.$active ? 'all' : 'none')};
`;

function MarketFilterStrategyPerformancePanel({ className, spanX, spanY, minHeight }) {
  const [view, setView] = useState(VIEWS.performance);
  const [infoTooltipAnchorEl, setInfoTooltipAnchorEl] = useState();

  const handlePerformanceClick = useCallback(() => {
    setView(VIEWS.performance);
    setInfoTooltipAnchorEl(null);
  }, [setView]);

  const handleCurveHistoryClick = useCallback(() => {
    setView(VIEWS.curveHistory);
    setInfoTooltipAnchorEl(null);
  }, [setView]);

  const handleInfoButtonClick = useCallback(
    evt => {
      setInfoTooltipAnchorEl(evt?.currentTarget);
    },
    [setInfoTooltipAnchorEl]
  );

  const handleInfoButtonClose = useCallback(() => {
    setInfoTooltipAnchorEl(null);
  }, [setInfoTooltipAnchorEl]);

  const handleExpandStatusChange = useCallback(
    expanded => {
      if (!expanded) setView(VIEWS.performance);
      setInfoTooltipAnchorEl(null);
    },
    [setView]
  );

  return (
    <AppPanel
      className={className}
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title="Strategy Performance"
      controls={
        <div>
          <AppPanel.Button
            aria-label="performance"
            selected={view === VIEWS.performance}
            onClick={handlePerformanceClick}
            startIcon={<IconPerformance />}
          >
            Performance
          </AppPanel.Button>
          <AppPanel.Button
            aria-label="curve-history"
            selected={view === VIEWS.curveHistory}
            onClick={handleCurveHistoryClick}
            startIcon={<IconCurveHistory />}
          >
            Track Record
          </AppPanel.Button>
          <StyledButtonInfo onClick={handleInfoButtonClick} $active={view === VIEWS.curveHistory} />
        </div>
      }
      expandable
      onChangeExpanded={handleExpandStatusChange}
    >
      <CurveHistoryTooltip
        open={Boolean(infoTooltipAnchorEl)}
        anchorEl={infoTooltipAnchorEl}
        onClose={handleInfoButtonClose}
      />
      <StrategyPerformanceVisualization
        showPerformanceView={view === VIEWS.performance}
        showCurveHistoryView={view === VIEWS.curveHistory}
      />
    </AppPanel>
  );
}

MarketFilterStrategyPerformancePanel.defaultProps = {};

MarketFilterStrategyPerformancePanel.propTypes = {
  children: PropTypes.any,
};

export default MarketFilterStrategyPerformancePanel;
