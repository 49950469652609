import { db } from 'src/config/firebase';
import { localDb } from 'src/config/dexie';

const dbFundList = db.collection('fundList');
const localDbFundList = localDb.funds;

async function getFundList() {
  const localData = await localDbFundList.toArray();
  if (localData.length > 0) return localData;

  const snapshot = await dbFundList.get();
  const data = snapshot.docs.map(doc => doc.data()).flatMap(d => d.funds);

  localDbFundList.bulkPut(data);

  return data;
}

export { getFundList };
