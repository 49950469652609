import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, FundManagerLocations } from 'src/components';
import { sm } from 'src/config/layout';
import ManagerMetaData from './ManagerMetaData';
import UserFundManagerDetail from './UserFundManagerDetail';

const Layout = styled.div`
  display: grid;
  gap: 10px;

  @media (min-width: ${sm.BREAKPOINT}px) {
    grid-template-columns: 1fr auto;
    grid-template-rows: max-content;
  }
`;

const TitleSection = styled.div`
  @media (min-width: ${sm.BREAKPOINT}px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const DescriptionSection = styled.div`
  @media (min-width: ${sm.BREAKPOINT}px) {
    grid-column-start: 1;
    grid-column-end: 2;
  }
`;

const Title = styled(Typography).attrs({ variant: 'h5' })`
  font-size: 1.428571429em;
  font-weight: 500;
`;

const Description = styled.div`
  font-size: 0.785714286em;
  padding-right: 0.5em;
  max-height: 7.272727273em;
  line-height: 1.454545455;
  color: ${p => (p.$bold ? '#fff' : '#939393')};
  font-weight: 300;
  font-style: ${p => (p.$bold ? 'italic' : 'normal')};
  overflow-y: ${p => (p.$scrollable ? 'auto' : 'hidden')};
`;

const MapSection = styled.div`
  @media (min-width: ${sm.BREAKPOINT}px) {
    grid-column-start: 2;
    grid-column-end: 3;

    grid-row-start: 2;
    grid-row-end: 4;
  }
`;

const MetadataSection = styled.div`
  @media (min-width: ${sm.BREAKPOINT}px) {
    /* grid-column: span 2; */
  }
`;

function FundManagerDetail({
  fundManager,
  fundManagerLocation,
  fundManagerFunds,
  strategies,
  expanded,
  dataGeo,
  handleLocationClick,
  userManagerAnchorEl,
  handleUserManagerMarkerClick,
  setVisibleToYouTooltipOpen,
  imageCaptureInProgress,
}) {
  if (fundManager.userId) {
    return (
      <UserFundManagerDetail
        fundManager={fundManager}
        userManagerAnchorEl={userManagerAnchorEl}
        handleUserManagerMarkerClick={handleUserManagerMarkerClick}
        setVisibleToYouTooltipOpen={setVisibleToYouTooltipOpen}
      />
    );
  }

  const inactiveManager =
    fundManager.description &&
    fundManager.description.toUpperCase().startsWith('THIS FUND MANAGER IS NO LONGER ACTIVE');

  return (
    <Layout>
      <TitleSection>
        <Title>{fundManager.name}</Title>
      </TitleSection>

      <DescriptionSection>
        <Description $scrollable={!imageCaptureInProgress} $bold={inactiveManager}>
          {inactiveManager
            ? `This fund manager is no longer active and has limited meta data available`
            : fundManager.description}
        </Description>
      </DescriptionSection>

      <MapSection>
        <FundManagerLocations
          dataGeo={dataGeo}
          dataPoints={fundManagerLocation.addressMeta || []}
          onItemClick={handleLocationClick}
        />
      </MapSection>

      <MetadataSection>
        <ManagerMetaData
          location={fundManagerLocation}
          fundManager={fundManager}
          strategies={strategies}
          fundCount={fundManagerFunds.length}
          expanded={expanded}
        />
      </MetadataSection>
    </Layout>
  );
}

FundManagerDetail.defaultProps = {
  fundManagerFunds: [],
  fundManager: {},
  fundManagerLocation: {},
  strategies: [],
  dataGeo: {},
  handleLocationClick: () => null,
};

FundManagerDetail.propTypes = {
  fundManager: PropTypes.object.isRequired,
  fundManagerFunds: PropTypes.array.isRequired,
  fundManagerLocation: PropTypes.object.isRequired,
  strategies: PropTypes.array.isRequired,
  dataGeo: PropTypes.object.isRequired,
  handleLocationClick: PropTypes.func.isRequired,
};

export default FundManagerDetail;
