import * as d3 from 'd3';
import getCanvas from './getCanvas';
import getXAxis from './getXAxis';
import getYAxis from './getYAxis';
import drawBars from './drawBars';
import drawCurves from './drawCurves';
import { MILESTONE_ACCENT_COLOR, FAILED_BREAKEVEN_ACCENT_COLOR } from './constants';
import { addMonths, getCurrentQuarter, formatDurationDecimalYear } from 'src/utils/date';

function findRisingTail(data, { TARGET_VALUE, ACCESSOR }) {
  const result = [];

  for (const d of data.slice().reverse()) {
    if (d[ACCESSOR] < TARGET_VALUE) {
      result.push(d);
      break;
    }

    result.push(d);
  }

  return result.reverse();
}

function getBreakeven(data, startDate) {
  const TARGET_VALUE = 0;
  const ACCESSOR = 'ncf';
  const [start, end] = findRisingTail(data, { TARGET_VALUE, ACCESSOR });

  if (!start) return;
  if (!end) return;
  if (!Number.isFinite(start[ACCESSOR])) return;
  if (!Number.isFinite(end[ACCESSOR])) return;
  if (start[ACCESSOR] > TARGET_VALUE) return;
  if (end[ACCESSOR] < TARGET_VALUE) return;

  const scale = d3.scaleLinear().domain([start[ACCESSOR], end[ACCESSOR]]).range([start.q, end.q]);

  const q = scale(TARGET_VALUE);
  const date = addMonths(q * 3)(startDate);

  return { q, date, [ACCESSOR]: TARGET_VALUE };
}

function getNavMarker(data, navTarget, startDate) {
  if (Number.isFinite(navTarget)) {
    return { q: 0, date: startDate, navMarker: navTarget };
  }

  const navMax = d3.max(data, d => d.nav);
  const navPeakPoint = data.find(d => d.nav === navMax);

  if (!navPeakPoint) return;

  const { q, nav: navMarker } = navPeakPoint;
  const date = addMonths(q * 3)(startDate);
  return { q, date, navMarker };
}

function sumIf(group, getter) {
  return group.some(d => Number.isFinite(getter(d))) ? d3.sum(group, getter) : undefined;
}

function getUnavailPlaceholder(data) {
  const relevantItems = data.filter(d => {
    if (!d.placeholder) return false;
    if (!d.unavailable) return false;
    return Number.isFinite(d.forecastCurve ?? d.forecastBarFall ?? d.forecastBarRise);
  });

  const item = [...relevantItems].pop();
  if (!item) return [];

  return [item.forecastCurve ?? item.forecastBarFall ?? item.forecastBarRise];
}

function getAggredateData({ data, startDate, navTarget }) {
  const groups = [...d3.group(data, d => d.q).values()];

  return groups
    .map(group => {
      return {
        ...group[0],
        date: addMonths(group[0].q * 3)(startDate),
        highlight: group.some(d => d.highlight),
        subjectCurve: sumIf(group, d => d.subjectCurve),
        subjectBarRise: sumIf(group, d => d.subjectBarRise),
        subjectBarFall: sumIf(group, d => d.subjectBarFall),
        forecastCurve: sumIf(group, d => d.forecastCurve),
        forecastBarRise: sumIf(group, d => d.forecastBarRise),
        forecastBarFall: sumIf(group, d => d.forecastBarFall),
        ncf: sumIf(group, d => d.ncf),
        nav: sumIf(group, d => d.nav),
        navTarget: Number.isFinite(sumIf(group, d => d.nav)) && navTarget,
        dpi: d3.mean(group, d => d.dpi),
        calledPct: d3.mean(group, d => d.calledPct),
        rvpi: d3.mean(group, d => d.rvpi),
        tvpi: d3.mean(group, d => d.tvpi),
        commitmentAmount: sumIf(group, d => d.commitmentAmount),
      };
    })
    .map((d, i, group) => {
      /* join the subject curve to the forecast curve */
      const subjectEndsNow = Number.isFinite(d.subjectCurve) && !Number.isFinite(group[i + 1]?.subjectCurve);

      const forecastStartsNext = !Number.isFinite(d.forecastCurve) && Number.isFinite(group[i + 1]?.forecastCurve);

      if (subjectEndsNow && forecastStartsNext) {
        return {
          ...d,
          forecastCurve: d.subjectCurve,
          highlight: true,
        };
      }

      return d;
    })
    .sort((a, b) => a.q - b.q);
}

function draw({
  data,
  colors,
  navTarget,
  yAxisFormatter,
  xAxisFormatter,
  xAxisTicks,
  normalizeYAxisData,
  showYAxisLabels,
  startDate = getCurrentQuarter(),
}) {
  return function ({ svg, width, height: _height, margin }) {
    if (!data) return;
    if (data.length < 1) return;

    const canvas = getCanvas({ svg, margin });
    const animate = svg.transition().delay(100).duration(550);
    const aggregateData = getAggredateData({
      data,
      startDate,
      navTarget,
    });

    const breakevenData = [getBreakeven(aggregateData, startDate)].filter(Boolean);
    const navMarkerData = [getNavMarker(aggregateData, navTarget, startDate)].filter(Boolean);
    const axisData = [...aggregateData, ...navMarkerData].filter(Boolean);
    const yAxisWidth = showYAxisLabels ? 16 * 0.8 * 2.5 : 0;
    const { xAxisSmallTicks, xAxisLargeTicks, xAxisLabels, xScale } = getXAxis({
      data: axisData,
      formatter: xAxisFormatter,
      ticks: xAxisTicks,
      range: [canvas.left + yAxisWidth + 8, canvas.right],
    });
    const { yAxis, yScale } = getYAxis({
      data: axisData,
      formatter: yAxisFormatter,
      range: [canvas.bottom, canvas.top + 17],
      normalizeYAxisData,
      width: canvas.width,
      height: canvas.height,
    });

    const unavailPlaceholderData = getUnavailPlaceholder(aggregateData);
    const isUnavailable = unavailPlaceholderData.some(Number.isFinite);
    const isFallBelowBreakeven =
      !aggregateData.map(d => d.subjectBarFall ?? d.forecastBarFall ?? d.nav).some(Boolean) &&
      breakevenData.length === 0;

    svg.style('font-size', `0.8em`);

    svg
      .selectAll('.x-axis')
      .data([xScale.ticks()])
      .join('g')
      .attr('class', 'x-axis')
      .style('transform', `translateY(${canvas.bottom}px)`)
      .call(g => {
        g.selectAll('.xAxisLabels')
          .data([xScale.ticks()])
          .join('g')
          .attr('class', 'xAxisLabels')
          .style('font-size', '1em')
          .call(xAxisLabels);

        g.selectAll('.xAxisLargeTicks')
          .data([xScale.ticks()])
          .join('g')
          .attr('class', 'xAxisLargeTicks')
          .call(xAxisLargeTicks);

        g.selectAll('.xAxisSmallTicks')
          .data([xScale.ticks()])
          .join('g')
          .attr('class', 'xAxisSmallTicks')
          .call(xAxisSmallTicks);
      });

    svg
      .selectAll('.y-axis')
      .data([yScale.ticks()])
      .join('g')
      .attr('class', 'y-axis')
      .style('font-size', '1em')
      .style('transform', `translateX(${canvas.left}px)`)

      .call(yAxis)
      .call(g => g.selectAll('text').style('text-anchor', 'end').attr('x', yAxisWidth));

    const root = svg.selectAll('.root').data([data]).join('g').attr('class', 'root');

    drawBars(aggregateData, {
      root,
      xScale,
      yScale,
      animate,
      canvas,
      colors,
      isUnavailable,
    });

    drawCurves(aggregateData, {
      root,
      xScale,
      yScale,
      animate,
      colors,
      isUnavailable,
      isFallBelowBreakeven,
    });

    root
      .selectAll('.navMarker')
      .data(navMarkerData)
      .join('line')
      .attr('class', 'navMarker')
      .attr('stroke', colors?.milestoneAccentColor ?? MILESTONE_ACCENT_COLOR)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', 2)
      .attr('x1', -10)
      .attr('x2', -10)
      .attr('y1', d => yScale(d.navMarker) || -10)
      .attr('y2', d => yScale(d.navMarker) || -10)
      .style('opacity', 0)
      .transition(animate)
      .attr('x1', canvas.left)
      .attr('x2', canvas.right)
      .style('opacity', 1);

    root
      .selectAll('.navMarker-label')
      .data(navMarkerData)
      .join('text')
      .attr('class', 'navMarker-label')
      .attr('fill', colors?.milestoneAccentColor ?? MILESTONE_ACCENT_COLOR)
      .attr('text-anchor', d => {
        return d.q < d3.max(axisData, dd => dd.q) / 2 ? 'start' : 'end';
      })
      .style('transform', 'translateY(-0.75em)')
      .text(d => {
        if (!d) return '';
        return navTarget ? 'Nav Target' : `NAV Peak: ${(d.q / 4).toFixed(1)} years`;
      })
      .style('opacity', 0)
      .attr('x', -100)
      .attr('y', d => yScale(d.navMarker))
      .transition(animate)
      .attr('x', d => xScale(d.date))
      .style('opacity', 1);

    root
      .selectAll('.zero-marker')
      .data([yScale(0)].filter(Number.isFinite))
      .join(
        function enter(selection) {
          selection
            .append('line')
            .attr('class', 'zero-marker')
            .attr('stroke', 'gray')
            .attr('stroke-width', 1)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', yScale(0))
            .attr('y2', yScale(0))
            .style('opacity', 0)
            .transition(animate)
            .attr('x1', 0)
            .attr('x2', width)
            .attr('y1', yScale(0))
            .attr('y2', yScale(0))
            .style('opacity', 1);
        },
        function update(selection) {
          selection.transition(animate).attr('x1', 0).attr('x2', width).attr('y1', yScale(0)).attr('y2', yScale(0));
        },
        function exit(selection) {
          selection
            .transition(animate)
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', yScale(0) ?? -10)
            .attr('y2', yScale(0) ?? -10)
            .remove();
        }
      );

    root
      .selectAll('.zero-marker-label')
      .data([aggregateData.map(d => d.ncf).some(Boolean)].filter(Boolean))
      .join('text')
      .attr('class', 'zero-marker-label')
      .style('fill', 'gray')
      .style('font-size', '1em')
      .attr('text-anchor', 'end')
      .style('transform', 'translate(-0.5em, -0.375em)')
      .text('Breakeven')
      .attr('x', canvas.right)
      .transition(animate)
      .attr('y', yScale(0));

    root
      .selectAll('.breakeven')
      .data(breakevenData)
      .join('line')
      .attr('class', 'breakeven')
      .attr('stroke', colors?.milestoneAccentColor ?? MILESTONE_ACCENT_COLOR)
      .attr('stroke-width', 0.5)
      .attr('stroke-dasharray', 4)
      .transition(animate)
      .attr('x1', d => xScale(d.date))
      .attr('x2', d => xScale(d.date))
      .attr('y1', canvas.top)
      .attr('y2', canvas.bottom);

    root
      .selectAll('.breakeven-label')
      .data(breakevenData)
      .join('text')
      .attr('class', 'breakeven-label')
      .style('fill', colors?.milestoneAccentColor ?? MILESTONE_ACCENT_COLOR)
      .style('font-size', '1em')
      .attr('text-anchor', 'end')
      .style('transform', 'translate(-1.5em, -1.5em)')
      .transition(animate)
      .text(d => {
        const duration = formatDurationDecimalYear(startDate)(d.date);
        return `Breakeven: ${duration} years`;
      })
      .attr('x', d => xScale(d.date))
      .attr('y', yScale(0));

    root
      .selectAll('.failed-breakeven-line')
      .data(isFallBelowBreakeven ? [0] : [])
      .join('line')
      .attr('class', 'failed-breakeven-line')
      .attr('stroke', colors?.failedBreakevenAccentColor ?? FAILED_BREAKEVEN_ACCENT_COLOR)
      .attr('stroke-width', 0.5)
      .attr('stroke-dasharray', 4)
      .transition(animate)
      .attr('x1', canvas.right - 0.5)
      .attr('x2', canvas.right - 0.5)
      .attr('y1', canvas.top)
      .attr('y2', yScale(aggregateData[aggregateData?.length - 1]?.forecastCurve));

    const htmlBlock = svg
      .selectAll('foreignObject')
      .data(unavailPlaceholderData)
      .join('foreignObject')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('x', 0)
      .attr('y', 0);
    htmlBlock
      .selectAll('.unavailable-message')
      .data(unavailPlaceholderData)
      .join('xhtml:div')
      .attr('class', 'unavailable-message')
      .style('top', d => `${yScale(d)}px`)
      .style('transform', d => {
        return yScale(d) > canvas.height / 2 ? 'translateY(-125%)' : 'translateY(25%)';
      })
      .html(`<p>No cashflow forecast is provided as this is a mature fund</p>`);

    return root;
  };
}

export default draw;
