import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTickerNews } from '../store/ticker-news';

function useTickerNews() {
  const dispatch = useDispatch();
  const tickerNews = useSelector(state => state.news.ticker);
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    if (!tickerNews.fetched && !tickerNews.fetching) {
      dispatch(fetchTickerNews());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickerNews.fetched, tickerNews.fetching]);

  useEffect(() => {
    if (tickerNews.fetched) {
      setArticles(tickerNews.articles);
    }
  }, [tickerNews]);

  return articles;
}

export default useTickerNews;
