import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import { useFund, useFundPeers } from 'src/hooks';
import { compareToPeers } from 'src/config/tutorials';
import {
  AppPanel,
  Responsive,
  ButtonInfo,
  ButtonFilters,
  FilterControlsTooltip,
  FundPeerVisualization,
  IconScatterPlot,
  IconBarChart,
  IconRadarChart,
} from 'src/components';

const PanelLayout = styled.section`
  min-height: 700px;

  @media (min-width: ${md.BREAKPOINT}px) {
    min-height: 400px;
  }
`;

const VIEWS = {
  plot: 'plot',
  radar: 'radar',
  bar: 'bar',
};

function CompareFundToPeersPanel({ spanX, spanY, fundId }) {
  const fund = useFund({ fundId });
  const peers =
    useFundPeers({
      fundId,
      numOfPrevVintages: 2,
      numOfNextVintages: 2,
    }) || [];
  const [view, setView] = useState(VIEWS.plot);
  const [isExpandedDefault, setIsExpandedDefault] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);
  const [infoTooltipAnchorEl, setInfoTooltipAnchorEl] = useState(null);
  const [isFilterControlsOpened, setIsFilterControlsOpened] = useState(null);

  function handleInfoTooltipOpen(evt) {
    setInfoTooltipAnchorEl(evt.currentTarget);
  }

  function handleInfoTooltipClose() {
    setInfoTooltipAnchorEl(null);
  }

  function handleFiltersToggle() {
    setIsFilterControlsOpened(isOpen => !isOpen);
  }

  function handleFiltersClose() {
    setIsFilterControlsOpened(null);
  }

  const title = useMemo(() => {
    if (isExpanded) return `${fund?.name} - Compare to Peers`;
    return 'Compare to Peers';
  }, [fund?.name, isExpanded]);

  useEffect(
    function () {
      if (view === VIEWS.plot) return;
      setIsExpandedDefault(view === VIEWS.radar || view === VIEWS.bar);
    },
    [view]
  );

  return (
    <PanelLayout
      as={AppPanel}
      spanX={spanX}
      spanY={spanY}
      title={title}
      isExpanded={isExpandedDefault}
      onChangeExpanded={setIsExpanded}
      tutorial={compareToPeers}
      controls={
        <>
          <div>
            <AppPanel.Button as={ButtonFilters} onClick={handleFiltersToggle} />
            <Responsive.OnAtLeastTablet>
              <ButtonInfo
                onClick={handleInfoTooltipOpen}
                css={`
                  color: #979797;
                `}
                className="info-button"
              />
            </Responsive.OnAtLeastTablet>
          </div>
          <div>
            <AppPanel.Button
              startIcon={<IconScatterPlot />}
              selected={view === VIEWS.plot}
              onClick={() => setView(VIEWS.plot)}
            >
              Plot
            </AppPanel.Button>
            <AppPanel.Button
              startIcon={<IconRadarChart />}
              selected={view === VIEWS.radar}
              onClick={() => setView(VIEWS.radar)}
            >
              Radar
            </AppPanel.Button>
            <Responsive.OnAtLeastTablet>
              <AppPanel.Button
                startIcon={<IconBarChart />}
                selected={view === VIEWS.bar}
                onClick={() => setView(VIEWS.bar)}
              >
                Bar
              </AppPanel.Button>
            </Responsive.OnAtLeastTablet>
          </div>
        </>
      }
      expandable
      downloadable={fund && { fileName: `${fund.name}_peers.png` }}
    >
      <FilterControlsTooltip
        open={Boolean(infoTooltipAnchorEl)}
        onClose={handleInfoTooltipClose}
        anchorEl={infoTooltipAnchorEl}
      />
      {fund && (
        <FundPeerVisualization
          subjectFund={fund}
          peerFunds={peers}
          showScatterPlot={view === VIEWS.plot}
          showRadarChart={view === VIEWS.radar}
          showBarChart={view === VIEWS.bar}
          showFilterControls={isFilterControlsOpened}
          handleFiltersClose={handleFiltersClose}
        />
      )}
    </PanelLayout>
  );
}

CompareFundToPeersPanel.defaultProps = {};

CompareFundToPeersPanel.propTypes = {
  fundId: PropTypes.string.isRequired,
};

export default CompareFundToPeersPanel;
