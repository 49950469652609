import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import { Box, Typography, CheckboxSmall } from 'src/components';

const VintagesTitle = styled(Typography)`
  color: #646464;
  font-weight: 300;
  font-size: 12px;
  margin-right: 10px;
`;

const VintageCheckboxLabel = styled(FormControlLabel)`
  font-size: 10px;
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: #949494;
  }
`;

function VintageSelection({ vintages, label, onVintageToggle, color: _color, ...rest }) {
  return (
    <Box display="inline-flex" alignItems="center" {...rest}>
      <VintagesTitle>{label}:</VintagesTitle>
      {vintages &&
        vintages.map(vintage => (
          <VintageCheckboxLabel
            key={vintage.value}
            control={<CheckboxSmall checked={vintage.checked} />}
            label={vintage.value}
            onChange={() => onVintageToggle(vintage)}
          />
        ))}
    </Box>
  );
}

VintageSelection.defaultProps = {
  label: 'Vintages',
  color: '#aaa',
  onVintageToggle: () => {},
};

VintageSelection.propTypes = {
  vintages: PropTypes.array.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  onVintageToggle: PropTypes.func,
};

export default VintageSelection;
