import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import { useFundFinancialHistory, useFundBenchmarkHistory } from 'src/hooks';
import { parseISO } from 'src/utils/date';
import { pickValues } from 'src/utils/object';
import {
  HistoricalChart,
  RecentPeriodChart,
  Responsive,
  IncompleteDataMessage,
  LoadingIndicator,
} from 'src/components';

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  margin-top: 20px;
  flex: 1;

  display: grid;
  gap: 30px 40px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${p => p.$gridRows};

  @media (min-width: ${md.BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Footer = styled.div`
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-top: 4px;
`;

const Square = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #4e4e4e;
  margin-right: 0.5em;
`;

const BLANK_PERIOD = {
  calledPct: 1,
  dpi: 0,
  rvpi: 0,
  tvpi: 0,
};

function FundPeerBenchmarks({ fundId }) {
  const fundHistory = useFundFinancialHistory({ fundId }) || {};

  const benchmarkHistory =
    useFundBenchmarkHistory({
      fundId,
    }) || {};

  const isLoading =
    fundHistory.fetching ||
    benchmarkHistory.fetching ||
    !fundHistory.items ||
    fundHistory.items.length < 1 ||
    !benchmarkHistory.items ||
    benchmarkHistory.items.length < 1;

  if (isLoading)
    return (
      <Layout
        css={`
          justify-content: center;
          align-items: center;
        `}
      >
        <LoadingIndicator display={true} message="Gathering Data"></LoadingIndicator>
      </Layout>
    );

  const [fundHistoryItems, benchmarkHistoryItems] = [fundHistory.items, benchmarkHistory.items];

  const fundRecentQuarter = fundHistoryItems[0] || BLANK_PERIOD;
  const benchmarkRecentQuarter = benchmarkHistoryItems[0] || BLANK_PERIOD;

  function getDryPowderValue(data) {
    if (!Number.isFinite(data.calledPct)) return null;
    return Math.max(1 - data.calledPct, 0);
  }

  const insufficientData = Object.values(pickValues('dpi', 'calledPct', 'tvpi', 'rvpi')(fundRecentQuarter)).some(
    val => val === null
  );

  return (
    <Layout>
      {insufficientData && <IncompleteDataMessage entity="fund" />}
      <Main $gridRows={fundHistoryItems.length > 2 ? '2fr 1fr' : '1fr'}>
        <RecentPeriodChart
          isLoading={isLoading}
          accentColor="#037233"
          title="Dry Powder"
          date={parseISO(fundRecentQuarter.date)}
          value={getDryPowderValue(fundRecentQuarter)}
          labelFormat={x => {
            if (x === null) return 'N/A';
            return `${Math.round(x * 100)}%`;
          }}
          benchmarkValue={getDryPowderValue(benchmarkRecentQuarter)}
          hasMoreHistory={fundHistoryItems.length > 1}
        />

        <Responsive.OnAtLeastTablet>
          <RecentPeriodChart
            isLoading={isLoading}
            title="Distributions"
            date={parseISO(fundRecentQuarter.date)}
            value={fundRecentQuarter.dpi}
            benchmarkValue={benchmarkRecentQuarter.dpi}
            hasMoreHistory={fundHistoryItems.length > 1}
          />
        </Responsive.OnAtLeastTablet>

        <RecentPeriodChart
          isLoading={isLoading}
          title="Remaining Value"
          date={parseISO(fundRecentQuarter.date)}
          value={fundRecentQuarter.rvpi}
          benchmarkValue={benchmarkRecentQuarter.rvpi}
          hasMoreHistory={fundHistoryItems.length > 1}
        />

        <Responsive.OnAtLeastTablet>
          <RecentPeriodChart
            isLoading={isLoading}
            title="Total Value"
            date={parseISO(fundRecentQuarter.date)}
            value={fundRecentQuarter.tvpi}
            benchmarkValue={benchmarkRecentQuarter.tvpi}
            hasMoreHistory={fundHistoryItems.length > 1}
          />
        </Responsive.OnAtLeastTablet>

        {fundHistoryItems.length > 2 && (
          <>
            <HistoricalChart
              isLoading={isLoading}
              accentColor="#037233"
              labelFormat={x => {
                if (x === null) return 'N/A';
                return `${Math.round(x * 100)}%`;
              }}
              history={fundHistoryItems.map(d => {
                return {
                  value: 1 - d.calledPct,
                  date: d.date,
                };
              })}
              benchmarkHistory={benchmarkHistoryItems.map(d => {
                return {
                  value: 1 - d.calledPct,
                  date: d.date,
                };
              })}
            />

            <Responsive.OnAtLeastTablet>
              <HistoricalChart
                isLoading={isLoading}
                history={fundHistoryItems.map(d => ({
                  value: d.dpi,
                  date: d.date,
                }))}
                benchmarkHistory={benchmarkHistoryItems.map(d => ({
                  value: d.dpi,
                  date: d.date,
                }))}
              />
            </Responsive.OnAtLeastTablet>

            <HistoricalChart
              isLoading={isLoading}
              history={fundHistoryItems.map(d => ({
                value: d.rvpi,
                date: d.date,
              }))}
              benchmarkHistory={benchmarkHistoryItems.map(d => ({
                value: d.rvpi,
                date: d.date,
              }))}
            />

            <Responsive.OnAtLeastTablet>
              <HistoricalChart
                isLoading={isLoading}
                history={fundHistoryItems.map(d => ({
                  value: d.tvpi,
                  date: d.date,
                }))}
                benchmarkHistory={benchmarkHistoryItems.map(d => ({
                  value: d.tvpi,
                  date: d.date,
                }))}
              />
            </Responsive.OnAtLeastTablet>
          </>
        )}
      </Main>

      <Footer>
        <Square /> Peer Group
      </Footer>
    </Layout>
  );
}

FundPeerBenchmarks.defaultProps = {};

FundPeerBenchmarks.propTypes = {
  fundId: PropTypes.string.isRequired,
};

export default FundPeerBenchmarks;
