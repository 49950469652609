import React from 'react';
import PropTypes from 'prop-types';
import * as auth from 'src/services/filter-api/authentication';
import { useCreateUserAccount } from 'src/hooks';

function AuthDevHelper() {
  const createUser = useCreateUserAccount();
  const deleteUser = auth.useDelete().mutate;
  const signIn = auth.useAuthenticate().mutate;
  const signOut = auth.useDeauthenticate().mutate;

  return (
    <div>
      <button
        onClick={() => {
          createUser({
            name: 'Example User',
            email: 'user@example.com',
            password: 'foobar',
            remember: false,
          });
        }}
      >
        Create user
      </button>
      <button
        onClick={() =>
          signIn({
            email: 'user@example.com',
            password: 'foobar',
            remember: true,
          })
        }
      >
        Sign in
      </button>
      <button onClick={signOut}>Sign out</button>
      <button onClick={deleteUser}>Delete user</button>
    </div>
  );
}

AuthDevHelper.defaultProps = {};

AuthDevHelper.propTypes = {
  children: PropTypes.any,
};

export default AuthDevHelper;
