import { db } from 'src/config/firebase';

const collection = db.collection('strategies');

async function getStrategy(strategyId) {
  const docRef = collection.doc(strategyId);
  const snapshot = await docRef.get();

  if (snapshot.exists) return snapshot.data();

  return null;
}

async function getStrategies() {
  return (await collection.orderBy('rank', 'asc').get()).docs.map(d => d.data());
}

export { getStrategies, getStrategy };
