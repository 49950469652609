import React from 'react';
import { useRef } from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow } from '@material-ui/core';
import { TooltipContent, TooltipCloseIcon, TooltipHeader } from 'src/components';

const bulletCss = css`
  margin: 8px 0;
  padding: 0;
`;

const Layout = styled.div``;

const Main = styled.div`
  max-width: 400px;
  color: #000;
  fontsize: 12px;
  overflow: auto;
`;

const Title = styled.h3`
  margin: 0;
  color: #838383;
  text-transform: uppercase;
`;

const Arrow = styled.div`
  position: absolute;

  &::before {
    content: '';
    display: block;
    width: 1.5em;
    height: 1.5em;
    background-color: #eeeeee;
    transform: rotate(45deg);
  }
`;

const ArrowTop = styled(Arrow)`
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ArrowBottom = styled(Arrow)`
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const ArrowLeft = styled(Arrow)`
  top: 50%;
  left: 0;
  transform: translate(-50%, 50%);
`;

const ArrowRight = styled(Arrow)`
  top: 50%;
  right: 0;
  transform: translate(50%, 50%);
`;

function CurveHistoryTooltip({ open, anchorEl, onClose }) {
  const arrowRef = useRef();

  return (
    <Popper
      style={{ zIndex: 100, position: 'relative' }}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0,15',
        },
        arrow: {
          enabled: true,
          element: arrowRef.current,
        },
        flip: {
          enabled: true,
        },
      }}
    >
      {({ TransitionProps, placement }) => {
        const top = placement === 'top';
        const bottom = placement === 'bottom';
        const left = placement === 'left';
        const right = placement === 'right';

        return (
          <Grow {...TransitionProps} timeout={300}>
            <Layout>
              {top && <ArrowBottom ref={arrowRef} />}
              {bottom && <ArrowTop ref={arrowRef} />}
              {left && <ArrowRight ref={arrowRef} />}
              {right && <ArrowLeft ref={arrowRef} />}
              <TooltipContent>
                <TooltipHeader>
                  <Title>Curve History</Title>
                  <TooltipCloseIcon margin="10px 0" onClick={onClose} />
                </TooltipHeader>
                <Main>
                  <p css={bulletCss}>
                    The J-curves and NAV curves represented in these charts reflect the respective history of funds in
                    these sub-strategies over the past 20 years. Users can assess the average payback and peak NAV for
                    each sub strategy as well as for the mean Top and Bottom quartiles for each sub strategy.
                  </p>
                </Main>
              </TooltipContent>
            </Layout>
          </Grow>
        );
      }}
    </Popper>
  );
}

CurveHistoryTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};

CurveHistoryTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default CurveHistoryTooltip;
