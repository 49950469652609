import { combineReducers } from '@reduxjs/toolkit';
import funds from './funds';
import fundFinancialHistory from './fund-financial-history';
import fundsInMarketCounts from './funds-in-market-counts';
import fundsInMarket from './funds-in-market';
import fundManagers from './fund-managers';
import fundManagerLocations from './fund-managers-locations';
import fundManagerNames from './fund-manager-names';
import fundManagerFundsInMarketSize from './fund-manager-funds-in-market-size';
import fundManagerFundsInMarket from './fund-manager-funds-in-market';
import fundManagerFundsRaising from './fund-manager-funds-raising';
import employees from './employees';
import employeeBookmarks from './employee-bookmarks';
import schoolEmployees from './school-employees';
import priorFirmEmployees from './prior-firm-employees';
import strategyPerformance from './strategy-performance';
import recent from './recent-news';
import portfolio from './portfolio-news';
import ticker from './ticker-news';
import fundManagerNews from './fund-manager-news';
import newsSummary from './news-summary';
import newsContent from './news-content';
import lastSelected from './last-selected';
import loading from './loading';
import fundCashflows from './fund-cashflows';

const rootReducer = combineReducers({
  fund: combineReducers({
    all: funds,
    financialHistory: fundFinancialHistory,
  }),
  fundManager: combineReducers({
    all: fundManagers,
    names: fundManagerNames,
    locations: fundManagerLocations,
  }),
  news: combineReducers({
    ticker,
    recent,
    portfolio,
    fundManager: fundManagerNews,
    summaries: newsSummary,
    content: newsContent,
  }),
  employee: combineReducers({
    all: employees,
    school: schoolEmployees,
    priorFirm: priorFirmEmployees,
    bookmarks: employeeBookmarks,
  }),
  fundsInMarket: combineReducers({
    counts: fundsInMarketCounts,
    funds: fundsInMarket,
    fundManagerSize: fundManagerFundsInMarketSize,
    fundManagerFunds: fundManagerFundsInMarket,
  }),
  fundsRaising: combineReducers({
    fundManagerFunds: fundManagerFundsRaising,
  }),
  strategyPerformance,
  lastSelected,
  loading,
  fundCashflows,
});

export default rootReducer;
