// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { capitalize } from '../../../formatters';
import { TooltipContent, TooltipCloseIcon, TooltipHeader, TooltipTitle } from '../..';
import FundManagerJoinersAndLeaversTable from '../FundManagerJoinersAndLeaversTable';

//#region styled components
const TooltipArrow = styled.span`
  margin-left: ${props => props.marginLeft};
  color: #141414;
  position: absolute;
  bottom: -8px;
  width: 0;
  height: 0;
  ::before {
    content: '';
    display: block;
    position: absolute;
    margin-left: ${props => props.marginLeft};
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-top-color: #4b4b4b;
    bottom: -14px;
    left: -1px;
  }
  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #141414;
    margin-left: ${props => props.marginLeft};
    bottom: 0;
  }
`;

const TooltipWrapper = styled.div``;

const Content = styled(TooltipContent)`
  box-shadow: ${props => props.theme.shadows[4]};
  background-color: #141414;
  border: solid 2px #4b4b4b;
  width: 450px;
  height: 250px;
`;

//#endregion

// eslint-disable-next-line no-unused-vars
function FundManagersJoinersAndLeaversTooltip({
  joinersAndLeavers,
  employees,
  fundManager,
  open,
  anchorEl,
  onClickAway,
  onClose,
}) {
  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    if (employees && fundManager && joinersAndLeavers) {
      const tooltipData = joinersAndLeavers.data[joinersAndLeavers.leaversOrJoiners];
      setTableData(
        tooltipData.map(({ employeeId, title }) => ({
          name: employees[employeeId] ? `${employees[employeeId].firstName} ${employees[employeeId].lastName}` : '',
          title,
        }))
      );
    }
  }, [employees, fundManager, joinersAndLeavers]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="top"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: '.joiners-leavers-tooltip-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <TooltipWrapper>
              <TooltipArrow className="joiners-leavers-tooltip-arrow" marginLeft="-6.5px" />
              <Content>
                <TooltipHeader>
                  <TooltipTitle color="#b8b8b8">
                    {joinersAndLeavers.data.year} Senior {capitalize()(joinersAndLeavers.leaversOrJoiners)}
                  </TooltipTitle>
                  <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
                </TooltipHeader>
                {tableData && <FundManagerJoinersAndLeaversTable data={tableData} />}
              </Content>
            </TooltipWrapper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}

FundManagersJoinersAndLeaversTooltip.defaultProps = {
  joinersAndLeavers: null,
  open: false,
  anchorEl: null,
  employees: null,
  fundManager: null,
  onClickAway: () => {},
  onClose: () => {},
};

FundManagersJoinersAndLeaversTooltip.propTypes = {
  joinersAndLeavers: PropTypes.object,
  employees: PropTypes.object,
  fundManager: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
};

export default FundManagersJoinersAndLeaversTooltip;
