import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'src/lib/prop-types';
import styled from 'styled-components';
// import { useTable, useSortBy } from 'react-table';
import { withResizeDetector } from 'react-resize-detector';
// import { FixedSizeList } from 'react-window';
// import { useScrollbarWidth } from 'src/hooks';
import Table from './Table';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  position: relative;
`;

function VirtualizedGridTable({
  className,
  columns,
  data,
  width,
  height,
  targetRef,
  stateReducer,
  hiddenColumnIds,
  initialSortBy,
  onChangeRows,
}) {
  const hiddenColumns = useMemo(
    () => [
      ...columns
        .filter(c => {
          if (!width) return false;
          return width < (c.minDisplayWidth || 0);
        })
        .map(c => c.id || (typeof c.accessor === 'string' && c.accessor)),
      ...hiddenColumnIds,
    ],
    [width, columns, hiddenColumnIds]
  );

  const gridTemplateColumns = columns
    .filter(c => !hiddenColumns.includes(c.id))
    .map(c => c.width)
    .join(' ');

  return (
    <Layout ref={targetRef} className={className}>
      <Table
        columns={columns}
        hiddenColumns={hiddenColumns}
        data={data}
        width={width}
        height={height}
        stateReducer={stateReducer}
        initialSortBy={initialSortBy}
        onChangeRows={onChangeRows}
        gridTemplateColumns={gridTemplateColumns}
      />
    </Layout>
  );
}

VirtualizedGridTable.defaultProps = {
  width: 0,
  height: 0,
  hiddenColumnIds: [],
  initialSortBy: [],
};

VirtualizedGridTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
      id: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      width: PropTypes.string,
      minDisplayWidth: PropTypes.number,
    })
  ),
  data: PropTypes.array.isRequired,
  hiddenColumnIds: PropTypes.array,
  onChangeRows: PropTypes.func,
};

export default withResizeDetector(VirtualizedGridTable, {
  refreshMode: 'debounce',
  refreshRate: 100,
  trailing: true,
});
