import React from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import styled from 'styled-components';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

const ButtonGroup = styled(ToggleButtonGroup)`
  .MuiButtonBase-root {
    font-size: ${p => (p.$sizeVariant === 'small' ? 0.75 : 1)}em;
    padding: 0.375em 1.5em;
  }
`;

function ToggleButtons({ value, options, sizeVariant, onChange, className }) {
  return (
    <ButtonGroup
      className={className}
      $sizeVariant={sizeVariant}
      value={value}
      exclusive
      onChange={(_, v) => onChange({ target: { value: v } })}
    >
      {options.map(option => {
        return (
          <ToggleButton key={option} value={option}>
            {option}
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
}

ToggleButtons.defaultProps = {
  options: [],
  sizeVariant: 'normal',
  onChange: funcStub('onChange'),
};

ToggleButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  sizeVariant: PropTypes.oneOf(['normal', 'small']),
};

export default ToggleButtons;
