import { select, geoNaturalEarth1, geoPath } from 'd3';

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function draw({ id, dataGeo, dataPoints, mouseEventRefs, width, height, margin }) {
  if (!width || !height) return;

  const svgWidth = width - margin.left - margin.right;
  const svgHeight = height - margin.top - margin.bottom;

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const projection = geoNaturalEarth1()
    .scale(svgWidth / 1.4 / Math.PI)
    .center([10, 10])
    .translate([svgWidth / 2, svgHeight / 2]);

  const root = select(`#${id}`).selectAll('.root').data([dataGeo]).join('g').attr('class', 'root');

  // Draw the map
  root
    .selectAll('path')
    .data(dataGeo.features)
    .join('path')
    .attr('fill', '#2A2A2A')
    .attr('d', geoPath().projection(projection))
    .style('stroke', 'none');

  // Add circles
  root
    .selectAll('.address-point')
    .data(dataPoints)
    .join('circle')
    .attr('class', 'address-point')
    .attr('cx', d => projection([+d.lng, +d.lat])[0])
    .attr('cy', d => projection([+d.log, +d.lat])[1])
    .attr('r', 3)
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .style('fill', '#0D8BAB')
    .attr('fill-opacity', 0.9);
}

export default draw;
