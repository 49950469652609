/* eslint-disable no-continue */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { summarizeNewsArticle, getKey } from '../store/news-summary';

export default function useNewsArticleSummary({ article, summaryLength }) {
  const dispatch = useDispatch();
  const summaries = useSelector(state => state.news.summaries);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    if (article && summaryLength) {
      dispatch(summarizeNewsArticle({ article, summaryLength }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, summaryLength]);

  useEffect(() => {
    if (article && summaryLength) {
      const key = getKey({
        articleId: article.articleId,
        summaryLength,
      });
      const item = summaries.byId[key];
      setSummary(item);
    }
  }, [article, summaries.byId, summaryLength]);

  return summary;
}
