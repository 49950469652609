import { db } from 'src/config/firebase';

const collection = db.collection('userAgreements');

async function getUserAgreements(userId) {
  const docRef = collection.doc(userId);
  const snapshot = await docRef.get();

  return snapshot.data();
}

async function setUserAgreement(userId, key, agree = true) {
  const docRef = collection.doc(userId);
  const data = {
    userId,
    [key]: agree ? new Date().toISOString() : null,
  };

  await docRef.set(data, { merge: true });
  const snapshot = await docRef.get();
  return snapshot.data();
}

export { getUserAgreements, setUserAgreement };
