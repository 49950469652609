import React from 'react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { uniqueId } from 'src/utils';
import { sortBy } from 'src/lib/lodash';
import { LoadingIndicator } from 'src/components';
import draw from './draw';

const Layout = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  font-size: 0.714285714em;

  .tick text {
    font-size: 7px;
  }
`;

const Footer = styled.p`
  margin: 0.375em 0;
`;

const ChartSection = styled.div`
  flex: 1;
  position: relative;
`;

const Svg = styled.svg`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

function HistoricalChart({ history, benchmarkHistory, accentColor, labelFormat, targetRef, width, height, isLoading }) {
  const { current: svgId } = useRef(uniqueId());
  const [relevantHistory, setRelevantHistory] = useState([]);
  const [relevantBenchmarkHistory, setRelevantBenchmarkHistory] = useState([]);

  useEffect(
    function () {
      /**
       * Only keep the last 3 years (12 quarters) of history
       */
      const fundHistory = sortBy(history, 'date').reverse().slice(0, 12);

      const benchmarkFundHistory = sortBy(benchmarkHistory, 'date').reverse().slice(0, 12);

      setRelevantHistory(fundHistory);
      setRelevantBenchmarkHistory(benchmarkFundHistory);
    },
    [history, benchmarkHistory]
  );

  useEffect(
    function () {
      draw({
        svgId,
        relevantHistory,
        relevantBenchmarkHistory,
        width: Math.floor(width),
        height: Math.floor(height),
        accentColor,
        labelFormat,
      });
    },
    [svgId, relevantHistory, relevantBenchmarkHistory, width, height, accentColor, labelFormat]
  );

  /**
   * If there's not enough history to draw the line charts
   * then bail
   *  */
  if (history.length < 2) return null;

  if (isLoading)
    return (
      <div
        css={`
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          align-items: center;
        `}
      >
        <LoadingIndicator />
      </div>
    );

  return (
    <Layout>
      <ChartSection ref={targetRef}>
        <Svg id={svgId} width={Math.floor(width)} height={Math.floor(height)} />
      </ChartSection>
      <Footer>Historic</Footer>
    </Layout>
  );
}

HistoricalChart.displayName = 'FundFinancialHistoryKeyMetricsHistoricalChart';

HistoricalChart.defaultProps = {
  width: 0,
  height: 0,
  accentColor: '#b0b0b0',
  labelFormat: x => `${parseFloat(x).toFixed(1)}⨯`,
};

HistoricalChart.propTypes = {
  accentColor: PropTypes.string,
  labelFormat: PropTypes.func,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  benchmarkHistory: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
};

export default withResizeDetector(HistoricalChart, {
  refreshMode: 'throttle',
  refreshRate: 50,
});
