import { useEffect, useMemo, useState, useCallback } from 'react';

const DEFAULT_OPTS = {
  defaultSelectedIds: undefined,
  coreAttributeIds: undefined,
  startYear: 1999,
  endYear: new Date().getFullYear(),
};

/**
 * @param options: memoized:Object{
 *   defaultSelectedIds: Array<vintageId>,
 *   coreAttributeIds: Array<vintageId>,
 *   startYear: Int,
 *   endYear: Int,
 * }
 * @example useVintagesFilter(useMemo(() => ({
 *   defaultSelectedIds: [2017, 2018, 2019],
 *   startYear: 1999,
 *   endYear: 2021,
 * }), []))
 */
function useVintagesFilter(options) {
  const [state, setState] = useState();

  const vintages = useMemo(() => {
    const { startYear, endYear, coreAttributeIds } = { ...DEFAULT_OPTS, ...options };
    const length = endYear - startYear + 1;
    return new Array(length).fill(endYear).map((n, i) => {
      const year = n - i;
      const isCoreAttribute = coreAttributeIds?.includes(year);
      const label = isCoreAttribute ? `${year} *` : year.toString();
      return {
        label,
        vintageId: year,
      };
    });
  }, [options]);

  const getDefaultSelectedState = useCallback(
    item => {
      if (!options || !options.defaultSelectedIds) return true;
      return options.defaultSelectedIds.includes(item.vintageId);
    },
    [options]
  );

  useEffect(() => {
    if (!vintages) return;

    return setState(
      vintages.map(item => ({
        ...item,
        disabled: false,
        selected: getDefaultSelectedState(item),
      }))
    );
  }, [getDefaultSelectedState, vintages]);

  return [state, setState];
}

export default useVintagesFilter;
