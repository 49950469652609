export const dark = {
  type: 'dark',
  primary: {
    main: '#0D8BAB',
  },
  secondary: {
    main: '#e1b72a',
  },
  error: {
    main: '#ff6205',
  },
  custom: {
    visualization: {
      header: { background: '#000' },
      body: { background: '#141414' },
      heatMap: ['#1f2e1c', '#6bdb15'],
    },
  },
};
