import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { useFundPeers, useFilteredFunds, useFundSystemPeerGroupFilter } from 'src/hooks';
import { lookup } from 'src/lookup';
import {
  FilterControls,
  FundSummaryScatter,
  Box,
  ButtonFilters,
  IconClose,
  UserFundBenchmarkIndicator,
} from 'src/components';

const TooltipWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #141414;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #4b4b4b;
  overflow: hidden;
`;

const ChartTitle = styled.span`
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 300;
  color: #b5b5b5;
`;

const ChartWrapper = styled(Box)`
  width: 850px;
  height: 300px;
`;

const TooltipArrow = styled.span`
  margin-left: ${props => props.marginLeft};
  color: #141414;
  position: absolute;
  right: -8px;
  width: 0;
  height: 0;
  ::before {
    content: '';
    display: block;
    position: absolute;
    margin-left: ${props => props.marginLeft};
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-left-color: #4b4b4b;
    right: -14px;
    top: -11px;
  }
  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #141414;
    margin-left: ${props => props.marginLeft};
    right: 1px;
    top: -10px;
  }
`;

const CustomSelect = styled.div`
  display: flex;

  color: #505050;
  background-color: #2b2b2b;
  border: 1px solid #383838;
  border-radius: 3px;
  padding: 2px 5px;

  select {
    border: none;
    background: transparent;
    color: #9d9d9d;

    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }
`;

function FundPerformanceTableTooltip({
  fund,
  fundPeerGroupFilter,
  benchmark,
  open,
  boundary,
  anchorEl,
  onBenchmarkChange,
  onClose,
  onClickAway,
}) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const toggleFilterOpen = () => setFiltersOpen(!filtersOpen);

  const {
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
  } = useFundSystemPeerGroupFilter(fund);

  const peers = useFilteredFunds({
    funds: useFundPeers({
      fundId: fund.fundId,
      numOfPrevVintages: 2,
      numOfNextVintages: 2,
    }),
    strategies: strategiesFilter,
    vintages: vintagesFilter,
    regions: regionsFilter,
    secondRegions: secondRegionsFilter,
    sizes: sizesFilter,
  });

  const handleClickAway = useCallback(
    function (_e) {
      if (!open) return;

      onClickAway();
    },
    [open, onClickAway]
  );

  if (!fund) return null;
  if (!open) return null;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="left"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,5',
          },
          arrow: {
            enabled: true,
            element: '.fund-performance-table-arrow',
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: boundary,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <TooltipWrapper>
              <TooltipArrow className="fund-performance-table-arrow" />
              <Box display="flex" justifyContent="flex-end" width="100%" onClick={onClose}>
                <IconClose
                  css={`
                    color: #8a8a8a;
                    width: 12px;
                    height: 12px;
                    margin-top: -8px;
                    cursor: pointer;
                    &:hover {
                      color: #afafaf;
                    }
                  `}
                />
              </Box>

              <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <ChartTitle>
                  {fund.name}
                  {fundPeerGroupFilter && <UserFundBenchmarkIndicator mb="6px" />}
                </ChartTitle>

                <Box display="flex" justifyContent="center" flex={1}>
                  <ButtonFilters onClick={toggleFilterOpen} />
                </Box>

                <Box display="flex" flex={1} justifyContent="flex-end">
                  <CustomSelect
                    css={`
                      margin-right: 1em;
                    `}
                  >
                    <header>Benchmark: </header>
                    <select
                      value={benchmark.key}
                      onChange={event => {
                        event.preventDefault();
                        onBenchmarkChange(lookup.financialMetric[event.target.value]);
                      }}
                    >
                      <option value={lookup.financialMetric.tvpi.key}>TVPI</option>
                      <option value={lookup.financialMetric.irr.key}>IRR</option>
                    </select>
                  </CustomSelect>
                </Box>
              </Box>
              <ChartWrapper className="chart-wrapper">
                <FundSummaryScatter metric={benchmark} data={peers} fundId={fund.fundId} />
              </ChartWrapper>

              <FilterControls
                showFundCoreAttributesHint
                open={filtersOpen}
                setOpen={setFiltersOpen}
                defaultRegionsFilter={defaultRegionsFilter}
                regionsFilter={regionsFilter}
                setRegionsFilter={setRegionsFilter}
                defaultSecondRegionsFilter={defaultSecondRegionsFilter}
                secondRegionsFilter={secondRegionsFilter}
                setSecondRegionsFilter={setSecondRegionsFilter}
                defaultSizesFilter={defaultSizesFilter}
                sizesFilter={sizesFilter}
                setSizesFilter={setSizesFilter}
                defaultStrategiesFilter={defaultStrategiesFilter}
                strategiesFilter={strategiesFilter}
                setStrategiesFilter={setStrategiesFilter}
                defaultVintagesFilter={defaultVintagesFilter}
                vintagesFilter={vintagesFilter}
                setVintagesFilter={setVintagesFilter}
              />
            </TooltipWrapper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
FundPerformanceTableTooltip.defaultProps = {
  fund: null,
  fundPeerGroupFilter: null,
  benchmark: 'tvpi',
  open: false,
  boundary: null,
  anchorEl: null,
  onClose: () => {},
  onBenchmarkChange: () => {},
};

FundPerformanceTableTooltip.propTypes = {
  fund: PropTypes.object,
  fundPeerGroupFilter: PropTypes.object,
  benchmark: PropTypes.object,
  open: PropTypes.bool,
  boundary: PropTypes.object,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  onBenchmarkChange: PropTypes.func,
};

export default FundPerformanceTableTooltip;
