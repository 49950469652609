import React from 'react';
import PropTypes from 'prop-types';
import { Select as MuiSelect } from '@material-ui/core';
import EnhancedSelect from './EnhancedSelect';
import CompactSelect from './CompactSelect';

function Select({ preset, ...rest }) {
  const Component = {
    enhanced: EnhancedSelect,
    standard: MuiSelect,
    compact: CompactSelect,
  }[preset];

  return <Component {...rest} />;
}

Select.defaultProps = {
  preset: 'standard',
  value: '',
};

Select.propTypes = {
  preset: PropTypes.oneOf(['standard', 'enhanced', 'compact']),
};

export default Select;
