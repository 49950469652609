import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStrategyTimeseriesQueries } from 'src/hooks';
import { AppPanel } from 'src/components';
import StrategyCurveHistoryItem from './Item';
// import { getBreakevenDate } from './calculator';
// import { getLastQuarter } from 'src/utils/date';

const COLUMNS = 4;
const ROWS = 4;
const QUARTILES = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${COLUMNS}, minmax(0, 1fr));
  grid-template-rows: repeat(${ROWS}, minmax(0, 1fr));
  gap: 4px;

  padding: 0 10px;
  padding-bottom: 10px;
  flex: 1;
`;

function StrategyCurveHistoryGrid({ type, quartile, strategies, onItemClick, ...rest }) {
  const { isExpanded } = useContext(AppPanel.Context);

  const timeseriesQueries = useStrategyTimeseriesQueries(
    [...new Set(strategies.map(({ subStrategyId, strategyId }) => [subStrategyId, strategyId]).flat())].map(
      strategyId => ({
        type: 'mean',
        strategyId,
        quartile: QUARTILES[quartile],
      })
    )
  );

  const timeseries = useMemo(() => {
    const seriesMap = timeseriesQueries.reduce((acc, query) => {
      if (!query.data) return acc;
      acc[query?.data?.strategyId] = query.data.series;
      return acc;
    }, {});

    return strategies.reduce((acc, { strategyId, subStrategyId }) => {
      const byStrategyId = seriesMap?.[strategyId];
      const bySubStrategyId = seriesMap?.[subStrategyId];

      const isStrategyTimeseries = Boolean(byStrategyId && bySubStrategyId?.find(s => s.n < 10 && s.q <= 40));
      const data = isStrategyTimeseries ? byStrategyId : bySubStrategyId;

      return {
        ...acc,
        [subStrategyId]: { data, isStrategyTimeseries },
      };
    }, {});
  }, [timeseriesQueries, strategies]);

  const data = useMemo(() => {
    return strategies;
    // const startDate = getLastQuarter();
    // return strategies
    //   .map(d => ({ ...d, delta: getBreakevenDate(timeseries?.[d.subStrategyId]?.data, startDate) }))
    //   .filter(d => d.subStrategy !== 'Real Asset Debt')
    //   .sort((a, b) => {
    //     if (customSort === 'asc') {
    //       if (!b.delta) return -1;
    //       if (!a.delta) return 1;
    //       return a.delta - b.delta;
    //     }
    //     if (customSort === 'desc') {
    //       if (!a.delta) return -1;
    //       if (!b.delta) return 1;
    //       return b.delta - a.delta;
    //     }
    //     return true;
    //   })
    // }, [strategies, timeseries, customSort]);
  }, [strategies]);

  return (
    <Container isExpanded={isExpanded} {...rest}>
      {data?.map((strategy, i) => (
        <StrategyCurveHistoryItem
          key={i}
          strategy={strategy}
          timeseries={timeseries?.[strategy?.subStrategyId]?.data}
          isStrategyTimeseries={timeseries?.[strategy?.subStrategyId]?.isStrategyTimeseries}
          expanded={isExpanded}
          type={type}
          onItemClick={onItemClick}
        />
      ))}
    </Container>
  );
}

StrategyCurveHistoryGrid.defaultProps = {
  onItemClick: () => {},
  width: 0,
  height: 0,
  targetRef: { current: null },
};

StrategyCurveHistoryGrid.propTypes = {
  quartile: PropTypes.string,
  strategies: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default StrategyCurveHistoryGrid;
