import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconSortNumericAsc(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="m18.3046875 12.9121094c.859375 0 1.5410156.3203125 2.0449219.9609375.5039062.640625.7558594 1.4980469.7558594 2.5722656v.3984375l-.0046355.3029427c-.0432639 1.391007-.389375 2.4638542-1.0383333 3.2185417-.6953125.8085937-1.7324219 1.21875-3.1113281 1.2304687h-.1699219v-1.1777343h.2050781l.2460938-.0092286c.7207031-.0448242 1.2832031-.2438964 1.6875-.5972168.4492187-.3925781.7011719-.9990234.7558593-1.8193359-.4765624.5039063-1.0332031.7558594-1.6699218.7558594-.75 0-1.3476563-.2626953-1.7929688-.788086-.4453125-.5253906-.6679687-1.2158203-.6679687-2.071289 0-.5625.1132812-1.0722657.3398437-1.5292969s.5488282-.8125.9667969-1.0664062c.4179687-.2539063.9023437-.3808594 1.453125-.3808594zm-9.3046875-7.9121094v10.01h3l-4 3.99-4-3.99h3v-10.01zm9.2988281 9.0605469c-.4101562 0-.7373047.1689453-.9814453.5068359s-.3662109.7646484-.3662109 1.2802734c0 .5351563.1230469.9667969.3691406 1.2949219s.5742187.4921875.984375.4921875c.2851563 0 .5527344-.0820312.8027344-.2460937s.4433593-.390625.5800781-.6796875v-.5566406l-.0060425-.2300721c-.0281982-.5233764-.1550903-.9525451-.3806763-1.2875061-.2578124-.3828125-.5917968-.5742187-1.0019531-.5742187zm1.1953125-12.0605469v8.5605469h-1.4179687v-6.8496094l-2.0917969.71484375v-1.20117187l3.328125-1.22460938z" />
    </Svg>
  );
}
