import React from 'react';
import PropTypes from 'prop-types';
import { SmallCircle } from 'src/components';

function MyComponent({ fundManager, userManagerAnchorEl, handleUserManagerMarkerClick, setVisibleToYouTooltipOpen }) {
  return (
    <div
      css={`
        font-size: 20px;
        font-weight: 300;
        display: flex;
        align-items: center;
      `}
    >
      {fundManager.name}
      <div
        ref={userManagerAnchorEl}
        onMouseOver={() => handleUserManagerMarkerClick()}
        onMouseOut={() => setVisibleToYouTooltipOpen(false)}
      >
        <SmallCircle
          css={`
            margin-left: 10px;
            margin-bottom: 8px;
          `}
        />
      </div>
    </div>
  );
}

MyComponent.defaultProps = {};

MyComponent.propTypes = {
  children: PropTypes.any,
};

export default MyComponent;
