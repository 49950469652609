import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import { Button, ErrorMessage, NarrowViewportMessage } from 'src/components';
import routes from 'src/config/routes';
import Form from './Form';
import AuthLayout from 'src/components/templates/AuthLayout';
import * as auth from 'src/services/filter-api/authentication';
import { useUpdate as useUpdateUser } from 'src/services/filter-api/user';

const Section = styled.section`
  h3 {
    font-size: 24px;
  }
`;

const SmallLink = styled(Link)`
  font-size: 12px;
  color: #b1b1b1;
  text-decoration: none;
  display: block;
  text-align: right;
  margin: 6px 0;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.footer`
  font-size: 14px;
`;

function UserSignInPage() {
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const signIn = auth.useAuthenticate().mutateAsync;
  const updateUser = useUpdateUser().mutateAsync;

  async function handleSignIn(credentials) {
    try {
      setIsSubmitted(true);

      const { uid: userId } = await signIn(credentials);
      const lastLogin = new Date().toISOString();
      await updateUser({ userId, lastLogin });

      navigate(routes.MARKET_FILTER);
    } catch (e) {
      setIsSubmitted(false);
      setError(e);
    }
  }

  return (
    <AuthLayout>
      <AuthLayout.Card
        css={`
          max-width: 24rem;
        `}
      >
        <Section>
          <h3>Create a free account:</h3>
          <Button
            variant="contained"
            color="primary"
            $block
            onClick={() => navigate(routes.USER_SIGN_UP)}
            disabled={isSubmitted}
          >
            Create account
          </Button>
          <NarrowViewportMessage />
        </Section>

        <Section>
          <h3>Sign in:</h3>
          <Form onSubmit={handleSignIn} isSubmitted={isSubmitted} />

          <Footer>
            <SmallLink to={routes.USER_PASSWORD_RESET}>Forgot password</SmallLink>
            {error && (
              <div>
                <ErrorMessage>{error.message}</ErrorMessage>
              </div>
            )}
          </Footer>
        </Section>
      </AuthLayout.Card>
    </AuthLayout>
  );
}

export default UserSignInPage;
