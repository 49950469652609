import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';
import Radio from '@material-ui/core/Radio';
import Collapsible from 'react-collapsible';
import { Flexbox, TreeviewCheckbox, IconRightCaret, IconDownCaret } from '../..';

const iconCss = css`
  position: absolute;
  right: 0;
  top: -25px;
  color: #999;
  cursor: pointer;
`;

const Label = styled.label`
  white-space: nowrap;
`;

function FilterStrategySelector({ strategies, onClickNode, ...rest }) {
  const handleClickParentNode = strategy => {
    return function () {
      if (strategy.selected) return;

      strategies.forEach(parent => {
        onClickNode(parent, { selected: false });
        parent.children.forEach(child => {
          onClickNode(child, { selected: false });
        });
      });

      onClickNode(strategy, { selected: true });
      strategy.children.forEach(child => {
        onClickNode(child, { selected: true });
      });
    };
  };

  return (
    <div
      css={`
        overflow-y: auto;
      `}
      {...rest}
    >
      {strategies.map(strategy => (
        <div key={strategy.strategyId}>
          <Flexbox>
            <Label onClick={handleClickParentNode(strategy)}>
              <Radio
                color="primary"
                value={strategy.strategyId}
                checked={strategy.selected}
                css={`
                  padding: 2px;
                `}
              />
              <span
                css={`
                  color: #9b9b9b;
                  font-size: 13px;
                  cursor: pointer;
                  margin-left: 5px;
                `}
              >
                {strategy.strategyName}
              </span>
            </Label>
          </Flexbox>
          <Collapsible
            transitionTime={250}
            open={strategies.length === 1}
            easing="ease"
            trigger={
              <Flexbox
                css={`
                  position: relative;
                `}
              >
                {strategy.selected && (
                  <IconRightCaret
                    css={`
                      ${iconCss};
                    `}
                  />
                )}
              </Flexbox>
            }
            triggerWhenOpen={
              <Flexbox
                css={`
                  position: relative;
                `}
              >
                {strategy.selected && (
                  <IconDownCaret
                    css={`
                      ${iconCss};
                    `}
                  />
                )}
              </Flexbox>
            }
          >
            <Flexbox column>
              {strategy.selected &&
                strategy.children.map(subStrategy => (
                  <Label
                    key={subStrategy.strategyId}
                    css={`
                      margin-left: 10px;
                    `}
                  >
                    <TreeviewCheckbox
                      checked={subStrategy.selected || false}
                      onClick={e => {
                        e.stopPropagation();
                        onClickNode(subStrategy, {
                          selected: !subStrategy.selected,
                        });
                      }}
                    />
                    <span
                      css={`
                        color: #9b9b9b;
                        font-size: 13px;
                      `}
                    >
                      {subStrategy.strategyName}
                    </span>
                  </Label>
                ))}
            </Flexbox>
          </Collapsible>
        </div>
      ))}
    </div>
  );
}

FilterStrategySelector.defaultProps = {};

FilterStrategySelector.propTypes = {
  strategies: PropTypes.array.isRequired,
  onClickNode: PropTypes.func.isRequired,
};

export default FilterStrategySelector;
