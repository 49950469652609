/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchFundManagerFundsRaisingPending = createAction('FETCH_FUND_MANAGER_FUNDS_RAISING_PENDING');
const fetchFundManagerFundsRaisingError = createAction('FETCH_FUND_MANAGER_FUNDS_RAISING_ERROR');
const fetchFundManagerFundsRaisingSuccess = createAction('FETCH_FUND_MANAGER_FUNDS_RAISING_SUCCESS');

export function fetchFundManagerFundsRaising({ fundManagerId }) {
  return async function (dispatch) {
    dispatch(fetchFundManagerFundsRaisingPending({ fundManagerId }));
    try {
      const fundsRaising = await filterApi.getFundManagerFundsRaising({
        fundManagerId,
      });
      dispatch(fetchFundManagerFundsRaisingSuccess({ fundsRaising }));
    } catch (error) {
      dispatch(fetchFundManagerFundsRaisingError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  fundManagerId: null,
  all: null,
};

export default createReducer(initialState, {
  [fetchFundManagerFundsRaisingPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    state.fetching = true;
    state.fundManagerId = fundManagerId;
  },
  [fetchFundManagerFundsRaisingError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [fetchFundManagerFundsRaisingSuccess]: (state, action) => {
    const { fundsRaising } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.all = fundsRaising;
  },
});
