import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactToPrint from 'react-to-print';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ButtonLargeCopy, ButtonLargePrint } from 'src/components';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';

const Layout = styled.div`
  padding: 10px;
  display: flex;
  visibility: ${p => (p.$hidden ? 'hidden' : null)};
  flex-direction: column;

  button {
    margin-bottom: 20px;
  }
`;

function NewsArticleControls(props) {
  const { summary, printRef, clipboardText, className } = props;

  function handleAfterPrint() {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.newsPrint);
  }

  function handleCopy() {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.newsCopy);
  }

  if (!summary) return null;

  const hidden = !summary.fetched || summary.sentences.length < 1;

  return (
    <Layout className={className} $hidden={hidden}>
      {/* <ButtonLargeShare onClick={onShare} /> */}
      <ReactToPrint
        onAfterPrint={handleAfterPrint}
        trigger={() => <ButtonLargePrint />}
        content={() => printRef.current}
        pageStyle="color:#000;background:#fff;margin:20px"
      />

      <CopyToClipboard text={clipboardText} onCopy={handleCopy}>
        <ButtonLargeCopy />
      </CopyToClipboard>
    </Layout>
  );
}

NewsArticleControls.defaultProps = {
  onShare: () => {},
  clipboardText: '',
  printRef: null,
};

NewsArticleControls.propTypes = {
  onShare: PropTypes.func,
  clipboardText: PropTypes.string,
  printRef: PropTypes.object,
};

export default NewsArticleControls;
