import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { button } from 'src/config/colors';

const spinnerCss = css`
  display: block;
  width: 1.2em;
  height: 1.2em;
  animation: rotate 2s linear infinite;
  stroke-linecap: round;
  stroke-width: 5;
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);

  & .path {
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

function Spinner() {
  return (
    <>
      &nbsp;
      <svg css={spinnerCss} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" />
      </svg>
      &nbsp;
    </>
  );
}

const dangerButtonCss = css`
  color: ${button.danger.BACKGROUND_COLOR};
  background-color: transparent;

  &:active,
  &:hover,
  &:focus {
    color: ${button.danger.active.BACKGROUND_COLOR};
    background-color: transparent;
  }
`;

const dangerButtonContainedCss = css`
  color: ${button.danger.TEXT_COLOR};
  background-color: ${button.danger.BACKGROUND_COLOR};

  &:active,
  &:hover,
  &:focus {
    color: ${button.danger.active.TEXT_COLOR};
    background-color: ${button.danger.active.BACKGROUND_COLOR};
  }
`;

const Layout = styled(MuiButton)`
  text-transform: none;
  width: ${p => (p.$block ? '100%' : null)};
  font-size: inherit;

  ${p => p.$danger && (p.variant === 'contained' ? dangerButtonContainedCss : dangerButtonCss)};

  &.MuiButton-outlinedSizeSmall,
  &.MuiButton-containedSizeSmall {
    font-size: 0.875em;
    padding-left: 0.675em;
    padding-right: 0.675em;
  }

  .MuiButton-label {
    font-size: inherit;
    line-height: 1.8;
    display: grid;
    gap: 0.25em;
    grid-auto-flow: column dense;
    grid-auto-columns: auto;
    align-content: center;
    justify-content: center;
  }
`;

function Button({ $isLoading, children, color, ...rest }) {
  const content = $isLoading ? <Spinner /> : children;

  return (
    <Layout {...rest} color={color === 'danger' ? 'inherit' : color} $danger={color === 'danger'}>
      {content}
    </Layout>
  );
}

export default Button;
