import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usageTrackingChannel } from 'src/services';
import { AppPanel, IconScatterPlot, IconList, IconBarChart, IconLineChart, DashboardFunds } from 'src/components';

const VIEW_STATE = {
  collectionPrefix: 'funds/',
  instancePrefix: 'fund/',
  grid: `funds/grid`,
  list: `funds/list`,
  timeSeries: `fund/timeseries`,
  keyMetrics: `fund/keymetrics`,
  null: '',
};

function DashboardFundsPanel({ spanX, spanY, minHeight }) {
  const [view, setView] = useState(VIEW_STATE.grid);

  const isGridView = view === VIEW_STATE.grid;

  function viewGrid() {
    setView(VIEW_STATE.grid);
  }

  const isListView = view === VIEW_STATE.list;

  function viewList() {
    setView(VIEW_STATE.list);
  }

  const isKeyMetricsView = view === VIEW_STATE.keyMetrics;

  function viewKeyMetrics() {
    setView(VIEW_STATE.keyMetrics);
  }

  const isTimeSeriesView = view === VIEW_STATE.timeSeries;

  function viewTimeSeries() {
    setView(VIEW_STATE.timeSeries);
  }

  useEffect(
    function () {
      usageTrackingChannel.publish(`saved.following.${view}`);
    },
    [view]
  );

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title="Fund Portfolios"
      controls={
        <>
          {view.startsWith(VIEW_STATE.collectionPrefix) ? (
            <div>
              <AppPanel.Button startIcon={<IconScatterPlot />} selected={isGridView} onClick={viewGrid}>
                Plot
              </AppPanel.Button>
              <AppPanel.Button startIcon={<IconList />} selected={isListView} onClick={viewList}>
                List
              </AppPanel.Button>
            </div>
          ) : (
            <div>
              <AppPanel.Button startIcon={<IconBarChart />} selected={isKeyMetricsView} onClick={viewKeyMetrics}>
                Key&nbsp;Metrics
              </AppPanel.Button>
              <AppPanel.Button startIcon={<IconLineChart />} selected={isTimeSeriesView} onClick={viewTimeSeries}>
                Performance
              </AppPanel.Button>
            </div>
          )}
        </>
      }
      expandable
    >
      <DashboardFunds isGridView={isGridView} isListView={isListView} viewGrid={viewGrid} viewList={viewList} />
    </AppPanel>
  );
}

DashboardFundsPanel.defaultProps = {};

DashboardFundsPanel.propTypes = {
  children: PropTypes.any,
};

export default DashboardFundsPanel;
