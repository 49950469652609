import React from 'react';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';
import IconSortDesc from '../IconSortDesc';

const ButtonFiltersRoot = styled(ButtonRounded)`
  color: rgba(255, 255, 255, 0.65);
  background-color: #0d8bab;
  padding-right: 12px;
  &:hover {
    background-color: #096177;
  }
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    left: 2px;
  }
`;

const ButtonFilters = props => {
  return (
    <ButtonFiltersRoot startIcon={<IconSortDesc />} {...props}>
      Filters
    </ButtonFiltersRoot>
  );
};

ButtonFilters.defaultProps = {};

ButtonFilters.propTypes = {};

export default ButtonFilters;
