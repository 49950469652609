import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/app';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
// import TagManager from 'react-gtm-module';

Bugsnag.start({
  apiKey: '490338892dc4691e4b834d0f18d89266',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION || '0.0.0',
  releaseStage: process.env.REACT_APP_ENVIRONMENT,
  enabledReleaseStages: ['production', 'staging'],
  autoDetectErrors: false,
});

console.info('VERSION:', {
  app: process.env.REACT_APP_VERSION,
  react: React.version,
  env: process.env.NODE_ENV,
  firebase: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

// TagManager.initialize({
//   gtmId: process.env.REACT_APP_GTM_ID,
//   auth: process.env.REACT_APP_GTM_AUTH,
//   preview: process.env.REACT_APP_GTM_PREVIEW,
// });

function render(Component) {
  // 👇This may break in future React releases
  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,

    document.getElementById('root')
  );
}

render(App);

if (module.hot) {
  module.hot.accept('./components/app', () => {
    const NextApp = require('./components/app').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
