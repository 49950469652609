import React from 'react';
import { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Fade, Grow, Collapse } from '@material-ui/core';
import routes from 'src/config/routes';
import { useAllFunds } from 'src/hooks';
import MatchedList from 'src/components/molecules/MatchedList';
import {
  Section,
  SectionHeader,
  SectionRow,
  SectionTitle,
  ActionButton,
  ActionLink,
  TextField,
  ValidRowIcon,
} from '../Styled';
import MarketingBlurb from './MarketingBlurb';

const FundName = styled.span`
  color: #b1b1b1;
`;

const MatchedListHeader = styled.h3`
  margin: 0;
  font-size: 1em;
`;

const ViewLink = styled.a`
  color: #0d8bab;
  cursor: pointer;
`;

function FieldupdateFormName({ formState, updateForm, inTransit, onClickViewFund, isEditable }) {
  const [searchQuery, setSearchQuery] = useState(false);
  const [isUnique, setIsUnique] = useState(false);
  const [sectionIsValid, setSectionIsValid] = useState(false);
  const fundNameField = useRef();
  const allFunds = useAllFunds();

  const handleFundNameChange = useCallback(function (event) {
    const { value } = event.target;
    setSearchQuery(value);
  }, []);

  const handleFundNameFocus = useCallback(
    function () {
      setSearchQuery(formState.name);
    },
    [formState.name]
  );

  const handleMatchedFundsChange = useCallback(
    function (matches) {
      if (!matches) return;
      if (!searchQuery) return;

      const unique = matches.every(match => match.value.toUpperCase() !== searchQuery.toUpperCase().trim());

      setIsUnique(unique);
    },
    [searchQuery]
  );

  const handleUpdateFundName = useCallback(
    function () {
      setSearchQuery(false);
      updateForm({ field: 'name', value: searchQuery });
    },
    [searchQuery, updateForm]
  );

  const handleCancelUpdateFundName = useCallback(function () {
    setSearchQuery(false);
  }, []);

  useEffect(
    function () {
      const result = formState.errors.name === false && searchQuery === false;

      setSectionIsValid(result);
    },
    [formState.errors.name, searchQuery]
  );

  const isLocked = formState.name && searchQuery === false;

  const inputValue = (searchQuery !== false ? searchQuery : formState.name) || '';

  const showMarketingBlurb = !inTransit && !formState.name && !searchQuery;

  return (
    <Section>
      {inTransit ? (
        <SectionHeader gridColumns="auto auto 1fr">
          <SectionTitle data-step="1">Fund name</SectionTitle>
          <ActionLink disabled={true}>{inTransit}</ActionLink>
        </SectionHeader>
      ) : (
        <SectionHeader gridColumns="auto auto 1fr">
          <SectionTitle data-step="1">Fund name</SectionTitle>
          {isEditable && <ValidRowIcon $valid={sectionIsValid} />}
          <div>
            {isEditable && (
              <ActionLink disabled={!isLocked} onClick={() => fundNameField.current.focus()}>
                edit
              </ActionLink>
            )}
          </div>
        </SectionHeader>
      )}
      <SectionRow gridColumns="4fr auto auto">
        <TextField
          css={`
            .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
              border-color: transparent;
            }

            .MuiInputBase-input.Mui-disabled {
              color: #fff8;
            }

            input {
              padding-left: 14px;
            }
          `}
          inputRef={fundNameField}
          required
          placeholder="Fund name"
          onChange={handleFundNameChange}
          onFocus={handleFundNameFocus}
          locked={isLocked}
          value={inputValue}
          disabled={!isEditable}
        />

        <Fade in={Boolean(searchQuery)}>
          <ActionButton
            disabled={!isUnique || !searchQuery || searchQuery === formState.name}
            onClick={handleUpdateFundName}
          >
            {formState.name ? 'Update' : 'Add'}
          </ActionButton>
        </Fade>
        <Fade in={typeof searchQuery === 'string'}>
          <ActionLink onClick={handleCancelUpdateFundName}>cancel</ActionLink>
        </Fade>
      </SectionRow>
      <SectionRow gridColumns="1fr">
        <Collapse in={Boolean(searchQuery)}>
          <MatchedListHeader>Existing funds in database:</MatchedListHeader>
          <MatchedList
            css={`
              height: ${(5 * 32 + 10) / 18}em;
              overflow-y: auto;
              padding: 5px 0;
            `}
            items={(allFunds || []).map(item => ({
              id: item.fundId,
              value: item.name,
            }))}
            query={searchQuery}
            onChange={handleMatchedFundsChange}
            renderItem={item => {
              return (
                <div key={item.id}>
                  <FundName>{item.value}</FundName>{' '}
                  <ViewLink
                    onClick={() => {
                      onClickViewFund();
                      navigate(routes.FUND.replace(':fundId', item.id));
                    }}
                  >
                    view »
                  </ViewLink>
                </div>
              );
            }}
            matchItem={item => item.name.toLowerCase()}
            matchLimit={5}
          />
        </Collapse>
      </SectionRow>
      <Collapse in={showMarketingBlurb}>
        <Grow in={showMarketingBlurb}>
          <div>
            <MarketingBlurb />
          </div>
        </Grow>
      </Collapse>
    </Section>
  );
}

FieldupdateFormName.defaultProps = {};

FieldupdateFormName.propTypes = {
  children: PropTypes.any,
};

export default FieldupdateFormName;
