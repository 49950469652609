import React from 'react';
import styled, { css } from 'styled-components';
import { lg } from 'src/config/layout';
import { AppPanel } from 'src/components';

const Content = styled.div`
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &:after {
    content: ${p => p.isBusy && `'${p.isBusy}'`};
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

function GridSpace({
  title,
  className,
  isBusy,
  expandedWidth,
  expandedHeight,
  isExpandable,
  children,
  onClickDownload,
  isDownloadable,
  controls,
}) {
  return (
    <AppPanel
      // spanX="full"
      // spanY="half"
      title={title}
      expandable={isExpandable}
      expandedWidth={expandedWidth}
      expandedHeight={expandedHeight}
      downloadable={isDownloadable}
      onClickDownload={onClickDownload}
      // maxHeight={100}
      css={`
        background-color: #1c1c1c;

        @media (max-width: ${lg.BREAKPOINT - 1}px) {
          min-height: auto !important;
          min-height: 200px !important;
        }

        @media (min-width: ${lg.BREAKPOINT}px) {
          grid-column-end: span 1;
          grid-row-end: span 6;
        }
        /* max-height: 300px !important; */
      `}
      adaptiveFrameCss={css`
        max-width: 200%;
        max-height: 200%;
      `}
      panelContentCss={css`
        padding: 0;
      `}
      controlBarCss={css`
        background-color: inherit;
        color: #bbb;
        font-size: 0.875em;
      `}
      controls={controls}
    >
      <Content className={className} isBusy={isBusy}>
        <div
          css={`
            opacity: ${isBusy && 0.25};
            height: 100%;
          `}
        >
          {children}
        </div>
      </Content>
    </AppPanel>
  );
}

GridSpace.defaultProps = {
  isExpandable: true,
};

GridSpace.propTypes = {};

export default GridSpace;
