import { select, format, scaleLinear, extent } from 'd3';

const fmtPercentage = format('+.1%');
const colorRange = ['#222D19', '#6bdb15'];

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function draw({ id, data, mouseEventRefs }) {
  const colorScale = scaleLinear()
    .range(colorRange)
    .domain(extent(data, d => d.delta));

  const opacityScale = scaleLinear()
    .range([0.35, 1])
    .domain(extent(data, d => d.delta));

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  // kick off the drawing
  select(`#${id}`)
    .selectAll('.rectangle')
    .data(data)
    .join('div')

    .attr('class', 'rectangle')
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .style('background-color', d => colorScale(d.delta))

    // Draw each text container
    .selectAll('.text-layer')
    .data(d => [d])
    .join('div')
    .attr('class', 'text-layer')

    // Draw each individual text item
    .selectAll('.text')
    .data(d => [
      {
        className: 'sub-strategy',
        value: d.subStrategy,
        delta: d.delta,
      },
      { className: 'strategy', value: d.strategy, delta: d.delta },
      {
        className: 'value',
        value: fmtPercentage(d.delta),
        delta: d.delta,
      },
    ])
    .join('span')
    .attr('class', d => `text ${d.className}`)
    .text(d => d.value)
    .style('opacity', d => opacityScale(d.delta));
}

export default draw;
