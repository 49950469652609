import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';
import { COLORS } from './constants';

const MARGIN = { top: 20, right: 30, bottom: 30, left: 50 };

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  flex: 1;
  position: relative;
  .tick line {
    color: #2e2e2e;
  }

  .tick text {
    fill: #808080;
    font-size: 10px;
    transform: translate(-10px, 5px) rotate(-45deg);

    @media (min-width: 1600px) {
      transform: unset;
    }
  }

  .x-axis-label,
  .y-axis-label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
  }

  .bar {
    cursor: pointer;
    transition: opacity 300ms ease;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }
  }

  .domain {
    opacity: 0;
  }

  .legend-item text {
    text-transform: uppercase;
    font-size: 10px;
    fill: #808080;
  }
`;

const Svg = styled.svg`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

const Legend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 5px;
`;

const LegendItem = styled.li`
  margin: 3px 5px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    background-color: ${p => p.$color};
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 5px;
    margin-left: 2px;
  }
`;

function FundsInMarket({
  data,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  ...rest
}) {
  const { current: id } = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });
  const [legendKeys, setLegendKeys] = useState([]);

  useEffect(
    function () {
      const keys = data
        .map(item => Object.keys(item))
        .flat()
        .filter(item => item !== 'year');
      const uniqueKeys = [...new Set(keys)];
      setLegendKeys(uniqueKeys);
    },
    [data]
  );

  useEffect(
    function () {
      draw({
        id,
        data,
        size: { width, height },
        margin: MARGIN,
        mouseEventRefs,
      });
    },
    [id, data, width, height, mouseEventRefs]
  );

  return (
    <Layout>
      <Container ref={targetRef} {...rest}>
        <Svg id={id} style={{ width, height }} />
      </Container>
      <Legend>
        {legendKeys.map((key, index) => {
          return (
            <LegendItem key={key} $color={COLORS[index]}>
              {key}
            </LegendItem>
          );
        })}
      </Legend>
    </Layout>
  );
}

FundsInMarket.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

FundsInMarket.propTypes = {
  data: PropTypes.array.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(FundsInMarket);
