import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { TooltipContent, TooltipArrowUp } from 'src/components';
import { FundPreview } from 'src/components';

const StyledPopper = styled(Popper)`
  &[x-placement*='top'] .fund-performance-arrow {
    top: unset;
    bottom: 0;
    transform: translateY(100%) rotate(180deg);
  }
`;

function FundPerformanceTooltip({ fund, open, anchorEl, calcs, index, onClickAway, onClose }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <StyledPopper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: '.fund-performance-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <div
              css={`
                position: relative;
              `}
            >
              <TooltipArrowUp className="fund-performance-arrow" marginLeft="2px" />
              <TooltipContent>
                <FundPreview fund={fund} onClose={onClose} pmeCalcs={calcs} pmeIndex={index} />
              </TooltipContent>
            </div>
          </Grow>
        )}
      </StyledPopper>
    </ClickAwayListener>
  );
}
FundPerformanceTooltip.defaultProps = {
  fund: null,
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewFund: () => {},
};

FundPerformanceTooltip.propTypes = {
  fund: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
  onViewFund: PropTypes.func,
};

export default FundPerformanceTooltip;
