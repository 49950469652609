import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${11 / 16}rem;
`;

const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* color: #1c1c1c; */
`;

const Row = styled.div`
  display: flex;
  height: 26px;
  align-items: center;
  padding: 0 10px;

  &:nth-child(odd) {
    background: #1c1c1c;
  }

  & > * {
    flex: 1;
  }

  & > *:first-child {
    flex: 4;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  font-size: ${10 / 11}em;

  ${Row}:nth-child(odd) {
    background: unset;
  }
`;

const Cell = styled.div``;

const Text = styled.div`
  width: 80%;
  background-color: currentColor;
  height: 1em;
  margin: 0.25ex 0;
  opacity: 0.3;

  @keyframes placeholderTableTextPulse {
    0% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.1;
    }
  }
  animation: placeholderTableTextPulse 1s ease alternate infinite;
`;

function Rows({ n = 5 }) {
  return new Array(n).fill(0).map((_, i) => (
    <Row key={i}>
      <Cell>
        <Text />
      </Cell>
      <Cell>
        <Text />
      </Cell>
      <Cell>
        <Text />
      </Cell>
      <Cell>
        <Text />
      </Cell>
      <Cell>
        <Text />
      </Cell>
    </Row>
  ));
}

function PlaceholderTable() {
  return (
    <Layout>
      <Header>
        <Rows n={1} />
      </Header>
      <Table>
        <Rows n={5} />
      </Table>
    </Layout>
  );
}

export default PlaceholderTable;
