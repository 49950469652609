/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';

export const setFundId = createAction('SET_LAST_SELECTED_FUND');
export const setFundManagerId = createAction('SET_LAST_SELECTED_FUND_MANAGER');

const initialState = {
  fundId: null,
  fundManagerId: null,
};

export default createReducer(initialState, {
  [setFundId]: (state, action) => {
    const { fundId } = action.payload;
    state.fundId = fundId;
  },
  [setFundManagerId]: (state, action) => {
    const { fundManagerId } = action.payload;
    state.fundManagerId = fundManagerId;
  },
});
