import React from 'react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import * as calc from 'src/calculators';
import { useStrategyTimeseriesQueries } from 'src/hooks';
import { CashflowDataVisualization } from 'src/components';
import VariantSelectItem from './Item';

const { VARIANTS } = CashflowDataVisualization.CONSTANTS;

const Layout = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: ${md.BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const Blank = styled.div`
  background-color: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '☒';
    font-size: 3em;
    opacity: 0.1;
  }
`;

function VariantSelectItems({ strategyId, onChange, selectedOption }) {
  const queries = useStrategyTimeseriesQueries([
    { strategyId, quartile: 'top' },
    { strategyId },
    { strategyId, quartile: 'bottom' },
  ]);

  const allNetCashflows = useMemo(() => {
    return queries
      .map(query => {
        if (!query.data) return [];
        return query.data.series;
      })
      .flat()
      .map(calc.netCashflow)
      .filter(Number.isFinite);
  }, [queries]);

  return queries.map((query, i) => {
    if (query.isLoading) {
      return (
        <VariantSelectItem
          key={i}
          title={query.status}
          onClick={() => onChange()}
          forecastTimeseries={[]}
          isLoading={true}
        />
      );
    }

    if (!query.data) {
      return <Blank key={i} />;
    }

    const { series, quartile } = query.data;

    const variant = {
      top: VARIANTS.TOP,
      bottom: VARIANTS.BOTTOM,
      all: VARIANTS.ALL,
    }[quartile || 'all'];

    return (
      <VariantSelectItem
        key={variant}
        title={variant}
        onClick={() => onChange(variant)}
        isSelected={selectedOption === variant}
        forecastTimeseries={series}
        normalizeYAxisData={allNetCashflows}
      />
    );
  });
}

function VariantSelect({ strategyId, options, selectedOption, onChange, className, itemsClassName }) {
  return (
    <Layout className={className} $columnCount={options.length}>
      <VariantSelectItems
        className={itemsClassName}
        options={options}
        strategyId={strategyId}
        selectedOption={selectedOption}
        onChange={onChange}
      />
    </Layout>
  );
}

VariantSelect.defaultProps = {
  options: [VARIANTS.ALL, VARIANTS.TOP, VARIANTS.BOTTOM],
  onChange: value => console.info('TODO, implement onChange', { value }),
};

VariantSelect.propTypes = {
  strategyId: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default VariantSelect;
