import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchNewsContentPending = createAction('FETCH_NEWS_CONTENT_PENDING');
const fetchNewsContentError = createAction('FETCH_NEWS_CONTENT_ERROR');
const fetchNewsContentSuccess = createAction('FETCH_NEWS_CONTENT_SUCCESS');

export function fetchNewsContent({ articleId }) {
  return async function (dispatch, getState) {
    try {
      const existing = getState().news.content[articleId];

      if (existing && (existing.fetched || existing.fetching)) return;
      dispatch(fetchNewsContentPending({ articleId }));
      const content = await filterApi.getNewsContent({ articleId });
      dispatch(
        fetchNewsContentSuccess({
          content,
          articleId,
        })
      );
    } catch (error) {
      dispatch(fetchNewsContentError({ articleId, error: error.message }));
    }
  };
}

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [fetchNewsContentPending]: (state, action) => {
    const { articleId } = action.payload;
    const content = state.byId[articleId] || {};
    content.fetching = true;
    content.error = false;
    state.byId[articleId] = content;
  },
  [fetchNewsContentError]: (state, action) => {
    const { error, articleId } = action.payload;
    const existing = state.byId[articleId];
    existing.fetching = false;
    existing.error = error;
    console.error(error);
  },
  [fetchNewsContentSuccess]: (state, action) => {
    const { articleId, content } = action.payload;
    const existing = state.byId[articleId];
    existing.fetching = false;
    existing.fetched = true;
    existing.error = null;
    state.byId[articleId] = { ...existing, ...content, articleId };
  },
});
