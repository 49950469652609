import React from 'react';
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { md } from 'src/config/layout';
import {
  useFundHierarchy,
  useLayoutActions,
  useHasSubscription,
  useStrategiesFilter,
  useSizesFilter,
  useRegionsFilter,
} from 'src/hooks';
import { AppPanel, Link, Box, MarketSizeBreakdown, FundsTable, SubscriptionPaywall } from 'src/components';

const colors = ['#0D8BAB', '#2194B2', '#2C9AB6', '#3AA0BA', '#4AA8C0', '#59AFC5', '#6adcf9'];
const DISPLAY = {
  treemap: 'treemap',
  list: 'list',
};

const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 10px 0;
  position: relative;

  @media (min-width: ${md.BREAKPOINT}px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 0 20px;
  }
`;

const Title = styled.span`
  font-size: 18px;
  opacity: 0.8;
`;

const BackLink = styled(Link).attrs(() => ({
  component: 'button',
  color: 'primary',
  size: 'small',
}))`
  @media (min-width: ${md.BREAKPOINT}px) {
    position: absolute;
    left: 15px;
  }
`;

function MarketSizeBreakdownVisualization() {
  const { isExpanded } = useContext(AppPanel.Context);
  const hasSubscription = useHasSubscription();
  const [display, setDisplay] = useState(DISPLAY.treemap);
  const [title, setTitle] = useState(null);
  const [strategiesFilter, setStrategiesFilter] = useStrategiesFilter();
  const [sizesFilter, setSizesFilter] = useSizesFilter();
  const [regionsFilter, setRegionsFilter] = useRegionsFilter();
  const hierarchy = useFundHierarchy();
  const { expandPanel } = useLayoutActions();

  useEffect(() => {
    if (!hasSubscription) return;
    if (display !== DISPLAY.list) return;

    expandPanel();
  }, [display, expandPanel, hasSubscription]);

  function getTableTitle(d) {
    const segments = [];
    let node = d;
    while (node.parent) {
      const label = node.data.label || node.data.strategyName || node.data.regionName;
      segments.push(label);
      node = node.parent;
    }

    return segments.reverse().join(' · ');
  }

  function getCurrentFilters(d) {
    switch (d.depth) {
      case 0:
        return {};
      case 1:
      case 2:
        return {
          strategyId: d.data.strategyId,
        };

      case 3:
        return {
          strategyId: d.parent.data.strategyId,
          regionId: d.data.regionId,
        };
      case 4:
        return {
          strategyId: d.parent.parent.data.strategyId,
          regionId: d.parent.data.regionId,
          sizeId: d.data.sizeId,
        };
      default:
        console.warn('unhandled case statement', d.depth);
        return {};
    }
  }

  function handleItemClick(_node, d) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterStrategyBreakdownDrilledDown);

    setTitle(getTableTitle(d));

    if ((d.data.children || []).length > 0 && !d.viewList) return;

    const filters = getCurrentFilters(d);

    setStrategiesFilter(state =>
      state.map(item => {
        return {
          ...item,
          selected: filters.strategyId ? [item.strategyId, item.parentId].includes(filters.strategyId) : true,
        };
      })
    );

    setRegionsFilter(state => {
      return state.map(item => {
        return {
          ...item,
          selected: filters.regionId ? [item.regionId, item.parentId].includes(filters.regionId) : true,
        };
      });
    });

    setSizesFilter(state => {
      return state.map(item => {
        return {
          ...item,
          selected: filters.sizeId ? item.sizeId === filters.sizeId : true,
        };
      });
    });

    setDisplay(DISPLAY.list);

    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterStrategyBreakdownList);
  }

  return (
    <>
      {display === DISPLAY.treemap && (
        <div
          css={`
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
          `}
        >
          {hierarchy && (
            <MarketSizeBreakdown data={hierarchy} colors={colors} expanded={isExpanded} onItemClick={handleItemClick} />
          )}
        </div>
      )}
      {display === DISPLAY.list && (
        <SubscriptionPaywall>
          <Header>
            <BackLink onClick={() => setDisplay(DISPLAY.treemap)}>‹ BACK</BackLink>
            <Title>{title}</Title>
          </Header>

          <FundsTable strategies={strategiesFilter} sizes={sizesFilter} regions={regionsFilter} />
        </SubscriptionPaywall>
      )}
    </>
  );
}

MarketSizeBreakdownVisualization.defaultProps = {};

MarketSizeBreakdownVisualization.propTypes = {};

export default MarketSizeBreakdownVisualization;
