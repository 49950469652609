import { isFinite } from 'src/lib/lodash';

/**
 * Examples:
 * validateNumber('dpi', options)(value);
 * validate('dpi', number(options), presence(options))(value);
 */

/**
 * Examples:
 * validate(value, number(options), presence(options));
 */

function presence({ length } = {}) {
  return function (value) {
    if (!value) {
      return `cannot be blank`;
    }

    if (length && value.length < length) {
      return `must be a least ${length} characters`;
    }
  };
}

function number({ min, max } = {}) {
  return function (value) {
    const v = parseFloat(value);

    if (!isFinite(v)) {
      return `must be a number`;
    }

    if (min && v < min) {
      return `must be at least ${min}`;
    }

    if (max && v > max) {
      return `must be at most ${max}`;
    }
  };
}

function validate(...validators) {
  return function (value) {
    return validators.map(fn => fn(value)).filter(msg => Boolean(msg))[0];
  };
}

// export { validateNumber, validatePresence };
export { validate, number, presence };
