import { useRef } from 'react';

function useDebounceCallback(fn, wait = 0) {
  const timer = useRef(null);

  function reset() {
    clearTimeout(timer.current);
    timer.current = null;
  }

  return function () {
    if (timer.current) reset();

    timer.current = setTimeout(() => {
      fn(...arguments);
      reset();
    }, wait);
  };
}

export default useDebounceCallback;
