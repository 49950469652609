import { useState, useEffect } from 'react';
import useFundManagerEmployeesByYear from './useFundManagerEmployeesByYear';

export default function useFundManagerJoinersAndLeavers({ fundManagerId, minYear = null }) {
  const employeesByYear = useFundManagerEmployeesByYear({
    fundManagerId,
  });
  const [joinersAndLeavers, setJoinersAndLeavers] = useState(null);

  useEffect(() => {
    if (fundManagerId && employeesByYear) {
      const joinersAndLeaversCollector = {};
      for (let year = Math.max(employeesByYear.minYear, minYear); year <= employeesByYear.maxYear; year++) {
        const prev =
          year === employeesByYear.minYear ? employeesByYear[year].employees : employeesByYear[year - 1].employees;
        const curr = employeesByYear[year].employees;
        const next =
          year === employeesByYear.maxYear ? employeesByYear[year].employees : employeesByYear[year + 1].employees;
        const joiners = curr.filter(x => !prev.includes(x));
        const leavers = curr.filter(x => !next.includes(x));
        joinersAndLeaversCollector[year] = {
          joiners,
          leavers,
          all: employeesByYear[year].employees,
        };
      }
      setJoinersAndLeavers(joinersAndLeaversCollector);
    }
  }, [employeesByYear, fundManagerId, minYear]);

  return joinersAndLeavers;
}
