import { createReducer, createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { uniq } from 'src/lib/lodash';
import { filterApi } from 'src/services';
import { auth } from 'src/services/filter-api/authentication';
import { arrayToObject } from 'src/utils';
import { getSizeId } from 'src/lookup/sizes';
import { fundUtil } from '@fundfilter/core/src/utils';

const fetchFundsPending = createAction('FETCH_FUNDS_PENDING');
const fetchFundsError = createAction('FETCH_FUNDS_ERROR');
export const fetchFundsSuccess = createAction('FETCH_FUNDS_SUCCESS');
const setUserFundPending = createAction('ADD_USER_FUND_PENDING');
const setUserFundError = createAction('ADD_USER_FUND_ERROR');
const setUserFundSuccess = createAction('ADD_USER_FUND_SUCCESS');
const deleteUserFundPending = createAction('DELETE_USER_FUND_PENDING');
const deleteUserFundError = createAction('DELETE_USER_FUND_ERROR');
const deleteUserFundSuccess = createAction('DELETE_USER_FUND_SUCCESS');
export const setFundsPeers = createAction('SET_FUNDS_PEERS');
const setFundsPeersTvpiRank = createAction('SET_FUNDS_PEERS_TVPI_RANK');
const setFundPeers = createAction('SET_FUND_PEERS');
const setFundByFundManager = createAction('SET_FUNDS_BY_FUND_MANAGER');
const setFundByStrategy = createAction('SET_FUNDS_BY_STRATEGY');
const setFundBySubStrategy = createAction('SET_FUNDS_BY_SUB_STRATEGY');
const setFundBySize = createAction('SET_FUNDS_BY_SIZE');
const setFundByLaunchYear = createAction('SET_FUNDS_BY_LAUNCH_YEAR');

function sortFundsAlpha(a, b) {
  return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
}

function getPeerIdsByFund(fund, funds) {
  const fundsArray = funds.allIds.map(id => funds.byId[id]);
  return fundsArray.filter(peer => fundUtil.isPeer(fund, peer)).map(f => f.fundId);
}

export function fetchFunds() {
  return async function (dispatch, getState) {
    const { fetching, fetched } = getState().fund.all;
    const user = auth.currentUser;
    if (!user) return;
    if (fetching || fetched) return;
    dispatch(fetchFundsPending());
    try {
      console.time('fetching funds');
      const [funds, userFunds] = await Promise.all([
        filterApi.getFundList(),
        filterApi.getUserFunds({
          userId: user.uid,
        }),
      ]);

      const allFunds = funds.concat(userFunds);
      const peerIdsByFundId = allFunds.reduce((acc, fund) => {
        return {
          ...acc,
          [fund.fundId]: fund.peerIds,
        };
      }, {});

      dispatch(setFundsPeers({ peerIdsByFundId }));
      dispatch(fetchFundsSuccess({ funds: allFunds }));

      console.timeEnd('fetching funds');
    } catch (error) {
      dispatch(fetchFundsError({ error: error.message }));
    }
  };
}

export function setUserFund({ fund }) {
  return async function (dispatch, getState) {
    const { fundId } = fund;
    const user = auth.currentUser;
    if (!user) return;

    try {
      dispatch(setUserFundPending({ fundId }));
      const { isNew } = fund;
      const updated = await filterApi.setUserFund({
        userId: user.uid,
        ...fund,
      });
      dispatch(setUserFundSuccess({ fund: updated, isNew }));
      const peers = getPeerIdsByFund(fund, getState().fund.all);
      dispatch(setFundPeers({ fundId: fund.fundId, peers }));
    } catch (error) {
      dispatch(setUserFundError({ error: error.message, fundId }));
    }
  };
}

export function deleteUserFund({ fundId }) {
  return async function (dispatch) {
    const user = auth.currentUser;
    if (!user) return;
    try {
      dispatch(deleteUserFundPending({ fundId }));
      await filterApi.deleteUserFund({ userId: user.uid, fundId });
      dispatch(deleteUserFundSuccess({ fundId }));
    } catch (error) {
      dispatch(deleteUserFundError({ error: error.message, fundId }));
    }
  };
}

// TODO: break peers out into its own slice
const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  byId: null,
  allIds: null,
  peerIdsByFundId: null,
};

export default createReducer(initialState, {
  [fetchFundsPending]: state => {
    state.fetching = true;
  },
  [fetchFundsError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [fetchFundsSuccess]: (state, action) => {
    const { funds } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    funds.sort(sortFundsAlpha);
    state.byId = arrayToObject('fundId')(
      funds.map(fund => ({
        ...fund,
        mainRegionGroupId: fund.mainRegionId || fund.mainRegionParentId,
        otherRegionGroupId: fund.otherRegionId || fund.otherRegionParentId,
        sizeId: getSizeId(fund.size),
        fetched: true,
      }))
    );
    state.allIds = funds.map(fund => fund.fundId);
  },
  [setFundsPeers]: (state, action) => {
    const { peerIdsByFundId } = action.payload;
    const peers = Object.keys(peerIdsByFundId).reduce((acc, fundId) => {
      acc[fundId] = uniq(peerIdsByFundId[fundId]);
      return acc;
    }, {});

    state.peerIdsByFundId = peers;
  },

  [setFundsPeersTvpiRank]: (state, action) => {
    const { tvpiRanksByFundId } = action.payload;
    state.tvpiRanksByFundId = tvpiRanksByFundId;
  },
  [setFundPeers]: (state, action) => {
    const { peers, fundId } = action.payload;
    state.peerIdsByFundId[fundId] = peers;
  },
  [setFundByFundManager]: (state, action) => {
    const { fundIdsByFundManagerId } = action.payload;
    state.fundIdsByFundManagerId = fundIdsByFundManagerId;
  },
  [setFundByStrategy]: (state, action) => {
    const { fundIdsByStrategy } = action.payload;
    state.fundIdsByStrategy = fundIdsByStrategy;
  },
  [setFundBySubStrategy]: (state, action) => {
    const { fundIdsBySubStrategy } = action.payload;
    state.fundIdsBySubStrategy = fundIdsBySubStrategy;
  },
  [setFundBySize]: (state, action) => {
    const { fundIdsBySize } = action.payload;
    state.fundIdsBySize = fundIdsBySize;
  },
  [setFundByLaunchYear]: (state, action) => {
    const { fundIdsByLaunchYear } = action.payload;
    state.fundIdsByLaunchYear = fundIdsByLaunchYear;
  },
  [setUserFundPending]: (state, action) => {
    const { fundId } = action.payload;
    const fund = state.byId[fundId] || {};
    fund.updating = true;
    state.byId[fundId] = fund;
  },
  [setUserFundError]: (state, action) => {
    const { fundId, error } = action.payload;
    const fund = state.byId[fundId] || {};
    fund.error = error;
    fund.updating = false;
    fund.updated = false;
    state.byId[fundId] = fund;
    console.error(error);
  },
  [setUserFundSuccess]: (state, action) => {
    const { fund } = action.payload;
    fund.error = null;
    fund.updating = false;
    fund.updated = true;
    fund.fetching = false;
    fund.fetched = true;

    state.byId[fund.fundId] = fund;
    const funds = Object.values(state.byId);
    funds.sort(sortFundsAlpha);

    state.byId = arrayToObject('fundId')(
      funds.map(f => ({
        ...f,
        mainRegionGroupId: f.mainRegionId || f.mainRegionParentId,
        otherRegionGroupId: f.otherRegionId || f.otherRegionParentId,
        sizeId: getSizeId(f.size),
        fetched: true,
      }))
    );
    state.allIds = funds.map(f => f.fundId);
  },
  [deleteUserFundPending]: (state, action) => {
    const { fundId } = action.payload;
    const fund = state.byId[fundId] || {};
    fund.deleting = true;
    state.byId[fundId] = fund;
  },
  [deleteUserFundError]: (state, action) => {
    const { fundId, error } = action.payload;
    const fund = state.byId[fundId] || {};
    fund.error = error;
    fund.deleting = false;
    fund.deleted = false;
    state.byId[fundId] = fund;
    console.error(error);
  },
  [deleteUserFundSuccess]: (state, action) => {
    const { fundId } = action.payload;
    state.allIds = state.allIds.filter(id => id !== fundId);
    state.byId = Object.values(state.byId).reduce((acc, fund) => {
      if (fund.fundId !== fundId) {
        acc[fund.fundId] = fund;
      }
      return acc;
    }, {});

    const peersByFundId = Object.keys(state.peerIdsByFundId).reduce((acc, f) => {
      acc[f] = state.peerIdsByFundId[f].filter(p => p !== fundId);
      return acc;
    }, {});

    state.peerIdsByFundId = peersByFundId;
  },
});

export const getUserFunds = createSelector([state => state.fund.all], funds => {
  if (!funds.fetched) return null;
  const res = funds.allIds.map(id => funds.byId[id]).filter(fund => !!fund.userId);
  return res;
});

export const getUserFundIds = createSelector([state => state.fund.all], funds => {
  if (!funds.fetched) return null;
  return funds.allIds.filter(id => id.startsWith('u_'));
});

export const getFundsByFundManager = createSelector([state => state.fund.all], funds => {
  if (funds.fetched) {
    // console.time('calculating FundsByFundManager');
    const res = funds.allIds
      .map(id => funds.byId[id])
      .reduce((accumulator, fund) => {
        const { fundManagerId } = fund;
        if (!accumulator[fundManagerId]) {
          accumulator[fundManagerId] = [];
        }
        accumulator[fundManagerId].push(fund);
        return accumulator;
      }, {});
    // console.timeEnd('calculating FundsByFundManager');
    return res;
  }
  return null;
});

export const getFundsByStrategy = createSelector([state => state.fund.all], funds => {
  if (!funds.fetched) return;
  console.time('calculating FundsByStrategy');
  const res = funds.allIds
    .map(id => funds.byId[id])
    .reduce((accumulator, fund) => {
      const { strategyId } = fund;
      if (!accumulator[strategyId]) {
        accumulator[strategyId] = [];
      }
      accumulator[strategyId].push(fund);
      return accumulator;
    }, {});
  console.timeEnd('calculating FundsByStrategy');
  return res;
});

export const getFundIdsByStrategy = createSelector([state => state.fund.all], funds => {
  if (!funds.fetched) return null;

  return funds.allIds.reduce((acc, id) => {
    const { strategyId } = funds.byId[id];
    const prev = acc[strategyId] || [];

    return { ...acc, [strategyId]: [...new Set([...prev, id])] };
  }, {});
});

export const getFundsBySubStrategy = createSelector([state => state.fund.all], funds => {
  if (funds.fetched) {
    const res = funds.allIds
      .map(id => funds.byId[id])
      .reduce((accumulator, fund) => {
        const { subStrategyId } = fund;
        if (!accumulator[subStrategyId]) {
          accumulator[subStrategyId] = [];
        }
        accumulator[subStrategyId].push(fund);
        return accumulator;
      }, {});
    return res;
  }
  return null;
});

export const getFundsBySize = createSelector([state => state.fund.all], funds => {
  if (funds.fetched) {
    console.time('calculating FundsBySize');
    const res = funds.allIds
      .map(id => funds.byId[id])
      .reduce((accumulator, fund) => {
        const { sizeId } = fund;
        if (!accumulator[sizeId]) {
          accumulator[sizeId] = [];
        }
        accumulator[sizeId].push(fund);
        return accumulator;
      }, {});
    console.timeEnd('calculating FundsBySize');
    return res;
  }
  return null;
});
