import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';
import Legend from './Legend';
import { Box } from 'src/components';
import { md } from 'src/config/layout';

const Layout = styled(Box)`
  position: relative;

  display: flex;
  flex-direction: column;
  padding-bottom: ${p => (p.$expanded ? 16 : 0)}px;
`;

const Title = styled.h3`
  display: ${p => (p.$expanded ? 'initial' : 'none')};

  color: #808080;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
`;

const SvgContainer = styled.div`
  flex: 1;
  min-height: 20px;
  position: relative;
`;

const LegendContainer = styled.div`
  flex: 0;
  height: 20px;
`;

const expandedSvgStyles = css`
  .x-axis .tick text {
    @media (min-width: ${md.BREAKPOINT}px) {
      transform: none;
    }
  }
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;

  cursor: ${p => (p.$expanded ? 'auto' : 'pointer')};

  .tick line {
    color: #2e2e2e;
  }

  .title text {
    fill: #808080;
    font-size: 18px;
  }

  .tick text {
    fill: #808080;
    font-size: 10px;
  }

  .x-axis .tick text {
    transform: translate(-10px, 3px) rotate(-45deg);
  }

  .x-axis-label,
  .y-axis-label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
  }

  .bar {
    cursor: pointer;
    transition: opacity 300ms ease;
  }

  .domain {
    opacity: 0;
  }

  ${p => p.$expanded && expandedSvgStyles}
`;

function FundsRaisedOverTime({
  data,
  expanded,
  onClick,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  ...rest
}) {
  const { current: id } = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    draw({
      id,
      data,
      expanded,
      size: { width, height },
      margin: {
        top: 20,
        right: 30,
        bottom: 30,
        left: 80,
      },
      mouseEventRefs,
    });
  }, [id, data, expanded, width, height, mouseEventRefs]);

  return (
    <Layout {...rest} $expanded={expanded}>
      <Title $expanded={expanded}>Funds Raised Over Time</Title>
      <SvgContainer ref={targetRef}>
        <Svg id={id} onClick={onClick} $expanded={expanded} width="0" height="0" />
      </SvgContainer>
      <LegendContainer>
        <Legend data={data} />
      </LegendContainer>
    </Layout>
  );
}

FundsRaisedOverTime.defaultProps = {
  expanded: false,
  onClick: () => {},
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

FundsRaisedOverTime.propTypes = {
  data: PropTypes.array.isRequired,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default withResizeDetector(FundsRaisedOverTime);
