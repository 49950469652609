import { db } from 'src/config/firebase';

const dbFundsInMarket = db.collection('fundsInMarket');
const dbFundsInMarketCounts = db.collection('fundsInMarketCounts');
const dbFundManagerFundsInMarketSize = db.collection('fundManagerFundsInMarketSize');
const dbFundRaising = db.collection('fundRaising');

async function getFundsInMarketCounts() {
  return (await dbFundsInMarketCounts.orderBy('year', 'asc').get()).docs.map(item => item.data());
}

async function getFundsInMarket({ year, regionId, strategyId }) {
  if (strategyId) {
    return (
      await dbFundsInMarket.where('vintage', '==', year).where('parentStrategyId1', '==', strategyId).get()
    ).docs.map(item => item.data());
  }
  if (regionId) {
    return (await dbFundsInMarket.where('vintage', '==', year).where('regionId1', '==', regionId).get()).docs.map(
      item => item.data()
    );
  }
  throw new Error('Either strategyId or regionId are required.');
}

async function getFundManagerFundsInMarketSize({ fundManagerId }) {
  return (
    await dbFundManagerFundsInMarketSize.where('fundManagerId', '==', fundManagerId).orderBy('year', 'asc').get()
  ).docs.map(item => item.data());
}

async function getFundManagerFundsInMarket({ fundManagerId }) {
  return (await dbFundsInMarket.where('fundManagerId', '==', fundManagerId).get()).docs.map(item => item.data());
}

async function getFundManagerFundsRaising({ fundManagerId }) {
  return (await dbFundRaising.where('fundManagerId', '==', fundManagerId).orderBy('name').get()).docs.map(item =>
    item.data()
  );
}
//#endregion

export { getFundsInMarketCounts };
export { getFundsInMarket };
export { getFundManagerFundsInMarketSize };
export { getFundManagerFundsInMarket };
export { getFundManagerFundsRaising };
