import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconDownCaret(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M27.9,40c0-1.7,1.4-3,3-3c0.8,0,1.6,0.3,2.1,0.9l17,17.7l17-17.7c1.2-1.2,3.1-1.2,4.2,0    c1.1,1.1,1.2,2.9,0.1,4.1L52.2,62c-1.1,1.2-3,1.2-4.2,0.1L47.9,62L28.7,42C28.2,41.5,27.9,40.8,27.9,40z" />
    </Svg>
  );
}
