import React from 'react';
import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import { FinancialMetricSelection, FundPerformance, CheckboxSmall } from 'src/components';

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
`;

const Title = styled.h3`
  font-size: 16px;
  opacity: 0.8;
  font-weight: 300;
`;

const CheckboxLabel = styled(FormControlLabel)`
  margin-left: 5px;

  .MuiFormControlLabel-label {
    font-size: 12px;
  }
`;

const TouchPointerHelp = styled.span`
  display: none;

  @media (hover: none) and (pointer: coarse) {
    display: flex;
    justify-content: space-around;
    transform: translateY(-50%);
    opacity: 0.5;
    font-size: 13px;
  }
`;

function FundPerformancePlotView({
  className,
  enableShowFullScale,
  showFullScale,
  handleFullScaleChange,
  selectedStrategyName,
  benchmark,
  handleMetricChange,
  scatterplotFunds,
  extents,
  handleItemClick,
}) {
  if (!scatterplotFunds) return null;
  if (!extents) return null;

  return (
    <Layout className={className}>
      <Header>
        <CheckboxLabel
          data-cy="show-full-scale-checkbox"
          control={<CheckboxSmall checked={!enableShowFullScale || showFullScale} />}
          label={`Show full ${benchmark.key.toUpperCase()} scale`}
          disabled={!enableShowFullScale}
          onChange={handleFullScaleChange}
          style={{
            opacity: enableShowFullScale ? 1 : 0.5,
          }}
        />
        <Title>{selectedStrategyName}</Title>
        <FinancialMetricSelection financialMetric={benchmark} onMetricChange={handleMetricChange} hidePme />
      </Header>
      <FundPerformance
        data={scatterplotFunds}
        metric={benchmark}
        extents={extents}
        onItemClick={handleItemClick}
        onItemTouchStart={handleItemClick}
      />
      <TouchPointerHelp>
        <span>Swipe to zoom in.</span>
        <span>Double-tap to zoom out.</span>
      </TouchPointerHelp>
    </Layout>
  );
}

FundPerformancePlotView.defaultProps = {};

FundPerformancePlotView.propTypes = {};

export default FundPerformancePlotView;
