import { useQuery, useQueryClient } from 'react-query';
import { filterApi } from 'src/services';

async function getPaymentMethod() {
  const customer = await filterApi.stripeGetCustomer();
  const paymentMethod = customer.invoice_settings?.default_payment_method;
  return paymentMethod
    ? {
        id: paymentMethod.id,
        card: {
          last4: paymentMethod.card.last4,
          brand: paymentMethod.card.brand,
          country: paymentMethod.card.country,
          expMonth: paymentMethod.card.exp_month,
          expYear: paymentMethod.card.exp_year,
          validationFailed: Object.values(paymentMethod.card?.checks).some(value => value === 'fail'),
        },
      }
    : null;
}

const QUERY_KEY = ['stripeCustomer', 'defaultPaymentMethod'];

function useDefaultPaymentMethod() {
  const queryClient = useQueryClient();
  const paymentMethod = useQuery(QUERY_KEY, getPaymentMethod, {
    refetchOnWindowFocus: false,
  });

  async function detachPaymentMethod() {
    if (!paymentMethod) return;

    await filterApi.stripeDetachPaymentMethod({
      paymentMethodId: paymentMethod.data.id,
    });
  }

  return {
    ...paymentMethod,
    delete: detachPaymentMethod,
    invalidate: () => queryClient.invalidateQueries(QUERY_KEY),
  };
}

export default useDefaultPaymentMethod;
