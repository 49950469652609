const partial = require('lodash/partial');
const curry = require('lodash/curry');

// Pipe an input through n functions sequentially, from right to left
function compose(...fns) {
  return function (x) {
    return fns.reduceRight(function (val, fn) {
      return fn(val);
    }, x);
  };
}

// Pipe an input through n functions sequentially, from left to right
function pipe(...fns) {
  return function (x) {
    return fns.reduce(function (val, fn) {
      return fn(val);
    }, x);
  };
}

const fp = {
  pipe,
  compose,
  partial,
  curry,
};

export { pipe, compose, partial, curry };
export default fp;
