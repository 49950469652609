import { parseISO, addMonths, addQuarters, subtractDays } from 'src/utils/date';

const HISTORY_LENGTH = (new Date().getFullYear() - 1999) * 4;

function incrementDateByQuarters(date) {
  return function (n) {
    if (typeof date === 'string') date = parseISO(date);

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    const nextMonth = addMonths(1)(startOfMonth);
    const lastDayOfMonth = subtractDays(1)(nextMonth);
    const nextQuarter = addQuarters(n)(lastDayOfMonth);

    return nextQuarter;
  };
}

function createHistoryTimeseries({ startDate, financialHistory, length = HISTORY_LENGTH }) {
  if (!startDate) return;
  if (!financialHistory) return;
  if (financialHistory.length < 1) return;

  return new Array(length).fill().map((_, i) => {
    const date = incrementDateByQuarters(startDate)(i);
    const dateStamp = date.toISOString().substring(0, 7);
    const historyRecord = financialHistory.find(x => {
      return x.date.startsWith(dateStamp);
    });

    const { rvpi = null, calledPct = null, tvpi = null, dpi = null } = historyRecord || {};

    return {
      date,
      calledPct,
      dpi,
      rvpi,
      tvpi,
    };
  });
}

function useFinanicalHistoryTimeseries({ vintage, financialHistory }) {
  const latestStartDate = new Date(Date.UTC(vintage + 1, 2, 31)).toISOString();

  const startOfFund = financialHistory.find(x => {
    return x.date <= latestStartDate;
  });

  const startOfFundDate = startOfFund ? startOfFund.date : new Date(Date.UTC(vintage, 8, 30)).toISOString();

  const series = createHistoryTimeseries({
    startDate: startOfFundDate,
    financialHistory,
  })
    .map(s => ({
      ...s,
      date: s.date.toISOString(),
    }))
    .map(s => {
      if ([s.calledPct, s.dpi, s.rvpi].some(Number.isFinite)) return s;
      return null;
    });

  return series;
}

export default useFinanicalHistoryTimeseries;
