import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStrategyTimeseriesQuery } from 'src/hooks';
import { CashflowDataVisualization, StrategyCurveDefaultIndicator } from 'src/components';

const QUARTILES = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const Container = styled.div`
  flex: 1;
  position: relative;
`;

function useStrategyTimeseries(strategyId, quartile) {
  const timeseriesQuery = useStrategyTimeseriesQuery({
    strategyId,
    quartile: QUARTILES[quartile],
  });

  return useMemo(() => {
    if (!timeseriesQuery.data) return [];
    return timeseriesQuery.data.series;
  }, [timeseriesQuery.data]);
}

function StrategyCurveHistoryTimeSeries({ strategyId, subStrategyId, type, colors, quartile }) {
  const strategyTimeseries = useStrategyTimeseries(strategyId, quartile);
  const subStrategyTimeseries = useStrategyTimeseries(subStrategyId, quartile);

  const isStrategyTimeseries = useMemo(
    () => strategyTimeseries?.length > 0 && subStrategyTimeseries.find(s => s.n < 10 && s.q <= 40),
    [strategyTimeseries, subStrategyTimeseries]
  );
  const timeseries = useMemo(
    () => (isStrategyTimeseries ? strategyTimeseries : subStrategyTimeseries),
    [isStrategyTimeseries, strategyTimeseries, subStrategyTimeseries]
  );

  return (
    <Container>
      {isStrategyTimeseries && <StrategyCurveDefaultIndicator x={28} y={54} />}
      <CashflowDataVisualization
        type={type}
        xAxisTicks={1}
        xAxisFormatter={(_, i) => `Year ${i + 1}`}
        yAxisFormatter={d => `${d * 100}%`}
        colors={colors}
        forecastTimeseries={timeseries}
      />
    </Container>
  );
}

StrategyCurveHistoryTimeSeries.defaultProps = {
  colors: {
    forecastContributionBarColor: '#4da8c2',
    forecastLineColor: '#4da8c2',
    failedBreakevenSubjectLineColor: '#ff7700',
    failedBreakevenForecastLineColor: '#ff7700',
    milestoneAccentColor: '#ffffff',
  },
};

StrategyCurveHistoryTimeSeries.propTypes = {
  strategyId: PropTypes.string.isRequired,
  subStrategyId: PropTypes.string.isRequired,
  quartile: PropTypes.string.isRequired,
};

export default StrategyCurveHistoryTimeSeries;
