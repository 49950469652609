import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { ExpandLessOutlined as IconCollapse } from '@material-ui/icons';
import { Button, IconLockFilled as IconLock, IconPencil as IconEdit } from 'src/components';
import EditControls from './ItemEditControls';

const ItemRow = styled.li`
  display: grid;
  gap: 0.5em;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  cursor: pointer;
  padding: 0.285714286em 1em;
  &:hover {
    background-color: #ffffff11;
  }
`;

const RowButton = styled(Button)`
  font-size: 1em;
  min-width: unset;
  border-radius: 0;
  padding: 2px 0.5em;

  .MuiSvgIcon-root {
    font-size: inherit;
  }

  background-color: #ffffff22;

  &:hover {
    background-color: ${p => p.$hover};
    border-color: ${p => p.$hover};
  }

  & + & {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const EditButton = styled(RowButton)`
  font-size: 1.25em;
  padding-right: 0.25em;
  background-color: transparent;
`;

const Label = styled.span`
  line-height: 1.2;
  font-weight: ${p => -p.$isSelected && 'bold'};
`;

const EditInput = styled(TextField)`
  .MuiInputBase-root {
    font-size: inherit;

    input {
      padding: 0.5625em 1em;
    }
  }
`;

function PortfolioListItem({ value, label, icon, selectedIcon, selected, onSelect, onDelete, onSave, locked }) {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(label);

  function handleClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (isEditing) return;
    onSelect({ portfolioId: value, name: label });
  }

  function handleToggleEdit(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (locked) return;

    setIsEditing(!isEditing);
  }

  async function handleClickSave(evt) {
    evt.preventDefault();
    if (locked) return;

    await onSave({ portfolioId: value, name: newName });
    setIsEditing(false);
  }

  async function handleClickDelete(evt) {
    evt.preventDefault();
    if (locked) return;

    await onDelete({ portfolioId: value });
    setIsEditing(false);
  }

  return (
    <ItemRow onClick={handleClick}>
      {selected && selectedIcon ? selectedIcon : icon}
      {isEditing ? (
        <EditInput
          size="small"
          value={newName}
          onClick={evt => evt.stopPropagation()}
          onChange={evt => setNewName(evt.target.value)}
        />
      ) : (
        <Label $isSelected={selected}>{label}</Label>
      )}

      <EditButton disabled={locked} onClick={handleToggleEdit}>
        {locked ? <IconLock /> : isEditing ? <IconCollapse /> : <IconEdit />}
      </EditButton>
      {isEditing && (
        <EditControls saveDisabled={!newName} onClickDelete={handleClickDelete} onClickSave={handleClickSave} />
      )}
    </ItemRow>
  );
}

PortfolioListItem.defaultProps = {
  locked: false,
};

PortfolioListItem.propTypes = {
  bookmark: PropTypes.object,
  portfolio: PropTypes.object,
  locked: PropTypes.bool,
  onChangeChecked: PropTypes.func,
  onDeletePortoflio: PropTypes.func,
};

export default PortfolioListItem;
