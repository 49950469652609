import React from 'react';
import { useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Menu, MenuItem } from '@material-ui/core';
import { useSearch, useBrowserFullScreen, useCurrentUser } from 'src/hooks';
import { usageTrackingChannel, USAGE_TRACKING_TOPICS } from 'src/services';
import { Box, SearchField, ButtonUserAvatar, ButtonFullScreen, ErrorBoundary } from 'src/components';
import { NODE_TYPE } from './constants.js';
import ListboxComponent from './ListboxComponent';
import { xl } from 'src/config/layout';
import routes from 'src/config/routes';
import { useDeauthenticate } from 'src/services/filter-api/authentication';
import Badge from 'src/components/atoms/Badge';
import { localDb } from 'src/config/dexie';

const Root = styled(Box)`
  display: flex;
  font-size: 13px;

  @media (min-height: ${xl.V_BREAKPOINT}px) {
    font-size: 16px;
    height: 100%;
    align-items: center;
  }
`;

const SearchSection = styled.div`
  flex: 1 1 75%;
  max-width: 500px;
`;

const ControlSection = styled.div`
  color: #7d7d7d;
  flex: 1 1 25%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 2em;

  & > * {
    margin-left: 10px;
  }
`;

const ListItem = styled.div`
  &[data-type='${NODE_TYPE.fund}'] {
    padding-left: 1em;
  }

  &[data-type='${NODE_TYPE.fundManager}'] {
    font-weight: bold;

    &::after {
      content: 'Fund Manager';
      color: #0bb9e7;
      font-size: 0.75em;
      font-weight: normal;
      text-decoration: underline;
      margin-left: 0.5rem;
    }
  }
`;

const useAutocompleteStyles = makeStyles({
  root: {
    fontSize: 'inherit',
  },
  inputRoot: {
    fontSize: 'inherit',
  },
});

function Header(props) {
  const autocompleteStyles = useAutocompleteStyles();
  const [inputValue, setInputValue] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [canRefreshData, setCanRefreshData] = useState(true);
  const [fullScreen, toggleFullScreen] = useBrowserFullScreen();
  const signOut = useDeauthenticate().mutate;
  const search = useSearch();
  const currentUser = useCurrentUser();

  useEffect(() => {
    setInputValue('');
  }, []);

  function handleAvatarClick(e) {
    setMenuAnchorEl(e.currentTarget);
  }

  async function handleAccountSettingsClick() {
    setMenuAnchorEl(null);
    return navigate(routes.ACCOUNT);
  }

  async function handleRefreshDataClick() {
    setCanRefreshData(false);
    await localDb.funds.clear();
    await localDb.fundManagerNamesList.clear();
    window.location.reload();
  }

  async function handleSignOutClick() {
    signOut();
    setMenuAnchorEl(null);
  }

  function handleChange(_event, item) {
    if (!item) return;
    const isFund = Boolean(item.fundId);
    if (isFund) {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.searchFund, {
        name: item.name,
        fundId: item.fundId,
        fundManagerId: item.fundManagerId,
      });
      navigate(routes.FUND.replace(':fundId', item.fundId));
    } else {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.searchFundManager, {
        name: item.name,
        fundManagerId: item.fundManagerId,
      });
      navigate(routes.FUND_MANAGER.replace(':fundManagerId', item.fundManagerId));
    }
  }

  function handleInputChange(_event, value) {
    setInputValue(value);
  }

  const searchOptions = search.options || [];
  const isLoading = searchOptions.length < 1;

  return (
    <Root {...props}>
      <SearchSection>
        <ErrorBoundary message="Search encountered an error." allowRetry={true}>
          <Autocomplete
            id="application-search-bar"
            classes={{ ...autocompleteStyles }}
            disableListWrap
            filterOptions={search.onFilter}
            blurOnSelect
            clearOnEscape
            loading={isLoading}
            disabled={isLoading}
            ListboxComponent={forwardRef((listboxProps, listboxRef) => (
              <ListboxComponent ref={listboxRef} {...listboxProps} onScroll={search.onScroll} />
            ))}
            options={searchOptions}
            getOptionLabel={option => option.label}
            inputValue={inputValue}
            renderInput={params => <SearchField isLoading={isLoading} {...params} />}
            noOptionsText="No results found"
            renderOption={option => {
              return <ListItem data-type={option?.type}>{option?.label}</ListItem>;
            }}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onClose={search.onReset}
          />
        </ErrorBoundary>
      </SearchSection>
      <ControlSection>
        {typeof toggleFullScreen === 'function' && (
          <ButtonFullScreen variant={fullScreen ? 'off' : 'on'} onClick={toggleFullScreen} aria-label="fullscreen" />
        )}
        <Badge color="secondary" variant="dot" invisible={!currentUser?.isAnonymous}>
          <ButtonUserAvatar aria-haspopup="true" onClick={handleAvatarClick} aria-label="account" />
        </Badge>
        <Menu anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)}>
          <MenuItem onClick={handleAccountSettingsClick}>
            <Badge color="secondary" variant="dot" invisible={!currentUser?.isAnonymous}>
              Account Settings
            </Badge>
          </MenuItem>
          <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
          <MenuItem disabled={!canRefreshData} onClick={handleRefreshDataClick}>
            {canRefreshData ? <>Refresh Data</> : <>Refreshing...</>}
          </MenuItem>
        </Menu>
      </ControlSection>
    </Root>
  );
}

Header.defaultProps = {};
Header.propTypes = {};

export default Header;
