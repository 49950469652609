const publicIndexes = {
  barclays_aggregate: { name: 'barclays_aggregate' },
  dow_jones_europe_real_estate: { name: 'dow_jones_europe_real_estate' },
  dow_jones_infra: { name: 'dow_jones_infra' },
  dow_jones_us_real_estate: { name: 'dow_jones_us_real_estate' },
  nasdaq_composite: { name: 'nasdaq_composite' },
  russell_3000: { name: 'russell_3000' },
  sp: { name: 'sp' },
  sp_500: { name: 'sp_500' },
  sp_bmi: { name: 'sp_bmi' },
  sp_global_infra: { name: 'sp_global_infra' },
  sp_global_leveraged: { name: 'sp_global_leveraged' },
  sp_global_nr: { name: 'sp_global_nr' },
  sp_gsci: { name: 'sp_gsci' },
  sp_lsta_leverage: { name: 'sp_lsta_leverage' },
  wilshire_5000: { name: 'wilshire_5000' },
};

export { publicIndexes };
