import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconRightCaret(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M40,72.2c-1.7,0-3-1.4-3-3c0-0.8,0.3-1.6,0.9-2.1l17.7-17L37.9,33c-1.2-1.2-1.2-3.1,0-4.2    c1.1-1.1,2.9-1.2,4.1-0.1l20,19.2c1.2,1.1,1.2,3,0.1,4.2c0,0-0.1,0.1-0.1,0.1l-20,19.2C41.5,71.8,40.8,72.1,40,72.2z" />
    </Svg>
  );
}
