import { useState, useEffect } from 'react';
import { json } from 'd3';

export default function useCountries() {
  const [countries, setCountries] = useState(null);
  useEffect(() => {
    json('/assets/json/countries.json').then(res => {
      setCountries(res);
    });
  }, []);
  return countries;
}
