import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import NewsArticleSkeleton from './news-article-skeleton';

const List = styled.ul`
  margin: 0;
  padding-right: 10px;
  padding-left: 20px;
  height: 100%;
  overflow-y: auto;

  @media (min-width: ${md.BREAKPOINT}px) {
    padding-left: 40px;
  }
`;

const ListItem = styled.li`
  padding: 10px 0;
  color: #9a9a9a;
`;

function NewsArticleSummary(props) {
  const { summary, summaryLength, className } = props;

  if (!summary) return null;

  if (!summary.fetched) {
    return <NewsArticleSkeleton length={summaryLength} />;
  }

  return (
    <List className={className}>
      {summary.sentences.map((sentence, index) => (
        <ListItem key={index}>{sentence}</ListItem>
      ))}
      {summary.sentences.length === 0 && <ListItem>Summarization did not return any results</ListItem>}
    </List>
  );
}

NewsArticleSummary.defaultProps = {};

NewsArticleSummary.propTypes = {
  summary: PropTypes.object,
  summaryLength: PropTypes.number.isRequired,
};

export default NewsArticleSummary;
