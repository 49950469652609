import React from 'react';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';

const Button = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
  }
`;

const ButtonManagers = props => {
  return <Button {...props}>Managers</Button>;
};

ButtonManagers.defaultProps = {};

ButtonManagers.propTypes = {};

export default ButtonManagers;
