import { createReducer, createAction } from '@reduxjs/toolkit';
import { arrayToObject } from '../utils';
import { getSizeId } from '../lookup/sizes';

const actionPending = createAction('FETCH_FUND_CASHFLOWS_PENDING');
const actionError = createAction('FETCH_FUND_CASHFLOWS_ERROR');
const actionSuccess = createAction('FETCH_FUND_CASHFLOWS_SUCCESS');

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  byId: null,
  allIds: null,
};

export function fetchFundCashflows() {
  return async function (dispatch, getState) {
    const { fetching, fetched } = getState().fund.all;
    if (fetching || fetched) return;
    dispatch(actionPending());
    try {
      console.time('fetching fund cashflows');

      // filterApi.getFundCashflows()

      dispatch(actionSuccess({ funds: [] }));

      console.timeEnd('fetching fund cashflows');
    } catch (error) {
      dispatch(actionError({ error: error.message }));
    }
  };
}

export default createReducer(initialState, {
  [actionPending]: state => {
    state.fetching = true;
  },
  [actionError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [actionSuccess]: (state, action) => {
    const { funds } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;

    state.byId = arrayToObject('fundId')(
      funds.map(fund => ({
        ...fund,
        mainRegionGroupId: fund.mainRegionId || fund.mainRegionParentId,
        otherRegionGroupId: fund.otherRegionId || fund.otherRegionParentId,
        sizeId: getSizeId(fund.size),
        fetched: true,
      }))
    );
    state.allIds = funds.map(fund => fund.fundId);
  },
});
