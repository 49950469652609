import React from 'react';
import { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import { lg } from 'src/config/layout';
import { useViewportSize } from 'src/hooks';
import { PrimaryNav, Header } from 'src/components';
import * as Styled from './styled';
import Context from './context';

function disabledSetExpandedId() {
  console.info('Panel expansion disabled on small devices');
}

function AppLayoutComponent({ width, height, targetRef, ticker, children }) {
  const isRenderingTicker = Boolean(ticker);
  const [expandedId, setExpandedId] = useState(null);
  const [expansionDisabled, setExpansionDisabled] = useState(null);
  const gridRef = useRef(null);
  const { width: viewportWidth, height: viewportHeight } = useViewportSize();

  useEffect(
    function () {
      setExpansionDisabled(viewportWidth < lg.BREAKPOINT);
    },
    [viewportWidth]
  );

  const contentWidth = useMemo(() => {
    if (!width) return null;
    if (!gridRef.current) return null;
    const rect = gridRef.current.getBoundingClientRect();
    return rect.width;
  }, [width]);

  const contentHeight = useMemo(() => {
    if (!height) return null;
    if (!gridRef.current) return null;
    const rect = gridRef.current.getBoundingClientRect();
    return rect.height;
  }, [height]);

  return (
    <Context.Provider
      value={{
        expandedId,
        expansionDisabled,
        setExpandedId: expansionDisabled ? disabledSetExpandedId : setExpandedId,
        gridRef,
        isRenderingTicker,
        width,
        height,
        contentWidth,
        contentHeight,
      }}
    >
      <Styled.Layout ref={targetRef}>
        <Styled.GlobalLayoutStyles />
        <Styled.PrimaryNavArea $ticker={isRenderingTicker}>
          <PrimaryNav />
        </Styled.PrimaryNavArea>
        <Styled.SecondaryNavArea>
          <Header />
        </Styled.SecondaryNavArea>
        <Styled.TickerArea $hide={!isRenderingTicker}>{ticker}</Styled.TickerArea>
        <Styled.ContentArea $ticker={isRenderingTicker}>
          <Styled.Grid
            ref={gridRef}
            $isExpanded={Boolean(expandedId)}
            $ticker={isRenderingTicker}
            $viewportHeight={viewportHeight}
          >
            {children}
          </Styled.Grid>
        </Styled.ContentArea>
      </Styled.Layout>
    </Context.Provider>
  );
}

const AppLayout = withResizeDetector(AppLayoutComponent, {
  refreshMode: 'throttle',
  refreshRate: 500,
});

AppLayout.Context = Context;

AppLayout.defaultProps = {};

AppLayout.propTypes = {
  ticker: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default AppLayout;
