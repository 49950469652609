import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SmallCircle, StrategyCurveDefaultTooltip } from 'src/components';

const Container = styled.div`
  position: absolute;
  top: ${p => p.y}px;
  right: ${p => p.x}px;
  z-index: 1;
`;

const StrategyCurveDefaultCircle = styled(SmallCircle)`
  background-color: #3f89a8;
`;

function StrategyCurveDefaultIndicator({ x, y }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleTooltipOpen = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleTooltipClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <Container x={x} y={y}>
      <StrategyCurveDefaultTooltip open={open} anchorEl={anchorEl} onClose={handleTooltipClose}>
        <StrategyCurveDefaultCircle
          onClick={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
          onMouseEnter={handleTooltipOpen}
        />
      </StrategyCurveDefaultTooltip>
    </Container>
  );
}

StrategyCurveDefaultIndicator.defaultProps = {
  x: 0,
  y: 0,
};

StrategyCurveDefaultIndicator.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
};

export default StrategyCurveDefaultIndicator;
