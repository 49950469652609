import { useQueryClient, useMutation } from 'react-query';
import { queryKeys } from './queries';
import * as client from './client';

function useCreate() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async data => await client.create(data);
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useCreateDefault() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ userId }) => {
    const exists = await client.getDefault(userId);
    if (exists) return exists;
    return await client.createDefault(userId);
  };

  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useUpdate() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ portfolioId, ...data }) => await client.update(portfolioId, data);
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useAddBookmark() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ portfolioId, ...data }) => {
    await client.addBookmark(portfolioId, data);
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onError(err) {
      console.error(err);
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useUpdateBookmark() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ portfolioId, bookmarkId, ...data }) => {
    await client.updateBookmark(portfolioId, bookmarkId, data);
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useUpdateBookmarks() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ portfolioId, bookmarkIds, ...data }) => {
    await client.updateBookmarks(portfolioId, bookmarkIds, data);
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useRemoveBookmark() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ portfolioId, fundId, targetFundId }) => {
    await client.removeBookmark(portfolioId, {
      fundId,
      targetFundId,
    });
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onError(err) {
      console.error(err);
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useDelete() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async portfolioId => await client.destroy(portfolioId);
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export {
  useCreate,
  useCreateDefault,
  useUpdate,
  useDelete,
  useAddBookmark,
  useUpdateBookmark,
  useRemoveBookmark,
  useUpdateBookmarks,
};
