import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'src/components';

function FormCancelButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
}

FormCancelButton.defaultProps = {
  children: 'Cancel',
};

FormCancelButton.propTypes = {
  children: PropTypes.any,
};

export default FormCancelButton;
