import { formatDateComplete } from 'src/utils/date';

export function getClipboardText(article, summary) {
  let text = `${article.title}\n${article.source} - ${formatDateComplete(article.publishedAt)}\n\n`;

  if (article && summary && summary.fetched) {
    text += `- ${summary.sentences.join('\n\n- ')}\n\n`;
  }

  text += `GENERATED BY THE FUNDFILTER NEWS ENGINE\n${window.location.origin}`;
  return text;
}
