import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import IconExpand from '../IconExpand';

const Button = styled(IconButton)`
  color: #7d7d7d;
  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    width: 0.625em;
    height: 0.625em;
  }
`;

const ButtonExpandIcon = props => {
  return (
    <Button size="small" {...props}>
      <IconExpand />
    </Button>
  );
};

ButtonExpandIcon.defaultProps = {};

ButtonExpandIcon.propTypes = {};

export default ButtonExpandIcon;
