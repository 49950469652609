import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagerEmployees } from '../store/employees';

export default function useFundManagerEmployees({ fundManagerId }) {
  const dispatch = useDispatch();
  const fundManagerEmployees = useSelector(state => state.fundManager.allEmployees);
  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    if (fundManagerId && !fundManagerEmployees.byId[fundManagerId]) {
      dispatch(fetchFundManagerEmployees({ fundManagerId }));
    }
  }, [dispatch, fundManagerEmployees.byId, fundManagerId]);

  useEffect(() => {
    if (fundManagerId && fundManagerEmployees.byId[fundManagerId] && fundManagerEmployees.byId[fundManagerId].fetched) {
      setEmployees(fundManagerEmployees.byId[fundManagerId]);
    }
  }, [fundManagerEmployees.byId, fundManagerId]);

  return employees;
}
