import React from 'react';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Folder as IconFolderClosed,
  FolderOpen as IconFolderOpen,
  CheckBox as IconCheckBoxChecked,
  CheckBoxOutlineBlank as IconCheckBoxUnchecked,
} from '@material-ui/icons';
import { Button, TextField } from 'src/components';
import ListItem from './ListItem';

const Layout = styled.div``;

const List = styled.ul`
  max-height: ${2.285714286 * 5.5}em;
  overflow-y: auto;
  list-style: none;
  padding: 0;
`;

const Footer = styled.footer`
  padding: 0.75em 1em 0.75em 3em;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const SearchFilterInput = styled(TextField)`
  .MuiInputBase-root {
    font-size: inherit;

    input {
      padding: 0.5625em 1em;
    }
  }
`;

function PortfolioListComponent({
  items,
  selectedItemIds,
  multiSelect,
  onCreateItem,
  onDeleteItem,
  onSaveItem,
  onSelectItem,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  const searchIsUnique = useMemo(() => {
    if (!searchQuery) return false;
    if (!items) return true;
    return items.every(item => item.name.toLowerCase() !== searchQuery.toLowerCase());
  }, [items, searchQuery]);

  const filteredItems = useMemo(() => {
    if (!items) return [];
    if (!searchQuery) return items;

    return items.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [items, searchQuery]);

  return (
    <Layout>
      <List>
        {filteredItems.map(item => {
          const { name, portfolioId } = item;
          const isSelected = selectedItemIds.includes(portfolioId);
          const isLocked = item.default;

          return (
            <ListItem
              key={portfolioId}
              value={portfolioId}
              label={name}
              selected={isSelected}
              locked={isLocked}
              icon={multiSelect ? <IconCheckBoxUnchecked /> : <IconFolderOpen />}
              selectedIcon={multiSelect ? <IconCheckBoxChecked /> : <IconFolderClosed />}
              onSelect={() => onSelectItem(portfolioId)}
              onSave={data => onSaveItem(portfolioId, data)}
              onDelete={() => onDeleteItem(portfolioId)}
            />
          );
        })}
      </List>

      <Footer>
        <SearchFilterInput
          value={searchQuery}
          onChange={evt => setSearchQuery(evt.target.value)}
          placeholder="Search or Create Portfolio"
        />
        <Button
          disabled={!searchIsUnique}
          onClick={async function () {
            if (!searchIsUnique) return;
            await onCreateItem({ name: searchQuery });
            setSearchQuery('');
          }}
        >
          Create
        </Button>
      </Footer>
    </Layout>
  );
}

PortfolioListComponent.defaultProps = {
  onCreateItem: console.info,
  onDeleteItem: console.info,
  onSaveItem: console.info,
  onSelectItem: console.info,
};

PortfolioListComponent.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItemId: PropTypes.string,
  onCreateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onSaveItem: PropTypes.func,
  onSelectItem: PropTypes.func,
};

export default PortfolioListComponent;
