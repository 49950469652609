import React from 'react';
import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import { Box, PeerGroupBar, FinancialMetricSelection, CheckboxSmall, IncompleteDataMessage } from 'src/components';

const CheckboxLabel = styled(FormControlLabel)`
  font-size: 10px;
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: #949494;
  }
`;

function BarChartSection({
  className,
  financialMetric,
  setFinancialMetric,
  fund,
  localPeers,
  selectedPeerIds,
  showOutliers,
  setShowOutliers,
}) {
  return (
    <Box display="column" padding="0 24px" height="100%" className={className}>
      <Box display="flex" flexDirection="row" alignItems="center" height="26px" justifyContent="space-between">
        <FinancialMetricSelection
          financialMetric={financialMetric}
          onMetricChange={setFinancialMetric}
          disableTvpi={fund.tvpi == null}
          disableIrr={fund.irr == null}
          hidePme
        />
        <div>{fund[financialMetric.key] === null && <IncompleteDataMessage entity="fund" />}</div>
        <CheckboxLabel
          control={<CheckboxSmall checked={showOutliers} />}
          label="Show Outliers"
          onChange={event => setShowOutliers(event.target.checked)}
        />
      </Box>

      <PeerGroupBar
        width="100%"
        height="calc(100% - 18px)"
        data={localPeers}
        selectedPeers={selectedPeerIds}
        metric={financialMetric}
        showOutliers={showOutliers}
      />
    </Box>
  );
}

BarChartSection.defaultProps = {};

BarChartSection.propTypes = {};

export default BarChartSection;
