import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconCheckboxIndeterminate(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M83,18 L83,83 L18,83 L18,18 L83,18 Z M78,23 L23,23 L23,78 L78,78 L78,23 Z M66,47 L65.9993414,51.999952 L35,52.0006592 L35,47.0044421 L66,47 Z" />
    </Svg>
  );
}
