import { useMemo, useEffect, useState, useCallback } from 'react';
import { useStrategies } from 'src/hooks';

const useStrategiesFilterDefaultOptions = {
  coreAttributeStrategyIds: null,
  defaultSelectedParentStrategyIds: null,
  defaultSelectedStrategyIds: null,
  whitelistParentStrategyIds: null,
  whitelistStrategyIds: null,
};

/**
 * @param defaultSelectedIds: memoized:Array[<strategyId>]
 * @example useStrategiesFilter(useMemo(() => ['0WMNvp9YQuIxmEDEOO6e'], []))
 */
function useStrategiesFilter(options) {
  const {
    coreAttributeStrategyIds,
    defaultSelectedParentStrategyIds,
    defaultSelectedStrategyIds,
    whitelistParentStrategyIds,
    whitelistStrategyIds,
  } = {
    ...useStrategiesFilterDefaultOptions,
    ...options,
  };

  const allStrategies = useStrategies();
  const [state, setState] = useState();

  const strategies = useMemo(
    () =>
      allStrategies?.map(({ strategyName, strategyId, ...rest }) => ({
        strategyName: coreAttributeStrategyIds?.includes(strategyId) ? `${strategyName} *` : strategyName,
        strategyId,
        ...rest,
      })),
    [allStrategies, coreAttributeStrategyIds]
  );

  const getDefaultSelectedState = useCallback(
    strategy => {
      if (Array.isArray(defaultSelectedParentStrategyIds)) {
        return (
          defaultSelectedParentStrategyIds.includes(strategy.strategyId) ||
          defaultSelectedParentStrategyIds.includes(strategy.parentId)
        );
      }
      if (Array.isArray(defaultSelectedStrategyIds)) {
        return defaultSelectedStrategyIds.includes(strategy.strategyId);
      }
      return true;
    },
    [defaultSelectedParentStrategyIds, defaultSelectedStrategyIds]
  );

  useEffect(() => {
    if (!strategies) return;

    return setState(
      strategies
        .filter(strategy => {
          if (Array.isArray(whitelistParentStrategyIds)) {
            return (
              whitelistParentStrategyIds.includes(strategy.strategyId) ||
              whitelistParentStrategyIds.includes(strategy.parentId)
            );
          }
          if (Array.isArray(whitelistStrategyIds)) {
            return whitelistStrategyIds.includes(strategy.strategyId);
          }
          return true;
        })
        .map(s => ({
          ...s,
          disabled: false,
          selected: getDefaultSelectedState(s),
        }))
    );
  }, [strategies, getDefaultSelectedState, whitelistParentStrategyIds, whitelistStrategyIds]);

  return [state, setState];
}

export default useStrategiesFilter;
