/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { select, extent, map, scaleOrdinal, scaleSqrt, geoMercator, geoPath, json, csv, schemeBlues } from 'd3';

function draw(data, width, height) {
  // Create svg object
  const svg = select('#containerBubbleMap')
    // .append('svg')
    // .attr('width', '100%')
    // .attr('height', '100%')

    .attr('preserveAspectRatio', 'xMinYMin')
    .attr('viewBox', `0 0 ${width}  ${height}`);

  // Map and projection
  const projection = geoMercator()
    .center([0, 40]) // GPS of location to zoom on
    // .scale(80) // This is like the zoom
    .scale(width / 2 / Math.PI)
    .translate([width / 2, height / 2]);

  Promise.all([
    json('https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson'),
    csv('https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/data_gpsLocSurfer.csv'),
  ]).then(ready);

  function ready([dataGeo, dataValues]) {
    // Create a color scale
    const allContinents = map(dataValues, d => d.homecontinent).keys();

    /*  const color = d3
      .scaleOrdinal()
      .domain(allContinent)
      .range(schemePaired); */

    // Add a scale for bubble size
    const valueExtent = extent(dataValues, d => +d.n);

    const color = scaleOrdinal().domain(valueExtent).range(schemeBlues[9]);

    const size = scaleSqrt()
      .domain(valueExtent) // What's in the data
      .range([1, 50]); // Size in pixel

    // Draw the map
    svg
      .append('g')
      .selectAll('path')
      .data(dataGeo.features)
      .enter()
      .append('path')
      .attr('fill', '#fff')
      .attr('d', geoPath().projection(projection))
      .style('stroke', 'none')
      .style('opacity', 0.8);

    // Add circles:
    svg
      .selectAll('myCircles')
      .data(
        dataValues
          .sort((a, b) => {
            return +b.n - +a.n;
          })
          .filter((d, i) => {
            return i < 1000;
          })
      )
      .enter()
      .append('circle')
      .attr('cx', d => {
        return projection([+d.homelon, +d.homelat])[0];
      })
      .attr('cy', d => {
        return projection([+d.homelon, +d.homelat])[1];
      })
      .attr('r', d => {
        return size(+d.n);
      })
      .style('fill', d => {
        // return color(+d.n);
        return 'steelblue';
      })
      .attr('stroke', d => {
        /* if (d.n > 2000) {
          return '#fff';
        } */
        return '#fff';
      })
      .attr('stroke-width', 0.1)
      .attr('fill-opacity', 0.4);

    // Add title and explanation
    /* svg
      .append('text')
      .attr('text-anchor', 'end')
      .style('fill', '#fff')
      .attr('x', width - 10)
      .attr('y', height - 30)
      .attr('width', 90)
      .html('WHERE SURFERS LIVE')
      .style('font-size', 14); */

    // --------------- //
    // ADD LEGEND //
    // --------------- //

    // Add legend: circles
    const valuesToShow = [100, 4000, 15000];
    const xCircle = 40;
    const xLabel = 90;

    svg
      .selectAll('legend')
      .data(valuesToShow)
      .enter()
      .append('circle')
      .attr('cx', xCircle)
      .attr('cy', d => {
        return height - size(d);
      })
      .attr('r', d => {
        return size(d);
      })
      .style('fill', 'none')
      .attr('stroke', '#fff');

    // Add legend: segments
    svg
      .selectAll('legend')
      .data(valuesToShow)
      .enter()
      .append('line')
      .attr('x1', d => {
        return xCircle + size(d);
      })
      .attr('x2', xLabel)
      .attr('y1', d => {
        return height - size(d);
      })
      .attr('y2', d => {
        return height - size(d);
      })
      .attr('stroke', '#fff')
      .style('stroke-dasharray', '2,2');

    // Add legend: labels
    svg
      .selectAll('legend')
      .data(valuesToShow)
      .enter()
      .append('text')
      .attr('x', xLabel)
      .attr('y', d => {
        return height - size(d);
      })
      .text(d => d)
      .style('font-size', 10)
      .attr('fill', '#fff')
      .attr('alignment-baseline', 'middle');
  }
}

const BubbleMap = props => {
  const { data, width, height } = props;
  useEffect(() => {
    draw(data, width, height);
  }, [data, width, height]);
  return <svg id="containerBubbleMap" {...props} />;
};

BubbleMap.defaultProps = {
  width: 460,
  height: 400,
  data: null,
};

BubbleMap.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default BubbleMap;
