import { Link as MuiLink } from '@material-ui/core';
import styled from 'styled-components';

export default styled(MuiLink).attrs(({ color, component, type }) => ({
  component: component || 'button',
  color: color || 'primary',
  size: 'small',
  type: type || (component ? null : 'button'),
}))`
  font-size: ${props => props.fontSize || '12px'};
  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
`;
