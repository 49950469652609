import React from 'react';
import PropTypes from 'prop-types';
import * as date from 'src/utils/date';

function PaymentMethod({ className, card }) {
  if (!card) return null;

  const { brand, last4, expYear, expMonth } = card;

  return (
    <div
      className={className}
      css={`
        color: white;
        font-family: monospace;
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        padding: 0.75rem 0.5rem;
      `}
    >
      <span>{brand.toUpperCase()}</span>
      <span>···· {last4}</span>
      <span>{date.formatDate('MM/yy')(new Date(expYear, expMonth - 1))}</span>
    </div>
  );
}

PaymentMethod.defaultProps = {};

PaymentMethod.propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired,
    expYear: PropTypes.number.isRequired,
    expMonth: PropTypes.number.isRequired,
  }),
};

export default PaymentMethod;
