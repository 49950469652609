import styled from 'styled-components';

export default styled.span`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  min-width: 6em;
  ::after {
    content: ':';
  }
`;
