import React from 'react';
import { useState } from 'react';
import { parseISO } from 'src/utils/date';
import styled from 'styled-components';
import { useFund } from 'src/hooks';
import { Dialog, CashflowDataVisualization, ViewForecastCheckbox } from 'src/components';
import ChartTypeSelect from '../../ChartTypeSelect';

const { TYPES, VIEWS } = CashflowDataVisualization.CONSTANTS;
const { useFundCashflowData } = CashflowDataVisualization;

const Controls = styled.div`
  display: flex;
  margin-bottom: 1.5em;
  justify-content: space-between;
`;

function ViewFundDialog({ fundId, open, onClose, className, view, commitmentAmount }) {
  const [typeSelect, setTypeSelect] = useState(TYPES.JCURVE_MEAN);
  const [viewForecast, setViewForecast] = useState(true);
  const fund = useFund({ fundId });
  const { forecastSeries, historySeries, startDate, isMatureFund, isLoading } = useFundCashflowData({
    fundId,
    date: fund.date,
    vintage: fund.vintage,
    commitmentAmount,
  });

  if (!fund) return null;

  return (
    <Dialog
      onClose={onClose}
      open={open || true}
      className={className}
      title={<span title={fund.fundId}>{fund.name}</span>}
    >
      <Controls>
        <ChartTypeSelect
          value={typeSelect}
          options={[TYPES.JCURVE_MEAN, TYPES.NAV, TYPES.CASHFLOW]}
          labels={{
            [TYPES.JCURVE_MEAN]: 'jCurve',
            [TYPES.NAV]: TYPES.NAV,
            [TYPES.CASHFLOW]: TYPES.CASHFLOW,
          }}
          onChange={value => {
            setTypeSelect(value);
          }}
        />

        <ViewForecastCheckbox
          checked={viewForecast}
          onChange={evt => setViewForecast(evt.target.checked)}
          disabled={isMatureFund}
          isMatureFund={isMatureFund}
        />
      </Controls>

      <div
        css={`
          height: 70vh;
        `}
      >
        <CashflowDataVisualization
          type={typeSelect}
          view={view}
          subjectTimeseries={historySeries}
          forecastTimeseries={viewForecast ? forecastSeries : []}
          startDate={parseISO(startDate)}
          css={`
            flex: 1;
            background: ${isLoading ? 'transparent' : '#1c1c1c;'};

            &::before {
              display: ${isLoading ? 'flex' : 'none'};
              content: 'Generating forecast...';
              position: absolute;
              height: 100%;
              width: 100%;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          `}
        />
      </div>
    </Dialog>
  );
}

ViewFundDialog.defaultProps = {
  view: VIEWS.CHART,
};

export default ViewFundDialog;
