import React from 'react';
import styled from 'styled-components';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import IconCheckboxUnchecked from 'src/components/atoms/IconCheckboxUnchecked';
import IconCheckboxChecked from 'src/components/atoms/IconCheckboxChecked';
import IconCheckboxIndeterminate from 'src/components/atoms/IconCheckboxIndeterminate';

const Checkbox = styled(MuiCheckbox)`
  width: ${props => props.$width || '28px'};
  height: ${props => props.$height || '28px'};
  padding: 0;
  .MuiSvgIcon-root {
    width: ${props => props.$width || '28px'};
    height: ${props => props.$height || '28px'};
  }
  &.Mui-disabled {
    color: ${props => props.$color};
  }
  &.MuiCheckbox-root {
    color: ${props => props.$color || props.theme.palette.primary.main};
  }
`;

function TreeviewCheckbox(props) {
  const { color, width, height, ...rest } = props;

  return (
    <Checkbox
      {...rest}
      color="default"
      icon={<IconCheckboxUnchecked fill={color} />}
      checkedIcon={<IconCheckboxChecked fill={color} />}
      indeterminateIcon={<IconCheckboxIndeterminate fill={color} />}
      $color={color}
      $width={width}
      $height={height}
    />
  );
}

export default TreeviewCheckbox;
