import React from 'react';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFundManager } from 'src/hooks';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { AppPanel, Responsive, SubscriptionPaywall, FundManagerTeamVisualization } from 'src/components';
import { teams } from 'src/config/tutorials';

const { VIEW_STATE } = FundManagerTeamVisualization.CONSTANTS;

function FundManagerTeamPanel({ spanX, spanY, minHeight, fundManagerId }) {
  const fundManager = useFundManager({ fundManagerId }) || {};
  const [view, setView] = useState(VIEW_STATE.employeeByTitle);
  const [isExpanded, setIsExpanded] = useState(false);
  const [wideTable, setWideTable] = useState(false);

  const title = useMemo(() => {
    if (isExpanded && fundManager.name) return `${fundManager.name} - Team`;
    return 'Team';
  }, [fundManager.name, isExpanded]);

  function handleViewChange(newView) {
    return function (_event) {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundManagerTeamDataSet);
      setView(newView);
    };
  }

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title={title}
      onChangeExpanded={setIsExpanded}
      tutorial={teams}
      controls={
        <Responsive.OnAtLeastLaptop>
          <div>
            <AppPanel.Button
              disabled={wideTable}
              selected={view === VIEW_STATE.employeeByTitle}
              onClick={handleViewChange(VIEW_STATE.employeeByTitle)}
            >
              Position
            </AppPanel.Button>
            <AppPanel.Button
              disabled={wideTable}
              selected={view === VIEW_STATE.employeeBySchool}
              onClick={handleViewChange(VIEW_STATE.employeeBySchool)}
            >
              Schools
            </AppPanel.Button>
            <AppPanel.Button
              disabled={wideTable}
              selected={view === VIEW_STATE.employeeByPriorFirm}
              onClick={handleViewChange(VIEW_STATE.employeeByPriorFirm)}
            >
              Prior Firm
            </AppPanel.Button>
          </div>
        </Responsive.OnAtLeastLaptop>
      }
      expandable
    >
      <SubscriptionPaywall compact>
        <FundManagerTeamVisualization
          fundManager={fundManager}
          view={view}
          setView={setView}
          wideTable={wideTable}
          setWideTable={setWideTable}
        />
      </SubscriptionPaywall>
    </AppPanel>
  );
}

FundManagerTeamPanel.defaultProps = {};

FundManagerTeamPanel.propTypes = {
  fundManagerId: PropTypes.string.isRequired,
};

export default FundManagerTeamPanel;
