import { uniq } from 'src/lib/lodash';
import {
  select,
  max,
  sum,
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  stack,
  stackOrderNone,
  stackOffsetNone,
  scaleOrdinal,
} from 'd3';
import { COLORS } from './constants';

function create({ id, margin }) {
  const prefixedId = `#${id}`;

  const root = select(prefixedId)
    .append('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  root
    .append('g')
    .attr('class', 'x-axis')
    .style('shape-rendering', 'crispEdges');

  root
    .append('g')
    .attr('class', 'y-axis')
    .style('shape-rendering', 'crispEdges');

  // add Y axis label
  root
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('dy', '1em')
    .attr('class', 'y-axis-label')
    .style('text-anchor', 'middle')
    .text('Funds in the Market');

  // add the legend
  root
    .append('g')
    .attr('class', 'legend')
    .append('g')
    .attr('class', 'legend-items');

  return root;
}

function handleEvent(ref) {
  return function (_, d) {
    const allCount = Object.values(d.data).reduce(function (acc, curr) {
      if (!curr.count) return acc;
      return acc + curr.count;
    }, 0);

    ref.current(this, {
      year: d.data.year,
      label: d.strategyName || d.regionName,
      strategyId: d.strategyId,
      regionId: d.regionId,
      count: d.count,
      allCount,
    });
  };
}

function draw({ id, data, size, margin, mouseEventRefs }) {
  const { height, width } = size;
  const { left, right, top, bottom } = margin;

  const svgWidth = Math.max(width - left - right, 0);
  const svgHeight = Math.max(height - top - bottom, 0);

  if (!svgWidth || !svgHeight) return;

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } =
    mouseEventRefs;

  const keys = uniq(
    data
      .map(d => Object.keys(d).slice(1))
      .reduce((acc, key) => [...acc, ...key], []),
  );

  const currentYear = data[data.length - 1]?.year;

  let root = select(`#${id} .root`);

  if (!root.node()) {
    root = create({ id, margin });
  }

  const seriesFn = stack()
    .keys(keys)
    .value((d, key) => (d[key] ? d[key].count : 0))
    .order(stackOrderNone)
    .offset(stackOffsetNone);

  const series = seriesFn(data);

  const x = scaleBand()
    .domain(data.map(d => d.year))
    .range([0, svgWidth])
    .padding(0.5);

  const yMax = max(
    data.map(datum =>
      sum(
        Object.values(datum)
          .slice(1)
          .map(item => item.count),
      ),
    ),
  );

  const y = scaleLinear()
    // eslint-disable-next-line no-shadow
    .domain([0, yMax])
    .rangeRound([svgHeight, 0]);

  // add the X axis
  root
    .select('.x-axis')
    .attr('transform', `translate(0,${svgHeight + 5})`)
    .transition()
    .call(axisBottom(x).tickSize(0));

  // add the Y axis
  root
    .select('.y-axis')
    .transition()
    .call(axisLeft(y).tickSize(-svgWidth).tickFormat(''));

  // position the y-axis label
  root
    .select('.y-axis-label')
    .attr('y', 0 - margin.left + 10)
    .attr('x', 0 - svgHeight / 2)
    .transition();

  const color = scaleOrdinal()
    .domain(series.map(d => d.key))
    .range(COLORS);

  const bands = root.selectAll('.band').data(series, d => d.index);

  bands.join(
    enter =>
      enter
        .append('g')
        .attr('class', (_d, i) => `band band-${i}`)
        .attr('fill', d => color(d.key)),
    update =>
      update.call(u =>
        u
          .transition()
          .attr('class', (_d, i) => `band band-${i}`)
          .attr('fill', d => color(d.key)),
      ),
    exit => exit.call(e => e.transition().remove()),
  );

  // eslint-disable-next-line no-shadow
  series.forEach((data, i) => {
    const localData = data.map(d => ({
      ...d,
      ...d.data[data.key],
    }));

    const band = root
      .select(`.band-${i}`)
      .selectAll('.bar')
      .data(localData, d => d.data.year);

    band.join(
      enter =>
        enter
          .append('rect')
          .attr('height', d => y(d[0]) - y(d[1]))
          .attr('width', x.bandwidth())
          .attr('x', d => x(d.data.year))
          .attr('y', d => y(d[1]))
          .attr(
            'class',
            d => `bar ${d.data.year === currentYear ? 'current' : ''}`,
          )
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd)),
      update =>
        update
          //.attr('opacity', 0)
          .attr('x', d => x(d.data.year))
          .attr('y', d => y(d[1]))
          .attr('height', d => y(d[0]) - y(d[1]))
          .attr('width', x.bandwidth())
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd))
          .call(u =>
            u
              .transition()
              .attr('x', d => x(d.data.year))
              .attr('y', d => y(d[1]))
              .attr('height', d => y(d[0]) - y(d[1]))
              .attr('width', x.bandwidth()),
          ),
      exit =>
        exit.call(ex => ex.transition().attr('cy', svgHeight).remove()),
    );
  });
}

export default draw;
