import { db, firestoreTimestamp, auth, serializeTimestamps, applyQueryFilters } from 'src/config/firebase';
import { DocumentNotFoundError } from 'src/services/filter-api/errors';
import { validatesPresence, validatesAsNumberical } from '../validations';

const collection = db.collection('userTargetFunds');

async function list(filters = {}) {
  const userId = auth.currentUser.uid;
  const query = applyQueryFilters(collection, { ...filters, userId });
  const snapshot = await query.get();

  return snapshot.docs.map(d => d.data()).map(serializeTimestamps);
}

async function get(docId) {
  const doc = await collection.doc(docId).get();
  if (!doc.exists) throw new DocumentNotFoundError(doc);
  return doc.data();
}

async function create(data) {
  const userId = auth.currentUser.uid;

  validatesPresence({ name: data.name });
  validatesAsNumberical({
    commitmentAmount: data.commitmentAmount,
    vintage: data.vintage,
  });

  const doc = collection.doc();
  await doc.set({
    ...data,
    userId,
    targetFundId: doc.id,
    createdAt: firestoreTimestamp(),
    updatedAt: firestoreTimestamp(),
  });
  return doc.id;
}

async function update(docId, data) {
  const doc = collection.doc(docId);
  if (Object.keys(data).some(key => key === 'name')) {
    validatesPresence({ name: data.name });
  }

  validatesAsNumberical({
    commitmentAmount: data.commitmentAmount,
    vintage: data.vintage,
  });

  await doc.update({ ...data, updatedAt: firestoreTimestamp() });
  return docId;
}

async function destroy(targetFundId) {
  const doc = collection.doc(targetFundId);
  await doc.delete();
  return targetFundId;
}

export { collection, list, get, create, update, destroy };
