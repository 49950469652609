import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { getStrategyTimeseries } from 'src/services/filter-api';

const QUERY_OPTS = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  staleTime: 1000 * 60 * 60,
};

function useStrategyTimeseriesQueries(queryArgs, { truncate = [0, 12 * 4], highlight = true } = {}) {
  const queryParams = queryArgs.map(({ strategyId, type = 'mean', quartile, queryId }) => {
    const docId = [strategyId, type, quartile].filter(Boolean).join('_');
    return {
      queryKey: ['strategyTimeseries', strategyId, type, quartile],
      queryFn: async () => {
        const result = await getStrategyTimeseries(docId);

        return { queryId, ...result };
      },
      ...QUERY_OPTS,
    };
  });

  const queries = useQueries(queryParams);

  return useMemo(() => {
    return queries.map(query => {
      if (!query.data) return query;
      if (truncate === false) return query;

      const series = query.data.series
        .filter(d => {
          if (!truncate) return true;
          return d.q >= truncate[0] && d.q < truncate[1];
        })
        .map(d => {
          if (highlight === false) return d;
          if (highlight === true) return { ...d, highlight: true };
          return d.q >= highlight[0] && d.q <= highlight[1];
        });

      return {
        ...query,
        data: {
          ...query.data,
          series,
        },
      };
    });
  }, [highlight, queries, truncate]);
}

export default useStrategyTimeseriesQueries;
