import React from 'react';
import styled from 'styled-components';
import { Dialog, IconButton, Collapse } from '@material-ui/core';
import { IconAdd as IconAddBase, Button, TextField as TextFieldBase, IconCheck } from 'src/components';
import { md } from 'src/config/layout';
import { button } from 'src/config/colors';

const IconClose = styled(IconAddBase)`
  stroke-width: 10px;
  transform: rotate(45deg);
  font-size: 2.513777778em;
`;

const IconAdd = styled(IconAddBase)`
  font-size: 1.166666667em;
  vertical-align: text-bottom;
  stroke-width: 1.25em;
`;

const Layout = styled(Dialog)`
  font-size: 1.125rem;

  .MuiDialog-container {
    & > .MuiPaper-root {
      width: calc(100% - 20px);
      max-width: 60rem;
      max-height: calc(100% - 20px);
      margin: 0;
      padding-bottom: 6rem;
      background-color: #313131;

      .MuiFormControl-root {
        font-size: 1em;
        .MuiSelect-select,
        .MuiInputBase-root,
        .MuiFormLabel-root {
          font-size: unset;
        }

        .MuiInputLabel-shrink {
          font-size: 1rem;
        }
      }

      .MuiOutlinedInput-input {
        background-color: transparent;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: unset;
        border-width: 1px;
      }

      .MuiFormLabel-root {
        color: #818181;
        font-weight: 300;
      }

      .MuiFormLabel-filled {
        background-color: #313131;
        padding: 0 3px;
      }
    }

    @media (min-width: ${md.BREAKPOINT}px) {
      & > .MuiPaper-root {
        width: calc(100% - 40px);
        max-height: calc(100% - 40px);
      }
    }
  }
`;

const Header = styled.header`
  text-align: center;
  padding: 1.75rem 2em;
`;

const Content = styled.div`
  width: 100%;
  max-width: 45rem;
  padding: 0 2rem;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-weight: 500;
  margin: 0;
  font-size: 1.777777778em;
`;

const Section = styled(Collapse)`
  & + & {
    margin-top: 2.625rem;
  }
`;

Section.defaultProps = {
  in: true,
};

const SectionTitle = styled.h4`
  font-size: 1.111111111em;
  margin: 0;
  font-weight: normal;
  line-height: 1;

  &[data-step]::before {
    content: attr(data-step);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #313131;
    border-radius: 50%;
    width: 1.9em;
    height: 1.9em;
    vertical-align: middle;
    margin-right: 0.45em;
  }
`;

const SectionRow = styled.div`
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: ${p => p.gridColumns};
  grid-template: ${p => p.grid};
  align-items: center;
`;

const SectionHeader = styled(SectionRow)`
  margin-bottom: 0.6em;
`;

const CloseButton = styled(IconButton).attrs({
  children: <IconClose />,
})`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  font-size: 20px;
`;

const ActionButton = styled(Button).attrs({
  margin: 'dense',
})`
  background-color: ${button.accent.BACKGROUND_COLOR};
  color: ${button.accent.TEXT_COLOR};
  /* padding: 0.444444444em 0.888888889em; */

  .MuiButton-label {
    line-height: 1.33333333;
  }

  &.MuiButton-root.Mui-disabled {
    color: ${button.disabled.TEXT_COLOR};
    background-color: ${button.disabled.BACKGROUND_COLOR};
  }
`;

const ActionLink = styled(ActionButton)`
  background-color: transparent;
  color: currentColor;
  border-color: transparent;
  text-decoration: underline;
  opacity: 0.75;
  font-weight: 300;
  font-size: 0.777777778em;

  color: ${p => p.$primary && button.accent.BACKGROUND_COLOR};
  text-decoration: ${p => p.$primary && 'none'};

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }

  &.MuiButton-root.Mui-disabled {
    opacity: 0.2;
    color: unset;
    background-color: unset;
  }
`;

const TextField = styled(TextFieldBase).attrs({
  preset: 'enhanced',
})`
  .MuiInputBase-input {
    color: white;
  }
`;

const HelpText = styled.div`
  font-size: 0.611111111em;
  color: #737373;

  strong {
    color: white;
    font-weight: inherit;
  }
`;

const ValidRowIcon = styled(IconCheck)`
  color: ${p => (p.$valid ? '#3dd41a' : '#fff')};
  opacity: ${p => (p.$valid ? 1 : 0.1)};
  font-size: 0.875rem;
`;

export {
  Layout,
  Header,
  Title,
  CloseButton,
  IconAdd,
  IconClose,
  Content,
  Section,
  SectionHeader,
  SectionRow,
  SectionTitle,
  ActionButton,
  ActionLink,
  TextField,
  HelpText,
  ValidRowIcon,
};
