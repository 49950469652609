const animation = {
  TIMING: 250,
  EASING: 'ease-in-out',
};

const sm = {
  BREAKPOINT: 600,
  V_BREAKPOINT: 0,
  MARGIN: 10,
  PRIMARY_NAV: 60,
  SECONDARY_NAV: 54,
  TICKER: 0,
};

const md = {
  BREAKPOINT: 960,
  V_BREAKPOINT: 540,
  MARGIN: 10,
  PRIMARY_NAV: 80,
  SECONDARY_NAV: 54,
  TICKER: 0,
};

const lg = {
  BREAKPOINT: 1280,
  V_BREAKPOINT: 800,
  MARGIN: 10,
  PRIMARY_NAV: 80,
  SECONDARY_NAV: 54,
  TICKER: 56,
};

const xl = {
  BREAKPOINT: 1600,
  V_BREAKPOINT: 1000,
  MARGIN: 10,
  PRIMARY_NAV: 240,
  SECONDARY_NAV: 74,
  TICKER: 56,
};

const xxl = {
  BREAKPOINT: 1920,
  V_BREAKPOINT: 1200,
  MARGIN: 10,
  PRIMARY_NAV: 240,
  SECONDARY_NAV: 74,
  TICKER: 56,
};

const xxxl = {
  BREAKPOINT: 2560,
  V_BREAKPOINT: 1440,
  MARGIN: 10,
  PRIMARY_NAV: 240,
  SECONDARY_NAV: 74,
  TICKER: 56,
};

function splitBreakpoint(a, b, f = 0.5) {
  const difference = Math.abs(a.BREAKPOINT - b.BREAKPOINT);
  const extra = difference * f;
  const base = Math.min(a.BREAKPOINT, b.BREAKPOINT);

  return base + extra;
}

export { animation, sm, md, lg, xl, xxl, xxxl, splitBreakpoint };
