import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHasSubscription } from 'src/hooks';
import PaywallModal from './PaywallModal';

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

const LayoutWithModal = styled(Layout)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FuzzyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  filter: blur(4px);
  pointer-events: none;
`;

function SubscriptionPaywall({ className, children, barebones, lite, full, forwardRef, ...rest }) {
  const hasSubscription = useHasSubscription();

  if (hasSubscription) {
    return (
      <Layout ref={forwardRef} className={className} {...rest}>
        {children}
      </Layout>
    );
  }

  return (
    <LayoutWithModal className={className}>
      <FuzzyOverlay ref={forwardRef} className={className} {...rest}>
        {children}
      </FuzzyOverlay>
      <PaywallModal barebones={barebones} lite={lite} full={full} />
    </LayoutWithModal>
  );
}

SubscriptionPaywall.defaultProps = {};

SubscriptionPaywall.propTypes = {
  lite: PropTypes.bool,
  compact: PropTypes.bool,
  full: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default React.forwardRef((props, ref) => <SubscriptionPaywall {...props} forwardRef={ref} />);
