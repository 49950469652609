import React from 'react';
import { useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cloneDeep } from 'src/lib/lodash';
import { extent, median, quantile } from 'd3';
import { useLayoutActions } from 'src/hooks';
import { lookup } from 'src/lookup';
import { upperCase } from 'src/formatters';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { md } from 'src/config/layout';
import {
  AppPanel,
  PeerGroupScatterTooltip,
  SubscriptionPaywall,
  ButtonArrowLeft,
  ButtonArrowRight,
  FilterControls,
} from 'src/components';
import { useFundPeerGroupFilter, useFilteredFunds } from 'src/hooks';
import RadarChartSection from './RadarChartSection';
import BarChartSection from './BarChartSection';
import PlotChartSection from './PlotChartSection';
import CompareToPeersTable from './CompareToPeersTable';

const SUBJECT_FUND_COLOR = '#0d8bab';
const PEER_COLORS = ['#a55aff', '#6fef29', '#fd8f03', '#5a70ff', '#ff5900', '#7b7b7b', '#e033bf', '#c2c2c2', '#c7b004'];

const RADAR_METRICS = [
  {
    value: 'tvpi',
    label: upperCase('net tvpi'),
    offset: { x: 0, y: '-1em' },
  },
  {
    value: 'calledPct',
    label: upperCase('called %'),
    offset: { x: '-3.5em', y: '0.25em' },
  },
  {
    value: 'dpi',
    label: upperCase('dpi'),
    offset: { x: '-2em', y: '2em' },
  },
  {
    value: 'rvpi',
    label: upperCase('rvpi'),
    offset: { x: '2em', y: '2em' },
  },
  {
    value: 'irr',
    label: upperCase('net irr'),
    offset: { x: '3em', y: '0.25em' },
  },
];

const Section = styled.div`
  position: relative;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  & > ${Section}:first-child {
    flex: 5;
  }

  & > ${Section}:last-child {
    flex: 7;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    flex-direction: row;

    & > ${Section}:first-child {
      flex: 0 0 ${p => (p.$narrowTable ? 400 : 700)}px;
    }
  }
`;

const ButtonArrow = styled.button`
  display: none;

  position: absolute;
  top: 14px;
  right: -5px;
  z-index: 1;
  color: #000;
  background-color: #e1b72a;
  &:hover {
    background-color: #ffcf2d;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    display: initial;
  }
`;

function identifyOutliers(funds, metric) {
  return function (fund) {
    const values = [...funds]
      .map(f => f[metric])
      .filter(Number.isFinite)
      .sort();
    const Q1 = quantile(values, 0.25);
    const Q3 = quantile(values, 0.75);
    const IQR = Q3 - Q1;
    const lower = Q1 - 1.5 * IQR;
    const upper = Q3 + 1.5 * IQR;

    const isBelowUpper = fund[metric] < upper;
    const isAboveLower = fund[metric] > lower;
    const isInBounds = Number.isFinite(fund[metric]) && isBelowUpper && isAboveLower;

    return { ...fund, [`${metric}Outlier`]: !isInBounds };
  };
}

function identifyFilteredFund(filteredFunds, subjectFund) {
  return function (fund) {
    return {
      ...fund,
      meetsFilterCriteria: filteredFunds.some(f => f.fundId === fund.fundId) || subjectFund?.fundId === fund.fundId,
    };
  };
}

function FundPeerVisualization({
  subjectFund,
  peerFunds,
  showScatterPlot,
  showRadarChart,
  showBarChart,
  showFilterControls,
  handleFiltersClose,
}) {
  const [selectedPeerIds, setSelectedPeerIds] = useState({
    [subjectFund.fundId]: SUBJECT_FUND_COLOR,
  });
  const [financialMetric, setFinancialMetric] = useState(lookup.financialMetric.tvpi);
  const [narrowTable, setNarrowTable] = useState(true);
  const reusableColors = useRef([]);

  const [chartTooltipOpen, setChartTooltipOpen] = useState(false);
  const [chartTooltipAnchorEl, setChartTooltipAnchorEl] = useState(null);
  const [chartTooltipData, setChartTooltipData] = useState(null);
  const [showMedians, setShowMedians] = useState(false);
  const { expandPanel } = useLayoutActions();
  const { isCapturingPreview } = useContext(AppPanel.Context);
  const [showOutliers, setShowOutliers] = useState(false);

  const {
    persisting,
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
    savePeerGroupFilter,
    resetPeerGroupFilter,
  } = useFundPeerGroupFilter(subjectFund);

  const filteredPeerFunds = useFilteredFunds({
    funds: peerFunds,
    strategies: strategiesFilter,
    vintages: vintagesFilter,
    regions: regionsFilter,
    secondRegions: secondRegionsFilter,
    sizes: sizesFilter,
  });

  const localPeers = useMemo(() => {
    if (!subjectFund) return;
    if (!selectedPeerIds) return;
    if (!peerFunds) return;
    if (!filteredPeerFunds) return;

    return [
      {
        ...subjectFund,
        color: SUBJECT_FUND_COLOR,
        meetsFilterCriteria: true,
        subjectFund: true,
      },
      ...peerFunds
        .filter(peer => peer.fundId !== subjectFund.fundId)
        .filter(fund => Boolean(fund.calledPct))
        .map(peer => ({
          ...peer,
          color: selectedPeerIds[peer.fundId],
        }))
        .map(identifyOutliers(peerFunds, 'tvpi'))
        .map(identifyOutliers(peerFunds, 'irr'))
        .map(identifyFilteredFund(filteredPeerFunds, subjectFund)),
    ];
  }, [peerFunds, selectedPeerIds, subjectFund, filteredPeerFunds]);

  const filteredLocalPeers = useMemo(() => {
    return localPeers.filter(peer => peer.meetsFilterCriteria);
  }, [localPeers]);

  const metrics = useMemo(() => {
    if (!filteredLocalPeers) return;
    return RADAR_METRICS.map(metric => {
      return {
        ...metric,
        extent: extent(
          filteredLocalPeers.filter(p => p[metric.value] != null),
          p => p[metric.value]
        ),
        median: median(filteredLocalPeers, p => p[metric.value]),
      };
    });
  }, [filteredLocalPeers]);

  const handleItemCheckToggle = useCallback(
    function handleItemCheckToggle(data) {
      return function (event) {
        event.stopPropagation();
        event.preventDefault();
        usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundPeerCompare);
        setSelectedPeerIds(prev => {
          const { fundId: selectedFundId } = data;
          const selected = !selectedPeerIds[selectedFundId];
          const prevClone = cloneDeep(prev);
          if (selected) {
            const nextColorIndex = (Object.keys(prevClone).length - 1) % PEER_COLORS.length;
            const reusableColor = reusableColors.current.pop();
            const color = reusableColor || PEER_COLORS[nextColorIndex];
            prevClone[selectedFundId] = color;
          } else {
            reusableColors.current.push(prevClone[selectedFundId]);
            delete prevClone[selectedFundId];
          }
          return cloneDeep(prevClone);
        });
      };
    },
    [selectedPeerIds]
  );

  const handleChangeMetric = useCallback(metric => {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundPeerMetric);
    setFinancialMetric(metric);
  }, []);

  const closeTooltips = useCallback(() => {
    setChartTooltipOpen(false);
    setChartTooltipAnchorEl(null);
  }, []);

  const handleItemClick = useCallback(
    (node, d) => {
      closeTooltips();
      setChartTooltipData(d);
      setChartTooltipAnchorEl(node);
      setChartTooltipOpen(true);
    },
    [closeTooltips]
  );

  const handleClickAway = useCallback(
    function handleClickAway(e) {
      if (
        !e.target.classList.contains('point') &&
        !e.target.classList.contains('fund-point') &&
        !e.target.classList.contains('info-button') &&
        !e.target.classList.contains('icon-info')
      ) {
        closeTooltips();
      }
    },
    [closeTooltips]
  );

  useEffect(() => {
    if (showFilterControls) expandPanel();
  }, [showFilterControls, expandPanel]);

  useEffect(() => {
    setSelectedPeerIds({
      [subjectFund.fundId]: SUBJECT_FUND_COLOR,
    });
  }, [subjectFund.fundId]);

  if (!localPeers) return null;
  if (!subjectFund.fundId) return null;

  return (
    <div
      css={`
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
      `}
    >
      <SubscriptionPaywall>
        <Layout $narrowTable={narrowTable}>
          <Section>
            <ButtonArrow
              as={narrowTable ? ButtonArrowRight : ButtonArrowLeft}
              onClick={() => setNarrowTable(!narrowTable)}
            />
            <CompareToPeersTable
              funds={localPeers}
              metric={financialMetric.key}
              onClickCompareCheckbox={handleItemCheckToggle}
              narrowTable={narrowTable}
              $scroll={!isCapturingPreview}
              $isNarrow={Boolean(narrowTable)}
              showOutliers={showOutliers}
            />
          </Section>

          <Section>
            {showScatterPlot && (
              <PlotChartSection
                showOutliers={showOutliers}
                setShowOutliers={setShowOutliers}
                financialMetric={financialMetric}
                fund={subjectFund}
                handleItemClick={handleItemClick}
                localPeers={filteredLocalPeers}
                setFinancialMetric={handleChangeMetric}
              />
            )}
            {showRadarChart && (
              <RadarChartSection
                showOutliers={showOutliers}
                setShowOutliers={setShowOutliers}
                metrics={metrics}
                localPeers={filteredLocalPeers}
                setShowMedians={setShowMedians}
                showMedians={showMedians}
              />
            )}
            {showBarChart && (
              <BarChartSection
                showOutliers={showOutliers}
                setShowOutliers={setShowOutliers}
                financialMetric={financialMetric}
                fund={subjectFund}
                localPeers={filteredLocalPeers}
                selectedPeerIds={selectedPeerIds}
                setFinancialMetric={handleChangeMetric}
              />
            )}
          </Section>
        </Layout>
      </SubscriptionPaywall>

      <PeerGroupScatterTooltip
        onClickAway={handleClickAway}
        fund={chartTooltipData}
        open={chartTooltipOpen}
        anchorEl={chartTooltipAnchorEl}
        onClose={closeTooltips}
      />

      <FilterControls
        persisting={persisting}
        open={showFilterControls}
        showFundCoreAttributesHint
        onClose={handleFiltersClose}
        setOpen={handleFiltersClose}
        closeButtonText="Save"
        onDoneButtonClick={savePeerGroupFilter}
        onResetButtonClick={resetPeerGroupFilter}
        regionsFilter={regionsFilter}
        secondRegionsFilter={secondRegionsFilter}
        sizesFilter={sizesFilter}
        strategiesFilter={strategiesFilter}
        vintagesFilter={vintagesFilter}
        defaultRegionsFilter={defaultRegionsFilter}
        defaultSecondRegionsFilter={defaultSecondRegionsFilter}
        defaultSizesFilter={defaultSizesFilter}
        defaultStrategiesFilter={defaultStrategiesFilter}
        defaultVintagesFilter={defaultVintagesFilter}
        setRegionsFilter={setRegionsFilter}
        setSecondRegionsFilter={setSecondRegionsFilter}
        setSizesFilter={setSizesFilter}
        setStrategiesFilter={setStrategiesFilter}
        setVintagesFilter={setVintagesFilter}
      />
    </div>
  );
}

FundPeerVisualization.defaultProps = {
  subjectFund: {},
  peerFunds: [],
  showScatterPlot: false,
  showRadarChart: false,
  showBarChart: false,
};

FundPeerVisualization.propTypes = {
  subjectFund: PropTypes.shape({
    fundId: PropTypes.string.isRequired,
    vintage: PropTypes.number.isRequired,
  }),
  peerFunds: PropTypes.arrayOf(PropTypes.object).isRequired,
  showScatterPlot: PropTypes.bool,
  showRadarChart: PropTypes.bool,
  showBarChart: PropTypes.bool,
};

export default FundPeerVisualization;
