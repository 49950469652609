import React from 'react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import styled, { css } from 'styled-components';
import { useMouseEventRefs } from 'src/hooks';
import { FundPerformanceTableTooltip } from 'src/components';
import draw from './draw';

const MARGIN = { top: 3, right: 25, bottom: 3, left: 25 };

const disabledContainerCss = css`
  opacity: 0.25;
  cursor: initial;
  &:hover {
    border: none;
  }
  pointer-events: none;

  .quartile-line,
  .quartile-line-base {
    display: none;
  }
`;

const Container = styled.div`
  height: 26px;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border: 1px solid #0d8bab;
  }

  ${p => p.$disabled && disabledContainerCss};
`;

const Svg = styled.svg`
  display: block;
`;

function FundDistributionChart({
  funds,
  fundPeerGroupFilters,
  subjectFundId,
  metric,
  onChangeMetric,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  className,
}) {
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  const { current: svgId } = useRef(uniqueId());

  const subjectFund = funds.find(d => d.fundId === subjectFundId);
  const subjectFundPeerGroupFilter = fundPeerGroupFilters?.[subjectFundId];

  const isTooNew = subjectFund ? subjectFund.vintage >= new Date().getUTCFullYear() - 3 : true;
  const isIrr = metric.key === 'irr';
  const isIrrelevant = isTooNew && isIrr;

  useEffect(() => {
    draw({
      svgId,
      width,
      height,
      funds,
      subjectFundId,
      metric,
      margin: MARGIN,
      mouseEventRefs,
      isIrrelevant,
    });
  }, [svgId, subjectFundId, funds, metric, mouseEventRefs, width, height, isIrrelevant]);

  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <>
      <Container ref={targetRef} onClick={() => setPopoverOpen(true)} className={className} $disabled={isIrrelevant}>
        <Svg id={svgId} width={width} height={height} />
      </Container>

      {targetRef.current && subjectFund && (
        <FundPerformanceTableTooltip
          fund={subjectFund}
          fundPeerGroupFilter={subjectFundPeerGroupFilter}
          benchmark={metric}
          open={popoverOpen}
          onClose={() => setPopoverOpen(false)}
          onClickAway={() => setPopoverOpen(false)}
          anchorEl={targetRef.current}
          onBenchmarkChange={onChangeMetric}
        />
      )}
    </>
  );
}

FundDistributionChart.defaultProps = {
  funds: [],
  fundPeerGroupFilters: {},
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
  onChangeMetric: () => {},
};

FundDistributionChart.propTypes = {
  funds: PropTypes.array,
  fundPeerGroupFilters: PropTypes.object,
  subjectFundId: PropTypes.string.isRequired,
  metric: PropTypes.object.isRequired,
  onChangeMetric: PropTypes.func.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default React.memo(
  withResizeDetector(FundDistributionChart, {
    refreshMode: 'throttle',
    refreshRate: 50,
  })
);
