import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';

const Layout = styled(MuiDialog)`
  .MuiDialogContent-root {
    postion: relative;
  }
`;

function Title({ title }) {
  if (!title) return null;
  return (
    <MuiDialogTitle>
      <Typography variant="h6">{title}</Typography>
    </MuiDialogTitle>
  );
}

function CloseButton({ onClick }) {
  if (!onClick) return null;
  return (
    <IconButton
      onClick={onClick}
      color="inherit"
      css={`
        position: absolute;
        right: 0;
        top: 0;
        font-size: 1em;
      `}
    >
      <IconClose />
    </IconButton>
  );
}

function Dialog({ open, onClose, title, children, ...rest }) {
  return (
    <Layout open={open} onClose={onClose} {...rest}>
      <CloseButton onClick={onClose} />
      <Title title={title} />
      <DialogContent>{children}</DialogContent>
    </Layout>
  );
}

Dialog.defaultProps = {
  fullWidth: true,
  maxWidth: 'lg',
};

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Dialog;
