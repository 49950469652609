const AGREEMENT_TYPES = {
  GENERAL: {
    key: 'generalTermsOfService',
  },
  CFF: {
    key: 'cffTermsOfService',
  },
  FUND_FORECAST: {
    key: 'fundForecastTermsOfService',
  },
  all() {
    return [this.CFF, this.FUND_FORECAST, this.GENERAL];
  },
};

module.exports = AGREEMENT_TYPES;
