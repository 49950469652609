import React from 'react';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFundManager } from 'src/hooks';
import { AppPanel, FundManagerNewsVisualization, IconScatterPlot, IconList } from 'src/components';

const VIEWS = {
  list: 'list',
  plot: 'plot',
};

function FundManagerNewsPanel({ spanX, spanY, minHeight, fundManagerId }) {
  const [view, setView] = useState(VIEWS.list);
  const fundManager = useFundManager({ fundManagerId }) || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const title = useMemo(() => {
    if (isExpanded) return `${fundManager.name} - News`;
    return 'Fund Manager News';
  }, [fundManager.name, isExpanded]);

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title={title}
      onChangeExpanded={setIsExpanded}
      controls={
        <div>
          <AppPanel.Button startIcon={<IconList />} selected={view === VIEWS.list} onClick={() => setView(VIEWS.list)}>
            List
          </AppPanel.Button>
          <AppPanel.Button
            startIcon={<IconScatterPlot />}
            selected={view === VIEWS.plot}
            onClick={() => setView(VIEWS.plot)}
          >
            Plot
          </AppPanel.Button>
        </div>
      }
      expandable
    >
      <FundManagerNewsVisualization fundManager={fundManager} showPlot={view === VIEWS.plot} />
    </AppPanel>
  );
}

FundManagerNewsPanel.defaultProps = {};

FundManagerNewsPanel.propTypes = {
  fundManagerId: PropTypes.string.isRequired,
};

export default FundManagerNewsPanel;
