import React from 'react';
import Button from '../Button';
import Icon from '../IconImageDownload';

const ButtonFilters = ({ busy, ...props }) => {
  return (
    <Button
      startIcon={<Icon />}
      css={`
        color: #fff;
        background-color: #0d8bab;
        padding-right: 12px;
        padding-left: 16px;
        font-size: 18px;
        &:hover {
          background-color: #096177;
        }
        .MuiButton-label {
          position: relative;
          top: -1px;
          left: -2px;
        }
        .MuiSvgIcon-root {
          width: 28px;
          height: 28px;
          position: relative;
          top: 1px;
          left: -4px;
        }
      `}
      {...props}
    >
      {busy ? busy : 'Download Image'}
    </Button>
  );
};

ButtonFilters.defaultProps = {};

ButtonFilters.propTypes = {};

export default ButtonFilters;
