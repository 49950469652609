import React from 'react';
import { useRef } from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow } from '@material-ui/core';
import { TooltipContent, TooltipCloseIcon, TooltipHeader } from 'src/components';

const bulletCss = css`
  margin: 8px 0;
  padding: 0;
`;

const termCss = css`
  font-weight: 500;
  margin-right: 10px;
`;

const Layout = styled.div``;

const Main = styled.div`
  max-width: 500px;
  max-height: 400px;
  padding: 0 20px;
  color: #000;
  fontsize: 12px;
  overflow: auto;
`;

const Title = styled.h3`
  margin: 0;
  color: #838383;
  text-transform: uppercase;
`;

const Arrow = styled.div`
  position: absolute;

  &::before {
    content: '';
    display: block;
    width: 1.5em;
    height: 1.5em;
    background-color: #eeeeee;
    transform: rotate(45deg);
  }
`;

const ArrowTop = styled(Arrow)`
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ArrowBottom = styled(Arrow)`
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const ArrowLeft = styled(Arrow)`
  top: 50%;
  left: 0;
  transform: translate(-50%, 50%);
`;

const ArrowRight = styled(Arrow)`
  top: 50%;
  right: 0;
  transform: translate(50%, 50%);
`;

function DefinitionsTooltip({ open, anchorEl, onClose }) {
  const arrowRef = useRef();

  return (
    <Popper
      style={{ zIndex: 100, position: 'relative' }}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0,15',
        },
        arrow: {
          enabled: true,
          element: arrowRef.current,
        },
        flip: {
          enabled: true,
        },
      }}
    >
      {({ TransitionProps, placement }) => {
        const top = placement === 'top';
        const bottom = placement === 'bottom';
        const left = placement === 'left';
        const right = placement === 'right';

        return (
          <Grow {...TransitionProps} timeout={300}>
            <Layout>
              {top && <ArrowBottom ref={arrowRef} />}
              {bottom && <ArrowTop ref={arrowRef} />}
              {left && <ArrowRight ref={arrowRef} />}
              {right && <ArrowLeft ref={arrowRef} />}
              <TooltipContent>
                <TooltipHeader>
                  <br />
                  <TooltipCloseIcon margin="0 0 20px 0" onClick={onClose} />
                </TooltipHeader>
                <Main>
                  <Title>Definitions</Title>
                  <p css={bulletCss}>
                    <span css={termCss}>DPI.</span>A measurement of how much a fund has paid out to investors. A high
                    multiple is preferable to investors. DPI stands for Distributions to Paid In Capital. It is
                    calculated by dividing the amount distributed to investors divided by the amount investors have
                    invested to date.
                  </p>
                  <p css={bulletCss}>
                    <span css={termCss}>TVPI.</span>A measurement of the value of a fund relative to how much capital
                    has been invested. A high multiple is preferable to investors. TVPI stands for Total Value to Paid
                    In Capital. It is calculated by dividing the Total Value of a fund (a combination of remaining Fair
                    Market Value plus Distributions to date) by the amount of capital investors have invested to date.
                  </p>
                  <p css={bulletCss}>
                    <span css={termCss}>RVPI.</span>A measurement of the remaining value of the fund relative to how
                    much capital has been invested. The value will decrease in time as the fund matures. RVPI stands for
                    Remaining Value to Paid In Capital. It is calculated by dividing the Remaining Value of a fund (Fair
                    Market Value or Net Asset Value) by the amount of capital investors have invested to date.
                  </p>
                  <p css={bulletCss}>
                    <span css={termCss}>Net IRR.</span>A measurement of a fund’s overall performance taking into account
                    the time value of money. If a fund invests and havests those investments early then the Net IRR will
                    be high, however the longer a fund takes to harvest investments, the lower the Net IRR falls. Net
                    IRR is after fees and carried interest (profit share with the fund manager) have been deducted.
                  </p>
                  <p css={bulletCss}>
                    <span css={termCss}>% Capital Called.</span>A measurement of how much capital a fund has drawn down
                    in proportion to the original commitment it made to the fund. It allows investors to see how much of
                    their commitment has been invested. It is calculated by dividing the amount of capital investors
                    have invested to date by investors original commitment.
                  </p>
                  <p css={bulletCss}>
                    <span css={termCss}>Vintage</span>In order to compare funds to one another, funds need to be
                    investing in approximately the same investment cycle to be comparable. The vintage of a fund is
                    typically noted as the year when a fund first begins to invest, however some investors look at when
                    a fund was formed or when it has its final close. For benchmarking purposes we compare funds to
                    other funds of the same year as wells to those funds one year younger and one year older as all
                    three vintages of funds will be investing in similar investment cycles, at the same time and likely
                    be competing against each other for the same or similar portfolio assets.
                  </p>
                </Main>
              </TooltipContent>
            </Layout>
          </Grow>
        );
      }}
    </Popper>
  );
}
DefinitionsTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};

DefinitionsTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default DefinitionsTooltip;
