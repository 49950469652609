import React from 'react';
import { domUtil } from '@fundfilter/core/src/utils';
import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { md } from 'src/config/layout';
import { deleteUserFund } from 'src/store/funds';
import { deleteUserFundFinancialHistory } from 'src/store/fund-financial-history';
import { BookmarkedFundsTable, ConfirmDialog, FundAddEditDialog, SmallCircle } from 'src/components';

function FundsView({ funds, isExpanded, wideTable, onRowClick, selectedId, onChangeSelectedId }) {
  const dispatch = useDispatch();
  const [fundIdToEdit, setFundIdToEdit] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState();
  const [confirmDialogMessage, setConfirmDialogMessage] = useState();
  const confirmDialogCloseHandler = useRef();

  const handleFundsTableColumnClick = useCallback(
    function (e, { column, data }) {
      switch (column.key) {
        case 'remove':
          e.preventDefault();
          e.stopPropagation();
          confirmDialogCloseHandler.current = async dialogResult => {
            setConfirmDialogOpen(false);
            confirmDialogCloseHandler.current = null;
            const { fundId } = data;
            if (!dialogResult) return;

            if (data.userId) {
              dispatch(deleteUserFund({ fundId }));
              dispatch(deleteUserFundFinancialHistory({ fundId }));
            }
          };
          setConfirmDialogMessage(data.userId ? 'Delete this fund?' : 'Stop following this fund?');
          setConfirmDialogOpen(true);

          return;

        case 'edit':
          if (!data.userId) return;
          e.preventDefault();
          e.stopPropagation();
          setFundIdToEdit(data.fundId);
          return;

        default:
          return;
      }
    },
    [dispatch]
  );

  function handleRowClick(event, { data }) {
    if (!event.target) return;
    onChangeSelectedId(data.fundId);
    if (domUtil.hasParentClass(event.target, 'MuiPaper-root')) return;
    onRowClick();
  }

  if (!funds) return null;

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        height: calc(100%);
        justify-content: space-between;
      `}
    >
      <BookmarkedFundsTable
        parent="fundsView"
        data={funds}
        selectedId={selectedId}
        expanded={isExpanded}
        wideTable={wideTable}
        enableHover
        onRowClick={handleRowClick}
        onColumnClick={handleFundsTableColumnClick}
        css={`
          height: calc(100% - 30px);
          padding: 10px;

          .edit {
            opacity: 0;
            pointer-events: none;
          }

          @media (min-width: ${md.BREAKPOINT}px) {
            .edit {
              opacity: unset;
              pointer-events: unset;
            }
          }
        `}
      />
      <div
        css={`
          display: flex;
          align-items: center;
          margin-left: 15px;
        `}
      >
        <SmallCircle />
        <span
          css={`
            color: #5e5e5e;
            margin-left: 10px;
            font-size: 11px;
          `}
        >
          Funds added manually by you
        </span>
      </div>
      {fundIdToEdit && (
        <FundAddEditDialog
          open={Boolean(fundIdToEdit)}
          fundId={fundIdToEdit}
          onClose={() => setFundIdToEdit(null)}
          viewFundAfterSave={true}
        />
      )}
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={confirmDialogCloseHandler.current}
        message={confirmDialogMessage}
      />
    </div>
  );
}

FundsView.defaultProps = {};

FundsView.propTypes = {
  children: PropTypes.any,
};

export default FundsView;
