import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as patterns from 'src/config/patterns';
import { Button, IconPencil } from 'src/components';
import FormTextField from './FormTextField';
import FormSubmitButton from './FormSubmitButton';
import FormCancelButton from './FormCancelButton';
import ConfirmPasswordDialog from './ConfirmPasswordDialog';
import { useUpdate as useUpdateAuth } from 'src/services/filter-api/authentication';

function AccountUserPasswordForm() {
  const { register, handleSubmit, formState, reset, watch } = useForm({
    mode: 'onChange',
  });
  const { isValid, errors, isSubmitting } = formState;
  const newPasswordValue = watch('newPassword', '');

  const [isEditing, setIsEditing] = useState(false);
  const [requirePassword, setRequirePassword] = useState(false);
  const update = useUpdateAuth().mutateAsync;

  const handleSubmitForm = handleSubmit(async function (data) {
    if (!isValid) return;

    try {
      await update({ password: data.newPassword });
      setIsEditing(false);
      setRequirePassword(false);
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setRequirePassword(true);
      } else {
        throw new Error(error);
      }
    }
  });

  function handleClickCancel() {
    reset();
    setIsEditing(false);
    setRequirePassword(false);
  }

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <section>
          <header>
            <h3>Change Password</h3>
            <Button size="small" variant="outlined" disabled={isEditing} onClick={() => setIsEditing(true)}>
              <IconPencil />
              Edit
            </Button>
          </header>
          <span />

          <FormTextField
            register={register}
            errors={errors}
            name="newPassword"
            type="password"
            label="New Password"
            disabled={!isEditing}
            validations={{
              required: 'New Password cannot be blank',
              pattern: {
                value: patterns.password,
                message: 'Password must be between 6 and 50 characters',
              },
            }}
          />

          <FormTextField
            register={register}
            errors={errors}
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            disabled={!isEditing}
            validations={{
              required: 'Confirm Password is required',
              validate: {
                matches: value => value === newPasswordValue || 'Confirmation should match your new password',
              },
            }}
          />

          <footer>
            <FormSubmitButton isSubmitting={isSubmitting} disabled={!isEditing || !isValid} />
            <FormCancelButton disabled={!isEditing} onClick={handleClickCancel} />
          </footer>
        </section>
      </form>

      <ConfirmPasswordDialog open={requirePassword} onSuccess={handleSubmitForm} onFailure={handleClickCancel} />
    </>
  );
}

AccountUserPasswordForm.defaultProps = {};

AccountUserPasswordForm.propTypes = {
  children: PropTypes.any,
};

export default AccountUserPasswordForm;
