import React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { parseISO } from 'src/utils/date';
import { md } from 'src/config/layout';
import { useFund, useExponentialBackoff } from 'src/hooks';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { useGet as useGetUserFund } from 'src/services/filter-api/userFunds/queries';
import {
  useGet as useUserAgreementsQuery,
  useSet as useUserAgreementsMutation,
} from 'src/services/filter-api/userAgreements';
import { Select, AgreeToContinueDialog, ViewForecastCheckbox, DownloadCsvButton, ToggleButtons } from 'src/components';
import { AGREEMENT_TYPES } from '@fundfilter/core/src/constants';
import CashflowDataVisualization from 'src/components/molecules/CashflowDataVisualization';

const { TYPES, VIEWS } = CashflowDataVisualization.CONSTANTS;
const { useFundCashflowData } = CashflowDataVisualization;

const Layout = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.header`
  padding: 0.75rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 0;
  }
`;

const SelectInput = styled(Select)`
  font-size: inherit;

  & .MuiInputBase-root {
    height: 28px;
  }
`;

const Message = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  padding: 1.5em 2em;
`;

function FundsJCurveView({ fundId, onChangeHistoryData }) {
  const systemFund = useFund({ fundId });
  const getUserFund = useGetUserFund(fundId.startsWith('u_') && fundId);

  const fund = useMemo(() => {
    if (getUserFund.data) return getUserFund.data;
    return systemFund;
  }, [getUserFund.data, systemFund]);

  const { forecastSeries, historySeries, startDate, isMatureFund, isLoading, isLoadingTimeseries } =
    useFundCashflowData({
      fundId: fund?.fundId,
      date: fund?.date,
      vintage: fund?.vintage,
      commitmentAmount: 100_000,
      preloadForecast: false,
    });

  const [type, setType] = useState(TYPES.JCURVE);
  const [view, setView] = useState(VIEWS.CHART);
  const [showForecast, setShowForecast] = useState(false);

  const userAgreementsQuery = useUserAgreementsQuery();
  const userAgreementMutation = useUserAgreementsMutation();
  const tosAccepted = userAgreementsQuery.isLoading || userAgreementsQuery.data?.[AGREEMENT_TYPES.FUND_FORECAST.key];

  function handleConfirmUserAgreement() {
    userAgreementMutation.mutate(AGREEMENT_TYPES.FUND_FORECAST.key);
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundForecastAcceptTos);
  }

  const queryClient = useQueryClient();

  useExponentialBackoff(1000, 8000, () => {
    queryClient.invalidateQueries('fundTimeseries', fundId);
    queryClient.invalidateQueries('fundForecast', fundId);
  });

  const insufficientData = historySeries.length < 2;

  const forecastDisabled = isLoading || isMatureFund || insufficientData;

  useEffect(() => {
    if (isLoadingTimeseries) return;
    onChangeHistoryData(historySeries);
  }, [historySeries, isLoadingTimeseries, onChangeHistoryData]);

  const csvData = useRef();

  return (
    <Layout>
      <Header>
        <div
          css={`
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 1em;
          `}
        >
          <SelectInput
            preset="compact"
            labelType="inline"
            label="Chart Type:"
            variant="outlined"
            size="small"
            items={[TYPES.JCURVE, TYPES.NAV, TYPES.CASHFLOW]}
            onChange={event => setType(event.target.value)}
            value={type}
          />
          <ToggleButtons
            sizeVariant="small"
            value={view}
            options={[VIEWS.CHART, VIEWS.TABLE]}
            onChange={event => setView(event.target.value)}
          />
          {view === VIEWS.TABLE && <DownloadCsvButton getData={() => csvData.current} filename={fund?.name} />}
        </div>
        <ViewForecastCheckbox
          checked={showForecast}
          disabled={forecastDisabled}
          isLoading={isLoading}
          onChange={evt => setShowForecast(evt.target.checked)}
          isMatureFund={isMatureFund}
          size="small"
          color="primary"
        />
      </Header>
      {isLoadingTimeseries ? (
        <Message>Loading...</Message>
      ) : insufficientData ? (
        <Message>
          <p>
            Not enough data exists to draw a history of this fund. To be Meaningful four consequtive quarters of
            historical data are&nbsp;Recommended.
          </p>
          <p>
            To generate a forecast for this fund a minimum Of four consequtive quarters of historical data
            is&nbsp;needed.
          </p>
        </Message>
      ) : (
        <CashflowDataVisualization
          type={type}
          view={view}
          subjectTimeseries={historySeries}
          forecastTimeseries={showForecast ? forecastSeries.slice(0, 20) : []}
          startDate={parseISO(startDate)}
          showAxisLabels={true}
          showMilestoneMarkers={true}
          onChangeTableRows={data => {
            csvData.current = data;
          }}
        />
      )}

      <AgreeToContinueDialog
        open={showForecast && !tosAccepted}
        onClickConfirm={handleConfirmUserAgreement}
        onClickCancel={() => setShowForecast(false)}
        isBusy={!userAgreementMutation.isIdle}
        title="Cashflow Forecasting Terms of Service"
      >
        <p>
          Information displayed on this screen is based on models and/or tools which use publicly available historical
          information to predict future outcomes or results. These models and/or tools are intended for informational
          use only, and you acknowledge and agree that FundFilter (nor Altman Inc) cannot and does not guarantee any
          particular outcomes or results, and that past or historical results are not indicative of and do not guarantee
          future performance, outcomes or results. Always obtain relevant and specific independent professional advice
          before making any investment or other decision.
        </p>
      </AgreeToContinueDialog>
    </Layout>
  );
}

FundsJCurveView.defaultProps = {
  onChangeHistoryData: () => {},
};

FundsJCurveView.propTypes = {
  fundId: PropTypes.string.isRequired,
  onChangeHistoryData: PropTypes.func,
};

export default FundsJCurveView;
