import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const Container = styled.div`
  padding: ${props => props.padding};
  position: relative;
  padding-bottom: 100%;
  margin-bottom: 1.5em;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;
`;

function FundsRaisedTotals(props) {
  const { data, onItemOver, onItemOut, onItemClick, onItemTouchStart, onItemTouchEnd, width, height, targetRef } =
    props;

  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    draw({
      id: id.current,
      data,
      size: { width, height },
      margin: { top: 0, right: 100, bottom: 0, left: 0 },
      mouseEventRefs,
    });
  }, [data, width, height, mouseEventRefs]);

  return (
    <Container ref={targetRef}>
      <Svg id={id.current} $width={width} $height={height} />
    </Container>
  );
}

FundsRaisedTotals.defaultProps = {
  expanded: false,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

FundsRaisedTotals.propTypes = {
  data: PropTypes.array.isRequired,
  expanded: PropTypes.bool,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(FundsRaisedTotals);
