import React from 'react';
import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { lg } from 'src/config/layout';
import { parseISO } from 'src/utils/date';
import { IconAdd, LoadingIndicator } from 'src/components';
import ListHeader from './Header';
import ListBody from './List';
import ViewFundDialog from './ViewFundDialog';
import ViewTargetFundDialog from './ViewTargetFundDialog';
import { useAddBookmark } from 'src/services/filter-api/userPortfolios/mutations';
import AddExistingFundDialog from './AddExistingFundDialog';

function getItemType(item) {
  const isFund = Boolean(item.fundId);
  const isUserFund = isFund && item.fundId.startsWith('u_');
  const isTargetFund = Boolean(item.targetFundId);
  return { isFund, isUserFund, isTargetFund };
}

const Layout = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(ListHeader)``;

const List = styled(ListBody)`
  flex: 1;
  overflow-y: auto;
  color: white;
  max-height: 60vh;

  @media (min-width: ${lg.BREAKPOINT}px) {
    max-height: auto;
  }
`;

const Footer = styled.div`
  padding: 1.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddFundButton = styled(Button)`
  font-size: 0.75em;
  padding: 0.375em 1.5em;
  background-color: #ffffff26;

  .MuiSvgIcon-root {
    transform: translateX(-25%);
  }
`;

function ForecastItems({
  items,
  isFetchingItems,
  isFetchingForecasts,
  portfolioId,
  onChangeNavTarget,
  onChangePortfolio,
  view,
}) {
  const [itemToView, setItemToView] = useState('');
  const [openAddFundDialog, setOpenAddFundDialog] = useState(false);

  const fundIdToView = useMemo(() => {
    if (!itemToView) return null;
    const { isFund } = getItemType(itemToView);
    if (!isFund) return null;
    return itemToView.fundId;
  }, [itemToView]);

  const strategyIdToView = useMemo(() => {
    if (!itemToView) return null;
    const { isTargetFund } = getItemType(itemToView);
    if (!isTargetFund) return null;
    return itemToView.strategyId;
  }, [itemToView]);

  const addBookmark = useAddBookmark().mutate;

  function handleAddBookmark(fundId) {
    if (!fundId) {
      console.warn('No fundId provided to handleAddBookmark');
      return;
    }

    if (!portfolioId) {
      console.warn('No portfolioId provided to handleAddBookmark');
      return;
    }
    const commitmentAmount = 1_000_000;
    addBookmark({ portfolioId, fundId, commitmentAmount });
  }

  return (
    <Layout>
      <Header portfolioId={portfolioId} onChangePortfolio={onChangePortfolio} onChangeNavTarget={onChangeNavTarget} />

      <List portfolioId={portfolioId} items={items} onClickViewItem={setItemToView} isFetching={isFetchingItems} />

      <Footer>
        <AddFundButton onClick={() => setOpenAddFundDialog(true)}>
          <IconAdd /> Add an existing fund to this portfolio
        </AddFundButton>

        <LoadingIndicator display={isFetchingForecasts} message="Calculating forecasts" />
      </Footer>

      {Boolean(fundIdToView) && (
        <ViewFundDialog
          view={view}
          open={Boolean(fundIdToView)}
          fundId={fundIdToView}
          onClose={() => setItemToView(null)}
        />
      )}
      {Boolean(strategyIdToView) && (
        <ViewTargetFundDialog
          view={view}
          open={Boolean(strategyIdToView)}
          strategyId={strategyIdToView}
          commitmentAmount={itemToView?.commitmentAmount}
          startDate={itemToView ? parseISO(itemToView.startDate) : undefined}
          title={itemToView?.name}
          onClose={() => setItemToView(null)}
        />
      )}
      {openAddFundDialog && (
        <AddExistingFundDialog
          open={openAddFundDialog}
          onClose={fundId => {
            handleAddBookmark(fundId);
            setOpenAddFundDialog(false);
          }}
        />
      )}
    </Layout>
  );
}

ForecastItems.defaultProps = {
  funds: [],
  fundForecastQueries: [],
  hiddenFunds: [],
  onChangeFundHidden: funcStub('onChangeFundHidden'),
};

ForecastItems.propTypes = {
  funds: PropTypes.array,
  isLoading: PropTypes.bool,
  fundForecastQueries: PropTypes.array,
  hiddenFunds: PropTypes.array,
  navTarget: PropTypes.number,
  setNavTarget: PropTypes.func,
  onChangeFundHidden: PropTypes.func,
  onChangeRowCommitmentAmount: PropTypes.func,
  portfolioId: PropTypes.string,
  onChangePortfolio: PropTypes.func,
};

export default ForecastItems;
