import styled from 'styled-components';

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => props.padding || '8px 16px 16px 16px'};
  border-radius: 8px;
  background-color: ${props => props.backgroundColor || props.theme.palette.grey[200]};
  color: ${props => props.color};
`;

export default TooltipContent;
