import React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { TreeView as MuiTreeView, TreeItem as MuiTreeItem } from '@material-ui/lab';
import { Box, Typography, TreeviewCheckbox, IconRightCaret, IconDownCaret } from 'src/components';

const Root = styled(Box)``;

const TreeView = styled(MuiTreeView)`
  color: ${({ theme }) => theme.palette.text.secondary};
  min-width: 10em;
`;

const TreeItem = styled(MuiTreeItem)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  .MuiTreeItem-content {
    background-color: transparent !important;
  }

  .MuiTreeItem-iconContainer {
    display: none;
  }

  .MuiTreeItem-label {
    background-color: transparent !important;
  }
`;

const TreeItemText = styled(Typography)`
  color: #9b9b9b;
  font-size: 13px;
`;

const CheckBox = styled(TreeviewCheckbox)`
  margin-right: ${({ theme }) => `${theme.spacing(0.5)}px`};
  margin-top: ${({ theme }) => `${theme.spacing(-0.5)}px`};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const caretStyles = css`
  color: #9b9b9b;
`;

const CaretRight = styled(IconRightCaret)`
  ${caretStyles};
`;

const CaretDown = styled(IconDownCaret)`
  ${caretStyles};
`;

function CheckBoxTreeItem({ node, idProp, labelProp, labelFormat, onClickNode }) {
  const { children } = node;
  const hasChildren = Boolean(children && children.length);

  const [expandedState, setExpandedState] = useState(false);

  function handleClickCheckbox() {
    if (!hasChildren) return onClickNode(node, { selected: !node.selected });

    node.children.forEach(child => {
      onClickNode(child, { selected: !node.selected });
    });
  }

  function handleClickLabel() {
    if (hasChildren) return setExpandedState(state => !state);
    onClickNode(node, { selected: !node.selected });
  }

  return (
    <TreeItem
      disabled={Boolean(node.disabled)}
      nodeId={node[idProp].toString()}
      label={
        <Box display="flex" alignItems="center" onClick={handleClickLabel}>
          <CheckBox
            disabled={Boolean(node.disabled)}
            checked={node.selected ?? false}
            onClick={e => {
              handleClickCheckbox();
              e.stopPropagation();
            }}
          />
          <TreeItemText>{labelFormat(node[labelProp])}</TreeItemText>
          {hasChildren && (expandedState ? <CaretDown /> : <CaretRight />)}
        </Box>
      }
    >
      {node.children &&
        node.children.map(childNode => (
          <CheckBoxTreeItem
            key={childNode[idProp]}
            disabled={Boolean(childNode.disabled)}
            idProp={idProp}
            labelProp={labelProp}
            node={childNode}
            onClickNode={onClickNode}
          />
        ))}
    </TreeItem>
  );
}

function FilterTreeView({ data, idProp, labelProp, labelFormat, onClickNode, ...rest }) {
  return (
    <Root {...rest}>
      <TreeView>
        {data.map(node => (
          <CheckBoxTreeItem
            key={node[idProp]}
            idProp={idProp}
            labelProp={labelProp}
            labelFormat={labelFormat}
            node={node}
            onClickNode={onClickNode}
          />
        ))}
      </TreeView>
    </Root>
  );
}

CheckBoxTreeItem.defaultProps = {
  labelFormat: value => value,
  onClickNode: () => {},
};

CheckBoxTreeItem.propTypes = {
  node: PropTypes.object.isRequired,
  idProp: PropTypes.string.isRequired,
  labelProp: PropTypes.string.isRequired,
  labelFormat: PropTypes.func.isRequired,
};

FilterTreeView.defaultProps = {
  labelFormat: value => value,
  onClickNode: () => {},
};

FilterTreeView.propTypes = {
  data: PropTypes.array.isRequired,
  idProp: PropTypes.string.isRequired,
  labelProp: PropTypes.string.isRequired,
  onClickNode: PropTypes.func.isRequired,
  labelFormat: PropTypes.func.isRequired,
};

export default FilterTreeView;
