import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconSortDesc(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <rect x="4" y="4" width="16" height="3" />
      <rect x="4" y="10" width="8" height="3" />
      <rect x="4" y="16" width="4" height="3" />
    </Svg>
  );
}
