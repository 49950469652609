import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Delete as IconDelete, Done as IconDone } from '@material-ui/icons';
import { Button } from 'src/components';

const RowButton = styled(Button)`
  font-size: 1em;
  min-width: unset;
  border-radius: 0;
  padding: 2px 0.5em;

  .MuiSvgIcon-root {
    font-size: inherit;
  }

  background-color: #ffffff22;
  background-color: $(p => p.$background);

  &:hover {
    background-color: ${p => p.$hover};
    border-color: ${p => p.$hover};
  }

  & + & {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const Layout = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: end;
`;

function EditControls({ saveDisabled, onClickDelete, onClickSave }) {
  const [isDeleting, setIsDeleting] = useState(false);
  function handleToggle() {
    setIsDeleting(!isDeleting);
  }

  if (isDeleting) {
    return (
      <Layout>
        <RowButton $background="#ef1167" onClick={onClickDelete}>
          <IconDelete />
          Delete this portfolio?&nbsp;
        </RowButton>
        <RowButton $background="transparent" onClick={handleToggle}>
          Cancel
        </RowButton>
      </Layout>
    );
  }

  return (
    <Layout>
      <RowButton $hover="#00cc9a" disabled={saveDisabled} onClick={onClickSave}>
        <IconDone /> Save
      </RowButton>
      <RowButton $hover="#ef1167" onClick={handleToggle}>
        &nbsp;
        <IconDelete />
        &nbsp;
      </RowButton>
    </Layout>
  );
}

EditControls.defaultProps = {
  saveDisabled: false,
  onClickDelete: () => {},
  onClickSave: () => {},
};

EditControls.propTypes = {
  saveDisabled: PropTypes.bool,
  onClickDelete: PropTypes.func,
  onClickSave: PropTypes.func,
};

export default EditControls;
