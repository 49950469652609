/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'src/lib/lodash';
import { getLeafs } from '../utils';
import { getFundsBySubStrategy } from '../store/funds';
import useStrategyHierarchy from './useStrategyHierarchy';
import useTopLevelRegions from './useTopLevelRegions';
import useSizeHierarchy from './useSizeHierarchy';

function useFundHierarchy() {
  const strategyHierarchy = useStrategyHierarchy();
  const topLevelRegions = useTopLevelRegions();
  const sizeHierarchy = useSizeHierarchy();

  const [fundHierarchy, setFundHierarchy] = useState(null);
  const fundsBySubStrategy = useSelector(state => getFundsBySubStrategy(state));

  useEffect(() => {
    if (fundsBySubStrategy && topLevelRegions && strategyHierarchy && sizeHierarchy) {
      const hierarchy = cloneDeep(strategyHierarchy.map(s => ({ ...s, idProp: 'strategyId' })));
      const subStrategyNodes = getLeafs(hierarchy);
      subStrategyNodes.forEach(subStrategyNode => {
        const subStrategyFunds = fundsBySubStrategy[subStrategyNode.strategyId];

        if (!subStrategyFunds) return;

        subStrategyNode.children = cloneDeep(topLevelRegions);
        subStrategyNode.children.forEach(regionNode => {
          regionNode.children = cloneDeep(sizeHierarchy);
          regionNode.children.forEach(sizeNode => {
            sizeNode.size = subStrategyFunds.filter(
              fund => fund.mainRegionGroupId === regionNode.regionId && fund.sizeId === sizeNode.sizeId
            ).length;
          });
        });
      });
      setFundHierarchy({ label: '', children: hierarchy });
    }
  }, [fundsBySubStrategy, topLevelRegions, strategyHierarchy, sizeHierarchy]);

  return fundHierarchy;
}

export default useFundHierarchy;
