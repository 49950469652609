import { useState, useEffect } from 'react';
import { createHierarchy } from '../utils';
import useSizes from './useSizes';

const createSizeHierarchy = createHierarchy('sizeId', 'parentId');

function useSizeHierarchy() {
  const [sizeHierarchy, setSizeHierarchy] = useState(null);
  const sizes = useSizes();

  useEffect(() => {
    if (sizes) {
      setSizeHierarchy(createSizeHierarchy(sizes));
    }
  }, [sizes]);

  return sizeHierarchy;
}

export default useSizeHierarchy;
