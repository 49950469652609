import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core/';
import { Button, TextField, CheckboxLarge } from 'src/components';
import { Popper } from '@material-ui/core';
import { TooltipArrowDown, TooltipContent, TooltipCloseIcon, TooltipHeader } from 'src/components';

const Form = styled.form`
  min-width: 250px;
  max-width: 400px;
`;

const CheckboxSection = styled.fieldset`
  font-size: 14px;
  margin: 1em 0;
  padding: 0;
  display: block;
  border: none;

  label {
    display: flex;
  }

  span {
    font-size: inherit;
    color: inherit;
    border-color: currentColor;
  }
`;

const Label = styled(FormControlLabel)`
  color: #818181;
  margin: 0;
  display: block;

  a {
    color: #aaa;
  }
`;

function UserSignUpForm({ onSubmit, isSubmitted, className }) {
  const searchParams = new URLSearchParams(window.location.search);

  const [email, setEmail] = useState(searchParams.get('email') ?? '');
  const [name, setName] = useState(searchParams.get('name') ?? '');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [educational, setEducational] = useState(false);
  const [educationalPopoverOpen, setEducationalPopoverOpen] = useState(false);
  const educationalAnchorRef = React.useRef(null);
  const [tosAccepted, setTosAccepted] = useState(Boolean(searchParams.get('tosAccepted')));

  async function handleSubmit(event) {
    event.preventDefault();
    onSubmit({ email, password, name, remember, educational });
  }

  return (
    <Form className={className}>
      <TextField
        preset="authForm"
        required
        label="Required"
        type="text"
        placeholder="name"
        defaultValue={name}
        onChange={e => setName(e.target.value)}
      />
      <TextField
        preset="authForm"
        required
        label="Required"
        type="email"
        placeholder="Business Email"
        defaultValue={email}
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        preset="authForm"
        required
        label="Required"
        type="password"
        placeholder="password"
        onChange={e => setPassword(e.target.value)}
      />

      <CheckboxSection>
        <Label
          control={
            <CheckboxLarge checked={tosAccepted} color="primary" onChange={e => setTosAccepted(e.target.checked)} />
          }
          label={
            <>
              I Accept the{' '}
              <a href="https://altman.ai/terms-of-service" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </>
          }
        />
        <Label
          control={<CheckboxLarge color="primary" onChange={e => setRemember(e.target.checked)} />}
          label="Remember me"
        />
        <Label
          control={
            <CheckboxLarge
              color="primary"
              onChange={e => {
                setEducational(e.target.checked);
                setEducationalPopoverOpen(e.target.checked);
              }}
            />
          }
          label={
            <>
              <span ref={educationalAnchorRef}>Educational Account</span>
              <Popper
                style={{
                  zIndex: 100,
                  maxWidth: 600,
                  marginLeft: 20,
                  marginRight: 20,
                }}
                open={educationalPopoverOpen}
                anchorEl={educationalAnchorRef.current}
                transition
                placement="top"
                modifiers={{
                  offset: {
                    enabled: true,
                    offset: '0,15',
                  },
                  arrow: {
                    enabled: true,
                    element: '.create-portfolio-info-arrow',
                  },
                }}
              >
                <div
                  css={`
                    overflow: auto;
                  `}
                >
                  <TooltipArrowDown className="create-portfolio-info-arrow" />
                  <TooltipContent padding="20px" color="#000" fontSize="12px">
                    <TooltipHeader>
                      <h3
                        css={`
                          margin: 5px 0;
                          padding: 0;
                        `}
                      >
                        Educational Account
                      </h3>
                      <TooltipCloseIcon
                        margin="0 0 0 8px"
                        onClick={() => {
                          setEducationalPopoverOpen(false);
                        }}
                      />
                    </TooltipHeader>
                    <div>
                      <p>
                        An Educational Account is a complementary account offered to full time students and faculty
                        members. By requesting such an account you agree to:
                      </p>
                      <ul
                        css={`
                          padding: 1em;
                          margin: 1em;
                        `}
                      >
                        <li>
                          <a href="https://altman.ai/terms-of-service" target="_blank" rel="noopener noreferrer">
                            Terms of Service
                          </a>
                          , specifically Clause 7.3
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/fundfilter">Follow FundFilter on LinkedIn</a> for
                          our verification purposes. Failure to do so will result in an expiration at the end of the
                          trial period as per a standard account.
                        </li>
                      </ul>
                    </div>
                  </TooltipContent>
                </div>
              </Popper>
            </>
          }
        />
      </CheckboxSection>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!tosAccepted || !name || !email || !password || isSubmitted}
        $isLoading={isSubmitted}
        $block
        type="submit"
      >
        Create Account
      </Button>
    </Form>
  );
}

UserSignUpForm.defaultProps = {
  onSignIn: () => {},
  onCreateAccount: () => {},
};

UserSignUpForm.propTypes = {
  onSignIn: PropTypes.func,
  onCreateAccount: PropTypes.func,
};

export default UserSignUpForm;
