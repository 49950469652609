import React from 'react';
import { useState } from 'react';
import { AppPanel, DashboardNews, IconScatterPlot, IconList } from 'src/components';

const VIEWS = {
  list: 'list',
  plot: 'plot',
};

function DashboardNewsPanel({ spanX, spanY, minHeight }) {
  const [view, setView] = useState(VIEWS.list);

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title="Dashboard News"
      controls={
        <div>
          <AppPanel.Button startIcon={<IconList />} selected={view === VIEWS.list} onClick={() => setView(VIEWS.list)}>
            List
          </AppPanel.Button>
          <AppPanel.Button
            startIcon={<IconScatterPlot />}
            selected={view === VIEWS.plot}
            onClick={() => setView(VIEWS.plot)}
          >
            Plot
          </AppPanel.Button>
        </div>
      }
      expandable
    >
      <DashboardNews showPlot={view === VIEWS.plot} />
    </AppPanel>
  );
}

DashboardNewsPanel.defaultProps = {};

DashboardNewsPanel.propTypes = {};

export default DashboardNewsPanel;
