import React from 'react';
import styled from 'styled-components';
import { UserFundBenchmarkIndicator } from 'src/components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

function UserFundBenchmarkTableIndicator() {
  return (
    <Wrapper>
      <UserFundBenchmarkIndicator ml={0} mb={0} />
    </Wrapper>
  );
}

export default UserFundBenchmarkTableIndicator;
