import React from 'react';
import { useRef, useCallback } from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { TooltipContent } from 'src/components';

const bulletCss = css`
  margin: 8px 0;
  padding: 0;
`;

const termCss = css`
  font-weight: 500;
  margin-right: 10px;
`;

const Layout = styled.div``;

const Main = styled.div`
  max-width: 500px;
  color: #000;
  fontsize: 12px;
  overflow: auto;
`;

const Arrow = styled.div`
  position: absolute;

  &::before {
    content: '';
    display: block;
    width: 1em;
    height: 1em;
    background-color: #eeeeee;
    transform: rotate(45deg);
  }
`;

const ArrowTop = styled(Arrow)`
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ArrowBottom = styled(Arrow)`
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const ArrowLeft = styled(Arrow)`
  top: 50%;
  left: 0;
  transform: translate(-50%, 50%);
`;

const ArrowRight = styled(Arrow)`
  top: 50%;
  right: 0;
  transform: translate(50%, 50%);
`;

function PMEInfoTooltip({ open, anchorEl, onClose }) {
  const arrowRef = useRef();

  const onClickAway = useCallback(
    evt => {
      if (anchorEl?.contains(evt.target)) return;
      onClose();
    },
    [anchorEl, onClose]
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{ zIndex: 100, position: 'relative' }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: arrowRef.current,
          },
          flip: {
            enabled: true,
          },
        }}
      >
        {({ TransitionProps, placement }) => {
          const top = placement === 'top';
          const bottom = placement === 'bottom';
          const left = placement === 'left';
          const right = placement === 'right';

          return (
            <Grow {...TransitionProps} timeout={300}>
              <Layout>
                {top && <ArrowBottom ref={arrowRef} />}
                {bottom && <ArrowTop ref={arrowRef} />}
                {left && <ArrowRight ref={arrowRef} />}
                {right && <ArrowLeft ref={arrowRef} />}
                <TooltipContent>
                  <Main>
                    <p css={bulletCss}>
                      <span css={termCss}>PME –</span>Public Market Equivalent
                    </p>
                    <p css={bulletCss}>
                      PME is a mechanism to measure the performance of a Private Fund compared to the performance of a
                      public index. It can be considered as the opportunity cost of investing in a fund versus putting
                      the same amount at the same time into a comparable index.
                    </p>
                    <p css={bulletCss}>
                      <span css={termCss}>KS PME:</span> KS PME is a measurement reflecting the overperformance (blue)
                      or underperformance (orange) of a private fund to an index as a multiple or a TVPI value. i.e a KE
                      PME of 1.25x means the fund has overperformed the index by 25%.
                    </p>
                    <p css={bulletCss}>
                      <span css={termCss}>Direct Alpha PME:</span> is a measurement reflecting the overperformance
                      (blue) or underperformance (orange) of a private fund to an index as a time weighted rate of
                      return. i.e. a Direct Alpha PME of -12.5% means the fund has underperformed the index by 12.5%.
                    </p>
                  </Main>
                </TooltipContent>
              </Layout>
            </Grow>
          );
        }}
      </Popper>
    </ClickAwayListener>
  );
}

PMEInfoTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};

PMEInfoTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default PMEInfoTooltip;
