import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRecentNews } from '../store/recent-news';

export default function useRecentNews() {
  const dispatch = useDispatch();
  const recentNews = useSelector(state => state.news.recent);
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    if (!recentNews.fetched && !recentNews.fetching) {
      dispatch(fetchRecentNews());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentNews.fetched, recentNews.fetching]);

  useEffect(() => {
    if (recentNews.fetched) {
      setArticles(recentNews.articles);
    }
  }, [recentNews]);

  return articles;
}
