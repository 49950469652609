import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { button, link } from 'src/config/colors';

const Button = styled.button`
  color: ${button.default.TEXT_COLOR};
  background-color: ${button.default.BACKGROUND_COLOR};

  border: none;
  font-size: 1.285714286em;
  font-weight: 500;
  text-align: center;
  padding: 0.5em 1.277777778em;
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  align-items: center;

  line-height: 0;

  svg {
    height: 0.833333333em;
    height: 0.791666667em;
    margin-right: 0.25em;
    opacity: 0.3;
  }
`;

const Link = styled.a`
  color: ${link.accent.TEXT_COLOR};
  background-color: ${link.accent.BACKGROUND_COLOR};

  display: block;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

function ModalAction({ primary, ...rest }) {
  return primary ? <Button {...rest} /> : <Link {...rest} />;
}

ModalAction.defaultProps = {
  onClick: () => console.info('TODO: hook up an onClick action'),
};

ModalAction.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default ModalAction;
