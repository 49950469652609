import { useMemo } from 'react';
import { uniq } from 'src/lib/lodash';
import useBookmarkedFundIds from './useBookmarkedFundIds';
import useFunds from './useFunds';
import useUserFunds from './useUserFunds';
import useEmployeeBookmarks from './useEmployeeBookmarks';
import useEmployees from './useEmployees';

function useFundManagerBookmarks() {
  const bookmarkedFundIds = useBookmarkedFundIds();
  const funds = useFunds({ fundIds: bookmarkedFundIds });
  const userFunds = useUserFunds();
  const employees = useEmployees({
    employeeIds: useEmployeeBookmarks({ asObjectOrArray: 'array' }),
    asObjectOrArray: 'array',
  });

  const fundManagerIds = useMemo(() => {
    if (!funds) return;
    if (!employees) return;
    if (!userFunds) return;

    return uniq([
      ...funds.map(f => f.fundManagerId),
      ...userFunds.map(f => f.fundManagerId),
      ...employees.map(({ currentFundManagerId }) => currentFundManagerId),
    ]);
  }, [employees, funds, userFunds]);

  return fundManagerIds;
}

export default useFundManagerBookmarks;
