import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import { link } from 'src/config/colors';
import {
  FundManagerDetail,
  FundManagerFundPerformance,
  FundManagerFundPerformanceTooltip,
  FundManagerAddressTooltip,
  FundAddEditDialog,
  FundBenchmarkChangeTooltip,
  VisibleOnlyToYouTooltip,
  SubscriptionPaywall,
} from 'src/components';
import FundPerformanceNav from './FundPerformanceNav';

const Layout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  display: grid;
  gap: 10px;
  grid-template-rows: max-content;
`;

const SubSection = styled.div`
  position: relative;
  border: 1px solid #4a4a4a;
  border-radius: 6px;
  padding: 10px;
`;

const SubSectionLabel = styled.span`
  font-size: 14px;
  color: #737373;
  font-weight: 300;
  position: absolute;
  top: -10px;
  width: 200px;
  text-align: center;
  left: calc(50% - 100px);
  background-color: #141414;
`;

const AddFundButton = styled.a`
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  display: none;
  color: ${link.accent.TEXT_COLOR};
  cursor: pointer;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: block;
  }
`;

function FundManagerVisualizationComponent({
  addressTooltipAnchorEl,
  addressTooltipData,
  addressTooltipOpen,
  benchmarkChangedTooltipAnchorEl,
  benchmarkChangedTooltipLabel,
  benchmarkChangedTooltipOpen,
  chartTooltipAnchorEl,
  chartTooltipData,
  chartTooltipOpen,
  closeTooltips,
  dataGeo,
  displayFunds,
  financialMetric,
  fundAddEditOpen,
  fundPeerGroupFilters,
  fundManager,
  fundManagerFundPeers,
  fundManagerFunds,
  fundManagerLocation,
  fundManagerStrategies,
  fundManagerIndexes,
  handleClickAway,
  handleFinancialMetricChange,
  handleFundAddEditClose,
  handleItemClick,
  handleLocationClick,
  handleStrategyChange,
  handleCalcsChange,
  handleIndexChange,
  handleUserManagerMarkerClick,
  handleViewFund,
  handleVintageToggle,
  selectedStrategy,
  selectedCalcs,
  selectedIndex,
  setFundAddEditOpen,
  setVisibleToYouTooltipOpen,
  userManagerAnchorEl,
  visibleToYouTooltipAnchorEl,
  visibleToYouTooltipLabel,
  visibleToYouTooltipOpen,
  defaultRegionsFilter,
  defaultSecondRegionsFilter,
  defaultVintagesFilter,
  defaultSizesFilter,
  defaultStrategiesFilter,
  regionsFilter,
  secondRegionsFilter,
  vintagesFilter,
  sizesFilter,
  strategiesFilter,
  setRegionsFilter,
  setSecondRegionsFilter,
  setVintagesFilter,
  setSizesFilter,
  setStrategiesFilter,
  handleMouseLeave,
}) {
  if (
    !(
      fundManager &&
      fundManager.fetched &&
      dataGeo &&
      fundManagerStrategies &&
      fundManagerLocation &&
      fundManagerLocation.fetched
    )
  ) {
    return null;
  }

  return (
    <Layout onMouseLeave={handleMouseLeave}>
      <Content>
        <FundManagerDetail
          expanded={false}
          fundManager={fundManager || {}}
          fundManagerLocation={fundManagerLocation || {}}
          fundManagerFunds={fundManagerFunds || []}
          strategies={fundManagerStrategies}
          dataGeo={dataGeo || {}}
          handleLocationClick={handleLocationClick}
          userManagerAnchorEl={userManagerAnchorEl}
          handleUserManagerMarkerClick={handleUserManagerMarkerClick}
          setVisibleToYouTooltipOpen={setVisibleToYouTooltipOpen}
        />

        <SubSection>
          <SubSectionLabel>Fund Performance</SubSectionLabel>

          <SubscriptionPaywall
            css={`
              flex: 1;
              display: flex;
              flex-direction: column;
            `}
          >
            <FundPerformanceNav
              financialMetric={financialMetric}
              setFinancialMetric={handleFinancialMetricChange}
              fundManagerStrategies={fundManagerStrategies}
              fundManagerIndexes={fundManagerIndexes}
              selectedStrategy={selectedStrategy}
              selectedCalcs={selectedCalcs}
              selectedIndex={selectedIndex}
              setSelectedStrategy={handleStrategyChange}
              setSelectedCalcs={handleCalcsChange}
              setSelectedIndex={handleIndexChange}
              handleVintageToggle={handleVintageToggle}
              defaultRegionsFilter={defaultRegionsFilter}
              defaultSecondRegionsFilter={defaultSecondRegionsFilter}
              defaultVintagesFilter={defaultVintagesFilter}
              defaultSizesFilter={defaultSizesFilter}
              defaultStrategiesFilter={defaultStrategiesFilter}
              regionsFilter={regionsFilter}
              secondRegionsFilter={secondRegionsFilter}
              vintagesFilter={vintagesFilter}
              sizesFilter={sizesFilter}
              strategiesFilter={strategiesFilter}
              setRegionsFilter={setRegionsFilter}
              setSecondRegionsFilter={setSecondRegionsFilter}
              setVintagesFilter={setVintagesFilter}
              setSizesFilter={setSizesFilter}
              setStrategiesFilter={setStrategiesFilter}
              css={`
                padding: 10px 0;
              `}
            />

            <FundManagerFundPerformance
              funds={displayFunds}
              fundPeerGroupFilters={fundPeerGroupFilters}
              peers={fundManagerFundPeers || {}}
              metric={financialMetric}
              calcs={selectedCalcs}
              index={selectedIndex}
              onItemClick={handleItemClick}
              onItemTouchStart={handleItemClick}
              css={`
                flex: 1;
              `}
            />

            <AddFundButton onClick={() => setFundAddEditOpen(true)}>add a fund +</AddFundButton>
          </SubscriptionPaywall>
        </SubSection>
      </Content>
      <FundManagerFundPerformanceTooltip
        onClickAway={handleClickAway}
        fund={chartTooltipData}
        open={chartTooltipOpen}
        calcs={selectedCalcs}
        index={selectedIndex}
        anchorEl={chartTooltipAnchorEl}
        onClose={closeTooltips}
        onViewFund={handleViewFund}
      />
      <FundManagerAddressTooltip
        onClickAway={handleClickAway}
        location={addressTooltipData}
        open={addressTooltipOpen}
        anchorEl={addressTooltipAnchorEl}
        onClose={closeTooltips}
      />

      <VisibleOnlyToYouTooltip
        open={visibleToYouTooltipOpen}
        anchorEl={visibleToYouTooltipAnchorEl}
        onClickAway={handleClickAway}
        label={visibleToYouTooltipLabel}
      />

      <FundBenchmarkChangeTooltip
        open={benchmarkChangedTooltipOpen}
        anchorEl={benchmarkChangedTooltipAnchorEl}
        onClickAway={handleClickAway}
        label={benchmarkChangedTooltipLabel}
      />

      {fundManager && (
        <FundAddEditDialog
          open={fundAddEditOpen}
          onClose={handleFundAddEditClose}
          viewFundAfterSave={false}
          formPresets={{
            fundManagerId: fundManager.fundManagerId,
            fundManagerName: fundManager.name,
            errors: {
              fundManagerName: false,
            },
          }}
        />
      )}
    </Layout>
  );
}

FundManagerVisualizationComponent.defaultProps = {};

FundManagerVisualizationComponent.propTypes = {
  fundManagerId: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  showExpandButton: PropTypes.bool,
};

export default FundManagerVisualizationComponent;
