import { useQuery } from 'react-query';
import queryKeyFactory from '../queryKeyFactory';
import * as client from './client';

const queryKeys = queryKeyFactory(client.collection.id);

const defaultOptions = {
  enabled: true,
  placeholderData: [],
  refetchOnWindowFocus: false,
  staleTime: Number.MAX_SAFE_INTEGER,
};

function useList(filters, options = {}) {
  const queryKey = queryKeys.list(filters);
  const queryFn = async () => {
    return await client.list(filters);
  };
  return useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
  });
}

function useGet(targetFundId, { enabled: optEnabled, ...options } = {}) {
  const enabled = optEnabled === undefined ? Boolean(targetFundId) : optEnabled;
  const queryKey = queryKeys.detail(targetFundId);
  const queryFn = async () => await client.get(targetFundId);
  return useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
    enabled,
  });
}

export { queryKeys, useList, useGet };
