import React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { Box, ButtonRounded } from '../..';
import { upperCase } from '../../../formatters';
import { lookup } from '../../../lookup';

const MeasureButton = css`
  margin-left: 5px;
  width: 44px;
`;

function FinancialMetricSelection({
  financialMetric,
  disableTvpi,
  disableIrr,
  disablePme,
  hideTvpi,
  hideIrr,
  hidePme,
  onMetricChange,
  ...rest
}) {
  const handleButtonClick = useCallback(
    function (metric) {
      return function (_event) {
        onMetricChange(metric);
      };
    },
    [onMetricChange]
  );

  const { tvpi, irr, pme } = lookup.financialMetric;

  return (
    <Box display="inline-flex" alignItems="center" {...rest}>
      {!hideTvpi && (
        <ButtonRounded
          aria-label="tvpi"
          selected={financialMetric === tvpi}
          onClick={handleButtonClick(tvpi)}
          disabled={disableTvpi}
          css={MeasureButton}
        >
          {upperCase(tvpi.label)}
        </ButtonRounded>
      )}
      {!hideIrr && (
        <ButtonRounded
          aria-label="irr"
          selected={financialMetric === irr}
          onClick={handleButtonClick(irr)}
          disabled={disableIrr}
          css={MeasureButton}
        >
          {upperCase(irr.label)}
        </ButtonRounded>
      )}
      {!hidePme && (
        <ButtonRounded
          aria-label="pme"
          selected={financialMetric === pme}
          onClick={handleButtonClick(pme)}
          disabled={disablePme}
          css={MeasureButton}
        >
          {upperCase(pme.label)}
        </ButtonRounded>
      )}
    </Box>
  );
}

FinancialMetricSelection.defaultProps = {
  onMetricChange: () => {},
  disableTvpi: false,
  disableIrr: false,
  hideTvpi: false,
  hideIrr: false,
  hidePme: false,
};

FinancialMetricSelection.propTypes = {
  financialMetric: PropTypes.object.isRequired,
  onMetricChange: PropTypes.func,
  disableTvpi: PropTypes.bool,
  disableIrr: PropTypes.bool,
  hideTvpi: PropTypes.bool,
  hideIrr: PropTypes.bool,
  hidePme: PropTypes.bool,
};

export default FinancialMetricSelection;
