import React from 'react';
import { useState, useCallback } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import styled from 'styled-components';
import { panel } from 'src/config/colors';
import { lookup } from 'src/lookup';
import { useFunds, useFundsFinancialHistory, useBookmarkedFundIds } from 'src/hooks';
import { BookmarkedFundsGrid } from 'src/components';
import BookmarkedFundsTable from './BookmarkedFundsTable';
import Header from './FundsCollectionHeader';

const { SORT_KEYS } = BookmarkedFundsGrid.constants;

const Layout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 10px;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 10px 0.75em 10px;
`;

const UserContentMessage = styled.div`
  color: ${panel.body.TEXT_COLOR};
  font-size: 0.785714286em;

  &:before {
    content: '● ';
    font-size: 1.325em;
    color: #ffd857;
  }
`;

function FundsCollectionView({
  financialMetric,
  timeRange,
  expanded,
  handleGridItemClick,
  handleChangeTimeRange,
  handleAddFundClick,
  isListView,
  isGridView,
}) {
  const [customGridSort, setCustomGridSort] = useState(SORT_KEYS.NUMERIC_DESC);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState('');
  const fundIds = useBookmarkedFundIds(selectedPortfolioId);
  const fundsHistory = useFundsFinancialHistory(fundIds);
  const funds = useFunds({ fundIds });

  const handleCustomGridSort = useCallback(function (value) {
    setCustomGridSort(value);
  }, []);

  if (!funds) return 'no funds';
  if (!fundsHistory) return 'no fund histories';

  return (
    <Layout>
      <Header
        showTimeRange={isGridView}
        timeRangeValue={timeRange}
        onChangeTimeRange={handleChangeTimeRange}
        showSort={isGridView}
        sortValue={customGridSort}
        onChangeSort={handleCustomGridSort}
        selectedPortfolioId={selectedPortfolioId}
        onChangeSelectedPortfolioId={setSelectedPortfolioId}
        onClickAddFund={handleAddFundClick}
      />
      <Content>
        {isListView ? (
          <BookmarkedFundsTable funds={funds} />
        ) : (
          <>
            <BookmarkedFundsGrid
              funds={funds}
              fundsHistory={fundsHistory}
              metric={financialMetric}
              timeRange={timeRange}
              expanded={expanded}
              onItemClick={handleGridItemClick}
              customSort={customGridSort}
            />
            <Footer>
              {fundIds.filter(Boolean).some(id => id.startsWith('u_')) && (
                <UserContentMessage>Funds added manually by you</UserContentMessage>
              )}
            </Footer>
          </>
        )}
      </Content>
    </Layout>
  );
}

FundsCollectionView.defaultProps = {
  isGridView: true,
  isListView: false,
  financialMetric: lookup.financialMetric.tvpi,
  timeRange: lookup.timeRange.threeYears,
  expanded: false,
  handleListDefCreated: funcStub('handleListDefCreated'),
  handleTableRowClick: funcStub('handleTableRowClick'),
  handleTableColumnClick: funcStub('handleTableColumnClick'),
  handleChangeTimeRange: funcStub('handleChangeTimeRange'),
  handleChangeFinancialMetric: funcStub('handleChangeFinancialMetric'),
  handleAddFundClick: funcStub('handleAddFundClick'),
};

FundsCollectionView.propTypes = {
  isGridView: PropTypes.bool.isRequired,
  isListView: PropTypes.bool.isRequired,
  financialMetric: PropTypes.oneOf(Object.values(lookup.financialMetric)).isRequired,
  timeRange: PropTypes.oneOf(Object.values(lookup.timeRange)).isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default React.memo(FundsCollectionView);
