import {
  select,
  ascending,
  hierarchy,
  linkRadial,
  scaleLinear,
  extent,
  cluster as d3Cluster,
  easeCubicInOut,
} from 'd3';

const transitionDuration = 500;

export function draw({ id, teamHierarchy, width, height, margin, expanded, mouseEventRefs }) {
  //#region layout functions
  function getGroupNodes() {
    const svg = select(`#${id}`);

    // if groups exist return them
    if (svg.select('.rootg').node()) {
      return [svg.select('.rootg'), svg.select('.linksg'), svg.select('.nodesg'), svg.select('.textsg')];
    }

    // create the groups
    const root = svg.append('g').attr('class', 'rootg');
    return [
      root,
      root.append('g').attr('class', 'linksg'),
      root.append('g').attr('class', 'nodesg'),
      root.append('g').attr('class', 'textsg'),
    ];
  }

  function getNodeRadius(d) {
    const { depth } = d;
    const { count } = d.data;

    if (depth === 0) {
      return expanded ? 75 : 50;
    }
    if (depth === 1) {
      return d.data.isSelected ? 4 : nodeRadius(count);
    }
    return 4;
  }

  function getLinkKey(d) {
    return `${d.source.data.id}${d.target ? `-${d.target.data.id}` : ''}`;
  }

  function getNodeKey(d) {
    return d.data.id;
  }

  function getNodePosition(d) {
    if (d.depth === 0) return null;
    return `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`;
  }

  function getLinkStroke(d) {
    if (d.target.data.isSelected) {
      return '#0D8BAB';
    }
    return expanded ? '#303030' : 'transparent';
  }

  function getNodeStroke(d) {
    const { depth, data } = d;
    const { isSelected } = data;
    if (depth === 1 && !isSelected) {
      return '#333';
    }
    return null;
  }

  function getNodeFill(d) {
    return d.data.isSelected ? '#0D8BAB' : '#545454';
  }

  function getNodeCursor(d) {
    const { depth } = d;

    if (depth === 1) {
      return 'pointer';
    }
    return null;
  }

  function getText(d) {
    return d.data.label;
  }

  function getTextAnchor(d) {
    const { depth, x } = d;

    if (depth === 0) {
      return 'middle';
    }
    return x < Math.PI ? 'start' : 'end';
  }

  function getTextTransform(d) {
    const { depth, x } = d;
    if (depth === 0) {
      return null;
    }
    return x >= Math.PI ? 'rotate(180)' : null;
  }

  // the distance out
  function getTextX(d) {
    const { x, depth } = d;
    const { isSelected } = d.data;

    if (depth === 0) {
      return null;
    }
    if (depth === 1) {
      if (!isSelected) {
        const offset = nodeRadius(d.data.count) + 10;
        return x < Math.PI ? `${offset}px` : `-${offset}px`;
      }
      return null;
    }
    return x < Math.PI ? '1em' : '-1em';
  }

  // the distance above or below
  function getTextY(d) {
    const { depth, data } = d;
    const { isSelected } = data;

    if (depth === 0) {
      return '0.5em';
    }
    if (depth === 1) {
      if (isSelected) {
        return '1.5em';
      }
      return '.35em';
    }
    if (depth === 2) {
      return '.35em';
    }
    return '1.5em';
  }

  function getTextFill(d) {
    const { depth, data } = d;
    const { isSelected } = data;

    if (depth === 0) {
      return '#efefef';
    }

    if (!expanded) {
      return 'transparent';
    }

    if (isSelected) {
      return '#0D8BAB';
    }
    return '#afafaf';
  }

  function getTextFontSize(d) {
    const { depth } = d;

    if (depth === 0) {
      return '14px';
    }
    if (!expanded) {
      return '6px';
    }
    if (depth === 1) {
      return '12px';
    }
    return '11px';
  }

  function getTextFontWeight(d) {
    const { depth, data } = d;
    const { isSelected } = data;

    if (depth === 1) {
      return isSelected ? '500' : '400';
    }
    return '400';
  }

  function getCountTextFill(d) {
    const { depth, data } = d;
    const { isSelected } = data;

    if (depth === 1 && !isSelected) {
      return '#000';
    }
    return 'transparent';
  }

  //#endregion

  //#region svg setup
  const svgWidth = width - margin.left - margin.right;
  const svgHeight = height - margin.top - margin.bottom;
  const maxLabelLength = 150;
  const radius = Math.min(svgHeight, svgWidth) / 2 - (expanded ? maxLabelLength : 0);
  const [rootGroup, linksGroup, nodesGroup, textsGroup] = getGroupNodes({
    id,
    radius,
    margin,
  });

  const t = rootGroup.transition().duration(transitionDuration).ease(easeCubicInOut);

  rootGroup.attr(
    'transform',
    `translate(${radius + (svgWidth / 2 - radius) + margin.left},${radius + (svgHeight / 2 - radius) + margin.top})`
  );
  //#endregion

  const nodeRadius = scaleLinear()
    .domain(extent(teamHierarchy.children.map(c => c.count)))
    .range([10, expanded ? 30 : 20]);

  // Create the cluster layout
  const tree = d3Cluster().size([2 * Math.PI, radius]);

  // Give the data to this cluster layout
  const root = tree(hierarchy(teamHierarchy).sort((a, b) => ascending(a.data.label, b.data.label)));

  const linksGenerator = linkRadial()
    .angle(d => d.x)
    .radius(d => d.y);

  // Add the links between nodes
  const links = linksGroup.selectAll('path').data(root.links(), getLinkKey);

  links.exit().attr('opacity', 0).transition(t).remove();

  links.transition(t).attr('opacity', 1).attr('d', linksGenerator).attr('stroke', getLinkStroke);

  links
    .enter()
    .append('path')
    .style('fill', 'none')
    .style('pointer-events', 'none')
    .attr('opacity', 0)
    .attr('stroke', getLinkStroke)
    .transition(t)
    .attr('opacity', 1)
    .attr('d', linksGenerator);

  // Add a circle for each node
  const nodes = nodesGroup.selectAll('circle').data(root.descendants(), getNodeKey);

  nodes.exit().attr('opacity', 0).transition(t).remove();

  nodes
    .transition(t)
    .attr('opacity', 1)
    .attr('transform', getNodePosition)
    .attr('stroke', getNodeStroke)
    .style('fill', getNodeFill)
    .style('cursor', getNodeCursor)
    .attr('r', getNodeRadius);

  nodes
    .enter()
    .append('circle')
    .attr('opacity', 0)
    .style('stroke-width', 0.5)
    .style('cursor', getNodeCursor)
    .on('click', handleClick)
    .on('mouseout', handleMouseOut)
    .on('mouseover', handleMouseOver)
    .on('touchstart', handleTouchStart)
    .on('touchend', handleTouchEnd)
    .transition(t)
    .attr('r', getNodeRadius)
    .style('fill', getNodeFill)
    .attr('stroke', getNodeStroke)
    .attr('transform', getNodePosition)
    .attr('opacity', 1);

  //#region add text blocks
  const texts = textsGroup.selectAll('.text').data(root.descendants(), getNodeKey);

  texts.exit().attr('opacity', 0).transition(t).remove();

  texts
    .transition(t)
    .attr('font-size', getTextFontSize)
    .attr('font-weight', getTextFontWeight)
    .attr('transform', getNodePosition)
    .attr('fill', getTextFill)
    .select('text')
    .attr('text-anchor', getTextAnchor)
    .attr('transform', getTextTransform)
    .attr('opacity', 1)
    .attr('y', getTextY)
    .attr('x', getTextX)
    .text(getText);

  texts
    .enter()
    .append('g')
    .attr('transform', getNodePosition)
    .attr('font-size', getTextFontSize)
    .attr('font-weight', getTextFontWeight)
    .style('cursor', getNodeCursor)
    .on('click', handleClick)
    .attr('fill', getTextFill)
    .attr('class', 'text')
    .append('text')
    .attr('y', getTextY)
    .attr('x', getTextX)
    .attr('text-anchor', getTextAnchor)
    .attr('transform', getTextTransform)
    .text(getText)
    .attr('opacity', 0)
    .transition(t)
    .attr('opacity', 1);

  const countTexts = textsGroup.selectAll('.count-text').data(root.descendants(), getNodeKey);

  countTexts.exit().attr('opacity', 0).transition(t).remove();

  countTexts
    .transition(t)
    .attr('transform', getNodePosition)
    .attr('fill', getCountTextFill)
    .select('text')
    .attr('opacity', expanded ? 1 : 0)
    .attr('transform', getTextTransform)
    .text(d => d.data.count || '');

  countTexts
    .enter()
    .append('g')
    .attr('font-size', '12px')
    .attr('transform', getNodePosition)
    .attr('pointer-events', 'none')
    .attr('fill', getCountTextFill)
    .attr('class', 'count-text')
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('transform', getTextTransform)
    .text(d => d.data.count || '')
    .attr('opacity', 0)
    .transition(t)
    .attr('opacity', expanded ? 1 : 0);

  //#region mouse event handlers
  function handleMouseOver(_, d) {
    mouseEventRefs.itemOver.current(this, d);
  }

  function handleMouseOut(_, d) {
    mouseEventRefs.itemOut.current(this, d);
  }

  function handleClick(_, d) {
    try {
      mouseEventRefs.itemClick.current(this, d);
    } catch (e) {
      // WTF?
    }
  }

  function handleTouchStart(_, d) {
    mouseEventRefs.itemTouchStart.current(this, d);
  }

  function handleTouchEnd(_, d) {
    mouseEventRefs.itemTouchEnd.current(this, d);
  }

  //#endregion
}
