import React from 'react';
import { useMemo, useCallback, useEffect } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { md } from 'src/config/layout';
import styled from 'styled-components';
import { TextField, InputAdornment } from '@material-ui/core';
import * as colors from 'src/config/colors';
import { currency } from 'src/formatters/currency';
import { getLastQuarter } from 'src/utils/date';
import { useStrategyQuery } from 'src/hooks';
import { NumberField, QuarterSelect, Button } from 'src/components';
import StrategySelect from './StrategySelect';
import NameField from './NameField';
import { useForm, Controller } from 'react-hook-form';
import { useCreate as useCreateTargetFund } from 'src/services/filter-api/userTargetFunds/mutations';
import { useAddBookmark } from 'src/services/filter-api/userPortfolios/mutations';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;

  & .MuiFormLabel-root {
    position: unset;
  }

  & .MuiInputBase-root {
    margin-top: 0;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    gap: 30px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const SubmitButton = styled(Button).attrs({ type: 'submit' })`
  padding: 0.375em 0.5em;
  padding-bottom: 0.3em;
  font-weight: 500;
  background-color: ${colors.button.accent.BACKGROUND_COLOR};
  color: ${colors.button.accent.TEXT_COLOR};

  &:hover {
    background-color: ${colors.button.accent.BACKGROUND_COLOR};
  }

  &:disabled {
    background-color: ${colors.button.disabled.BACKGROUND_COLOR};
  }

  .MuiButton-label {
    display: flex;
    flex-direction: column;
  }
`;

const defaultValues = {
  name: '',
  strategyId: '0WMNvp9YQuIxmEDEOO6e',
  startDate: getLastQuarter(),
  commitmentAmount: 1_000_000,
};

function formatCommaSeperated(value) {
  currency()()(+value);
}

function useFundNamePlaceholder(startDate, strategyName) {
  const placeholder = useMemo(() => {
    const year = startDate.getFullYear();
    const name = strategyName || '';

    return `${year} ${name} Fund`;
  }, [startDate, strategyName]);

  return placeholder;
}

function AddToPortfolioForm({ onChange, portfolioId }) {
  const {
    handleSubmit: handleFormSubmit,
    watch,
    formState,
    control,
    reset,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });
  const formIsValid = Object.keys(formState.errors).length < 1;

  const name = watch('name');
  const strategyId = watch('strategyId');
  const startDate = watch('startDate');
  const commitmentAmount = watch('commitmentAmount');

  const strategyQuery = useStrategyQuery(strategyId);
  const createTargetFund = useCreateTargetFund().mutateAsync;
  const addBookmark = useAddBookmark().mutate;

  const [strategyName, parentStrategyId] = useMemo(() => {
    if (!strategyQuery.data) return [];
    const { strategyName: dataName, parentId } = strategyQuery.data;
    return [dataName, parentId];
  }, [strategyQuery.data]);

  const namePlaceholder = useFundNamePlaceholder(startDate, strategyName);

  const parentStrategyQuery = useStrategyQuery(parentStrategyId);

  useEffect(() => {
    onChange({
      name,
      startDate,
      strategyId,
      parentStrategyId,
      namePlaceholder,
      commitmentAmount: +commitmentAmount,
    });
  }, [commitmentAmount, name, namePlaceholder, onChange, parentStrategyId, startDate, strategyId]);

  const handleSubmit = useCallback(
    async data => {
      const payload = {
        ...data,
        strategy: parentStrategyId ? parentStrategyQuery?.data?.strategyName : strategyName,
        strategyId: parentStrategyId ?? strategyId,
        subStrategy: parentStrategyId ? strategyName : '',
        subStrategyId: parentStrategyId ? strategyId : null,
        startDate: data.startDate.toISOString(),
        vintage: data.startDate.getFullYear(),
        commitmentAmount: +data.commitmentAmount,
        name: data.name || namePlaceholder,
      };

      const { targetFundId } = await createTargetFund({
        portfolioId,
        ...payload,
      });

      addBookmark({
        portfolioId,
        targetFundId,
        commitmentAmount: payload.commitmentAmount,
      });
      reset();
    },
    [
      addBookmark,
      createTargetFund,
      namePlaceholder,
      parentStrategyId,
      parentStrategyQuery?.data?.strategyName,
      portfolioId,
      reset,
      strategyId,
      strategyName,
    ]
  );

  const handleNameInvaidation = useCallback(
    prevIsValid => newIsValid => {
      const changingtoValid = !prevIsValid && newIsValid;
      const changingtoInvalid = prevIsValid && !newIsValid;

      if (!changingtoValid && !changingtoInvalid) return;
      if (changingtoValid) return clearErrors('name');
      setError('name', {
        type: 'notMatch',
        message: 'Cannot match an existing fund',
      });
    },
    [clearErrors, setError]
  );

  return (
    <form onSubmit={handleFormSubmit(async data => await handleSubmit(data))}>
      <Layout>
        <Fields>
          <Controller
            name="strategyId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <StrategySelect
                label="1. Select Fund Strategy"
                selectedStrategyId={strategyId}
                MenuProps={{ disableScrollLock: true }}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <NameField
                  label="3. Choose Fund Name"
                  value={field.value}
                  placeholder={namePlaceholder}
                  onChange={field.onChange}
                  onChangeIsValid={handleNameInvaidation(!fieldState.invalid)}
                  validationErrorMessage={fieldState?.error?.message}
                />
              );
            }}
          />
          <Controller
            name="startDate"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <QuarterSelect
                variant="standard"
                label="2. Select Fund Start Date"
                value={startDate.toISOString().substr(0, 7)}
                onChange={dateString => {
                  field.onChange(new Date(dateString));
                }}
                minDate={defaultValues.startDate}
              />
            )}
          />
          <Controller
            name="commitmentAmount"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                defaultValue={defaultValues.commitmentAmount}
                placeholder={formatCommaSeperated(defaultValues.commitmentAmount)}
                label="4. Commitment Amount"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumberField,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                {...field}
              />
            )}
          />
        </Fields>

        <Actions>
          <SubmitButton disabled={!formIsValid}>Add to Portfolio</SubmitButton>
        </Actions>
      </Layout>
    </form>
  );
}

AddToPortfolioForm.defaultProps = {
  selectedTags: [],
  onChange: funcStub('onChange'),
  onSubmit: funcStub('onSubmit'),
};

AddToPortfolioForm.propTypes = {
  selectedTags: PropTypes.array,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddToPortfolioForm;
