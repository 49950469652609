import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.div`
  font-size: 13px;
  color: #ffd548;
  margin: 1em 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const DEFAULT_MESSAGE = `
  FundFilter is primarily built as a desktop tool. Given the screen
  size of this device you may consider switching to a desktop for an
  optimal experience while we develop mobile further.
`;

function NarrowViewportMessage({ children, ...rest }) {
  return <Layout {...rest}>{children || DEFAULT_MESSAGE}</Layout>;
}

NarrowViewportMessage.defaultProps = {
  children: null,
};

NarrowViewportMessage.propTypes = {
  children: PropTypes.any,
};

export default NarrowViewportMessage;
