import React from 'react';
import styled from 'styled-components';
import ButtonLarge from '../ButtonLarge';
import IconPrint from '../IconPrint';

const Icon = styled(IconPrint)`
  color: #a6a6a6;
  width: 18px;
`;

const ButtonLargePrint = props => {
  return (
    <ButtonLarge {...props}>
      <Icon />
      Print
    </ButtonLarge>
  );
};

ButtonLargePrint.defaultProps = {};

ButtonLargePrint.propTypes = {};

export default ButtonLargePrint;
