import { useMemo } from 'react';
import userAccountType from '@fundfilter/core/src/users/account-type';
import { useCurrentUser } from 'src/hooks';

export default function useHasSubscription() {
  const currentUser = useCurrentUser();

  return useMemo(() => {
    const { isActive, isTrialing } = userAccountType(currentUser);
    return isActive || isTrialing;
  }, [currentUser]);
}
