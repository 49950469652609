import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from 'src/services';
import { auth } from 'src/services/filter-api/authentication';
import { arrayToObject } from 'src/utils';
import { setUserFundManagerSuccess } from './fund-managers';

const fetchFundManagerNamesPending = createAction('FETCH_FUND_MANAGER_NAMES_PENDING');
const fetchFundManagerNamesError = createAction('FETCH_FUND_MANAGER_NAMES_ERROR');
export const fetchFundManagerNamesSuccess = createAction('FETCH_FUND_MANAGER_NAMES_SUCCESS');

const sortFundManagerNamesAlpha = function (a, b) {
  return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
};

export function fetchFundManagerNames() {
  return async function (dispatch, getState) {
    const { fetching, fetched } = getState().fundManager.names;
    const user = auth.currentUser;

    if (!user) return;
    if (fetching || fetched) return;

    dispatch(fetchFundManagerNamesPending());
    try {
      console.time('fetching fund manager names');
      const [fundManagerNames, userFundManagers] = await Promise.all([
        filterApi.getFundManagerNameList(),
        filterApi.getUserFundManagers({
          userId: user.uid,
        }),
      ]);

      console.timeEnd('fetching fund manager names');
      dispatch(
        fetchFundManagerNamesSuccess({
          fundManagerNames: fundManagerNames.concat(userFundManagers),
        })
      );
    } catch (error) {
      console.timeEnd('fetching fund manager names');
      dispatch(fetchFundManagerNamesError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  byId: null,
  allIds: null,
};

export default createReducer(initialState, {
  [fetchFundManagerNamesPending]: state => {
    state.fetching = true;
  },
  [fetchFundManagerNamesError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [fetchFundManagerNamesSuccess]: (state, action) => {
    const { fundManagerNames } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    fundManagerNames.sort(sortFundManagerNamesAlpha);
    state.byId = arrayToObject('fundManagerId')(fundManagerNames.map(obj => ({ ...obj, fetched: true })));
    state.allIds = fundManagerNames.map(fundManager => fundManager.fundManagerId);
  },
  [setUserFundManagerSuccess]: (state, action) => {
    const { fundManager } = action.payload;
    const existing = state.byId[fundManager.fundManagerId] || {};
    const byId = Object.values(state.byId);
    existing.error = null;
    existing.fetching = false;
    existing.fetched = true;
    existing.name = fundManager.name;
    existing.fundManagerId = fundManager.fundManagerId;
    existing.userId = fundManager.userId;
    byId.push(existing);
    byId.sort(sortFundManagerNamesAlpha);
    state.byId = arrayToObject('fundManagerId')(byId);
  },
});
