import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { button } from 'src/config/colors';
import { addFund } from 'src/config/tutorials';
import {
  IconBook,
  IconFunnel,
  IconCoinStack,
  IconIdCard,
  IconFire,
  IconBookmark,
  IconLock,
  IconChat,
  IconCashflows,
  ButtonAddFund,
  ButtonTutorialPlay,
  TutorialDialog,
  FundAddEditDialog,
} from 'src/components';
import Link from './Link';
import { md, lg, xl } from 'src/config/layout';

const Logo = styled.img.attrs({
  onClick: () => navigate('/'),
  alt: 'FundFilter',
})`
  cursor: pointer;
  max-height: 100%;
  max-width: 100%;
  padding: 20px 25px;

  display: none;
`;

const LogoOnly = styled(Logo)`
  @media (min-width: ${md.BREAKPOINT}px) {
    display: initial;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    display: none;
  }
`;

const LogoWithBrand = styled(Logo)`
  @media (min-width: ${xl.BREAKPOINT}px) {
    display: initial;
  }
`;

const Label = styled.span`
  margin-top: 4px;
`;

const ShortLabel = styled(Label)`
  @media (min-width: ${xl.BREAKPOINT}px) {
    display: none;
  }
`;

const LongLabel = styled(Label)`
  display: none;

  @media (min-width: ${xl.BREAKPOINT}px) {
    display: inline-block;
    margin-left: 0.5em;
  }
`;

const ExternalLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer noopener',
})`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div`
  display: none;
  text-align: center;
  font-size: 0.636363636em;

  @media (min-width: ${md.BREAKPOINT}px) and (min-height: ${lg.V_BREAKPOINT}px) {
    display: block;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    text-align: left;
  }
`;

const NavLink = styled(Link)`
  color: ${button.neutral.TEXT_COLOR};
  background-color: ${button.neutral.BACKGROUND_COLOR};
  text-decoration: none;
  padding: 1em;
  opacity: 0.75;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover,
  &.active {
    color: ${button.neutral.active.TEXT_COLOR};
    background-color: ${button.neutral.active.BACKGROUND_COLOR};
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 0.75em 1em;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    flex-direction: row;
    width: 100%;
    padding: 10px 40px;
  }
`;

const NavItem = styled.div`
  padding: 10px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AddFundNavItem = styled(NavItem)`
  position: relative;
  display: flex;

  @media (min-width: ${xl.BREAKPOINT}px) {
    align-items: flex-start;
  }
`;

const AddFundTutorialButton = styled(ButtonTutorialPlay)`
  display: none;
  border-radius: 2px;

  @media (min-width: ${xl.BREAKPOINT}px) {
    display: unset;
  }
`;

const Hint = styled.span`
  opacity: 0.35;
  color: white;
  padding: 8px 4px;

  svg {
    vertical-align: top;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (min-width: ${md.BREAKPOINT}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    margin: 0;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    align-items: flex-start;
  }
`;

const HeaderSection = styled(Section)`
  @media (min-width: ${xl.BREAKPOINT}px) {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const MainSection = styled(Section)`
  flex: 1;
  margin-left: 10%;
  margin-right: 10%;

  @media (min-width: ${md.BREAKPOINT}px) {
    margin: 0;
  }
`;

const AuxSection = styled(Section)`
  display: none;

  @media (min-width: ${md.BREAKPOINT}px) and (min-height: ${lg.V_BREAKPOINT}px) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const LegalSection = styled(Section)`
  display: none;
  padding: 0 10px;
  font-size: 0.818181818em;
  text-align: center;

  ${ExternalLink} {
    display: block;
    margin-bottom: 0.75em;
    line-height: 1.2;

    &:hover {
      opacity: 1;
    }
  }

  @media (min-width: ${md.BREAKPOINT}px) and (min-height: ${md.V_BREAKPOINT}px) {
    display: initial;

    ${ExternalLink} {
      display: block;
    }
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    text-align: left;
    padding: 0 40px;
  }
`;

const Layout = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

  font-size: 11px;

  @media (min-width: ${md.BREAKPOINT}px) {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 20px;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    font-size: 16px;
  }
`;

function PrimaryNav(props) {
  const [fundAddEditOpen, setFundAddEditOpen] = useState(false);
  const [fundAddTutorialOpen, setFundAddTutorialOpen] = useState(false);

  const handleFundAddEditOpen = useCallback(function () {
    setFundAddEditOpen(true);
  }, []);

  const handleFundAddEditClose = useCallback(function () {
    setFundAddEditOpen(false);
  }, []);

  const handleFundAddTutorialOpen = useCallback(function () {
    setFundAddTutorialOpen(true);
  }, []);

  const handleFundAddTutorialClose = useCallback(function () {
    setFundAddTutorialOpen(false);
  }, []);

  return (
    <Layout {...props}>
      <HeaderSection>
        <LogoOnly src="/assets/img/logo.svg" />
        <LogoWithBrand src="/assets/img/logo_with_brand.svg" />
      </HeaderSection>

      <MainSection>
        <NavLink path="/" exact>
          <IconFunnel />
          <ShortLabel>Markets</ShortLabel>
          <LongLabel>Market Filter</LongLabel>
        </NavLink>

        <NavLink path="/cashflows">
          <IconCashflows />
          <ShortLabel>Forecasts</ShortLabel>
          <LongLabel>Forecasts</LongLabel>
        </NavLink>

        <NavLink path="/funds">
          <IconCoinStack />
          <ShortLabel>Funds</ShortLabel>
          <LongLabel>Funds</LongLabel>
        </NavLink>

        <NavLink path="/managers">
          <IconIdCard />
          <ShortLabel>Managers</ShortLabel>
          <LongLabel>Fund Managers</LongLabel>
        </NavLink>

        <NavLink path="/news">
          <IconFire />
          <ShortLabel>News</ShortLabel>
          <LongLabel>News</LongLabel>
        </NavLink>

        <NavLink path="/saved">
          <IconBookmark />
          <ShortLabel>Saved</ShortLabel>
          <LongLabel>Saved</LongLabel>
        </NavLink>
      </MainSection>

      <AuxSection>
        <AddFundNavItem>
          <ButtonAddFund onClick={handleFundAddEditOpen} className="button-add-fund" />
          <AddFundTutorialButton onClick={handleFundAddTutorialOpen} />
          <Hint>
            <IconLock width="0.75em" height="0.75em" /> Private to you
          </Hint>

          <TutorialDialog open={fundAddTutorialOpen} onClose={handleFundAddTutorialClose} {...addFund} />
          <FundAddEditDialog open={fundAddEditOpen} onClose={handleFundAddEditClose} />
        </AddFundNavItem>

        <NavLink path="/tutorials">
          <IconBook />
          <ShortLabel>Tutorials</ShortLabel>
          <LongLabel>Tutorials</LongLabel>
        </NavLink>
        {/*
        <NavLink path="/help">
          <IconLifePreserver />
          <ShortLabel>Help</ShortLabel>
          <LongLabel>Help</LongLabel>
        </NavLink>
        <NavLink path="/about">
          <IconInfo />
          <ShortLabel>About</ShortLabel>
          <LongLabel>About</LongLabel>
        </NavLink>
         */}

        <NavLink path="/contact">
          <IconChat />
          <ShortLabel>Contact</ShortLabel>
          <LongLabel>Contact</LongLabel>
        </NavLink>

        {/*
        <NavLink path="/dev/sandbox">
          <CPUIcon />
          <ShortLabel>Dev</ShortLabel>
          <LongLabel>Dev Sandbox</LongLabel>
        </NavLink>
        */}
      </AuxSection>

      <LegalSection>
        <ExternalLink href="https://altman.ai/terms-of-service">Terms of Service</ExternalLink>
        <ExternalLink href="https://altman.ai/privacy-policy">Privacy Policy</ExternalLink>
        <Copyright>
          {`© ${new Date().getFullYear()} Altman Inc.`}
          <br />
          All Rights Reserved
        </Copyright>
      </LegalSection>
    </Layout>
  );
}

export default PrimaryNav;
