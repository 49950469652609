import React from 'react';
import PropTypes from 'prop-types';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import styled from 'styled-components';
import { Responsive, ButtonExpandToggle, ButtonShareIcon, ButtonTutorialPlay, TutorialDialog } from 'src/components';

const Layout = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: #737373;
  /* font-size: 16px; */
`;

const Title = styled.h2`
  font-size: 1.142857143em;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  margin-left: 12px;
  padding: 8px 0;
`;

const Button = styled.button`
  padding: 10px;
  border-radius: 4px;
`;

const IconSpinner = styled(HourglassEmptyIcon)`
  width: 38px;
  height: 38px;
  padding: 10px;
  display: block;

  @keyframes icon-spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation-name: icon-spinner-rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
`;

function PanelControlBar({
  title,
  expandable,
  onClickExpand,
  tutorial,
  tutorialOpen,
  downloadable,
  onClickDownload,
  onTutorialOpen,
  onTutorialClose,
  isExpanded,
  isDownloading,
  children,
  className,
}) {
  return (
    <Layout className={className}>
      {title !== null && <Title>{title}&nbsp;</Title>}
      {children}

      <div>
        {tutorial && (
          <Responsive.OnAtLeastTablet>
            <Button as={ButtonTutorialPlay} onClick={onTutorialOpen} />
          </Responsive.OnAtLeastTablet>
        )}
        {downloadable && (
          <Responsive.OnAtLeastTablet>
            {isDownloading ? <IconSpinner /> : <Button as={ButtonShareIcon} onClick={onClickDownload} />}
          </Responsive.OnAtLeastTablet>
        )}
        {expandable && <Button as={ButtonExpandToggle} expanded={isExpanded} onClick={onClickExpand} />}
      </div>

      <TutorialDialog
        open={tutorialOpen}
        onClose={onTutorialClose}
        videoId={tutorial?.videoId}
        title={tutorial?.title}
      />
    </Layout>
  );
}

PanelControlBar.defaultProps = {};

PanelControlBar.propTypes = {
  children: PropTypes.any,
};

export default PanelControlBar;
