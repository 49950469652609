import { useMemo } from 'react';

function useEmployeePosition(employee) {
  return useMemo(() => `${employee?.currentTitleName} — ${employee?.currentFundManagerName}`, [employee]);
}

function useEmployeeSchoolNames(employee) {
  return useMemo(() => {
    return [...new Set(employee?.education?.map(row => row.schoolName ?? row.parentSchoolName))];
  }, [employee]);
}

function useEmployeePriorFirm(employee) {
  return useMemo(() => {
    const currentFundManagerId = employee?.currentFundManagerId;
    const priorExperiences =
      employee?.experience?.filter(({ fundManagerId }) => fundManagerId !== currentFundManagerId) ?? [];

    return priorExperiences;
  }, [employee]);
}

export { useEmployeePosition, useEmployeeSchoolNames, useEmployeePriorFirm };
