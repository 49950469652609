import React from 'react';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as patterns from 'src/config/patterns';
import { Button, IconPencil } from 'src/components';
import FormTextField from './FormTextField';
import FormSubmitButton from './FormSubmitButton';
import FormCancelButton from './FormCancelButton';
import ConfirmPasswordDialog from './ConfirmPasswordDialog';
import { useUpdate as useUpdateUser } from 'src/services/filter-api/user';
import { useUpdate as useUpdateAuth, useGet as useGetAuth } from 'src/services/filter-api/authentication';

function AccountUserProfileForm({ user }) {
  const { userId, name } = user;
  const { email } = useGetAuth();
  const updateUser = useUpdateUser().mutateAsync;
  const updateAuth = useUpdateAuth().mutateAsync;
  const defaultValues = useMemo(() => {
    return { email, name };
  }, [email, name]);
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues,
    mode: 'onBlur',
  });
  const { isValid, errors, isSubmitting } = formState;
  const [isEditing, setIsEditing] = useState(false);
  const [requirePassword, setRequirePassword] = useState(false);

  const handleSubmitForm = handleSubmit(async function (data) {
    if (!isValid) return;

    try {
      await updateUser({ ...data, userId });
      await updateAuth(data);

      setIsEditing(false);
      setRequirePassword(false);
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        setRequirePassword(true);
      } else {
        throw new Error(error);
      }
    }
  });

  function handleClickCancel() {
    reset(defaultValues);
    setIsEditing(false);
    setRequirePassword(false);
  }

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <section>
          <header>
            <h3>Name & Email Address</h3>
            <Button size="small" variant="outlined" disabled={isEditing} onClick={() => setIsEditing(true)}>
              <IconPencil />
              Edit
            </Button>
          </header>
          <span />
          <FormTextField
            register={register}
            errors={errors}
            name="name"
            label="Name"
            validations={{
              required: 'Name cannot be blank',
            }}
            disabled={!isEditing}
          />

          <FormTextField
            register={register}
            errors={errors}
            validations={{
              required: 'Email cannot be blank',
              pattern: {
                value: patterns.email,
                message: 'Email must look like an email address',
              },
            }}
            name="email"
            type="email"
            label="Email"
            disabled={!isEditing}
          />

          <footer>
            <FormSubmitButton isSubmitting={isSubmitting} disabled={!isEditing || !isValid} />
            <FormCancelButton disabled={!isEditing} onClick={handleClickCancel} />
          </footer>
        </section>
      </form>

      <ConfirmPasswordDialog open={requirePassword} onSuccess={handleSubmitForm} onFailure={handleClickCancel} />
    </>
  );
}

AccountUserProfileForm.defaultProps = {};

AccountUserProfileForm.propTypes = {
  children: PropTypes.any,
};

export default AccountUserProfileForm;
