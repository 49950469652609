import { functions } from '../config/firebase';

const logMessage = severity => async message => {
  functions.httpsCallable('log')({ severity, message });
};

export const logger = {
  log: logMessage('log'),
  info: logMessage('info'),
  warn: logMessage('warn'),
  error: logMessage('error'),
};
