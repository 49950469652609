import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees } from '../store/employees';
import useEmployeeBookmarks from './useEmployeeBookmarks';

export default function useEmployees({ employeeIds, asObjectOrArray = 'object' }) {
  const dispatch = useDispatch();
  const allEmployees = useSelector(state => state.employee.all);
  const employeeBookmarks = useEmployeeBookmarks({
    asObjectOrArray: 'object',
  });
  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    if (employeeIds && employeeIds.length > 0) {
      dispatch(fetchEmployees({ employeeIds }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeIds]);

  useEffect(() => {
    if (employeeIds && employeeBookmarks) {
      const emps = employeeIds
        .map(employeeId => allEmployees.byId[employeeId])
        .filter(employee => employee && employee.fetched)
        .map(employee => {
          const lastSchoolName =
            (employee.education && employee.education.length > 0 && employee.education[0].schoolName) || '';

          const lastParentSchoolName =
            (employee.education && employee.education.length > 0 && employee.education[0].parentSchoolName) || '';

          return {
            ...employee,
            lastSchoolName,
            lastParentSchoolName,
            bookmarked: employeeBookmarks[employee.employeeId],
          };
        });

      //if (emps.length === employeeIds.length) {
      // add to state once they are all fetched
      if (asObjectOrArray === 'object') {
        setEmployees(
          emps.reduce((acc, employee) => {
            acc[employee.employeeId] = employee;
            return acc;
          }, {})
        );
      } else {
        setEmployees(emps);
      }
      //} else {
      //  setEmployees(null);
      //}
    }
  }, [allEmployees.byId, asObjectOrArray, employeeBookmarks, employeeIds]);

  return employees;
}
