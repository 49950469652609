const addQuarters = require('date-fns/fp/addQuarters').default;
const calc = require('../calculators');
const { sort } = require('../utils/array');

const HISTORY_LENGTH = (function getMaxHistoryLength() {
  const startYear = 1999;
  const endYear = new Date().getFullYear();
  return (endYear - startYear) * 4;
})();

function findStartDate(history, vintage) {
  const latestStartQuarter = `${vintage + 1}-03`;
  const startOfHistoryQuarter = history[0].date.substring(0, 7);
  const artificalStartQuarter = `${vintage}-06`;
  return startOfHistoryQuarter <= latestStartQuarter ? startOfHistoryQuarter : artificalStartQuarter;
}

function addCashflowMetrics(d, i, group) {
  return {
    ...d,
    distribution: calc.spotDistribution(d, i, group),
    contribution: calc.spotContribution(d, i, group),
  };
}

function historyTimeseries(history, vintage) {
  if (!Number.isFinite(+vintage)) {
    throw new Error(`Cannot calculate timeseries without a vintage, vintage provided was: \`${vintage}\``);
  }

  const sortedHistory = sort((a, b) => a.date.localeCompare(b.date))(history);
  const startQuarter = findStartDate(sortedHistory, +vintage);

  return new Array(HISTORY_LENGTH).fill().map((_, i) => {
    const quarter = addQuarters(i)(new Date(`${startQuarter}-15`))
      .toISOString()
      .substring(0, 7);

    const historyRecord = sortedHistory.map(addCashflowMetrics).find(d => {
      return d.date.substring(0, 7) === quarter;
    });

    if (!historyRecord) return null;

    const { date, rvpi, calledPct, tvpi, dpi, distribution, contribution } = historyRecord;

    return {
      q: i,
      date,
      calledPct,
      dpi,
      rvpi,
      tvpi,
      distribution,
      contribution,
    };
  });
}

module.exports = historyTimeseries;
