import { select, pie as d3Pie, arc as d3Arc } from 'd3';

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, {
      label: d.data.label,
      size: d.data.value,
    });
  };
}

function draw({ id, data, size, mouseEventRefs }) {
  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const radius = size.width / 2;
  const pie = d3Pie().value(d => d.value);
  const path = d3Arc().outerRadius(radius).innerRadius(0);

  const root = select(`#${id}`)
    .selectAll('.root')
    .data([data])
    .join('g')
    .attr('class', 'root')
    .attr('transform', `translate(${radius},${radius})`);

  const arcData = root.selectAll('.arc').data(pie(data));

  arcData
    .transition()
    .attr('d', path)
    .attr('fill', d => {
      return d.data.color;
    });

  arcData.exit().transition().remove();

  arcData
    .enter()
    .append('path')
    .attr('d', path)
    .style('cursor', 'pointer')
    .attr('class', 'arc')
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .attr('fill', d => d.data.color);
}

export default draw;
