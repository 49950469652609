import React from 'react';
import PropTypes from 'prop-types';
import { Flexbox } from 'src/components';

function VisualizationMessage({ children, ...rest }) {
  return (
    <Flexbox
      column
      css={`
        align-items: center;
        text-transform: uppercase;
        margin-top: 40px;
        font-size: 12px;
        color: #5a5a5a;
      `}
      {...rest}
    >
      {children}
    </Flexbox>
  );
}

VisualizationMessage.defaultProps = {
  children: null,
};

VisualizationMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default VisualizationMessage;
