import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFunds } from 'src/store/funds';
import { pick } from 'src/lib/lodash';
import { scaleQuantile } from 'd3';
import { pipe } from 'src/utils/fp';

function quantileScale(subject, group) {
  const domain = [subject, ...group].sort();
  const range = new Array(100).fill(0).map((_, i) => i + 1);
  return scaleQuantile().domain(domain).range(range)(subject) / 100;
}

function maybeFilterByFundIds(fundIds) {
  return function (funds) {
    if (!funds) return;
    if (!fundIds) return funds.filter(Boolean);

    return funds.filter(fund => {
      if (!fund) return false;
      return fundIds.includes(fund.fundId);
    });
  };
}

function maybeFilterByStrategyId(strategyId) {
  return function (funds) {
    if (!funds) return;
    if (!strategyId) return funds;

    return funds.filter(fund => {
      return [fund.subStrategyId, fund.strategyId].includes(strategyId);
    });
  };
}

function maybeIncludeTvpiRank(includeTvpiRank) {
  return function (funds) {
    if (!funds) return;
    if (!includeTvpiRank) return funds;

    const tvpis = funds.map(f => f.tvpi);

    return funds.map(fund => {
      return { ...fund, tvpiRank: quantileScale(fund.tvpi, tvpis) };
    });
  };
}

function maybePickProps(pickProps) {
  return function (funds) {
    if (!funds) return;
    const propList = pickProps.filter(Boolean);
    if (propList.length < 1) return funds;
    return funds.map(fund => pick(fund, propList));
  };
}

function maybeSortByProp(sortBy) {
  return function (funds) {
    if (!funds) return;
    if (!sortBy) return funds;
    return funds.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
  };
}

const DEFAULT_PICK_PROPS = [];

function useFunds({ fundIds, strategyId, sortBy, pickProps = DEFAULT_PICK_PROPS, includeTvpiRank = false } = {}) {
  const dispatch = useDispatch();
  const funds = useSelector(state => state.fund.all);

  return useMemo(() => {
    if (funds.fetching) return;
    if (!funds.fetched) return dispatch(fetchFunds());

    return pipe(
      maybeFilterByFundIds(fundIds),
      maybeFilterByStrategyId(strategyId),
      maybeIncludeTvpiRank(includeTvpiRank),
      maybePickProps([includeTvpiRank && 'tvpiRank', ...pickProps]),
      maybeSortByProp(sortBy)
    )(Object.values(funds.byId));
  }, [dispatch, fundIds, funds.byId, funds.fetched, funds.fetching, includeTvpiRank, pickProps, sortBy, strategyId]);
}

export default useFunds;
