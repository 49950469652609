import { NODE_TYPE, MAX_FUND_MANAGER_NODE_LENGTH, OTHER_FUND_MANAGER_IDS } from './constants';

function groupEmployeesByTitle(currentEmployees) {
  return currentEmployees.reduce((acc, employee) => {
    const key = employee.currentParentTitleName || employee.currentTitleName;
    if (!acc[key]) {
      acc[key] = [];
      acc[key].titleId = employee.currentParentTitleId || employee.currentTitleId;
    }
    acc[key].push(employee);
    return acc;
  }, {});
}

function groupEmployeesSchool(currentEmployees) {
  return currentEmployees.reduce((acc, employee) => {
    if (employee.lastParentSchoolName) {
      const key = employee.lastParentSchoolName;
      if (!acc[key]) {
        acc[key] = [];
        acc[key].schoolId = employee.lastParentSchoolId;
      }
      if (!acc[key].find(e => e.employeeId === employee.employeeId)) acc[key].push(employee);
    }

    if (employee.prevParentSchoolName) {
      const key = employee.prevParentSchoolName;
      if (!acc[key]) {
        acc[key] = [];
        acc[key].schoolId = employee.prevParentSchoolId;
      }
      if (!acc[key].find(e => e.employeeId === employee.employeeId)) acc[key].push(employee);
    }

    return acc;
  }, {});
}

function groupEmployeesPriorFirm(currentEmployees) {
  return currentEmployees.reduce((acc, employee) => {
    for (const experience of employee?.experience?.slice(1) ?? []) {
      const key = experience.parentFundManagerName;
      if (OTHER_FUND_MANAGER_IDS.includes(experience.parentFundManagerId)) continue;

      if (!acc[key]) {
        acc[key] = [];
        acc[key].currentFundManagerId = employee.currentParentFundManagerId;
        acc[key].fundManagerId = experience.parentFundManagerId;
      }
      if (!acc[key].find(e => e.employeeId === employee.employeeId)) acc[key].push(employee);
    }
    return acc;
  }, {});
}

function groupFundManagersBySchool(schoolEmployees) {
  return schoolEmployees.reduce((acc, employee) => {
    const key = employee.currentFundManagerName;
    if (!acc[key]) {
      acc[key] = [];
      acc[key].currentFundManagerId = employee.currentFundManagerId;
    }
    acc[key].push(employee);
    return acc;
  }, {});
}

function mapEmployee(employee, selectedEmployee) {
  return {
    label: employee.name,
    id: employee.employeeId,
    type: NODE_TYPE.employee,
    isSelected: selectedEmployee === employee.employeeId,
  };
}

function createFundManagerRootNode(fundManager) {
  return {
    label: fundManager.name,
    id: fundManager.fundManagerId,
    type: NODE_TYPE.root,
  };
}

function createSchoolRootNode(school) {
  return {
    label: school.name,
    id: school.schoolId,
    type: NODE_TYPE.root,
  };
}

function createTitleHierarchy(currentEmployees, expanded, selectedEmployee, selectedNode) {
  const employeesByTitle = groupEmployeesByTitle(currentEmployees);
  const hierarchy = Object.keys(employeesByTitle).map(title => {
    const group = employeesByTitle[title];
    return {
      label: title,
      id: title,
      key: group.titleId,
      count: group.length,
      type: NODE_TYPE.title,
      isSelected: expanded ? selectedNode === title : false,
      children:
        expanded && selectedNode === title ? group.map(employee => mapEmployee(employee, selectedEmployee)) : [],
    };
  });
  const sorted = hierarchy.sort((a, b) => b.count - a.count);
  return sorted.slice(0, MAX_FUND_MANAGER_NODE_LENGTH);
}

function createSchoolHierarchy(currentEmployees, expanded, selectedEmployee, selectedNode) {
  const employeesByLastSchool = groupEmployeesSchool(currentEmployees);
  const hierarchy = Object.keys(employeesByLastSchool).map(school => {
    const group = employeesByLastSchool[school];
    return {
      label: school,
      id: school,
      key: group.schoolId,
      count: group.length,
      type: NODE_TYPE.school,
      isSelected: expanded ? selectedNode === school : false,
      children:
        expanded && selectedNode === school ? group.map(employee => mapEmployee(employee, selectedEmployee)) : [],
    };
  });
  const sorted = hierarchy.sort((a, b) => b.count - a.count);
  return sorted.slice(0, MAX_FUND_MANAGER_NODE_LENGTH);
}

function createPriorFirmHierarchy(currentEmployees, expanded, selectedEmployee, selectedNode) {
  const employeesByPriorFirm = groupEmployeesPriorFirm(currentEmployees);
  const hierarchy = Object.keys(employeesByPriorFirm).map(fundManager => {
    const group = employeesByPriorFirm[fundManager];
    return {
      label: fundManager,
      id: fundManager,
      key: group.fundManagerId,
      count: group.length,
      type: NODE_TYPE.priorFirm,
      isSelected: expanded ? selectedNode === fundManager : false,
      children:
        expanded && selectedNode === fundManager ? group.map(employee => mapEmployee(employee, selectedEmployee)) : [],
    };
  });
  const sorted = hierarchy.sort((a, b) => b.count - a.count);
  return sorted.slice(0, MAX_FUND_MANAGER_NODE_LENGTH);
}

function createFundManagerBySchoolHierarchy(schoolEmployees) {
  const fundManagersBySchool = groupFundManagersBySchool(schoolEmployees);
  const hierarchy = Object.keys(fundManagersBySchool).map(fundManagerName => {
    const group = fundManagersBySchool[fundManagerName];
    return {
      label: fundManagerName,
      id: fundManagerName,
      key: group.currentFundManagerId,
      count: group.length,
      type: NODE_TYPE.fundManager,
      isSelected: false,
      children: [],
    };
  });

  const sorted = hierarchy.sort((a, b) => b.count - a.count);
  return sorted.slice(0, MAX_FUND_MANAGER_NODE_LENGTH);
}

function createFundManagerByPriorFirmHierarchy(priorFirmEmployees) {
  const fundManagersByPriorFirm = groupEmployeesPriorFirm(priorFirmEmployees);
  const hierarchy = Object.keys(fundManagersByPriorFirm).map(fundManagerName => {
    const group = fundManagersByPriorFirm[fundManagerName];
    return {
      label: fundManagerName,
      id: fundManagerName,
      key: group.currentFundManagerId,
      count: group.length,
      type: NODE_TYPE.fundManager,
      isSelected: false,
      children: [],
    };
  });

  const sorted = hierarchy.sort((a, b) => b.count - a.count);
  return sorted.slice(0, MAX_FUND_MANAGER_NODE_LENGTH);
}

export {
  createFundManagerRootNode,
  createSchoolRootNode,
  createTitleHierarchy,
  createSchoolHierarchy,
  createPriorFirmHierarchy,
  createFundManagerBySchoolHierarchy,
  createFundManagerByPriorFirmHierarchy,
};
