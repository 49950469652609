import generateHistoryTimeseries from '@fundfilter/core/src/generators/historyTimeseries';
import { db } from 'src/config/firebase';
import { DocumentNotFoundError } from './errors';
import { getUserFundFinancialHistory } from './userFundFinancialHistory';

const dbFundTimeseries = db.collection('fundTimeseries');

async function fetchFundTimeseries(fundId) {
  const doc = await dbFundTimeseries.doc(fundId).get();
  if (!doc.exists) throw new DocumentNotFoundError(doc);
  return doc.data();
}

async function getFundTimeseries(fundId, userId, vintage = null) {
  if (!userId) return;
  if (!fundId.startsWith('u_')) return fetchFundTimeseries(fundId);

  try {
    const history = await getUserFundFinancialHistory({
      userId,
      fundId,
    });
    const series = generateHistoryTimeseries(history, vintage);

    return {
      fundId,
      vintage,
      series,
    };
  } catch (error) {
    console.error(error);
    return {
      fundId,
      vintage,
      series: [],
    };
  }
}

async function getFundsTimeseries() {
  const snapshot = await dbFundTimeseries.get();
  return snapshot.docs.map(d => d.data());
}

export { getFundTimeseries, getFundsTimeseries };
