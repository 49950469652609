export const capitalize =
  (allWords = true) =>
  str => {
    if (typeof str !== 'string') return '';
    if (allWords) {
      return str
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
