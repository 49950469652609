import React from 'react';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import { AppLayout } from 'src/components';

const Panel = styled.section`
  background-color: #141414;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  &::before {
    content: 'Contact & About';
    display: block;
    background-color: black;
    padding: 13px;
    color: #737373;
    font-size: 16px;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    grid-column-end: span 2;
    grid-row-end: span 6;
  }
`;

const Layout = styled.div`
  color: #d0d0d0;
  font-size: 14px;
  font-weight: 300;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: flex;
    padding: 28px 0;
  }
`;

const Section = styled.section`
  padding: 20px;
  border-radius: 6px;

  ul {
    padding-left: 10px;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 36px;
    flex: 1;
  }
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  padding: 10px;
  opacity: 0.5;
`;

const SectionTitle = styled.h1`
  color: #4d4d4d;
  font-size: 28px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 14px;

  line-height: 1;
`;

const SectionSubtitle = styled.h2`
  color: #adadad;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 14px;

  line-height: 1;
`;

const ContactSection = styled(Section)`
  background-color: #262626;
  border: 1px solid #303030;

  h1 {
    color: #5d5d5d;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    margin-left: 18px;
  }
`;

const ContactItem = styled.div`
  margin-top: 21px;
  margin-bottom: 28px;

  h3 {
    color: #d0d0d0;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
  }
`;

const Link = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
`;

const Address = styled.div`
  margin: 10px 0;
`;

const Tel = styled(Link)``;

const Email = styled(Link)`
  color: #008eae;
`;

const version = process.env.REACT_APP_VERSION;

function ContactPage() {
  return (
    <AppLayout>
      <Panel>
        <Layout>
          <Section>
            <header>
              <SectionTitle>Accessibility</SectionTitle>
            </header>
            <p>
              The private market is changing rapidly. There is a growing demand for access to private investment
              opportunities from a large universe of new investors.
            </p>
            <p>
              However, not all these investors can afford the institutional priced analytical tools needed to
              independently assess and monitor fund investments. Without such access, asymmetric information creates an
              unlevel playing field.
            </p>
            <p>
              FundFilter is a private fund analytics platform addressing the needs of this rapidly changing market. We
              use the latest in machine learning technology to efficiently curate data as well as forecast cash flows,
              risk assets and fund performance. FundFilter provides transparency before and after investing in order to
              level out the playing field so investors can make informed decisions, cost effectively.
            </p>
            <p>
              The product allows investors to ...
              <ul>
                <li>Screen Fund Managers</li>
                <li>Compare Fund Performance</li>
                <li>Forecast Fund Cash Flows</li>
                <li>Construct Portfolios</li>
                <li>Plan Future Commitments</li>
              </ul>
            </p>
            <p>
              Our team of researchers, engineers, designers and data scientists have reimagined how private fund data
              should be accessed and interacted with. We have focused on making the data actionable in order to help
              understand, research and forecast private fund investments for a growing range of investors.
            </p>
            <p>
              <b>Gavin Burke — Founder & Fund Investor</b>
            </p>
          </Section>

          <ContactSection>
            <header>
              <SectionTitle>Contact</SectionTitle>
            </header>

            <SectionSubtitle>Assistance</SectionSubtitle>
            <p>We welcome any comments questions or suggestions you may have</p>

            <SectionSubtitle>Suggestions</SectionSubtitle>
            <ContactItem>
              <h3>Ideas, Feedback, Corrections</h3>
              <Email href="mailto:suggestions@fundfilter.com">suggestions@fundfilter.com</Email>
            </ContactItem>

            <SectionSubtitle>Support</SectionSubtitle>
            <ContactItem>
              <h3>
                Data <i>(methodology, sources):</i>
              </h3>
              <Email href="mailto:data@fundfilter.com">data@fundfilter.com</Email>
            </ContactItem>

            <ContactItem>
              <h3>
                Tech <i>(functionality, glitches):</i>
              </h3>
              <Email href="mailto:tech@fundfilter.com">tech@fundfilter.com</Email>
            </ContactItem>

            <ContactItem>
              <h3>
                Profile <i>(billing, legal, setup):</i>
              </h3>
              <Email href="mailto:myprofile@fundfilter.com">myprofile@fundfilter.com</Email>
            </ContactItem>

            <ContactItem>
              <h3>
                General <i>(navigation, media):</i>
              </h3>
              <Email href="mailto:general@fundfilter.com">general@fundfilter.com</Email>
            </ContactItem>

            {/*
            <ContactItem>
              <h2>Sales</h2>
              <Tel href="tel:15413334567">+1 (541) 333-4567</Tel>
              <Email href="mailto:sales@fundfilter.com">
                sales@fundfilter.com
              </Email>
            </ContactItem>

            <ContactItem>
              <h2>Business Inquiries</h2>
              <Tel href="tel:15413334567">+1 (541) 333-4567</Tel>
              <Email href="mailto:inquiries@fundfilter.com">
                inquiries@fundfilter.com
              </Email>
            </ContactItem>

            <ContactItem>
              <h2>Funds & Fund Managers</h2>
              <Tel href="tel:15413334567">+1 (541) 333-4567</Tel>
              <Email href="mailto:inquiries@fundfilter.com">
                inquiries@fundfilter.com
              </Email>
            </ContactItem>
             */}

            <SectionSubtitle>Contact</SectionSubtitle>

            <ContactItem>
              <Address>FundFilter, ℅ Altman Inc., 1001 SW Emkay Drive, Suite 100, Bend, OR 97702</Address>
              <Tel href="tel:+18007697992">+1 800 769 7992</Tel>
              <Email href="mailto:info@fundfilter.com">info@fundfilter.com</Email>
            </ContactItem>
          </ContactSection>
          {version && (
            <Footer>
              <small>version: {version}</small>
            </Footer>
          )}
        </Layout>
      </Panel>
    </AppLayout>
  );
}

export default ContactPage;
