import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { lg } from 'src/config/layout';
import { useNewsContent } from 'src/hooks';
import ArticlesList from './ArticlesList';
import SentimentScatter from './SentimentScatter';
import SelectedArticle from './SelectedArticle';

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${lg.BREAKPOINT}px) {
    flex-direction: row;
    overflow: unset;
  }
`;

const Title = styled.div`
  display: flex;
  text-transform: uppercase;
  color: #737373;
`;

const ArticlesSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: ${lg.BREAKPOINT}px) {
    flex: 4;
  }
`;

const SelectedArticleSection = styled.section`
  /* display: none; */
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;

  transform: translate3d(0px, ${p => (p.$hidden ? 120 : 0)}%, 0);
  transition: transform 350ms ease;

  @media (min-width: ${lg.BREAKPOINT}px) {
    display: ${p => p.$forceCondensedView && 'none'};
    position: static;
    padding: 8px 8px 8px 20px;
    transform: translate3d(0px, 0%, 0);
    box-shadow: none;
    flex: 3;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 1;
  font-size: 1.5rem;
  font-weight: 300;
  color: #ffffff99;
  border: none;
  background: transparent;
  top: 0;
  right: 0;
  width: 1.5em;
  height: 1.5em;

  outline: none;
  cursor: pointer;

  &::active,
  &:focus {
    outline: none;
    border: none;
  }

  &::before {
    content: '✕';
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    display: none;
  }
`;

const Section = styled.div`
  display: ${p => p.$display === false && 'none'};
  flex: 1;
  flex-basis: 100%;
`;

const Triangle = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 24px solid #e1b62a;
  border-right: 24px solid transparent;

  @media (min-width: ${lg.BREAKPOINT}px) {
    top: 8px;
    left: unset;
    right: 8px;
    width: 0;
    height: 0;
    border: none;
    border-top: 24px solid #e1b62a;
    border-left: 24px solid transparent;
  }
`;

function NewsArticles({
  className,
  articles,
  defaultSelectedArticleId,
  width,
  targetRef,
  onSelectedArticle,
  highlightSelectedArticle,
  title,
  showSentimentGraph,
  sentimentFilter,
  sentimentGraphLayout,
}) {
  const defaultArticleSet = useRef(false);
  const [selectedArticleId, setSelectedArticleId] = useState(defaultSelectedArticleId);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [userSelectedArticle, setUserSelectedArticle] = useState(false);
  const selectedArticleContent = useNewsContent({
    articleId: selectedArticleId,
  });

  useEffect(
    function () {
      if (!articles) return;
      if (articles.length < 1) return;
      if (defaultArticleSet.current) return;

      const articleId = defaultSelectedArticleId || articles[0].articleId;
      setSelectedArticleId(articleId);

      defaultArticleSet.current = true;
    },
    [articles, defaultSelectedArticleId]
  );

  useEffect(() => {
    if (!selectedArticleId) return;
    if (!articles) return;
    if (!selectedArticleContent) return;
    if (!selectedArticleContent.fetched) return;
    if (selectedArticleContent.articleId !== selectedArticleId) return;

    setSelectedArticle({
      ...articles.find(a => a.articleId === selectedArticleId),
      ...selectedArticleContent,
    });
  }, [articles, selectedArticleId, selectedArticleContent]);

  const handleLatestNewsListDefCreated = useCallback(function (listDef) {
    listDef[0].label = 'LATEST NEWS';
  }, []);

  const handleRecentNewsRowClick = useCallback(
    function (event, { data }) {
      event.preventDefault();

      setUserSelectedArticle(true);
      setSelectedArticleId(data.articleId);
      onSelectedArticle(data);
    },
    [setUserSelectedArticle, setSelectedArticleId, onSelectedArticle]
  );

  const handleSentimentClickViewArticle = useCallback(
    function (article) {
      setUserSelectedArticle(true);
      setSelectedArticleId(article.articleId);
      onSelectedArticle(article);
    },
    [setUserSelectedArticle, setSelectedArticleId, onSelectedArticle]
  );

  const handleHideSelectedArticlePanel = useCallback(function () {
    setUserSelectedArticle(false);
  }, []);

  const forceCondensedView = width < 1144;
  const sentimentGraphOnlyView = showSentimentGraph && sentimentGraphLayout === 'full';

  return (
    <Layout ref={targetRef} className={className}>
      <ArticlesSection $layout={sentimentGraphLayout}>
        {title && <Title>{title}</Title>}

        <Section
          $display={Boolean(showSentimentGraph)}
          css={`
            flex-basis: 40%;
          `}
        >
          <SentimentScatter
            $layout={sentimentGraphLayout}
            articles={articles.filter(sentimentFilter)}
            onClickViewArticle={handleSentimentClickViewArticle}
          />
        </Section>

        <Section $display={!sentimentGraphOnlyView}>
          <ArticlesList
            css={`
              flex: 1;
            `}
            articles={articles}
            onRowClick={handleRecentNewsRowClick}
            selectedArticleId={selectedArticleId}
            forceCondensedView={forceCondensedView}
            onListDefCreated={handleLatestNewsListDefCreated}
            highlightSelectedArticle={highlightSelectedArticle}
          />
        </Section>
      </ArticlesSection>
      <SelectedArticleSection $hidden={!userSelectedArticle} $forceCondensedView={forceCondensedView}>
        <SelectedArticle selectedArticle={selectedArticle} />
        <CloseButton aria-label="Close" onClick={handleHideSelectedArticlePanel} />
        <Triangle />
      </SelectedArticleSection>
    </Layout>
  );
}

NewsArticles.defaultProps = {
  articles: [],
  defaultSelectedArticleId: null,
  disableSideBySideView: false,
  highlightSelectedArticle: false,
  onSelectedArticle: () => {},
  onShare: () => {},
  sentimentGraphLayout: 'default',
  sentimentFilter: () => true,
  showSentimentGraph: false,
  title: '',
};

NewsArticles.propTypes = {
  articles: PropTypes.array.isRequired,
  defaultSelectedArticleId: PropTypes.string,
  disableSideBySideView: PropTypes.bool.isRequired,
  highlightSelectedArticle: PropTypes.bool,
  onSelectedArticle: PropTypes.func,
  onShare: PropTypes.func,
  sentimentGraphLayout: PropTypes.oneOf(['default', 'full']),
  sentimentFilter: PropTypes.func.isRequired,
  showSentimentGraph: PropTypes.bool,
  title: PropTypes.node,
};

export default withResizeDetector(React.memo(NewsArticles));
