import { groupBy } from 'src/lib/lodash';

export function createHierarchy(
  primaryKey = 'id',
  foreignKey = 'parentId',
) {
  return function (arr) {
    if (!arr) return [];
    if (arr.length < 1) return [];
    const collection = groupBy(arr, foreignKey);
    return collection[null].map(item => {
      return { ...item, children: collection[item[primaryKey]] };
    });
  };
}

export function getLeafs(nodes, accumulator = []) {
  for (let i = 0, { length } = nodes; i < length; i++) {
    if (!nodes[i].children || nodes[i].children.length === 0) {
      accumulator.push(nodes[i]);
    } else {
      // eslint-disable-next-line no-param-reassign
      accumulator = getLeafs(
        Object.values(nodes[i].children),
        accumulator,
      );
    }
  }
  return accumulator;
}

export function findNode(root, id, idProp) {
  const stack = [];
  let node;
  stack.push(...root);

  while (stack.length > 0) {
    node = stack.pop();
    if (node[idProp] === id) {
      // found it
      return node;
    }
    if (node.children && node.children.length) {
      stack.push(...node.children);
    }
  }
  return null;
}

export function visitEveryNode(root, fn) {
  for (let i = 0; i < root.length; i++) {
    fn(root[i]);
    if (root[i].children && root[i].children.length > 0) {
      for (let j = 0; j < root[i].children.length; j++) {
        visitEveryNode(root[i].children, fn);
      }
    }
  }
  if (root.children && root.children.length > 0) {
    fn(root);

    for (let j = 0; j < root.children.length; j++) {
      visitEveryNode(root, fn);
    }
  }
}

export function getDescendants(node, accumulator = []) {
  if (node.children && node.children.length > 0) {
    for (let i = 0; i < node.children.length; i++) {
      accumulator.push(node.children[i]);
      getDescendants(node.children[i], accumulator);
    }
  }
  return accumulator;
}

export function getSiblings(node, root, idProp) {
  if (node.parentId) {
    const parent = findNode(root, node.parentId, idProp);
    if (parent) {
      return parent.children;
    }
  }
  return null;
}

export function getParents(node, root, idProp, accumulator = []) {
  if (node.parentId) {
    const parent = findNode(root, node.parentId, idProp);
    if (parent) {
      accumulator.push(parent);
    }
    getParents(parent, root, idProp, accumulator);
  }
  return accumulator;
}
