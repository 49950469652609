import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import getDataKeys from './getDataKeys';
import colorScale from './colors';

function getLegendItems({ keys }) {
  const color = colorScale(keys);

  return keys.map((key, index) => ({
    label: key,
    color: color(index),
  }));
}

const Layout = styled.div`
  display: flex;
  justify-content: center;
`;

const Item = styled.div`
  color: ${p => p.$color};
  margin: 0 0.5em;
  display: flex;
  align-items: center;
`;

const ItemKey = styled.span`
  display: block;
  background-color: currentColor;
  width: 10px;
  height: 10px;
  margin-right: 0.25em;
`;

const ItemLabel = styled.span`
  font-size: 10px;
  line-height: 1;
  color: #808080;
`;

function Legend(props) {
  const { data } = props;
  const keys = getDataKeys(data);
  const items = getLegendItems({ keys });

  return (
    <Layout>
      {items.map(({ label, color }) => (
        <Item key={label} $color={color}>
          <ItemKey />
          <ItemLabel>{label}</ItemLabel>{' '}
        </Item>
      ))}
    </Layout>
  );
}

Legend.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Legend;
