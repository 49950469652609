import { useContext, useCallback } from 'react';
import { AppLayout, AppPanel } from 'src/components';

function useLayoutActions() {
  const { setExpandedId } = useContext(AppLayout.Context);
  const { panelId, isExpanded, isExpanding } = useContext(AppPanel.Context);

  const expandPanel = useCallback(
    function () {
      return setExpandedId(panelId);
    },
    [panelId, setExpandedId]
  );

  const collapsePanel = useCallback(
    function () {
      return setExpandedId(null);
    },
    [setExpandedId]
  );

  const toggleExpanded = useCallback(
    function () {
      return setExpandedId(isExpanded ? null : panelId);
    },
    [isExpanded, panelId, setExpandedId]
  );

  return { toggleExpanded, panelId, expandPanel, collapsePanel, isExpanded, isExpanding };
}

export default useLayoutActions;
