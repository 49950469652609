import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { newSequential } from 'src/utils/array';
import { sm } from 'src/config/layout';
import ButtonRounded from 'src/components/atoms/ButtonRounded';
import Context from './Context';
import Panel from './Panel';
import ControlBar from './ControlBar';

const SPANY_OPTIONS = ['full', 'half', 'third', ...newSequential(12, 1)];

const SPANX_OPTIONS = ['full', 'half', 1, 2];

function AppPanel(props) {
  return <Panel {...props} />;
}

AppPanel.CONSTANTS = { SPANY_OPTIONS, SPANX_OPTIONS };
AppPanel.Context = Context;
AppPanel.ControlBar = ControlBar;
AppPanel.Button = styled(ButtonRounded)`
  margin: 2px;
  padding: 0 5px;
  font-size: 0;

  .MuiButton-label > span {
    margin-right: 0;
    margin-left: 0;
  }

  &[aria-disabled='true'],
  &:disabled {
    opacity: 0.5;
  }

  @media (min-width: ${sm.BREAKPOINT}px) {
    font-size: 11px;

    .MuiButton-label > span {
      margin-right: 2px;
      margin-left: 2px;
    }
  }
`;

AppPanel.defaultProps = {
  render: () => null,
  tutorial: null,
  expandable: false,
  downloadable: false,
  controls: <></>,
  title: '',
  minHeight: 0,
  isLoading: false,
};

AppPanel.propTypes = {
  spanX: PropTypes.oneOf(SPANX_OPTIONS),
  spanY: PropTypes.oneOf(SPANY_OPTIONS),
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  expandable: PropTypes.bool,
  tutorial: PropTypes.shape({
    title: PropTypes.string,
    videoId: PropTypes.number.isRequired,
  }),
  downloadable: PropTypes.oneOfType([
    PropTypes.oneOf([false, true]),
    PropTypes.shape({
      fileName: PropTypes.string,
    }),
  ]),
  isLoading: PropTypes.bool,
  expandedWidth: PropTypes.number,
  expandedHeight: PropTypes.number,
  onClickDownload: PropTypes.func,
};

export default AppPanel;
