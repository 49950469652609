import React from 'react';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@material-ui/core';
import { Button } from 'src/components';

function AgreeToContinueDialog({ open, onClickConfirm, onClickCancel, isBusy, title, children, checkboxLabel }) {
  const [accepted, setAccepted] = useState(false);

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}

        <FormControlLabel
          control={
            <Checkbox
              checked={accepted}
              onChange={event => setAccepted(event.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label={checkboxLabel}
        />
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={onClickCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" disabled={!accepted || isBusy} onClick={onClickConfirm}>
          {isBusy ? 'Please wait...' : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AgreeToContinueDialog.defaultProps = {
  checkboxLabel: 'I agree',
  isBusy: false,
};

export default AgreeToContinueDialog;
