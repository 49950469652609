import {
  db,
  firestoreTimestamp,
  firestoreFieldValue,
  serializeTimestamps,
  applyQueryFilters,
  auth,
} from 'src/config/firebase';
import { denormalize } from 'src/utils/object';
import { DocumentNotFoundError, UnauthorizedError } from '../errors';
import { validatesPresence } from '../validations';

const DEFAULT_PORTFOLIO_NAME = 'Saved List';
const DEFAULT_PORTFOLIO_FUNDS = [
  'q3HdoJPgo5y6qfrPOmtk',
  'm9veLnsr1kAfHwx48T46',
  'RyJUb2VrP7SB9Q6VriTt',
  'SSKX5OXrbXHGAYMHIbPY',
];
const DEFAULT_PORTFOLIO_COMMITMENT_AMOUNT = 1000000;

const collection = db.collection('userPortfolios');

function requireUserId() {
  if (!auth.currentUser) throw new UnauthorizedError();
  return auth.currentUser.uid;
}

async function list(filters = {}) {
  const userId = requireUserId();
  const query = applyQueryFilters(collection, { userId, ...filters });
  const snapshot = await query.get();

  return snapshot.docs
    .map(d => d.data())
    .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
    .sort(a => (a.default ? -1 : 0))
    .map(serializeTimestamps)
    .map(denormalize('bookmarks', 'portfolioId'));
}

async function get(docId) {
  const doc = await collection.doc(docId).get();
  if (!doc.exists) throw new DocumentNotFoundError(docId);

  return denormalize('bookmarks', 'portfolioId')(serializeTimestamps(doc.data()));
}

async function getDefault(userId) {
  userId = userId ?? requireUserId();
  const query = collection.where('userId', '==', userId).where('default', '==', true);
  const snapshot = await query.get();

  if (snapshot.empty) return null;

  return snapshot.docs
    .map(d => d.data())
    .map(serializeTimestamps)
    .map(denormalize('bookmarks', 'portfolioId'))[0];
}

async function create(data) {
  const userId = requireUserId();

  validatesPresence({ name: data.name });

  const doc = collection.doc();
  await doc.set({
    ...data,
    userId,
    portfolioId: doc.id,
    createdAt: firestoreTimestamp(),
    updatedAt: firestoreTimestamp(),
  });
  return doc.id;
}

async function createDefault(userId) {
  userId = userId ?? requireUserId();
  const doc = collection.doc();
  const data = {
    userId,
    portfolioId: doc.id,
    createdAt: firestoreTimestamp(),
    updatedAt: firestoreTimestamp(),
    default: true,
    name: DEFAULT_PORTFOLIO_NAME,
    version: '2022-09-07T18:04:43.503Z',
    bookmarks: DEFAULT_PORTFOLIO_FUNDS.reduce((acc, fundId) => {
      const bookmarkId = `fundId_${fundId}`;
      return {
        ...acc,
        [bookmarkId]: {
          bookmarkId,
          fundId,
          commitmentAmount: DEFAULT_PORTFOLIO_COMMITMENT_AMOUNT,
          createdAt: firestoreTimestamp(),
          updatedAt: firestoreTimestamp(),
        },
      };
    }, {}),
  };

  await doc.set(data);
  return doc.id;
}

async function update(docId, data) {
  const doc = collection.doc(docId);
  if (Object.keys(data).some(key => key === 'name')) {
    validatesPresence({ name: data.name });
  }
  await doc.update({ ...data, updatedAt: firestoreTimestamp() });
  return doc.id;
}

async function addBookmark(portfolioId, data) {
  const { fundId, targetFundId, userFundId, ...rest } = data;

  if (!fundId && !targetFundId && !userFundId) return;

  const filters = Object.entries({ fundId, targetFundId }).filter(([_, value]) => value !== undefined);

  const payload = filters.reduce((acc, [field, value]) => {
    const bookmarkId = `${field}_${value}`;
    return {
      ...acc,
      [`bookmarks.${bookmarkId}`]: {
        ...rest,
        bookmarkId,
        [field]: value,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
    };
  }, {});

  return await update(portfolioId, payload);
}

async function updateBookmark(portfolioId, bookmarkId, data) {
  const payload = Object.entries(data).reduce(
    (acc, [field, value]) => {
      if (value === undefined) return acc;
      return {
        ...acc,
        [`bookmarks.${bookmarkId}.${field}`]: value,
      };
    },
    { [`bookmarks.${bookmarkId}.updatedAt`]: firestoreTimestamp() }
  );

  return await update(portfolioId, payload);
}

async function updateBookmarks(portfolioId, bookmarkIds, data) {
  const payloads = bookmarkIds.map(bookmarkId => {
    return Object.entries(data).reduce(
      (acc, [field, value]) => {
        if (value === undefined) return acc;
        return {
          ...acc,
          [`bookmarks.${bookmarkId}.${field}`]: value,
        };
      },
      { [`bookmarks.${bookmarkId}.updatedAt`]: firestoreTimestamp() }
    );
  });

  return await update(
    portfolioId,
    payloads.reduce((acc, payload) => {
      return { ...acc, ...payload };
    })
  );
}

async function removeBookmark(portfolioId, data) {
  const { fundId, targetFundId } = data;

  const payload = Object.entries({ fundId, targetFundId }).reduce((acc, [field, value]) => {
    if (value === undefined) return acc;
    const bookmarkId = `${field}_${value}`;
    return {
      ...acc,
      [`bookmarks.${bookmarkId}`]: firestoreFieldValue.delete(),
    };
  }, {});

  return await update(portfolioId, payload);
}

async function destroy(docId) {
  const doc = collection.doc(docId);
  await doc.delete();

  return doc.id;
}

export {
  collection,
  list,
  get,
  getDefault,
  create,
  createDefault,
  update,
  destroy,
  addBookmark,
  removeBookmark,
  updateBookmark,
  updateBookmarks,
};
