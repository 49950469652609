import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManager } from '../store/fund-managers';

export default function useFundManager({ fundManagerId }) {
  const dispatch = useDispatch();
  const allFundManagers = useSelector(state => state.fundManager.all.byId) || {};
  const fundManager = allFundManagers[fundManagerId];

  useEffect(() => {
    if (!fundManagerId) return;
    if (fundManager) return;

    dispatch(fetchFundManager({ fundManagerId }));
  }, [dispatch, fundManagerId, fundManager]);

  return fundManager;
}
