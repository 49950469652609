import { useMemo, useCallback } from 'react';
import useUserFundPeerGroupFilter from './useUserFundPeerGroupFilter';
import useStrategiesFilter from './useStrategiesFilter';
import useSizesFilter from './useSizesFilter';
import useVintagesFilter from './useVintagesFilter';
import useRegionsFilter from './useRegionsFilter';
import useFilterSelectedIds from './useFilterSelectedIds';
import useSystemDefaultBenchmark from './useSystemDefaultBenchmark';

function useVintagesFilterWithOptions(fund, defaultSelectedIds) {
  return useVintagesFilter(
    useMemo(
      () =>
        fund
          ? {
              startYear: fund.vintage - 2,
              endYear: fund.vintage + 2,
              coreAttributeIds: [fund.vintage],
              defaultSelectedIds: defaultSelectedIds ?? [fund.vintage - 1, fund.vintage, fund.vintage + 1],
            }
          : undefined,
      [fund, defaultSelectedIds]
    )
  );
}

function useStrategiesFilterWithOptions(fund, defaultSelectedStrategyIds) {
  return useStrategiesFilter(
    useMemo(
      () =>
        fund
          ? {
              coreAttributeStrategyIds: [fund.subStrategyId],
              whitelistParentStrategyIds: [fund.strategyId],
              defaultSelectedStrategyIds,
            }
          : undefined,
      [fund, defaultSelectedStrategyIds]
    )
  );
}

function useDefaultStrategiesFilterWithOptions(fund) {
  return useStrategiesFilter(
    useMemo(
      () =>
        fund
          ? {
              coreAttributeStrategyIds: [fund.subStrategyId],
              whitelistParentStrategyIds: [fund.strategyId],
              defaultSelectedParentStrategyIds: [fund.strategyId],
            }
          : undefined,
      [fund]
    )
  );
}

function useRegionsFilterWithOptions(fund, defaultSelectedIds) {
  return useRegionsFilter(
    useMemo(
      () => ({
        coreAttributeStrategyIds: [fund.mainRegionId].filter(Boolean),
        defaultSelectedIds,
      }),
      [fund, defaultSelectedIds]
    )
  );
}

// function useSecondRegionsFilterWithOptions(fund, defaultSelectedIds) {
//   return useRegionsFilter(
//     useMemo(
//       () => ({
//         coreAttributeStrategyIds: [fund.otherRegionId ?? fund.mainRegionId].filter(Boolean),
//         defaultSelectedIds,
//       }),
//       [fund, defaultSelectedIds]
//     )
//   );
// }
function useSecondRegionsFilterWithOptions(fund) {
  return useRegionsFilter(
    useMemo(
      () => ({
        coreAttributeStrategyIds: [fund.otherRegionId ?? fund.mainRegionId].filter(Boolean),
      }),
      [fund]
    )
  );
}

function useSizesFilterWithOptions(fund, defaultSelectedSizeIds) {
  return useSizesFilter(
    useMemo(
      () => ({
        coreAttributeSizeIds: [fund.sizeId].filter(Boolean),
        defaultSelectedSizeIds,
      }),
      [fund, defaultSelectedSizeIds]
    )
  );
}

function useFundPeerGroupFilter(fund) {
  const {
    persisting,
    selectedVintageIds,
    selectedRegionIds,
    selectedSecondRegionIds,
    selectedSizeIds,
    selectedStrategyIds,
    saveUserPeerGroupFilter,
    resetUserPeerGroupFilter,
  } = useUserFundPeerGroupFilter(fund?.fundId);

  const [vintagesFilter, setVintagesFilter] = useVintagesFilterWithOptions(fund, selectedVintageIds);
  const [defaultVintagesFilter] = useVintagesFilterWithOptions(fund);
  const [regionsFilter, setRegionsFilter] = useRegionsFilterWithOptions(fund, selectedRegionIds);
  const [defaultRegionsFilter] = useRegionsFilterWithOptions(fund);
  const [secondRegionsFilter, setSecondRegionsFilter] = useSecondRegionsFilterWithOptions(
    fund,
    selectedSecondRegionIds
  );
  const [defaultSecondRegionsFilter] = useSecondRegionsFilterWithOptions(fund);
  const [sizesFilter, setSizesFilter] = useSizesFilterWithOptions(fund, selectedSizeIds);
  const [defaultSizesFilter] = useSizesFilterWithOptions(fund);
  const [strategiesFilter, setStrategiesFilter] = useStrategiesFilterWithOptions(fund, selectedStrategyIds);
  const [defaultStrategiesFilter] = useDefaultStrategiesFilterWithOptions(fund);

  const selectedVintagesFilterIds = useFilterSelectedIds(vintagesFilter, 'vintageId');
  const selectedRegionsFilterIds = useFilterSelectedIds(regionsFilter, 'regionId');
  const selectedSecondRegionsFilterIds = useFilterSelectedIds(secondRegionsFilter, 'regionId');
  const selectedSizesFilterIds = useFilterSelectedIds(sizesFilter, 'sizeId');
  const selectedStrategiesFilterIds = useFilterSelectedIds(strategiesFilter, 'strategyId');

  const isSystemDefaultBenchmark = useSystemDefaultBenchmark({
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
  });

  const savePeerGroupFilter = useCallback(() => {
    if (isSystemDefaultBenchmark) {
      return resetUserPeerGroupFilter();
    }
    return saveUserPeerGroupFilter({
      selectedVintageIds: selectedVintagesFilterIds,
      selectedRegionIds: selectedRegionsFilterIds,
      selectedSecondRegionIds: selectedSecondRegionsFilterIds,
      selectedSizeIds: selectedSizesFilterIds,
      selectedStrategyIds: selectedStrategiesFilterIds,
    });
  }, [
    saveUserPeerGroupFilter,
    resetUserPeerGroupFilter,
    selectedVintagesFilterIds,
    selectedRegionsFilterIds,
    selectedSecondRegionsFilterIds,
    selectedSizesFilterIds,
    selectedStrategiesFilterIds,
    isSystemDefaultBenchmark,
  ]);

  return {
    persisting,
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
    savePeerGroupFilter,
    resetPeerGroupFilter: resetUserPeerGroupFilter,
    isSystemDefaultBenchmark,
  };
}

export default useFundPeerGroupFilter;
