function parseUrl(string) {
  try {
    if (!string.startsWith('http')) string = `http://${string}`;
    return new URL(string);
  } catch {
    return '';
  }
}

export { parseUrl };
