/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import IconCheckboxUnchecked from '../IconCheckboxUnchecked';
import IconCheckboxChecked from '../IconCheckboxChecked';

const CheckedIcon = styled(IconCheckboxChecked)``;

const UncheckedIcon = styled(IconCheckboxUnchecked)``;

const Checkbox = styled(MuiCheckbox)`
  width: 18px;
  height: 18px;
  padding: 0;
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`;

export default function CheckboxSmall(props) {
  const { color = 'default', ...rest } = props;
  return <Checkbox color={color} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...rest} />;
}
