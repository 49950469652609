import { useMemo } from 'react';
import useStrategiesFilter from './useStrategiesFilter';
import useSizesFilter from './useSizesFilter';
import useVintagesSelectionFilter from './useVintagesSelectionFilter';
import useRegionsFilter from './useRegionsFilter';

function useStrategiesFilterOptions(fundManagerStrategies, fundManagerSelectedStrategy) {
  return useMemo(() => {
    if (!Array.isArray(fundManagerStrategies)) return;

    const whitelistParentStrategyIds = [fundManagerSelectedStrategy?.strategyId].filter(Boolean);
    if (whitelistParentStrategyIds === 0) return;

    const defaultSelectedParentStrategyIds = whitelistParentStrategyIds;
    return { whitelistParentStrategyIds, defaultSelectedParentStrategyIds };
  }, [fundManagerStrategies, fundManagerSelectedStrategy]);
}

function useFundManagerPerformanceFilter({ fundManagerStrategies, fundManagerSelectedStrategy }) {
  const [vintagesFilter, setVintagesFilter] = useVintagesSelectionFilter();
  const [defaultVintagesFilter] = useVintagesSelectionFilter();
  const [regionsFilter, setRegionsFilter] = useRegionsFilter();
  const [defaultRegionsFilter] = useRegionsFilter();
  const [secondRegionsFilter, setSecondRegionsFilter] = useRegionsFilter();
  const [defaultSecondRegionsFilter] = useRegionsFilter();
  const [sizesFilter, setSizesFilter] = useSizesFilter();
  const [defaultSizesFilter] = useSizesFilter();
  const [strategiesFilter, setStrategiesFilter] = useStrategiesFilter(
    useStrategiesFilterOptions(fundManagerStrategies, fundManagerSelectedStrategy)
  );
  const [defaultStrategiesFilter] = useStrategiesFilter(
    useStrategiesFilterOptions(fundManagerStrategies, fundManagerSelectedStrategy)
  );
  return {
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
  };
}

export default useFundManagerPerformanceFilter;
