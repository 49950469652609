import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconContent(props) {
  return (
    <Svg viewBox="-55 147 500 625" {...props}>
      <g>
        <path d="M-1.5,147v500h393V147H-1.5z M352.2,607.7H37.8V186.3h314.4V607.7z" />
        <rect x="77.1" y="332.2" width="235.8" height="39.3" />
        <rect x="77.1" y="241.7" width="235.8" height="39.3" />
        <rect x="77.1" y="422.6" width="235.8" height="39.3" />
        <rect x="77.1" y="513" width="235.8" height="39.3" />
      </g>
    </Svg>
  );
}
