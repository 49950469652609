import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function IconYoutubePlay(props) {
  return (
    <Svg viewBox="0 0 1023 719" {...props}>
      <path
        d="M1012,155.3 C1012,155.3 1002,84.9 971.4,53.9 C932.6,13.2 889,13 869.1,10.6 C726.1,0.3 511.7,0.3 511.7,0.3 L511.3,0.3 C511.3,0.3 296.9,0.3 153.9,10.6 C134,13 90.4,13.2 51.6,53.9 C21,84.9 11,155.3 11,155.3 C11,155.3 0.8,237.9 0.8,320.6 L0.8,398.1 C0.8,480.8 11,563.4 11,563.4 C11,563.4 21,633.8 51.6,664.8 C90.5,705.5 141.5,704.2 164.2,708.5 C245.9,716.3 511.5,718.8 511.5,718.8 C511.5,718.8 726.1,718.5 869.1,708.1 C889.1,705.7 932.6,705.5 971.4,664.8 C1002,633.8 1012,563.4 1012,563.4 C1012,563.4 1022.2,480.7 1022.2,398.1 L1022.2,320.6 C1022.2,237.9 1012,155.3 1012,155.3 Z M406,492 L406,205 L682,349 L406,492 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default IconYoutubePlay;
