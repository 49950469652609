import React from 'react';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { useFundManagerNames } from 'src/hooks';
import { TextField, ActionButton } from '../Styled';
import { Fade } from '@material-ui/core';
import { IconAdd } from 'src/components';
import MatchedList from 'src/components/molecules/MatchedList';

const Layout = styled.div`
  position: relative;
`;

const StyledPaper = styled(Paper).attrs({
  elevation: 8,
})`
  opacity: ${p => (p.visible ? 1 : 0)};
  height: auto;
  position: absolute;
  z-index: 2;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
`;

const AddButton = styled(ActionButton)`
  margin: 0 16px;
  font-size: ${14 / 18}em;
`;

const ListHeader = styled.h3`
  font-size: ${14 / 18}em;
  margin: 0 16px;
  margin-top: 0.75em;
  opacity: 0.5;
`;

function FundManagerField({ value, onChange, disabled, className }) {
  const [searchQuery, setSearchQuery] = useState((value || {}).name);
  const [hasFocus, setHasFocus] = useState(false);
  const fundManagerNames = useFundManagerNames();
  const [isExactMatch, setIsExactMatch] = useState(false);
  const [hasMatches, setHasMatches] = useState(false);

  const handleInputChange = useCallback(
    function (event) {
      const fundManagerName = event.target.value;
      setSearchQuery(fundManagerName);
      onChange(event, { fundManagerName, fundManagerId: null });
    },
    [onChange]
  );

  const handleQueryMatchesChange = useCallback(
    function (matches) {
      setHasMatches(matches && matches.length > 0);
      setIsExactMatch(
        matches.some(match => {
          return match.value.toLowerCase().trim() === searchQuery.toLowerCase().trim();
        })
      );
    },
    [searchQuery]
  );

  const handleItemClick = useCallback(
    function (item) {
      return function (event) {
        const { fundManagerId, name } = item;

        setSearchQuery(item.name);
        onChange(event, { fundManagerName: name, fundManagerId });
      };
    },
    [onChange]
  );

  if (!fundManagerNames) return null;
  if (!fundManagerNames.byId) return null;

  return (
    <Layout>
      <TextField
        required
        label="Fund Manager"
        onChange={handleInputChange}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        value={searchQuery || value || ''}
        disabled={disabled}
        className={className}
      />

      <Fade in={Boolean(searchQuery) && hasFocus}>
        <StyledPaper>
          {!isExactMatch && (
            <AddButton>
              <IconAdd />
              Add &ldquo;{searchQuery}&rdquo;
            </AddButton>
          )}

          {hasMatches && <ListHeader>Choose from existing fund managers:</ListHeader>}

          <MatchedList
            items={Object.values(fundManagerNames.byId).map(item => ({
              id: item.fundManagerId,
              value: item.name,
              onClick: handleItemClick(item),
            }))}
            query={searchQuery}
            onChange={handleQueryMatchesChange}
            matchLimit={5}
          />
        </StyledPaper>
      </Fade>
    </Layout>
  );
}

FundManagerField.defaultProps = {};

FundManagerField.propTypes = {
  children: PropTypes.any,
};

export default FundManagerField;
