import { useMemo } from 'react';
import useStrategiesFilter from './useStrategiesFilter';
import useSizesFilter from './useSizesFilter';
import useVintagesFilter from './useVintagesFilter';
import useRegionsFilter from './useRegionsFilter';
import useFilterSelectedIds from './useFilterSelectedIds';

function useStrategiesFilterWithOptions(userStrategiesFilter) {
  const selectedUserStrategies = useFilterSelectedIds(userStrategiesFilter, 'strategyId');
  return useStrategiesFilter(
    useMemo(() => {
      const defaultSelectedStrategyIds = selectedUserStrategies.length > 0 ? selectedUserStrategies : null;
      const defaultSelectedParentStrategyIds = defaultSelectedStrategyIds ? null : ['0WMNvp9YQuIxmEDEOO6e'];
      return {
        defaultSelectedStrategyIds,
        defaultSelectedParentStrategyIds,
      };
    }, [selectedUserStrategies])
  );
}

function useMarketFundPerformanceFilter() {
  const [vintagesFilter, setVintagesFilter] = useVintagesFilter();
  const [defaultVintagesFilter] = useVintagesFilter();
  const [regionsFilter, setRegionsFilter] = useRegionsFilter();
  const [defaultRegionsFilter] = useRegionsFilter();
  const [secondRegionsFilter, setSecondRegionsFilter] = useRegionsFilter();
  const [defaultSecondRegionsFilter] = useRegionsFilter();
  const [sizesFilter, setSizesFilter] = useSizesFilter();
  const [defaultSizesFilter] = useSizesFilter();
  const [strategiesFilter, setStrategiesFilter] = useStrategiesFilterWithOptions();
  const [defaultStrategiesFilter] = useStrategiesFilterWithOptions(strategiesFilter);

  return {
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
  };
}

export default useMarketFundPerformanceFilter;
