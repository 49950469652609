import { db, firestoreTimestamp, auth } from 'src/config/firebase';
import { DocumentNotFoundError } from '../errors';

const collection = db.collection('userFundPeerGroupFilters');

function dataPayload(data) {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    return { ...acc, [key]: value };
  }, {});
}

async function list(filters = {}) {
  const userId = auth.currentUser.uid;
  const fields = Object.entries({ ...filters, userId });
  const query = fields.reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    return acc.where(key, '==', value);
  }, collection);
  const snapshot = await query.get();
  return snapshot.docs.map(d => d.data());
}

async function get(docId) {
  const doc = await collection.doc(docId).get();
  if (!doc.exists) throw new DocumentNotFoundError(doc);

  return doc.data();
}

async function create({ fundId, settings = {}, ...data }) {
  const userId = auth.currentUser.uid;
  const doc = collection.doc();
  const payload = {
    ...data,
    settings,
    fundId,
    userId,
    filterId: doc.id,
    createdAt: firestoreTimestamp(),
    updatedAt: firestoreTimestamp(),
  };

  await doc.set(dataPayload(payload));
  return payload;
}

async function update(docId, data) {
  const doc = collection.doc(docId);
  const payload = {
    ...data,
    updatedAt: firestoreTimestamp(),
  };

  await doc.update(dataPayload(payload));
  return payload;
}

async function destroy(bookmarkId) {
  const doc = collection.doc(bookmarkId);
  await doc.delete();
  return doc.id;
}

export { collection, list, get, create, update, destroy };
