import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFundsTableColumnPresets } from 'src/hooks';
import { VirtualizedGridTable, SubscriptionPaywall } from 'src/components';

const Title = styled.span`
  margin-left: 6px;
  font-size: 16px;
  font-weight: 300;
  color: #737373;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Empty = styled.div`
  height: ${props => props.height || '100%'};
  display: grid;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;

  & > div {
    max-width: 300px;
  }
`;

function CurrentFundPerformanceTable({ className, funds }) {
  const { columns: presetColumns, tableCss } = useFundsTableColumnPresets();

  const columns = useMemo(() => {
    return [
      ...presetColumns.filter(column =>
        [
          'name',
          'info',
          'vintage',
          'size',
          'strategy',
          'subStrategy',
          'geography',
          'dpi',
          'rvpi',
          'irr',
          'tvpi',
          'calledPct',
        ].includes(column.id)
      ),
      {
        id: 'status',
        Header: 'Status',
        accessor: 'fundStatus',
        width: '5.5em',
        minDisplayWidth: 0,
      },
    ];
  }, [presetColumns]);

  return (
    <Layout className={className} as={SubscriptionPaywall}>
      <Title>Current Fund Performance</Title>
      <VirtualizedGridTable
        columns={columns}
        data={funds}
        initialSortBy={[
          {
            id: 'vintage',
            desc: true,
          },
        ]}
        css={`
          ${tableCss};

          .status {
            text-align: center;
          }
        `}
      />

      {funds.length < 1 && (
        <Empty>
          <div>
            <p>There is currently no fund performance data for this fund&nbsp;manager</p>
            <p>You can add a fund by clicking the &ldquo;add a fund&rdquo;&nbsp;feature</p>
          </div>
        </Empty>
      )}
    </Layout>
  );
}

CurrentFundPerformanceTable.defaultProps = {
  vintages: [],
  strategies: [],
  sizes: [],
  regions: [],
};

CurrentFundPerformanceTable.propTypes = {
  vintages: PropTypes.array,
  strategies: PropTypes.array,
  sizes: PropTypes.array,
  regions: PropTypes.array,
};

export default CurrentFundPerformanceTable;
