import { typeCheck } from 'type-check';

function isRequiredError(typeName) {
  return function (x) {
    const passed = typeCheck(typeName, x);
    if (passed) return true;
    console.warn(`${x} is not of type ${typeName}`);
  };
}

function isRequiredWarn(typeName) {
  return function (x) {
    const passed = typeCheck(typeName, x);
    if (passed) return true;
    throw new Error(`${x} is not of type ${typeName}`);
  };
}

function isType(typeName, { required = false } = {}) {
  if (typeName === 'any') typeName = '*';
  return function (x) {
    if (required === 'warn') return isRequiredWarn(typeName)(x);
    if (required === 'error') return isRequiredError(typeName)(x);
    if (required) return isRequiredError(typeName)(x);
    return typeCheck(typeName, x);
  };
}

function isObject(val, opts) {
  return isType('Object', opts)(val);
}

function isDate(val, opts) {
  return isType('Date', opts)(val);
}

function isArray(val, opts) {
  return isType('Array', opts)(val);
}

function isString(val, opts) {
  return isType('String', opts)(val);
}

function isBoolean(val, opts) {
  return isType('Boolean', opts)(val);
}

export { isType, isObject, isDate, isArray, isString, isBoolean };
