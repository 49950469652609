import { useMemo } from 'react';
import { useFunds } from 'src/hooks';
import { useGet as useGetPortfolio } from 'src/services/filter-api/userPortfolios/queries';
import { useList as useListTargetFunds } from 'src/services/filter-api/userTargetFunds/queries';

function mergeWithBookmark(bookmarks) {
  return function (item) {
    const type = item.targetFundId ? 'targetFund' : 'fund';
    const primaryKey = `${type}Id`;
    const key = item[primaryKey];
    const bookmark = bookmarks.find(b => b[primaryKey] === key);

    if (!bookmark) return null;
    return { ...item, ...bookmark, type, primaryKey, key };
  };
}

function usePortfolioItems({ portfolioId }) {
  const { data: portfolio, isFetching, isLoading } = useGetPortfolio(portfolioId);

  const bookmarks = useMemo(() => {
    if (!portfolioId) return [];
    if (!portfolio?.bookmarks) return [];
    return portfolio.bookmarks;
  }, [portfolio, portfolioId]);

  const funds = useFunds({
    fundIds: bookmarks.map(f => f.fundId).filter(Boolean),
  });

  const { data: targetFunds } = useListTargetFunds({ portfolioId });

  const forecastItems = useMemo(() => {
    return [...(funds || []), ...(targetFunds || [])]
      .map(mergeWithBookmark(bookmarks))
      .filter(Boolean)
      .sort((a, b) => {
        return a.createdAt.localeCompare(b.createdAt);
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  }, [bookmarks, funds, targetFunds]);

  return [forecastItems, { isFetching, isLoading }];
}

export default usePortfolioItems;
