import styled from 'styled-components';
import { IconYoutubePlay } from 'src/components';
import { lg } from 'src/config/layout';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  gap: 1em;
  width: 100%;

  @media (min-width: ${lg.BREAKPOINT}px) {
    padding-top: 1em;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    max-width: 1100px;
  }
`;

const VideoPreview = styled.div`
  position: relative;
  border: 1px solid #333;
  cursor: pointer;
`;

const VideoInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  text-align: center;
  font-weight: 500;
  color: #aaa;
`;

const PlayIcon = styled(IconYoutubePlay)`
  height: 40px;
  width: 40px;
`;

const Title = styled.div`
  margin-top: 0.5em;
`;

export { Layout, Grid, VideoPreview, VideoInformation, PlayIcon, Title };
