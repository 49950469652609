import React from 'react';
import { useMemo, useCallback } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { parseISO, getLastQuarter } from 'src/utils/date';
import * as calc from 'src/calculators';
import ErrorBoundary from '../ErrorBoundary';
import Chart from './Chart';
import Table from './Table';
import useFundCashflowData from './useFundCashflowData';

const VARIANTS = {
  TOP: 'Top Quartile',
  BOTTOM: 'Bottom Quartile',
  ALL: 'Mean Cashflow',
};

const TYPES = {
  JCURVE_MEAN: 'jCurve (Mean)',
  JCURVE_POLY: 'jCurve',
  JCURVE: 'jCurve',
  NAV: 'Net Asset Value',
  CASHFLOW: 'Net Cashflow',
};

const VIEWS = {
  CHART: 'Chart',
  TABLE: 'Table',
};

const CONFIDENCE_LEVELS = {
  _90: 0.9,
  _95: 0.95,
};

function CashflowDataVisualization({
  type,
  view,
  subjectTimeseries,
  forecastTimeseries,
  startDate,
  margin,
  colors,
  className,
  Footer,
  navTarget,
  smallXAxisLabels,
  smallYAxisLabels,
  showXAxisLabels,
  showYAxisLabels,
  showMilestoneMarkers,
  normalizeYAxisData,
  onChangeTableRows,
  ...rest
}) {
  const startDateObject = useMemo(() => {
    if (typeof startDate === 'string') return parseISO(startDate);
    return startDate;
  }, [startDate]);

  const dataMapper = useCallback(
    function (namespace) {
      function getBase(d) {
        return {
          q: d.q,
          calledPct: d.calledPct,
          dpi: d.dpi,
          rvpi: d.rvpi,
          tvpi: d.tvpi,
          highlight: d.highlight,
          placeholder: d.placeholder,
          unavailable: d.unavailable,
          fundName: d.fundName,
        };
      }

      function getNcfCurve(d) {
        const scale = d.commitmentAmount || 1;
        const ncf = calc.netCashflow(d) * scale;
        const distributions = calc.distPct(d) * scale;
        const contributions = d.calledPct * scale;
        return {
          ...getBase(d),
          ncf,
          distributions,
          contributions,
          [`${namespace}Curve`]: ncf,
        };
      }

      function getNavCurve(d) {
        const scale = d.commitmentAmount || 1;
        const nav = calc.netAssetValue(d) * scale;
        const distributions = calc.distPct(d) * scale;
        const contributions = d.calledPct * scale;
        return {
          ...getBase(d),
          nav,
          distributions,
          contributions,
          [`${namespace}Curve`]: nav,
        };
      }

      function getContributionsDistributions(d) {
        const scale = d.commitmentAmount || 1;
        const distribution = d.distribution * scale;
        const contribution = d.contribution * -1 * scale;

        return {
          ...getBase(d),
          distribution,
          contribution,
          [`${namespace}Curve`]: null,
          [`${namespace}BarRise`]: distribution,
          [`${namespace}BarFall`]: contribution,
        };
      }

      return {
        [TYPES.JCURVE_POLY]: getNcfCurve,
        [TYPES.JCURVE_MEAN]: getNcfCurve,
        [TYPES.NAV]: getNavCurve,
        [TYPES.CASHFLOW]: getContributionsDistributions,
      }[type];
    },
    [type]
  );

  const subjectData = useMemo(() => {
    if (!subjectTimeseries) return [];
    return subjectTimeseries.map(dataMapper('subject')).filter(Boolean);
  }, [dataMapper, subjectTimeseries]);

  const forecastData = useMemo(() => {
    if (!forecastTimeseries) return [];
    return forecastTimeseries.map(dataMapper('forecast')).filter(Boolean);
  }, [dataMapper, forecastTimeseries]);

  const data = useMemo(() => {
    const last = [...subjectData].pop();

    return [
      ...subjectData,
      ...forecastData.filter(d => {
        if (!last) return true;
        return d.q > last.q;
      }),
    ];
  }, [forecastData, subjectData]);

  if (view === VIEWS.TABLE) {
    return (
      <ErrorBoundary>
        <Table type={type} data={data} startDate={startDateObject} onChangeRows={onChangeTableRows} />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <div
        css={`
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
      >
        <Chart
          {...rest}
          data={data}
          startDate={startDateObject}
          navTarget={navTarget}
          margin={margin}
          colors={colors}
          className={className}
          smallXAxisLabels={smallXAxisLabels}
          smallYAxisLabels={smallYAxisLabels}
          showXAxisLabels={showXAxisLabels}
          showYAxisLabels={showYAxisLabels}
          showMilestoneMarkers={showMilestoneMarkers}
          normalizeYAxisData={normalizeYAxisData}
        />
        {Footer && <Footer {...rest} type={type} view={view} />}
      </div>
    </ErrorBoundary>
  );
}

CashflowDataVisualization.CONSTANTS = {
  VARIANTS,
  TYPES,
  VIEWS,
  CONFIDENCE_LEVELS,
};

CashflowDataVisualization.useFundCashflowData = useFundCashflowData;

CashflowDataVisualization.defaultProps = {
  confidenceLevel: 0,
  cutoff: 40,
  margin: { top: '2em', right: '1em', bottom: '3em', left: '1em' },
  type: TYPES.JCURVE_MEAN,
  view: VIEWS.CHART,
  forecastTimeseries: [],
  subjectTimeseries: [],
  smallXAxisLabels: false,
  smallYAxisLabels: false,
  showXAxisLabels: true,
  showYAxisLabels: true,
  showMilestoneMarkers: true,
  onChangeTableRows: funcStub('onChangeTableRows'),
  startDate: getLastQuarter(),
};

CashflowDataVisualization.propTypes = {
  navTarget: PropTypes.number,
  xAxisFormatter: PropTypes.func,
  xAxisTicks: PropTypes.number,
  yAxisFormatter: PropTypes.func,
  smallXAxisLabels: PropTypes.bool,
  smallYAxisLabels: PropTypes.bool,
  showXAxisLabels: PropTypes.bool,
  showYAxisLabels: PropTypes.bool,
  showMilestoneMarkers: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  colors: PropTypes.shape({
    subjectLineColor: PropTypes.string,
    subjectDistributionBarColor: PropTypes.string,
    subjectContributionBarColor: PropTypes.string,
    forecastLineColor: PropTypes.string,
    forecastDistributionBarColor: PropTypes.string,
    forecastContributionBarColor: PropTypes.string,
    navLabelColor: PropTypes.string,
    milestoneAccentColor: PropTypes.string,
  }),
  forecastTimeseries: PropTypes.arrayOf(
    PropTypes.shape({
      q: PropTypes.number,
      calledPct: PropTypes.number,
      dpi: PropTypes.number,
      rvpi: PropTypes.number,
    })
  ),
  subjectTimeseries: PropTypes.arrayOf(
    PropTypes.shape({
      q: PropTypes.number,
      calledPct: PropTypes.number,
      dpi: PropTypes.number,
      rvpi: PropTypes.number,
    })
  ),
  onChangeTableRows: PropTypes.func,
};

export { VARIANTS, TYPES, VIEWS, CONFIDENCE_LEVELS };

export default CashflowDataVisualization;
