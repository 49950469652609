import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function fundPeerFilter({ includeFund, fundId, vintages }) {
  return function (peer) {
    if (includeFund && peer.fundId === fundId) return true;
    return vintages.indexOf(peer.vintage) > -1;
  };
}

function useFundPeers({
  fundId,
  numOfPrevVintages = 1,
  numOfNextVintages = 1,
  includeFund = true,
  includeCurrentVintage = true,
  includePrevVintage = true,
  includeNextVintage = true,
}) {
  const funds = useSelector(state => state.fund.all);

  const fund = useMemo(() => {
    if (!fundId) return;
    if (!funds.fetched) return;

    return funds.byId[fundId];
  }, [fundId, funds.byId, funds.fetched]);

  const peerIds = useMemo(() => {
    if (!fund) return;
    if (fund.peerIds) return fund.peerIds;

    return Object.values(funds.byId)
      .filter(peer => {
        return fund.fundId !== peer.fundId;
      })
      .filter(peer => {
        return (
          fund.vintage <= peer.vintage + 2 && fund.vintage >= peer.vintage - 2 && fund.strategyId === peer.strategyId
        );
      })
      .map(peer => peer.fundId);
  }, [fund, funds.byId]);

  return useMemo(() => {
    if (!fund) return;

    const vintages = [
      includePrevVintage &&
        Array(numOfPrevVintages)
          .fill(fund.vintage)
          .map((v, n) => v - 1 - n),
      includeCurrentVintage && fund.vintage,
      includeNextVintage &&
        Array(numOfNextVintages)
          .fill(fund.vintage)
          .map((v, n) => v + 1 + n),
    ]
      .flat()
      .filter(Boolean);

    const peers = peerIds.map(id => funds.byId[id]).filter(fundPeerFilter({ includeFund, fundId, vintages }));

    return peers;
  }, [
    fund,
    fundId,
    funds.byId,
    numOfPrevVintages,
    numOfNextVintages,
    includeCurrentVintage,
    includeFund,
    includeNextVintage,
    includePrevVintage,
    peerIds,
  ]);
}

export default useFundPeers;
