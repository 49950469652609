import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import IconShrink from '../IconShrink';

const Button = styled(IconButton)`
  color: #7d7d7d;
  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    width: 0.625em;
    height: 0.625em;
  }
`;

const ButtonShrinkIcon = props => {
  return (
    <Button size="small" {...props}>
      <IconShrink />
    </Button>
  );
};

ButtonShrinkIcon.defaultProps = {};

ButtonShrinkIcon.propTypes = {};

export default ButtonShrinkIcon;
