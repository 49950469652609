import { useMemo } from 'react';
import { extent } from 'src/utils/array';
import { subtractQuarters, parseISO } from 'src/utils/date';
import {
  useFund,
  useFundTimeseriesQuery,
  useFundForecastQuery,
} from 'src/hooks';

function useFundCashflowData({
  fundId,
  commitmentAmount,
  vintage,
  date,
}) {
  const fund = useFund({ fundId });
  const fetchTimeseries = useFundTimeseriesQuery(fundId, vintage);
  const fetchForecast = useFundForecastQuery(fundId, date);
  const isMatureFund = fetchForecast?.data?.phase === 4;

  const historySeries = useMemo(() => {
    if (fetchTimeseries.isLoading) return [];
    if (!fetchTimeseries?.data?.series) return [];

    return fetchTimeseries.data.series
      .map((d, q) => {
        if (!d) return null;
        const fundName = fund?.name;
        return { q, ...d, commitmentAmount, fundName };
      })
      .filter(Boolean);
  }, [commitmentAmount, fetchTimeseries, fund]);

  const [first, last] = extent(historySeries);

  const forecastOffset = first?.q;
  const startDate = useMemo(() => {
    if (!first) return;
    return subtractQuarters(forecastOffset)(
      parseISO(first.date),
    ).toISOString();
  }, [first, forecastOffset]);

  const forecastPlaceholder = useMemo(() => {
    if (!last) return [];

    const offset = fetchForecast?.data?.forecastStart - forecastOffset;
    const series = new Array(88).fill(last).map((d, q) => ({
      ...d,
      q: q + Number.isFinite(offset) ? offset : 0,
      placeholder: true,
      commitmentAmount: last.commitmentAmount,
      unavailable: isMatureFund,
    }));

    return isMatureFund ? series.slice(0, 16) : series;
  }, [fetchForecast.data, forecastOffset, isMatureFund, last]);

  const forecastSeries = useMemo(() => {
    if (fetchForecast.isLoading) return forecastPlaceholder;
    if (!fetchForecast?.data?.history) return forecastPlaceholder;

    return fetchForecast.data.history.map(d => {
      const fundName = fund?.name;
      return {
        ...d,
        q: d.q + forecastOffset,
        commitmentAmount,
        fundName,
      };
    });
  }, [
    commitmentAmount,
    fetchForecast.data,
    fetchForecast.isLoading,
    forecastOffset,
    forecastPlaceholder,
    fund,
  ]);

  const isLoadingTimeseries = fetchTimeseries.isLoading;
  const isLoading = [fetchTimeseries, fetchForecast].some(
    query => query.isLoading,
  );

  return {
    forecastSeries,
    historySeries,
    startDate,
    isMatureFund,
    isLoading,
    isLoadingTimeseries,
  };
}

export default useFundCashflowData;
