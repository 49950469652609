/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cloneDeep } from 'src/lib/lodash';
import { upperCase } from 'src/formatters';
import BaseVirtualTable from '../VirtualizedTable';
import Tooltip from './Tooltip';
import { IconBookmark } from 'src/components';
import { md } from 'src/config/layout';

const SaveIcon = styled(IconBookmark)`
  position: relative;
  top: 2px;
  color: ${props => (props.data.bookmarked ? '#ccc' : '#3a3a3a')};
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const listDefNarrow = [
  {
    width: 120,
    sortable: true,
    headerStyle: { padding: '0 12px 0 12px' },
    cellStyle: { fontSize: '11px', padding: '0 12px 0 12px' },
    property: 'name',
    key: 'name',
    label: upperCase('name'),
    isNumeric: false,
  },
  {
    width: 120,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'currentFundManagerName',
    key: 'currentFundManagerName',
    label: upperCase('current firm'),
    isNumeric: false,
  },
  {
    width: 120,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'currentTitleName',
    key: 'currentTitleName',
    label: upperCase('current title'),
    isNumeric: false,
  },
  {
    width: 120,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'lastSchoolName',
    key: 'lastSchoolName',
    label: upperCase('last school'),
    isNumeric: false,
    minDisplayWidth: md.BREAKPOINT,
  },
  {
    width: 40,
    key: 'save',
    headerStyle: { textAlign: 'center', padding: '0 0 0 0' },
    cellStyle: { textAlign: 'center', padding: '0 0 0 0' },
    label: upperCase('save'),
    align: 'right',
    component: data => <SaveIcon data={data} />,
  },
];

const listDefWide = [
  {
    width: 140,
    sortable: true,
    headerStyle: { padding: '0 12px 0 12px' },
    cellStyle: { fontSize: '11px', padding: '0 12px 0 12px' },
    property: 'name',
    key: 'name',
    label: upperCase('name'),
    isNumeric: false,
  },
  {
    width: 300,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'currentFundManagerName',
    key: 'currentFundManagerName',
    label: upperCase('current firm'),
    isNumeric: false,
  },
  {
    width: 300,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'currentTitleName',
    key: 'currentTitleName',
    label: upperCase('current title'),
    isNumeric: false,
  },
  {
    width: 220,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'lastSchoolName',
    key: 'lastSchoolName',
    label: upperCase('last school'),
    isNumeric: false,
  },
  {
    width: 220,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    property: 'prevSchoolName',
    key: 'prevSchoolName',
    label: upperCase('prev school'),
    isNumeric: false,
  },
  {
    width: 180,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    key: 'priorExperience1',
    label: upperCase('prior experience 1'),
    component: data => data?.experience?.[1]?.fundManagerName,
  },
  {
    width: 180,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    key: 'priorExperience2',
    label: upperCase('prior experience 2'),
    component: data => data?.experience?.[2]?.fundManagerName,
  },
  {
    width: 180,
    sortable: true,
    cellStyle: { fontSize: '11px' },
    key: 'priorExperience3',
    label: upperCase('prior experience 3'),
    component: data => data?.experience?.[3]?.fundManagerName,
  },
  {
    width: 30,
    key: 'save',
    headerStyle: { textAlign: 'center', padding: '0 0 0 0' },
    cellStyle: { textAlign: 'center', padding: '0 0 0 0' },
    label: upperCase('save'),
    align: 'right',
    component: data => <SaveIcon data={data} />,
  },
];

const VirtualizedTable = styled(BaseVirtualTable)`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
`;

function FundManagerTeamSchoolTable({
  data,
  expanded,
  enableHover,
  listDefNarrow,
  listDefWide,
  rowHoverColor,
  rowHoverBackgroundColor,
  tooltipAnchorEl,
  tooltipData,
  tooltipOpen,
  onListDefCreated,
  onColumnClick,
  onRowClick,
  onRowMouseOver,
  onRowMouseOut,
  ...rest
}) {
  const listRef = useRef(null);
  const listData = useRef(data);
  const [listDef, setListDef] = useState(null);

  useEffect(() => {
    const listDefClone = cloneDeep(expanded ? listDefWide : listDefNarrow);

    // give the parent component a chance to update the listDef
    onListDefCreated(listDefClone);
    setListDef(listDefClone);
  }, [expanded, onListDefCreated, listDefNarrow, listDefWide]);

  useEffect(() => {
    if (!listRef.current || !listData.current) return;

    const selectedEmployeeIndex = listData.current.findIndex(item => item.isSelected);
    if (selectedEmployeeIndex === -1) return;

    listRef.current.scrollToItem(selectedEmployeeIndex, 'smart');
  }, [data, listData]);

  function handleDataUpdate(data) {
    listData.current = data;
  }

  function handleColumnClick(e, { column, data, index }) {
    onColumnClick(e, { column, data, index });
  }

  function handleRowClick(e, { data, index }) {
    onRowClick(e, { data, index });
  }

  function handleRowMouseOver(e, { data, index }) {
    onRowMouseOver(e, { data, index });
  }

  function handleRowMouseOut(e, { data, index }) {
    onRowMouseOut(e, { data, index });
  }

  function getRowBackgroundColor(employee) {
    return employee.isSelected ? '#0d8bab' : undefined;
  }

  function getRowColor(employee) {
    return employee.isSelected ? '#fff' : '#afafaf';
  }

  return (
    <>
      <VirtualizedTable
        data={data}
        listDef={listDef}
        listRef={listRef}
        onDataUpdate={handleDataUpdate}
        onColumnClick={handleColumnClick}
        onRowClick={handleRowClick}
        onRowMouseOver={handleRowMouseOver}
        onRowMouseOut={handleRowMouseOut}
        enableHover={enableHover}
        rowColor={getRowColor}
        rowBackgroundColor={getRowBackgroundColor}
        rowHoverColor={rowHoverColor}
        rowHoverBackgroundColor={rowHoverBackgroundColor}
        {...rest}
      />
      <Tooltip open={Boolean(tooltipOpen) && !expanded} anchorEl={tooltipAnchorEl} data={tooltipData} />
    </>
  );
}

FundManagerTeamSchoolTable.defaultProps = {
  expanded: false,
  components: {},
  enableHover: true,
  listDefNarrow,
  listDefWide,
  rowHoverColor: '#fff',
  rowHoverBackgroundColor: null,
  tooltipAnchorEl: null,
  tooltipData: {},
  tooltipOpen: false,
  onListDefCreated: () => {},
  onColumnClick: () => {},
  onRowClick: () => {},
  onRowMouseOver: () => {},
  onRowMouseOut: () => {},
};

FundManagerTeamSchoolTable.propTypes = {
  data: PropTypes.array.isRequired,
  components: PropTypes.object,
  expanded: PropTypes.bool,
  enableHover: PropTypes.bool,
  listDefNarrow: PropTypes.array,
  listDefWide: PropTypes.array,
  rowHoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  tooltipAnchorEl: PropTypes.object,
  tooltipData: PropTypes.object,
  tooltipOpen: PropTypes.bool,
  onListDefCreated: PropTypes.func,
  onColumnClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowMouseOver: PropTypes.func,
  onRowMouseOut: PropTypes.func,
};

export default FundManagerTeamSchoolTable;
