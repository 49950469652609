import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconFullScreenOn(props) {
  return (
    <Svg viewBox="0 0 150 150" {...props}>
      <g>
        <polygon points="29,61 25,61 25,32 52,32 52,36 29,36  " />
        <polygon points="125,61 121,61 121.1,36 98,36 98,32 125,32  " />
        <polygon points="52,118 25,118 25,89 29,89 29,114 52,114  " />
        <polygon points="125,118 98,118 98,114 121.1,114 121.1,89 125,89  " />
        <path d="M109,100.9H41V49.1h68V100.9z M44.9,97h60.1V53H44.9V97z" />
      </g>
    </Svg>
  );
}
