import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parseISO, formatDurationToNow } from 'src/utils/date';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.a`
  margin-bottom: 8px;
  font-size: 12px;
  color: #0d8bab;
  text-decoration: none;
  &:hover {
    opacity: 0.9;
  }
`;

const ByLine = styled.span`
  font-size: 9px;
  color: #6c6c6c;
  text-transform: uppercase;
`;

const Spacer = styled.span`
  margin: 0 5px;
`;

function NewsArticleSource(props) {
  const { article, className } = props;
  const { title, source } = article;
  const publishedAt = article.publishedAt ? formatDurationToNow(parseISO(article.publishedAt)) : '-';

  return (
    <Layout key={article.articleId} className={className}>
      <Title target="_blank" href={article.url}>
        {title}
      </Title>
      <ByLine>
        {source}
        <Spacer>|</Spacer>
        {publishedAt}
      </ByLine>
    </Layout>
  );
}

NewsArticleSource.defaultProps = {};

NewsArticleSource.propTypes = {
  article: PropTypes.object.isRequired,
};

export default NewsArticleSource;
