import styled from 'styled-components';

export default styled.span`
  color: ${props => props.color || '#333'};
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 300;
`;
