import React from 'react';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';

const Button = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
  }
`;

const ButtonPeople = props => {
  return <Button {...props}>People</Button>;
};

ButtonPeople.defaultProps = {};

ButtonPeople.propTypes = {};

export default ButtonPeople;
