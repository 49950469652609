import { useMemo } from 'react';
import useStrategiesFilter from './useStrategiesFilter';
import useSizesFilter from './useSizesFilter';
import useVintagesFilter from './useVintagesFilter';
import useRegionsFilter from './useRegionsFilter';

function useVintagesFilterWithOptions(fund) {
  return useVintagesFilter(
    useMemo(
      () =>
        fund
          ? {
              startYear: fund.vintage - 2,
              endYear: fund.vintage + 2,
              coreAttributeIds: [fund.vintage],
              defaultSelectedIds: [fund.vintage - 1, fund.vintage, fund.vintage + 1],
            }
          : undefined,
      [fund]
    )
  );
}

function useStrategiesFilterWithOptions(fund) {
  return useStrategiesFilter(
    useMemo(
      () =>
        fund
          ? {
              coreAttributeStrategyIds: [fund.subStrategyId],
              whitelistParentStrategyIds: [fund.strategyId],
            }
          : undefined,
      [fund]
    )
  );
}

function useDefaultStrategiesFilterWithOptions(fund) {
  return useStrategiesFilter(
    useMemo(
      () =>
        fund
          ? {
              coreAttributeStrategyIds: [fund.subStrategyId],
              whitelistParentStrategyIds: [fund.strategyId],
              defaultSelectedParentStrategyIds: [fund.strategyId],
            }
          : undefined,
      [fund]
    )
  );
}

function useRegionsFilterWithOptions(fund) {
  return useRegionsFilter(
    useMemo(
      () => ({
        coreAttributeStrategyIds: [fund.mainRegionId].filter(Boolean),
      }),
      [fund]
    )
  );
}

function useSecondRegionsFilterWithOptions(fund) {
  return useRegionsFilter(
    useMemo(
      () => ({
        coreAttributeStrategyIds: [fund.otherRegionId ?? fund.mainRegionId].filter(Boolean),
      }),
      [fund]
    )
  );
}

function useSizesFilterWithOptions(fund) {
  return useSizesFilter(
    useMemo(
      () => ({
        coreAttributeSizeIds: [fund.sizeId].filter(Boolean),
      }),
      [fund]
    )
  );
}

function useFundSystemPeerGroupFilter(fund) {
  const [vintagesFilter, setVintagesFilter] = useVintagesFilterWithOptions(fund);
  const [defaultVintagesFilter] = useVintagesFilterWithOptions(fund);
  const [regionsFilter, setRegionsFilter] = useRegionsFilterWithOptions(fund);
  const [defaultRegionsFilter] = useRegionsFilterWithOptions(fund);
  const [secondRegionsFilter, setSecondRegionsFilter] = useSecondRegionsFilterWithOptions(fund);
  const [defaultSecondRegionsFilter] = useSecondRegionsFilterWithOptions(fund);
  const [sizesFilter, setSizesFilter] = useSizesFilterWithOptions(fund);
  const [defaultSizesFilter] = useSizesFilterWithOptions(fund);
  const [strategiesFilter, setStrategiesFilter] = useStrategiesFilterWithOptions(fund);
  const [defaultStrategiesFilter] = useDefaultStrategiesFilterWithOptions(fund);

  return {
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
  };
}

export default useFundSystemPeerGroupFilter;
