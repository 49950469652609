import React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMouseEventRefs } from 'src/hooks';
import { D3Canvas } from 'src/components';
import draw from './draw';

const MARGIN = { top: 10, right: 20, bottom: 44, left: 60 };

const StyledD3Canvas = styled(D3Canvas)`
  .tick line,
  .x-axis .domain,
  .y-axis .domain {
    color: rgb(46, 46, 46);
  }

  .x-axis-label,
  .y-axis-label {
    fill: rgb(128, 128, 128);
    font-size: 12px;
  }
`;

function getZoomFactor(extents, original) {
  if (!extents || !original) return 1;

  const xZoom = (original.x[1] - original.x[0]) / (extents.x[1] - extents.x[0]);
  const yZoom = (original.y[1] - original.y[0]) / (extents.y[1] - extents.y[0]);

  return Math.max(xZoom, yZoom);
}

function FundPerformance({
  data,
  metric,
  extents,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  className,
}) {
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  const [zoomExtents, setZoomExtents] = useState(extents);

  useEffect(() => {
    setZoomExtents(extents);
  }, [extents]);

  const zoomFactor = getZoomFactor(zoomExtents, extents);

  return (
    <StyledD3Canvas
      containerProps={{ 'data-cy': 'fund-performance-chart-container' }}
      svgProps={{ 'data-cy': 'fund-performance-chart-svg' }}
      className={className}
      margin={MARGIN}
      draw={draw({
        data,
        mouseEventRefs,
        metric,
        zoom: zoomFactor,
        extents: zoomExtents,
        onChangeZoom: (x, y) => setZoomExtents({ x, y }),
        onResetZoom: () => setZoomExtents(extents),
      })}
    />
  );
}

FundPerformance.defaultProps = {
  expanded: false,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
  targetRef: null,
};

FundPerformance.propTypes = {
  data: PropTypes.array.isRequired,
  metric: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  extents: PropTypes.object.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default FundPerformance;
