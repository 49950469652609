import { chunk } from 'src/lib/lodash';
import { db } from 'src/config/firebase';

const dbFundFinancialHistory = db.collection('fundFinancialHistory');

async function getFundFinancialHistory({ fundId }) {
  return (await dbFundFinancialHistory.orderBy('date', 'desc').where('fundId', '==', fundId).get()).docs.map(d =>
    d.data()
  );
}

async function getFundsFinancialHistory({ fundIds }) {
  const fundIdChunks = chunk(fundIds, 10);
  const fundFinancialHistoryChunks = await Promise.all(
    fundIdChunks.map(fundChunkIds => {
      return dbFundFinancialHistory.where('fundId', 'in', fundChunkIds).orderBy('date', 'desc').get();
    })
  );

  return fundFinancialHistoryChunks
    .map(historyChunk => historyChunk.docs.map(doc => doc.data()))
    .reduce((acc, history) => [...acc, ...history], []);
}

export { getFundFinancialHistory };
export { getFundsFinancialHistory };
