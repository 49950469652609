import { db, firestoreTimestamp } from 'src/config/firebase';
import { DocumentNotFoundError } from 'src/services/filter-api/errors';

const collection = db.collection('users');

function userAdapter(user) {
  if (!user) return null;
  return {
    ...user,
    createdAt: user.createdAt?.toDate().toISOString(),
    updatedAt: user.createdAt?.toDate().toISOString(),
    trialExpiresAt: user.trialExpiresAt?.toDate().toISOString(),
    lastSeenAt: user.lastSeenAt?.toDate().toISOString(),
  };
}

async function get(docId) {
  if (!docId) return null;
  const doc = await collection.doc(docId).get();
  if (!doc.exists) throw new DocumentNotFoundError(doc);

  return userAdapter(doc.data());
}

async function create(userId, data) {
  const createdAt = firestoreTimestamp();
  const updatedAt = createdAt;
  const user = Object.entries(data).reduce(
    (acc, [key, value]) => {
      if (value === undefined) return acc;
      return { ...acc, [key]: value };
    },
    { userId, createdAt, updatedAt },
  );

  await collection.doc(userId).set(user);
  return userAdapter(user);
}

async function update(userId, data) {
  try {
    const user = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (value === undefined) return acc;
        if (value === 'TIMESTAMP') value = firestoreTimestamp();
        return { ...acc, [key]: value };
      },
      { updatedAt: firestoreTimestamp() },
    );
    await collection.doc(userId).set(user, { merge: true });
    return userId;
  } catch (error) {
    console.warn('Caught an error in currentUser.update');
    console.info({ userId, data, error });
    console.error(error);
    return null;
  }
}

export { collection, get, create, update };
