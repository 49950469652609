const INDEX_PREFIX = 'funds/';
const ITEM_PREFIX = 'fund/';

const VIEW_STATE = {
  INDEX_PREFIX,
  ITEM_PREFIX,
  collectionPrefix: INDEX_PREFIX,
  instancePrefix: ITEM_PREFIX,
  grid: `${INDEX_PREFIX}grid`,
  list: `${INDEX_PREFIX}list`,
  timeSeries: `${ITEM_PREFIX}timeseries`,
  keyMetrics: `${ITEM_PREFIX}keymetrics`,
  null: '',
};

export { VIEW_STATE };
