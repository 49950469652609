import { useEffect, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagerNames } from 'src/store/fund-manager-names';
import { fetchFunds } from 'src/store/funds';
import { matchSorter } from 'match-sorter';
import { uniqBy, orderBy } from 'lodash';

const NODE_TYPE = {
  fund: 'fund',
  fundManager: 'fundmanager',
};

function seachOptionAdapter(itemsById, type) {
  return Object.values(itemsById).map(item => {
    return {
      label: item.name,
      name: item.name,
      id: item.fundId || item.fundManagerId,
      fundId: item.fundId,
      fundManagerId: item.fundManagerId,
      type: type,
      uid: [type, item.fundManagerId, item.fundId].join(''),
    };
  });
}

function useSearch() {
  const dispatch = useDispatch();
  const funds = useSelector(s => s.fund.all);
  const fundsById = funds?.byId;
  const fundManagerNames = useSelector(s => s.fundManager.names);
  const fundManagersById = fundManagerNames?.byId;
  const enabled = funds.fetched && fundManagerNames.fetched;
  const [isScrolled, setIsScrolled] = useState(false);

  function handleScroll() {
    if (isScrolled) return;
    setIsScrolled(true);
  }

  function handleReset() {
    if (!isScrolled) return;
    setIsScrolled(false);
  }

  const handleFilterOptions = useCallback(
    (options, { inputValue: searchTerm }) => {
      const matchLimit = searchTerm || isScrolled ? Infinity : 25;

      const matches = matchSorter(options, searchTerm, {
        keys: [
          { key: 'label', threshold: matchSorter.rankings.CONTAINS },
          {
            key: 'id',
            threshold: matchSorter.rankings.STARTS_WITH,
          },
        ],
        baseSort: a => (a.type === NODE_TYPE.fundManager ? -1 : 1),
      }).slice(0, matchLimit);

      const matchResults = matches.flatMap(match => {
        // If the match is a fund manager, include all
        // funds that belong to the fund manager
        if (match.type === NODE_TYPE.fundManager) {
          const fundOptions = options.filter(option => {
            if (option.type !== NODE_TYPE.fund) return false;
            return option.fundManagerId === match.fundManagerId;
          });

          return [match, ...fundOptions];
        }

        // If the match is a fund, include the fund manager
        // that fund belongs to
        if (match.type === NODE_TYPE.fund) {
          const fundManagerOption = options.find(option => {
            const isManager = option.type === NODE_TYPE.fundManager;
            const isMatch = option.fundManagerId === match.fundManagerId;
            return isManager && isMatch;
          });
          return [fundManagerOption, match];
        }

        return [];
      });

      return orderBy(uniqBy(matchResults, 'uid'), 'label', 'asc');
    },
    [isScrolled]
  );

  useEffect(() => {
    if (funds.fetching) return;
    if (funds.fetched) return;

    dispatch(fetchFunds());
  });

  useEffect(() => {
    if (fundManagerNames.fetching) return;
    if (fundManagerNames.fetched) return;

    dispatch(fetchFundManagerNames());
  });

  const query = useQuery({
    queryKey: 'searchOptions',
    async queryFn() {
      return [
        ...seachOptionAdapter(fundsById, NODE_TYPE.fund),
        ...seachOptionAdapter(fundManagersById, NODE_TYPE.fundManager),
      ];
    },
    enabled: enabled,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return {
    options: query.data || [],
    onFilter: handleFilterOptions,
    onScroll: handleScroll,
    onReset: handleReset,
  };
}

export default useSearch;
