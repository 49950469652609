import React from 'react';
import PropTypes from 'prop-types';
import { BookmarkedFundManagersTable } from 'src/components';

function ManagersView({ fundManagers, isExpanded, wideTable, onChangeSelectedId, onRowClick, selectedId }) {
  function handleTableRowClick(_e, { data }) {
    onChangeSelectedId(data.fundManagerId);
    onRowClick(data);
  }

  if (!fundManagers) return null;
  return (
    <BookmarkedFundManagersTable
      data={fundManagers}
      selectedId={selectedId}
      expanded={isExpanded}
      wideTable={wideTable}
      enableHover
      onRowClick={handleTableRowClick}
      css={`
        padding: 10px;
      `}
    />
  );
}

ManagersView.defaultProps = {};

ManagersView.propTypes = {
  children: PropTypes.any,
};

export default ManagersView;
