import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NewsArticleBulletPointLength from './news-article-bullet-point-length';
import { lookup } from 'src/lookup';
import { parseISO, formatDurationToNow } from 'src/utils/date';
import { NewsSentiment } from 'src/components';
import { sm, md, lg, xl } from 'src/config/layout';

const Title = styled.h3`
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  margin-bottom: 20px;
`;

const Details = styled.div`
  font-size: 11px;
  font-weight: 300;
  color: #929090;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    display: initial;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    display: flex;
  }
`;

const SourceGroup = styled.div`
  flex: 1 1 100%;

  display: flex;
  align-items: center;
`;

const SummaryLengthControls = styled(NewsArticleBulletPointLength)`
  flex: 1 0 auto;
  margin-top: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: ${md.BREAKPOINT}px) {
    margin-top: 0;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    margin-top: 16px;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    margin-top: 0;
  }
`;

const Label = styled.span`
  text-transform: uppercase;
  font-size: 9px;

  @media (min-width: ${sm.BREAKPOINT}px) {
    font-size: 11px;
  }
`;

const SourceLabel = styled(Label)`
  font-weight: 500;
  color: #fff;
  margin-right: 5px;

  display: flex;
  align-items: center;

  flex: 0 0 auto;
`;

const SourceIcon = styled.img`
  margin-right: 5px;
  width: 16px;
`;

const DateLabel = styled(Label)`
  flex: 0 0 auto;

  &::before {
    content: '• ';
    margin: 0 0.25em;
  }
`;

const SentimentLabel = styled(NewsSentiment)`
  flex: 1;

  display: ${p => (p.$hidden ? 'none' : 'flex')};
  justify-content: flex-end;
  align-items: center;
  font-size: 9px;

  @media (min-width: ${sm.BREAKPOINT}px) {
    font-size: 11px;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    justify-content: center;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    justify-content: flex-end;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    justify-content: center;
  }
`;

function NewsArticleHeading({ article, favicon, summaryLength, onSummaryLengthChange }) {
  const [sentiment, setSentiment] = useState();

  useEffect(() => {
    if (article) {
      setSentiment(Object.values(lookup.sentiments).find(lu => lu.value === article.sentiment));
    }
  }, [article]);

  const timestamp = article.publishedAt ? formatDurationToNow(parseISO(article.publishedAt)) : '-';

  return (
    <>
      <Title>{article.title}</Title>
      <Details>
        <SourceGroup>
          <SourceLabel>
            {favicon && <SourceIcon alt="source logo" src={favicon} />}
            {article.source}
          </SourceLabel>

          <DateLabel>{timestamp}</DateLabel>

          <SentimentLabel $hidden={!sentiment} value={article.sentiment} size="10px" labelVisible />
        </SourceGroup>

        <SummaryLengthControls value={summaryLength} onChange={onSummaryLengthChange} />
      </Details>
    </>
  );
}

NewsArticleHeading.defaultProps = {
  onSummaryLengthChange: () => {},
  favicon: null,
};

NewsArticleHeading.propTypes = {
  article: PropTypes.object.isRequired,
  favicon: PropTypes.string,
  summaryLength: PropTypes.number.isRequired,
  onSummaryLengthChange: PropTypes.func,
};

export default NewsArticleHeading;
