import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagerFundsInMarketSize } from '../store/fund-manager-funds-in-market-size';

export default function useFundManagerFundsInMarketSize({ fundManagerId }) {
  const dispatch = useDispatch();
  const fundManagerFundsInMarketSize = useSelector(state => state.fundsInMarket.fundManagerSize);
  const [counts, setCounts] = useState(null);

  useEffect(() => {
    if (fundManagerId) {
      dispatch(fetchFundManagerFundsInMarketSize({ fundManagerId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundManagerId]);

  useEffect(() => {
    if (fundManagerFundsInMarketSize.fetched) {
      setCounts(fundManagerFundsInMarketSize.all);
    }
  }, [fundManagerFundsInMarketSize.all, fundManagerFundsInMarketSize.fetched]);

  return counts;
}
