import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconFire(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <g>
        <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z" />
        <path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z" />
        <path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z" />
        <path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z" />
        <path d="M75.5,37.3c-0.6-0.7-1.8-0.2-1.8,0.8c0.2,7.7-6.7,13.9-6.7,13.9c0.9-15.1-7.3-40.3-21.7-49.2c-0.8-0.5-1.8,0.3-1.5,1.2    C49,19.8,16.2,35,16.2,61.6c0,13.8,8.2,26.3,16.5,35.5c0.9,1,2.6,0,2.1-1.3c-1.2-3.3-2-6.8-2-10.5c0-15.9,15.9-25.2,14.2-34.7    c-0.1-0.8,0.8-1.4,1.5-0.8c6.8,6,10.6,20.7,10.2,29.6c0,0,3-3.3,3.4-7.6c0.1-0.9,1.2-1.1,1.6-0.4c3.6,5.4,3.6,11.8,3.6,14    c0,3.7-0.8,7.2-2,10.5c-0.5,1.3,1.2,2.3,2.1,1.3c8.3-9.2,16.5-21.7,16.5-35.5C83.8,57.9,83.8,46.3,75.5,37.3z" />
      </g>
    </Svg>
  );
}
