import { useCallback, useMemo } from 'react';
import * as d3 from 'd3';
import { unique } from 'src/utils/array';
import { sequence } from 'src/utils/number';

const DEFAULTS = {
  width: 0,
  height: 0,
  margin: { top: 20, right: 20, bottom: 30, left: 60 },
  colorScheme: ['gray', 'white'],
  onItemMouseOver: () => {},
  onItemMouseOut: () => {},
  onItemClick: () => {},
  defaultTransition: d3.transition().delay(250).duration(550),
};

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function useChart(args = {}) {
  const {
    targetRef,
    width: svgWidth,
    height: svgHeight,
    margin,
    data,
    colorScheme,
    yAxisLabel,
    xAxisLabel,
    mouseEventRefs,
  } = { ...DEFAULTS, ...args };

  const canvas = useMemo(() => {
    if (!targetRef.current) return {};

    const width = Math.floor(svgWidth - margin.left - margin.right);
    const height = Math.floor(svgHeight - margin.top - margin.bottom);
    const { top, left } = margin;
    const bottom = height + top;
    const right = width + left;
    const isReady = [width, height, top, left, bottom, right].every(Number.isFinite);

    return { width, height, top, left, bottom, right, isReady };
  }, [margin, svgHeight, svgWidth, targetRef]);

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const xDomain = useMemo(() => sequence(...d3.extent(data, d => +d.x)), [data]);
  const xScale = d3.scaleBand().domain(xDomain).range([canvas.left, canvas.right]).padding(0.25);
  const yDomain = useMemo(() => d3.extent(data.flatMap(d => [d.y, d.yStart, d.yEnd])), [data]);
  const yScale = d3.scaleLinear().domain(yDomain).range([canvas.bottom, canvas.top]);
  const zDomain = useMemo(() => unique(data.filter(d => d.y).map(d => d.z)), [data]);

  const zScale = useCallback(
    value => {
      const index = zDomain.indexOf(value);
      const distance = index / zDomain.length;
      return d3.scaleSequential(colorScheme)(distance);
    },
    [colorScheme, zDomain]
  );

  const xAxis = d3.axisBottom(xScale).tickSize(0);

  const yAxis = d3
    .axisLeft(yScale)
    .ticks(5)
    .tickFormat(d => d3.format(',.2r')(d / 1e6) + ' M')
    .tickSize(-canvas.width)
    .tickSizeOuter(0);

  const legendData = useMemo(() => {
    return zDomain
      .map(key => {
        const item = data.find(d => d.z === key);

        if (!item) return null;

        return {
          key,
          color: zScale(key),
          label: item.zLabel,
          value: d3.sum(
            data.filter(d => d.z === key),
            d => d.y
          ),
        };
      })
      .filter(Boolean);
  }, [data, zDomain, zScale]);

  const draw = useCallback(() => {
    if (!canvas.isReady) return;

    const svg = d3
      .select(targetRef.current)
      .selectAll('svg')
      .data([data])
      .join('svg')
      .style('width', '100%')
      .style('height', '100%');

    const xAxisGroup = svg
      .selectAll('.x-axis')
      .data([data])
      .join('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${0},${canvas.bottom + 6})`)
      .call(xAxis);

    xAxisGroup
      .selectAll('.x-axis-label')
      .data([xAxisLabel])
      .join('text')
      .attr('class', 'x-axis-label')
      .attr('x', canvas.width / 2)
      .attr('y', '2em')
      .attr('text-anchor', 'middle')
      .attr('fill', 'currentColor')
      .text(d => d);

    const yAxisGroup = svg
      .selectAll('.y-axis')
      .data([yAxisLabel])
      .join('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${canvas.left},${0})`)
      .call(yAxis);

    yAxisGroup
      .selectAll('.y-axis-label')
      .data([yAxisLabel])
      .join('text')
      .attr('class', 'y-axis-label')
      .attr('x', -(canvas.height / 2) - canvas.top)
      .attr('y', -canvas.left + 10)
      .attr('text-anchor', 'middle')
      .attr('fill', 'currentColor')
      .attr('transform', 'rotate(-90)')
      .text(d => d);

    const defaultTransition = svg.transition().delay(0).duration(550);
    const root = svg.selectAll('.canvas').data([data]).join('g').attr('class', 'canvas');

    root
      .selectAll('rect')
      .data(
        data.filter(d => d.z !== 'placeholder' && d.y !== 0),
        d => `${d.z}-${d.x}`
      )

      .join(
        function enter(selection) {
          selection
            .append('rect')
            .attr('id', d => `${d.z}-${d.x}`)
            .on('mouseover', handleEvent(itemOver))
            .on('mouseout', handleEvent(itemOut))
            .on('click', handleEvent(itemClick))
            .on('touchstart', handleEvent(itemTouchStart))
            .on('touchend', handleEvent(itemTouchEnd))
            .attr('fill', d => zScale(d.z))
            .attr('x', d => xScale(d.x))
            .attr('width', xScale.bandwidth())
            .attr('y', yScale(d3.min(yDomain)))
            .attr('height', 0)
            .transition(defaultTransition)
            .attr('height', d => yScale(d.yStart) - yScale(d.yEnd))
            .attr('y', d => yScale(d.yEnd));
        },
        function update(selection) {
          selection
            .attr('fill', d => zScale(d.z))
            .attr('x', d => xScale(d.x))
            .attr('width', xScale.bandwidth())
            .attr('y', yScale(d3.min(yDomain)))
            .attr('height', 0)
            .transition(defaultTransition)
            .attr('height', d => yScale(d.yStart) - yScale(d.yEnd))
            .attr('y', d => yScale(d.yEnd));
        },
        function exit(selection) {
          selection
            .attr('height', d => yScale(d.yStart) - yScale(d.yEnd))
            .attr('y', d => yScale(d.yEnd))
            .transition(defaultTransition)
            .attr('y', yScale(d3.min(yDomain)))
            .attr('height', 0)
            .remove();
        }
      );
  }, [
    canvas.bottom,
    canvas.height,
    canvas.isReady,
    canvas.left,
    canvas.top,
    canvas.width,
    data,
    itemClick,
    itemOut,
    itemOver,
    itemTouchEnd,
    itemTouchStart,
    targetRef,
    xAxis,
    xAxisLabel,
    xScale,
    yAxis,
    yAxisLabel,
    yDomain,
    yScale,
    zScale,
  ]);

  return {
    canvas,
    xDomain,
    yDomain,
    zDomain,
    xScale,
    yScale,
    zScale,
    legendData,
    draw,
  };
}
export default useChart;
