import { useState, useEffect } from 'react';
import { createHierarchy } from '../utils';
import useStrategies from './useStrategies';

const createStrategyHierarchy = createHierarchy('strategyId', 'parentId');

function useStrategyHierarchy() {
  const [strategyHierarchy, setStrategyHierarchy] = useState(null);
  const strategies = useStrategies();

  useEffect(() => {
    if (strategies) {
      setStrategyHierarchy(createStrategyHierarchy(strategies));
    }
  }, [strategies]);

  return strategyHierarchy;
}

export default useStrategyHierarchy;
