import { isObject } from 'src/utils/object';

function unfurl(array) {
  return array.filter(Boolean).reduce((acc, item) => {
    if (Array.isArray(item)) {
      return [...acc, ...unfurl(item)];
    }
    if (isObject(item)) {
      item = Object.entries(item).filter(kv => kv.every(Boolean));
      return [...acc, ...unfurl(item)];
    }
    return [...acc, item];
  }, []);
}

function queryKeyFactory(primaryKey) {
  const queryKeys = {
    all() {
      return unfurl([primaryKey]);
    },
    list(...args) {
      return unfurl([primaryKey, 'list', args]);
    },
    detail(...args) {
      return unfurl([primaryKey, 'detail', args]);
    },
    lists(...args) {
      console.warn('queryKeyFactory.lists is deprecated, use queryKeys.list instead');
      return queryKeys.list(args);
    },
    details(...args) {
      console.warn('queryKeyFactory.details is deprecated, use queryKeys.detail instead');
      return queryKeys.detail(args);
    },
  };

  return queryKeys;
}

export default queryKeyFactory;
