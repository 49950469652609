import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { sm, xl } from 'src/config/layout';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import {
  HeaderField,
  HeaderFieldLabel as Label,
  HeaderFieldValue as Value,
  UserFundIndicator,
  UserFundBenchmarkIndicator,
} from 'src/components';
import FundBookmarkControl from '../FundBookmarkControl';

const useFundBookmarkControls = FundBookmarkControl.useControls;

const Layout = styled.div`
  display: flex;
`;

const Content = styled.div``;
const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const TitleSection = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 400;
  line-height: 1.3;
`;

const MetadataSection = styled.div`
  display: flex;
  margin-top: 7px;
  flex-wrap: wrap;
`;

const Field = styled(HeaderField)`
  margin-right: 12px;
  margin-top: 5px;

  @media (min-width: ${sm.BREAKPOINT}px) {
    margin-right: 18px;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    margin-right: 24px;
  }
`;

const BookmarkLabel = styled.span`
  font-size: 0.8em;
  text-transform: uppercase;
`;

function FundMetadata({ fundId, title, isUserFund, isUserFundBenchmark, metaData }) {
  const { isBookmarked } = useFundBookmarkControls(fundId);
  function handleFundManagerSelect(link) {
    return function () {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundFundManagerSelect);
      navigate(link);
    };
  }

  return (
    <Layout>
      <Content>
        <TitleSection>
          <span
            css={`
              color: #ccc;
            `}
          >
            {title}
          </span>
          {isUserFund && <UserFundIndicator />}
          {isUserFundBenchmark && <UserFundBenchmarkIndicator />}
        </TitleSection>

        <MetadataSection>
          {metaData.map(({ label, value, link }) => {
            const linkProps = link
              ? {
                  onClick: handleFundManagerSelect(link),
                  style: {
                    color: '#00abd4',
                    textDecoration: 'none',
                    borderBottom: '1px solid',
                    cursor: 'pointer',
                  },
                }
              : {};
            return (
              <Field key={label}>
                <Label>{label}</Label>
                <Value {...linkProps}>{value}</Value>
              </Field>
            );
          })}
        </MetadataSection>
      </Content>
      <Actions>
        <FundBookmarkControl
          fundId={fundId}
          title={title}
          css={`
            display: flex;
            align-items: center;
            font-size: unset;
            color: inherit;
            color: ${isBookmarked && 'white'};
            .MuiSvgIcon-root {
              font-size: 2.5em;
            }
          `}
          label={props => {
            const suffix = props.isBookmarked ? 'd' : '';
            return <BookmarkLabel>Save{suffix}</BookmarkLabel>;
          }}
        />
      </Actions>
    </Layout>
  );
}

FundMetadata.defaultProps = {
  fund: {},
};

FundMetadata.propTypes = {
  title: PropTypes.string,
  isUserFund: PropTypes.bool,
  isUserFundBenchmark: PropTypes.bool,
  metaData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default FundMetadata;
