import { useReducer, useMemo } from 'react';
import { getRecentQuarter } from 'src/utils/date';
import { nanoid } from 'src/utils/nanoid';
import { validate, presence, number } from '../validations';

const BLANK_FUND = {
  financialHistory: [{ date: getRecentQuarter()().toISOString(), key: nanoid() }],
  errors: {
    name: undefined,
    fundManagerName: undefined,
    vintage: undefined,
    size: undefined,
    regionId1: undefined,
    strategyId: undefined,
    financialHistory: [
      {
        dpi: undefined,
        calledPct: undefined,
        rvpi: undefined,
        tvpi: undefined,
        irr: undefined,
      },
    ],
  },
};

/**
 *
 * FWIW, this validation stuff works, and it's kinda neat, but
 * it's way too clever for my taste—too hard to understand and
 * I fear it's going to be equally hard to maintain. I think
 * we should use https://react-hook-form.com/ in the future, and
 * migrate this over on the next opportunity.
 */
const fieldValidations = {
  name: validate(presence()),
  fundManagerName: validate(presence()),
  vintage: validate(presence(), number()),
  size: validate(presence(), number()),
  regionId1: validate(presence()),
  strategyId: validate(presence()),
  financialHistory: {
    dpi: validate(presence(), number()),
    calledPct: validate(presence(), number()),
    rvpi: validate(presence(), number()),
    tvpi: validate(presence(), number()),
    irr: validate(presence(), number()),
  },
};

function getValidator(validator) {
  if (typeof validator === 'undefined') return () => false;
  if (typeof validator === 'function') return validator;

  return rows =>
    rows.map(function (row) {
      const fields = Object.keys(validator);
      const errors = {};
      fields.forEach(field => {
        errors[field] = validator[field](row[field]) || false;
      });
      return errors;
    });
}

function formReducer(state, { type, field, value }) {
  if (type === 'reset') return value || { ...BLANK_FUND };

  const validator = getValidator(fieldValidations[field]);
  const error = validator(value) || false;
  const errors = { ...state.errors, [field]: error };
  return { ...state, [field]: value, errors };
}

function useFormController(formPresets) {
  const initialFormState = useMemo(() => {
    return {
      ...BLANK_FUND,
      ...formPresets,
      financialHistory: [...BLANK_FUND.financialHistory],
      errors: {
        ...BLANK_FUND.errors,
        ...formPresets.errors,
      },
    };
  }, [formPresets]);

  const [formState, updateForm] = useReducer(formReducer, initialFormState);

  return [formState, updateForm, fieldValidations];
}

export default useFormController;
