import React from 'react';
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { useUniqueId } from 'src/hooks';
import { select } from 'd3';
import defaultDrawFn from './defaultDrawFn';

const DEFAULT_MARGIN = {
  top: 14,
  right: 14,
  bottom: 14,
  left: 14,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Svg = styled.svg`
  width: 100%;
  height: 0;
  display: block;
  flex: 1 1 auto;
`;

function D3Canvas({
  className,
  draw,
  children,
  width: resizeWidth,
  height: resizeHeight,
  targetRef,
  minDrawHeight,
  minDrawWidth,
  margin: marginProp,
  containerProps,
  svgProps,
}) {
  const svgId = useUniqueId();

  const m = useMemo(() => ({ ...DEFAULT_MARGIN, ...marginProp }), [marginProp]);
  const width = useMemo(() => Math.floor(resizeWidth), [resizeWidth]);
  const height = useMemo(() => Math.floor(resizeHeight), [resizeHeight]);

  useEffect(() => {
    if (!svgId) return;
    if (width < minDrawWidth || height < minDrawHeight) return;

    draw({
      width,
      height,
      margin: m,
      svg: select(`#${svgId}`),
      canvas: {
        top: m.top,
        left: m.left,
        right: width - m.right,
        bottom: height - m.bottom,
        height: height - (m.top + m.bottom),
        width: width - (m.left + m.right),
      },
    });
  }, [draw, height, m, minDrawHeight, minDrawWidth, svgId, width]);

  return (
    <Container ref={targetRef} className={className} {...containerProps}>
      <Svg id={svgId} aria-label="chart" {...svgProps}>
        {children}
      </Svg>
    </Container>
  );
}

D3Canvas.defaultProps = {
  draw: defaultDrawFn,
  width: 0,
  height: 0,
  minDrawWidth: 28,
  minDrawHeight: 28,
};

D3Canvas.propTypes = {
  draw: PropTypes.func.isRequired,
  margin: PropTypes.object,
};

export default withResizeDetector(D3Canvas, {
  refreshMode: 'debounce',
  refreshRate: 350,
  trailing: true,
});
