import { round } from './round';

export const multiple =
  (precision = 1) =>
  value => {
    if (value == null) {
      return '-';
    }

    // eslint-disable-next-line no-restricted-globals
    if (!isFinite(value)) {
      return '-';
    }

    return precision ? `${round(precision)(value).toString()}x` : `${value.toString()}x`;
  };
