import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { D3Canvas } from 'src/components';
import draw from './draw';

const xAxisStyles = css`
  .x-axis {
    color: #666;
    font-size: 1em;

    .domain {
      display: none;
    }

    .tick text {
      font-size: 1em;
      text-anchor: middle;
      color: #ccc;
      transform: translateY(0.5em);
    }
  }

  .subject-regression-label text {
    text-anchor: end;
    transform: rotate(-90deg);
    transform-origin: inherit;
  }
`;

const yAxisStyles = css`
  .y-axis {
    color: #666;
    font-size: 1em;

    .domain {
      display: none;
    }
    .tick line {
      stroke: currentColor;
      stroke-dasharray: 0.2em;
      opacity: 0.5;
    }
    .tick text {
      font-size: 1em;
      text-anchor: start;
      color: #ccc;
      transform: translate(0, -0.5em);
    }
  }
`;

const smallXAxisLabelsCss = css`
  .x-axis {
    .tick text {
      font-size: 0.75em;
    }
  }
`;

const smallYAxisLabelsCss = css`
  .y-axis {
    .tick text {
      font-size: 0.75em;
    }
  }
`;

const hideXAxisLabelsCss = css`
  .x-axis {
    .tick {
      display: none;
    }
  }
`;

const hideYAxisLabelsCss = css`
  .y-axis {
    .tick text {
      display: none;
    }
  }
`;

const hideMilestoneMarkersCss = css`
  .breakeven,
  .breakeven-label {
    display: none;
  }
`;

const SvgCanvas = styled(D3Canvas)`
  font-size: 1em;
  position: relative;

  ${xAxisStyles}
  ${yAxisStyles}

  ${p => !p.$showXAxisLabels && hideXAxisLabelsCss};
  ${p => !p.$showYAxisLabels && hideYAxisLabelsCss};
  ${p => !p.$showMilestoneMarkers && hideMilestoneMarkersCss};

  ${p => p.$smallXAxisLabels && smallXAxisLabelsCss};
  ${p => p.$smallYAxisLabels && smallYAxisLabelsCss};

  .unavailable-message {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #888;
    position: absolute;
    top: 0;
    right: 0;

    p {
      max-width: 15em;
      margin: 0.5em;
    }
  }
`;

function Chart({
  data,
  colors,
  margin,
  smallXAxisLabels,
  smallYAxisLabels,
  showXAxisLabels,
  showYAxisLabels,
  showRelativeYears,
  showMilestoneMarkers,
  className,
  navTarget,
  xAxisTicks,
  xAxisFormatter,
  yAxisFormatter,
  normalizeYAxisData,
  startDate,
}) {
  if (!data) return null;

  return (
    <SvgCanvas
      className={className}
      margin={margin}
      draw={draw({
        data,
        colors,
        navTarget,
        yAxisFormatter,
        xAxisFormatter,
        xAxisTicks,
        normalizeYAxisData,
        showXAxisLabels,
        showYAxisLabels,
        showRelativeYears,
        startDate,
      })}
      $showMilestoneMarkers={showMilestoneMarkers}
      $showXAxisLabels={showXAxisLabels}
      $showYAxisLabels={showYAxisLabels}
      $smallXAxisLabels={smallXAxisLabels}
      $smallYAxisLabels={smallYAxisLabels}
    />
  );
}

Chart.defaultProps = {
  showBreakevenLabel: false,
  showRelativeYears: false,
  showXAxisLabels: true,
  showYAxisLabels: true,
  smallXAxisLabels: false,
  smallYAxisLabels: false,
  data: [],
};

Chart.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.object,
  smallXAxisLabels: PropTypes.bool,
  smallYAxisLabels: PropTypes.bool,
  showXAxisLabels: PropTypes.bool,
  showYAxisLabels: PropTypes.bool,
  showBreakevenLabel: PropTypes.bool,
  showRelativeYears: PropTypes.bool,
};

export default Chart;
