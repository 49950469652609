import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { Box } from 'src/components';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const MARGIN = { top: 30, right: 20, bottom: 50, left: 50 };

const Container = styled(Box)`
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'};

  .tick line {
    color: #2e2e2e;
  }

  .tick text {
    fill: #808080;
    font-size: 10px;
  }
  .x-axis-label,
  .y-axis-label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
  }
  .domain {
    opacity: 0;
  }
  .bar {
    fill: rgba(255, 255, 255, 0.06);
  }
`;

function PeerGroupBar({
  className,
  data,
  selectedPeers,
  metric,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  showOutliers,
  width,
  height,
  targetRef,
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    draw({
      id: id.current,
      data,
      selectedPeers,
      metric,
      size: { width, height },
      margin: MARGIN,
      mouseEventRefs,
      showOutliers,
    });
  }, [data, metric, selectedPeers, width, height, mouseEventRefs, showOutliers]);

  return (
    <Container ref={targetRef} className={className}>
      <svg id={id.current} style={{ width: '100%', height: '100%' }} />
    </Container>
  );
}

PeerGroupBar.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

PeerGroupBar.propTypes = {
  data: PropTypes.array.isRequired,
  selectedPeers: PropTypes.object.isRequired,
  metric: PropTypes.object.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(PeerGroupBar);
