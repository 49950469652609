import { capitalize } from './capitalize';

const titleize = capitalize(true);
const acceptCharsRegex = /[^a-zA-Z0-9 ]/g;

const indexNameMapper = {
  barclays_aggregate: 'Barclays Aggregate (AGG)',
  dow_jones_europe_real_estate: 'Dow Jones Europe Select RE (DWEURS)',
  dow_jones_infra: 'Dow Jones Diversified Infrastructure (DJBDV)',
  dow_jones_us_real_estate: 'Dow Jones U.S. Real Estate Index (DJUSRE)',
  ftse_nareit: 'FTSE NAREIT (FNER)',
  nasdaq_composite: 'NASDAQ Composite (IXIC)',
  russell_3000: 'Russell 3000 (RUA)',
  sp: 'S&P Listed PE (SPLPEQTY)',
  sp_500: 'S&P 500 (SPX)',
  sp_bmi: 'S&P BMI NA Natural Resources (SPNANRUP)',
  sp_global_infra: 'S&P Global Infrastructure Index (SPGTIND)',
  sp_global_leveraged: 'S&P Global Leverage Loan Index',
  sp_global_nr: 'S&P Global Natural Resources (SPGNRUP)',
  sp_gsci: 'S&P-GSCI Commodity Index Future',
  sp_lsta_leverage: 'S&P/LSTA U.S. Leveraged Loan 100 Index',
  wilshire_5000: 'Wilshire 5000 Total Market Index (W5000)',
};

export const indexName = value => {
  if (value == null) {
    return null;
  }

  if (value in indexNameMapper) {
    return indexNameMapper[value];
  }

  return value
    .replace(acceptCharsRegex, ' ')
    .split(' ')
    .map(text => (text.length > 2 ? titleize(text) : text.toUpperCase()))
    .join(' ');
};
