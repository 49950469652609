export const percent =
  (args = { minimumFractionDigits: 1, maximumFractionDigits: 1 }) =>
  value => {
    if (value == null) {
      return '-';
    }
    if (!Number.isFinite(value)) {
      return '-';
    }

    return Intl.NumberFormat(navigator.language, {
      style: 'percent',
      ...args,
    }).format(value);
  };
