import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '../Flexbox';
import { lookup } from '../../../lookup';

const { sentiments } = lookup;
const neutralColor = sentiments.neutral.color;

function getColor(d) {
  if (d > 0) return sentiments.positive.color;
  if (d < 0) return sentiments.negative.color;
  return neutralColor;
}

function getLabel(d) {
  if (d > 0) return sentiments.positive.label;
  if (d < 0) return sentiments.negative.label;
  return sentiments.neutral.label;
}

function NewsSentiment({ value, size, labelVisible, ...rest }) {
  return (
    <Flexbox {...rest}>
      <span
        css={`
          display: inline-block;
          border-radius: 50%;
          background-color: ${getColor(value)};
          width: ${size};
          height: ${size};
        `}
      />
      {labelVisible && (
        <span
          css={`
            color: #929090;
            font-weight: 300;
            text-transform: uppercase;
            margin-left: 5px;
          `}
        >
          {getLabel(value)}
        </span>
      )}
    </Flexbox>
  );
}

NewsSentiment.defaultProps = {
  value: null,
  size: '6px',
  labelVisible: false,
};

NewsSentiment.propTypes = {
  value: PropTypes.number,
  size: PropTypes.string,
  labelVisible: PropTypes.bool,
};

export default NewsSentiment;
