import React from 'react';
import { AppLayout, AppPanel } from 'src/components';

function Component() {
  return <div />;
}

function DevSandboxPage() {
  return (
    <AppLayout>
      <AppPanel spanX="full" spanY="full" title="Sandbox">
        <Component />
      </AppPanel>
    </AppLayout>
  );
}

export default DevSandboxPage;
