import React from 'react';
import { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import styled from 'styled-components';
import { TextField, ClickAwayListener } from '@material-ui/core';
import { navigate } from '@reach/router';
import routes from 'src/config/routes';
import { useAllFunds } from 'src/hooks';
import { MatchedList } from 'src/components';
import * as colors from 'src/config/colors';

const Layout = styled.div`
  display: block;
`;

const FundNameText = styled.span`
  color: #b1b1b1;
  color: ${p => p.$highlight && 'white'};
  font-weight: ${p => p.$highlight && 'bold'};
`;

const ViewFundLink = styled.a`
  color: #0d8bab;
  cursor: pointer;
`;

const Error = styled.div`
  color: ${colors.message.error.TEXT_COLOR};
`;

function valueStringExactMatch(value) {
  return function (match) {
    return match.value.toLowerCase().trim() === value.toLowerCase().trim();
  };
}

function CreateTargetFundFormNameField({
  label,
  value,
  onChange,
  onChangeIsValid,
  placeholder,
  validationErrorMessage,
}) {
  const allFunds = useAllFunds();

  const [hasFocus, setHasFocus] = useState(false);
  const [hasMatches, setHasMatches] = useState(false);
  const [exactMatch, setExactMatch] = useState(null);

  const showMatchList = value && hasFocus && hasMatches;

  const fieldValue = useMemo(() => {
    if (hasFocus) return value;
    return value || placeholder;
  }, [hasFocus, placeholder, value]);

  const handleMatchedFundsChange = useCallback(
    function (matches) {
      setHasMatches(matches?.length > 0);
      setExactMatch(matches?.find(valueStringExactMatch(value)));
    },
    [value]
  );

  const matchedListItems = useMemo(() => {
    if (!allFunds) return [];
    return allFunds.map(item => ({
      id: item.fundId,
      value: item.name,
    }));
  }, [allFunds]);

  const handleMatchedListRenderItem = useCallback(
    item => {
      return (
        <div key={item.id}>
          <FundNameText $highlight={item.id === exactMatch?.id}>{item.value}</FundNameText>{' '}
          <ViewFundLink
            onClick={() => {
              navigate(routes.FUND.replace(':fundId', item.id));
            }}
          >
            view »
          </ViewFundLink>
        </div>
      );
    },
    [exactMatch]
  );

  const handleMatchedListMatchItem = useCallback(item => {
    return item.name.toLowerCase();
  }, []);

  const handleTextFieldOnFocus = useCallback(() => {
    setHasFocus(true);
  }, [setHasFocus]);

  const handleClickAway = useCallback(() => {
    setHasFocus(false);
  }, [setHasFocus]);

  useEffect(() => {
    onChangeIsValid(!exactMatch);
  }, [exactMatch, onChangeIsValid]);

  const labelWithError = validationErrorMessage ? 'Invalid Fund Name' : label;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Layout>
        <TextField
          css={`
            min-width: 100%;
          `}
          label={labelWithError}
          value={fieldValue}
          onChange={onChange}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          onFocus={handleTextFieldOnFocus}
        />

        <div
          css={`
            position: absolute;
            background: #3e3e3e;
            z-index: 1;
            padding: 0.75em 1em;
            display: ${showMatchList ? 'block' : 'none'};
          `}
        >
          <MatchedList
            css={`
              overflow-y: auto;
              padding: 5px 0;
            `}
            items={matchedListItems}
            query={value}
            onChange={handleMatchedFundsChange}
            renderItem={handleMatchedListRenderItem}
            matchItem={handleMatchedListMatchItem}
            matchLimit={9}
          />

          {exactMatch && <Error>{validationErrorMessage}</Error>}
        </div>
      </Layout>
    </ClickAwayListener>
  );
}

CreateTargetFundFormNameField.defaultProps = {
  label: 'Fund Name',
  onChangeIsValid: funcStub('onChangeIsValid'),
};

CreateTargetFundFormNameField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onChangeIsValid: PropTypes.func,
};

export default React.memo(CreateTargetFundFormNameField);
