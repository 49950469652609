const DARK_YELLOW = '#e1b72a';
const YELLOW = '#ffd548';
const ORANGE = '#ff7701';
const BRIGHT_YELLOW = '#FFCF30';
const BLUE = '#108bab';
const BRIGHT_BLUE = '#40d8ff';
// const GREEN = '#007333';
const BRIGHT_GREEN = '#39BF75';
const RED = '#CC294C';
const BRIGHT_RED = '#FF4D74';

const DARKEST_NEUTRAL = '#000000';
const LIGHTEST_NEUTRAL = '#ffffff';

const layout = {
  body: {
    TEXT_COLOR: '#838383',
    // This is hsb(0,0,20)
    BACKGROUND_COLOR: '#323232',
  },

  primaryNav: {
    TEXT_COLOR: '#9e9e9e',
    // This is hsb(0,0,24)
    BACKGROUND_COLOR: '#3e3e3e',
  },

  secondaryNav: {
    TEXT_COLOR: '#838383',
    BACKGROUND_COLOR: 'inherit',
  },

  newsTicker: {
    TEXT_COLOR: '#7c7c7c',
    BACKGROUND_COLOR: DARKEST_NEUTRAL,
  },

  contentArea: {
    TEXT_COLOR: '#838383',
    BACKGROUND_COLOR: null,
  },
};

const panel = {
  header: {
    TEXT_COLOR: '#787878',
    BACKGROUND_COLOR: DARKEST_NEUTRAL,
  },
  body: {
    TEXT_COLOR: '#787878',
    BACKGROUND_COLOR: '#141414',
  },
};

const table = {
  header: {
    TEXT_COLOR: '#787878',
    BACKGROUND_COLOR: null,
  },

  row: {
    TEXT_COLOR: '#787878',
    BACKGROUND_COLOR: null,

    active: {
      TEXT_COLOR: DARKEST_NEUTRAL,
      BACKGROUND_COLOR: YELLOW,
    },
  },
};

const metadata = {
  label: {
    TEXT_COLOR: '#787878',
    BACKGROUND_COLOR: null,
  },

  data: {
    TEXT_COLOR: LIGHTEST_NEUTRAL,
    BACKGROUND_COLOR: null,
  },
};

const link = {
  default: {
    TEXT_COLOR: BLUE,
    BACKGROUND_COLOR: null,

    active: {
      TEXT_COLOR: BRIGHT_BLUE,
      BACKGROUND_COLOR: null,
    },
  },

  accent: {
    TEXT_COLOR: YELLOW,
    BACKGROUND_COLOR: null,

    active: {
      TEXT_COLOR: BRIGHT_YELLOW,
      BACKGROUND_COLOR: null,
    },
  },

  neutral: {
    TEXT_COLOR: '#e1e1e1',
    BACKGROUND_COLOR: null,

    active: {
      TEXT_COLOR: LIGHTEST_NEUTRAL,
      BACKGROUND_COLOR: null,
    },
  },
};

const button = {
  default: {
    TEXT_COLOR: LIGHTEST_NEUTRAL,
    BACKGROUND_COLOR: BLUE,

    active: {
      TEXT_COLOR: LIGHTEST_NEUTRAL,
      BACKGROUND_COLOR: BRIGHT_BLUE,
    },
  },

  accent: {
    TEXT_COLOR: DARKEST_NEUTRAL,
    BACKGROUND_COLOR: DARK_YELLOW,

    active: {
      TEXT_COLOR: DARKEST_NEUTRAL,
      BACKGROUND_COLOR: YELLOW,
    },
  },

  danger: {
    TEXT_COLOR: '#e1e1e1',
    BACKGROUND_COLOR: RED,

    active: {
      TEXT_COLOR: LIGHTEST_NEUTRAL,
      BACKGROUND_COLOR: BRIGHT_RED,
    },
  },

  neutral: {
    TEXT_COLOR: '#e1e1e1',
    BACKGROUND_COLOR: null,

    active: {
      TEXT_COLOR: LIGHTEST_NEUTRAL,
      BACKGROUND_COLOR: 'rgba(255,255,255,0.15)',
    },
  },

  disabled: {
    TEXT_COLOR: 'rgba(255,255,255,0.15)',
    BACKGROUND_COLOR: 'rgba(255,255,255,0.15)',

    active: {},
  },
};

const modal = {
  body: {
    TEXT_COLOR: '#a4a4a4',
    BACKGROUND_COLOR: '#323232',
  },

  header: {
    TEXT_COLOR: '#878787',
    BACKGROUND_COLOR: 'rgba(255, 255, 255, 0.06)',
  },

  backdrop: {
    TEXT_COLOR: null,
    BACKGROUND_COLOR: 'rgba(0, 0, 0, 0.5)',
  },
};

const form = {
  input: {
    TEXT_COLOR: null,
    BACKGROUND_COLOR: 'transparent',
  },
};

const message = {
  default: {
    TEXT_COLOR: '#838383',
    BACKGROUND_COLOR: null,
  },

  info: {
    TEXT_COLOR: BRIGHT_BLUE,
    BACKGROUND_COLOR: null,
  },

  accent: {
    TEXT_COLOR: YELLOW,
    BACKGROUND_COLOR: null,
  },

  success: {
    TEXT_COLOR: BRIGHT_GREEN,
    BACKGROUND_COLOR: null,
  },

  warn: {
    TEXT_COLOR: YELLOW,
    BACKGROUND_COLOR: null,
  },

  error: {
    TEXT_COLOR: ORANGE,
    BACKGROUND_COLOR: null,
  },
};

const text = message;

const card = {
  default: {
    TEXT_COLOR: '#838383',
    BACKGROUND_COLOR: '#3e3e3e',
  },

  selected: {
    TEXT_COLOR: '#fff',
    BACKGROUND_COLOR: BLUE,
  },
};

export { layout, panel, table, metadata, link, button, modal, form, message, text, card, BLUE, BRIGHT_BLUE };
