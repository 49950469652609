import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledToggleButton = styled(ToggleButton)`
  height: 30px;
`;

function ViewToggle({ value, options, onChange }) {
  return (
    <Container>
      <ToggleButtonGroup size="small" value={value} exclusive onChange={(_, v) => onChange(v)}>
        {options.map(option => {
          return (
            <StyledToggleButton key={option} value={option}>
              {option}
            </StyledToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Container>
  );
}

ViewToggle.defaultProps = {
  options: [],
};

ViewToggle.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
};

export default ViewToggle;
