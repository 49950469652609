import { select, hierarchy, linkRadial, scaleLinear, extent, cluster as d3Cluster, easeCubicInOut } from 'd3';

const transitionDuration = 500;

function getNodes({ id }) {
  const svg = select(`#${id}`);
  if (svg.select('.rootg').node()) {
    return [svg.select('.rootg'), svg.select('.linksg'), svg.select('.nodesg'), svg.select('.textsg')];
  }
  const root = svg.append('g').attr('class', 'rootg');
  return [
    root,
    root.append('g').attr('class', 'linksg'),
    root.append('g').attr('class', 'nodesg'),
    root.append('g').attr('class', 'textsg'),
  ];
}

function draw({ id, data, size, margin, expanded, handleItemClick }) {
  //#region layout functions
  function getNodeRadius(d) {
    const { depth } = d;
    const { count } = d.data;

    if (depth === 0) {
      return 75;
    }
    if (depth === 1) {
      return d.data.isSelected ? 4 : nodeRadius(count);
    }
    return 4;
  }

  function getLinkKey(d) {
    return `${d.source.data.id}${d.target ? `-${d.target.data.id}` : ''}`;
  }

  function getNodeKey(d) {
    return d.data.id;
  }

  function getNodePosition(d) {
    if (d.depth === 0) return null;
    return `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`;
  }

  function getLinkStroke(d) {
    if (d.target.data.isSelected) return '#0D8BAB';
    return expanded ? '#303030' : 'transparent';
  }

  function getNodeStroke(d) {
    const { isSelected, type } = d.data;

    if (type === 'employee') {
      if (isSelected) {
        return '#0D8BAB';
      }
      return '#545454';
    }
    if (d.depth === 1 && !isSelected) {
      return '#333';
    }
    return null;
  }

  function getNodeFill(d) {
    const { type } = d.data;
    if (type === 'employee') return 'none';
    return d.data.isSelected ? '#0D8BAB' : '#545454';
  }

  function getNodeCursor(d) {
    if (d.depth > 0) return 'pointer';
    return null;
  }

  function getText(d) {
    return d.data.label;
  }

  function getTextAnchor(d) {
    if (d.depth === 0) return 'middle';
    return d.x < Math.PI ? 'start' : 'end';
  }

  function getTextTransform(d) {
    const { depth, x } = d;
    if (depth === 0) return null;
    return x >= Math.PI ? 'rotate(180)' : null;
  }

  // the distance out
  function getTextX(d) {
    const { x, depth } = d;
    const { isSelected } = d.data;

    if (depth === 0) {
      return null;
    }
    if (depth === 1) {
      if (!isSelected) {
        const offset = nodeRadius(d.data.count) + 10;
        return x < Math.PI ? `${offset}px` : `-${offset}px`;
      }
      return null;
    }
    return x < Math.PI ? '1em' : '-1em';
  }

  // the distance above or below
  function getTextY(d) {
    const { isSelected } = d.data;

    if (d.depth === 0) return '0.5em';
    if (d.depth === 1) {
      if (isSelected) return '1.5em';
      return '.35em';
    }
    if (d.depth === 2) return '.35em';
    return '1.5em';
  }

  function getTextFill(d) {
    const { isSelected } = d.data;

    if (d.depth === 0) return '#000';
    if (!expanded) return 'transparent';
    if (isSelected) return '#0D8BAB';
    return '#666';
  }

  function getTextFontSize(d) {
    const { depth } = d;

    if (depth === 0) return '12px';
    if (!expanded) return '6px';
    if (depth === 1) return '12px';
    return '11px';
  }

  function getTextFontWeight(d) {
    const { isSelected } = d.data;

    if (d.depth === 1 && isSelected) return '500';
    return '400';
  }

  function getCountTextFill(d) {
    const { isSelected } = d.data;

    if (d.depth === 1 && !isSelected) return '#000';
    return 'transparent';
  }

  //#endregion

  //#region svg setup
  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;
  const maxLabelLength = 150;
  const radius = Math.min(svgHeight, svgWidth) / 2 - (expanded ? maxLabelLength : 0);
  const [rootg, linksg, nodesg, textsg] = getNodes({ id });

  const t = rootg.transition().duration(transitionDuration).ease(easeCubicInOut);

  rootg.attr(
    'transform',
    `translate(${radius + (svgWidth / 2 - radius) + margin.left},${radius + (svgHeight / 2 - radius) + margin.top})`
  );
  //#endregion

  const nodeRadius = scaleLinear()
    .domain(extent(data.children.map(c => c.count)))
    .range([10, expanded ? 30 : 20]);

  // Create the cluster layout
  const tree = d3Cluster().size([2 * Math.PI, radius]);

  // Give the data to this cluster layout

  const root = tree(hierarchy(data) /* .sort((a, b) => ascending(a.data.label, b.data.label)) */);

  // Features of the links between nodes
  const linksGenerator = linkRadial()
    .angle(d => d.x)
    .radius(d => d.y);

  //#region add the links between nodes
  const links = linksg.selectAll('path').data(root.links(), getLinkKey);

  links.exit().attr('opacity', 0).transition(t).remove();

  links.transition(t).attr('opacity', 1).attr('d', linksGenerator).attr('stroke', getLinkStroke);

  links
    .enter()
    .append('path')
    .style('fill', 'none')
    .style('pointer-events', 'none')
    .attr('opacity', 0)
    .attr('stroke', getLinkStroke)
    .transition(t)
    .attr('opacity', 1)
    .attr('d', linksGenerator);
  //#endregion

  //#region add a circle for each node
  const nodes = nodesg.selectAll('circle').data(root.descendants(), getNodeKey);

  nodes.exit().attr('opacity', 0).transition(t).remove();

  nodes
    .on('click', handleItemClick)
    .transition(t)
    .attr('opacity', 1)
    .attr('transform', getNodePosition)
    .attr('stroke', getNodeStroke)
    .style('fill', getNodeFill)
    .style('cursor', getNodeCursor)
    .attr('r', getNodeRadius);

  nodes
    .enter()
    .append('circle')
    .attr('opacity', 0)
    .style('stroke-width', 0.5)
    .style('cursor', getNodeCursor)
    .on('click', handleItemClick)
    .transition(t)
    .attr('r', getNodeRadius)
    .style('fill', getNodeFill)
    .attr('stroke', getNodeStroke)
    .attr('transform', getNodePosition)
    .attr('opacity', 1);
  //#endregion

  //#region add text blocks
  const texts = textsg.selectAll('.text').data(root.descendants(), getNodeKey);

  texts.exit().attr('opacity', 0).transition(t).remove();

  texts
    .transition(t)
    .attr('font-size', getTextFontSize)
    .attr('font-weight', getTextFontWeight)
    .attr('transform', getNodePosition)
    .attr('fill', getTextFill)
    .select('text')
    .attr('text-anchor', getTextAnchor)
    .attr('transform', getTextTransform)
    .attr('opacity', 1)
    .attr('y', getTextY)
    .attr('x', getTextX)
    .text(getText);

  texts
    .enter()
    .append('g')
    .attr('transform', getNodePosition)
    .attr('font-size', getTextFontSize)
    .attr('font-weight', getTextFontWeight)
    .style('cursor', getNodeCursor)
    .on('click', handleItemClick)
    .attr('fill', getTextFill)
    .attr('class', 'text')
    .append('text')
    .attr('y', getTextY)
    .attr('x', getTextX)
    .attr('text-anchor', getTextAnchor)
    .attr('transform', getTextTransform)
    .text(getText)
    .attr('opacity', 0)
    .transition(t)
    .attr('opacity', 1);

  const countTexts = textsg.selectAll('.count-text').data(root.descendants(), getNodeKey);

  countTexts.exit().attr('opacity', 0).transition(t).remove();

  countTexts
    .transition(t)
    .attr('transform', getNodePosition)
    .attr('fill', getCountTextFill)
    .select('text')
    .attr('opacity', expanded ? 1 : 0)
    .attr('transform', getTextTransform)
    .text(d => d.data.count || '');

  countTexts
    .enter()
    .append('g')
    .attr('font-size', '12px')
    .attr('transform', getNodePosition)
    .attr('pointer-events', 'none')
    .attr('fill', getCountTextFill)
    .attr('class', 'count-text')
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('transform', getTextTransform)
    .text(d => d.data.count || '')
    .attr('opacity', 0)
    .transition(t)
    .attr('opacity', expanded ? 1 : 0);
  //#endregion
}

export default draw;
