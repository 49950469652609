import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import { Box } from 'src/components';
import draw from './draw';

const MARGIN = { top: 30, right: 20, bottom: 50, left: 60 };

const Container = styled(Box)`
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'};
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
`;

function PeerGroupScatter({
  className,
  fund,
  data,
  metric,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  targetRef,
  width,
  height,
  showOutliers,
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    if (!data) return;

    draw({
      id: id.current,
      fund,
      data,
      metric,
      size: { width, height },
      margin: MARGIN,
      mouseEventRefs,
      showOutliers,
    });
  }, [data, fund, metric, width, height, mouseEventRefs, showOutliers]);

  return (
    <Container ref={targetRef} className={className}>
      <Svg id={id.current} />
    </Container>
  );
}

PeerGroupScatter.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

PeerGroupScatter.propTypes = {
  fund: PropTypes.object,
  data: PropTypes.array.isRequired,
  metric: PropTypes.object.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(PeerGroupScatter);
