import React from 'react';
import { useMemo, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { useMouseEventRefs } from 'src/hooks';
import stackValues from 'src/adapters/stackValues';
import useChart from './useChart';

const axisSyles = css`
  .tick line {
    color: #2e2e2e;
  }

  .title text {
    fill: #808080;
    font-size: 18px;
  }

  .tick text {
    fill: #808080;
    font-size: 10px;
  }

  .x-axis-label,
  .y-axis-label {
    font-size: 12px;
    text-transform: uppercase;
  }

  .bar {
    cursor: pointer;
    transition: opacity 300ms ease;
  }

  .domain {
    opacity: 0;
  }
`;

const rotate45 = css`
  transform: translate(-0.5em, 0.5em) rotate(-45deg);
`;

const Layout = styled.div`
  min-height: 200px;
  position: relative;

  & > svg {
    ${axisSyles};
    .x-axis .tick text {
      ${p => p.$narrow && rotate45};
    }
  }
`;

function StackedBarChart({
  className,
  data,
  width,
  height,
  targetRef,
  onItemMouseOver,
  onItemMouseOut,
  colorScheme,
  onDraw,
  yAxisLabel,
  xAxisLabel,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
}) {
  const stackedData = useMemo(() => {
    return stackValues([...data].sort((a, b) => b.y - a.y).sort((a, b) => a.x - b.x));
  }, [data]);

  const mouseEventRefs = useMouseEventRefs({
    onItemOver: onItemMouseOver,
    onItemOut: onItemMouseOut,
    onItemClick: onItemMouseOver,
    onItemTouchStart: onItemMouseOver,
    onItemTouchEnd: onItemMouseOver,
  });

  const margin = useMemo(() => {
    return {
      top: marginTop,
      right: marginRight,
      bottom: marginBottom,
      left: marginLeft,
    };
  }, [marginLeft, marginRight, marginTop, marginBottom]);

  const { draw, legendData } = useChart({
    targetRef,
    data: stackedData,
    colorScheme,
    mouseEventRefs,
    width,
    height,
    yAxisLabel,
    xAxisLabel,
    margin,
  });

  useLayoutEffect(() => {
    draw();
  });

  useEffect(() => {
    onDraw({ legendData });
  }, [legendData, onDraw]);

  return <Layout ref={targetRef} className={className} $narrow={width < 800} />;
}

StackedBarChart.defaultProps = {
  data: [
    {
      x: 2008,
      y: 50e9,
      yStart: 0,
      yEnd: 0,
      z: 'placeholder',
      zLabel: 'Loading...',
    },
    {
      x: new Date().getFullYear() - 1,
      y: 50e9,
      yStart: 0,
      yEnd: 0,
      z: 'placeholder',
      zLabel: 'Loading...',
    },
  ],
  colorScheme: ['#02ccff', '#0f3a45'],
  onItemMouseOver: () => {},
  onItemMouseOut: () => {},
  onItemClick: () => {},
  onDraw: () => {},
  marginLeft: 60,
  marginRight: 20,
  marginTop: 20,
  marginBottom: 30,
};

StackedBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      z: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      zLabel: PropTypes.string.isRequired,
    })
  ),
  expanded: PropTypes.bool,
  onItemMouseOver: PropTypes.func,
  onItemMouseOut: PropTypes.func,
  colorScheme: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

const MemoizedStackedBarChart = React.memo(StackedBarChart);

export default withResizeDetector(MemoizedStackedBarChart);
