import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonRounded, IconSortNumericAsc, IconSortNumericDesc } from 'src/components';

const StyledSortButton = styled(ButtonRounded)`
  min-width: unset;
  margin-right: ${({ theme }) => `${theme.spacing(0.5)}px`};
`;
const ButtonIcon = styled.span`
  padding: 2px;
`;

function SortButton({ sort, onToggleSort }) {
  return (
    <StyledSortButton aria-label="Sort" onClick={onToggleSort} selected={sort !== 'none'}>
      <ButtonIcon as={sort === 'desc' ? IconSortNumericDesc : IconSortNumericAsc} />
    </StyledSortButton>
  );
}

SortButton.defaultProps = {
  sort: 'none',
  onToggleSort: () => {},
};

SortButton.propTypes = {
  sort: PropTypes.string,
  onToggleSort: PropTypes.func,
};

export { SortButton };
