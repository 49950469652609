import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { Box } from 'src/components';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const MARGIN = { top: 30, right: 0, bottom: 0, left: 0 };

const Container = styled(Box)`
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'};
`;

function PeerGroupRadar({
  className,
  data,
  metrics,
  showMedians,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    if (!data) return;

    draw({
      id: id.current,
      data,
      metrics,
      showMedians,
      size: { width, height },
      margin: MARGIN,
      mouseEventRefs,
    });
  }, [data, showMedians, metrics, width, height, mouseEventRefs]);

  return (
    <Container className={className} ref={targetRef}>
      <svg id={id.current} style={{ width: '100%', height: '100%', display: 'block' }} />
    </Container>
  );
}

PeerGroupRadar.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

PeerGroupRadar.propTypes = {
  data: PropTypes.array.isRequired,
  metrics: PropTypes.array.isRequired,
  showMedians: PropTypes.bool.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(PeerGroupRadar, {
  refreshMode: 'debounce',
  refreshRate: 350,
  refreshOptions: {
    trailing: true,
  },
});
