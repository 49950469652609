import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconDownload(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M1 24c-.129 0-.259-.024-.383-.076-.373-.154-.617-.52-.617-.924v-7c0-.553.448-1 1-1s1 .447 1 1v4.586l7.293-7.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-9 9c-.191.191-.447.293-.707.293zM8 24h-7c-.552 0-1-.447-1-1s.448-1 1-1h7c.552 0 1 .447 1 1s-.448 1-1 1zM14 11c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l9-9c.286-.287.715-.371 1.09-.217.374.155.617.52.617.924v7c0 .552-.447 1-1 1s-1-.448-1-1v-4.586l-7.293 7.293c-.195.195-.451.293-.707.293zM23 2h-7c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z" />
    </Svg>
  );
}
