import React from 'react';
import PropTypes from 'prop-types';
import { AppPanel, MarketSizeBreakdownVisualization } from 'src/components';

function MarketFilterMarketSizePanel({ className, spanX, spanY, minHeight }) {
  return (
    <AppPanel
      className={className}
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title="Strategy Breakdown"
      expandable
    >
      <MarketSizeBreakdownVisualization />
    </AppPanel>
  );
}

MarketFilterMarketSizePanel.defaultProps = {};

MarketFilterMarketSizePanel.propTypes = {
  children: PropTypes.any,
};

export default MarketFilterMarketSizePanel;
