import React from 'react';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import {
  TooltipContent,
  TooltipCloseIcon,
  TooltipHeader,
  TooltipRow,
  TooltipTitle,
  TooltipData,
  TooltipArrowUp,
  TooltipLink,
} from '../..';

function FundManagerAddressTooltip({ location, open, anchorEl, onClickAway, onClose }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: '.manager-address-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <div
              css={`
                max-width: 300px;
              `}
            >
              <TooltipArrowUp className="manager-address-arrow" marginLeft="2px" />
              <TooltipContent>
                <TooltipHeader>
                  <TooltipTitle>LOCATION</TooltipTitle>
                  <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
                </TooltipHeader>
                <TooltipRow>
                  <TooltipData
                    css={`
                      margin-left: 0;
                    `}
                  >
                    {location.address}
                  </TooltipData>
                </TooltipRow>
                <TooltipRow marginTop="10px">
                  <TooltipLink
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.address)}`}
                    target="_blank"
                  >
                    View on map
                  </TooltipLink>
                </TooltipRow>
              </TooltipContent>
            </div>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
FundManagerAddressTooltip.defaultProps = {
  location: null,
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewFund: () => {},
};

FundManagerAddressTooltip.propTypes = {
  location: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
  onViewFund: PropTypes.func,
};

export default FundManagerAddressTooltip;
