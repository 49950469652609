import { useState, useEffect } from 'react';
import useStrategies from './useStrategies';

function useSubStrategies() {
  const [subStrategies, setSubStrategies] = useState(null);
  const strategies = useStrategies();

  useEffect(() => {
    if (strategies) {
      setSubStrategies(strategies.filter(s => s.parentId));
    }
  }, [strategies]);

  return subStrategies;
}

export default useSubStrategies;
