import { createContext } from 'react';

const Context = createContext({
  panelId: null,
  isExpanded: false,
  isCollapsed: false,
  isCapturingPreview: false,
  setTitle: () => undefined,
  setControls: () => undefined,
});

Context.displayName = 'AppPanelContext';

export default Context;
