import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  font-size: inherit;

  width: 1.25em;
  width: ${props => props.width};

  height: 1.25em;
  height: ${props => props.height};
`;

export default function IconCheck(props) {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="44"
        d="M416 128L192 384l-96-96"
      />
    </Svg>
  );
}
