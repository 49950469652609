import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const MARGIN = { top: 24, right: 0, bottom: 0, left: 0 };

const Container = styled.div`
  height: 100%;
  width: 100%;

  svg {
    display: block;

    text {
      pointer-events: none;
    }
    .grandparent rect {
      stroke: none;
    }
    .click-target {
      cursor: pointer;
    }
    text.title {
      fill: #fff;
      font-size: 16px;
      opacity: 0.8;
    }
    text.back {
      fill: #0D8BAB;
      font-size: 12px;
    }
    rect {
      stroke: #141414;
    }
    rect.parent {
      stroke-width: 2px;
      cursor: pointer;
    }
    .ptext {
      fill: #fff;
      fill-opacity: 0.85
      font-size: 12px;
    }
    rect.child {
      stroke: #ffffff33;
      stroke-width: 1px;
    }

    .children rect.parent {
      cursor: zoom-in;
    }
    .children:hover rect.child {
      fill-opacity: 0.5;
      stroke: #61b3c8;
    }
  }
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

function MarketSizeBreakdown({
  data,
  colors,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  ...rest
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    if (width <= 0 || height <= 0) return;

    draw({
      id: id.current,
      data,
      width,
      height,
      margin: MARGIN,
      colors,
      mouseEventRefs,
    });
  }, [data, width, height, colors, mouseEventRefs]);

  return (
    <Container ref={targetRef} {...rest}>
      <Svg id={id.current} />
    </Container>
  );
}

MarketSizeBreakdown.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

MarketSizeBreakdown.propTypes = {
  data: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  colors: PropTypes.array.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  targetRef: PropTypes.object.isRequired,
};

export default withResizeDetector(MarketSizeBreakdown, {
  refreshMode: 'debounce',
  refreshRate: 100,
});
