import * as d3 from 'd3';

function getCanvas({ svg, margin }) {
  if (!svg.node()) return {};

  const svgBox = svg.node().getBoundingClientRect();

  const reference = svg
    .selectAll('.reference')
    .data([0])
    .join('rect')
    .attr('class', 'reference')
    .style('fill', '#ffffff11')
    .style('stroke', '#cccccc33')
    .style('transform', `translate(${margin.left}, ${margin.top}`)
    .attr('width', `calc(${svgBox.width}px - ${margin.left} - ${margin.right})`)
    .attr('height', `calc(${svgBox.height}px - ${margin.top} - ${margin.bottom})`);

  const box = reference.node().getBoundingClientRect();

  const top = box.top - svgBox.top;
  const left = box.left - svgBox.left;
  const right = left + box.width;
  const bottom = top + box.height;

  reference.remove();

  return {
    width: box.width,
    height: box.height,
    top,
    left,
    right,
    bottom,
  };
}

function drawDiversificationChart({ data }) {
  return function ({ svg, margin }) {
    if (!data) return;

    const canvas = getCanvas({ svg, margin });

    const arcs = d3
      .pie()
      .sort(null)
      .value(d => d.value)(data);

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(Math.min(canvas.width, canvas.height) / 3);

    svg.style('font-size', `0.8em`);

    const root = svg
      .selectAll('.root')
      .data([arcs])
      .join('g')
      .attr('class', 'root')
      .style('transform', `translate(${canvas.width * (2 / 3) + canvas.left}px, ${canvas.height / 2 + canvas.top}px)`);

    root
      .selectAll('path')
      .data(arcs)
      .join('path')
      .attr('fill', d => `#${d.data.colorHex}`)
      .attr('d', arc);

    svg
      .selectAll('rect')
      .data(arcs)
      .join('rect')
      .attr('fill', d => `#${d.data.colorHex}`)
      .attr('width', '1.5em')
      .attr('height', '1.5em')
      .attr('x', canvas.left)
      .attr('y', (_, i) => {
        const count = arcs.length;
        const spread = count * 24;
        const center = canvas.height / 2 + canvas.top;
        return center - spread / 2 + 24 * i;
      });

    svg
      .selectAll('text')
      .data(arcs)
      .join('text')
      .attr('fill', 'currentColor')
      .style('text-anchor', 'start')
      .style('transform', 'translate(0.5em, 1.25em)')
      .style('font-size', '1em')
      .attr('x', canvas.left + 15)
      .attr('y', (_, i) => {
        const count = arcs.length;
        const spread = count * 24;
        const center = canvas.height / 2 + canvas.top;
        return center - spread / 2 + 24 * i;
      })
      .text(d => d.data.name);
  };
}

export default drawDiversificationChart;
