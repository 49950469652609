import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { isString, isDate, isType } from 'src/utils/types';
// import { parseISO } from 'src/utils/date';
import { getFundCashflowForecast } from 'src/services/filter-api';

const QUERY_OPTS = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  staleTime: 1000 * 60 * 60,
};

function queryFn({ fundId, date }) {
  return function () {
    isString(fundId, { required: true });
    isType('String | Date', { required: true })(date);
    if (isDate(date)) date = date.toISOString();

    return getFundCashflowForecast({ fundId, date, debug: true });
  };
}

const DEFAULTS = {
  truncate: 5 * 4,
  highlight: false,
};

function useFundForecastQuery(fundId, date, options) {
  const { truncate, highlight } = { ...DEFAULTS, options };

  const query = useQuery(['fundForecast', fundId, date], queryFn({ fundId, date }), {
    ...QUERY_OPTS,
    enabled: Boolean(fundId),
  });

  return useMemo(() => {
    if (!query.data) return query;
    if (truncate === false) return query;

    const { forecastStart, history } = query.data;
    const start = forecastStart;
    const end = start + truncate;

    try {
      const preppedHistory = history
        .filter(d => {
          return d.q >= start && d.q < end;
        })
        .map(d => {
          return {
            ...d,
            highlight: highlight ? d.q >= highlight[0] && d.q <= highlight[1] : d.q >= start && d.q <= start + 12,
          };
        });

      return {
        ...query,
        data: {
          ...query.data,
          history: preppedHistory,
        },
      };
    } catch (error) {
      console.error({ fundId, date, start, end, history });
      console.error(error);
      return {
        ...query,
        data: {
          ...query.data,
          history,
        },
      };
    }
  }, [date, fundId, highlight, query, truncate]);
}

export default useFundForecastQuery;
