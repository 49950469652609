import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconList(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <g>
        <circle cx="19.6" cy="26.1" r="7" />
        <path d="M87.6,26.1c0,2.6-2.1,4.7-4.7,4.7H40.7c-2.6,0-4.7-2.1-4.7-4.7l0,0c0-2.6,2.1-4.7,4.7-4.7h42.2  C85.5,21.5,87.6,23.6,87.6,26.1L87.6,26.1z" />
        <circle cx="19.6" cy="49.6" r="7" />
        <path d="M87.6,49.6c0,2.6-2.1,4.7-4.7,4.7H40.7c-2.6,0-4.7-2.1-4.7-4.7l0,0c0-2.6,2.1-4.7,4.7-4.7h42.2C85.5,44.9,87.6,47,87.6,49.6  L87.6,49.6z" />
        <circle cx="19.6" cy="73" r="7" />
        <path d="M87.6,73c0,2.6-2.1,4.7-4.7,4.7H40.7c-2.6,0-4.7-2.1-4.7-4.7l0,0c0-2.6,2.1-4.7,4.7-4.7h42.2C85.5,68.3,87.6,70.5,87.6,73  L87.6,73z" />
      </g>
    </Svg>
  );
}
