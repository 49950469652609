/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchStrategyPerformancePending = createAction('FETCH_STRATEGY_PERFORMANCE_PENDING');
const fetchStrategyPerformanceError = createAction('FETCH_STRATEGY_PERFORMANCE_ERROR');
const fetchStrategyPerformanceSuccess = createAction('FETCH_STRATEGY_PERFORMANCE_SUCCESS');
export const selectStrategy = createAction('STRATEGY_PERFORMANCE_SELECT_STRATEGY');

export function fetchStrategyPerformance() {
  return async function (dispatch) {
    dispatch(fetchStrategyPerformancePending());
    try {
      const strategyPerformance = await filterApi.getFundSubStrategyFinancialHistory();
      const strategies = await filterApi.getStrategies();
      dispatch(
        fetchStrategyPerformanceSuccess({
          strategyPerformance,
          strategies,
        })
      );
    } catch (error) {
      dispatch(fetchStrategyPerformanceError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  byId: null,
};

export default createReducer(initialState, {
  [fetchStrategyPerformancePending]: state => {
    state.fetching = true;
  },
  [fetchStrategyPerformanceError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [fetchStrategyPerformanceSuccess]: (state, action) => {
    const { strategyPerformance, strategies } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    const strategyMap = strategies
      .filter(s => s.parentId)
      .reduce((acc, strategy) => {
        acc[strategy.strategyId] = strategy;
        return acc;
      }, {});

    state.byId = strategyPerformance.reduce((acc, item) => {
      if (!acc[item.subStrategyId]) {
        const { strategy, strategyId, subStrategy, subStrategyId } = item;
        acc[subStrategyId] = {
          strategy,
          strategyId,
          subStrategy,
          subStrategyId,
          rank: strategyMap[subStrategyId].rank,
          all: [],
          topQuartile: [],
        };
      }
      acc[item.subStrategyId].all.push({ ...item.all, date: item.date });
      acc[item.subStrategyId].topQuartile.push({ ...item.topQuartile, date: item.date });
      return acc;
    }, {});
  },
});
