import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconLeftCaret(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M60,27.9c1.7,0,3,1.4,3,3c0,0.8-0.3,1.6-0.9,2.1L44.4,50l17.7,17c1.2,1.2,1.2,3.1,0,4.2    c-1.1,1.1-2.9,1.2-4.1,0.1l-20-19.1c-1.2-1.1-1.2-3-0.1-4.2c0,0,0.1-0.1,0.1-0.1l20-19.2C58.5,28.2,59.2,27.9,60,27.9z" />
    </Svg>
  );
}
