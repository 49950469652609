import { db } from 'src/config/firebase';
import { subtractYears } from 'src/utils/date';
import { pick } from 'src/utils/object';

const DEFAULT_FROM_DATE = subtractYears(1)(new Date()).toISOString();

const collection = db.collection('news');

async function list(fundManagerId) {
  if (!fundManagerId) return [];

  const query = collection
    .where('fundManagerId', '==', fundManagerId)
    .where('publishedAt', '>=', DEFAULT_FROM_DATE)
    .where('sentimentPopulated', '==', true)
    .orderBy('publishedAt', 'desc')
    .limit(250);

  const snapshot = await query.get();

  return snapshot.docs.map(doc =>
    pick(
      'articleId',
      'fundManagerId',
      'publishedAt',
      'sentiment',
      'sentimentDerivedValue',
      'source',
      'sourceHostname',
      'title',
      'url'
    )(doc.data())
  );
}

export { collection, list };
