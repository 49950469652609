import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function LogoVisa(props) {
  return (
    <Svg viewBox="0 0 280 91" {...props}>
      <path d="M135.43602,1.5305 L121.24202,89.3395 L98.57702,89.3395 L112.76302,1.5315 L112.76602,1.5305 L135.43602,1.5305 Z M36.781,1.57046 C41.6130755,1.76276189 45.5345123,3.35480973 46.9708998,8.65665215 L47.051,8.96666 L54.988,49.27546 L54.99802,49.3007 L57.36402,61.4607 L79.55802,1.6167 L103.56402,1.6167 L67.87402,89.24436 L43.87402,89.2557 L23.6490055,12.697861 C16.9715494,8.76376861 9.39126948,5.57474909 0.942342596,3.32747198 L-2.84217094e-14,3.08166 L0.21302,1.57046 L36.781,1.57046 Z M183.17302,0 C160.74402,0 144.98902,11.924 144.85002,28.974 C144.708,41.605 156.11702,48.648 164.69802,52.846 C173.55102,57.1376 176.50802,59.8955 176.47902,63.735 C176.39102,69.62 169.39492,72.3012 162.89402,72.3012 C153.83082,72.3012 148.99902,70.9777 141.57202,67.7114 L138.62722,66.3117 L135.45522,85.9627 C140.75792,88.4082 150.55022,90.527 160.73022,90.6377 C184.53122,90.6377 200.01722,78.8507 200.20122,60.6507 C200.29492,50.6327 194.23902,43.0507 181.14522,36.7907 C173.24082,32.7206 168.35322,30.0218 168.42622,25.8977 C168.43222,22.2537 172.52752,18.3483 181.41722,18.3483 C188.84162,18.22333 194.18122,19.9275 198.38522,21.7225 L200.40742,22.7314 L203.49192,3.6954 C199.01192,1.9257 191.94192,0 183.17192,0 L183.17302,0 Z M244.12802,1.62238 C238.69752,1.62238 234.62802,3.17028 232.23102,8.91048 L198.57402,89.36848 L222.38702,89.36848 C222.38702,89.36848 226.28042,78.53848 227.16092,76.16748 C229.75972,76.16748 252.88792,76.21743 256.18792,76.21743 C256.88946,79.27923 258.94862,89.36743 258.94862,89.36743 L280.00362,89.36743 L261.64462,1.62238 L244.12862,1.62238 L244.12802,1.62238 Z M245.72992,25.29508 L247.24092,32.86518 C247.24092,32.86518 251.59782,53.81618 252.50672,58.20418 L233.68472,58.20418 C235.56782,53.16558 242.71672,33.66018 242.71672,33.66018 C242.57755,33.89876 244.57652,28.58128 245.72962,25.29508 L245.72992,25.29508 Z" />
    </Svg>
  );
}

LogoVisa.defaultProps = {
  width: '2.8em',
  height: '0.91em',
};

export default LogoVisa;
