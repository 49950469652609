import { usageTrackingChannel } from './usage-tracking';

function identify({ userId, name, email }) {
  if (typeof window?.FS?.identify !== 'function') return;

  window.FS.identify(userId, {
    displayName: name,
    email,
  });
}

function logEvent(message) {
  if (typeof window?.FS?.event !== 'function') return;
  if (!message?.topic) return;

  window.FS.event(message.topic);
}

usageTrackingChannel.subscribe('#', (_, message) => logEvent(message));

const fullstory = { identify };

export { fullstory };
export default fullstory;
