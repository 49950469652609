import React from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow } from '@material-ui/core';
import { TooltipArrowLeft, TooltipContent, TooltipCloseIcon, TooltipHeader } from 'src/components';

const paragraph = css`
  color: #888;
  line-height: 1.2;
  margin: 0;
`;

function TvpiChartTooltip({ open, anchorEl, onClose }) {
  return (
    <Popper
      style={{
        zIndex: 100,
        maxWidth: 600,
      }}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="right"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0,15',
        },
        arrow: {
          enabled: true,
          element: '.tvpi-chart-info-arrow',
        },
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          <div
            css={`
              overflow: auto;
            `}
          >
            <TooltipArrowLeft className="tvpi-chart-info-arrow" />
            <TooltipContent padding="20px" color="#000" maxWidth="100px" fontSize="12px">
              <TooltipHeader>
                <h3
                  css={`
                    margin: 5px 0;
                    padding: 0;
                  `}
                >
                  TVPI Chart
                </h3>
                <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
              </TooltipHeader>
              <div
                css={`
                  width: 200px;
                `}
              >
                <p css={paragraph}>
                  The TVPI chart in Strategy Performance takes the average TVPI of all funds from that strategy for each
                  quarter. The displayed percentage captures the relative change for the most recent average TVPI value
                  when compared to the selected time period on the left - hand side of the screen .
                </p>
              </div>
            </TooltipContent>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

TvpiChartTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};

TvpiChartTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default TvpiChartTooltip;
