import { useQuery } from 'react-query';
import { filterApi } from 'src/services';

function useCurrentSubscription() {
  return useQuery(
    ['stripeCustomer', 'currentSubscription'],
    async () => {
      const response = await filterApi.stripeGetCustomerSubscriptions();
      if (response.data) return response.data[0];
      return response.error;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export default useCurrentSubscription;
