import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function IconTag({ variation, ...rest }) {
  return (
    <Svg viewBox="0 0 24 24" {...rest}>
      {variation === 'outline' ? (
        <path d="M17.63,5.84 C17.27,5.33 16.67,5 16,5 L5,5.01 C3.9,5.01 3,5.9 3,7 L3,17 C3,18.1 3.9,18.99 5,18.99 L16,19 C16.67,19 17.27,18.67 17.63,18.16 L22,12 L17.63,5.84 Z M16,17 L5,17 L5,7 L16,7 L19.55,12 L16,17 Z" />
      ) : (
        <path d="M17.63,5.84 C17.27,5.33 16.67,5 16,5 L5,5.01 C3.9,5.01 3,5.9 3,7 L3,17 C3,18.1 3.9,18.99 5,18.99 L16,19 C16.67,19 17.27,18.67 17.63,18.16 L22,12 L17.63,5.84 Z" />
      )}
    </Svg>
  );
}

IconTag.defaultProps = {
  width: '0.75em',
  height: '0.75em',
};

export default IconTag;
