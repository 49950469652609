import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFundsFinancialHistory } from 'src/store/fund-financial-history';

function useFundsFinancialHistory(fundIds) {
  const dispatch = useDispatch();
  const allHistories = useSelector(state => state.fund.financialHistory.byId);

  useEffect(() => {
    dispatch(fetchFundsFinancialHistory({ fundIds }));
  }, [dispatch, fundIds]);

  return useMemo(() => {
    if (!fundIds) return null;

    if (!allHistories) return null;

    return fundIds
      .map(id => allHistories[id])
      .filter(Boolean)
      .filter(x => !x.error);
  }, [allHistories, fundIds]);
}

export default useFundsFinancialHistory;
