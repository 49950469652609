import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core/';
import { Button, TextField, CheckboxLarge } from 'src/components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const CheckboxSection = styled.fieldset`
  font-size: 14px;
  margin: 1em 0;
  padding: 0;
  display: block;
  border: none;

  label {
    display: flex;
  }

  span {
    font-size: inherit;
    color: inherit;
    border-color: currentColor;
  }
`;

const Label = styled(FormControlLabel)`
  color: #818181;
  margin: 0;
  display: block;

  a {
    color: #aaa;
  }
`;

function UserSignUpForm({ onSubmit, isSubmitted }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [remember, setRemember] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    onSubmit({ email, password, remember });
  }

  return (
    <Form>
      <TextField
        preset="authForm"
        required
        label="Required"
        type="email"
        placeholder="email"
        onChange={e => setEmail(e.target.value)}
      />

      <TextField
        preset="authForm"
        required
        label="Required"
        type="password"
        placeholder="password"
        onChange={e => setPassword(e.target.value)}
      />

      <CheckboxSection>
        <Label
          control={<CheckboxLarge color="primary" onChange={e => setRemember(e.target.checked)} />}
          label="Remember me"
        />
      </CheckboxSection>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!email || !password || isSubmitted}
        $isLoading={isSubmitted}
        type="submit"
      >
        Sign In
      </Button>
    </Form>
  );
}

UserSignUpForm.defaultProps = {
  onSignIn: () => {},
  onCreateAccount: () => {},
  isSubmitted: false,
};

UserSignUpForm.propTypes = {
  onSignIn: PropTypes.func,
  onCreateAccount: PropTypes.func,
  isSubmitted: PropTypes.bool,
};

export default UserSignUpForm;
