import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, InputLabel } from '@material-ui/core';

function ChartTypeSelect({ value, options, onChange, labels }) {
  return (
    <div>
      <InputLabel
        css={`
          grid-column: 1 / 3;
        `}
        shrink
        id="curve-select-label"
      >
        Chart Type
      </InputLabel>

      <Select
        css={`
          padding-left: 0.25em;
        `}
        labelId="curve-select-label"
        onChange={event => onChange(event.target.value)}
        value={value}
        MenuProps={{ disableScrollLock: true }}
      >
        {options.map(option => {
          return (
            <MenuItem key={option} value={option}>
              {labels ? labels[option] : option}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}

ChartTypeSelect.defaultProps = {
  onChange: console.info,
};

ChartTypeSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ChartTypeSelect;
