import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFundsInMarketCounts, useFundsInMarket, useLayoutActions, useHasSubscription } from 'src/hooks';
import { FundsInMarket, FundsInMarketTable, Link, FundsInMarketTooltip } from 'src/components';

const BackLink = styled(Link).attrs((/* props */) => ({
  component: 'button',
  color: 'primary',
  size: 'small',
}))`
  position: absolute;
  left: 15px;
`;

export function FundsInMarketVisualization({
  categorizationFn,
  switchToListView,
  switchToPlotView,
  isPlotView,
  isListView,
}) {
  const { expandPanel } = useLayoutActions();
  const hasSubscription = useHasSubscription();

  const [data, setData] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const fundsInMarketCounts = useFundsInMarketCounts();
  const fundsInMarket = useFundsInMarket(selectedItem || {});

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);

  useEffect(
    function () {
      if (!fundsInMarketCounts) return;

      const structuredData = fundsInMarketCounts
        .map(categorizationFn)
        .filter(item => Number.parseInt(item.year, 10) > 2007);

      setData(structuredData);
    },
    [fundsInMarketCounts, categorizationFn]
  );

  useEffect(
    function () {
      if (!isListView) return;
      if (!hasSubscription) return;

      expandPanel();
    },
    [isListView, hasSubscription, expandPanel]
  );

  function closeTooltips() {
    setTooltipOpen(false);
    setTooltipAnchorEl(null);
  }

  function showChartTooltip(node, d) {
    const boundingClientRect = node.getBoundingClientRect();

    setTooltipData(d);
    setTooltipAnchorEl({
      clientWidth: boundingClientRect.width,
      clientHeight: boundingClientRect.height,
      getBoundingClientRect: () => boundingClientRect,
    });
    setTooltipOpen(true);
  }

  function handleItemOver(node, d) {
    showChartTooltip(node, d);
  }

  function handleItemTouchStart(node, d) {
    showChartTooltip(node, d);
  }

  function handleClickAway({ target }) {
    if (target.classList.contains('bar')) return;
    if (target.classList.contains('current')) return;

    closeTooltips();
  }

  function handleClickBarSegment(d) {
    const { strategyId, regionId, year } = d;
    setSelectedItem({ strategyId, regionId, year: parseInt(year, 10) });
    setCurrentFilter(d);
    closeTooltips();
    switchToListView();
  }

  return (
    <div
      onMouseLeave={closeTooltips}
      css={`
        height: 100%;
        position: relative;
      `}
    >
      {data && isPlotView && (
        <FundsInMarket
          data={data}
          onItemOver={handleItemOver}
          onItemTouchStart={handleItemTouchStart}
          onItemClick={(_node, d) => handleClickBarSegment(d)}
        />
      )}

      {fundsInMarket && isListView && (
        <div
          css={`
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <BackLink onClick={switchToPlotView}>‹ BACK</BackLink>
            <span
              css={`
                font-size: 18px;
                opacity: 0.8;
              `}
            >
              {currentFilter && currentFilter.label}
            </span>
          </div>
          <div
            css={`
              width: 100%;
              height: 100%;
              position: relative;
            `}
          >
            <FundsInMarketTable data={fundsInMarket} />
          </div>
        </div>
      )}

      <FundsInMarketTooltip
        open={tooltipOpen}
        data={tooltipData}
        anchorEl={tooltipAnchorEl}
        onClickAway={handleClickAway}
        onClose={closeTooltips}
        onViewFullList={handleClickBarSegment}
      />
    </div>
  );
}

FundsInMarketVisualization.defaultProps = {
  isPlotView: true,
  isListView: false,
};

FundsInMarketVisualization.propTypes = {
  categorizationFn: PropTypes.func.isRequired,
  switchToListView: PropTypes.func.isRequired,
  switchToPlotView: PropTypes.func.isRequired,
  isPlotView: PropTypes.bool,
  isListView: PropTypes.bool,
};

export default FundsInMarketVisualization;
