import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { usageTrackingChannel, USAGE_TRACKING_TOPICS } from 'src/services';
import { AppLayout } from '../..';

function SubscribedPage() {
  useEffect(() => {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.userSubscribed);
    navigate('/');
  }, []);
  return <AppLayout />;
}

export default SubscribedPage;
