import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconDot(props) {
  return (
    <Svg viewBox="0 0 100 125" {...props}>
      <path d="M49.999,40.019c-5.503,0-9.98,4.478-9.98,9.981s4.478,9.981,9.98,9.981c5.504,0,9.982-4.478,9.982-9.981  S55.503,40.019,49.999,40.019z" />
    </Svg>
  );
}
