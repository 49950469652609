import React, { useEffect } from 'react';
import { css } from 'styled-components';
import { lg } from 'src/config/layout';
import { AppLayout, Footer } from 'src/components';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import FundPerformancePanel from './FundPerformancePanel';
import FundsInMarketPanel from './FundsInMarketPanel';
import MarketSizePanel from './MarketSizePanel';
import StrategyPerformancePanel from './StrategyPerformancePanel';

function assignOrderAtBreakpoint(order, breakpoint) {
  return css`
    @media (min-width: ${breakpoint}px) {
      order: ${order};
    }
  `;
}

function MarketFilterPage() {
  useEffect(() => {
    window.currentNode = null;
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilter);
  }, []);

  return (
    <AppLayout ticker={<Footer />}>
      <FundPerformancePanel css={assignOrderAtBreakpoint(1, lg.BREAKPOINT)} spanX="half" spanY="half" minHeight={400} />

      <StrategyPerformancePanel
        css={assignOrderAtBreakpoint(3, lg.BREAKPOINT)}
        spanX="half"
        spanY="half"
        minHeight={350}
      />

      <FundsInMarketPanel
        css={assignOrderAtBreakpoint(4, lg.BREAKPOINT)}
        spanX="half"
        spanY="half"
        minHeight={450}
        // content={FundsInMarketVisualization}
        // expandable
      />

      <MarketSizePanel css={assignOrderAtBreakpoint(2, lg.BREAKPOINT)} spanX="half" spanY="half" minHeight={350} />
    </AppLayout>
  );
}

export default MarketFilterPage;
