import { useMemo, useEffect, useState, useCallback } from 'react';
import { useTopLevelRegions } from 'src/hooks';

const useRegionsFilterDefaultOptions = {
  coreAttributeRegionIds: null,
  defaultSelectedIds: null,
};

/**
 * @param defaultSelectedIds: memoized:Array[<topLevelRegionId>]
 * @example useRegionsFilter(useMemo(() => ['kJPtgfD11tNn8lhOcLVP'], []))
 */
function useRegionsFilter(options) {
  const { coreAttributeStrategyIds, defaultSelectedIds } = { ...useRegionsFilterDefaultOptions, ...options };

  const topLevelRegions = useTopLevelRegions();
  const [state, setState] = useState();

  const regions = useMemo(
    () =>
      topLevelRegions?.map(({ regionName, regionId, ...rest }) => ({
        regionName: coreAttributeStrategyIds?.includes(regionId) ? `${regionName} *` : regionName,
        regionId,
        ...rest,
      })),
    [topLevelRegions, coreAttributeStrategyIds]
  );

  const getDefaultSelectedState = useCallback(
    item => {
      if (!defaultSelectedIds) return true;
      return defaultSelectedIds.includes(item.regionId);
    },
    [defaultSelectedIds]
  );

  useEffect(() => {
    if (!regions) return;

    return setState(
      regions.map(item => ({
        ...item,
        disabled: false,
        selected: getDefaultSelectedState(item),
      }))
    );
  }, [regions, getDefaultSelectedState]);

  return [state, setState];
}

export default useRegionsFilter;
