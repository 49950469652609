import React from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as patterns from 'src/config/patterns';
import FormTextField from './FormTextField';
import FormSubmitButton from './FormSubmitButton';
import FormCancelButton from './FormCancelButton';
import { useUpdate as useUpdateAuth } from 'src/services/filter-api/authentication';
import { useLinkWithCredential } from 'src/services/filter-api/authentication';
import { useUpdate as useUpdateUser } from 'src/services/filter-api/user';

function ConvertAnonymousForm({ user, onCancel }) {
  const { userId, name, email } = user;
  const defaultValues = useMemo(() => {
    return { email, name };
  }, [email, name]);
  const { register, handleSubmit, formState, reset, watch } = useForm({
    defaultValues,
    mode: 'onChange',
  });
  const { isValid, errors, isSubmitting } = formState;
  const [isEditing] = useState(true);
  const newPasswordValue = watch('newPassword', '');

  const updateUser = useUpdateUser().mutateAsync;
  const updateAuth = useUpdateAuth().mutateAsync;
  const linkWith = useLinkWithCredential().mutateAsync;

  const handleSubmitForm = handleSubmit(async function (data) {
    if (!isValid) return;

    await linkWith({ email: data.email, password: data.newPassword });
    await updateAuth({ name: data.name });
    await updateUser({
      userId,
      email: data.email,
      name: data.name,
      isAnonymous: false,
    });
  });

  function handleClickCancel() {
    reset();
    onCancel();
  }

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <section>
          <FormTextField
            register={register}
            errors={errors}
            name="name"
            label="Name"
            validations={{
              required: 'Name cannot be blank',
            }}
            disabled={!isEditing}
          />

          <FormTextField
            register={register}
            errors={errors}
            validations={{
              required: 'Email cannot be blank',
              pattern: {
                value: patterns.email,
                message: 'Email must look like an email address',
              },
            }}
            name="email"
            type="email"
            label="Email"
            disabled={!isEditing}
          />

          <FormTextField
            register={register}
            errors={errors}
            name="newPassword"
            type="password"
            label="Choose a Password"
            disabled={!isEditing}
            validations={{
              required: 'New Password cannot be blank',
              pattern: {
                value: patterns.password,
                message: 'Password must be between 6 and 50 characters',
              },
            }}
          />

          <FormTextField
            register={register}
            errors={errors}
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            disabled={!isEditing}
            validations={{
              required: 'Confirm Password is required',
              validate: {
                matches: value => value === newPasswordValue || 'Confirmation should match your new password',
              },
            }}
          />

          <footer>
            <FormSubmitButton isSubmitting={isSubmitting} disabled={!isEditing || !isValid} />
            {onCancel && <FormCancelButton disabled={!isEditing} onClick={handleClickCancel} />}
          </footer>
        </section>
      </form>
    </>
  );
}

ConvertAnonymousForm.defaultProps = {};

ConvertAnonymousForm.propTypes = {
  children: PropTypes.any,
};

export default ConvertAnonymousForm;
