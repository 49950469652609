import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import { useFundManager } from 'src/hooks';
import { AppPanel, FundManagerVisualization } from 'src/components';
import { fundManagerSummary } from 'src/config/tutorials';

const Layout = styled.div`
  height: ${p => p.minHeight}px;

  @media (min-width: ${md.BREAKPOINT}px) {
    height: inherit;
  }
`;

function FundManagerSummaryPanel({ spanX, spanY, minHeight, fundManagerId }) {
  let fundManager = useFundManager({ fundManagerId });

  return (
    <Layout
      as={AppPanel}
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title="Fund Manager Summary"
      tutorial={fundManagerSummary}
      downloadable={{
        fileName: fundManager && `${fundManager.name}-summary.png`,
      }}
      css={`
        @media (min-width: 0px) and (max-width: ${md.BREAKPOINT}px) {
          min-height: 130vh;
        }
      `}
    >
      <FundManagerVisualization fundManagerId={fundManagerId} />
    </Layout>
  );
}

FundManagerSummaryPanel.defaultProps = {};

FundManagerSummaryPanel.propTypes = {
  fundManagerId: PropTypes.string.isRequired,
};

export default FundManagerSummaryPanel;
