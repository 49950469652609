import React from 'react';
import { useRef, useState } from 'react';
import { SmallCircle, VisibleOnlyToYouTooltip } from 'src/components';

function UserFundIndicator() {
  const anchorEl = useRef();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div ref={anchorEl} onMouseOver={() => setTooltipOpen(true)} onMouseOut={() => setTooltipOpen(false)}>
      <SmallCircle
        css={`
          margin-left: 10px;
          margin-bottom: 8px;
        `}
      />
      <VisibleOnlyToYouTooltip open={tooltipOpen} anchorEl={anchorEl && anchorEl.current} />
    </div>
  );
}

export default UserFundIndicator;
