import { select, scaleLinear, scaleUtc, extent, easeCubicInOut, axisBottom, axisLeft, area, line, format } from 'd3';

import { isEqual, formatDateShortQuarter, getQuarter, getRecentQuarter } from 'src/utils/date';
import { upperCase } from 'src/formatters';
import { strings } from 'src/strings';
import { valueChange } from 'src/calculators';

const percent = format('+.1%');
const xformat = formatDateShortQuarter;
const TRANSITION_DURATION = 500;
const GREEN = '#60C016';

// get an item from the series where the date is back the amount of time specified by timeAgo
function getItem(series, monthsAgo) {
  const { date: latestDate } = series[series.length - 1];
  const prevDate = getRecentQuarter(monthsAgo / 3)(latestDate);
  return series.find(item => isEqual(prevDate)(item.date));
}

// TODO: use enter and exit to transition
function draw({ id, data, timeRange, metric, size, margin }) {
  if (!size.width || !size.height) return;

  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;
  // map the ISO date string to a date
  const series = data.map(item => ({
    ...item,
    date: new Date(item.date),
  }));
  const quartersBack = timeRange.value / 3; // 3 months in a quarter
  const prefixedId = `#${id}`;
  const areaOfInterest = series.slice(series.length - quartersBack - 2);
  const currItem = series[series.length - 1];
  const prevItem = getItem(series, timeRange.value);
  const metricKey = metric.key;
  const horizontalLineExtentionLength = 25;

  if (!prevItem) return false;

  select(prefixedId).selectAll('*').remove();

  const svg = select(prefixedId).append('g').attr('transform', `translate(${margin.left},${margin.top})`);

  const t = svg.transition().duration(TRANSITION_DURATION).ease(easeCubicInOut);

  const xExtent = extent(series, d => d.date);

  // Build X scale:

  const xScale = scaleUtc().domain(xExtent).range([0, svgWidth]);

  svg
    .append('g')
    .attr('transform', `translate(0,${svgHeight})`)
    .attr('class', 'x-axis')
    .style('shape-rendering', 'crispEdges')
    .call(
      axisBottom(xScale)
        .ticks(series.length)
        .tickSizeInner(-(svgHeight - 25))
        .tickFormat(d => {
          return xformat(getRecentQuarter()(d));
        })
    );

  //debugger;
  const yExtent = extent(series, d => d[metricKey]);
  if (yExtent[0] > 1.0) yExtent[0] = 1.0;

  // Build Y scale:
  const yScale = scaleLinear().domain([yExtent[0], yExtent[1]]).range([svgHeight, 55]);

  // add the Y axis
  svg.append('g').attr('class', 'y-axis').style('shape-rendering', 'crispEdges').call(
    axisLeft(yScale).ticks(4).tickSize(-svgWidth)
    // .tickFormat(formatPercent0)
  );

  // remove the Q2 and Q4 ticks
  const tickText = svg.selectAll('.x-axis .tick text');
  tickText.each(function removeTick(date) {
    if (getQuarter(date) % 2 !== 0) return;
    select(this).remove();
  });

  // add Y axis label
  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 0 - margin.left / 1.25)
    .attr('x', 0 - svgHeight / 2)
    .attr('dy', '1em')
    .attr('class', 'axis-label')
    .style('text-anchor', 'middle')
    .text(upperCase(metric.labelChart));

  // add the grey line
  svg
    .append('path')
    .datum(series)
    .attr('fill', 'none')
    // .transition(t)
    .attr('stroke', '#333333')
    .attr('stroke-width', 2)
    .attr(
      'd',
      line()
        .x(d => xScale(d.date))
        .y(d => yScale(d[metricKey]))
    );

  // Add the GREEN line
  svg
    .append('path')
    .datum(areaOfInterest) // quarters
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 2)
    .attr('opacity', 0)
    .attr(
      'd',
      line()
        .x(d => xScale(d.date))
        .y(() => svgHeight)
    )
    .transition(t)
    .attr(
      'd',
      line()
        .x(d => xScale(d.date))
        .y(d => yScale(d[metricKey]))
    )
    .attr('opacity', 1);

  // add the grey area under the GREEN line
  svg
    .append('path')
    .datum(areaOfInterest)
    .attr('fill', '#6a6a6a')
    .attr('fill-opacity', 0.5)
    .attr(
      'd',
      area()
        .x(d => xScale(d.date))
        .y0(svgHeight)
        .y1(svgHeight)
    )
    .transition(t)
    .attr('fill-opacity', 0.2)
    .attr(
      'd',
      area()
        .x(d => xScale(d.date))
        .y0(svgHeight)
        .y1(d => yScale(d[metricKey]))
    );

  // Add the previous items horizontal dashed GREEN the line
  const prevItemHorizontalLineStart = { ...prevItem };
  const prevItemHorizontalLineEnd = { ...currItem };
  prevItemHorizontalLineEnd[metricKey] = prevItemHorizontalLineStart[metricKey];
  const prevItemHorizontalLineSeries = [prevItemHorizontalLineStart, prevItemHorizontalLineEnd];

  // Add the cirrent items horizontal dashed GREEN the line
  const currItemHorizontalLineStart = { ...currItem };
  const currItemHorizontalLineEnd = { ...currItemHorizontalLineStart };
  currItemHorizontalLineEnd[metricKey] = currItemHorizontalLineStart[metricKey];
  const currItemHorizontalLineSeries = [currItemHorizontalLineStart, currItemHorizontalLineEnd];

  const prevItemVerticalLineStart = { ...prevItem };
  const prevItemVerticalLineEnd = { ...prevItemVerticalLineStart };
  [prevItemVerticalLineStart[metricKey]] = yExtent;

  prevItemVerticalLineEnd[metricKey] = yExtent[1];
  const prevItemVerticalLineSeries = [prevItemVerticalLineStart, prevItemVerticalLineEnd];

  const currItemVerticalLineStart = { ...currItem };
  const currItemVerticalLineEnd = { ...currItemVerticalLineStart };
  [currItemVerticalLineStart[metricKey]] = yExtent;

  currItemVerticalLineEnd[metricKey] = yExtent[1];
  const currItemVerticalLineSeries = [currItemVerticalLineStart, currItemVerticalLineEnd];

  svg
    .append('path')
    .datum(prevItemHorizontalLineSeries)
    .attr('class', 'horizontal-dashed')
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? xScale(d.date) : xScale(d.date) + horizontalLineExtentionLength))
        .y(() => svgHeight)
    )
    .transition(t)
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1.5)
    .attr('stroke-dasharray', '6 6')
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? xScale(d.date) : xScale(d.date) + horizontalLineExtentionLength))
        .y(d => yScale(d[metricKey]))
    );
  svg
    .append('path')
    .datum(currItemHorizontalLineSeries)
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? xScale(d.date) : xScale(d.date) + horizontalLineExtentionLength))
        .y(() => 0)
    )
    .transition(t)
    .attr('class', 'horizontal-dashed')
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1.5)
    .attr('stroke-dasharray', '6 6')
    .attr(
      'd',
      line()
        .x((d, index) => (index === 0 ? xScale(d.date) : xScale(d.date) + horizontalLineExtentionLength))
        .y(d => yScale(d[metricKey]))
    );

  svg
    .append('path')
    .datum(prevItemVerticalLineSeries)
    .transition(t)
    .attr('class', 'vertical-solid')
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1)
    .attr(
      'd',
      line()
        .x(d => xScale(d.date))
        .y(d => yScale(d[metricKey]))
    );

  svg
    .append('path')
    .datum(currItemVerticalLineSeries)
    .transition(t)
    .attr('class', 'vertical-solid')
    .attr('fill', 'none')
    .attr('stroke', GREEN)
    .attr('stroke-width', 1)
    .attr(
      'd',
      line()
        .x(d => xScale(d.date))
        .y(d => yScale(d[metricKey]))
    );

  const currValue = currItem[metricKey];
  const prevValue = prevItem[metricKey];
  const change = valueChange(currValue)(prevValue);

  // add the tooltip
  const tooltipSize = { width: 54, height: 36 };
  const tooltip = svg
    .append('g')
    .attr(
      'transform',
      `translate(${xScale(currItem.date) - tooltipSize.width / 2},${yScale(currValue) - tooltipSize.height - 18})`
    );

  // tooltip rect
  tooltip
    .append('rect')
    .attr('width', tooltipSize.width)
    .attr('height', tooltipSize.height)
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('fill', GREEN)
    .attr('class', 'shadow')
    .transition(t)
    .attr('stroke', '#33333333')
    .attr('stroke-width', 1);

  // tooltip change%
  tooltip
    .append('text')
    .attr('x', tooltipSize.width / 2)
    .attr('y', tooltipSize.height / 2 - 4)
    .attr('opacity', 0)
    .attr('text-align', 'center')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('fill', '#fff')
    .attr('font-size', '14px')
    .transition(t)
    .attr('opacity', 1)
    .text(percent(change));

  // tooltip "MEAN"
  tooltip
    .append('text')
    .attr('x', tooltipSize.width / 2)
    .attr('y', tooltipSize.height / 2 + 9)
    .attr('text-align', 'center')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('fill', '#fff')
    .attr('font-size', '8px')
    .attr('letter-spacing', '1px')
    .text(upperCase(strings.labels.mean));

  // tooltip triangle
  tooltip
    .append('path')
    .attr('d', 'M 0 0 L 15 0 L 7.5 7.5 z')
    .attr('transform', `translate(${tooltipSize.width / 2 - 7.5} ,${tooltipSize.height / 2 + 17})`)
    .attr('class', 'shadow')
    .attr('fill', GREEN);
}

export default draw;
