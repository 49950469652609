import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from 'src/services';
import { subtractYears } from 'src/utils/date';

const fetchFundManagerNewsPending = createAction('FETCH_FUND_MANAGER_NEWS_PENDING');
const fetchFundManagerNewsError = createAction('FETCH_FUND_MANAGER_NEWS_ERROR');
const fetchFundManagerNewsSuccess = createAction('FETCH_FUND_MANAGER_NEWS_SUCCESS');

export function fetchFundManagerNews({ fundManagerId }) {
  return async function (dispatch, getState) {
    try {
      const { fundManager } = getState().news;
      if (fundManager[fundManagerId] && (fundManager[fundManagerId].fetching || fundManager[fundManagerId].fetched))
        return;

      dispatch(fetchFundManagerNewsPending({ fundManagerId }));

      const articles = await filterApi.getNewsByFundManager({
        fundManagerId,
        minDateTime: subtractYears(1)(new Date()).toISOString(),
      });
      dispatch(
        fetchFundManagerNewsSuccess({
          articles,
          fundManagerId,
        })
      );
    } catch (error) {
      dispatch(
        fetchFundManagerNewsError({
          error: error.message,
          fundManagerId,
        })
      );
    }
  };
}

const initialState = {};

export default createReducer(initialState, {
  [fetchFundManagerNewsPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    const existing = state[fundManagerId] || {};
    existing.fetching = true;
    state[fundManagerId] = existing;
  },
  [fetchFundManagerNewsError]: (state, action) => {
    const { error, fundManagerId } = action.payload;
    const existing = state[fundManagerId] || {};
    existing.fetching = false;
    existing.error = error;
    state[fundManagerId] = existing;
    console.error(error);
  },
  [fetchFundManagerNewsSuccess]: (state, action) => {
    const { fundManagerId, articles } = action.payload;
    state[fundManagerId] = {
      fetching: false,
      fetched: true,
      articles,
      error: null,
    };
  },
});
