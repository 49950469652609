import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useLayoutActions } from 'src/hooks';
import { lookup } from 'src/lookup';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { AppPanel, FundAddEditDialog } from 'src/components';
import FundsCollectionView from './FundsCollectionView';
import SingleFundView from './SingleFundView';
import { VIEW_STATE } from './constants';

export function DashboardFunds({ isGridView, isListView, viewGrid }) {
  const [timeRange, setTimeRange] = useState();
  const [financialMetric, setFinancialMetric] = useState(lookup.financialMetric.tvpi);

  const [selectedFundId, setSelectedFundId] = useState();
  const [fundAddEditOpen, setFundAddEditOpen] = useState(false);
  const { isExpanded } = useContext(AppPanel.Context);
  const { expandPanel } = useLayoutActions();

  function handleListDefCreated(listDef) {
    listDef.pop();
  }

  function handleChangeTimeRange(value) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.savedFundTimeRange);
    setTimeRange(value);
  }

  function handleChangeFinancialMetric(value) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.savedFundMetric);
    setFinancialMetric(value);
  }

  function handleGridItemClick(_, d) {
    setSelectedFundId(d.fundId);
  }

  function handleTableRowClick(_, d) {
    const { data } = d;
    setSelectedFundId(data.fundId);
  }

  function handleFundAddEditClose() {
    setFundAddEditOpen(false);
  }

  useEffect(
    function () {
      if (isListView) return;
      if (isGridView) return;
      if (!selectedFundId) return viewGrid();
    },
    [selectedFundId, isListView, isGridView, viewGrid]
  );

  useEffect(
    function () {
      if (!isListView) return;
      expandPanel();
    },
    [isListView, expandPanel]
  );

  return (
    <>
      <div
        css={`
          height: 100%;
          /* position: relative;
          overflow-y: scroll; */

          /* display: grid; */
          /* gap: 10px; */
        `}
      >
        {selectedFundId ? (
          <SingleFundView
            css={`
              height: 100%;
            `}
            fundId={selectedFundId}
            onClickBack={() => setSelectedFundId(null)}
            timeRange={timeRange}
            handleChangeTimeRange={handleChangeTimeRange}
            financialMetric={financialMetric}
            handleChangeFinancialMetric={handleChangeFinancialMetric}
          />
        ) : (
          <FundsCollectionView
            css={`
              flex: 1;
            `}
            isGridView={isGridView}
            isListView={isListView}
            expanded={isExpanded}
            financialMetric={financialMetric}
            timeRange={timeRange}
            handleGridItemClick={handleGridItemClick}
            handleListDefCreated={handleListDefCreated}
            handleTableRowClick={handleTableRowClick}
            handleChangeTimeRange={handleChangeTimeRange}
            handleChangeFinancialMetric={handleChangeFinancialMetric}
            handleAddFundClick={() => setFundAddEditOpen(true)}
          />
        )}
      </div>

      <FundAddEditDialog open={fundAddEditOpen} onClose={handleFundAddEditClose} />
    </>
  );
}

DashboardFunds.CONSTANTS = { VIEW_STATE };

DashboardFunds.defaultProps = {};

DashboardFunds.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
};

export default DashboardFunds;
