import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconPerformance(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M7.98058 53.96164h12v34h-12zM31.9711 40.18491h12v47.77673h-12zM55.96162 27.002h12v60.95964h-12zM79.95214 12.03762h12v75.92402h-12z" />
    </Svg>
  );
}
