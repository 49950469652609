import { select, scaleTime, extent, axisBottom, line, scaleLinear, hsl } from 'd3';

const V_BREAKPOINT = 180;
const BREAKPOINT = 200;

function trianglePolygon({ x, y, size = 5 }) {
  return `${x}, ${y} ${x - size}, ${y - size} ${x + size}, ${y - size}`;
}

function getSettings({ height, width, data }) {
  const key = width > BREAKPOINT && height > V_BREAKPOINT ? 'detailed' : 'simplified';

  function shortTickFormat(date) {
    return `'${date.getFullYear().toString().substr(-2)}`;
  }

  function longTickFormat(date) {
    return `Q${date.getMonth() / 3 + 1}'${date.getFullYear().toString().substr(-2)}`;
  }

  const margin = {
    simplified: {
      top: 12,
      bottom: 12,
      left: 12,
      right: 12,
    },
    detailed: {
      top: 36,
      bottom: 12,
      left: 24,
      right: 24,
    },
  }[key];

  const canvas = {
    margin,
    width: Math.max(width - (margin.left + margin.right), 0),
    height: Math.max(height - (margin.top + margin.bottom), 0),
  };

  return {
    simplified: {
      isSimplified: true,
      drawValueLabels: false,
      tickFormat: shortTickFormat,
      tickCount: Math.round(data.length / 4),
      canvas,
    },
    detailed: {
      isSimplified: false,
      drawValueLabels: true,
      tickFormat: longTickFormat,
      tickCount: Math.round(data.length / 4),
      canvas,
    },
  }[key];
}

function drawHistoricalChart({
  svgId,
  relevantHistory,
  relevantBenchmarkHistory,
  width,
  height,
  accentColor,
  labelFormat,
}) {
  if (!svgId) return;
  if (!width || !height) return;
  if (relevantHistory.length < 1) return;
  if (relevantBenchmarkHistory.length < 1) return;

  const settings = getSettings({
    width,
    height,
    data: relevantHistory,
  });
  const { canvas } = settings;

  const dataSet = [
    { values: relevantBenchmarkHistory, color: '#4e4e4e' },
    {
      values: relevantHistory,
      color: accentColor,
    },
  ];

  const timeScale = relevantHistory.map(d => new Date(d.date));

  const valueScale = relevantHistory.concat(relevantBenchmarkHistory).map(d => d.value);

  const xScale = scaleTime().domain(extent(timeScale)).range([0, canvas.width]);

  const yScale = scaleLinear().domain(extent(valueScale)).range([canvas.height, canvas.margin.top]);

  const xAxis = function (g) {
    return g
      .attr('transform', `translate(0,${canvas.height})`)
      .call(axisBottom(xScale).ticks(settings.tickCount).tickFormat(settings.tickFormat));
  };

  const lineFn = line()
    .defined(d => !isNaN(d.value))
    .x(d => xScale(new Date(d.date)))
    .y(d => yScale(d.value));

  const root = select(`#${svgId}`)
    .selectAll('.root')
    .data([relevantHistory])
    .join('g')
    .attr('class', 'root')
    .attr('transform', `translate(${canvas.margin.left}, 0)`);

  const lines = root.selectAll('.line').data(dataSet);

  lines
    .transition()
    .attr('d', d => lineFn(d.values))
    .attr('stroke', d => d.color)
    .attr('stroke-width', 1.5)
    .attr('fill', 'none');

  lines
    .enter()
    .append('path')
    .attr('d', d => lineFn(d.values))
    .attr('class', 'line')
    .attr('stroke', d => d.color)
    .attr('stroke-width', 1.5)
    .attr('fill', 'none');

  const labelsGroup = root
    .selectAll('.y-value-labels')
    .data([settings.isSimplified ? 'none' : 'initial'])
    .join('g')
    .attr('class', 'y-value-labels')
    .style('display', d => d);

  const labelGroup = labelsGroup
    .selectAll('.y-value-label')
    .data([relevantHistory[0], relevantHistory[relevantHistory.length - 1]])
    .join('g')
    .attr('class', 'y-value-label')
    .attr('transform', 'translate(-13, -22)')
    .style('opacity', 1);

  labelGroup
    .selectAll('rect')
    .data(d => [d])
    .join('rect')
    .attr('class', 'y-value-bubble')
    .attr('fill', accentColor)
    .attr('y', d => yScale(d.value))
    .attr('x', d => xScale(new Date(d.date)))
    .attr('width', 26)
    .attr('height', 14)
    .attr('rx', 3);

  labelGroup
    .selectAll('polygon')
    .data(d => [d])
    .join('polygon')
    .attr('class', 'y-value-triangle')
    .attr('fill', accentColor)
    .attr('points', d =>
      trianglePolygon({
        x: xScale(new Date(d.date)),
        y: yScale(d.value),
      })
    )
    .attr('transform', 'translate(13, 18)');

  labelGroup
    .selectAll('text')
    .data(d => [d])
    .join('text')
    .attr('class', 'y-value-text')
    .attr('fill', hsl(accentColor).l > 0.5 ? 'black' : 'white')
    .attr('text-anchor', 'middle')
    .attr('y', d => yScale(d.value))
    .attr('x', d => xScale(new Date(d.date)))
    .text(d => labelFormat(d.value))
    .attr('transform', 'translate(13, 10)');

  root
    .selectAll('.tick')
    .data([timeScale])
    .join('g')
    .attr('class', 'tick')
    .call(xAxis)
    .call(g => g.select('.domain').remove())
    .call(g => g.selectAll('line').remove());
}

export default drawHistoricalChart;
