import { useQuery } from 'react-query';
import { useMeasure } from 'react-use';

function useOembed(videoId) {
  return useQuery(
    `vimeo.oembed.${videoId}`,
    () => fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`).then(res => res.json()),
    { enabled: Boolean(videoId), cacheTime: Infinity }
  );
}

function useThumbnail(videoId) {
  const [ref, { width }] = useMeasure();
  const { data: oembed, ...rest } = useOembed(videoId);
  if (!oembed) return { ref, rest };

  const data = {
    url: oembed.thumbnail_url,
    width: oembed.thumbnail_width,
    height: (width / oembed.thumbnail_width) * oembed.thumbnail_height,
  };
  return { ref, data, ...rest };
}

export { useOembed, useThumbnail };
