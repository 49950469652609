import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconLifePreserver(props) {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
      <path d="M256,32C132.3,32,32,132.3,32,256s100.3,224,224,224s224-100.3,224-224S379.7,32,256,32z M434.3,184.5l-61.5,18.9  c-6.3-14-15.1-26.8-26.3-38c-11.2-11.2-24-20-38-26.3l18.9-61.5c23.8,9.5,45.6,23.9,64.3,42.5C410.4,138.9,424.8,160.7,434.3,184.5z   M256,352c-53,0-96-43-96-96c0-53,43-96,96-96s96,43,96,96C352,309,309,352,256,352z M120.2,120.2c18.7-18.7,40.5-33,64.3-42.5  l18.9,61.5c-14,6.3-26.8,15.1-38,26.3c-11.2,11.2-20,24-26.3,38l-61.5-18.9C87.2,160.7,101.6,138.9,120.2,120.2z M77.7,327.5  l61.5-18.9c6.3,14,15.1,26.8,26.3,38s24,20,38,26.3l-18.9,61.5c-23.8-9.5-45.6-23.9-64.3-42.5C101.6,373.1,87.2,351.3,77.7,327.5z   M391.8,391.8c-18.7,18.7-40.5,33-64.3,42.5l-18.9-61.5c14-6.3,26.8-15.1,38-26.3s20-24,26.3-38l61.5,18.9  C424.8,351.3,410.4,373.1,391.8,391.8z" />
    </Svg>
  );
}
