import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagerFundsRaising } from '../store/fund-manager-funds-raising';
import { fetchFundManagerFundsInMarket } from '../store/fund-manager-funds-in-market';

export default function useFundManagerFundsRaising({ fundManagerId }) {
  const dispatch = useDispatch();
  const fundManagerFundsRaising = useSelector(state => state.fundsRaising.fundManagerFunds);
  const fundManagerFundsInMarket = useSelector(state => state.fundsInMarket.fundManagerFunds);
  const [funds, setFunds] = useState(null);

  useEffect(() => {
    if (!fundManagerId) return;
    dispatch(fetchFundManagerFundsRaising({ fundManagerId }));
    dispatch(fetchFundManagerFundsInMarket({ fundManagerId }));
  }, [fundManagerId, dispatch]);

  useEffect(() => {
    if (
      fundManagerFundsRaising.fundManagerId === fundManagerId &&
      fundManagerFundsRaising.fetched &&
      !fundManagerFundsRaising.fetching &&
      fundManagerFundsInMarket.fundManagerId === fundManagerId &&
      fundManagerFundsInMarket.fetched &&
      !fundManagerFundsInMarket.fetching
    ) {
      setFunds([...fundManagerFundsRaising.all, ...fundManagerFundsInMarket.all]);
    }
  }, [
    fundManagerFundsRaising.fetched,
    fundManagerFundsRaising.fetching,
    fundManagerFundsRaising.all,
    fundManagerFundsRaising.fundManagerId,
    fundManagerFundsInMarket.fetched,
    fundManagerFundsInMarket.fetching,
    fundManagerFundsInMarket.all,
    fundManagerFundsInMarket.fundManagerId,
    fundManagerId,
  ]);

  return funds;
}
