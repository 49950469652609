import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { upperCase } from 'src/formatters';
import { strings } from 'src/strings';
import { Link } from 'src/components';

const StyledLink = styled(Link).attrs(() => ({
  component: 'button',
  color: 'primary',
  size: 'small',
}))`
  font-size: 12px;
`;

const StyledBreadCrumbNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function BreadCrumbNav({ showAllFundsLink, onBackClick, onViewAllFundsClick }) {
  return (
    <StyledBreadCrumbNav>
      <StyledLink onClick={onBackClick}>
        &lsaquo;
        {` ${upperCase(strings.labels.backToAllStrategies)}`}
      </StyledLink>

      {showAllFundsLink && (
        <StyledLink onClick={onViewAllFundsClick}>
          {upperCase(`${strings.labels.viewAll} ${strings.labels.funds} `)}
          &raquo;
        </StyledLink>
      )}
    </StyledBreadCrumbNav>
  );
}

BreadCrumbNav.defaultProps = {
  showAllFundsLink: false,
  onBackClick: () => {},
  onViewAllFundsClick: () => {},
};

BreadCrumbNav.propTypes = {
  showAllFundsLink: PropTypes.bool,
  onBackClick: PropTypes.func,
  onViewAllFundsClick: PropTypes.func,
};

export { BreadCrumbNav };
