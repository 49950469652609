import React from 'react';
import { useState, useRef, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { useFundManagerLocation, useLayoutActions } from 'src/hooks';
import { useGet as useGetFundManagerNews } from 'src/services/filter-api/fundManagerNews/queries';
import {
  AppPanel,
  FundFundManagerSummary,
  VisibleOnlyToYouTooltip,
  SmallCircle,
  UserFundOrManagerMessage,
  VisualizationMessage,
  NewsArticles,
  LoadingIndicator,
} from 'src/components';

function handleFundManagerSelect(manager) {
  usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundFundManagerSelect);
  navigate(`/managers/${manager.fundManagerId}`);
}

const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

function FundFundManagerSummaryVisualization({ isLoading, fundManager }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const location = useFundManagerLocation({
    fundManagerId: fundManager.fundManagerId,
  });

  const { data: articles } = useGetFundManagerNews(fundManager?.fundManagerId);

  const anchorEl = useRef();

  const { expandPanel } = useLayoutActions();
  const { isExpanded } = useContext(AppPanel.Context);

  const handleNewsArticleClick = useCallback(
    function () {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundNews);
      expandPanel();
    },
    [expandPanel]
  );

  if (isLoading)
    return (
      <div
        css={`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <LoadingIndicator />
      </div>
    );

  if (fundManager.userId)
    return (
      <Layout
        css={`
          justify-content: space-between;
        `}
      >
        <div
          css={`
            font-size: 20px;
            font-weight: 300;
            display: flex;
            align-items: center;
          `}
        >
          {fundManager.name}
          <div ref={anchorEl} onMouseOver={() => setTooltipOpen(true)} onMouseOut={() => setTooltipOpen(false)}>
            <SmallCircle
              css={`
                margin-left: 10px;
                margin-bottom: 8px;
              `}
            />
          </div>
        </div>
        <UserFundOrManagerMessage
          label="fund manager"
          css={`
            margin-top: -15px;
          `}
        />
        <div />

        <VisibleOnlyToYouTooltip open={tooltipOpen} anchorEl={anchorEl && anchorEl.current} label="fund manager" />
      </Layout>
    );
  return (
    <Layout>
      {!isExpanded && (
        <FundFundManagerSummary
          fundManager={fundManager}
          location={location}
          onFundManagerSelect={handleFundManagerSelect}
        />
      )}

      <div
        css={`
          flex: 1;
          display: flex;
          flex-direction: column;
          max-height: 100%;
        `}
      >
        {articles && articles.length === 0 ? (
          <VisualizationMessage
            css={`
              max-width: 300px;
              align-self: center;
              text-align: center;
            `}
          >
            <p>at present no news has been generated for this fund manager.</p>
          </VisualizationMessage>
        ) : (
          <NewsArticles
            articles={articles || []}
            onSelectedArticle={handleNewsArticleClick}
            forceCondensedView={!isExpanded}
          />
        )}
      </div>
    </Layout>
  );
}

FundFundManagerSummaryVisualization.defaultProps = {
  isLoading: true,
};

FundFundManagerSummaryVisualization.propTypes = {
  fundManager: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default FundFundManagerSummaryVisualization;
