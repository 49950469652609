import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const Container = styled.div`
  width: 100%;
  height: ${p => p.$width * 0.5}px;
`;

const Svg = styled.svg`
  opacity: ${p => (p.$inactive ? 0.4 : 1)};
  display: block;
  width: 100%;
  height: 100%;

  .tick line {
    color: #2e2e2e;
  }
  .tick text {
    fill: #808080;
    font-size: 10px;
  }
  .x-axis-label,
  .y-axis-label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
  }
  .domain {
    opacity: 0;
  }
  .address-point {
    cursor: pointer;
  }
`;

function FundManagerLocations({
  dataGeo,
  dataPoints,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  ...rest
}) {
  const id = useRef(uniqueId());

  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    draw({
      id: id.current,
      dataGeo,
      dataPoints,
      mouseEventRefs,
      width,
      height,
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
    });
  }, [dataGeo, dataPoints, width, height, mouseEventRefs]);

  return (
    <Container ref={targetRef} $width={width} {...rest}>
      <Svg id={id.current} $inactive={dataPoints.length < 1} />
    </Container>
  );
}

FundManagerLocations.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
  targetRef: null,
};

FundManagerLocations.propTypes = {
  dataGeo: PropTypes.object.isRequired,
  dataPoints: PropTypes.array.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default withResizeDetector(FundManagerLocations);
