import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md, lg } from 'src/config/layout';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { SubscriptionPaywall } from 'src/components';
import NewsArticleSource from './news-article-source';
import NewsArticleHeading from './news-article-heading';
import NewsArticleControls from './news-article-controls';
import NewsArticleSummary from './news-article-summary';
import { IconSummary } from 'src/components';
import { getFaviconUrl } from './getFaviconUrl';
import { getClipboardText } from './getClipboardText';

const Layout = styled.div`
  height: 100%;
  position: relative;
  border: 1px solid #303030;
  border-radius: 6px;
  background-color: #262626;

  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  padding: 36px 18px 10px 18px;

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 36px 36px 20px 36px;
  }
`;

const Main = styled.div`
  padding: 0 18px 8px 18px;

  flex: 1;
  overflow-y: hidden;

  display: flex;

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 0 36px 16px 36px;
  }
`;

const Footer = styled.div`
  display: none;
  flex: 0;
  padding: 10px 18px;
  background-color: #2d2d2d;

  @media (min-height: ${lg.V_BREAKPOINT}px) {
    display: unset;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 20px 36px;
  }
`;

const SummarySection = styled.section`
  flex: 1 1 100%;
`;

const ControlsSection = styled.section`
  display: none;
  flex: 1 1 auto;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: unset;
  }
`;

const TagLine = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 10px 0;
  font-size: 9px;
  color: rgba(225, 183, 42, 0.7);
  text-align: center;
  text-transform: uppercase;
  .MuiSvgIcon-root {
    margin-right: 5px;
    height: 14px;
    width: 13px;
  }
`;

const BottomHeading = styled.span`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  color: #a6a6a6;
`;

const ArticleSources = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const Triangle = styled.span`
  display: ${p => (p.$hidden ? 'none' : null)};
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 24px solid #e1b62a;
  border-left: 24px solid transparent;
`;

function NewsArticle(props) {
  const { article, summary, summaryLength, onSummaryLengthChange, onShare, showSelectedIndicator, className } = props;

  const componentRef = useRef();
  const [clipboardText, setClipboardText] = useState(null);
  const [favicon, setFavicon] = useState(null);

  useEffect(() => {
    setClipboardText(getClipboardText(article, summary));
  }, [article, summary]);

  useEffect(() => {
    setFavicon(getFaviconUrl(article.url));
  }, [article]);

  function handleSummaryLengthChange(length) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.newsSummaryLength);
    onSummaryLengthChange(length);
  }

  return (
    <Layout className={className}>
      <Header>
        <NewsArticleHeading
          article={article}
          favicon={favicon}
          summaryLength={summaryLength}
          onSummaryLengthChange={handleSummaryLengthChange}
        />
        <Triangle $hidden={!showSelectedIndicator} />
      </Header>

      <Main ref={componentRef} as={SubscriptionPaywall}>
        <SummarySection>
          <NewsArticleSummary summary={summary} summaryLength={summaryLength} />
        </SummarySection>
        <ControlsSection>
          <NewsArticleControls
            summary={summary}
            onShare={onShare}
            printRef={componentRef}
            clipboardText={clipboardText}
          />
        </ControlsSection>
      </Main>

      <TagLine>
        <IconSummary />
        generated by the FundFilter news engine
      </TagLine>

      <Footer>
        <BottomHeading>Source article:</BottomHeading>
        <ArticleSources>
          <NewsArticleSource article={article} />
        </ArticleSources>
      </Footer>
    </Layout>
  );
}

NewsArticle.defaultProps = {
  expanded: false,
  summary: null,
  onSummaryLengthChange: () => {},
  onShare: () => {},
  onCopy: () => {},
  showSelectedIndicator: true,
};

NewsArticle.propTypes = {
  article: PropTypes.object.isRequired,
  summary: PropTypes.object,
  expanded: PropTypes.bool,
  summaryLength: PropTypes.number.isRequired,
  onSummaryLengthChange: PropTypes.func,
  onShare: PropTypes.func,
  onCopy: PropTypes.func,
  showSelectedIndicator: PropTypes.bool,
};

export default NewsArticle;
