import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconInfoOutlined, Tooltip, Checkbox } from 'src/components';
import { ClickAwayListener } from '@material-ui/core';

const Layout = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipTitle = styled.h3`
  color: #918d8d;
  text-transform: uppercase;
`;

const LineLegend = styled.span`
  border-bottom: 2px ${p => (p.$dotted ? 'dotted' : 'solid')};
  border-color: ${p => (p.$forecast ? '#ad48f8' : '#00abd4')};
  width: 3em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
`;

const InlineLabel = styled.span`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 0.5em;

  .MuiSvgIcon-root {
    width: 0.625em;
    height: 0.625em;
    margin-bottom: 2px;
  }
`;

const LabelText = styled.span`
  font-size: 1em;

  animation: 1s infinite;
  animation-name: ${p => p.pulse && 'sk-pulse'};
`;

const ExtLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})``;

function ViewForecastCheckbox({
  disabled,
  checked,
  onChange,
  isLoading,
  isMatureFund,
  size,
  color,
  disabledWithMessage,
}) {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  function handleTooltipOpen() {
    setOpenTooltip(true);
  }

  function handleTooltipClose() {
    setOpenTooltip(false);
  }

  const labelText = disabledWithMessage
    ? disabledWithMessage
    : isLoading
    ? 'Preparing forecast...'
    : isMatureFund
    ? 'Forecast unavailable'
    : '3-Year Forecast';

  return (
    <Layout>
      <Checkbox color={color} checked={disabled ? false : checked} onChange={onChange} disabled={disabled} size={size}>
        <InlineLabel>
          <LabelText pulse={isLoading}>{labelText}</LabelText>
        </InlineLabel>
      </Checkbox>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            open={openTooltip}
            title={
              isMatureFund ? (
                <div>
                  No forecast available as this <br />
                  fund has reached maturity
                </div>
              ) : (
                <div>
                  <div>
                    <TooltipTitle>Forecasts</TooltipTitle>
                    <p>
                      Forecasts are generated from applying multiple machine learning models to the historic data of
                      thousands of private funds. See <ExtLink href="https://fundfilter.com/faq/">FAQ</ExtLink> for more
                      detail.
                    </p>

                    <p>
                      Our models are optimized for forecasting over 3 years as represented by the solid purple line. The
                      dotted purple line is a continuation of the forecast but is less reliable.
                    </p>

                    <p>
                      The forecast tool is aimed at only providing directional value to users of what might occur. These
                      models and/or tools are intended for informational use only, and you acknowledge and agree that
                      FundFilter or Altman Inc. cannot and does not guarantee any particular outcome or results, and
                      that past or historical results are not indicative of and do not guarantee future performance,
                      outcomes or results. Always obtain relevant and specific independent professional advice before
                      making any investment or other decision.{' '}
                      <ExtLink href="https://altman.ai/terms-of-use">Terms of Use.</ExtLink>
                    </p>

                    <p>The blue line represents actual history of the fund to date</p>
                  </div>

                  <div>
                    <LineLegend />
                    <span>Historial</span>
                  </div>

                  <div>
                    <LineLegend $forecast />
                    <span>3-year forecast</span>
                  </div>
                  <div>
                    <LineLegend $dotted $forecast />
                    <span>2-year extension</span>
                  </div>
                </div>
              )
            }
          >
            <div
              css={`
                cursor: help;
              `}
              onClick={handleTooltipOpen}
            >
              <IconInfoOutlined width="0.75em" height="0.75em" />
            </div>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Layout>
  );
}

ViewForecastCheckbox.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => {},
  isLoading: false,
  isMatureFund: false,
  size: 'medium',
};

ViewForecastCheckbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isMatureFund: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default ViewForecastCheckbox;
