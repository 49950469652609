import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setFundId } from 'src/store/last-selected';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { useFund, useFundFinancialHistory } from 'src/hooks';
import { lookup } from 'src/lookup';
import { ButtonRounded, FundFinancialHistoryTimeSeries, FinancialMetricSelection } from 'src/components';

const Header = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(ButtonRounded)`
  min-width: unset;
  margin-right: ${({ theme }) => `${theme.spacing(0.5)}px`};
`;

function TimeSeriesView({ fundId }) {
  const dispatch = useDispatch();
  const fund = useFund({ fundId });
  const fundHistory = useFundFinancialHistory({ fundId });

  const [financialMetric, setFinancialMetric] = useState(lookup.financialMetric.tvpi);

  const [fundFinancialHistoryTimeSeries, setFundFinancialHistoryTimeSeries] = useState(null);

  const getDefaultTimeRange = useCallback(
    function () {
      if (!fund) return lookup.timeRange.oneQuarter;
      if (!fund.financialHistory) return lookup.timeRange.oneQuarter;
      if (fund.financialHistory.length < 3) return lookup.timeRange.oneQuarter;
      if (fund.financialHistory.length < 4) return lookup.timeRange.threeQuarters;
      return lookup.timeRange.oneYear;
    },
    [fund]
  );

  const [timeRange, setTimeRange] = useState(null);

  useEffect(
    function () {
      if (!fundHistory) return;
      if (!fundHistory.fetched) return;
      if (!fundHistory.items) return;

      setFundFinancialHistoryTimeSeries(fundHistory.items.slice().reverse());
    },
    [fundHistory]
  );

  useEffect(() => {
    if (!fund) return;
    if (fund.userId) return;

    dispatch(setFundId({ fundId }));
  }, [fund, fundId, dispatch]);

  useEffect(() => {
    if (!fund) return;
    if (!fund.vintage) return;
    if (fund.vintage < new Date().getUTCFullYear() - 3) return;

    setFinancialMetric(lookup.financialMetric.tvpi);
  }, [fund, fundId, dispatch]);

  const handleTimeRangeChange = useCallback(function (value) {
    return function (_event) {
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundTimeRange);
      setTimeRange(value);
    };
  }, []);

  const handleMetricChange = useCallback(function handleMetricChange(metric) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundMetric);
    setFinancialMetric(metric);
  }, []);

  if (!fund) return null;
  if (!fundFinancialHistoryTimeSeries) return null;

  return (
    <>
      <Header>
        <div>
          {Object.values(lookup.timeRange).map(tr => (
            <Button
              key={tr.value}
              selected={tr === (timeRange || getDefaultTimeRange())}
              onClick={handleTimeRangeChange(tr)}
            >
              {tr.label}
            </Button>
          ))}
        </div>
        {fund.vintage < new Date().getUTCFullYear() - 3 ? (
          <FinancialMetricSelection financialMetric={financialMetric} onMetricChange={handleMetricChange} hidePme />
        ) : (
          financialMetric.label.toUpperCase()
        )}
      </Header>
      {fundFinancialHistoryTimeSeries && (
        <FundFinancialHistoryTimeSeries
          data={fundFinancialHistoryTimeSeries}
          isUserFund={!!fund.userId}
          metric={financialMetric}
          timeRange={timeRange || getDefaultTimeRange()}
          css={`
            flex: 1;
          `}
        />
      )}
    </>
  );
}

TimeSeriesView.defaultProps = {};

TimeSeriesView.propTypes = {
  fundId: PropTypes.string.isRequired,
};

export default TimeSeriesView;
