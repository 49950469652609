import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UserFundOrManagerMessage } from 'src/components';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

function UserFundManagerTeamVisualizationFundManager({ fundManager }) {
  if (!fundManager) return null;

  return (
    <Layout>
      <UserFundOrManagerMessage label="fund manager" />
    </Layout>
  );
}

UserFundManagerTeamVisualizationFundManager.defaultProps = {};

UserFundManagerTeamVisualizationFundManager.propTypes = {
  fundManager: PropTypes.object.isRequired,
};

export default UserFundManagerTeamVisualizationFundManager;
