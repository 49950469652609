import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { IconClose } from 'src/components';

const Button = styled(IconButton)`
  .MuiSvgIcon-root {
    font-size: 1.5em;
  }
`;

function ButtonDialogClose({ onClick }) {
  return (
    <Button onClick={onClick} color="inherit" aria-label="close">
      <IconClose />
    </Button>
  );
}

export default ButtonDialogClose;
