import React from 'react';
import { useMemo, useCallback } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import styled from 'styled-components';
import { Checkbox as MuiCheckbox, TextField, InputAdornment, Button } from '@material-ui/core';
import { sm, md } from 'src/config/layout';
import {
  useUpdateBookmarks,
  useUpdateBookmark,
  useRemoveBookmark,
} from 'src/services/filter-api/userPortfolios/mutations';
import {
  NumberField,
  IconCashflows,
  IconClose,
  FundBookmarkControl,
  UserFundIndicator,
  FundLink,
} from 'src/components';
import { useDelete as useDeleteTargetFund } from 'src/services/filter-api/userTargetFunds/mutations';

const GridList = styled.div`
  display: grid;
  align-items: center;
  font-size: 0.6875rem;

  grid-template-columns:
    max-content
    max-content
    minmax(20ch, 3fr)
    /* minmax(8ch, 1fr) */
    /* minmax(16ch, 1fr) */
    minmax(18ch, 1fr)
    max-content;

  gap: 0.375em;

  .cell:nth-child(8n),
  .cell:nth-child(8n - 3),
  .cell:nth-child(8n - 4) {
    display: none;
  }

  @media (min-width: ${sm.BREAKPOINT}px) {
    grid-template-columns:
      max-content
      max-content
      minmax(20ch, 3fr)
      minmax(8ch, 1fr)
      minmax(16ch, 1fr)
      minmax(18ch, 1fr)
      max-content
      max-content;

    .cell:nth-child(8n),
    .cell:nth-child(8n - 3),
    .cell:nth-child(8n - 4) {
      display: flex;
    }
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    grid-template-columns:
      max-content
      max-content
      minmax(28ch, 3fr)
      minmax(8ch, 1fr)
      minmax(16ch, 1fr)
      minmax(18ch, 1fr)
      max-content
      max-content;

    gap: 0.375em 1em;

    .cell:nth-child(8n),
    .cell:nth-child(8n - 3),
    .cell:nth-child(8n - 4) {
      display: flex;
    }
  }
`;

const Hr = styled.hr`
  border: 0;
  height: 1px;
  background-color: #ffffff33;

  margin: 0.5em 0;
  grid-column: 1 / -1;
`;

const EmptyCell = styled.span`
  display: none;
`;

const Cell = styled.div.attrs({ className: 'cell' })`
  display: flex;
  visibility: ${p => p.$display === false && 'hidden'};
  align-items: center;
  justify-content: flex-start;
  justify-content: ${p => p.$justify};

  padding: 0px;
  opacity: ${p => (p.$hidden ? 0.5 : 1)};
`;

const Header = styled(Cell)`
  text-transform: uppercase;
  font-size: 0.8em;
  padding-top: 0;
  padding-bottom: 0;
  color: #888;
`;

const Checkbox = styled(MuiCheckbox)`
  font-size: 1.5em;
  padding: 0;

  .MuiSvgIcon-root {
    font-size: 1em;
  }
`;

const Text = styled.span`
  color: #bbb;
`;

const CellButton = styled(Button)`
  min-width: 1em;
  font-size: 1.25em;
  padding: 4px;
`;

const DeleteButton = styled(CellButton)`
  font-size: 1em;
  color: #bbb;
  svg {
    font-size: inherit;
  }
`;

function ListItem({ item, portfolioId, onClickViewItem, isFetching }) {
  const { hidden, bookmarkId } = item;
  const isUserFund = (item.fundId || '').startsWith('u_');
  const updateBookmark = useUpdateBookmark();
  const removeBookmark = useRemoveBookmark().mutateAsync;
  const deleteTargetFund = useDeleteTargetFund().mutateAsync;
  const [isDeleting, setIsDeleting] = React.useState(false);

  function handleChangeHidden(event) {
    updateBookmark.mutate({
      portfolioId,
      bookmarkId,
      hidden: !event.target.checked,
    });
  }

  function handleChangeCommitmentAmount(event) {
    const value = event.target.value.replace(/,/g, '');
    const commitmentAmount = Number.parseInt(value, 10);
    updateBookmark.mutate({
      portfolioId,
      bookmarkId,
      commitmentAmount,
    });
  }

  async function handleDelete() {
    const { targetFundId } = item;
    if (!targetFundId) return;
    if (isDeleting) return;
    setIsDeleting(true);
    await removeBookmark({ portfolioId, targetFundId });
    await deleteTargetFund(targetFundId);
    setIsDeleting(false);
  }

  const isMutating = useMemo(() => {
    const { isError, isIdle, isPaused, isSuccess } = updateBookmark;
    if (updateBookmark.isLoading) return true;
    return !(isError || isSuccess || isPaused || isIdle);
  }, [updateBookmark]);

  return (
    <>
      <Cell $hidden={hidden} $justify="center">
        <Checkbox color="default" checked={!hidden} onChange={handleChangeHidden} />
      </Cell>

      <Cell $hidden={hidden} $justify="center">
        <CellButton onClick={() => onClickViewItem(item)}>
          <IconCashflows
            css={`
              font-size: 1em;
            `}
          />
        </CellButton>
      </Cell>

      <Cell $hidden={hidden}>
        <Text
          css={`
            display: flex;
          `}
        >
          {item.fundId ? <FundLink fund={item}>{item.name}</FundLink> : item.name}
          {isUserFund && <UserFundIndicator />}
        </Text>
      </Cell>

      <Cell $hidden={hidden} $justify="center">
        <Text>{item.vintage}</Text>{' '}
      </Cell>

      <Cell $hidden={hidden}>
        <Text>{item.strategy}</Text>{' '}
      </Cell>

      <Cell $hidden={hidden}>
        <TextField
          css={`
            .MuiInputBase-root {
              font-size: inherit;
            }
            .MuiInputBase-input,
            .MuiInputAdornment-root .MuiTypography-root {
              padding: 4px 0px;
              font-size: 1em;
              background-color: transparent;
            }
          `}
          placeholder="1,000,000"
          value={item.commitmentAmount}
          onBlur={handleChangeCommitmentAmount}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            disabled: isMutating,
            inputComponent: NumberField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Cell>

      <Cell $hidden={hidden} $display={!item.targetFundId}>
        <FundBookmarkControl fundId={item.fundId} title={item.name} targetFundId={item.targetFundId} />
      </Cell>

      <Cell $hidden={hidden} $display={Boolean(item.targetFundId)}>
        <DeleteButton
          disabled={isDeleting || isFetching || isMutating}
          onClick={handleDelete}
          css={`
            font-size: 0.8em;
          `}
        >
          <IconClose />
        </DeleteButton>
      </Cell>
    </>
  );
}

function ForecastItemsList({ items, portfolioId, onClickViewItem, className }) {
  const checkboxChecked = items.every(item => !item.hidden);
  const checkboxIndeterminate = items.some(item => item.hidden) && items.some(item => !item.hidden);

  const updateBookmarks = useUpdateBookmarks().mutate;

  const handleChangeHideAll = useCallback(
    event => {
      const bookmarkIds = items.map(ci => ci.bookmarkId);

      updateBookmarks({
        bookmarkIds,
        portfolioId,
        hidden: !event.target.checked,
      });
    },
    [items, portfolioId, updateBookmarks]
  );

  return (
    <GridList className={className}>
      <Header
        $justify="center"
        css={`
          font-size: 1em;
        `}
      >
        <Checkbox
          color="default"
          checked={checkboxChecked}
          indeterminate={checkboxIndeterminate}
          onChange={handleChangeHideAll}
        />
      </Header>
      <Header>&nbsp;</Header>
      <Header>Name</Header>

      <Header $justify="center">Vintage</Header>

      <Header>Stragegy</Header>

      <Header>Commitment</Header>

      <Header>&nbsp;</Header>
      <Header>&nbsp;</Header>

      <Hr />
      <EmptyCell />
      <EmptyCell />
      <EmptyCell />
      <EmptyCell />
      <EmptyCell />
      <EmptyCell />
      <EmptyCell />

      {items.map(item => {
        return (
          <ListItem key={item.bookmarkId} portfolioId={portfolioId} item={item} onClickViewItem={onClickViewItem} />
        );
      })}
    </GridList>
  );
}

ForecastItemsList.defaultProps = {
  items: [],
  hiddenItems: [],
  onClickAddFund: funcStub('onClickAddFund'),
  onChangeItemHidden: funcStub('onChangeItemHidden'),
  onClickEditItem: funcStub('ForecastItemsList.onClickEditItem'),
  onChangeItemCommitmentAmount: funcStub('onChangeItemCommitmentAmount'),
};

ForecastItemsList.propTypes = {
  items: PropTypes.array,
  onClickAddFund: PropTypes.func,
  onChangeItemHidden: PropTypes.func,
  onClickEditItem: PropTypes.func,
  onChangeItemCommitmentAmount: PropTypes.func,
};

export default ForecastItemsList;
