import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconFunnel(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M54.932,3.03C30.881,1.587,7.793,6.768,3.373,14.587c-0.975,1.723-0.975,3.484,0,5.207l31.311,39.072  c1.333,1.658,2.054,3.725,2.054,5.85v32.156c0,1.293,1.053,2.34,2.34,2.34c0.461,0,0.917-0.137,1.3-0.397l14.651-9.763  c1.3-0.871,2.08-2.333,2.08-3.894V64.715c0-2.125,0.728-4.191,2.054-5.85l31.317-39.072C94.9,11.974,78.988,4.467,54.932,3.03z   M46.923,26.125c-23.277,0-37.57-5.376-37.57-9.23c0-3.852,14.293-9.224,37.57-9.224c23.282,0,37.577,5.372,37.577,9.224  C84.5,20.749,70.205,26.125,46.923,26.125z" />
    </Svg>
  );
}
