import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { splitBreakpoint, md, xl } from 'src/config/layout';
import { Responsive, FundManagerTeamTable, FundManagerTeamHierarchy, ButtonArrowRight } from 'src/components';
import { TABLE_WIDTH } from './constants';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const TableSection = styled.div`
  position: relative;
  width: 100%;
  transition: width ease 300ms;

  @media (min-width: ${splitBreakpoint(md, xl)}px) {
    width: ${p => (p.$wide ? TABLE_WIDTH.wide : TABLE_WIDTH.narrow)};
  }
`;

const OrgWheelSection = styled.div`
  display: none;
  flex: 1;
  position: relative;

  @media (min-width: ${splitBreakpoint(md, xl)}px) {
    display: unset;
  }
`;

const Arrow = styled(ButtonArrowRight)`
  display: none;
  position: absolute;
  top: 4px;
  right: -5px;
  z-index: 1;
  color: #000;
  background-color: #e1b72a;

  &:hover {
    background-color: #ffcf2d;
  }

  @media (min-width: ${splitBreakpoint(md, xl)}px) {
    display: unset;
  }
`;

const Empty = styled.div`
  height: ${props => props.height || '100%'};
  display: grid;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;

  & > div {
    max-width: 300px;
  }
`;

function FundManagerTeamVisualizationFundManager({
  fundManager,
  fundManagerEmployees,
  fundManagerTeamHierarchy,
  wideTable,
  handleWideTableToggle,
  tableEmployees,
  handleTableRowClick,
  handleTableRowMouseOver,
  handleTableRowMouseOut,
  handleTableColumnClick,
  handleTableListDefCreated,
  expanded,
  tableTooltipOpen,
  tableTooltipData,
  tableTooltipAnchorEl,
  handleSvgClick,
  handleHierarchyItemClick,
}) {
  if (!fundManager) return null;

  const empty = fundManagerEmployees && fundManagerEmployees.length < 1;

  return (
    <Layout>
      {fundManagerEmployees && (
        <TableSection $wide={!empty && wideTable && expanded} $expanded={expanded}>
          {empty ? (
            <Empty>
              <div>
                <p>There is currently no team information available for this fund manager</p>
              </div>
            </Empty>
          ) : (
            <>
              <Arrow flipped={wideTable} $expanded={expanded} onClick={handleWideTableToggle} />
              <Responsive.OnAtLeastLaptop>
                <FundManagerTeamTable
                  data={tableEmployees}
                  expanded={wideTable}
                  tooltipOpen={tableTooltipOpen}
                  tooltipData={tableTooltipData}
                  tooltipAnchorEl={tableTooltipAnchorEl}
                  onRowClick={handleTableRowClick}
                  onRowMouseOver={handleTableRowMouseOver}
                  onRowMouseOut={handleTableRowMouseOut}
                  onColumnClick={handleTableColumnClick}
                  onListDefCreated={handleTableListDefCreated}
                />
              </Responsive.OnAtLeastLaptop>
              <Responsive.OnAtMostLaptop>
                <FundManagerTeamTable
                  expanded
                  data={tableEmployees}
                  tooltipOpen={tableTooltipOpen}
                  tooltipData={tableTooltipData}
                  tooltipAnchorEl={tableTooltipAnchorEl}
                  onRowClick={handleTableRowClick}
                  onRowMouseOver={handleTableRowMouseOver}
                  onRowMouseOut={handleTableRowMouseOut}
                  onColumnClick={handleTableColumnClick}
                  onListDefCreated={handleTableListDefCreated}
                />
              </Responsive.OnAtMostLaptop>
            </>
          )}
        </TableSection>
      )}
      {fundManagerTeamHierarchy && !wideTable && (
        <Responsive.OnAtLeastLaptop>
          <OrgWheelSection>
            <FundManagerTeamHierarchy
              teamHierarchy={fundManagerTeamHierarchy}
              expanded={expanded}
              onClick={handleSvgClick}
              onItemClick={handleHierarchyItemClick}
              id="fundManagerTeamHierarchy"
              css={`
                width: 100%;
                height: 100%;
              `}
            />
          </OrgWheelSection>
        </Responsive.OnAtLeastLaptop>
      )}
    </Layout>
  );
}

FundManagerTeamVisualizationFundManager.defaultProps = {};

FundManagerTeamVisualizationFundManager.propTypes = {
  children: PropTypes.any,
};

export default FundManagerTeamVisualizationFundManager;
