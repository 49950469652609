import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconSlack(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <g>
        <path d="M88.21,47.745l-9.597-23.167c-4.974-12.005-18.86-17.76-30.869-12.79l-23.167,9.6C12.571,26.361,6.816,40.251,11.79,52.257  l9.597,23.17C26.36,87.432,40.251,93.184,52.256,88.21l23.171-9.597C87.429,73.64,93.184,59.753,88.21,47.745z M72.874,72.455  l-23.167,9.596c-2.058,0.85-4.219,1.283-6.433,1.283c-6.92,0-13.099-4.105-15.729-10.459l-9.597-23.167  c-1.726-4.164-1.716-8.77,0.026-12.972c1.738-4.203,4.99-7.464,9.15-9.189l23.17-9.6c2.051-0.85,4.213-1.279,6.423-1.279  c6.927,0,13.103,4.105,15.735,10.462l9.597,23.164C85.628,58.937,81.514,68.874,72.874,72.455z" />
        <path d="M75.56,52.042l-2.552-6.16l-10.319,4.274l-3.824-9.238l10.315-4.277l-2.552-6.159l-10.313,4.274L52.041,24.44l-6.159,2.552  l4.274,10.315l-9.238,3.825l-4.274-10.313l-6.158,2.549l4.273,10.315L24.44,47.959l2.552,6.159l10.319-4.274l3.824,9.239  l-10.318,4.273l2.552,6.159l10.319-4.274l4.273,10.315l6.159-2.549l-4.274-10.318l9.235-3.825l4.271,10.319l6.162-2.553  l-4.273-10.315L75.56,52.042z M47.295,56.53l-3.825-9.238l9.235-3.825l3.825,9.242L47.295,56.53z" />
      </g>
    </Svg>
  );
}
