import styled from 'styled-components';

const ModalActions = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--modal-padding) var(--modal-padding);
  padding-top: 0;
`;

export default ModalActions;
