import React from 'react';
import { useCallback, useState, useEffect, useRef } from 'react';
import { usageTrackingChannel, USAGE_TRACKING_TOPICS } from 'src/services';
import { AppPanel, ButtonFilters, IconList, IconScatterPlot, FundPerformanceVisualization } from 'src/components';
import { filtering } from 'src/config/tutorials';

const VIEWS = {
  plot: 'plot',
  list: 'list',
};

function MarketFilterFundPerformancePanel({ className, spanX, spanY, minHeight }) {
  const [view, setView] = useState(VIEWS.plot);
  const [showFilterControls, setShowFilterControls] = useState(false);
  const forceTooltipsClose = useRef();

  const handleFilterClick = useCallback(() => {
    setShowFilterControls(!showFilterControls);
  }, [showFilterControls]);

  useEffect(
    function () {
      if (!showFilterControls) return;

      forceTooltipsClose.current = Date.now();
      usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterFundPerformanceFilter);
    },
    [showFilterControls]
  );

  useEffect(
    function () {
      usageTrackingChannel.publish(
        view === VIEWS.list
          ? USAGE_TRACKING_TOPICS.marketFilterFundPerformanceList
          : USAGE_TRACKING_TOPICS.marketFilterFundPerformancePlot
      );
    },
    [view]
  );

  return (
    <AppPanel
      data-cy="market-filter-fund-performance-panel"
      className={className}
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title="Fund Performance"
      controls={
        <>
          <div>
            <AppPanel.Button aria-label="filters" as={ButtonFilters} onClick={handleFilterClick} />
          </div>
          <div>
            <AppPanel.Button
              aria-label="plot"
              startIcon={<IconScatterPlot />}
              selected={view === VIEWS.plot}
              onClick={() => setView(VIEWS.plot)}
            >
              Plot
            </AppPanel.Button>
            <AppPanel.Button
              aria-label="list"
              startIcon={<IconList />}
              selected={view === VIEWS.list}
              onClick={() => setView(VIEWS.list)}
            >
              List
            </AppPanel.Button>
          </div>
        </>
      }
      tutorial={filtering}
      expandable
    >
      <FundPerformanceVisualization
        showListView={view === VIEWS.list}
        showPlotView={view === VIEWS.plot}
        showFilterControls={showFilterControls}
        setShowFilterControls={setShowFilterControls}
        forceTooltipsClose={forceTooltipsClose}
      />
    </AppPanel>
  );
}

MarketFilterFundPerformancePanel.defaultProps = {};

MarketFilterFundPerformancePanel.propTypes = {};

export default MarketFilterFundPerformancePanel;
