import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { TooltipArrowRight, TooltipContent, FundPreview } from 'src/components';

const TooltipWrapper = styled.div``;

function PeerGroupScatterTooltip({ className, fund, open, anchorEl, onClickAway, onClose, placement, offset }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        className={className}
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement={placement}
        modifiers={{
          offset: {
            enabled: Boolean(offset),
            offset,
          },
          arrow: {
            enabled: true,
            element: '.funds-raised-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <TooltipWrapper>
              <TooltipArrowRight className="funds-raised-arrow" marginLeft="2px" />
              <TooltipContent>
                <FundPreview fund={fund} onClose={onClose} />
              </TooltipContent>
            </TooltipWrapper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
PeerGroupScatterTooltip.defaultProps = {
  fund: null,
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewFund: () => {},
  placement: 'left',
  offset: '0,15',
};

PeerGroupScatterTooltip.propTypes = {
  fund: PropTypes.object,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
  onViewFund: PropTypes.func,
  placement: PropTypes.string,
  offset: PropTypes.string,
};

export default PeerGroupScatterTooltip;
