import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconBook(props) {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <g>
        <path d="M16,30.86l-1.29-.74A25.66,25.66,0,0,0,2,26.75H1.25V1.25H2A27.16,27.16,0,0,1,15.45,4.82l.55.31.55-.31A27.16,27.16,0,0,1,30,1.25h.75v25.5H30a25.66,25.66,0,0,0-12.71,3.37ZM2.75,25.26a27.16,27.16,0,0,1,12.7,3.56l.55.31.55-.31a27.16,27.16,0,0,1,12.7-3.56V2.76a25.67,25.67,0,0,0-12,3.36L16,6.86l-1.29-.74a25.67,25.67,0,0,0-12-3.36Z" />
        <rect x="15.25" y="6" width="1.5" height="24" />
      </g>
    </Svg>
  );
}
