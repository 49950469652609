import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { min, max } from 'd3';
import { uniqueId, getRecentQuarter } from 'src/utils';
import { valueChange } from 'src/calculators';
import { useMouseEventRefs } from 'src/hooks';
import { AppPanel } from 'src/components';
import draw from './draw';
import { baseStyles, compactStyles, expandedStyles } from './styles';

const COLUMNS = 4;
const ROWS = 4;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${COLUMNS}, minmax(0, 1fr));
  grid-template-rows: repeat(${ROWS}, minmax(0, 1fr));
  gap: 4px;

  padding: 0 10px;
  padding-bottom: 10px;
  flex: 1;

  ${baseStyles}
  ${p => (p.isExpanded ? expandedStyles : compactStyles)};
`;

function getEndItem(series) {
  const endDate = max(series, d => d.date);
  const endItem = series.find(d => d.date === endDate);
  return endItem;
}

function getStartItem(series, endItem, monthsAgo) {
  const nQuarters = Math.floor(monthsAgo / 3);
  const startDate = getRecentQuarter(nQuarters)(endItem.date);
  const range = series.filter(d => d.date >= startDate.toISOString());
  const startItem = series.find(d => d.date === min(range, n => n.date));
  return startItem;
}

function getSeriesExtent(series, monthsAgo) {
  const endItem = getEndItem(series);
  const startItem = getStartItem(series, endItem, monthsAgo);
  return [startItem, endItem];
}

function dataMapper(metric, timeRange, series) {
  return function (datum) {
    const [start, end] = getSeriesExtent(datum[series], timeRange.value);
    const startValue = start[metric.key] || NaN;
    const endValue = end[metric.key] || NaN;

    return {
      ...datum,
      delta: valueChange(endValue)(startValue),
    };
  };
}

function StrategyPerformanceGrid({
  strategies,
  metric,
  timeRange,
  expanded: _expanded,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  customSort,
  ...rest
}) {
  const id = useRef(uniqueId());
  const [data, setData] = useState(null);
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  const panelContext = useContext(AppPanel.Context);

  useEffect(() => {
    const mappedData = strategies
      .map(dataMapper(metric, timeRange, 'all'))
      .filter(d => d.subStrategy !== 'Real Asset Debt')
      .sort((a, b) => {
        if (customSort === 'asc') return a.delta - b.delta;
        if (customSort === 'desc') return b.delta - a.delta;
        return true;
      });

    setData(mappedData);
  }, [strategies, timeRange, metric, customSort]);

  useEffect(() => {
    if (!data) return;

    draw({
      id: id.current,
      data,
      mouseEventRefs,
    });
  }, [data, mouseEventRefs]);

  return <Container id={id.current} isExpanded={panelContext.isExpanded} {...rest} />;
}

StrategyPerformanceGrid.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
  targetRef: { current: null },
};

StrategyPerformanceGrid.propTypes = {
  strategies: PropTypes.array.isRequired,
  metric: PropTypes.object.isRequired,
  timeRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default StrategyPerformanceGrid;
