import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { valueOf } from './utils';
import { IconSortDown, IconSortUp } from '../..';
import { table } from 'src/config/colors';

const iconStyles = css`
  position: relative;
  top: 2px;
  height: 10px;
  width: 10px;
  margin-left: 2px;
`;

const SortedUp = styled(IconSortUp)`
  ${iconStyles};
`;

const SortedDown = styled(IconSortDown)`
  ${iconStyles};
`;

const HeaderRow = styled.div.attrs(props => ({
  style: { width: props.$width },
}))`
  display: flex;
  line-height: 2.6;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  overflow: hidden;

  color: ${table.header.TEXT_COLOR};
  background-color: ${table.header.BACKGROUND_COLOR};
`;

const HeaderCell = styled.div`
  flex: ${props => props.flex};
  font-size: 10px;
`;

const HeaderLabel = styled.span`
  cursor: ${props => (props.sortable ? 'pointer' : null)};
  &:hover {
    color: ${props => (props.sortable ? '#cacaca' : null)};
  }
  transition: color 300ms ease;
`;

function Header(props) {
  const { width, sortField, handleSort, listDef, className } = props;

  return (
    <HeaderRow className={className} $width={width}>
      {listDef.map(column => (
        <HeaderCell
          key={column.key}
          flex={`0 0 ${column.width}px`}
          style={valueOf(column.headerStyle)}
          className={`vt-head ${column.key}`}
        >
          <HeaderLabel
            sortable={column.sortable}
            onClick={() =>
              column.sortable &&
              handleSort({
                property: column.property,
                isNumeric: column.isNumeric,
              })
            }
          >
            {column.label}
            {column.property && sortField && sortField.property === column.property && sortField.isDescending && (
              <SortedDown />
            )}
            {column.property && sortField && sortField.property === column.property && !sortField.isDescending && (
              <SortedUp />
            )}
          </HeaderLabel>
        </HeaderCell>
      ))}
    </HeaderRow>
  );
}

Header.defaultProps = {
  sortField: null,
};

Header.propTypes = {
  width: PropTypes.number.isRequired,
  sortField: PropTypes.object,
  handleSort: PropTypes.func.isRequired,
  listDef: PropTypes.array.isRequired,
};

export default Header;
