import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import { useDefaultPaymentMethod } from 'src/hooks';
import { Button, IconAdd, IconPencil } from 'src/components';
import FormSubmitButton from './FormSubmitButton';
import FormCancelButton from './FormCancelButton';
import ConfirmPasswordDialog from './ConfirmPasswordDialog';
import CreditCard from './CreditCard';
import Skeleton from '@material-ui/lab/Skeleton';

function PaymentMethods({ onClickNew, onClickCancel }) {
  const [isEditing, setIsEditing] = useState(false);
  const [requirePassword, setRequirePassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    data: paymentMethod,
    isLoading,
    delete: deletePaymentMethod,
    refetch: refetchDefaultPaymentMethod,
  } = useDefaultPaymentMethod();

  if (isLoading) {
    return (
      <section>
        <Skeleton variant="text" />
        <span />
        <Skeleton variant="rect" height={162} />
        <span />
      </section>
    );
  }

  return (
    <>
      <section>
        <header>
          <h3>Payment Methods</h3>
          {paymentMethod?.card ? (
            <Button size="small" variant="outlined" disabled={isEditing} onClick={() => setIsEditing(state => !state)}>
              <IconPencil />
              Edit
            </Button>
          ) : (
            <Button size="small" variant="outlined" disabled={isEditing} onClick={onClickNew}>
              <IconAdd />
              Add
            </Button>
          )}
        </header>
        <span />

        <div>
          <Collapse in={Boolean(paymentMethod)}>
            <CreditCard
              card={paymentMethod?.card}
              css={`
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
              `}
            />
          </Collapse>
          <Collapse in={!paymentMethod}>
            <p>You haven&apos;t added a payment method yet</p>
          </Collapse>
        </div>

        <span />
        <footer>
          <FormSubmitButton
            color="danger"
            isSubmitting={isSubmitting}
            disabled={!isEditing || !paymentMethod}
            onClick={() => {
              setRequirePassword(true);
              setIsSubmitting(true);
            }}
          >
            Remove Card
          </FormSubmitButton>

          <FormCancelButton
            disabled={!isEditing}
            onClick={() => {
              onClickCancel();
              setIsEditing(false);
            }}
          />
        </footer>
      </section>

      <ConfirmPasswordDialog
        open={requirePassword}
        onSuccess={async () => {
          await deletePaymentMethod();
          await refetchDefaultPaymentMethod();
          setRequirePassword(false);
          setIsEditing(false);
          setIsSubmitting(false);
        }}
        onFailure={({ reason }) => {
          if (reason === 'user-canceled') setRequirePassword(false);
          setIsSubmitting(false);
        }}
      />
    </>
  );
}

PaymentMethods.defaultProps = {
  onClickNew: () => {},
  onClickCancel: () => {},
};

PaymentMethods.propTypes = {
  onClickNew: PropTypes.func,
  onClickCancel: PropTypes.func,
};

export default PaymentMethods;
