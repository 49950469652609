import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconCopy(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <g>
        <path d="M87.5,2.5H28.7c-1.8,0-3.2,1.4-3.2,3.2v13h-13c-1.8,0-3.2,1.4-3.2,3.2v72.5c0,1.8,1.4,3.2,3.2,3.2h58.9     c1.8,0,3.2-1.4,3.2-3.2v-13h13c1.8,0,3.2-1.4,3.2-3.2V5.7C90.7,3.9,89.3,2.5,87.5,2.5z M68.2,91.2H15.7V25h9.8v53.2     c0,1.8,1.4,3.2,3.2,3.2h39.5V91.2z M84.3,75H31.8V8.8h52.5V75z" />
        <path d="M40.7,24.3h34.9c0.4,0,0.7-0.3,0.7-0.7v-4.9c0-0.4-0.3-0.7-0.7-0.7H40.7c-0.4,0-0.7,0.3-0.7,0.7v4.9     C39.9,24,40.3,24.3,40.7,24.3z" />
        <path d="M40.7,38.2h34.9c0.4,0,0.7-0.3,0.7-0.7v-4.9c0-0.4-0.3-0.7-0.7-0.7H40.7c-0.4,0-0.7,0.3-0.7,0.7v4.9     C39.9,37.8,40.3,38.2,40.7,38.2z" />
        <path d="M40.7,65.9h34.9c0.4,0,0.7-0.3,0.7-0.7v-4.9c0-0.4-0.3-0.7-0.7-0.7H40.7c-0.4,0-0.7,0.3-0.7,0.7v4.9     C39.9,65.5,40.3,65.9,40.7,65.9z" />
        <path d="M40.7,52h34.9c0.4,0,0.7-0.3,0.7-0.7v-4.9c0-0.4-0.3-0.7-0.7-0.7H40.7c-0.4,0-0.7,0.3-0.7,0.7v4.9     C39.9,51.7,40.3,52,40.7,52z" />
      </g>
    </Svg>
  );
}
