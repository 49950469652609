import React from 'react';
import styled from 'styled-components';
import { button } from 'src/config/colors';
import { xl } from 'src/config/layout';
import Button from '../Button';
import IconAdd from '../IconAdd';

const ADJUSTED_TEXT_COLOR = button.accent.TEXT_COLOR + 'b0';

const Layout = styled(Button)`
  color: ${ADJUSTED_TEXT_COLOR};
  background-color: ${button.accent.BACKGROUND_COLOR};
  font-weight: 900;
  font-size: 1rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  /* .MuiButton-root {
  } */

  &:hover,
  &:active,
  &:focus {
    color: ${button.accent.active.TEXT_COLOR};
    background-color: ${button.accent.active.BACKGROUND_COLOR};
  }

  svg {
    stroke-width: 2.5em;
  }

  min-width: ${p => !p.$hasText && '2em'};
`;

const Label = styled.span`
  display: none;

  @media (min-width: ${xl.BREAKPOINT}px) {
    display: contents;
  }
`;

function ButtonAddFund({ label, className, onClick }) {
  return (
    <Layout $hasText={Boolean(label)} className={className} onClick={onClick} aria-label="Add Fund">
      <IconAdd />
      <Label>{label}</Label>
    </Layout>
  );
}

ButtonAddFund.defaultProps = {
  label: 'Add a Fund',
};

export default ButtonAddFund;
