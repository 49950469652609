import { db } from 'src/config/firebase';

const collection = db.collection('strategyTimeseries');

async function getStrategyTimeseries(docId) {
  const doc = await collection.doc(docId).get();
  if (!doc.exists) return null;
  return doc.data();
}

export { getStrategyTimeseries };
