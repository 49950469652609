function clamp(min, val, max) {
  return Math.min(Math.max(min, val), max);
}

function sum(arr, { key, initialValue = 0 } = {}) {
  return arr.reduce((acc, item) => {
    if (!key) return acc + item;
    return acc + item[key];
  }, initialValue);
}

function cumulativeSum(items) {
  return items.reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []);
}

function randomBetween(min, max) {
  return Math.random() * (max - min) + min;
}

const math = {
  clamp,
  cumulativeSum,
  randomBetween,
  sum,
};

export { clamp };
export { cumulativeSum };
export { randomBetween };
export { sum };
export default math;
