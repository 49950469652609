import React from 'react';
import { useEffect, useCallback, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useList as useListPortfolios } from 'src/services/filter-api/userPortfolios/queries';
import {
  useCreate as useCreatePortfolio,
  useUpdate as useUpdatePortfolio,
  useDelete as useDeletePortfolio,
} from 'src/services/filter-api/userPortfolios/mutations';
import Component from './Component';

function reducer(state, action) {
  const isSelected = state.includes(action.payload);
  const execSet = () => action.payload;
  const execRemove = () => state.filter(id => id !== action.payload);
  const execAdd = () => [...state, action.payload];

  switch (action.type) {
    case 'set':
      if (isSelected) return state;
      return execSet();

    case 'update':
      if (isSelected) return execRemove();
      return execAdd();

    case 'add':
      return execAdd();

    case 'remove':
      return execRemove();

    default:
      return;
  }
}

function addedItems(prev, next) {
  return next.filter(x => !prev.includes(x));
}

function removedItems(prev, next) {
  return prev.filter(x => !next.includes(x));
}

function PortfolioList({ value, onSelect, onDeselect, multiSelect }) {
  const { data: portfolios } = useListPortfolios();
  const createPortfolio = useCreatePortfolio().mutateAsync;
  const updatePortfolio = useUpdatePortfolio().mutateAsync;
  const deletePortfolio = useDeletePortfolio().mutateAsync;

  const [selectedItemIds, setSelectedItemIds] = useReducer(reducer, value);

  const prevSelection = useRef(selectedItemIds);

  const handleSelectItem = useCallback(
    portfolioId => {
      setSelectedItemIds({
        type: multiSelect ? 'update' : 'set',
        payload: multiSelect ? portfolioId : [portfolioId],
      });
    },
    [multiSelect]
  );

  useEffect(() => {
    const prev = prevSelection.current;
    const next = selectedItemIds;

    addedItems(prev, next).forEach(onSelect);
    removedItems(prev, next).forEach(onDeselect);

    prevSelection.current = next;
  }, [onDeselect, onSelect, selectedItemIds]);

  useEffect(() => {
    setSelectedItemIds({ type: 'set', payload: value });
  }, [value]);

  return (
    <Component
      multiSelect={multiSelect}
      items={portfolios}
      selectedItemIds={selectedItemIds}
      onSelectItem={handleSelectItem}
      onSaveItem={(portfolioId, data) => {
        return updatePortfolio({ portfolioId, ...data });
      }}
      onCreateItem={data => {
        return createPortfolio(data);
      }}
      onDeleteItem={portfolioId => {
        return deletePortfolio(portfolioId);
      }}
    />
  );
}

PortfolioList.defaultProps = {
  onSelect: console.info,
  onDeselect: console.info,
  multiSelect: PropTypes.false,
  value: [],
};

PortfolioList.propTypes = {
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  multiSelect: PropTypes.bool,
  value: PropTypes.array,
};

export default PortfolioList;
