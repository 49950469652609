import React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { cloneDeep } from 'src/lib/lodash';
import { sm, lg } from 'src/config/layout';
import { upperCase } from 'src/formatters';
import { parseISO, formatDurationToNowAbbreviated } from 'src/utils/date';
import { AppLayout, NewsSentiment, VirtualizedTable } from 'src/components';
import { table, link, button } from 'src/config/colors';

const defaultTableCss = css`
  .vt-head {
    color: ${table.header.TEXT_COLOR};
    background-color: ${table.header.BACKGROUND_COLOR};
  }

  .vt-row {
    color: ${table.row.TEXT_COLOR};
    background-color: ${table.row.BACKGROUND_COLOR};
    font-size: 9px;
    flex-wrap: wrap;
    padding: 5px;

    &:hover {
      color: ${button.accent.TEXT_COLOR};
      background-color: ${button.accent.BACKGROUND_COLOR};
    }

    &.-selected {
      .vt-cell.title {
        color: ${link.accent.TEXT_COLOR};
      }
    }
  }

  .vt-cell.title {
    color: ${link.accent.TEXT_COLOR};
    font-size: 13px;
  }

  .title {
    padding-left: 4px;
    padding-right: 12px;
    flex: 0 0 100%;
    order: 1;
  }

  .source {
    flex: 0 0 auto;
    order: 3;
    margin-right: 0.25em;
  }

  .publishedAt {
    flex: 1;
    text-align: left;
    order: 4;

    &::before {
      content: ' • ';
    }
  }

  .sentiment {
    flex: 0 0 15px;
    text-align: left;
    order: 2;
  }

  @media (min-width: ${sm.BREAKPOINT}px) {
    .title {
      padding-left: 4px;
      padding-right: 12px;
      flex: 1;
    }

    .source {
      flex: 0 0 100px;
    }

    .publishedAt {
      flex: 0 0 50px;
      text-align: center;
      &::before {
        content: unset;
      }
    }

    .sentiment {
      flex: 0 0 70px;
      text-align: center;
    }
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    .vt-row {
      &.-selected {
        color: ${button.accent.TEXT_COLOR};
        background-color: ${button.accent.BACKGROUND_COLOR};

        .vt-cell.title {
          color: inherit;
        }
      }
    }
  }
`;

function ago(date) {
  return upperCase(formatDurationToNowAbbreviated({ useSuffix: false })(parseISO(date)));
}
const formatTitle = text => `${text} »`;

const StyledVirtualizedTable = styled(VirtualizedTable)`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
`;

const listDefNarrow = [
  {
    sortable: true,
    formatter: formatTitle,
    property: 'title',
    key: 'title',
    label: 'FUND MANAGER NEWS',
    isNumeric: false,
  },
  {
    sortable: true,
    property: 'source',
    formatter: upperCase,
    key: 'source',
    label: 'SOURCE',
    isNumeric: false,
  },
  {
    sortable: true,
    property: 'publishedAt',
    key: 'publishedAt',
    formatter: ago,
    label: 'DATE',
    isNumeric: false,
  },
  {
    sortable: true,
    property: 'sentiment',
    key: 'sentiment',
    label: 'SENTIMENT',
    isNumeric: false,
    component: data => <NewsSentiment value={data.sentiment} style={{ justifyContent: 'center' }} />,
  },
];

const listDefWide = cloneDeep(listDefNarrow);
// listDefWide[0].width = 500;

function NewsTable({
  className,
  articles,
  expanded,
  selectedArticleId,
  onListDefCreated,
  onColumnClick,
  onRowClick,
  highlightSelectedArticle,
}) {
  const [listDef, setListDef] = useState(null);
  const [itemSize, setItemSize] = useState(52);
  const { width: layoutWidth } = useContext(AppLayout.Context);

  useEffect(
    function () {
      const size = layoutWidth < sm.BREAKPOINT ? 52 : 26;
      setItemSize(size);
    },
    [layoutWidth]
  );

  useEffect(() => {
    const listDefClone = cloneDeep(expanded ? listDefWide : listDefNarrow);
    // give the parent component a chance to update the listDef
    onListDefCreated(listDefClone);
    setListDef(listDefClone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, onListDefCreated]);

  const getRowClassName = useCallback(
    function getRowClassName(article) {
      if (!highlightSelectedArticle) return '';
      if (selectedArticleId !== article.articleId) return '';
      return '-selected';
    },
    [highlightSelectedArticle, selectedArticleId]
  );

  return (
    <StyledVirtualizedTable
      showHeader={itemSize < 52}
      itemSize={itemSize}
      className={className}
      css={defaultTableCss}
      data={articles}
      listDef={listDef}
      onColumnClick={onColumnClick}
      onRowClick={onRowClick}
      enableHover={true}
      rowClassName={getRowClassName}
    />
  );
}

NewsTable.defaultProps = {
  expanded: false,
  selectedArticle: null,
  enableHover: true,
  onListDefCreated: () => {},
  onColumnClick: () => {},
  onRowClick: () => {},
  highlightSelectedArticle: true,
};

NewsTable.propTypes = {
  articles: PropTypes.array.isRequired,
  selectedArticle: PropTypes.object,
  expanded: PropTypes.bool,
  enableHover: PropTypes.bool,
  onListDefCreated: PropTypes.func,
  onColumnClick: PropTypes.func,
  onRowClick: PropTypes.func,
  highlightSelectedArticle: PropTypes.bool,
};

export default React.memo(NewsTable);
