import React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import { pickValues } from 'src/utils/object';
import { Box, PeerGroupRadar, CheckboxSmall, IncompleteDataMessage } from 'src/components';

const MediansCheckBox = styled(CheckboxSmall)`
  margin-left: 5px;
  color: rgb(225, 183, 42);
`;

const MediansCheckboxLabel = styled(FormControlLabel)`
  font-size: 10px;
  .MuiFormControlLabel-label {
    margin-left: 5px;
    font-weight: 300;
    font-size: 12px;
    color: rgb(225, 183, 42);
  }
`;

function RadarChartSection({ className, metrics, narrowTable, showMedians, setShowMedians, localPeers }) {
  const insufficientData = useMemo(() => {
    if (!metrics) return false;
    if (!localPeers) return false;
    const subjectFund = localPeers.find(fund => fund.subjectFund);

    return pickValues('dpi', 'calledPct', 'tvpi', 'rvpi')(subjectFund).some(val => val === null);
  }, [localPeers, metrics]);

  return (
    <Box display="column" padding="0 24px" height="100%" className={className}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" height="26px">
        <div>{insufficientData && <IncompleteDataMessage entity="fund" />}</div>

        <MediansCheckboxLabel
          control={<MediansCheckBox checked={showMedians} /* color={color} */ />}
          label="Peer Group Median"
          onChange={() => setShowMedians(!showMedians)}
        />
      </Box>

      <PeerGroupRadar
        flexBasis={narrowTable ? 'calc(100% - 420px)' : 'calc(100% - 700px)'}
        metrics={metrics}
        height="calc(100% - 24px)"
        showMedians={showMedians}
        data={localPeers}
      />
    </Box>
  );
}

RadarChartSection.defaultProps = {};

RadarChartSection.propTypes = {};

export default RadarChartSection;
