/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from 'src/services';
import { auth } from 'src/services/filter-api/authentication';

const fetchEmployeeBookmarksPending = createAction('FETCH_EMPLOYEE_BOOKMARKS_PENDING');
const fetchEmployeeBookmarksError = createAction('FETCH_EMPLOYEE_BOOKMARKS_ERROR');
const fetchEmployeeBookmarksSuccess = createAction('FETCH_EMPLOYEE_BOOKMARKS_SUCCESS');
const setEmployeeBookmarkError = createAction('SET_EMPLOYEE_BOOKMARK_ERROR');
const setEmployeeBookmarkSuccess = createAction('SET_EMPLOYEE_BOOKMARK_SUCCESS');

export function fetchEmployeeBookmarks() {
  return async function (dispatch, getState) {
    const user = auth.currentUser;
    const { fetching, fetched } = getState().employee.bookmarks;
    if (!user) return;
    if (fetching || fetched) return;
    dispatch(fetchEmployeeBookmarksPending());
    try {
      const bookmarks = await filterApi.getEmployeeBookmarks({
        userId: user.uid,
      });
      dispatch(fetchEmployeeBookmarksSuccess({ bookmarks }));
    } catch (error) {
      dispatch(fetchEmployeeBookmarksError({ error: error.message }));
    }
  };
}

export function setEmployeeBookmark({ employeeId, bookmarked }) {
  return async function (dispatch) {
    const user = auth.currentUser;
    if (!user) return;
    try {
      if (bookmarked) {
        filterApi.setEmployeeBookmark({
          userId: user.uid,
          employeeId,
        });
      } else {
        filterApi.deleteEmployeeBookmark({
          userId: user.uid,
          employeeId,
        });
      }
      dispatch(setEmployeeBookmarkSuccess({ employeeId, bookmarked }));
    } catch (error) {
      dispatch(setEmployeeBookmarkError({ error: error.message, employeeId }));
    }
  };
}

const initialState = {
  byEmployeeId: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchEmployeeBookmarksPending]: state => {
    state.fetching = true;
  },
  [fetchEmployeeBookmarksError]: (state, action) => {
    const { error } = action.payload;
    state.fetching = false;
    state.error = error;
    console.error(error);
  },
  [fetchEmployeeBookmarksSuccess]: (state, action) => {
    const { bookmarks } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.byEmployeeId = bookmarks.reduce((acc, bookmark) => {
      acc[bookmark.employeeId] = {
        bookmarked: true,
        employeeId: bookmark.employeeId,
      };
      return acc;
    }, {});
  },
  [setEmployeeBookmarkError]: (state, action) => {
    const { error, employeeId } = action.payload;
    const { byEmployeeId } = state;
    byEmployeeId[employeeId] = { error };
    console.error(error);
  },
  [setEmployeeBookmarkSuccess]: (state, action) => {
    const { employeeId, bookmarked } = action.payload;
    const { byEmployeeId } = state;
    byEmployeeId[employeeId] = { employeeId, error: null, bookmarked };
  },
});
