import { useCallback } from 'react';
import ACCOUNT_TYPES from '@fundfilter/core/src/types/account-types';
import { firestoreTimestamp } from 'src/config/firebase';
import { addDays } from 'src/utils/date';
import * as auth from 'src/services/filter-api/authentication';
import { useCreate as useCreateUser } from 'src/services/filter-api/user';
import { useCreateDefault as useCreateDefaultPortfolio } from 'src/services/filter-api/userPortfolios/mutations';

const DEFAULT_ACCOUNT_TYPE = ACCOUNT_TYPES.FREE_TRIAL_ACTIVE.KEY;
const DEFAULT_FREE_TRIAL_DAYS = 15;

function useCreateUserAccount() {
  const { mutateAsync: createAuthUser } = auth.useCreate();
  const createUser = useCreateUser().mutateAsync;
  const createDefaultPortfolio = useCreateDefaultPortfolio().mutateAsync;

  return useCallback(
    async function createUserAccount({ email, password, name, remember, educational, isAnonymous }) {
      const { uid: userId } = await createAuthUser({
        email,
        password,
        remember,
        isAnonymous,
      });

      const user = await createUser({
        userId,
        email,
        name,
        isAnonymous,
        accountType: DEFAULT_ACCOUNT_TYPE,
        educationalAccount: educational && firestoreTimestamp(),
        trialExpiresAt: firestoreTimestamp(addDays(DEFAULT_FREE_TRIAL_DAYS)(new Date())),
      });

      await createDefaultPortfolio({ userId });

      return user;
    },
    [createAuthUser, createDefaultPortfolio, createUser]
  );
}

export default useCreateUserAccount;
