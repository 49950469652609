/* eslint-disable no-unused-vars */
import { select, scaleLinear, scaleUtc, extent, axisBottom, axisLeft, line, format, curveMonotoneX } from 'd3';

const percent = format('+.1%');

function create({ id, margin }) {
  const prefixedId = `#${id}`;

  const root = select(prefixedId)
    .append('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  root.append('g').attr('class', 'x-axis').style('shape-rendering', 'crispEdges');
  root.append('g').attr('class', 'y-axis').style('shape-rendering', 'crispEdges');

  // add Y axis label
  root
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('dy', '1em')
    .attr('class', 'y-axis-label')
    .style('text-anchor', 'middle')
    .text('Net Internal Rate of Return');

  return root;
}

function draw({ id, data, publicIndex, topQuartileVisible, size, margin }) {
  if (!size.width || !size.height || !data) return;

  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;
  const STROKE_WIDTH = 4;
  const STRATEGY_SERIES_COLOR = '#68c11a';
  const INDEX_SERIES_COLOR = '#4682b4';

  let root = select(`#${id} .root`);
  if (!root.node()) {
    root = create({ id, margin });
  }

  // convert the ISO 8601 date to a Date()
  // and remove the first value in the series, it's meaningless
  const allSeries = data.all
    .map(item => ({
      ...item,
      date: new Date(item.date),
    }))
    .slice(1);

  const topQuartileSeries = data.topQuartile
    .map(item => ({
      ...item,
      date: new Date(item.date),
    }))
    .slice(1);

  const xExtent = extent(allSeries, d => d.date);
  const xScale = scaleUtc().domain(xExtent).range([0, svgWidth]);

  let allIrrValues = [...allSeries.map(d => d.irr)];

  if (publicIndex) {
    allIrrValues = [...allIrrValues, ...allSeries.map(d => d.publicIndexValues[publicIndex].irr)];
  }
  if (topQuartileVisible) {
    allIrrValues = [...allIrrValues, ...topQuartileSeries.map(d => d.irr)];
    if (publicIndex) {
      allIrrValues = [...allIrrValues, ...topQuartileSeries.map(d => d.publicIndexValues[publicIndex].irr)];
    }
  }

  const yExtent = extent(allIrrValues);
  const yScale = scaleLinear().domain([yExtent[0], yExtent[1]]).range([svgHeight, 0]);

  root
    .select('.x-axis')
    .attr('transform', `translate(0,${svgHeight})`)
    .transition()
    .call(axisBottom(xScale).tickSize(-svgWidth));

  root.select('.y-axis').transition().call(axisLeft(yScale).ticks(4).tickSize(-svgWidth).tickFormat(percent)); //.tickFormat(millions));

  root
    .select('.y-axis-label')
    .attr('y', 0 - margin.left + 10)
    .attr('x', 0 - svgHeight / 2)
    .transition();

  root
    .selectAll('.all')
    .data([allSeries])
    .join('path')
    .attr('class', 'all')
    .attr('fill', 'none')
    .attr('stroke', STRATEGY_SERIES_COLOR)
    .attr('stroke-width', STROKE_WIDTH)
    .attr(
      'd',
      line()
        .curve(curveMonotoneX)
        .x(d => xScale(d.date))
        .y(d => yScale(d.irr))
    );

  root
    .selectAll('.top-quartile')
    .data([topQuartileVisible ? topQuartileSeries : []])
    .join('path')
    .attr('class', 'top-quartile')
    .attr('fill', 'none')
    .attr('stroke', STRATEGY_SERIES_COLOR)
    .attr('stroke-width', STROKE_WIDTH)
    .attr(
      'd',
      line()
        .curve(curveMonotoneX)
        .x(d => xScale(d.date))
        .y(d => yScale(d.irr))
    );

  root
    .selectAll('.public-index-all')
    .data([publicIndex ? allSeries : []])
    .join('path')
    .attr('class', 'public-index-all')
    .attr('fill', 'none')
    .attr('stroke', INDEX_SERIES_COLOR)
    .attr('stroke-width', STROKE_WIDTH)
    .attr(
      'd',
      line()
        .curve(curveMonotoneX)
        .x(d => xScale(d.date))
        .y(d => yScale(d.publicIndexValues[publicIndex].irr))
    );

  root
    .selectAll('.public-index-top-quartile')
    .data([publicIndex && topQuartileVisible ? topQuartileSeries : []])
    .join('path')
    .attr('class', 'public-index-top-quartile')
    .attr('fill', 'none')
    .attr('stroke', INDEX_SERIES_COLOR)
    .attr('stroke-width', STROKE_WIDTH)
    .attr(
      'd',
      line()
        .curve(curveMonotoneX)
        .x(d => xScale(d.date))
        .y(d => yScale(d.publicIndexValues[publicIndex].irr))
    );
}

export default draw;
