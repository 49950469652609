import styled from 'styled-components';

export default styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  text-decoration: none;
  ::after {
    content: ' »';
  }
`;
