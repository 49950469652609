import React from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import styled, { css } from 'styled-components';
import { InputLabel, MenuItem, FormControl as MuiFormControl, Select as MuiSelect } from '@material-ui/core';

const menuItemGridCss = css`
  display: grid;
  grid-auto-flow: column;
  gap: 0.5em;
  align-items: center;
  grid-auto-columns: max-content;
`;

const sizeSmallCss = css`
  .MuiSelect-root {
    padding: 0.125rem 2em 0.125rem 0.25em;
  }

  .MuiList-root {
    font-size: 0.8125rem;
  }
`;

const sizeDefaultCss = css`
  .MuiSelect-root {
    padding: 0.25rem 2em 0.25rem 0.5em;
  }

  .MuiList-root {
    font-size: 1;
  }
`;

const FormControl = styled(MuiFormControl)`
  .MuiSelect-root {
    background-color: transparent;
  }

  .MuiList-root {
    font-size: 1rem;
  }

  label + .MuiInput-formControl {
    margin: 0;
  }

  ${p => p.size === 'medium' && sizeDefaultCss};
  ${p => p.size === 'small' && sizeSmallCss};
`;

const Select = styled(MuiSelect)`
  font-size: 1rem;
  font-size: ${p => p.size === 'small' && 0.8125}rem;
  color: inherit;

  .MuiSelect-selectMenu {
    font-size: inherit;
    min-width: 10em;
    ${menuItemGridCss}
  }
`;

const Item = styled(MenuItem)`
  font-size: inherit;
  ${menuItemGridCss}
`;

const InlineLabel = styled(Item)`
  padding: 0;
  &:hover {
    background-color: unset;
  }
`;

const Label = styled.span.attrs(p => {
  const children = p.$isLoading ? 'Loading Portfolios…' : p.label;
  return { children };
})`
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  animation: 2s ease-out infinite;
  animation-name: ${p => p.$isLoading && 'pulse'};
`;

function CompactSelect({ value, onChange, size, variant, label, labelType, className, isLoading, items }) {
  return (
    <FormControl className={className} size={size} variant={variant}>
      {labelType === 'default' && (
        <InputLabel id="portfolio-select-label" shrink>
          <Label $isLoading={isLoading} label={label} />
        </InputLabel>
      )}
      <Select
        margin="dense"
        size={size}
        labelId="portfolio-select-label"
        id="portfolio-select"
        value={value}
        onChange={onChange}
        displayEmpty={false}
        renderValue={v => {
          if (labelType === 'inline') {
            return (
              <InlineLabel>
                <Label $isLoading={isLoading} label={label} /> {v}
              </InlineLabel>
            );
          }

          return v;
        }}
      >
        {items.map(item => {
          const { label: itemLabel, value: itemValue } = item;

          return (
            <Item key={JSON.stringify(itemValue) ?? item} value={itemValue ?? item}>
              {itemLabel ?? item}
            </Item>
          );
        })}
      </Select>
    </FormControl>
  );
}

CompactSelect.defaultProps = {
  onChange: funcStub('CompactSelect'),
  size: 'medium',
  labelType: 'default',
  label: '',
  variant: 'standard',
  isLoading: false,
};

CompactSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  label: PropTypes.string,
  labelType: PropTypes.oneOf(['inline', 'default']),
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      })
    ),
  ]).isRequired,
};

export default CompactSelect;
