import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconPaperPlane(props) {
  return (
    <Svg viewBox="0 0 95 90" {...props}>
      <path d="M50.114,73.814l-13.859-7.92c-0.613-0.353-1.111-0.063-1.111,0.645v17.804h-0.002c0,4.696,2.501,5.622,5.558,2.058  l9.645-11.248C50.73,74.706,50.625,74.105,50.114,73.814z" />
      <path d="M90.438,11.753L8.555,39.047c-4.456,1.486-4.765,4.606-0.688,6.938L29.681,58.45c0.512,0.291,1.318,0.254,1.801-0.084  l43.364-30.33c2.416-1.688,2.61-1.447,0.434,0.542L38.57,62.125c-0.522,0.479-0.447,1.148,0.167,1.499l31.796,18.169  c2.548,1.457,5.293,0.34,6.099-2.482l18.121-63.423C95.721,12.5,93.777,10.64,90.438,11.753z" />
    </Svg>
  );
}
