import React from 'react';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'src/utils/nanoid';
import { unionByKey } from 'src/utils/array';
import { Section, SectionHeader, SectionTitle, ActionLink, ValidRowIcon } from '../Styled';
import { getRecentQuarter, sortDatesDesc, formatDateISOString } from 'src/utils/date';
import FundDataField from './FundDataField';

const DATE_POOL = new Array(24)
  .fill(null)
  .map((_, i) => getRecentQuarter(i)())
  .map(formatDateISOString)
  .sort(sortDatesDesc);

function FieldsetFundHistory({ formState, updateForm, fieldValidations, isEditable, ...rest }) {
  const isUserFund = formState?.fundId?.startsWith('u_');

  const handleAddRowClick = useCallback(
    function () {
      const nextQuarter = DATE_POOL.filter(date => {
        return !formState.financialHistory.map(item => item.date).includes(date);
      }).sort(sortDatesDesc)[0];

      updateForm({
        field: 'financialHistory',
        value: [
          ...formState.financialHistory,
          {
            date: nextQuarter,
            updated: Date.now(),
            key: nanoid(),
          },
        ],
      });
    },
    [formState.financialHistory, updateForm]
  );

  const handleHistoryItemChange = useCallback(
    function (date) {
      return function (_event, newItem) {
        const index = formState.financialHistory.findIndex(d => d.date === date);
        const oldItem = formState.financialHistory[index];
        const indicateUpdated = newItem.date !== oldItem.date;

        formState.financialHistory[index] = {
          ...oldItem,
          ...newItem,
          updated: indicateUpdated && Date.now(),
        };

        if (isUserFund || oldItem.isUserEdited) {
          updateForm({
            field: 'financialHistory',
            value: [...formState.financialHistory],
          });
        } else {
          updateForm({
            field: 'financialHistory',
            value: [...formState.financialHistory],
          });
        }
      };
    },
    [isUserFund, formState.financialHistory, updateForm]
  );

  const handleDeleteRowClick = useCallback(
    function (date) {
      return function (_event) {
        const index = formState.financialHistory.findIndex(d => d.date === date);

        formState.financialHistory[index].deleted = true;

        updateForm({
          field: 'financialHistory',
          value: [...formState.financialHistory],
        });
      };
    },
    [formState.financialHistory, updateForm]
  );

  const handleRestoreRowClick = useCallback(
    function (date) {
      return function (_event) {
        const index = formState.financialHistory.findIndex(d => d.date === date);

        formState.financialHistory[index].deleted = false;

        updateForm({
          field: 'financialHistory',
          value: [...formState.financialHistory],
        });
      };
    },
    [formState.financialHistory, updateForm]
  );

  const handleRevertRowClick = useCallback(
    function (date) {
      return function (_event) {
        const items = formState.financialHistory.filter(x => {
          return x.date !== date;
        });

        const item = formState.financialHistory.find(x => {
          return x.date === date && !x.isUserEdited;
        });

        updateForm({
          field: 'financialHistory',
          value: [...items, item],
        });
      };
    },
    [formState.financialHistory, updateForm]
  );

  const fundData = useMemo(
    function () {
      return unionByKey('date')(
        formState.financialHistory,
        formState.financialHistory.filter(f => f.isUserEdited)
      ).sort((a, b) => sortDatesDesc(a.date, b.date));
    },
    [formState.financialHistory]
  );

  return (
    <Section {...rest}>
      <SectionHeader gridColumns="auto auto 1fr">
        <SectionTitle data-step="3">Fund performance data</SectionTitle>
        <ValidRowIcon $valid={true} />
        <div>
          <ActionLink $primary onClick={handleAddRowClick}>
            + Add Row
          </ActionLink>
        </div>
      </SectionHeader>

      <div
        css={`
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 0.75em;
        `}
      >
        {fundData.map((historyItem, index) => {
          const key = historyItem.date;

          return (
            <FundDataField
              disabled={!isEditable}
              key={key}
              isUpdated={historyItem.updated}
              isDeleted={historyItem.deleted}
              isNew={historyItem.new}
              isUserFund={isUserFund}
              historyItem={historyItem}
              onChange={handleHistoryItemChange(key)}
              onDeleteRow={handleDeleteRowClick(key)}
              onRestoreRow={handleRestoreRowClick(key)}
              onRevertRow={handleRevertRowClick(key)}
              dateSelectItems={DATE_POOL}
              usedDates={formState.financialHistory.map(item => item.date)}
              showLabel={index === 0}
              fieldValidations={fieldValidations}
            />
          );
        })}
      </div>
    </Section>
  );
}

FieldsetFundHistory.defaultProps = {};

FieldsetFundHistory.propTypes = {
  children: PropTypes.any,
};

export default FieldsetFundHistory;
