import { filterApi } from 'src/services';

let strategies;

export async function getStrategies() {
  if (!strategies) {
    strategies = await filterApi.getStrategies();
  }

  return strategies;
}
