import React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { cloneDeep } from 'src/lib/lodash';
import routes from 'src/config/routes';
import { Section, SectionRow, ActionButton, HelpText } from '../Styled';
import { sortDatesDesc } from 'src/utils/date';
import FieldsetFundName from './FieldsetFundName';
import FieldsetFundDetails from './FieldsetFundDetails';
import FieldsetFundHistory from './FieldsetFundHistory';
import { useFund, useFundManagerNames } from 'src/hooks';
import useFormController from './useFormController';
import useUpdateUserFundCallback from './useUpdateUserFundCallback';

function toDecimal(val) {
  const num = Number.parseFloat(val);
  return Number.isFinite(num) ? num : null;
}

function toPercent(val) {
  const dec = toDecimal(val);
  return Number.isFinite(dec) ? dec / 100 : null;
}

function FundAddEditForm({ onCloseClick, fundId, formPresets, viewFundAfterSave }) {
  const [formState, updateForm, fieldValidations] = useFormController(formPresets);
  const [formIsValid, setFormIsValid] = useState(false);
  const [inTransit, setInTransit] = useState(false);

  const fund = useFund({
    fundId,
    includeFinancialHistory: 'append',
  });
  const fundManagerNames = useFundManagerNames();

  const isUserFund = fund && fund.fundId.startsWith('u_');
  const isNewFund = !fund;

  useEffect(
    function () {
      if (!fund) return;
      if (!fund.fundId) return;
      if (!fund.fetched) return;
      if (!fund.financialHistory) return;
      if (!fundManagerNames) return;

      const financialHistory = cloneDeep(fund.financialHistory).map(item => {
        const irr = Math.round(Number.parseFloat(item.irr) * 100, 1);
        const calledPct = Math.round(Number.parseFloat(item.calledPct) * 100, 1);

        return { ...item, irr, calledPct };
      });

      const fundManagerName = fundManagerNames.byId[fund.fundManagerId].name;

      updateForm({
        type: 'reset',
        value: {
          fundId: fund.fundId,
          name: fund.name,
          vintage: fund.vintage,
          size: fund.size,
          regionId1: fund.mainRegionId,
          regionName1: fund.mainRegionName,
          strategy: fund.strategy,
          strategyId: fund.strategyId,
          subStrategy: fund.subStrategy,
          subStrategyId: fund.subStrategyId,
          fundManagerId: fund.fundManagerId,
          fundManagerName,
          financialHistory,
          errors: {
            name: false,
            fundManagerName: false,
            vintage: false,
            size: false,
            regionId1: false,
            strategyId: false,
            financialHistory: financialHistory.map(() => ({
              dpi: false,
              calledPct: false,
              rvpi: false,
              tvpi: false,
              irr: false,
            })),
          },
        },
      });
    },
    [fund, fundManagerNames, updateForm]
  );

  useEffect(
    function () {
      if (!formState.errors.financialHistory) return;

      const noFieldErrors = [
        formState.errors.name,
        formState.errors.fundManagerName,
        formState.errors.vintage,
        formState.errors.size,
        formState.errors.regionId1,
        formState.errors.strategyId,
      ].every(value => value === false);

      setFormIsValid(noFieldErrors);
    },
    [
      formState.errors.name,
      formState.errors.fundManagerName,
      formState.errors.vintage,
      formState.errors.size,
      formState.errors.regionId1,
      formState.errors.strategyId,
      formState.errors.financialHistory,
      formState.fundId,
    ]
  );

  const isLoading = useMemo(() => {
    if (!fundId) return false;
    if (!fund?.fetched) return 'Loading...';
  }, [fund, fundId]);

  const handleSubmitUserFund = useUpdateUserFundCallback(formState);

  const handleSubmitSystemFund = useCallback(async function ({ financialHistory: _ }) {
    return console.warn('On hold until we can revisit');
    /**
       * On hold until we can revisit

       const recentItem = financialHistory[0]?.isUserEdited ? financialHistory[0] : undefined;

       const data = {
         calledPct: recentItem?.calledPct || null,
         dpi: recentItem?.dpi || null,
         irr: recentItem?.irr || null,
         rvpi: recentItem?.rvpi || null,
         tvpi: recentItem?.tvpi || null,
         financialHistory: financialHistory.filter(item => item.isUserEdited),
        };

        try {
          await updateBookmark({ fundId, ...data });
        } catch (error) {
          console.error(error);
          console.warn('Could not');
          await createBookmark({ fundId, ...data });
        }
        */
  }, []);

  const afterSubmit = useCallback(
    submittedFundId => {
      updateForm({ type: 'reset' });

      setInTransit(false);
      onCloseClick();

      if (!viewFundAfterSave) return;
      navigate(routes.FUND.replace(':fundId', submittedFundId));
    },
    [onCloseClick, updateForm, viewFundAfterSave]
  );

  const handleSubmit = useCallback(
    async function (event) {
      event.preventDefault();

      if (!formIsValid) return;

      setInTransit('Saving...');

      const financialHistory = [...formState.financialHistory]
        .sort((a, b) => sortDatesDesc(a.date, b.date))
        .map(item => {
          return {
            ...item,
            dpi: toDecimal(item.dpi),
            calledPct: toPercent(item.calledPct),
            rvpi: toDecimal(item.rvpi),
            tvpi: toDecimal(item.tvpi),
            irr: toPercent(item.irr),
            date: item.date,
          };
        });

      if (isUserFund || isNewFund) {
        const newUserFundId = await handleSubmitUserFund({
          financialHistory,
        });
        afterSubmit(newUserFundId);
      } else {
        await handleSubmitSystemFund({ financialHistory });
        afterSubmit(fundId);
      }
    },
    [
      afterSubmit,
      formIsValid,
      formState.financialHistory,
      fundId,
      handleSubmitSystemFund,
      handleSubmitUserFund,
      isNewFund,
      isUserFund,
    ]
  );

  const isEditable = !inTransit && !isLoading && (isUserFund || isNewFund);

  return (
    <>
      <FieldsetFundName
        formState={formState}
        updateForm={updateForm}
        inTransit={inTransit || isLoading}
        onClickViewFund={onCloseClick}
        isEditable={isEditable}
      />
      <FieldsetFundDetails
        in={Boolean(formState.name)}
        formState={formState}
        updateForm={updateForm}
        isEditable={isEditable}
      />
      <FieldsetFundHistory
        in={Boolean(formState.name)}
        formState={formState}
        updateForm={updateForm}
        fieldValidations={fieldValidations.financialHistory}
        isEditable={isEditable}
      />
      <Section in={Boolean(formState.name) || Boolean(fundId)}>
        <SectionRow gridColumns="3fr 6fr">
          <ActionButton disabled={Boolean(!formIsValid || inTransit || isLoading)} onClick={handleSubmit}>
            {inTransit || isLoading ? inTransit : `Save & Close`}
          </ActionButton>
          <span />
        </SectionRow>
        <SectionRow gridColumns="3fr 6fr">
          <HelpText>
            <br />
            The data that you input about this fund is <strong>private to you only</strong> and not added to the general
            FundFilter database.
          </HelpText>
          <span />
        </SectionRow>
      </Section>
    </>
  );
}

FundAddEditForm.defaultProps = {
  formPresets: {},
  viewFundAfterSave: true,
};

FundAddEditForm.propTypes = {
  onCloseClick: PropTypes.func,
  fundId: PropTypes.string,
  formPresets: PropTypes.object.isRequired,
};

export default FundAddEditForm;
