import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

const STATUS_LABEL = {
  fund: 'loading fund data...',
  managers: 'loading fund manager data...',
};

function InitialLoadDialog() {
  const [statusLabel, setStatusLabel] = useState(STATUS_LABEL.fund);
  const [percentComplete, setPercentComplete] = useState(0);
  const timer = useRef();
  useEffect(() => {
    timer.current = setInterval(() => {
      if (percentComplete < 100) {
        setPercentComplete(prev => {
          const newValue = prev + 4;
          if (newValue >= 100) {
            clearInterval(timer.current);
          }
          if (newValue >= 50) {
            setStatusLabel(STATUS_LABEL.managers);
          }
          return newValue;
        });
      }
    }, 200);
    return () => {
      clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog open>
        <DialogContent>
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <img
              src="/assets/img/logo_with_brand.svg"
              alt="FundFilter"
              css={`
                width: 180px;
                margin-bottom: 20px;
              `}
            />
            <div
              css={`
                width: 400px;
                height: 40px;
                background-color: #000;
                position: relative;
                margin: 0 20px 15px 15px;
              `}
            >
              <div
                css={`
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: ${percentComplete}%;
                  height: 40px;
                  background-color: #0097ae;
                `}
              />
            </div>
            <span
              css={`
                margin-bottom: 50px;
                color: #aaa;
                font-size: 12px;
                text-transform: uppercase;
              `}
            >
              {statusLabel}
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

InitialLoadDialog.defaultProps = {};

InitialLoadDialog.propTypes = {};

export default InitialLoadDialog;
