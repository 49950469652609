import { useQueryClient, useMutation } from 'react-query';
import { queryKeys } from './queries';
import * as client from './client';

function useCreate(filters = {}) {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async data => {
    return await client.create({ ...filters, ...data });
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useUpdate() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ filterId, ...data }) => {
    return await client.update(filterId, data);
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useDelete() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ filterId }) => {
    return await client.destroy(filterId);
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export { useCreate, useUpdate, useDelete };
