import React from 'react';
import { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { lg, md } from 'src/config/layout';
import { getRecentQuarter } from 'src/utils/date';
import { useStrategyTimeseriesQuery } from 'src/hooks';
import { CashflowDataVisualization, IconDownload } from 'src/components';
import ChartTypeSelect from '../ChartTypeSelect';
import ViewToggle from './ViewToggle';
import VariantSelect from './VariantSelect';
import CreateTargetFundForm from './CreateTargetFundForm';
import ForecastChart from '../ForecastChart';

const Layout = styled.div`
  display: grid;
  gap: 2em;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;

  @media (min-width: ${lg.BREAKPOINT}px) {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
  }
`;

const SettingsSection = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 20px;
`;

const DetailsSection = styled.section`
  height: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: 30px 100px auto;
  background-color: #1c1c1c;
  padding: 10px 18px;

  @media (min-width: ${md.BREAKPOINT}px) {
    grid-template-rows: 30px auto;
    grid-template-columns: 2fr 7fr;
  }
`;

const PreviewOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-column: 1 / -1;

  @media (min-width: ${md.BREAKPOINT}px) {
    grid-column: auto;
    gap: 30px;
  }
`;

const DownloadButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  font-size: 1em;
  color: inherit;
  border: none;

  svg {
    font-size: 1.25em;
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.1em;
  font-weight: 500;
  padding: 0;
  margin: 0;
`;

const { TYPES, VIEWS, VARIANTS } = CashflowDataVisualization.CONSTANTS;
const TYPE_OPTIONS = [TYPES.JCURVE_POLY, TYPES.NAV, TYPES.CASHFLOW];

const VIEW_OPTIONS = Object.values(VIEWS);

const DEFAULT_START_DATE = getRecentQuarter()();

function TargetFund({ portfolioId }) {
  const [strategyId, setStrategyId] = useState('0WMNvp9YQuIxmEDEOO6e');
  const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
  const [commitmentAmount, setCommitmentAmount] = useState(1_000_000);
  const [type, setType] = useState(TYPES.JCURVE_POLY);
  const [view, setView] = useState(VIEWS.CHART);
  const [variant, setVariant] = useState(VARIANTS.ALL);
  const [fundName, setFundName] = useState('');
  const [downloadType, setDownloadType] = useState(null);

  const handleOnChangeForm = useCallback(data => {
    setStrategyId(data.strategyId);
    setStartDate(data.startDate);
    setCommitmentAmount(data.commitmentAmount);
    setFundName(data.name || data.namePlaceholder);
  }, []);

  const quartile = {
    [VARIANTS.TOP]: 'top',
    [VARIANTS.BOTTOM]: 'bottom',
  }[variant];

  const timeseriesQuery = useStrategyTimeseriesQuery({
    strategyId,
    quartile,
  });

  const timeseries = useMemo(() => {
    if (!timeseriesQuery.data) return [];

    return timeseriesQuery.data.series.map(d => {
      return { ...d, commitmentAmount, fundName };
    });
  }, [commitmentAmount, fundName, timeseriesQuery.data]);

  if (!portfolioId) return null;

  return (
    <Layout>
      <SettingsSection>
        <SectionTitle
          css={`
            margin-top: 10px;
          `}
        >
          Target Fund Criteria
        </SectionTitle>
        <CreateTargetFundForm portfolioId={portfolioId} onChange={handleOnChangeForm} />
      </SettingsSection>

      <DetailsSection>
        <SectionTitle>Target Fund Preview</SectionTitle>
        <header
          css={`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <ViewToggle value={view} onChange={setView} options={VIEW_OPTIONS} />
        </header>

        <PreviewOptions>
          <ChartTypeSelect options={TYPE_OPTIONS} value={type} onChange={setType} />
          <VariantSelect
            strategyId={strategyId}
            commitmentAmount={commitmentAmount}
            onChange={setVariant}
            selectedOption={variant}
            startDate={startDate}
          />
        </PreviewOptions>
        <div
          css={`
            display: flex;
            flex-direction: column;
            grid-column: 1 / -1;
            @media (min-width: ${md.BREAKPOINT}px) {
              grid-column: auto;
            }
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: space-between;
            `}
          >
            {`${fundName} (${variant})`}

            {view === VIEWS.TABLE && (
              <DownloadButton onClick={() => setDownloadType(type)}>
                <IconDownload />
              </DownloadButton>
            )}
          </div>

          <ForecastChart
            data={timeseries}
            type={type}
            view={view}
            startDate={startDate}
            isLoading={timeseriesQuery.isLoading}
            downloadType={downloadType}
            downloadFilename={fundName}
            onDownloadComplete={() => setDownloadType(null)}
            margin={{
              top: '0em',
              left: '0em',
              right: '0em',
              bottom: '2.5em',
            }}
          />
        </div>
      </DetailsSection>
    </Layout>
  );
}

TargetFund.defaultProps = {
  selectedTags: [],
};

export default TargetFund;
