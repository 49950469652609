import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import IconFullScreenOn from '../IconFullScreenOn';
import IconFullScreenOff from '../IconFullScreenOff';

const Button = styled(IconButton)`
  color: currentColor;
  padding: 0;
  font-size: 1.3em;
  line-height: 1;

  .MuiSvgIcon-root {
    font-size: unset;
  }
`;

const ButtonFullScreen = props => {
  const { variant, ...rest } = props;

  const IconVariant = variant === 'on' ? IconFullScreenOn : IconFullScreenOff;

  return (
    <Button {...rest}>
      <IconVariant />
    </Button>
  );
};

ButtonFullScreen.defaultProps = {
  variant: 'on',
};

ButtonFullScreen.propTypes = {
  variant: PropTypes.oneOf(['on', 'off']),
};

export default ButtonFullScreen;
