import { useMemo } from 'react';
import { slugify } from 'src/utils/string';
import { uniqBy } from 'src/lib/lodash';

function useDedupeNewsArticles({ articles }) {
  return useMemo(() => {
    if (!articles) return [];
    return uniqBy(articles, a => slugify(a.title));
  }, [articles]);
}

export default useDedupeNewsArticles;
