import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';

const Layout = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
`;

const StyledRadio = styled(Radio)`
  padding: 4px;
  margin-right: 5px;
`;

function VariantSelectItem({ title, isSelected, onClick }) {
  return (
    <Layout onClick={onClick}>
      <Header>
        <StyledRadio checked={isSelected} color="default" size="small" />
        <span>{title}</span>
      </Header>
    </Layout>
  );
}

VariantSelectItem.defaultProps = {
  funds: [],
};

VariantSelectItem.propTypes = {
  title: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default VariantSelectItem;
