import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { TooltipContent, TooltipHeader, TooltipArrowUp } from 'src/components';

const Title = styled.h3`
  margin: 0;
  color: #000;
`;

const Main = styled.div`
  color: #000;
  fontsize: 12px;
  overflow: auto;
`;

function StrategyCurveDefaultTooltip({ children, open, anchorEl, onClose }) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        {children}
        <Popper
          style={{ zIndex: 100 }}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom"
          modifiers={{
            offset: {
              enabled: true,
              offset: '0,15',
            },
            arrow: {
              enabled: true,
              element: '.strategy-curve-default-arrow',
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={300}>
              <div
                css={`
                  max-width: 300px;
                `}
              >
                <TooltipArrowUp className="strategy-curve-default-arrow" marginLeft="2px" />
                <TooltipContent>
                  <TooltipHeader>
                    <Title>Strategy Curve Default</Title>
                  </TooltipHeader>
                  <Main>
                    Charts marked by a blue dot indicates that the chart has defaulted to the respective strategy curve
                    instead of the sub-strategy chart due to inadequate robustness of fund data beyond a certain time
                    period for that sub strategy.
                  </Main>
                </TooltipContent>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

StrategyCurveDefaultTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

StrategyCurveDefaultTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => null,
};

export default StrategyCurveDefaultTooltip;
