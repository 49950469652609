import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const IconAddSvg = styled(SvgIcon)`
  height: ${props => props.height};
  height: ${props => props.height};

  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1em;
`;

export default function IconAdd(props) {
  return (
    <IconAddSvg viewBox="0 0 512 512" {...props}>
      <path d="M256 112v288M400 256H112" />
    </IconAddSvg>
  );
}
