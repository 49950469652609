import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconSortDown(props) {
  return (
    <Svg viewBox="0 0 100 125" {...props}>
      <path d="M88.83,22.75a7.84,7.84,0,0,1,5.55,13.38L55.55,75a7.81,7.81,0,0,1-11.06,0L5.63,36.13a7.84,7.84,0,0,1,5.55-13.38Z" />
    </Svg>
  );
}
