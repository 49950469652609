import { createContext } from 'react';

const AppLayoutContext = createContext({
  expandedId: null,
  expansionDisabled: false,
  setExpandedId: () => null,
  gridRef: { current: null },
  isRenderingTicker: false,
  width: 0,
  height: 0,
});

AppLayoutContext.displayName = 'AppLayoutContext';

export default AppLayoutContext;
