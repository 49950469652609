const groupBy = require('lodash/fp/groupBy');
const keyBy = require('lodash/fp/keyBy');
const sortBy = require('lodash/fp/sortBy');

function sort(sortFn) {
  return function (arr) {
    return arr.slice().sort(sortFn);
  };
}

function split(n, arr) {
  return new Array(n).fill().map((_, i) => {
    const start = (arr.length / n) * i;
    const end = (arr.length / n) * (i + 1);
    return arr.slice(start, end);
  });
}

module.exports = { sort, groupBy, keyBy, sortBy, split };
