import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconSortUp(props) {
  return (
    <Svg viewBox="0 0 100 125" {...props}>
      <path d="M11.18,77.25A7.84,7.84,0,0,1,5.64,63.87L44.46,25.05a7.79,7.79,0,0,1,11.06,0L94.34,63.87a7.82,7.82,0,0,1-5.51,13.38Z" />
    </Svg>
  );
}
