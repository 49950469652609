import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconUserAvatar(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M12,23c-1.869,0-3.628-0.471-5.171-1.297    l2.023-0.674c0.162-0.054,0.286-0.187,0.327-0.353l0.403-1.611c0.035-0.138,0.009-0.283-0.07-0.401    c-0.08-0.117-0.205-0.195-0.346-0.215c-1.66-0.231-2.808-0.626-3.433-0.89c0.62-0.84,1.573-2.736,1.573-6.448    C7.306,6.087,11.068,5,12,5c1.154,0,1.401,0.192,2.008,0.799c0.094,0.093,0.222,0.146,0.354,0.146h0.472    c1.619,0,1.861,3.237,1.861,5.167c0,3.709,0.951,5.605,1.571,6.446c-0.63,0.264-1.781,0.659-3.433,0.89    c-0.14,0.019-0.266,0.098-0.345,0.215c-0.079,0.118-0.105,0.264-0.07,0.401l0.403,1.613c0.041,0.166,0.165,0.299,0.327,0.353    l2.022,0.674C15.627,22.529,13.868,23,12,23z M18.279,21.019l-2.554-0.852l-0.204-0.815c2.481-0.422,3.76-1.166,3.817-1.2    c0.142-0.084,0.23-0.234,0.242-0.398c0.012-0.164-0.062-0.325-0.19-0.428c-0.017-0.013-1.695-1.403-1.695-6.215    c0-4.092-0.963-6.167-2.861-6.167h-0.268C13.887,4.29,13.372,4,12,4c-1.783,0-5.694,1.79-5.694,7.111    c0,4.812-1.678,6.202-1.689,6.211c-0.134,0.099-0.208,0.259-0.2,0.425c0.009,0.166,0.099,0.317,0.242,0.403    c0.056,0.034,1.323,0.782,3.82,1.204l-0.203,0.813l-2.555,0.852C2.872,19.029,1,15.731,1,12C1,5.935,5.935,1,12,1s11,4.935,11,11    C23,15.731,21.128,19.029,18.279,21.019z" />
    </Svg>
  );
}
