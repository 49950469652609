import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import IconArrowRight from '../IconArrowRight';

const Button = styled(IconButton)`
  color: #7d7d7d;
  transform: scaleX(${p => (p.$flipped ? -1 : 1)});

  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    width: 0.425em;
    height: 0.425em;
  }
`;

function ButtonArrowRight({ flipped, ...rest }) {
  return (
    <Button size="small" $flipped={flipped} {...rest}>
      <IconArrowRight />
    </Button>
  );
}

ButtonArrowRight.defaultProps = {};

ButtonArrowRight.propTypes = {};

export default ButtonArrowRight;
