import { chunk } from 'src/lib/lodash';
import { db } from 'src/config/firebase';

const dbUserFundManagers = db.collection('userFundManagers');

async function getUserFundManagers({ userId }) {
  return (await dbUserFundManagers.where('userId', '==', userId).orderBy('name', 'asc').get()).docs.map(d => d.data());
}

async function getUserFundManagersById({ fundManagerIds, userId }) {
  const fundManagerIdChunks = chunk(fundManagerIds, 10);
  const fundManagerChunks = await Promise.all(
    fundManagerIdChunks.map(fundManagerChunkIds =>
      dbUserFundManagers.where('fundManagerId', 'in', fundManagerChunkIds).where('userId', '==', userId).get()
    )
  );

  return fundManagerChunks
    .map(managerChunk => managerChunk.docs.map(doc => doc.data()))
    .reduce((acc, manager) => [...acc, ...manager], []);
}

async function getUserFundManager({ userId, fundManagerId }) {
  return (await dbUserFundManagers.doc(`${userId}_${fundManagerId}`).get()).data();
}

async function setUserFundManager({ userId, fundManagerId, name }) {
  const now = new Date().toISOString();
  const fundManager = {
    userId,
    fundManagerId,
    name,
    lastUpdateDate: now,
  };
  await dbUserFundManagers.doc(`${userId}_${fundManagerId}`).set(fundManager);
  return fundManager;
}

export { getUserFundManagers };
export { getUserFundManagersById };
export { getUserFundManager };
export { setUserFundManager };
