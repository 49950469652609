import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchSchoolEmployeesError = createAction('FETCH_SCHOOL_EMPLOYEES_ERROR');
const fetchSchoolEmployeesPending = createAction('FETCH_SCHOOL_EMPLOYEES_PENDING');
const fetchSchoolEmployeesSuccess = createAction('FETCH_SCHOOL_EMPLOYEES_SUCCESS');

export function fetchSchoolEmployees({ schoolId }) {
  return async function (dispatch, getState) {
    const { byId } = getState().employee.school;
    if (byId[schoolId]) return;
    dispatch(fetchSchoolEmployeesPending({ schoolId }));
    try {
      const employees = await filterApi.getEmployeesAttendingSchool({ schoolId });
      dispatch(fetchSchoolEmployeesSuccess({ employees, schoolId }));
    } catch (error) {
      dispatch(fetchSchoolEmployeesError({ error: error.message, schoolId }));
    }
  };
}

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [fetchSchoolEmployeesPending]: (state, action) => {
    const { schoolId } = action.payload;
    const obj = state.byId[schoolId] || {};
    obj.fetching = true;
    state.byId[schoolId] = obj;
  },
  [fetchSchoolEmployeesError]: (state, action) => {
    const { error, schoolId } = action.payload;
    const obj = state.byId[schoolId] || {};
    obj.error = error;
    obj.fetching = false;
    state.byId[schoolId] = obj;
    console.error(error);
  },
  [fetchSchoolEmployeesSuccess]: (state, action) => {
    const { employees, schoolId } = action.payload;
    state.byId[schoolId] = {
      employees,
      fetched: true,
      fetching: false,
      error: null,
    };
  },
});
