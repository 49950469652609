import React from 'react';
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Folder as IconFolderClosed,
  FolderOpen as IconFolderOpen,
  ArrowDropDownOutlined as IconDropdown,
} from '@material-ui/icons';
import { useList as useListPortfolios } from 'src/services/filter-api/userPortfolios/queries';
import {
  useCreate as useCreatePortfolio,
  useUpdate as useUpdatePortfolio,
  useDelete as useDeletePortfolio,
} from 'src/services/filter-api/userPortfolios/mutations';
import Popover from './Popover';
import List from './List';

const Layout = styled.div`
  font-size: 1em;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.5em;
  border: 1px solid #ffffff44;
  border-radius: 3px;
  padding: 0.125em 0.5em;
  cursor: pointer;

  .col {
    display: flex;
    align-items: center;
  }
`;

const Label = styled.span`
  white-space: nowrap;
  overflow: hidden;
  width: 8em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IconFolder = styled.i.attrs(p => {
  return {
    as: p.$active ? IconFolderClosed : IconFolderOpen,
  };
})`
  opacity: ${p => (p.$active ? 1 : 0.5)};
  font-size: 1.428571429em;
  position: relative;
  top: -1px;
  margin-right: 0.25em;
`;

function SelectPortfolioControl({ value, onChange, className }) {
  const { data: portfolios } = useListPortfolios();
  const createPortfolio = useCreatePortfolio().mutateAsync;
  const updatePortfolio = useUpdatePortfolio().mutateAsync;
  const deletePortfolio = useDeletePortfolio().mutateAsync;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPortfolioId, setSelectedPortfolioId] = React.useState('');

  const defaultPortfolioId = useMemo(() => {
    if (!portfolios) return null;
    return portfolios.find(p => p.isDefault) || portfolios[0]?.portfolioId;
  }, [portfolios]);

  const displayLabel = useMemo(() => {
    if (!portfolios) return 'Loading...';
    const defaultPortfolio = portfolios.find(p => {
      return p.portfolioId === defaultPortfolioId;
    });
    const selectedPortfolio = portfolios.find(p => {
      return p.portfolioId === selectedPortfolioId;
    });
    return (selectedPortfolio || defaultPortfolio)?.name;
  }, [defaultPortfolioId, portfolios, selectedPortfolioId]);

  useEffect(() => {
    onChange(selectedPortfolioId || defaultPortfolioId);
  }, [defaultPortfolioId, onChange, selectedPortfolioId]);

  return (
    <>
      <Layout onClick={evt => setAnchorEl(evt.currentTarget)} className={className}>
        <span className="col">Portfolio:</span>
        <span className="col fixwidth">
          <IconFolder $active={true} />
          <Label>{displayLabel}</Label>
        </span>
        <span className="col">
          <IconDropdown />
        </span>
      </Layout>

      <Popover
        anchorEl={anchorEl}
        onClose={evt => {
          evt.stopPropagation(evt);
          setAnchorEl(null);
        }}
      >
        <List
          items={portfolios}
          selectedItemId={value || selectedPortfolioId || defaultPortfolioId}
          onClickItem={id => {
            setSelectedPortfolioId(id);
            setAnchorEl(null);
          }}
          onClickCreateItem={data => {
            return createPortfolio({ name: data });
          }}
          onClickUpdateItem={(id, data) => {
            return updatePortfolio({ portfolioId: id, ...data });
          }}
          onClickDeleteItem={id => {
            return deletePortfolio(id);
          }}
        />
      </Popover>
    </>
  );
}

SelectPortfolioControl.defaultProps = {
  onChange: () => {},
};

SelectPortfolioControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectPortfolioControl;
