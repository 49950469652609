import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useViewportSize } from 'src/hooks';
import { lg } from 'src/config/layout';
import { CashflowDataVisualization } from 'src/components';
import ForecastChart from '../ForecastChart';
import StrategyBreakdownChart from './StrategyBreakdownChart';
import { Grid } from 'src/components/templates/AppLayout/styled';
import Context from 'src/components/templates/AppLayout/context';
import { withResizeDetector } from 'react-resize-detector';
import { useLayoutActions } from 'src/hooks';
import GridSpace from './GridSpace';

const { VIEWS, TYPES } = CashflowDataVisualization.CONSTANTS;

function ChartGrid({ isFetching, portfolioForecastData, portfolioItems, navTarget, view }) {
  const [expandedId, setExpandedId] = useState(null);
  const { expandPanel } = useLayoutActions();
  const gridRef = useRef(null);
  const [downloadType, setDownloadType] = useState(null);
  const [strategyChartType, setStrategyChartType] = useState();
  const { width, height } = useViewportSize();

  const isExpandable = width >= lg.BREAKPOINT;

  useEffect(() => {
    if (expandedId) expandPanel();
  }, [expandedId, expandPanel]);

  return (
    <>
      <Context.Provider
        value={{
          expandedId,
          expansionDisabled: false,
          setExpandedId,
          gridRef,
          isRenderingTicker: false,
          width,
          height,
        }}
      >
        <Grid ref={gridRef} $isExpanded={Boolean(expandedId)} $viewportHeight={500}>
          <GridSpace
            title="Porfolio Cashflow Curve"
            isBusy={isFetching && 'Calculating forecasts...'}
            expandedWidth={width}
            expandedHeight={height}
            isDownloadable={view === VIEWS.TABLE}
            onClickDownload={() => setDownloadType(TYPES.JCURVE_MEAN)}
            isExpandable={isExpandable}
          >
            <ForecastChart
              type={TYPES.JCURVE_MEAN}
              view={view}
              data={portfolioForecastData}
              downloadType={downloadType}
              downloadFilename={'Porfolio Cashflow Curve'}
              onDownloadComplete={() => setDownloadType(null)}
            />
          </GridSpace>

          <GridSpace
            title="Porfolio Net Cashflow"
            isBusy={isFetching && 'Calculating forecasts...'}
            expandedWidth={width}
            expandedHeight={height}
            isDownloadable={view === VIEWS.TABLE}
            onClickDownload={() => setDownloadType(TYPES.CASHFLOW)}
            isExpandable={isExpandable}
          >
            <ForecastChart
              type={TYPES.CASHFLOW}
              view={view}
              data={portfolioForecastData}
              downloadType={downloadType}
              downloadFilename={'Porfolio Net Cashflow'}
              onDownloadComplete={() => setDownloadType(null)}
            />
          </GridSpace>

          <GridSpace
            title="Porfolio Net Asset Value"
            isBusy={isFetching && 'Calculating forecasts...'}
            expandedWidth={width}
            expandedHeight={height}
            isDownloadable={view === VIEWS.TABLE}
            onClickDownload={() => setDownloadType(TYPES.NAV)}
            isExpandable={isExpandable}
          >
            <ForecastChart
              type={TYPES.NAV}
              view={view}
              data={portfolioForecastData}
              navTarget={navTarget}
              downloadType={downloadType}
              downloadFilename={'Porfolio Net Cashflow'}
              onDownloadComplete={() => setDownloadType(null)}
            />
          </GridSpace>

          <GridSpace
            title={null}
            isBusy={isFetching && 'Calculating forecasts...'}
            expandedWidth={width}
            expandedHeight={height}
            isExpandable={isExpandable}
            controls={
              <div
                css={`
                  padding: 0.25em 1em;
                `}
              >
                <StrategyBreakdownChart.Title onChange={setStrategyChartType} />
              </div>
            }
          >
            <StrategyBreakdownChart forecastItems={portfolioItems} chartType={strategyChartType} />
          </GridSpace>
        </Grid>
      </Context.Provider>
    </>
  );
}

export default withResizeDetector(ChartGrid, {
  refreshMode: 'throttle',
  refreshRate: 500,
});
