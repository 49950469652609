import React from 'react';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setEmployeeBookmark } from 'src/store/employee-bookmarks';
import { BookmarkedEmployeesTable, ConfirmDialog } from 'src/components';

function EmployeesView({ employees, isExpanded, wideTable, onRowClick, onChangeSelectedId, selectedId }) {
  const dispatch = useDispatch();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState();
  const [confirmDialogMessage, setConfirmDialogMessage] = useState();
  const confirmDialogCloseHandler = useRef();

  function handleRowClick(_e, { data }) {
    onChangeSelectedId(data.employeeId);
    onRowClick();
  }

  function handleColumnClick(e, { column, data }) {
    if (column.key === 'remove') {
      e.preventDefault();
      e.stopPropagation();
      confirmDialogCloseHandler.current = dialogResult => {
        setConfirmDialogOpen(false);
        confirmDialogCloseHandler.current = null;
        if (dialogResult) {
          dispatch(
            setEmployeeBookmark({
              employeeId: data.employeeId,
              bookmarked: false,
            })
          );
        }
      };
      setConfirmDialogMessage('Stop following this person?');
      setConfirmDialogOpen(true);
    }
  }

  if (!employees) return null;
  return (
    <>
      <BookmarkedEmployeesTable
        data={employees}
        selectedId={selectedId}
        expanded={isExpanded}
        wideTable={wideTable}
        enableHover
        onRowClick={handleRowClick}
        onColumnClick={handleColumnClick}
        css={`
          padding: 10px;
        `}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={confirmDialogCloseHandler.current}
        message={confirmDialogMessage}
      />
    </>
  );
}

EmployeesView.defaultProps = {};

EmployeesView.propTypes = {
  children: PropTypes.any,
};

export default EmployeesView;
