import React from 'react';
import PropTypes from 'prop-types';
import * as date from 'src/utils/date';
import { IconEmvChip, LogoVisa, LogoMasterCard, LogoAmericanExpress } from 'src/components';

const CARD_BRANDS = {
  VISA: <LogoVisa />,
  MASTERCARD: <LogoMasterCard />,
  AMEX: <LogoAmericanExpress />,
};

function CreditCard({ className, card }) {
  if (!card) return null;

  const { brand, last4, expYear, expMonth } = card;

  return (
    <div
      className={className}
      css={`
        color: white;
        font-size: 16px;
        font-family: monospace;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        max-width: 24em;
        /* margin: 0 1.5em; */
        text-shadow: 0 1px 3px black;
        border-radius: 6px;
        background: linear-gradient(306deg, rgba(39, 39, 41, 1) 0%, rgba(46, 47, 47, 1) 35%, rgba(27, 28, 29, 1) 100%);
      `}
    >
      <div
        css={`
          font-size: 24px;
          margin-bottom: 3rem;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>{CARD_BRANDS[brand.toUpperCase()] || brand.toUpperCase()}</div>

        <IconEmvChip
          css={`
            font-size: 2rem;
          `}
        />
      </div>
      <br />
      <div
        css={`
          display: flex;
          justify-content: space-between;
        `}
      >
        <span>····· ···· ···· {last4}</span>
        <span>{date.formatDate('MM/yy')(new Date(expYear, expMonth - 1))}</span>
      </div>
    </div>
  );
}

CreditCard.defaultProps = {};

CreditCard.propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired,
    expYear: PropTypes.number.isRequired,
    expMonth: PropTypes.number.isRequired,
  }),
};

export default CreditCard;
