import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FundManagerJoinersAndLeavers,
  FundManagerJoinersAndLeaversTooltip,
  UserFundOrManagerMessage,
} from 'src/components';
import { useFundManager, useFundManagerJoinersAndLeavers, useEmployees } from 'src/hooks';
import { visualization } from 'src/config/app';

export function FundManagerJoinersAndLeaversVisualization({ fundManagerId, expanded, _onShare, onExpandToggle }) {
  const [range, setRange] = useState(null);
  const [expanding, setExpanding] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
  const [tooltipJoinersAndLeavers, setTooltipJoinersAndLeavers] = useState(null);
  const [tooltipEmployeeIds, setTooltipEmployeeIds] = useState(null);
  const tooltipEmployees = useEmployees({
    employeeIds: tooltipEmployeeIds,
  });
  const fundManager = useFundManager({ fundManagerId });
  const joinersAndLeavers = useFundManagerJoinersAndLeavers({
    fundManagerId,
    minYear: new Date().getUTCFullYear() - visualization.fundManager.YEARS_BACK,
  });

  function closeTooltip() {
    setTooltipOpen(false);
    setTooltipAnchorEl(null);
  }

  useEffect(() => {
    if (!expanded) closeTooltip();

    if (expanded) {
      setExpanding(true);
      setTimeout(() => setExpanding(false), 500);
    }
  }, [expanded]);

  useEffect(() => {
    if (joinersAndLeavers) {
      const years = Object.keys(joinersAndLeavers);
      setRange({ minYear: years[0], maxYear: years[years.length - 1] });
    }
  }, [joinersAndLeavers]);

  function handleClickAway(e) {
    if (e.target.classList.contains('leaver') || e.target.classList.contains('joiner')) {
      return;
    }
    closeTooltip();
  }

  function showTooltip(node, d, leaversOrJoiners) {
    if (!expanded || expanding) {
      return;
    }

    const boundingClientRect = node.getBoundingClientRect();
    setTooltipEmployeeIds(d[leaversOrJoiners].map(({ employeeId }) => employeeId));
    setTooltipJoinersAndLeavers({ data: d, leaversOrJoiners });
    setTooltipAnchorEl({
      clientWidth: boundingClientRect.width,
      clientHeight: boundingClientRect.height,
      getBoundingClientRect: () => boundingClientRect,
    });
    setTooltipOpen(true);
  }

  function handleItemOver(node, d, leaversOrJoiners) {
    showTooltip(node, d, leaversOrJoiners);
  }

  function ensureExpanded() {
    if (!expanded) {
      onExpandToggle();
    }
  }

  return (
    <>
      <div
        css={`
          height: 100%;
          padding: 2px 12px 8px 12px;
        `}
      >
        {fundManager && (
          <>
            {!fundManager.userId ? (
              <>
                {joinersAndLeavers && expanded && range && (
                  <div
                    css={`
                      position: absolute;
                      font-size: 18px;
                      font-weight: 300;
                      color: #b8b8b8;
                      display: flex;
                      justify-content: center;
                      width: 100%;
                      top: 60px;
                    `}
                  >
                    {`Team Stability, ${range.minYear}-${range.maxYear}`}
                    <span
                      css={`
                        margin-left: 4px;
                        color: #7a7a7a;
                      `}
                    >
                      ({fundManager.name})
                    </span>
                  </div>
                )}
                {joinersAndLeavers && (
                  <FundManagerJoinersAndLeavers
                    joinersAndLeavers={joinersAndLeavers}
                    expanded={expanded}
                    onClick={() => ensureExpanded()}
                    onItemOver={handleItemOver}
                  />
                )}
              </>
            ) : (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                `}
              >
                <UserFundOrManagerMessage label="fund manager" />
              </div>
            )}
          </>
        )}
      </div>

      <FundManagerJoinersAndLeaversTooltip
        onClickAway={handleClickAway}
        fundManager={fundManager}
        joinersAndLeavers={tooltipJoinersAndLeavers}
        employees={tooltipEmployees}
        open={tooltipOpen}
        anchorEl={tooltipAnchorEl}
        onClose={closeTooltip}
      />
    </>
  );
}

FundManagerJoinersAndLeaversVisualization.defaultProps = {
  onShare: () => {},
  onExpandToggle: () => {},
};

FundManagerJoinersAndLeaversVisualization.propTypes = {
  fundManagerId: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onShare: PropTypes.func,
  onExpandToggle: PropTypes.func,
};

export default FundManagerJoinersAndLeaversVisualization;
