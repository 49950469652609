import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconCheckboxChecked(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M83,18 L83,83 L18,83 L18,18 L83,18 Z M78,23 L23,23 L23,78 L78,78 L78,23 Z M65.5,36.964 L69.035,40.5 L45.5,64.036 L31.964,50.5 L35.5,46.964 L45.5,56.964 L65.5,36.964 Z" />
    </Svg>
  );
}
