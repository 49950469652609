import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { Flexbox } from '../..';

const minWidth = 50;
const maxWidth = 95;

function NewsArticleSkeleton({ length, ...rest }) {
  function width() {
    return Math.floor(Math.random() * (maxWidth - minWidth) + minWidth);
  }
  return (
    <div
      css={`
        width: 100%;
      `}
      {...rest}
    >
      {Array(length)
        .fill(null)
        .map((_, index) => (
          <Flexbox
            key={index}
            css={`
              margin-bottom: 15px;
            `}
          >
            <Skeleton variant="circle" width={5} height={5} style={{ margin: '0 20px 0 15px' }} />
            <Skeleton variant="rect" height={50} width={`${width()}%`} />
          </Flexbox>
        ))}
    </div>
  );
}

NewsArticleSkeleton.defaultProps = {};

NewsArticleSkeleton.propTypes = {
  length: PropTypes.number.isRequired,
};

export default NewsArticleSkeleton;
