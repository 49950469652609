import React from 'react';
import { useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { navigate } from '@reach/router';
import { useLayoutActions } from 'src/hooks';
import { AppPanel, Box, ButtonArrowLeft, ButtonArrowRight, DashboardHierarchy } from 'src/components';
import useDashboardHierarchy from './useDashboardHierarchy';
import useBookmarkedFunds from './useBookmarkedFunds';
import useBookmarkedFundManagers from './useBookmarkedFundManagers';
import useBookmarkedEmployees from './useBookmarkedEmployees';
import FundsView from './FundsView';
import ManagersView from './ManagersView';
import EmployeesView from './EmployeesView';

const arrowStyles = css`
  position: absolute;
  top: 15px;
  right: 5px;
  z-index: 1;
  color: #000;
  background-color: #e1b72a;
  &:hover {
    background-color: #ffcf2d;
  }
`;

export function DashboardFollowing({ showFunds, showManagers, showPeople }) {
  const { isExpanded } = useContext(AppPanel.Context);
  const { expandPanel } = useLayoutActions();

  const [wideTable, setWideTable] = useState(true);
  const [isArrowLeft, setIsArrowLeft] = useState(true);

  const fundManagers = useBookmarkedFundManagers();
  const funds = useBookmarkedFunds();
  const employees = useBookmarkedEmployees();

  const [selectedType, setSelectedType] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedParentId, setSelectedParentId] = useState('');

  const dashboardHierarchy = useDashboardHierarchy({
    funds,
    fundManagers,
    employees,
    selectedId,
    selectedType,
    selectedParentId,
  });

  useEffect(() => {
    if (isExpanded) return;
    setSelectedType('');
    setSelectedId('');
    setSelectedParentId('');
  }, [isExpanded]);

  function toggleWideTable() {
    if (wideTable === true) {
      setWideTable(false);
      setIsArrowLeft(!isArrowLeft);
    }
    if (wideTable === false) {
      setWideTable(true);
      setIsArrowLeft(!isArrowLeft);
    }
  }

  function handleWideTableToggle() {
    toggleWideTable();
  }

  const navigateTo = useCallback(({ id, type, parentId }) => {
    const path = {
      fund: `/funds/${id}`,
      manager: `/managers/${id}`,
      employee: `/managers/${parentId}`,
    }[type];

    return navigate(path);
  }, []);

  const handleClickHierarchyNode = useCallback(
    ({ id, type, parentId, isSelected }) => {
      if (isSelected) return navigateTo({ id, type, parentId });

      setSelectedId(id);
      setSelectedType(type);
      setSelectedParentId(parentId);
    },
    [navigateTo]
  );

  return (
    <>
      <div
        css={`
          height: 100%;
          display: flex;
        `}
      >
        <Box position="relative" width={wideTable ? '700px' : '450px'} style={{ transition: 'width 300ms' }}>
          {isArrowLeft ? (
            <ButtonArrowLeft onClick={handleWideTableToggle} css={arrowStyles} />
          ) : (
            <ButtonArrowRight onClick={handleWideTableToggle} css={arrowStyles} />
          )}
          {showManagers && (
            <ManagersView
              fundManagers={fundManagers}
              onRowClick={() => expandPanel()}
              onChangeSelectedId={id => {
                if (selectedId === id) {
                  return navigateTo({ id, type: 'manager' });
                }

                setSelectedId(id);
                setSelectedType('manager');
                setSelectedParentId(id);
              }}
              isExpanded={isExpanded}
              wideTable={wideTable}
              selectedId={selectedParentId || (selectedType === 'manager' ? selectedId : '')}
            />
          )}
          {showFunds && (
            <FundsView
              funds={funds}
              isExpanded={isExpanded}
              wideTable={wideTable}
              onRowClick={() => !isExpanded && expandPanel()}
              selectedId={selectedType === 'fund' ? selectedId : ''}
              onChangeSelectedId={id => {
                const parentId = funds.find(e => e.fundId === id)?.fundManagerId;

                if (selectedId === id) {
                  return navigateTo({ id, type: 'fund' });
                }

                setSelectedId(id);
                setSelectedType('fund');
                setSelectedParentId(parentId);
              }}
            />
          )}
          {showPeople && (
            <EmployeesView
              employees={employees}
              isExpanded={isExpanded}
              wideTable={wideTable}
              onRowClick={() => !isExpanded && expandPanel()}
              selectedId={selectedType === 'employee' ? selectedId : ''}
              onChangeSelectedId={id => {
                const parentId = employees.find(e => e.employeeId === id)?.currentFundManagerId;

                if (selectedId === id) {
                  return navigateTo({ parentId, type: 'employee' });
                }

                setSelectedId(id);
                setSelectedType('employee');
                setSelectedParentId(parentId);
              }}
            />
          )}
        </Box>
        {dashboardHierarchy && (
          <DashboardHierarchy
            css={`
              flex: 1;
            `}
            data={dashboardHierarchy}
            expanded={isExpanded}
            onClick={() => !isExpanded && expandPanel()}
            selectedType={selectedType}
            selectedId={selectedId}
            onClickNode={handleClickHierarchyNode}
          />
        )}
      </div>
    </>
  );
}

DashboardFollowing.defaultProps = {
  showFunds: true,
  showManagers: false,
  showPeople: false,
};

DashboardFollowing.propTypes = {
  showFunds: PropTypes.bool,
  showManagers: PropTypes.bool,
  showPeople: PropTypes.bool,
};

export default DashboardFollowing;
