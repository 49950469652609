import React from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import { useStrategyTimeseriesQuery } from 'src/hooks';
import { IconClose, CashflowDataVisualization } from 'src/components';
import ChartTypeSelect from '../../ChartTypeSelect';

const { TYPES, VIEWS } = CashflowDataVisualization.CONSTANTS;

function ViewTargetFundDialog({ title, strategyId, startDate, commitmentAmount, open, onClose, className }) {
  const [typeSelect, setTypeSelect] = useState(TYPES.JCURVE_MEAN);

  const timeseriesQuery = useStrategyTimeseriesQuery({
    strategyId,
  });

  const timeseries = useMemo(() => {
    if (!timeseriesQuery?.data?.series) return [];

    return timeseriesQuery.data.series
      .map((d, q) => {
        if (!d) return { q };
        return { q, ...d, commitmentAmount };
      })
      .filter(Boolean);
  }, [commitmentAmount, timeseriesQuery]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={className}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        disableTypography
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Typography variant="h6" title={title}>
          {title}
        </Typography>

        <div>
          <ChartTypeSelect
            value={typeSelect}
            options={[TYPES.JCURVE_MEAN, TYPES.NAV, TYPES.CASHFLOW]}
            labels={{
              [TYPES.JCURVE_MEAN]: 'jCurve',
              [TYPES.NAV]: TYPES.NAV,
              [TYPES.CASHFLOW]: TYPES.CASHFLOW,
            }}
            onChange={value => {
              setTypeSelect(value);
            }}
          />
        </div>

        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <IconClose />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <div
          css={`
            height: 70vh;
          `}
        >
          <CashflowDataVisualization
            type={typeSelect}
            view={VIEWS.CHART}
            subjectTimeseries={useMemo(() => [], [])}
            forecastTimeseries={timeseries}
            startDate={startDate}
            css={`
              flex: 1;
              background: ${timeseriesQuery.isLoading ? 'transparent' : '#1c1c1c;'};

              &::before {
                display: ${timeseriesQuery.isLoading ? 'flex' : 'none'};
                content: 'Generating forecast...';
                position: absolute;
                height: 100%;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
            `}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

ViewTargetFundDialog.defaultProps = {
  startDate: new Date(),
  commitmentAmount: 1_000_000,
  open: false,
  onClose: funcStub('onClose'),
};

ViewTargetFundDialog.propTypes = {
  title: PropTypes.string,
  strategyId: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  commitmentAmount: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewTargetFundDialog;
