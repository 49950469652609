import { createTheme as createMuiTheme } from '@material-ui/core';
import { typography } from './typography';
import { dark, light } from './palettes';

function createTheme(lightOrDark) {
  const theme = createMuiTheme({
    palette: lightOrDark === 'light' ? light : dark,
    typography,
    props: {
      MuiTextField: {
        variant: 'outlined',
      },
      MuiLink: {
        color: 'textPrimary',
      },
      MuiTypography: {
        variant: 'body1',
      },
    },
    shape: {
      borderRadius: 2,
    },
  });

  return {
    ...theme,
    overrides: {
      MuiButton: {
        containedPrimary: {
          // color: '#000'
        },
        containedSizeSmall: {
          padding: theme.spacing(0.25, 2, 0.25, 1.25),
        },
        startIcon: {
          marginRight: `${theme.spacing(0.75)}px`,
          marginLeft: 0,
        },
      },
      MuiInputBase: {
        input: {
          // fontSize: 14
        },
      },
      MuiOutlinedInput: {
        input: {
          backgroundColor: theme.palette.background.paper,
        },
        inputMarginDense: {
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
        },
      },
    },
  };
}

export const themeDark = createTheme('dark');
export const themeLight = createTheme('light');
export { lightenDarkenColor } from './lighten-darken-color';
export { toRgba } from './to-rgba';
export { default as breakpoints } from './breakpoints';
