import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchEmployeesError = createAction('FETCH_EMPLOYEES_ERROR');
const fetchEmployeesPending = createAction('FETCH_EMPLOYEES_PENDING');
const fetchEmployeesSuccess = createAction('FETCH_EMPLOYEES_SUCCESS');
const fetchFundManagerEmployeesError = createAction('FETCH_FUND_MANAGER_EMPLOYEES_ERROR');
const fetchFundManagerEmployeesPending = createAction('FETCH_FUND_MANAGER_EMPLOYEES_PENDING');
const fetchFundManagerEmployeesSuccess = createAction('FETCH_FUND_MANAGER_EMPLOYEES_SUCCESS');

export const bookmarkEmployee = createAction('BOOKMARK_EMPLOYEE');

export function fetchEmployees({ employeeIds }) {
  return async function (dispatch, getState) {
    const { byId } = getState().employee.all;
    const employeesToFetch = employeeIds.filter(id => !byId[id]);
    if (employeesToFetch.length === 0) return;
    employeesToFetch.forEach(employeeId => dispatch(fetchEmployeesPending({ employeeId })));
    try {
      const employees = await filterApi.getEmployees({ employeeIds: employeesToFetch });
      employees.forEach(employee => dispatch(fetchEmployeesSuccess({ employee })));
    } catch (error) {
      employeesToFetch.forEach(employeeId => dispatch(fetchEmployeesError({ error: error.message, employeeId })));
    }
  };
}

export function fetchFundManagerEmployees({ fundManagerId }) {
  return async function (dispatch, getState) {
    const { byFundManagerId } = getState().employee.all;
    if (byFundManagerId[fundManagerId]) return;
    dispatch(fetchFundManagerEmployeesPending({ fundManagerId }));

    try {
      const currentEmployees = await filterApi.getFundManagerCurrentEmployees({ fundManagerId });
      dispatch(fetchFundManagerEmployeesSuccess({ currentEmployees, fundManagerId }));
    } catch (error) {
      dispatch(fetchFundManagerEmployeesError({ error: error.message, fundManagerId }));
    }
  };
}

const initialState = {
  byId: {},
  byFundManagerId: {},
};

export default createReducer(initialState, {
  [fetchEmployeesPending]: (state, action) => {
    const { employeeId } = action.payload;
    const employee = state.byId[employeeId] || {};
    employee.fetching = true;
    state.byId[employeeId] = employee;
  },

  [fetchEmployeesError]: (state, action) => {
    const { error, employeeId } = action.payload;
    const employee = state.byId[employeeId] || {};
    employee.fetching = true;
    employee.error = error;
    state.byId[employeeId] = employee;
    console.error(error);
  },
  [fetchEmployeesSuccess]: (state, action) => {
    const { employee } = action.payload;
    employee.error = null;
    employee.fetching = false;
    employee.fetched = true;
    state.byId[employee.employeeId] = employee;
  },
  [fetchFundManagerEmployeesPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    const currentEmployees = state.byFundManagerId[fundManagerId] || {};
    currentEmployees.fetching = true;
    state.byFundManagerId[fundManagerId] = currentEmployees;
  },
  [fetchFundManagerEmployeesError]: (state, action) => {
    const { error, fundManagerId } = action.payload;
    const currentEmployees = state.byFundManagerId[fundManagerId] || {};
    currentEmployees.error = error;
    currentEmployees.fetching = false;
    state.byFundManagerId[fundManagerId] = currentEmployees;
    console.error(error);
  },
  [fetchFundManagerEmployeesSuccess]: (state, action) => {
    const { currentEmployees, fundManagerId } = action.payload;
    currentEmployees.forEach(employee => {
      state.byId[employee.employeeId] = { ...employee, fetched: true, fetching: false, error: null };
    });
    state.byFundManagerId[fundManagerId] = {
      employeeIds: currentEmployees.map(e => e.employeeId),
      fetched: true,
      fetching: false,
      error: null,
    };
  },
  [bookmarkEmployee]: (state, action) => {
    const { employeeId, bookmarked } = action.payload;
    const employee = state.byId[employeeId];
    if (employee) {
      employee.bookmarked = bookmarked;
    }
  },
});
