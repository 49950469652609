import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { filterApi } from 'src/services';
import * as date from 'src/utils/date';
import { useCurrentUser, useSubscriptionPlans, useStripeCustomer, useCurrentSubscription } from 'src/hooks';
import { IconPencil, Button } from 'src/components';
import FormSubmitButton from './FormSubmitButton';
import FormCancelButton from './FormCancelButton';
import Plan from './Plan';
import CreateSubscriptionDialog from './CreateSubscriptionDialog';
import ConfirmPasswordDialog from './ConfirmPasswordDialog';

const DEFAULT_INTERVAL = 'month';

const Footer = styled.footer`
  margin-top: 1.5rem;
  padding-top: 0.75rem;
`;

function SubscriptionPlans() {
  const currentUser = useCurrentUser();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedInterval, setSelectedInterval] = useState();
  const [isEditing, setIsEditing] = useState();
  const [isSubmitting, setIsSubmitting] = useState();
  const [requirePassword, setRequirePassword] = useState();
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);

  const { data: plans, isLoading: isLoadingPlans, error: plansError } = useSubscriptionPlans();

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    refetch: refetchCustomer,
    error: customerError,
  } = useStripeCustomer();

  const {
    data: currentSubscription,
    isLoading: isLoadingCurrentSubscription,
    error: subscriptionError,
    refetch: refetchCurrentSubscription,
  } = useCurrentSubscription();

  const isLoading = isLoadingPlans || isLoadingCustomer || isLoadingCurrentSubscription;
  const error = plansError || customerError || subscriptionError;

  async function cancelCurrentSubscription() {
    await filterApi.stripeCancelSubscription({
      subscriptionId: currentSubscription.id,
    });
  }

  async function handleCancelSubscription() {
    setIsSubmitting(true);
    await cancelCurrentSubscription();
    await refetchCurrentSubscription();
    setIsEditing(false);
    setIsSubmitting(false);
    setOpenConfirmCancel(false);
  }

  useEffect(() => setSelectedPlan(null), []);

  if (isLoading || !currentUser) {
    return (
      <section>
        <Skeleton variant="text" />
        <span />
        <Skeleton variant="rect" height={162} />
        <span />
      </section>
    );
  }

  const interval = selectedInterval || currentSubscription?.plan?.interval || DEFAULT_INTERVAL;

  if (error) throw error;

  return (
    <>
      <section>
        <header>
          <h3>Subscription Plans</h3>
          {currentSubscription ? (
            <Button size="small" variant="outlined" disabled={isEditing} onClick={() => setIsEditing(state => !state)}>
              <IconPencil />
              Edit
            </Button>
          ) : (
            <div>
              <ToggleButtonGroup
                size="small"
                value={interval}
                exclusive
                onChange={(_, value) => {
                  if (!value) return;
                  setSelectedInterval(value);
                }}
              >
                <ToggleButton value="month">Monthly</ToggleButton>
                {/*<ToggleButton value="year">Yearly</ToggleButton>*/}
              </ToggleButtonGroup>
            </div>
          )}
        </header>
        <span />
        <div
          css={`
            display: grid;
            gap: 1.5em;
            grid-template-rows: auto;
            grid-template-columns: 1fr;
          `}
        >
          {plans
            .filter(plan => {
              return plan.recurring.interval === interval;
            })
            .map(plan => {
              return (
                <Plan
                  key={plan.id}
                  plan={plan}
                  customer={customer}
                  trialExpiryDate={date.parseISO(currentUser.trialExpiresAt)}
                  description={`The ${plan.product.name} gets you unlimited access to everything that FundFilter has to offer.`}
                  onClickSubscribe={setSelectedPlan}
                />
              );
            })}
        </div>
        <span />

        {currentSubscription && isEditing && (
          <footer>
            <FormSubmitButton
              color="danger"
              isSubmitting={isSubmitting}
              disabled={!isEditing || isLoading}
              onClick={() => {
                setRequirePassword(true);
                setIsSubmitting(true);
              }}
            >
              Cancel Subscription
            </FormSubmitButton>

            <FormCancelButton
              disabled={!isEditing || isSubmitting || isLoading}
              onClick={() => {
                setIsEditing(false);
              }}
            />
          </footer>
        )}
      </section>

      <CreateSubscriptionDialog
        open={Boolean(selectedPlan)}
        onClose={() => {
          setSelectedPlan();
          refetchCustomer();
        }}
        plan={selectedPlan}
      />

      <ConfirmPasswordDialog
        open={requirePassword}
        onSuccess={() => {
          setRequirePassword(false);
          setOpenConfirmCancel(true);
          setIsSubmitting(false);
        }}
        onFailure={({ reason }) => {
          if (reason === 'user-canceled') setRequirePassword(false);
          setIsSubmitting(false);
        }}
      />

      <Dialog
        open={Boolean(openConfirmCancel)}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') return;
          setOpenConfirmCancel(false);
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Subscription Cancelation</DialogTitle>
        <DialogContent>
          <p>
            Are you sure you want to cancel your subscription? You will loose access to FundFilter&apos;s
            subscriber-only views.
          </p>
          <Footer>
            <Button
              variant="contained"
              color="danger"
              disableElevation
              onClick={handleCancelSubscription}
              $isLoading={isSubmitting}
            >
              Yes, please cancel my subscription
            </Button>{' '}
            <Button
              disableElevation
              onClick={() => {
                setOpenConfirmCancel(false);
                setIsEditing(false);
              }}
              disabled={isSubmitting}
            >
              I&apos;ll keep my subscription going
            </Button>
          </Footer>
        </DialogContent>
      </Dialog>
    </>
  );
}

SubscriptionPlans.defaultProps = {
  onOpenPaymentDialog: () => {},
  onClosePaymentDialog: () => {},
};

SubscriptionPlans.propTypes = {
  children: PropTypes.any,
};

export default SubscriptionPlans;
