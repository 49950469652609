import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const Container = styled.div`
  height: ${props => props.height || '100%'};
  padding: ${props => props.padding};

  .tick {
    line {
      color: #2e2e2e;
    }
    text {
      font-size: 10px;
      fill: #808080;
    }
  }

  .axis-label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
  }
  .domain {
    opacity: 0;
    // color: #2e2e2e;
  }
  .shadow {
    filter: drop-shadow(0px 3px 8px #000);
    -webkit-filter: drop-shadow(0px 3px 8px #000);
    -moz-filter: drop-shadow(0px 3px 8px #000);
  }
`;

const MARGIN = { top: 10, right: 60, bottom: 25, left: 60 };

function StrategyPerformanceTimeSeries({
  data,
  metric,
  timeRange,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
  ...rest
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(() => {
    draw({
      id: id.current,
      data,
      timeRange,
      metric,
      size: { width, height },
      margin: MARGIN,
      mouseEventRefs,
    });
  }, [data, metric, timeRange, width, height, mouseEventRefs]);

  return (
    <Container ref={targetRef} {...rest}>
      <svg id={id.current} style={{ width: '100%', height: '100%', display: 'block' }} />
    </Container>
  );
}

StrategyPerformanceTimeSeries.defaultProps = {
  expanded: false,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

StrategyPerformanceTimeSeries.propTypes = {
  data: PropTypes.array.isRequired,
  metric: PropTypes.object.isRequired,
  timeRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(StrategyPerformanceTimeSeries);
