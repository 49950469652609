import { useMemo } from 'react';
import { useFunds, useUserFunds, useBookmarkedFundIds } from 'src/hooks';

function useBookmarkedFunds() {
  const bookmarkedFundIds = useBookmarkedFundIds();
  const userFunds = useUserFunds();

  const fundIds = useMemo(() => {
    if (!bookmarkedFundIds) return;
    if (!userFunds) return;
    return [...bookmarkedFundIds, ...userFunds.map(f => f.fundId)];
  }, [bookmarkedFundIds, userFunds]);

  const funds = useFunds({ fundIds });

  return funds;
}

export default useBookmarkedFunds;
