function hasParentClass(child, classname) {
  try {
    if (child.className.split(' ').indexOf(classname) >= 0) return true;
    //Throws TypeError if child doesn't have parent any more
    return child.parentNode && hasParentClass(child.parentNode, classname);
  } catch (TypeError) {
    return false;
  }
}

module.exports = { hasParentClass };
