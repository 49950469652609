import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popper, Grow, ClickAwayListener } from '@material-ui/core';
import { TooltipContent, TooltipCloseIcon, TooltipArrowUp } from 'src/components';

const Layout = styled.div`
  position: relative;

  ${TooltipCloseIcon} {
    position: absolute;
    right: 10px;
    top: 5px;
  }
`;

const StyledToolTipContent = styled(TooltipContent)`
  width: 300px;
`;

function NewsArticleTooltip({ open, anchorEl, onClickAway, onClose, className, children }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        className={className}
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,15',
          },
          arrow: {
            enabled: true,
            element: '.news-article-arrow',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <Layout>
              <TooltipArrowUp className="news-article-arrow" marginLeft="2px" />
              <TooltipCloseIcon onClick={onClose} />
              <StyledToolTipContent>{children}</StyledToolTipContent>
            </Layout>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
NewsArticleTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClickAway: () => {},
  onClose: () => {},
  onViewArticle: () => {},
};

NewsArticleTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onClose: PropTypes.func,
};

export default NewsArticleTooltip;
