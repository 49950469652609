import React from 'react';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFundManager } from 'src/hooks';
import { AppPanel, FundManagerFundRaisingVisualization } from 'src/components';

function FundManagerFundRaisingPanel({ spanX, spanY, minHeight, fundManagerId }) {
  const fundManager = useFundManager({ fundManagerId }) || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const title = useMemo(() => {
    if (isExpanded) return `${fundManager.name} - Fund Raising`;
    return 'Fund Raising';
  }, [fundManager.name, isExpanded]);

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title={title}
      onChangeExpanded={setIsExpanded}
      expandable
    >
      <FundManagerFundRaisingVisualization fundManager={fundManager} />
    </AppPanel>
  );
}

FundManagerFundRaisingPanel.defaultProps = {};

FundManagerFundRaisingPanel.propTypes = {
  fundManagerId: PropTypes.string.isRequired,
};

export default FundManagerFundRaisingPanel;
