import { useState } from 'react';

function useVintagesSelectionFilter() {
  return useState([
    { label: 'Previous Vintage', vintageId: -1, disabled: false, selected: true },
    { label: 'Current Vintage', vintageId: 0, disabled: false, selected: true },
    { label: 'Next Vintage', vintageId: 1, disabled: false, selected: true },
  ]);
}

export default useVintagesSelectionFilter;
