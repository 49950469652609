function getCanvas({ svg, margin }) {
  if (!svg.node()) return {};

  const svgBox = svg.node().getBoundingClientRect();

  const reference = svg
    .selectAll('.reference')
    .data([0])
    .join('rect')
    .attr('class', 'reference')
    .style('fill', '#ffffff11')
    .style('stroke', '#cccccc33')
    .style('transform', `translate(${margin.left}, ${margin.top}`)
    .attr('width', `calc(${svgBox.width}px - ${margin.left} - ${margin.right})`)
    .attr('height', `calc(${svgBox.height}px - ${margin.top} - ${margin.bottom})`);

  const box = reference.node().getBoundingClientRect();

  const top = box.top - svgBox.top;
  const left = box.left - svgBox.left;
  const right = left + box.width;
  const bottom = top + box.height;

  reference.remove();

  return {
    width: box.width,
    height: box.height,
    top,
    left,
    right,
    bottom,
  };
}

export default getCanvas;
