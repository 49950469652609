import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFundId } from 'src/store/last-selected';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { AppLayout } from 'src/components';
import FundsPanel from './FundsPanel';
import NewsPanel from './NewsPanel';
import BookmarksPanel from './BookmarksPanel';

function DashboardPage({ fundId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (fundId) dispatch(setFundId({ fundId }));
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.saved);
  }, [dispatch, fundId]);

  return (
    <AppLayout>
      <FundsPanel spanX="half" spanY="half" minHeight={300} />
      <NewsPanel spanX="half" spanY="half" minHeight={400} />
      <BookmarksPanel spanX="full" spanY="half" minHeight={300} />
    </AppLayout>
  );
}

export default DashboardPage;
