import { select, scaleLinear, easeCubicInOut } from 'd3';

const CONFIG = {
  levels: 10,
  opacityArea: 0.2,
  scaleColor: '#363636',
  labelColor: '#666',
  labelPad: 40,
};

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function create({ id }) {
  const prefixedId = `#${id}`;

  const root = select(prefixedId).append('g').attr('class', 'root');

  return root;
}

// this is where the magic happens
function angleToCoordinate(fractionOfCircle, value) {
  // the fraction of a circle is 0.25, 0.5 etc.
  const x = -Math.sin(fractionOfCircle * 2 * Math.PI) * value;
  const y = -Math.cos(fractionOfCircle * 2 * Math.PI) * value;
  return { x, y };
}

function draw({ id, data, metrics, showMedians, size, margin, mouseEventRefs }) {
  if (size.width < 50 || size.height < 50) return;

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;
  const visibleData = data.filter(d => Boolean(d.color));

  let root = select(`#${id} .root`);

  if (!root.node()) {
    root = create({ id });
  }

  // set the dimensions and margins of the graph
  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;
  const radius = Math.min(svgHeight / 2, svgWidth / 2) - CONFIG.labelPad;
  const translate = `translate(${radius}, ${radius + CONFIG.labelPad})`;
  const range = [radius / 5, radius];
  const scales = {};
  const t = root.transition().duration(500).ease(easeCubicInOut);

  root.attr('transform', `translate(${margin.left + (size.width - radius * 2) / 2},${margin.top})`);

  metrics.forEach(
    // eslint-disable-next-line no-return-assign
    (metric, i) => (scales[metrics[i].value] = scaleLinear().domain(metric.extent).range(range))
  );

  const apexCount = metrics.length;

  const levels = Array(CONFIG.levels)
    .fill(null)
    .map((_, index) => {
      return radius * ((index + 1) / CONFIG.levels);
    });

  // scale bands
  levels.forEach((level, index) => {
    const band = root.selectAll(`.tick.level-${index}`).data(metrics, d => d.value);

    // remove old bands
    band.exit().transition(t).remove();

    // update existing bands
    band
      .transition(t)
      .attr('x1', (_d, i) => angleToCoordinate(i / apexCount, level).x)
      .attr('y1', (_d, i) => angleToCoordinate(i / apexCount, level).y)
      .attr('x2', (_d, i) => angleToCoordinate((i + 1) / apexCount, level).x)
      .attr('y2', (_d, i) => angleToCoordinate((i + 1) / apexCount, level).y)
      .attr('transform', translate);

    // add new bands
    band
      .enter()
      .append('line')
      .attr('class', `tick level-${index}`)
      .attr('stroke', CONFIG.scaleColor)
      .attr('x1', (_d, i) => angleToCoordinate(i / apexCount, level).x)
      .attr('y1', (_d, i) => angleToCoordinate(i / apexCount, level).y)
      .attr('x2', (_d, i) => angleToCoordinate((i + 1) / apexCount, level).x)
      .attr('y2', (_d, i) => angleToCoordinate((i + 1) / apexCount, level).y)
      .attr('transform', translate);
  });

  const legend = root.selectAll('.legend').data(metrics, d => d.value);

  // remove old labels
  legend.exit().transition(t).remove();

  // update existing labels
  legend
    .transition(t)
    .attr('x', (_d, i) => angleToCoordinate(i / apexCount, radius).x)
    .attr('y', (_d, i) => angleToCoordinate(i / apexCount, radius).y)
    .attr('transform', translate);

  // add the labels
  legend
    .enter()
    .append('text')
    .attr('class', 'legend')
    .style('font-family', 'Roboto')
    .attr('text-anchor', 'middle')
    .attr('fill', CONFIG.labelColor)
    .attr('dx', d => d.offset.x)
    .attr('dy', d => d.offset.y)
    .style('font-size', '9px')
    .attr('x', (_d, i) => angleToCoordinate(i / apexCount, radius).x)
    .attr('y', (_d, i) => angleToCoordinate(i / apexCount, radius).y)
    .text(d => d.label)
    .attr('transform', translate);

  // medians

  let medians;
  if (showMedians) {
    medians = root.selectAll('.median').data([
      metrics.reduce((acc, m) => {
        acc[m.value] = m.median;
        return acc;
      }, {}),
    ]);
  } else {
    medians = root.selectAll('.median').data([]);
  }

  // remove old medians
  medians
    .exit()
    .transition(t)
    .attr('points', () => {
      const points = metrics.map(() => ({ x: 0, y: 0 }));
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .remove();

  // update existing medians
  medians
    .on('mouseover', handleMouseOver)
    .on('mouseout', handleMouseOut)
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('points', d => {
      const points = metrics.map((metric, i) =>
        angleToCoordinate(i / apexCount, scales[metric.value](d[metric.value]))
      );
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .attr('transform', translate);

  // add new medians
  medians
    .enter()
    .append('polygon')
    .style('stroke-width', '1px')
    .style('stroke', d => d.color)
    .style('fill', d => d.color)
    .style('fill-opacity', 0)
    .attr('class', 'median')
    .attr('data-series', d => d.fundId)
    .attr('transform', translate)
    .attr('points', () => {
      const points = metrics.map(() => ({ x: 0, y: 0 }));
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .on('mouseover', handleMouseOver)
    .on('mouseout', handleMouseOut)
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('points', d => {
      const points = metrics.map((metric, i) =>
        angleToCoordinate(i / apexCount, scales[metric.value](d[metric.value]))
      );
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .style('stroke', '#e1b72a')
    .style('stroke-width', '2px');

  // the poligons
  const poligons = root.selectAll('.area').data(visibleData.reverse(), d => d.fundId);

  // remove old poligons
  poligons
    .exit()
    .transition(t)
    .attr('points', () => {
      const points = metrics.map(() => ({ x: 0, y: 0 }));
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .remove();

  // update existing poligons
  poligons
    // .style('stroke', d => d.color)
    // .style('fill', d => d.color)
    .on('mouseover', handleMouseOver)
    .on('mouseout', handleMouseOut)
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('points', d => {
      const points = metrics.map((metric, i) =>
        angleToCoordinate(
          i / apexCount,
          scales[metric.value](d[metric.value] == null ? metric.median : d[metric.value])
        )
      );
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .style('stroke', d => d.color)
    .style('fill', d => d.color)
    .style('fill-opacity', CONFIG.opacityArea)
    .attr('transform', translate);

  // add new poligons
  poligons
    .enter()
    .append('polygon')
    .style('stroke-width', '1px')
    .style('stroke', d => d.color)
    .style('fill', d => d.color)
    .style('fill-opacity', 0)
    .attr('class', d => `area series-${d.fundId}`)
    .attr('data-series', d => d.fundId)
    .attr('transform', translate)
    .attr('points', () => {
      const points = metrics.map(() => ({ x: 0, y: 0 }));
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .on('mouseover', handleMouseOver)
    .on('mouseout', handleMouseOut)
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('points', d => {
      const points = metrics.map((metric, i) =>
        angleToCoordinate(
          i / apexCount,
          scales[metric.value](d[metric.value] == null ? metric.median : d[metric.value])
        )
      );
      return points.map(p => `${p.x},${p.y}`).join(',');
    })
    .style('stroke', d => d.color)
    .style('fill', d => d.color)
    .style('fill-opacity', CONFIG.opacityArea);

  // the apex points
  metrics.forEach((metric, index) => {
    const points = root.selectAll(`.point-${metric.value}`).data(visibleData.reverse(), d => d.fundId);

    // reove old apex points
    points.exit().transition(t).style('cx', 0).style('cy', 0).remove();

    //  update existing apex points
    points
      //.style('fill', d => d.color)
      .transition(t)
      .style('fill', d => d.color)
      .style('stroke', d => d.color)
      .style('stroke-width', '1px')
      .style('fill-opacity', d => (d[metric.value] == null ? 0 : 1))
      .style(
        'cx',
        d =>
          angleToCoordinate(
            index / apexCount,
            scales[metric.value](d[metric.value] == null ? metric.median : d[metric.value])
          ).x
      )
      .style(
        'cy',
        d =>
          angleToCoordinate(
            index / apexCount,
            scales[metric.value](d[metric.value] == null ? metric.median : d[metric.value])
          ).y
      )
      .attr('transform', translate);

    // add new apex points
    points
      .enter()
      .append('circle')
      .style('fill', d => d.color)
      .style('stroke', d => d.color)
      .style('stroke-width', '1px')
      .style('fill-opacity', d => (d[metric.value] == null ? 0 : 1))
      .style('r', 3.5)
      .style('cx', 0.1)
      .style('cy', 0.1)
      .attr('class', d => `point point-${metric.value} series-${d.fundId}`)
      .attr('data-series', d => d.fundId)
      .attr('transform', translate)
      .transition(t)
      .style(
        'cx',
        d =>
          angleToCoordinate(
            index / apexCount,
            scales[metric.value](d[metric.value] == null ? metric.median : d[metric.value])
          ).x
      )
      .style(
        'cy',
        d =>
          angleToCoordinate(
            index / apexCount,
            scales[metric.value](d[metric.value] == null ? metric.median : d[metric.value])
          ).y
      );
  });

  function handleMouseOver(_, d) {
    const item = select(this);
    const series = item.attr('data-series');

    root.selectAll('.area, .point').transition('mouseover').style('fill-opacity', 0.1).style('stroke-opacity', 0.1);
    root.selectAll(`.area.series-${series}`).transition('mouseover').style('fill-opacity', 0.35);
    root.selectAll(`.point.series-${series}`).transition('mouseover').style('fill-opacity', 1);

    itemOver.current(this, d);
  }

  function handleMouseOut(_, d) {
    root.selectAll('.area').transition('mouseout').style('fill-opacity', CONFIG.opacityArea).style('stroke-opacity', 1);
    root.selectAll('.point').transition('mouseout').style('fill-opacity', 1);

    itemOut.current(this, d);
  }
}

export default draw;
