import { css } from 'styled-components';
import { breakpoints } from 'src/components/themes';

const baseStyles = css`
  transition: font-size 500ms ease;

  .rectangle {
    min-height: 0;
    cursor: pointer;
    padding: 4px 8px;
    color: #fff;
    border: 2px solid transparent;
    transition: background-color 500ms cubic-bezier(0.5, 0, 0.5, 1);

    &:hover {
      border-color: #4a4a4a;
      box-shadow: 0 0 7px rgba(47, 47, 47, 1);
      z-index: 1;
    }
  }

  .text-layer {
    font-size: inherit;
    position: relative;
    height: 100%;
  }

  .text {
    display: block;
    font-weight: 500;
    margin: 0;
  }

  .strategy {
    font-size: 0.666666667em;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.083333333em;
  }

  .value {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0%;
    text-align: right;
    font-size: 1.166666667em;
    transform: translate(4px, 4px);
    transition: right 500ms ease;
  }
`;

const compactStyles = css`
  font-size: 12px;

  @media (min-width: ${breakpoints.wide}px) {
    .value {
      right: 100%;
    }
  }
`;

const expandedStyles = css`
  .value {
    right: 100%;
  }
`;

export { baseStyles, compactStyles, expandedStyles };
