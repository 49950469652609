import { useQuery, useQueries } from 'react-query';
import queryKeyFactory from '../queryKeyFactory';
import * as client from './client';
import { slugify } from 'src/utils/string';
import { uniqBy } from 'src/lib/lodash';

const queryKeys = queryKeyFactory('fundManagerNews');

const DEFAULT_OPTIONS = {
  enabled: true,
  refetchOnWindowFocus: false,
};

function QueryConfig(options) {
  return function (fundManagerId) {
    const enabled = options.enabled ?? Boolean(fundManagerId);

    return {
      queryKey: queryKeys.detail(fundManagerId),
      async queryFn() {
        const results = await client.list(fundManagerId);
        return uniqBy(results, a => slugify(a.title));
      },
      ...DEFAULT_OPTIONS,
      ...options,
      enabled,
    };
  };
}

function useGetMany(fundManagerIds = [], options = {}) {
  return useQueries(fundManagerIds.map(QueryConfig(options)));
}

function useGet(fundManagerId, options = {}) {
  return useQuery(QueryConfig(options)(fundManagerId));
}

export { queryKeys, useGet, useGetMany };
