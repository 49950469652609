import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResizeDetector from 'react-resize-detector';
import { select, axisBottom, axisLeft, scaleLinear, scaleTime, timeFormat, extent, easeCubicInOut } from 'd3';
import { uniqueId } from 'src/utils';
import { Box } from 'src/components';
import { useMouseEventRefs } from 'src/hooks';
import { lookup } from 'src/lookup';

const transitionDuration = 500;

const MARGIN = { top: 20, right: 30, bottom: 40, left: 60 };

const Container = styled(Box)`
  height: ${props => props.height || '100%'};
`;

function create({ id, showTitle, margin }) {
  const root = select(`#${id}`)
    .append('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  root.append('g').attr('class', 'x-axis').style('shape-rendering', 'crispEdges');

  root.append('g').attr('class', 'y-axis').style('shape-rendering', 'crispEdges');

  // add X axis label
  root
    .append('text')
    .attr('class', 'x-axis-label')
    .style('fill', '#fff')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '18px')
    .style('font-weight', '300')
    .style('font-family', 'Roboto')
    .text(showTitle ? 'News Sentiment' : '');

  return root;
}

const { sentiments } = lookup;

function getSentiment(sentiment) {
  if (sentiment < 0) return sentiments.negative;
  if (sentiment > 0) return sentiments.positive;
  return sentiments.neutral;
}

function getSentimentColor(sentiment) {
  return getSentiment(sentiment)?.color;
}

function getSentimentLabel(sentiment) {
  return getSentiment(sentiment)?.label;
}

function draw({ id, articles, showTitle, size, margin, mouseEventRefs }) {
  let root = select(`#${id} .root`);

  if (!root.node()) {
    root = create({ id, margin, showTitle });
  }

  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;

  const t = root.transition().duration(transitionDuration).ease(easeCubicInOut);

  // create the y-scale
  const y = scaleLinear()
    .domain([1, -1])
    .range([10, svgHeight - 10]);

  // add the y axis
  const yAxis = axisLeft(y).tickFormat(getSentimentLabel).ticks(2).tickSize(0);

  root
    .select('.y-axis')
    .attr('transform', `translate(-10,0)`)
    .transition(t)
    .call(yAxis)
    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', getSentimentColor))
    .call(g => g.selectAll('.domain').attr('opacity', 0));

  // create the X axis
  const x = scaleTime()
    .domain(extent(articles, d => new Date(d.publishedAt)))
    .range([0, svgWidth]);

  // add the X axis
  const xAxis = axisBottom(x)
    .tickSize(10)
    .ticks(svgWidth > 600 ? 8 : svgWidth > 400 ? 5 : 3)
    .tickFormat(timeFormat('%b %d'));

  // position the x-axis
  root
    .select('.x-axis')
    .attr('transform', `translate(0,${svgHeight})`)
    .transition(t)
    .call(xAxis)
    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', '#808080'))
    .call(g => g.selectAll('.domain').attr('stroke', '#2e2e2e'));

  // position the x-axis label
  root
    .select('.x-axis-label')
    .transition(t)
    .attr('transform', `translate(${svgWidth / 2 - 30},-5)`);

  const points = root.selectAll('.point').data(articles, d => d.articleId);

  points.join(
    enter =>
      enter
        .append('circle')
        .on('mouseover', handleMouseOver)
        .on('mouseout', handleMouseOut)
        .on('click', handleClick)
        .on('touchstart', handleTouchStart)
        .on('touchend', handleTouchEnd)
        .attr('class', 'point')
        .attr('r', 3)
        .attr('stroke', 'none')
        .attr('fill', d => getSentimentColor(d.sentiment))
        .attr('opacity', d => (d.sentiment === 0 ? 0.5 : Math.abs(d.sentimentDerivedValue)))
        .attr('cx', d => x(new Date(d.publishedAt)))
        .attr('cy', d => y(d.sentimentDerivedValue)),
    update =>
      update.call(u =>
        u
          .on('mouseover', handleMouseOver)
          .on('mouseout', handleMouseOut)
          .on('click', handleClick)
          .on('touchstart', handleTouchStart)
          .on('touchend', handleTouchEnd)
          .transition(t)
          .attr('fill', d => getSentimentColor(d.sentiment))
          .attr('opacity', d => (d.sentiment === 0 ? 0.5 : Math.abs(d.sentimentDerivedValue)))
          .attr('cx', d => x(new Date(d.publishedAt)))
          .attr('cy', d => y(d.sentimentDerivedValue))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('cy', 0).remove())
  );

  function handleMouseOver(_, d) {
    mouseEventRefs.itemOver.current(this, d);
  }

  function handleMouseOut(_, d) {
    mouseEventRefs.itemOut.current(this, d);
  }

  function handleClick(_, d) {
    mouseEventRefs.itemClick.current(this, d);
  }

  function handleTouchStart(_, d) {
    mouseEventRefs.itemTouchStart.current(this, d);
  }

  function handleTouchEnd(_, d) {
    mouseEventRefs.itemTouchEnd.current(this, d);
  }
}

function NewsSentimentScatter({
  articles,
  expanded,
  showTitle,
  onClick,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  ...rest
}) {
  const id = useRef(uniqueId());
  const [size, setSize] = useState({ width: 0, height: 0 });
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });
  const containerRef = useRef(null);
  const visCreated = useRef(false);

  useEffect(() => {
    if (!visCreated.current) return;
    if (!articles) return;
    draw({
      id: id.current,
      articles,
      size,
      showTitle,
      margin: MARGIN,
      expanded,
      mouseEventRefs,
      onItemOver,
    });
  }, [articles, expanded, size, mouseEventRefs, onItemOver, showTitle]);

  function onResize(width, height) {
    setSize({ width, height });
    draw({
      id: id.current,
      articles,
      size: { width, height },
      showTitle,
      margin: MARGIN,
      expanded,
      mouseEventRefs,
      onItemOver,
    });
    visCreated.current = true;
  }

  return (
    <Container ref={containerRef} {...rest}>
      {size && (
        <svg
          id={id.current}
          onClick={onClick}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <ResizeDetector handleWidth handleHeight onResize={onResize} targetDomEl={containerRef.current} />
    </Container>
  );
}

NewsSentimentScatter.defaultProps = {
  expanded: false,
  showTitle: false,
  onClick: () => {},
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

NewsSentimentScatter.propTypes = {
  articles: PropTypes.array.isRequired,
  showTitle: PropTypes.bool,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default NewsSentimentScatter;
