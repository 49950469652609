import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.div`
  font-size: 1em;
  display: ${p => (p.$display ? 'initial' : 'none')};

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      opacity: 0;
      transform: scale(0);
    }
    40% {
      opacity: 0.75;
      transform: scale(1);
    }
  }

  @keyframes sk-pulse {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
`;

const Dot = styled.div`
  width: 0.625em;
  height: 0.625em;
  margin: 2px;
  background-color: currentColor;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const Message = styled.span`
  animation: sk-pulse 1.4s infinite ease-in-out both;
  display: ${p => (p.$display ? 'initial' : 'none')};
`;

function LoadingIndicator({ className, display, message }) {
  return (
    <Layout className={className} $display={display}>
      <Message $display={Boolean(message)}>{message}&nbsp;</Message>
      <span>
        <Dot />
        <Dot />
        <Dot />
      </span>
    </Layout>
  );
}

LoadingIndicator.defaultProps = {};

LoadingIndicator.propTypes = {
  children: PropTypes.any,
};

export default LoadingIndicator;
