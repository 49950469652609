import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserFunds } from 'src/store/funds';

function useUserFundIds({ tagsFilter } = {}) {
  const funds = useSelector(state => getUserFunds(state));

  return useMemo(() => {
    if (!funds) return [];
    if (!tagsFilter) return funds.map(f => f.fundId);
    if (tagsFilter.length < 1) return funds.map(f => f.fundId);
    return funds
      .filter(fund => {
        if (!fund.tags) return false;
        return fund.tags.some(tag => tagsFilter.map(t => t.toUpperCase()).includes(tag.toUpperCase()));
      })
      .map(f => f.fundId);
  }, [funds, tagsFilter]);
}

export default useUserFundIds;
