import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFundId } from 'src/store/last-selected';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { AppLayout } from 'src/components';
import FundPerformancePanel from './FundPerformancePanel';
import FundManagerSummaryPanel from './FundManagerSummaryPanel';
import CompareFundToPeersPanel from './CompareFundToPeersPanel';

function FundsPage({ fundId }) {
  const dispatch = useDispatch();

  const lastSelectedFundId = useSelector(state => state.lastSelected.fundId);

  useEffect(() => {
    if (lastSelectedFundId.startsWith('u_')) return;
    dispatch(setFundId({ fundId: lastSelectedFundId }));
  }, [dispatch, lastSelectedFundId]);

  const currentFundId = fundId || lastSelectedFundId;

  useEffect(() => {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fund);
  }, []);

  return (
    <AppLayout>
      <FundPerformancePanel spanX="half" spanY="half" minHeight={400} fundId={currentFundId} />
      <FundManagerSummaryPanel spanX="half" spanY="half" minHeight={400} fundId={currentFundId} />
      <CompareFundToPeersPanel spanX="full" spanY="half" fundId={currentFundId} />
    </AppLayout>
  );
}

export default FundsPage;
