import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

const ButtonRoundedRoot = styled(Button).attrs((/* props */) => ({
  variant: 'contained',
  size: 'small',
}))`
  .MuiButton-startIcon {
    margin-right: 2px;
  }
  background-color: ${({ selected }) => (selected ? '#949494' : '#353535')};
  color: ${({ selected }) => (selected ? '#353535' : '#5b5b5b')};
  padding 0 8px;
  height: 22px;
  min-width: ${props => props.minWidth || 'unset'};
  text-transform: none;
  font-weight: 400;
  font-size: 11px;
  border-radius: 30px;
`;

const ButtonRounded = props => {
  return <ButtonRoundedRoot {...props} />;
};

ButtonRounded.defaultProps = {};

ButtonRounded.propTypes = {};

export default ButtonRounded;
