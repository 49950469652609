import { useState, useEffect } from 'react';
import { min, max } from 'd3';
import useFundManagerEmployees from './useFundManagerEmployees';

export default function useFundManagerEmployeesByYear({ fundManagerId }) {
  const fundManagerEmployees = useFundManagerEmployees({
    fundManagerId,
  });
  const [byYear, setByYear] = useState(null);

  useEffect(() => {
    if (fundManagerId && fundManagerEmployees && fundManagerEmployees.length > 0) {
      const minYear = min(fundManagerEmployees, e => new Date(e.dateFrom).getUTCFullYear());
      const maxYear = max(fundManagerEmployees, e => new Date(e.dateTo).getUTCFullYear());
      const employeesByYear = { minYear, maxYear };
      for (let year = minYear; year <= maxYear; year++) {
        employeesByYear[year] = {
          year,
          employees: fundManagerEmployees.filter(e => {
            return (
              new Date(e.dateFrom).getUTCFullYear() <= year &&
              (e.dateTo === null || new Date(e.dateTo).getUTCFullYear() >= year)
            );
          }),
        };
      }
      setByYear(employeesByYear);
    }
  }, [fundManagerEmployees, fundManagerId]);

  return byYear;
}
