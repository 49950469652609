function max(array) {
  array = array.filter(Number.isFinite);
  return Math.max(...array);
}

function min(array) {
  array = array.filter(Number.isFinite);
  return Math.min(...array);
}

function round(n = 4) {
  return function (value) {
    const v = Number.parseFloat(value);

    if (!Number.isFinite(v)) return null;

    const f = 10 ** n;
    return Math.round(v * f) / f;
  };
}

function isNumber(value) {
  return Number.isFinite(Number.parseFloat(value));
}

module.exports = { round, min, max, isNumber };
