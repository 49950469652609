import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.ol`
  font-size: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: 100%;
`;

const Item = styled.li`
  display: grid;
  grid-auto-flow: column;
  gap: 0.5em;
`;

const ItemKey = styled.span`
  display: inline-block;
  background-color: currentColor;
  width: 1em;
  height: 1em;
`;

const ItemLabel = styled.span`
  line-height: 1;
  color: currentColor;
  white-space: nowrap;
`;

function Legend({ data, className }) {
  return (
    <Layout className={className}>
      {data.map(({ label, key, color }) => {
        return (
          <Item key={key}>
            <ItemKey style={{ color }} />
            <ItemLabel>{label}</ItemLabel>{' '}
          </Item>
        );
      })}
    </Layout>
  );
}

Legend.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Legend;
