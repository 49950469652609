import { select, extent, axisBottom, axisLeft, scaleLinear, easeCubicInOut, quantile } from 'd3';
import { strings } from 'src/strings';
import { currency, abbreviations, upperCase, round, percent } from 'src/formatters';

const TIMING = 500;
const FUND_COLOR = '#00cbff91';
const PEER_COLOR = '#6f6e6e78';

const yFormat = currency(abbreviations.billion)({
  minimumFractionDigits: 2,
});

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function draw({ id, data, metric, fundId, width, height, margin, mouseEventRefs }) {
  if (!width || !height) return;

  // set the dimensions and margins of the graph
  const canvasWidth = Math.max(0, width - (margin.left + margin.right));
  const canvasHeight = Math.max(0, height - (margin.top + margin.bottom));

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const metricKey = metric.key;
  const visibleData = data
    .filter(d => d.fundId === fundId || true)
    .filter(d => d[metricKey] != null)
    .sort((a, b) => a[metricKey] - b[metricKey]);

  // create the X axis
  const xExtent = extent(data, d => d[metric.key]);

  const xScale = scaleLinear().domain([xExtent[0], xExtent[1]]).range([0, canvasWidth]);

  const quartileValues = [
    {
      label: '25%',
      value: quantile(visibleData, 0.25, d => d[metric.key]),
    },
    {
      label: 'med.',
      value: quantile(visibleData, 0.5, d => d[metric.key]),
    },
    {
      label: '75%',
      value: quantile(visibleData, 0.75, d => d[metric.key]),
    },
  ];

  const xAxis = axisBottom(xScale)
    .tickValues(quartileValues.map(d => d.value))
    .tickSize(-(canvasHeight - 4))
    .tickFormat(metric.key === 'irr' ? percent(1) : round(2));

  const yExtent = extent(data, d => d.size);

  // create the Y axis
  const y = scaleLinear().domain([yExtent[0], yExtent[1]]).range([canvasHeight, 0]);

  const yAxis = axisLeft(y).ticks(4).tickSize(-canvasWidth).tickFormat(yFormat);

  const root = select(`#${id}`)
    .selectAll('.root')
    .data([data])
    .join('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const t = root.transition().duration(TIMING).ease(easeCubicInOut);

  root
    .selectAll('.y-axis')
    .data([yExtent])
    .join('g')
    .attr('class', 'y-axis')
    .call(function (g) {
      g.selectAll('.label')
        .data([yExtent])
        .join('text')
        .attr('class', 'label')
        .attr('x', 0)
        .attr('y', 0)
        .attr('transform', `translate(-70, ${canvasHeight / 2}) rotate(-90)`)

        .transition(t)
        .text(upperCase(strings.labels.fundSize));
    })
    .transition(t)
    .call(yAxis);

  const peerFunds = data.filter(d => d.fundId !== fundId);
  const subjectFunds = data.filter(d => d.fundId === fundId);

  const peerPointsGroup = root
    .selectAll('.peers')
    .data([peerFunds])
    .join('g')
    .attr('class', 'peers')
    .attr('fill', PEER_COLOR);

  peerPointsGroup
    .selectAll('.point.-peer')
    .data(peerFunds, d => d.fundId)
    .join('circle')
    .attr('class', 'point -peer')
    .attr('cx', d => xScale(d[metric.key]))
    .attr('cy', 0)
    .attr('r', 6.5)
    .attr('opacity', 0)
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('opacity', 1)
    .attr('cy', d => y(d.size));

  const subjectPointsGroup = root
    .selectAll('.subjects')
    .data([subjectFunds])
    .join('g')
    .attr('class', 'subjects')
    .attr('fill', FUND_COLOR);

  subjectPointsGroup
    .selectAll('.point.-subject')
    .data(subjectFunds, d => d.fundId)
    .join('circle')
    .attr('class', 'point -subject')
    .attr('cx', d => xScale(d[metric.key]))
    .attr('cy', 0)
    .attr('r', 6.5)
    .attr('opacity', 0)
    .on('mouseover', handleEvent(itemOver))
    .on('mouseout', handleEvent(itemOut))
    .on('click', handleEvent(itemClick))
    .on('touchstart', handleEvent(itemTouchStart))
    .on('touchend', handleEvent(itemTouchEnd))
    .transition(t)
    .attr('opacity', 1)
    .attr('cy', d => y(d.size));

  root
    .selectAll('.x-axis')
    .data([xExtent])
    .join('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${canvasHeight + 10})`)
    .attr('stroke-dasharray', '5 5')
    .call(function (g) {
      g.selectAll('.label')
        .data([xExtent])
        .join('text')
        .attr('class', 'label')
        .attr('x', canvasWidth / 2)
        .attr('y', 26)
        .transition(t)
        .text(upperCase(metric.labelChart));
    })
    .transition(t)
    .call(xAxis);

  root
    .selectAll('.quartile-label')
    .data(quartileValues)
    .join('text')
    .attr('class', 'quartile-label')
    .transition(t)
    .attr('x', d => xScale(d.value))
    .attr('y', 0)
    .text(d => d.label);
}

export default draw;
