import React from 'react';
import { useReducer, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BookmarkBorderSharpIcon from '@material-ui/icons/BookmarkBorderSharp';
import BookmarkSharpIcon from '@material-ui/icons/BookmarkSharp';
import useFundBookmarkControls from './useFundBookmarkControls';
import { PortfolioList, LoadingIndicator } from 'src/components';
import { Popover } from 'src/components';

const presets = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  useCloseButton: true,
};

const Section = styled.section`
  padding: 0.75em 1em;
  display: grid;
  gap: 0.5em;
  grid-template-columns: auto 1fr;

  & + & {
    padding-top: 1.5em;
  }
`;

const Header = styled(Section).attrs({ as: 'header' })`
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #ffffff22;
`;

const SectionTitle = styled.h3`
  margin: 0;
  padding-right: 1.5em;
  max-width: 275px;
  line-height: 1.2;
  margin-top: 0.1875em;
`;

const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  RESET: 'reset',
};

function reducer(state, { action, data }) {
  switch (action) {
    case ACTIONS.RESET:
      return {};

    default:
      return { ...state, [data.portfolioId]: { action, data } };
  }
}

function FundBookmarkControlPopover({ anchorPosition, onClose, className, fundId, title, commitmentAmount }) {
  const { portfolioIds, isBookmarked, addBookmark, removeBookmark } = useFundBookmarkControls(fundId);
  const [changed, dispatchChanged] = useReducer(reducer, {});
  const [isWaiting, setIsWaiting] = useState(false);

  const handleOnSelect = useCallback(
    portfolioId => {
      if (!fundId) return;
      dispatchChanged({
        action: ACTIONS.ADD,
        data: { portfolioId, fundId, commitmentAmount },
      });
    },
    [commitmentAmount, fundId]
  );

  const handleOnDeslect = useCallback(
    portfolioId => {
      if (!fundId) return;
      dispatchChanged({
        action: ACTIONS.REMOVE,
        data: { portfolioId, fundId },
      });
    },
    [fundId]
  );

  const workUnits = useMemo(() => {
    const actions = [ACTIONS.ADD, ACTIONS.REMOVE];
    return Object.values(changed)
      .map(state => {
        if (!actions.includes(state.action)) return null;
        return () => {
          const { action, data } = state;
          if (action === ACTIONS.ADD) return addBookmark(data);
          if (action === ACTIONS.REMOVE) return removeBookmark(data);
        };
      })
      .filter(Boolean);
  }, [addBookmark, changed, removeBookmark]);

  const handleCommit = useCallback(async () => {
    if (workUnits.length < 1) return onClose();

    setIsWaiting(true);
    await Promise.all(workUnits.map(flush => flush()));
    dispatchChanged({ action: ACTIONS.RESET });
    setIsWaiting(false);
    onClose();
  }, [onClose, workUnits]);

  return (
    <Popover
      className={className}
      open={Boolean(anchorPosition)}
      anchorPosition={anchorPosition}
      anchorReference="anchorPosition"
      onClose={handleCommit}
      {...presets}
      css={`
        .MuiPaper-root {
          width: 340px;
          padding-bottom: 1.5em;
        }
      `}
    >
      <div onClick={evt => evt.stopPropagation()}>
        <Header>
          {isWaiting ? (
            <LoadingIndicator
              display={isWaiting}
              css={`
                font-size: 0.8em;
              `}
            />
          ) : isBookmarked ? (
            <BookmarkSharpIcon />
          ) : (
            <BookmarkBorderSharpIcon />
          )}
          <SectionTitle>{isWaiting ? 'Saving...' : title}</SectionTitle>
        </Header>

        <section
          css={`
            max-height: ${32 * 5.5}px;
            overflow-y: auto;
          `}
        >
          <PortfolioList
            disabled={isWaiting}
            multiSelect
            value={portfolioIds}
            onSelect={handleOnSelect}
            onDeselect={handleOnDeslect}
          />
        </section>
      </div>
    </Popover>
  );
}

FundBookmarkControlPopover.defaultProps = {
  commitmentAmount: 1_000_000,
};

FundBookmarkControlPopover.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
};

export default FundBookmarkControlPopover;
