import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { IconUserAvatar } from 'src/components';

const Button = styled(IconButton)`
  color: currentColor;
  padding: 0;
  font-size: 1em;
  line-height: 1;

  .MuiSvgIcon-root {
    font-size: unset;
  }
`;

const ButtonUserAvatar = props => {
  return (
    <Button {...props}>
      <IconUserAvatar />
    </Button>
  );
};

ButtonUserAvatar.defaultProps = {};

ButtonUserAvatar.propTypes = {};

export default ButtonUserAvatar;
