import { useQuery } from 'react-query';
import { filterApi } from 'src/services';

function useSubscriptionPlans() {
  const result = useQuery(
    'subscriptionPlans',
    async () => {
      const prices = await filterApi.stripeListPlans();

      return prices.filter(price => price.product.active);
    },
    { refetchOnWindowFocus: false }
  );

  return result;
}

export default useSubscriptionPlans;
