import { db, auth } from 'src/config/firebase';
import { DocumentNotFoundError } from 'src/services/filter-api/errors';

const collection = db.collection('userFunds');

async function list() {
  const userId = auth.currentUser.uid;
  const query = collection.where('userId', '==', userId);
  const snapshot = await query.get();

  return snapshot.docs.map(d => d.data());
}

async function get(fundId) {
  const userId = auth.currentUser.uid;
  const docId = `${userId}_${fundId}`;
  const doc = await collection.doc(docId).get();
  if (!doc.exists) throw new DocumentNotFoundError(doc);
  return doc.data();
}

export { collection, list, get };
