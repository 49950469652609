import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { IconYoutubePlay } from 'src/components';

const Button = styled(IconButton)`
  color: currentColor;
  padding: 6px 8px;
  font-size: 1.7em;
  line-height: 1;

  .MuiSvgIcon-root {
    font-size: unset;
  }
`;

const ButtonTutorialPlay = props => {
  return (
    <Button {...props}>
      <IconYoutubePlay />
    </Button>
  );
};

ButtonTutorialPlay.defaultProps = {};

ButtonTutorialPlay.propTypes = {};

export default ButtonTutorialPlay;
