import { useMemo } from 'react';
import { currency } from 'src/formatters/currency';
import { formatDate, addQuarters } from 'src/utils/date';
import { TYPES } from './';

function formatCurrencyCell({ value }) {
  return currency()({
    minimumFractionDigits: 2,
  })(value);
}

function formatPercentCell({ value }) {
  if (!Number.isFinite(value)) return '-';
  return `${(value * 100).toFixed(1)}%`;
}

function formatMultipleCell({ value }) {
  if (!Number.isFinite(value)) return '-';
  return `${value.toFixed(2)}×`;
}

function formatDateCell({ value }) {
  if (!value) return '-';
  return formatDate('QQQ yyyy')(value);
}

function column({ id, label, ...rest }) {
  return {
    id,
    Header: label,
    accessor: id,
    minDisplayWidth: 0,
    ...rest,
  };
}

function currencyColumn({ id, label, ...rest }) {
  return {
    ...column({ id, label }),
    className: 'number currency',
    Cell: formatCurrencyCell,
    ...rest,
  };
}

function multipleColumn({ id, label, ...rest }) {
  return {
    ...column({ id, label }),
    className: 'multiple',
    Cell: formatMultipleCell,
    ...rest,
  };
}

function percentColumn({ id, label, ...rest }) {
  return {
    ...column({ id, label }),
    className: 'number percent',
    Cell: formatPercentCell,
    ...rest,
  };
}

function dateColumn({ id, label, ...rest }) {
  return {
    ...column({ id, label }),
    className: 'date',
    Cell: formatDateCell,
    ...rest,
  };
}

function getDataClass(type) {
  return {
    isJcurve: [TYPES.JCURVE_MEAN, TYPES.JCURVE_POLY].includes(type),
    isNav: type === TYPES.NAV,
    isCashflow: type === TYPES.CASHFLOW,
  };
}

function pickColumns(columns, ids) {
  return [...ids, 'spacer'].map(id => columns.find(c => c.id === id)).filter(Boolean);
}

function useTableColumns(dataType, startDate) {
  const columns = useMemo(() => {
    return [
      column({
        id: 'fundName',
        label: 'Fund',
        width: '11.5em',
      }),
      dateColumn({
        id: 'date',
        label: 'Date',
        width: 'minmax(4em, 1fr)',
        accessor: row => {
          if (!startDate) return;
          if (!Number.isFinite(row.q)) return;
          return addQuarters(row.q)(startDate);
        },
        ExportCell: formatDateCell,
      }),
      percentColumn({
        id: 'calledPct',
        label: 'Called',
        width: '4em',
        minDisplayWidth: 380,
        isVisible: false,
      }),
      multipleColumn({
        id: 'dpi',
        label: 'DPI',
        width: '3em',
        minDisplayWidth: 380,
      }),
      currencyColumn({
        id: 'contributions',
        label: 'Contributions',
        width: '7em',
        minDisplayWidth: 300,
      }),
      currencyColumn({
        id: 'distributions',
        label: 'Distributions',
        width: '7em',
        minDisplayWidth: 300,
      }),
      currencyColumn({
        id: 'ncf',
        label: 'Net Cashflows',
        width: '7em',
      }),
      multipleColumn({
        id: 'rvpi',
        label: 'RVPI',
        width: '4em',
      }),
      currencyColumn({
        id: 'nav',
        label: 'Net Asset Value',
        width: '7.5em',
      }),
      currencyColumn({
        id: 'contribution',
        label: 'Contribution',
        width: '7.5em',
        Cell: ({ value }) => formatCurrencyCell({ value: value * -1 }),
      }),
      currencyColumn({
        id: 'distribution',
        label: 'Distribution',
        width: '7.5em',
      }),
    ];
  }, [startDate]);

  return useMemo(() => {
    const { isJcurve, isNav, isCashflow } = getDataClass(dataType);

    if (isJcurve) {
      return pickColumns(columns, [
        'fundName',
        'date',
        'dpi',
        'calledPct',
        'contributions',
        'distributions',
        'ncf',
      ]).map(c => {
        if (c.id === 'fundName') c.minDisplayWidth = 480;
        if (c.id === 'dpi') c.minDisplayWidth = 480;
        if (c.id === 'calledPct') c.minDisplayWidth = 480;
        if (c.id === 'contributions') c.minDisplayWidth = 650;
        if (c.id === 'distributions') c.minDisplayWidth = 650;

        return c;
      });
    }

    if (isNav) {
      return pickColumns(columns, ['fundName', 'date', 'rvpi', 'nav']).map(c => {
        if (c.id === 'fundName') c.minDisplayWidth = 480;
        if (c.id === 'rvpi') c.minDisplayWidth = 0;
        if (c.id === 'nav') c.minDisplayWidth = 0;

        return c;
      });
    }

    if (isCashflow) {
      return pickColumns(columns, ['fundName', 'date', 'distribution', 'contribution']).map(c => {
        if (c.id === 'fundName') c.minDisplayWidth = 480;

        return c;
      });
    }
    return [];
  }, [dataType, columns]);
}

export default useTableColumns;
