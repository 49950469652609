import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundsInMarket } from '../store/funds-in-market';

export default function useFundsInMarket({ strategyId, regionId, year }) {
  const dispatch = useDispatch();
  const fundsInMarket = useSelector(state => state.fundsInMarket.funds);
  const allFundIds = useSelector(state => state.fund.all.allIds);
  const [funds, setFunds] = useState(null);

  useEffect(() => {
    if (!(strategyId || regionId)) return;
    if (!year) return;

    dispatch(fetchFundsInMarket({ strategyId, regionId, year }));
  }, [regionId, strategyId, year, dispatch]);

  useEffect(() => {
    if (fundsInMarket.fetching) return;
    if (!fundsInMarket.fetched) return;
    if (!allFundIds) return;

    const allFundsInMarket = fundsInMarket.all.map(fund => {
      return {
        ...fund,
        hasFinancialHistory: allFundIds.includes(fund.fundId),
      };
    });

    setFunds(allFundsInMarket);
  }, [fundsInMarket.fetched, fundsInMarket.fetching, fundsInMarket.all, allFundIds]);

  return funds;
}
