import { useState, useEffect } from 'react';
import { auth, persistence, EmailAuthProvider } from 'src/config/firebase';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { addMinutes, isPast } from 'src/utils/date';

const queryKey = 'authentication';
const verificationSentKey = [queryKey, 'verificationSent'].join('/');

const STATUSES = {
  LOADING: 'loading',
  UNAUTHENTICATED: 'unauthenticated',
  AUTHENTICATED: 'authenticated',
};

const QUERY_DEFAULTS = {
  cacheTime: Infinity,
  staleTime: Infinity,
};

async function setPersistence(remember) {
  const persist = remember ? persistence.LOCAL : persistence.SESSION;
  await auth.setPersistence(persist);
  return persist;
}

function getStatus(currentUser) {
  if (currentUser === undefined) return STATUSES.LOADING;
  if (currentUser === null) return STATUSES.UNAUTHENTICATED;
  return STATUSES.AUTHENTICATED;
}

function getisAnonymousExpired(user) {
  if (!user?.isAnonymous) return false;
  try {
    const createdAt = new Date(user?.metadata.creationTime);
    const expiryDate = addMinutes(10)(createdAt);
    return isPast(expiryDate);
  } catch {
    return false;
  }
}

function useGet({ poll = false, pollFreq = 1000 } = {}) {
  const queryClient = useQueryClient();
  const [refetchInterval, setRefetchInterval] = useState(poll && pollFreq);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      auth.currentUser?.reload();
      setEnabled(true);
      queryClient.invalidateQueries(queryKey);
    });

    return function cleanup() {
      unsubscribe();
    };
  }, [queryClient]);

  const { data } = useQuery({
    enabled,
    queryKey: queryKey,
    async queryFn() {
      const { currentUser } = auth;
      const status = getStatus(auth.currentUser);

      return {
        uid: currentUser?.uid,
        email: currentUser?.email,
        isVerificationSent: Boolean(localStorage.getItem(verificationSentKey)),
        isVerified: Boolean(currentUser?.emailVerified),
        isAuthenticated: status === STATUSES.AUTHENTICATED,
        isLoading: status === STATUSES.LOADING,
        isAnonymous: currentUser?.isAnonymous,
        isAnonymousExpired: getisAnonymousExpired(currentUser),
        createdAt: new Date(currentUser?.metadata.creationTime),
        status: getStatus(currentUser),
      };
    },
    initialData: {
      isLoading: true,
      status: STATUSES.LOADING,
    },
    refetchInterval,
    async onSuccess({ isVerified }) {
      if (!poll) return;
      if (isVerified) setRefetchInterval(false);
      await auth.currentUser?.reload();
    },
    ...QUERY_DEFAULTS,
  });

  return data;
}

function useAuthenticate() {
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn({ email, password, remember }) {
      await setPersistence(remember);
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      return user;
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useDeauthenticate() {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn() {
      await auth.signOut();
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useCreate() {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ email, password, name, remember, isAnonymous }) {
      await setPersistence(remember);

      if (isAnonymous) {
        const { user } = await auth.signInAnonymously();
        return user;
      } else {
        const { user } = await auth.createUserWithEmailAndPassword(email, password);
        await user.updateProfile({ displayName: name });
        return user;
      }
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useUpdate() {
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn({ name: displayName, email, password }) {
      await auth.currentUser.updateProfile({ displayName });
      if (email) {
        localStorage.removeItem(verificationSentKey);
        await auth.currentUser.updateEmail(email);
      }
      if (password) await auth.currentUser.updatePassword(password);

      await auth.currentUser.reload();
      return auth.currentUser;
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useDelete() {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn() {
      localStorage.removeItem(verificationSentKey);
      await auth.currentUser.delete();
      await auth.signOut();
      return null;
    },
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useReauthenticate() {
  return useMutation({
    async mutationFn({ password }) {
      const { email } = auth.currentUser;
      const credential = EmailAuthProvider.credential(email, password);
      await auth.currentUser.reauthenticateWithCredential(credential);
      return true;
    },
  });
}

function useResetPassword() {
  return useMutation({
    async mutationFn(email) {
      await auth.sendPasswordResetEmail(email);
      return true;
    },
  });
}

function useSendEmailVerification() {
  return useMutation({
    async mutationFn() {
      await auth.currentUser.sendEmailVerification();
    },
    onSuccess() {
      localStorage.setItem(verificationSentKey, 'true');
    },
  });
}

function useLinkWithCredential() {
  const queryClient = useQueryClient();
  return useMutation({
    async mutationFn({ email, password }) {
      const credential = EmailAuthProvider.credential(email, password);
      return await auth.currentUser.linkWithCredential(credential);
    },
    onSuccess() {
      localStorage.removeItem(verificationSentKey);
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export {
  useGet,
  useCreate,
  useUpdate,
  useDelete,
  useAuthenticate,
  useReauthenticate,
  useDeauthenticate,
  useResetPassword,
  useSendEmailVerification,
  useLinkWithCredential,
  auth,
};
