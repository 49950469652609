import { filterApi } from 'src/services';

let regions;

export async function getRegions() {
  if (!regions) {
    regions = await filterApi.getRegions();
    regions.sort((a, b) => a.rank - b.rank);
  }
  return regions;
}
