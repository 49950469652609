const d3 = require('d3/dist/d3.node.js');
const { round } = require('../utils/number');

function distPct({ dpi, calledPct }) {
  return round(4)(dpi * calledPct);
}

function navPct({ rvpi, calledPct }) {
  return round(4)(rvpi * calledPct);
}

function netAssetValue({ rvpi, calledPct }) {
  return round(4)(rvpi * calledPct);
}

function spotDistribution({ dpi, calledPct }, i, group) {
  const prevItem = group[i - 1];
  if (!prevItem) return distPct({ dpi, calledPct });
  return distPct({ dpi, calledPct }) - distPct(prevItem);
}

function spotContribution({ calledPct }, i, group) {
  const prevItem = group[i - 1];
  if (!prevItem) return calledPct;
  return round(4)(calledPct - prevItem.calledPct);
}

function netCashflow({ dpi, calledPct }) {
  return round(4)(dpi * calledPct - calledPct);
}

function numberOrNull(value) {
  return Number.isFinite(value) ? value : null;
}

module.exports = {
  netCashflow,
  distPct,
  navPct,
  netAssetValue,
  spotDistribution,
  spotContribution,
  numberOrNull,
  mean: d3.mean,
  median: d3.median,
};
