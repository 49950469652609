import React from 'react';
import PropTypes from 'prop-types';

export function UserFundOrManagerMessage({ label, ...rest }) {
  const isFundManager = label.toLowerCase() === 'fund manager';

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 300;
        color: #838383;
      `}
      {...rest}
    >
      <span
        css={`
          max-width: 240px;
          text-align: center;
        `}
      >
        This {label} was added by&nbsp;you
        {isFundManager && <> and to date no news has been&nbsp;generated</>}
      </span>
    </div>
  );
}

UserFundOrManagerMessage.defaultProps = {
  label: 'fund',
};

UserFundOrManagerMessage.propTypes = {
  label: PropTypes.string,
};

export default UserFundOrManagerMessage;
