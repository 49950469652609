import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MenuItem, Menu, List, ListItem, ListItemText, Radio, InputLabel, FormControl } from '@material-ui/core';

const StyledList = styled(List)`
  padding: 0;

  .MuiListItem-root {
    border: 1px solid rgba(255, 255, 255, 0.23);
    font-weight: 300;
  }

  .MuiListItem-gutters {
    padding-left: 14px;
    padding-right: 32px;
  }

  .MuiListItem-dense {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .MuiListItemText-primary,
  .MuiListItemText-secondary {
    font-size: 1em;
    font-weight: 300;
  }

  .MuiListItemText-secondary {
    opacity: 0.5;
  }
`;

const StyledMenu = styled(Menu).attrs({
  keepMounted: true,
  variant: 'menu',
})`
  .MuiPaper-root {
    font-size: 0.8125rem;
    background-color: #313131;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: #a4a4a4;
  font-weight: 300;
  padding: 0.307692308em 20px;
  font-size: inherit;

  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
  }
`;

const MenuSectionHeader = styled(MenuItem).attrs({
  disabled: true,
})`
  margin-top: 1.5em;
  text-transform: capitalize;
  color: white;
  font-size: 1em;

  &.MuiListItem-root.Mui-disabled {
    opacity: 1;
  }
`;

const StyledRadio = styled(Radio)`
  padding: 0;
  margin-right: 9px;
`;

function FundRegionField({ className, placeholder, categories, value, onChange, disabled, label }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displayValue, setDisplayValue] = React.useState();

  const handleClickListItem = event => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick =
    (category, { regionId, regionName }) =>
    event => {
      onChange(event, {
        ...value,
        [category]: {
          regionId,
          regionName,
        },
      });
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(
    function () {
      const categoryKeys = Object.keys(categories);
      const displayValues = categoryKeys
        .map(key => value[key])
        .filter(Boolean)
        .map(item => item.regionName)
        .filter(Boolean);

      setDisplayValue(displayValues.join(' / '));
    },
    [value, categories]
  );

  const placeholderValue = displayValue ? false : placeholder;

  return (
    <>
      <FormControl className={className}>
        <InputLabel
          shrink
          css={`
            position: absolute;
            top: -9px;
            left: 10px;
            padding: 1px 6px;
            background-color: #313131;
            z-index: 1;
          `}
        >
          {displayValue ? label : ''}
        </InputLabel>
        <StyledList component="nav" dense>
          <ListItem button onClick={handleClickListItem} disabled={disabled}>
            <ListItemText
              css={`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
              primary={displayValue}
              secondary={placeholderValue}
            />
            {!disabled && <ArrowDropDownIcon />}
          </ListItem>
        </StyledList>
      </FormControl>
      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.keys(categories).map(category => {
          const group = categories[category];

          return [
            <MenuSectionHeader key={category}>{category}</MenuSectionHeader>,

            group.map(item => {
              const isSelected = value[category].regionId === item.regionId;
              const returnValue = {
                regionId: isSelected ? null : item.regionId,
                regionName: isSelected ? null : item.regionName,
              };
              const isDisabled = category === 'secondary' && !value.primary.regionId;

              return (
                <StyledMenuItem
                  key={`${category}-${item.regionId}`}
                  disabled={isDisabled}
                  selected={isSelected}
                  onClick={handleMenuItemClick(category, returnValue)}
                >
                  <StyledRadio color="primary" checked={isSelected} /> {item.regionName}
                </StyledMenuItem>
              );
            }),
          ];
        })}
      </StyledMenu>
    </>
  );
}

FundRegionField.defaultProps = {};

FundRegionField.propTypes = {
  children: PropTypes.any,
  categories: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default FundRegionField;
