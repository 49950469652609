import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unionByKey } from 'src/utils/array';
import { fetchFunds } from 'src/store/funds';
import { fetchFundFinancialHistory } from 'src/store/fund-financial-history';

function useFund({ fundId, includeFinancialHistory = false }) {
  const dispatch = useDispatch();
  const funds = useSelector(state => state.fund.all);
  const fundFinancialHistoriesById = useSelector(state => state.fund.financialHistory?.byId || {});

  const fund = useMemo(() => {
    if (!fundId) return;
    if (funds.fetching) return;
    if (funds.fetched) return funds.byId[fundId];

    dispatch(fetchFunds());
    return;
  }, [dispatch, fundId, funds.byId, funds.fetched, funds.fetching]);

  const bookmark = useMemo(() => {
    if (!fundId) return {};
    /**
     * User-data layer on top of system funds
     * Removed until we can revisit
     */
    return {};
  }, [fundId]);

  const fundFinancialHistory = useMemo(() => {
    if (!fundId) return;
    if (!includeFinancialHistory) return;

    const history = fundFinancialHistoriesById[fundId];

    if (history?.fetching) return;
    if (history?.fetched) return history;

    dispatch(fetchFundFinancialHistory({ fundId }));
  }, [dispatch, fundFinancialHistoriesById, fundId, includeFinancialHistory]);

  const state = useMemo(() => {
    if (!fund) return;

    if (!includeFinancialHistory) {
      return {
        ...fund,
        ...bookmark,
        financialHistory: undefined,
      };
    }

    const fundHistory = fundFinancialHistory?.items || [];
    const userHistory = (bookmark.financialHistory || []).map(item => ({
      ...item,
      isUserEdited: true,
    }));

    if (includeFinancialHistory === 'append') {
      return {
        ...fund,
        ...bookmark,
        financialHistory: [...fundHistory, ...userHistory].sort((a, b) => (a.date < b.date ? -1 : 1)),
      };
    }

    return {
      ...fund,
      ...bookmark,
      financialHistory: unionByKey('date')(fundHistory, userHistory).sort((a, b) => (a.date < b.date ? -1 : 1)),
    };
  }, [bookmark, fund, fundFinancialHistory, includeFinancialHistory]);

  return state;
}

export default useFund;
