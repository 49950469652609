import { ExportToCsv } from 'export-to-csv';
import { useCallback, useMemo } from 'react';

const DEFAULT_OPTIONS = {
  showLabels: true,
  useKeysAsHeaders: true,
};

function useExportCsvCallback(options = {}) {
  const title = useMemo(() => {
    if (!options.title) return;
    if (!(options.title instanceof Array)) return options.title;

    let delimiter = ',';

    return options.title
      .map(row => {
        if (!(row instanceof Array)) return row;
        delimiter = '\n';
        return row.join(',');
      })
      .join(delimiter);
  }, [options.title]);

  const csvExporter = useMemo(() => {
    const showTitle = Boolean(title);

    return new ExportToCsv({
      ...DEFAULT_OPTIONS,
      ...options,
      showTitle,
      title,
    });
  }, [options, title]);

  return useCallback(
    data => {
      csvExporter.generateCsv(
        data.map(row => {
          return Object.entries(row).reduce((acc, [key, value]) => {
            const val = Number.isNaN(value) ? '' : value ?? '';
            return { ...acc, [key]: val };
          }, {});
        })
      );
    },
    [csvExporter]
  );
}

export default useExportCsvCallback;
