import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Dvg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconArrowRight(props) {
  return (
    <Dvg viewBox="0 0 29.998 26.7675" {...props}>
      <g>
        <path d="M29.705,10l-10-10l-1.414,1.414l8.293,8.293H5v2h21.584L18.291,20l1.414,1.414l10-10   C30.096,11.023,30.096,10.391,29.705,10z" />
        <rect y="2.707" width="2" height="16" />
      </g>
    </Dvg>
  );
}
