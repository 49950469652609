import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { navigate } from '@reach/router';
import { sm } from 'src/config/layout';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import { Link, VisualizationMessage } from 'src/components';
import routes from 'src/config/routes';
import draw from './draw';

const Container = styled.div`
  .tick {
    line {
      color: #2e2e2e;
    }
    text {
      font-size: 10px;
      fill: #808080;
    }
  }

  .tick.Q2 text {
    display: none;

    @media (min-width: ${sm.BREAKPOINT}px) {
      display: unset;
    }
  }

  .axis-label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
  }
  .domain {
    opacity: 0;
  }
  .shadow {
    filter: drop-shadow(0px 3px 8px #000);
    -webkit-filter: drop-shadow(0px 3px 8px #000);
    -moz-filter: drop-shadow(0px 3px 8px #000);
  }
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
`;

const requiredDataNotAvailableMessageCss = css`
  max-width: 300px;
  position: relative;
`;

const MARGIN = { top: 10, right: 30, bottom: 25, left: 40 };

function FundFinancialHistoryTimeSeries({
  data,
  isUserFund,
  metric,
  timeRange,
  isOnDashboard,
  width,
  height,
  targetRef,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  ...rest
}) {
  const { current: svgId } = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  const [requiredDataAvailable, setRequiredDataAvailable] = useState(true);

  useEffect(() => {
    const drawSuccess = draw({
      svgId,
      data,
      timeRange,
      metric,
      width,
      height,
      margin: MARGIN,
      mouseEventRefs,
    });

    setRequiredDataAvailable(drawSuccess);
  }, [data, metric, timeRange, width, height, svgId, mouseEventRefs]);

  function handleGotoDashboard() {
    navigate(routes.DASHBOARD);
  }

  return (
    <Container ref={targetRef} {...rest}>
      {!requiredDataAvailable && isUserFund && isOnDashboard && (
        <VisualizationMessage>
          <p>this fund was added by you</p>
          <p css={requiredDataNotAvailableMessageCss}>
            in order to see a visual representation of the selected time range, please locate this fund below click edit
            and add more quarterly data
          </p>
        </VisualizationMessage>
      )}
      {!requiredDataAvailable && isUserFund && !isOnDashboard && (
        <VisualizationMessage>
          <p>this fund was added by you</p>
          <p css={requiredDataNotAvailableMessageCss}>
            in order to see a visual representation of the selected time range, please{' '}
            <Link
              color="primary"
              onMouseDown={handleGotoDashboard}
              css={`
                top: -1px;
                font-size: 12px;
                text-transform: uppercase;
              `}
            >
              go here
            </Link>{' '}
            to saved funds, locate this fund, click edit and add more quarterly data
          </p>
        </VisualizationMessage>
      )}
      {!requiredDataAvailable && !isUserFund && (
        <VisualizationMessage>
          <p css={requiredDataNotAvailableMessageCss}>
            certain historical data is missing in this time series and the requested graphic cannot be generated
            currently
          </p>
        </VisualizationMessage>
      )}
      <Svg id={svgId} />
    </Container>
  );
}

FundFinancialHistoryTimeSeries.defaultProps = {
  isOnDashboard: false,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
  targetRef: null,
};

FundFinancialHistoryTimeSeries.propTypes = {
  data: PropTypes.array.isRequired,
  metric: PropTypes.object.isRequired,
  timeRange: PropTypes.object.isRequired,
  isUserFund: PropTypes.bool.isRequired,
  isOnDashboard: PropTypes.bool,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default withResizeDetector(FundFinancialHistoryTimeSeries);
