import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'src/utils/nanoid';
import { setUserFund } from 'src/store/funds';
import { setUserFundManager } from 'src/store/fund-managers';
import { getSizeId } from 'src/lookup/sizes';
import { setUserFundFinancialHistory } from 'src/store/fund-financial-history';

function useUpdateUserFundCallback(formState) {
  const dispatch = useDispatch();

  return useCallback(
    async function ({ financialHistory }) {
      const isNew = Boolean(formState.fundId);
      const userFundManagerId = formState.fundManagerId || `u_${nanoid()}`;
      const userFundId = formState.fundId || `u_${nanoid()}`;
      const lastUpdate = new Date();

      if (userFundManagerId !== formState.fundManagerId) {
        await dispatch(
          setUserFundManager({
            fundManager: {
              fundManagerId: userFundManagerId,
              name: formState.fundManagerName,
              lastUpdateDate: lastUpdate,
            },
          })
        );
      }

      await dispatch(
        setUserFundFinancialHistory({
          fundId: userFundId,
          fundFinancialHistory: financialHistory,
        })
      );

      await dispatch(
        setUserFund({
          fund: {
            isNew,
            date: financialHistory[0].date,
            calledPct: financialHistory[0].calledPct,
            dpi: financialHistory[0].dpi,
            fundId: userFundId,
            fundManagerId: userFundManagerId,
            fundManagerName: formState.fundManagerName,
            irr: financialHistory[0].irr,
            lastUpdate,
            mainRegionId: formState.regionId1,
            mainRegionName: formState.regionName1,
            mainRegionGroupId: formState.regionId1,
            name: formState.name,
            regionId1: formState.regionId1,
            regionId2: formState.regionId2,
            regionName1: formState.regionName1,
            regionName2: formState.regionName2,
            rvpi: financialHistory[0].rvpi,
            size: Number.parseInt(formState.size, 10),
            sizeId: getSizeId(Number.parseInt(formState.size, 10)),
            strategy: formState.strategy,
            strategyId: formState.strategyId,
            subStrategy: formState.subStrategy,
            subStrategyId: formState.subStrategyId,
            tvpi: financialHistory[0].tvpi,
            vintage: formState.vintage,
          },
        })
      );

      return userFundId;
    },
    [formState, dispatch]
  );
}

export default useUpdateUserFundCallback;
