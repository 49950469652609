import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorBoundary } from 'src/components';
import { modal } from 'src/config/colors';

const Layout = styled.section`
  background-color: ${modal.body.BACKGROUND_COLOR};
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 2em;

  width: auto;
  margin-inline: auto;
`;

function AuthLayoutCard(props) {
  return (
    <ErrorBoundary>
      <Layout {...props} />
    </ErrorBoundary>
  );
}

AuthLayoutCard.defaultProps = {
  side: 'front',
};

AuthLayoutCard.propTypes = {
  children: PropTypes.any,
  side: PropTypes.oneOf(['front', 'back']),
};

export default AuthLayoutCard;
