import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { md } from 'src/config/layout';
import Radio from '@material-ui/core/Radio';
import { QUARTILE_OPTIONS } from './constants';

const Layout = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: flex;
  }
`;

const StyledRadio = styled(Radio)`
  padding: 4px;
`;

const Label = styled.label`
  font-size: 0.9em;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;

  & + ${StyledRadio} {
    margin-left: 6px;
  }
`;

function QuartileSelect({ options, value, onChange }) {
  return (
    <Layout>
      {Object.keys(options).map((key, i) => (
        <React.Fragment key={i}>
          <StyledRadio color="default" size="small" checked={key === value} onChange={() => onChange(key)} />{' '}
          <Label onClick={() => onChange(key)}>{options[key]}</Label>
        </React.Fragment>
      ))}
    </Layout>
  );
}

QuartileSelect.defaultProps = {
  onChange: () => null,
  value: 'ALL',
  options: QUARTILE_OPTIONS,
};

QuartileSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.object,
};

export { QuartileSelect };
