import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import * as date from 'src/utils/date';
import * as colors from 'src/config/colors';
import { Button, Typography } from 'src/components';

function Plan({ plan, customer, description, trialExpiryDate, onClickSubscribe }) {
  const name = plan.product.name;
  const price = plan.unit_amount;
  const interval = plan.recurring?.interval;

  const isSubscribed =
    customer.currentSubscription?.plan?.id === plan.id &&
    (customer.currentSubscription?.status === 'active' ||
      (customer.currentSubscription?.status === 'trialing' && customer.defaultPaymentMethod));

  const isTrialExpired = date.isPast(trialExpiryDate);
  const trialExpiryDisplayDate = date.formatDate('MMM d')(trialExpiryDate);

  function handleClickSubscribe() {
    onClickSubscribe({
      id: plan.id,
      name,
      price,
      interval,
    });
  }

  const monthlyPrice = {
    year: (price / 12 / 100).toFixed(2),
    month: (price / 100).toFixed(2),
  }[interval];

  return (
    <Card
      raised={false}
      variant="outlined"
      css={`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: ${colors.card[isSubscribed ? 'selected' : 'default'].BACKGROUND_COLOR};
      `}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {name} {!isSubscribed && <Chip label={`$${monthlyPrice} /month`} />}{' '}
          {!isSubscribed && interval === 'year' && <Chip color="secondary" label={`Save 20%`} />}
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
          Billed <b>{interval}ly</b>, recurring.
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>

      <CardActions>
        {isSubscribed ? (
          <Chip label="Subscribed" variant="outlined" />
        ) : (
          <Button
            onClick={handleClickSubscribe}
            size="small"
            variant="contained"
            color="primary"
            disableElevation={true}
          >
            Subscribe
          </Button>
        )}

        {isSubscribed && !isTrialExpired && trialExpiryDisplayDate && (
          <span>
            <i>Billing will start {trialExpiryDisplayDate}</i>
          </span>
        )}

        {!isSubscribed && isTrialExpired && trialExpiryDisplayDate && (
          <span>
            <i>
              Your free trial expire{isTrialExpired ? 'd' : 's'} {trialExpiryDisplayDate}
            </i>
          </span>
        )}
      </CardActions>
    </Card>
  );
}

Plan.defaultProps = {
  onClickSubscribe: () => {},
  trialExpiryDate: new Date(),
};

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  description: PropTypes.string,
  trialExpiryDate: PropTypes.instanceOf(Date),
  onClickSubscribe: PropTypes.func,
};

export default Plan;
