import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { AppLayout, Button, Typography } from '../..';

const Layout = styled.div`
  grid-column-end: span 2;
  grid-row-end: span 3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function NotFoundPage() {
  return (
    <AppLayout>
      <Layout>
        <Typography variant="h5">Page not found</Typography>
        <Button color="primary" variant="contained" style={{ marginTop: '20px' }} onClick={() => navigate('/')}>
          GO TO HOME PAGE
        </Button>
      </Layout>
    </AppLayout>
  );
}

export default NotFoundPage;
