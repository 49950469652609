import React from 'react';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';
import IconList from '../IconList';

const StyledButtonRounded = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 1px;
    left: 2px;
  }
`;

const ButtonList = props => {
  return (
    <StyledButtonRounded startIcon={<IconList />} {...props}>
      List
    </StyledButtonRounded>
  );
};

ButtonList.defaultProps = {};

ButtonList.propTypes = {};

export default ButtonList;
