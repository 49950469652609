import React from 'react';
import { useMemo } from 'react';
import PropTypes, { funcStub } from 'src/lib/prop-types';
import { css } from 'styled-components';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@material-ui/icons';
import { IconCheckboxUnchecked, IconCheckboxChecked, IconCheckboxIndeterminate } from 'src/components';

const smallStyles = css`
  font-size: 0.875em;

  .MuiCheckbox-root {
    padding: 0.25em;
    margin-bottom: 1px;
  }
`;

const colors = {
  default: '#838383',
  primary: '#00abd4',
  secondary: '#ad48f8',
  disabled: '#5b5b5b',
};

function Checkbox({ checked, onChange, disabled, color, children, className, size }) {
  const Icon = useMemo(() => {
    if (size === 'small') {
      return checked === true
        ? IconCheckboxChecked
        : checked === false
        ? IconCheckboxUnchecked
        : IconCheckboxIndeterminate;
    }

    return checked === true ? CheckBox : checked === false ? CheckBoxOutlineBlank : IndeterminateCheckBox;
  }, [checked, size]);

  return (
    <FormControlLabel
      css={`
        &.MuiFormControlLabel-root {
          display: flex;
          align-items: center;
        }
        .MuiFormControlLabel-label {
          font-size: 1em;
        }

        .MuiTypography-root,
        .MuiCheckbox-root {
          color: ${colors[color] || colors.default};
        }

        &.MuiFormControlLabel-root.Mui-disabled {
          .MuiTypography-root,
          .MuiCheckbox-root {
            color: ${colors.disabled};
          }
        }

        ${size === 'small' && smallStyles}
      `}
      className={className}
      control={
        <MuiCheckbox
          checked={checked}
          onChange={onChange}
          color={color}
          disabled={disabled}
          size={size}
          icon={<Icon />}
          checkedIcon={<Icon />}
          indeterminateIcon={<Icon />}
        />
      }
      label={children}
    />
  );
}

Checkbox.defaultProps = {
  color: 'default',
  onChange: funcStub('Checkbox'),
  disabled: false,
  size: 'medium',
};

Checkbox.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Checkbox;
