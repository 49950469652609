import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconFullScreenOff(props) {
  return (
    <Svg viewBox="0 0 150 150" {...props}>
      <g>
        <path d="M126,118H24V31h102V118z M28,114h94V36H28V114z" />
        <polygon points="41,68 37.1,68 37.1,46 58,46 58,50 41,50  " />
        <polygon points="112.9,68 109,68 109,50 92,50 92,46 112.9,46  " />
        <polygon points="59,104 37.1,104 37.1,82 41,82 41,100 59,100  " />
        <polygon points="113,104 91,104 91,100 109,100 109,82 113,82  " />
      </g>
    </Svg>
  );
}
