import styled from 'styled-components';
import Box from '../Box';
import { metadata } from 'src/config/colors';

export default styled(Box)`
  font-size: 11px;
  overflow-y: auto;
  color: ${metadata.data.TEXT_COLOR};
  background-color: ${metadata.data.BACKGROUND_COLOR};
`;
