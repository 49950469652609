import { chunk } from 'src/lib/lodash';
import { db } from 'src/config/firebase';
const dbEmployees = db.collection('employees');

async function getEmployee({ employeeId }) {
  return (await dbEmployees.doc(employeeId).get()).data();
}

async function getEmployees({ employeeIds }) {
  const employeeIdChunks = chunk(employeeIds, 10);
  const employeeChunks = await Promise.all(
    employeeIdChunks.map(employeeChunkIds => dbEmployees.where('employeeId', 'in', employeeChunkIds).get())
  );
  return employeeChunks
    .map(employeeChunk => employeeChunk.docs.map(doc => doc.data()))
    .reduce((acc, employee) => [...acc, ...employee], []);
}

async function getFundManagerCurrentEmployees({ fundManagerId }) {
  return (await dbEmployees.where('currentFundManagerId', '==', fundManagerId).get()).docs.map(item => item.data());
}

async function getFundManagerFormerEmployees({ fundManagerId }) {
  return (
    await dbEmployees
      .where('parentFundManagerIds', 'array-contains', fundManagerId)
      .where('currentParentFundManagerId', '!=', fundManagerId)
      .get()
  ).docs.map(item => item.data());
}

async function getEmployeesAttendingSchool({ schoolId }) {
  return (await dbEmployees.where('schoolIds', 'array-contains', schoolId).get()).docs.map(item => item.data());
}

export { getEmployee };
export { getEmployees };
export { getFundManagerCurrentEmployees };
export { getFundManagerFormerEmployees };
export { getEmployeesAttendingSchool };
