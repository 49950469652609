import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { currency, abbreviations } from 'src/formatters';
import { parseUrl } from 'src/utils';
import { Link, HeaderField, HeaderFieldLabel, HeaderFieldValue } from 'src/components';
import { arrayUtil } from '@fundfilter/core/src/utils';

const millions = currency(abbreviations.million)();
const sortByStrategy = arrayUtil.sort((a, b) => (a.strategy > b.strategy ? 1 : -1));

const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto auto;
  gap: 10px;
`;

const Section = styled(HeaderField)`
  margin: 0;
`;

const StrategiesSection = styled(Section)`
  grid-column: span 3;
`;

const Label = styled(HeaderFieldLabel)`
  white-space: nowrap;
`;

const WebsiteLink = styled(Link).attrs({
  component: 'a',
  color: 'primary',
  target: '_blank',
})`
  position: relative;
  max-width: 9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

function Value({ children, formatter }) {
  const placeholderValue = '—';
  if (!children) children = placeholderValue;
  if (children === 'undefined') children = placeholderValue;
  if (Number.isNaN(children)) children = placeholderValue;
  if (typeof formatter === 'function' && children !== placeholderValue) children = formatter(children);
  return <HeaderFieldValue>{children}</HeaderFieldValue>;
}

function ManagerMetaData({ fundManager, location, strategies, fundCount }) {
  const strategyNames = sortByStrategy(strategies)
    .map(s => s.subStrategy)
    .join(', ');

  const websiteUrl = parseUrl(fundManager.websiteUrl);

  return (
    <Layout>
      <Section>
        <Label>WEBSITE</Label>
        <Value formatter={value => <WebsiteLink href={value.href}>{value.hostname}</WebsiteLink>}>{websiteUrl}</Value>
      </Section>

      <Section>
        <Label>ASSETS UNDER MGT.</Label>
        <Value formatter={millions}>{fundManager.aumPooledAmount}</Value>
      </Section>

      <Section>
        <Label>EMPLOYEES</Label>
        <Value>{fundManager.employeeCount}</Value>
      </Section>

      <Section>
        <Label>FOUNDED</Label>
        <Value>{fundManager.dateFounded ? fundManager.dateFounded.substr(0, 4) : null}</Value>
      </Section>

      <Section>
        <Label>Headquarters</Label>
        <Value>{`${((location.addressMeta || [])[0] || {}).city}`}</Value>
      </Section>

      <Section>
        <Label>Funds</Label>
        <Value>{fundCount}</Value>
      </Section>

      <StrategiesSection>
        <Label>STRATEGIES</Label>
        <Value>{strategyNames}</Value>
      </StrategiesSection>
    </Layout>
  );
}

ManagerMetaData.propTypes = {
  fundManager: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  strategies: PropTypes.array.isRequired,
  fundCount: PropTypes.number.isRequired,
};

export default ManagerMetaData;
