import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MuiTextField } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { form } from 'src/config/colors';

const lockedStyles = css`
  fieldset {
    border-color: transparent;
  }

  input {
    padding-left: 0em;
    padding-right: 0em;
  }

  .MuiOutlinedInput-root:hover,
  .MuiOutlinedInput-root:focus {
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }

  .MuiInputBase-root:hover input {
    cursor: pointer;
  }

  .MuiInputBase-root:focus input {
    cursor: text;
  }
`;

const StandardTextField = styled(MuiTextField)`
  margin: 0;

  fieldset {
    border-radius: 0;
  }

  input {
    transition: padding 350ms ease;
  }

  ${p => p.$locked && lockedStyles};
`;

const CompactTextField = styled(StandardTextField).attrs(p => {
  return {
    InputLabelProps: p.InputLabelProps,
    margin: 'dense',
  };
})`
  label {
    color: #818181;
  }

  input {
    font-size: unset;
    color: #b1b1b1;
    height: auto;
    padding: 0.375em 0.5em;
    line-height: 1.5;
    /* background-color: inherit; */

    ::placeholder {
      font-size: inherit;
      font-weight: 300;
      color: #818181;
      opacity: 1;
      text-transform: capitalize;
    }
  }
`;

const EnchancedTextField = styled(StandardTextField).attrs(p => {
  return {
    fullWidth: true,
    InputLabelProps: { shrink: true, ...p.InputLabelProps },
    variant: 'outlined',
    margin: 'dense',
  };
})`
  background-color: ${form.input.BACKGROUND_COLOR};

  label {
    color: #818181;
  }

  input {
    font-size: unset;
    color: #b1b1b1;
    height: auto;
    /* line-height: 1.34; */
    background-color: inherit;

    ::placeholder {
      font-size: inherit;
      font-weight: 300;
      color: #818181;
      opacity: 1;
      text-transform: capitalize;
    }
  }
`;

const AuthFormTextField = styled(EnchancedTextField)`
  & + & {
    margin-top: 30px;
  }
`;

function TextField({ preset, locked, ...rest }) {
  const props = { $locked: locked, readOnly: locked, ...rest };

  switch (preset) {
    case 'standard':
      return <StandardTextField {...props} />;

    case 'enhanced':
      return <EnchancedTextField {...props} />;

    case 'authForm':
      return <AuthFormTextField {...props} />;

    case 'compact':
      return <CompactTextField {...props} />;

    default:
      return <StandardTextField {...props} />;
  }
}

TextField.defaultProps = {
  preset: 'standard',
};

TextField.propTypes = {
  preset: PropTypes.oneOf(['standard', 'enhanced', 'authForm', 'compact']),
};

export default TextField;
