import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconStrategy(props) {
  return (
    <Svg viewBox="0 0 2539.98 3174.975" {...props}>
      <polygon points="200.36,2271.42 765.87,2271.42 765.87,1705.91 532.92,1705.91 532.91,1221.39 1220.87,1221.39 1220.87,1705.91 987.24,1705.92 987.24,2271.42 1552.74,2271.42 1552.74,1705.91 1321.54,1705.91 1320.87,1221.39 2008.83,1221.39 2008.82,1705.92 1774.12,1705.92 1774.12,2271.42 2339.62,2271.42 2339.62,1705.92 2108.42,1705.92 2108.43,1113.33 1320.87,1113.33 1320.87,834.06 1553.63,834.06 1553.63,268.55 988.12,268.55 988.12,834.06 1220.87,834.06 1220.87,1113.33 433.31,1113.33 433.32,1705.91 200.36,1705.91 " />
    </Svg>
  );
}
