import { useMemo } from 'react';
import { useFundManagerBookmarks, useFundManagers } from 'src/hooks';
import { useGetMany } from 'src/services/filter-api/fundManagerLocations/queries';

function useBookmarkedFundManagers() {
  const fundManagerIds = useFundManagerBookmarks();
  const fundManagers = useFundManagers({ fundManagerIds });
  const getFundManagerLocations = useGetMany(fundManagerIds);

  return useMemo(() => {
    if (!fundManagers) return;
    return fundManagers.map(fm => {
      const location = getFundManagerLocations
        .filter(q => Boolean(q.data))
        .map(q => q.data)
        .find(fml => fml.fundManagerId === fm.fundManagerId);

      if (!location) return fm;
      return { ...fm, hq: location.addressMeta[0].city };
    });
  }, [fundManagers, getFundManagerLocations]);
}

export default useBookmarkedFundManagers;
