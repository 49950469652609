import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from 'src/components';

function UserPasswordResetForm({ onSubmit }) {
  const [email, setEmail] = useState(null);

  return (
    <div>
      <TextField
        preset="authForm"
        required
        label="Required"
        type="text"
        placeholder="email"
        onChange={e => setEmail(e.target.value)}
        style={{ marginBottom: '30px' }}
      />
      <Button variant="contained" color="primary" $block onClick={() => onSubmit(email)} disabled={!email}>
        Reset password
      </Button>
    </div>
  );
}

UserPasswordResetForm.defaultProps = {
  onSubmit: () => {},
};

UserPasswordResetForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default UserPasswordResetForm;
