import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconChat(props) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <polygon points="22 13 22 7 16 7 16 9 18.6 9 12.9 14.7 8.8 10.6 4 15.5 4 2 2 2 2 22 22 22 22 20 4 20 4 18.4 8.8 13.4 12.9 17.5 20 10.4 20 13" />
    </Svg>
  );
}
