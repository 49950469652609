import { chunk } from 'src/lib/lodash';
import { db } from 'src/config/firebase';
import { localDb } from 'src/config/dexie';

const dbFundManagers = db.collection('fundManagers');
const dbFundManagerLocations = db.collection('fundManagerLocations');
const dbFundManagersNameList = db.collection('fundManagerNameList');
const localDbFundManagersNameList = localDb.fundManagerNamesList;

async function getFundManager({ fundManagerId }) {
  return (await dbFundManagers.doc(fundManagerId).get()).data();
}

async function getFundManagerLocation({ fundManagerId }) {
  return (await dbFundManagerLocations.doc(fundManagerId).get()).data();
}

async function getFundManagers({ fundManagerIds }) {
  const fundManagerIdChunks = chunk(fundManagerIds, 10);
  const fundManagerChunks = await Promise.all(
    fundManagerIdChunks.map(fundManagerChunkIds =>
      dbFundManagers.where('fundManagerId', 'in', fundManagerChunkIds).get()
    )
  );
  return fundManagerChunks
    .map(historyChunk => historyChunk.docs.map(doc => doc.data()))
    .reduce((acc, history) => [...acc, ...history], []);
}

async function getFundManagerNameList() {
  const localData = await localDbFundManagersNameList.toArray();
  if (localData.length > 0) return localData;

  const snapshot = await dbFundManagersNameList.get();
  const data = snapshot.docs
    .flatMap(doc => Object.entries(doc.data()))
    .map(entry => ({ fundManagerId: entry[0], name: entry[1] }));

  localDbFundManagersNameList.bulkPut(data);

  return data;
}

export { getFundManager };
export { getFundManagerLocation };
export { getFundManagers };
export { getFundManagerNameList };
