import styled from 'styled-components';
import { modal } from 'src/config/colors';

const ModalHeader = styled.header`
  color: ${modal.header.TEXT_COLOR};
  background-color: ${modal.header.BACKGROUND_COLOR};

  width: 100%;
  padding: 0.59375em var(--modal-padding);

  font-size: 1.142857143em;
  font-weight: 300;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 -0.5px 0 #000000;
`;

export default ModalHeader;
