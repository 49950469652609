import React from 'react';
import { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import routes from 'src/config/routes';
import { lookup } from 'src/lookup';
import { useFund, useFundFinancialHistory } from 'src/hooks';
import {
  AppPanel,
  ButtonRounded,
  FinancialMetricSelection,
  FundFinancialHistoryTimeSeries,
  FundPeerBenchmarks,
  Link,
  IconBarChart,
  IconLineChart,
  FundForecastCurves,
} from 'src/components';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BreadcrumbNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  padding: 10px 0;
`;

const BreadcrumbLink = styled(Link).attrs({ component: 'button' })`
  font-size: 12px;
`;

const SectionNav = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 0.5)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0;
`;

const TimeRangeButton = styled(ButtonRounded)`
  margin-right: 4px;
  min-width: unset;
`;

const ContentSection = styled.section`
  height: 100%;
`;

const View = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  height: ${p => !p.$in && '0px'};
  overflow: ${p => !p.$in && 'hidden'};
`;

const VIEWS = {
  KEY_METRICS: 'Key Metrics',
  TIMESERIES: 'Performance',
  J_CURVE: 'J-Curve',
};

function SingleFundView({
  className,
  fundId,
  onClickBack,
  timeRange,
  handleChangeTimeRange,
  financialMetric,
  handleChangeFinancialMetric,
}) {
  const fund = useFund({ fundId: fundId });
  const fundHistory = (
    useFundFinancialHistory({
      fundId: fundId,
    }) || {}
  ).items;

  const { setControls } = useContext(AppPanel.Context);
  const [defaultView, setDefaultView] = useState(VIEWS.J_CURVE);
  const [userView, setUserView] = useState();
  const view = userView || defaultView;

  function handleChangeHistoryData(data) {
    setDefaultView(data.length < 2 ? VIEWS.KEY_METRICS : VIEWS.J_CURVE);
  }

  useEffect(() => {
    setControls(
      <div>
        <AppPanel.Button
          startIcon={<IconLineChart />}
          selected={view === VIEWS.J_CURVE}
          onClick={() => setUserView(VIEWS.J_CURVE)}
        >
          {VIEWS.J_CURVE}
        </AppPanel.Button>
        <AppPanel.Button
          startIcon={<IconBarChart />}
          selected={view === VIEWS.KEY_METRICS}
          onClick={() => setUserView(VIEWS.KEY_METRICS)}
        >
          {VIEWS.KEY_METRICS}
        </AppPanel.Button>
        {/*
          This view has been temporarily removed pending further review.

          <AppPanel.Button
            startIcon={<IconLineChart />}
            selected={view === VIEWS.TIMESERIES}
            onClick={() => setView(VIEWS.TIMESERIES)}
          >
            {VIEWS.TIMESERIES}
          </AppPanel.Button>
        */}
      </div>
    );
  }, [setControls, view]);

  function handleViewFundClick() {
    navigate(routes.FUND.replace(':fundId', fundId));
  }

  const getDefaultTimeRange = useCallback(
    function () {
      const { oneQuarter, threeQuarters, oneYear } = lookup.timeRange;

      if (!fund) return oneQuarter;
      if (!fundHistory) return oneQuarter;
      if (fundHistory.length < 3) return oneQuarter;
      if (fundHistory.length < 4) return threeQuarters;
      return oneYear;
    },
    [fund, fundHistory]
  );

  const selectedTimeRange = timeRange || getDefaultTimeRange();

  return (
    <Layout className={className}>
      <BreadcrumbNav>
        <BreadcrumbLink onClick={onClickBack}>&lsaquo; BACK TO ALL FUNDS</BreadcrumbLink>
        <span
          css={`
            position: absolute;
            width: 100%;
            text-align: center;
            font-size: 18px;
            opacity: 0.8;
            pointer-events: none;
          `}
        >
          {(fund || {}).name}
        </span>
        <BreadcrumbLink onClick={handleViewFundClick}>VIEW FUND &raquo;</BreadcrumbLink>
      </BreadcrumbNav>

      {view === VIEWS.TIMESERIES && (
        <SectionNav>
          <div>
            {Object.values(lookup.timeRange).map(tr => (
              <TimeRangeButton
                key={tr.value}
                selected={tr === selectedTimeRange}
                onClick={() => handleChangeTimeRange(tr)}
              >
                {tr.label}
              </TimeRangeButton>
            ))}
          </div>
          <FinancialMetricSelection
            financialMetric={financialMetric}
            onMetricChange={handleChangeFinancialMetric}
            hidePme
          />
        </SectionNav>
      )}

      <ContentSection>
        <View $in={view === VIEWS.TIMESERIES}>
          <FundFinancialHistoryTimeSeries
            isOnDashboard
            isUserFund={Boolean(fund?.userId)}
            data={fundHistory || []}
            metric={financialMetric}
            timeRange={selectedTimeRange}
            css={`
              flex: 1;
            `}
          />{' '}
        </View>

        <View $in={view === VIEWS.KEY_METRICS}>
          <FundPeerBenchmarks fundId={fundId} />
        </View>

        <View $in={view === VIEWS.J_CURVE}>
          <FundForecastCurves fundId={fundId} onChangeHistoryData={handleChangeHistoryData} />
        </View>
      </ContentSection>
    </Layout>
  );
}

SingleFundView.defaultProps = {};

SingleFundView.propTypes = {};

export default SingleFundView;
