import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Responsive, CashflowDataVisualization, StrategyCurveDefaultIndicator } from '..';

const MARGIN_NORMAL = { top: '1.5em', right: '0em', bottom: '1.7em', left: '0em' };
const MARGIN_EXPANDED = { top: '1.5em', right: '0em', bottom: '2.2em', left: '0em' };

const StrategyGridItem = styled.div`
  position: relative;
  display: flex;
  min-height: 0;
  cursor: pointer;
  padding: 4px 8px;
  color: #fff;
  border: 2px solid #4a4a4a;
  flex: 1;
`;

const StrategyHeader = styled.header`
  position: absolute;
  top: 4px;
  left: 8px;
  right: 8px;
  color: #bbb;
`;

const StrategyName = styled.div`
  font-size: 0.666666667em;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.083333333em;
`;

const SubStrategyName = styled.div`
  display: block;
  font-weight: 500;
  margin: 0;
`;

function StrategyCurveHistoryItem({ strategy, timeseries, isStrategyTimeseries, expanded, type, colors, onItemClick }) {
  function handleItemClick() {
    onItemClick(this, strategy);
  }

  return (
    <StrategyGridItem onClick={handleItemClick}>
      <Responsive.OnAtLeastTablet
        css={`
          flex: 1;
        `}
      >
        {isStrategyTimeseries && <StrategyCurveDefaultIndicator x={20} y={48} />}
        <CashflowDataVisualization
          showYAxisLabels={false}
          showMilestoneMarkers={expanded}
          type={type}
          margin={expanded ? MARGIN_EXPANDED : MARGIN_NORMAL}
          smallXAxisLabels={!expanded}
          xAxisTicks={2}
          xAxisFormatter={(_, i) => `Year ${i + 1}`}
          colors={colors}
          forecastTimeseries={timeseries}
        />
      </Responsive.OnAtLeastTablet>
      <StrategyHeader>
        <SubStrategyName>{strategy?.subStrategy}</SubStrategyName>
        <StrategyName>{strategy?.strategy}</StrategyName>
      </StrategyHeader>
    </StrategyGridItem>
  );
}

StrategyCurveHistoryItem.defaultProps = {
  expanded: false,
  timeseries: [],
  isStrategyTimeseries: false,
  colors: {
    forecastContributionBarColor: '#4da8c2',
    forecastLineColor: '#4da8c2',
    failedBreakevenSubjectLineColor: '#ff7700',
    failedBreakevenForecastLineColor: '#ff7700',
    milestoneAccentColor: '#ffffff',
  },
  onItemClick: () => {},
};

StrategyCurveHistoryItem.propTypes = {
  strategy: PropTypes.object,
  timeseries: PropTypes.array,
  isStrategyTimeseries: PropTypes.bool,
  expanded: PropTypes.bool,
  type: PropTypes.string,
  colors: PropTypes.object,
  onItemClick: PropTypes.func,
};

export default StrategyCurveHistoryItem;
