import {
  select,
  max,
  min,
  sum,
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  stack,
  stackOrderNone,
  stackOffsetNone,
} from 'd3';
import { currency, abbreviations } from 'src/formatters';
import colorScale from './colors';
import getDataKeys from './getDataKeys';

const millions = currency(abbreviations.million)();

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, {
      year: d.data.year,
      label: d.label,
      strategyId: d.strategyId,
      size: d.size,
    });
  };
}

function create({ id, margin }) {
  const prefixedId = `#${id}`;

  const root = select(prefixedId)
    .append('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  root.append('g').attr('class', 'title').append('text');
  root.append('g').attr('class', 'x-axis').style('shape-rendering', 'crispEdges');
  root.append('g').attr('class', 'y-axis').style('shape-rendering', 'crispEdges');

  // add Y axis label
  root
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('dy', '1em')
    .attr('class', 'y-axis-label')
    .style('text-anchor', 'middle')
    .text('FUNDS RAISED');

  // add the legend
  root.append('g').attr('class', 'legend');

  return root;
}

function draw({ id, data, size, margin, mouseEventRefs }) {
  // set the dimensions and margins of the graph
  const svgWidth = Math.max(size.width - margin.left - margin.right, 0);
  const svgHeight = Math.max(size.height - margin.top - margin.bottom, 0);

  if (!svgWidth || !svgHeight) return;

  const keys = getDataKeys(data);

  let root = select(`#${id} .root`);
  if (!root.node()) {
    root = create({ id, margin });
  }

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const seriesFn = stack()
    .keys(keys)
    .value((d, key) => (d[key] ? d[key].size : 0))
    .order(stackOrderNone)
    .offset(stackOffsetNone);

  const series = seriesFn(data);

  const minYear = +min(data.map(d => d.year));
  const maxYear = new Date().getUTCFullYear();
  const years = new Array(maxYear - minYear + 1).fill().map((_, index) => minYear + index);

  const x = scaleBand().domain(years).range([0, svgWidth]).padding(0.5);

  const yMax = max(
    data.map(datum =>
      sum(
        Object.values(datum)
          .slice(1)
          .map(item => item.size)
      )
    )
  );

  const y = scaleLinear().domain([0, yMax]).rangeRound([svgHeight, 0]);

  // add the X axis
  root
    .select('.x-axis')
    .attr('transform', `translate(0,${svgHeight + 5})`)
    .transition()
    .call(axisBottom(x).tickSize(0));

  // add the Y axis
  root.select('.y-axis').transition().call(axisLeft(y).ticks(4).tickSize(-svgWidth).tickFormat(millions));

  // position the y-axis label
  root
    .select('.y-axis-label')
    .attr('y', 0 - margin.left + 10)
    .attr('x', 0 - svgHeight / 2)
    .transition();

  const color = colorScale(series);

  const bands = root.selectAll('.band').data(series, d => d.index);

  bands.join(
    enter =>
      enter
        .append('g')
        .attr('class', (_d, i) => `band band-${i}`)
        .attr('fill', (_d, i) => color(i)),
    update =>
      update.call(u =>
        u
          .transition()
          .attr('class', (_d, i) => `band band-${i}`)
          .attr('fill', (_d, i) => color(i))
      ),
    exit => exit.call(e => e.transition().remove())
  );

  // eslint-disable-next-line no-shadow
  series.forEach((data, i) => {
    const localData = data.map(d => ({
      ...d,
      ...d.data[data.key],
    }));

    const band = root
      .select(`.band-${i}`)
      .selectAll('.bar')
      .data(localData, d => d.data.year);

    band.join(
      enter =>
        enter
          .append('rect')
          .attr('height', d => y(d[0]) - y(d[1]))
          .attr('width', x.bandwidth())
          .attr('x', d => x(d.data.year))
          .attr('y', d => y(d[1]))
          .attr('class', 'bar')
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd)),
      update =>
        update
          .attr('x', d => x(d.data.year))
          .attr('y', d => y(d[1]))
          .attr('height', d => y(d[0]) - y(d[1]))
          .attr('width', x.bandwidth())
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd))
          .call(u =>
            u
              .transition()
              .attr('x', d => x(d.data.year))
              .attr('y', d => y(d[1]))
              .attr('height', d => y(d[0]) - y(d[1]))
              .attr('width', x.bandwidth())
          ),
      exit => exit.call(ex => ex.transition().attr('cy', svgHeight).remove())
    );
  });
}

export default draw;
