import React from 'react';
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import mergeRefs from 'react-merge-refs';
import { refPropType, valueOfPropType, stringSort, numericSort } from './utils';
import Header from './Header';
import RowItem from './RowItem';

const DEFAULT_ITEM_SIZE = 26;

const StyledHeader = styled(Header)`
  padding-right: 8px;
`;

const List = styled(FixedSizeList)`
  overflow-x: hidden !important;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Layout = styled.div`
  overflow: hidden;
`;

const sort = ({ property, isNumeric, isDescending }) => {
  if (isNumeric) {
    return numericSort(property, isDescending);
  }
  return stringSort(property, isDescending);
};

function VirtualizedTable({
  className,
  data,
  listDef,
  listRef,
  enableHover,
  rowColor,
  rowBackgroundColor,
  rowHoverColor,
  rowHoverBackgroundColor,
  onColumnClick,
  onRowClick,
  onRowMouseOver,
  onRowMouseOut,
  onColumnMouseOver,
  onColumnMouseOut,
  onScroll,
  onDataUpdate,
  itemSize,
  showHeader,
  rowClassName,
}) {
  const localListRef = useRef(null);
  const listRefs = mergeRefs([localListRef, listRef]);

  const [size, setSize] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [sortField, setSortField] = useState(null);

  const localListDef = useMemo(
    () => listDef?.filter(c => !size?.width || size?.width > (c.minDisplayWidth ?? 0)),
    [listDef, size]
  );

  useEffect(() => {
    if (!sortField) {
      setLocalData(data);
      onDataUpdate(data);
      return;
    }

    const comparer = sort({ ...sortField });
    const clone = data.slice();
    clone.sort(comparer);
    setLocalData(clone);
    onDataUpdate(clone);
  }, [data, sortField, onDataUpdate]);

  const handleSort = useCallback(
    function ({ property, isNumeric = false }) {
      let currentSort = sortField ? { ...sortField } : {};
      if (currentSort.property === property) {
        currentSort.isDescending = !currentSort.isDescending;
      } else {
        currentSort = { property, isDescending: true, isNumeric };
      }
      setSortField(currentSort);
    },
    [sortField]
  );

  if (!localData) return null;

  return (
    <Layout className={className}>
      <AutoSizer onResize={setSize}>
        {({ height, width }) => (
          <>
            {showHeader && (
              <StyledHeader width={width} listDef={localListDef} handleSort={handleSort} sortField={sortField} />
            )}
            <List
              ref={listRefs}
              height={height - (itemSize || DEFAULT_ITEM_SIZE)}
              width={width}
              itemCount={localData.length}
              itemSize={itemSize || DEFAULT_ITEM_SIZE}
              onScroll={onScroll}
              className="vt-list"
              itemData={{
                localData,
                enableHover,
                rowColor,
                rowBackgroundColor,
                rowHoverColor,
                rowHoverBackgroundColor,
                onRowClick,
                onRowMouseOver,
                onRowMouseOut,
                listDef: localListDef,
                onColumnMouseOver,
                onColumnMouseOut,
                onColumnClick,
                rowClassName,
              }}
            >
              {RowItem}
            </List>
          </>
        )}
      </AutoSizer>
    </Layout>
  );
}

VirtualizedTable.defaultProps = {
  showHeader: true,
  data: null,
  listDef: null,
  listRef: null,
  enableHover: false,
  rowHoverColor: null,
  rowHoverBackgroundColor: null,
  rowBackgroundColor: null,
  rowColor: null,
  onColumnClick: () => {},
  onRowClick: () => {},
  onRowMouseOver: () => {},
  onRowMouseOut: () => {},
  onColumnMouseOver: () => {},
  onColumnMouseOut: () => {},
  onScroll: () => {},
  onDataUpdate: () => {},
  rowClassName: '',
};

VirtualizedTable.propTypes = {
  showHeader: PropTypes.bool,
  data: PropTypes.array,
  listDef: PropTypes.array,
  listRef: refPropType,
  enableHover: PropTypes.bool,
  rowColor: valueOfPropType,
  rowHoverColor: valueOfPropType,
  rowBackgroundColor: valueOfPropType,
  rowHoverBackgroundColor: valueOfPropType,
  onColumnClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowMouseOver: PropTypes.func,
  onRowMouseOut: PropTypes.func,
  onColumnMouseOver: PropTypes.func,
  onColumnMouseOut: PropTypes.func,
  onScroll: PropTypes.func,
  onDataUpdate: PropTypes.func,
  rowClassName: valueOfPropType,
};

export default React.memo(VirtualizedTable);
