import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagers } from '../store/fund-managers';

export default function useFundManagers({ fundManagerIds }) {
  const dispatch = useDispatch();
  const fundManagers = useSelector(state => state.fundManager.all);
  const [state, setState] = useState(null);

  useEffect(() => {
    if (fundManagerIds) {
      dispatch(fetchFundManagers({ fundManagerIds }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundManagerIds, fundManagers.byId]);

  useEffect(() => {
    if (
      fundManagerIds &&
      fundManagerIds
        .map(fundManagerId => fundManagers.byId[fundManagerId])
        .filter(manager => manager && manager.fetched).length === fundManagerIds.length
    ) {
      setState(
        fundManagerIds
          .map(managerId => fundManagers.byId[managerId])
          .map(manager => {
            const hq = manager.addressMeta ? manager.addressMeta[0].city : null;
            return { ...manager, hq };
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [fundManagerIds, fundManagers.byId]);
  return state;
}
