import { isArray } from './array';

function isObject(obj) {
  if (!obj) return false;
  if (isArray(obj)) return false;
  return typeof obj === 'object';
}

function pick() {
  const keys = Array.from(arguments);
  return function (obj = {}) {
    return keys.reduce((acc, key) => {
      const value = obj[key];
      if (value === undefined) return acc;
      return { ...acc, [key]: obj[key] };
    }, {});
  };
}

function pickKeys() {
  const args = arguments;
  return function (obj = {}) {
    const result = pick(...args)(obj);
    return Object.keys(result);
  };
}

function pickValues() {
  const args = arguments;
  return function (obj = {}) {
    const result = pick(...args)(obj);
    return Object.values(result);
  };
}

function denormalize(field, primaryKey) {
  return function (parent) {
    const obj = parent[field];
    if (!isObject(obj)) return parent;

    return {
      ...parent,
      [field]: Object.values(obj).map(value => {
        return {
          ...value,
          [primaryKey]: parent[primaryKey],
        };
      }),
    };
  };
}

export { pick, pickKeys, pickValues, isObject, denormalize };
