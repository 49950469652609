import { usageTrackingChannel } from './usage-tracking';

function boot({ appId, name, email, created }) {
  if (typeof window.Intercom !== 'function') return;

  const createdTs = Math.floor(new Date(created).getTime() / 1000);
  const options = {
    app_id: appId,
    name, // Full name
    email, // Email address
    created_at: createdTs, // Signup date as a Unix timestamp
  };
  window.Intercom('boot', options);
}

function logEvent(message) {
  if (typeof window.Intercom !== 'function') return;

  window.Intercom('trackEvent', message.topic);
}

usageTrackingChannel.subscribe('#', (_, message) => logEvent(message));

export const intercom = {
  boot,
};
