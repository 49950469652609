import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import FundNameField from './FundNameField';

function AddExistingFundDialog({ open, onClose }) {
  const [selectedFundId, setSelectedFundId] = useState('');

  return (
    <Dialog maxWidth="lg" open={open}>
      <DialogTitle>Add an Existing Fund to this Portfolio</DialogTitle>
      <DialogContent>
        <FundNameField onChangeSelectedFund={fund => setSelectedFundId(fund?.fundId)} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()} color="primary">
          cancel
        </Button>
        <Button
          disabled={!selectedFundId}
          variant="contained"
          onClick={() => onClose(selectedFundId)}
          color="primary"
          autoFocus
        >
          Add to Portfolio
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddExistingFundDialog.defaultProps = {
  message: '',
  open: false,
  onClose: () => {},
};

AddExistingFundDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddExistingFundDialog;
