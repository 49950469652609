import React from 'react';
import styled from 'styled-components';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import IconCheckboxUnchecked from '../IconCheckboxUnchecked';
import IconCheckboxChecked from '../IconCheckboxChecked';

const CheckedIcon = styled(IconCheckboxChecked)``;

const UncheckedIcon = styled(IconCheckboxUnchecked)``;

const Checkbox = styled(MuiCheckbox)`
  width: 28px;
  height: 28px;
  padding: 0;
  color: ${p => (p.$color ? p.$color : '#626262')};

  .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
  }
`;

export default function CheckboxLarge(props) {
  const { color = 'currentColor', ...rest } = props;
  return <Checkbox $color={color} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...rest} />;
}
