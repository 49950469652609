import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';
import { sentenceSplit } from '../formatters';

const summarizeNewsArticlePending = createAction('SUMMARIZE_NEWS_ARTICLE_PENDING');
const summarizeNewsArticleError = createAction('SUMMARIZE_NEWS_ARTICLE_ERROR');
const summarizeNewsArticleSuccess = createAction('SUMMARIZE_NEWS_ARTICLE_SUCCESS');

const sourcesWithPaywall = [
  'barrons.com',
  'bizjournals.com',
  'bloomberg.com',
  'bloombergquint.com',
  'bostonglobe.com',
  'bostonherald.com',
  'business-standard.com',
  'businessinsider.com',
  'businessinsider.com.au',
  'businessinsider.in',
  'cbinsights.com',
  'chicagotribune.com',
  'commercialobserver.com',
  'computerweekly.com',
  'crunchbase.com',
  'denverpost.com',
  'economist.com',
  'financialpost.com',
  'fortune.com',
  'ft.com',
  'greentechmedia.com',
  'haaretz.com',
  'irishtimes.com',
  'japantimes.co.jp',
  'latimes.com',
  'nationalpost.com',
  'newsweek.com',
  'newswire.ca',
  'nytimes.com',
  'nzherald.co.nz',
  'pehub.com',
  'pionline.com',
  'pitchbook.com',
  'politico.com',
  'politico.eu',
  'prnewswire.com',
  'qz.com',
  'richmond.com',
  'seattletimes.com',
  'seekingalpha.com',
  'skift.com',
  'smh.com.au',
  'startribune.com',
  'stltoday.com',
  'techinasia.com',
  'telegraph.co.uk',
  'thestar.com',
  'time.com',
  'washingtonpost.com',
  'wsj.com',
];

const SUMMARY_TYPE = {
  text: 1,
  url: 2,
};

function parseUrl(href) {
  const l = document.createElement('a');
  l.href = href;
  return l;
}

export function getSummaryType(article) {
  const { hostname } = parseUrl(article.url);
  if (sourcesWithPaywall.indexOf(hostname.toLowerCase()) > -1) {
    return SUMMARY_TYPE.text;
  }
  return SUMMARY_TYPE.url;
}

export function getKey({ articleId, summaryLength }) {
  return `${articleId}_${summaryLength}`;
}

export function summarizeNewsArticle({ article, summaryLength }) {
  return async function (dispatch, getState) {
    const { articleId } = article;
    try {
      // check if  the summary already in state?
      const { byId } = getState().news.summaries;
      if (byId[getKey({ articleId, summaryLength })]) return;

      dispatch(summarizeNewsArticlePending({ articleId, summaryLength }));

      // check if the summary is in the database
      let summary = await filterApi.getNewsSummary({ articleId, summaryLength });

      if (summary) {
        dispatch(
          summarizeNewsArticleSuccess({
            articleId,
            sentences: summary.sentences,
            summaryLength,
          })
        );
        return;
      }

      const summaryType = getSummaryType(article);

      // create a new summary
      summary = await filterApi.createNewsSummary({
        text: article.content,
        url: article.url,
        summaryType,
        summaryLength,
      });

      // remove the [...] strings
      summary = summary.replace(/ \[...\] /g, ' ');
      const sentences = sentenceSplit(summary);

      // save to the db
      filterApi.setNewsSummary({ articleId, summaryLength, sentences });

      dispatch(
        summarizeNewsArticleSuccess({
          articleId,
          sentences,
          summaryLength,
          summaryType,
        })
      );
    } catch (error) {
      dispatch(summarizeNewsArticleError({ error: error.message, articleId, summaryLength }));
    }
  };
}

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [summarizeNewsArticlePending]: (state, action) => {
    const existing = state.byId[getKey(action.payload)] || {};
    existing.fetching = true;
    state.byId[getKey(action.payload)] = existing;
  },
  [summarizeNewsArticleError]: (state, action) => {
    const { error } = action.payload;
    const existing = state.byId[getKey(action.payload)] || {};
    existing.fetching = false;
    existing.error = action.payload.error;
    state.byId[getKey(action.payload)] = existing;
    console.error(error);
  },
  [summarizeNewsArticleSuccess]: (state, action) => {
    state.byId[getKey(action.payload)] = {
      sentences: action.payload.sentences,
      fetched: true,
      fetching: false,
      error: null,
    };
  },
});
