import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStripe } from '@stripe/react-stripe-js';
import { filterApi } from 'src/services';
import { useCurrentUser, useDefaultPaymentMethod, useCurrentSubscription } from 'src/hooks';
import { Button } from 'src/components';
import * as date from 'src/utils/date';
import PaymentMethod from './PaymentMethod';
import CreditCardForm from './CreditCardForm';
import FormSubmitButton from './FormSubmitButton';
import FormCancelButton from './FormCancelButton';
import Collapse from '@material-ui/core/Collapse';

const Footer = styled.footer`
  margin-top: 1.5rem;
  padding-top: 0.75rem;
`;

function CreateSubscriptionDialog({ open, onClose, plan }) {
  const stripe = useStripe();
  const currentUser = useCurrentUser();
  const customerId = currentUser?.stripeCustomerId;
  const trialExpiryDate = date.parseISO(currentUser?.trialExpiresAt);

  const {
    data: defaultPaymentMethod,
    refetch: refetchDefaultPaymentMethod,
    invalidate: invalidateDefaultPaymentMethod,
    delete: deleteDefaultPaymentMethod,
    isLoading,
  } = useDefaultPaymentMethod();

  const { data: currentSubscription, refetch: refetchSubscriptions } = useCurrentSubscription();

  const [isProcessing, setIsProcessing] = useState();
  const [paymentError, setPaymentError] = useState();
  const [confirmRemovePaymentMethod, setConfirmRemovePaymentMethod] = useState();

  const paymentIntent = currentSubscription?.latest_invoice?.payment_intent;
  const paymentStatus = paymentIntent?.status;
  const subscriptionStatus = currentSubscription?.status;

  const isTrialExpired = date.isPastOrNow(trialExpiryDate);
  const needsPaymentMethod = !defaultPaymentMethod || paymentError;
  const needsPaymentConfirmation = isTrialExpired && defaultPaymentMethod && paymentStatus === 'requires_action';
  const showDefaultPaymentMethod = Boolean(defaultPaymentMethod) && !paymentError;

  function handleCancel() {
    setPaymentError(null);
    onClose();
  }

  function handleClose(evt, reason) {
    if (reason === 'backdropClick') return;
    onClose(evt);
  }

  async function handleSubscribe() {
    setIsProcessing(true);

    if (paymentStatus === 'requires_payment_method') {
      await confirmPaymentMethod();
      await refetchSubscriptions();
      return setIsProcessing(false);
    }

    try {
      if (!customerId) throw new Error('No customerId');
      if (!plan?.id) throw new Error('No plan/id');

      await filterApi.stripeCreateSubscription({
        customerId,
        priceId: plan.id,
        trialEnd: isTrialExpired ? undefined : trialExpiryDate ? date.formatUnixTimestamp(trialExpiryDate) : undefined,
      });

      await refetchSubscriptions();
    } catch (createSubscriptionError) {
      console.error(createSubscriptionError);
      setPaymentError(createSubscriptionError.message);
    }

    setIsProcessing(false);
  }

  async function handleRemovePaymentMethod() {
    const timer = setTimeout(() => {
      setConfirmRemovePaymentMethod(false);
    }, 3000);

    if (!confirmRemovePaymentMethod) {
      return setConfirmRemovePaymentMethod(true);
    }

    clearTimeout(timer);

    await deleteDefaultPaymentMethod();
    await refetchDefaultPaymentMethod();
  }

  const confirmPaymentMethod = useCallback(
    async function confirmPaymentMethod() {
      setIsProcessing(true);

      const response = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: defaultPaymentMethod.id,
      });

      if (response.error) setPaymentError(response.error.message);

      setIsProcessing(false);
    },
    [defaultPaymentMethod, paymentIntent, stripe]
  );

  useEffect(() => {
    if (!needsPaymentConfirmation) return;
    if (!stripe) return;

    confirmPaymentMethod();
  }, [confirmPaymentMethod, needsPaymentConfirmation, stripe]);

  if (!plan) return null;

  if (isLoading)
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        css={`
          .MuiDialog-paper {
          }
        `}
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <p>Loading...</p>
        </DialogContent>
      </Dialog>
    );

  if (subscriptionStatus === 'active' || subscriptionStatus === 'trialing')
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Thanks for subscribing</DialogTitle>
        <DialogContent>
          <p>You&apos;re all set up, we hope you enjoy what we&apos;ve built.</p>
          <Footer>
            <Button variant="contained" disableElevation onClick={handleCancel} $isLoading={isProcessing}>
              Done
            </Button>
          </Footer>
        </DialogContent>
      </Dialog>
    );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
      <DialogContent>
        <section>
          <p>
            Do you want to subcribe to the {plan.name} for ${(plan.price / 100).toFixed(2)} per {plan.interval}?
          </p>
        </section>

        <Collapse in={Boolean(showDefaultPaymentMethod)}>
          <section>
            <div
              css={`
                display: flex;
                align-items: center;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-color: gray;
                margin: 1.5rem 0;
              `}
            >
              <PaymentMethod
                card={defaultPaymentMethod?.card}
                css={`
                  flex: 1;
                `}
              />

              <Button
                color={confirmRemovePaymentMethod ? 'danger' : 'default'}
                variant={confirmRemovePaymentMethod ? 'contained' : 'text'}
                disableElevation
                size="small"
                onClick={handleRemovePaymentMethod}
              >
                {confirmRemovePaymentMethod ? <>Remove?</> : <DeleteIcon fontSize="small" />}
              </Button>
            </div>
          </section>
        </Collapse>

        <Collapse in={Boolean(needsPaymentMethod)}>
          <section>
            <div>Add a credit card to continue</div>

            <CreditCardForm
              errorMessage={paymentError}
              onSuccess={() => {
                setPaymentError(null);
                invalidateDefaultPaymentMethod();
              }}
              onCancel={handleCancel}
            />
          </section>
        </Collapse>

        <div>
          {!needsPaymentMethod && (
            <p>
              Great! Looks like we&apos;re ready to go. Just hit &ldquo;Subscribe&rdquo; below to finish up. We&apos;ll
              use the payment method above for all future payments for this subscription.
            </p>
          )}

          {!needsPaymentMethod && (
            <Footer>
              <FormSubmitButton
                onClick={handleSubscribe}
                disabled={!defaultPaymentMethod || isProcessing}
                $isLoading={isProcessing}
              >
                Subscribe
              </FormSubmitButton>

              <FormCancelButton onClick={handleCancel}>Cancel</FormCancelButton>
            </Footer>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

CreateSubscriptionDialog.defaultProps = {
  plan: {},
};

CreateSubscriptionDialog.propTypes = {
  plan: PropTypes.object,
};

export default CreateSubscriptionDialog;
