import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { IconDot, NewsSentiment, Flexbox } from 'src/components';
import { upperCase } from 'src/formatters';
import { formatDateLong } from 'src/utils/date';

const metaCss = css`
  color: #ffffffb2;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.66;
`;

const FooterArticle = ({ article, onSelect, ...rest }) => {
  const theme = useTheme();

  return (
    <Flexbox
      column
      onClick={() => onSelect(article)}
      className="foobar"
      css={`
        cursor: pointer;
        display: flex;
        flex-direction: column;
        color: ${theme.palette.text.secondary};
      `}
      {...rest}
    >
      <Flexbox
        css={`
          display: flex;
          width: 100%;
        `}
      >
        <span
          css={`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #e1b72a;
            font-size: 16px;
          `}
        >
          {article.title}
        </span>
      </Flexbox>
      <Flexbox>
        <span css={metaCss}>{upperCase(formatDateLong(article.publishedAt))}</span>
        <IconDot />
        <span css={metaCss}>{upperCase(article.source)}</span>
        <NewsSentiment
          value={article.sentiment}
          labelVisible
          size="10px"
          css={`
            margin-left: 10px;
          `}
        />
      </Flexbox>
    </Flexbox>
  );
};

FooterArticle.defaultProps = { onSelect: () => {} };

FooterArticle.propTypes = {
  article: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};

export default FooterArticle;
