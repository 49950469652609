import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAllFunds, useFundsWithPeers, useFilteredFunds, useFundsTableColumnPresets } from 'src/hooks';
import BookmarkPopover from 'src/components/molecules/FundBookmarkControl/Popover';
import VirtualizedGridTable from '../VirtualizedGridTable';

const TABLE_SORTS = {
  name: { id: 'name', desc: false },
  vintage: {
    id: 'vintage',
    desc: true,
  },
  undefined: { id: 'name', desc: false },
};

function FundsTable({ vintages, strategies, sizes, regions, secondRegions, initialSortBy: initialSortByKey }) {
  const { columns, tableStateReducer, tableCss, bookmarkPopoverState } = useFundsTableColumnPresets();

  const fundsWithPeers = useFundsWithPeers({
    funds: useFilteredFunds({
      funds: useAllFunds({
        excludeLatestThreeYears: false,
        excludeNullTvpiIrr: false,
        excludeNullCalledPct: true,
        excludeZeroCalledPct: true,
      }),
      strategies,
      vintages,
      regions,
      secondRegions,
      sizes,
    }),
  });

  const data = useMemo(() => {
    if (!fundsWithPeers) return [];
    return fundsWithPeers;
  }, [fundsWithPeers]);

  const initialSortBy = useMemo(() => TABLE_SORTS[initialSortByKey], [initialSortByKey]);

  return (
    <>
      <VirtualizedGridTable
        columns={columns}
        data={data}
        filters={{ vintages }}
        stateReducer={tableStateReducer}
        initialSortBy={[initialSortBy]}
        css={tableCss}
      />
      <BookmarkPopover {...bookmarkPopoverState} />
    </>
  );
}

FundsTable.defaultProps = {
  vintages: null,
  strategies: null,
  sizes: null,
  regions: null,
  secondRegions: null,
};

FundsTable.propTypes = {
  vintages: PropTypes.array,
  strategies: PropTypes.array,
  sizes: PropTypes.array,
  regions: PropTypes.array,
  secondRegions: PropTypes.array,
  initialSortBy: PropTypes.oneOf(Object.keys(TABLE_SORTS)),
};

export default FundsTable;
