import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { useFundManagerNames } from 'src/hooks';
import { TextField as TextFieldBase, ErrorMessage, CheckboxLarge } from 'src/components';

const StyledDialogContent = styled(DialogContent)`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const MoreInfoControl = styled(FormControlLabel)`
  margin-left: -5px;
  color: #a4a4a4;

  .MuiTypography-root {
    font-size: 0.875em;
  }
`;

const TextField = styled(TextFieldBase)`
  width: ${props => props.width};
  margin-bottom: 10px;

  .MuiInputBase-input {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .MuiFormLabel-root {
    text-transform: uppercase;
    margin-top: -5px;

    &.Mui-focused {
      margin-top: unset;
    }

    &.MuiFormLabel-filled {
      margin-top: unset;
    }

    &.MuiInputLabel-shrink {
      margin-top: unset;
    }
  }

  .MuiInputAdornment-root {
    .MuiTypography-root {
      font-size: 11px;
      position: relative;
      top: 0px;
    }
  }
`;

function FundManagerAddDialog({ fundManagerName, open, onClose, onCreateFundManager }) {
  const [name, setName] = useState(fundManagerName);
  const [requestMoreInformation, setrequestMoreInformation] = useState();
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState();
  const fundManagerNames = useFundManagerNames();
  const [fundManagerNamesList, setFundManagerNamesList] = useState([]);

  useEffect(
    function makeFundManagerNamesList() {
      if (!fundManagerNames) return;
      if (!fundManagerNames.byId) return;
      const ids = Object.keys(fundManagerNames.byId);
      const namesList = ids.map(id => fundManagerNames.byId[id].name);
      setFundManagerNamesList(namesList);
    },
    [fundManagerNames]
  );

  useEffect(() => {
    setSubmitted(false);
  }, [open]);

  function validateFundManagerNameLength(value) {
    if (!value) return 'Fund manager name cannot be blank';
    if (value < 3) return 'Fund manager name is too short';

    return null;
  }

  function validateFundManagerNameUniqueness(value) {
    const uppercaseNames = fundManagerNamesList.map(n => n.toUpperCase());
    const isNotUnique = uppercaseNames.includes(value.toUpperCase());
    if (isNotUnique) return 'Fund manager name already exists';
    return null;
  }

  function handleTextChange(e) {
    const { value } = e.target;
    setName(value);
    setError(!validateFundManagerNameLength(value));
  }

  function handleAdd() {
    setSubmitted(true);
    let err;

    err = validateFundManagerNameLength(name);
    err = validateFundManagerNameUniqueness(name);

    if (err) return setError(err);
    onCreateFundManager(name, requestMoreInformation);
  }

  function handleRequestInfo(e) {
    setrequestMoreInformation(e.target.checked);
  }

  return (
    <div>
      <Dialog open={open} aria-labelledby="confirm-dialog-title">
        <DialogTitle id="confirm-dialog-title">Add Fund Manager</DialogTitle>
        <StyledDialogContent>
          <TextField
            size="small"
            label="Fund Manager Name"
            inputProps={{ maxLength: 30 }}
            error={error && submitted}
            onChange={handleTextChange}
          />
          <MoreInfoControl
            control={<CheckboxLarge onChange={handleRequestInfo} />}
            label="I would like more information on this manager"
          />
          {error && submitted && (
            <ErrorMessage
              css={`
                display: block;
              `}
            >
              {error}
            </ErrorMessage>
          )}
        </StyledDialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="default">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAdd} color="secondary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

FundManagerAddDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onCreateFundManager: () => {},
  fundManagerName: '',
};

FundManagerAddDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateFundManager: PropTypes.func,
  fundManagerName: PropTypes.string,
};

export default FundManagerAddDialog;
