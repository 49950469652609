import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { InputAdornment } from '@material-ui/core';
import { useTopLevelRegions, useSubStrategies, useStrategies } from 'src/hooks';
import { Select, NumberField } from 'src/components';
import { Section, SectionHeader, SectionRow, SectionTitle, TextField, ValidRowIcon } from '../Styled';
import FundRegionField from './FundRegionField';
import FundManagerField from './FundManagerField';

const disabledInputCss = css`
  .MuiInputBase-input,
  .MuiListItem-root,
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline,
    &.Mui-disabled {
      color: gray;
    }

    .MuiSelect-icon.Mui-disabled {
      display: none;
    }
  }
`;

const CURRENT_YEAR = new Date().getUTCFullYear();

const VINTAGES = Array(20)
  .fill(null)
  .map((_, index) => CURRENT_YEAR - index);

function getParentStrategyName(strategies, subStrategy) {
  if (!strategies || !subStrategy) return '';
  const parent = strategies.find(s => s.strategyId === subStrategy.parentId);
  return parent ? parent.strategyName : '';
}

function FieldsetFundDetails({ formState, updateForm, isEditable, ...rest }) {
  const [sectionIsValid, setSectionIsValid] = useState(false);
  const regions = useTopLevelRegions();
  const subStrategies = useSubStrategies();
  const strategies = useStrategies();

  const handleFieldChange = useCallback(
    function (field) {
      return function (event) {
        const { value } = event.target;
        updateForm({ field, value });
      };
    },
    [updateForm]
  );

  const handleRegionChange = useCallback(
    function (_event, { primary, secondary }) {
      if (!regions) return;

      updateForm({ field: 'regionId1', value: primary.regionId });
      updateForm({ field: 'regionName1', value: primary.regionName });
      updateForm({ field: 'regionId2', value: secondary.regionId });
      updateForm({ field: 'regionName2', value: secondary.regionName });
    },
    [updateForm, regions]
  );

  const handleFundManagerChange = useCallback(
    function (_event, { fundManagerId, fundManagerName }) {
      updateForm({ field: 'fundManagerId', value: fundManagerId });
      updateForm({ field: 'fundManagerName', value: fundManagerName });
    },
    [updateForm]
  );

  const handleStrategyChange = useCallback(
    function (event) {
      const { value: subStrategyId } = event.target;
      const subStrategy = subStrategies.find(s => s.strategyId === subStrategyId);
      const strategy = strategies.find(s => s.strategyId === subStrategy.parentId);

      updateForm({ field: 'strategy', value: strategy.strategyName });
      updateForm({ field: 'strategyId', value: strategy.strategyId });
      updateForm({
        field: 'subStrategy',
        value: subStrategy.strategyName,
      });
      updateForm({
        field: 'subStrategyId',
        value: subStrategy.strategyId,
      });
    },
    [updateForm, subStrategies, strategies]
  );

  useEffect(
    function () {
      const result = [
        formState.errors.fundManagerName,
        formState.errors.vintage,
        formState.errors.size,
        formState.errors.strategyId,
        formState.errors.regionId1,
      ].every(field => field === false);

      setSectionIsValid(result);
    },
    [
      formState.errors.fundManagerName,
      formState.errors.vintage,
      formState.errors.size,
      formState.errors.strategyId,
      formState.errors.regionId1,
    ]
  );

  useEffect(() => {
    /**
     * Convert a strategy to substrategy
     */
    if (!strategies) return;
    if (!formState.strategyId) return;

    const subStrategy = strategies.find(s => s.parentId === formState.strategyId);

    handleStrategyChange({
      target: { value: subStrategy?.strategyId },
    });

    updateForm({
      field: 'strategyId',
      value: subStrategy?.parentId,
    });
  }, [formState.strategyId, handleStrategyChange, strategies, updateForm]);

  if (!regions) return null;
  if (!subStrategies) return null;
  if (!strategies) return null;

  const formatStrategyLabel = strategy => `${getParentStrategyName(strategies, strategy)} - ${strategy.strategyName}`;

  return (
    <Section {...rest}>
      <SectionHeader gridColumns="auto 1fr">
        <SectionTitle data-step="2">Fund details</SectionTitle>
        {isEditable && <ValidRowIcon $valid={sectionIsValid} />}
      </SectionHeader>
      <br />
      <SectionRow grid="auto / 7fr 2fr 3fr">
        <FundManagerField
          value={formState.fundManagerName}
          onChange={handleFundManagerChange}
          css={!isEditable && disabledInputCss}
          disabled={!isEditable}
        />

        <Select
          preset="enhanced"
          label="Vintage"
          items={VINTAGES}
          value={formState.vintage || ''}
          onChange={handleFieldChange('vintage')}
          error={true}
          css={!isEditable && disabledInputCss}
          disabled={!isEditable}
        />

        <TextField
          required
          placeholder="200,000,000"
          label="Fund Size"
          value={formState.size || ''}
          onChange={handleFieldChange('size')}
          InputProps={{
            inputComponent: NumberField,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          css={!isEditable && disabledInputCss}
          disabled={!isEditable}
        />

        <Select
          preset="enhanced"
          label="Strategy & sub-strategy"
          items={subStrategies}
          onChange={handleStrategyChange}
          value={formState.subStrategyId || ''}
          renderItemKey={subStrategy => subStrategy.strategyId}
          renderItemValue={subStrategy => subStrategy.strategyId}
          renderItemLabel={formatStrategyLabel}
          renderValue={subStrategyId => {
            const item = subStrategies.find(s => s.strategyId === subStrategyId);
            return formatStrategyLabel(item);
          }}
          css={!isEditable && disabledInputCss}
          disabled={!isEditable}
        />

        <FundRegionField
          placeholder="Geography"
          label="Geography"
          categories={{ primary: regions, secondary: regions }}
          onChange={handleRegionChange}
          value={{
            primary: {
              regionId: formState.regionId1,
              regionName: formState.regionName1,
            },
            secondary: {
              regionId: formState.regionId2,
              regionName: formState.regionName2,
            },
          }}
          css={`
            grid-column: span 2;
            ${!isEditable && disabledInputCss}
          `}
          disabled={!isEditable}
        />
      </SectionRow>
    </Section>
  );
}

FieldsetFundDetails.defaultProps = {};

FieldsetFundDetails.propTypes = {
  children: PropTypes.any,
};

export default FieldsetFundDetails;
