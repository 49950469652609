import { useQuery } from 'react-query';
import { filterApi } from 'src/services';
import { useCurrentUser } from 'src/hooks';
import { keyBy, mapValues } from 'src/lib/lodash';

async function getFundsTimeseriesByFundId() {
  const data = await filterApi.getFundsTimeseries();
  return mapValues(keyBy(data, 'fundId'), 'series');
}

function useFundTimeseriesQuery(fundId, vintage = null) {
  const user = useCurrentUser();
  const queryFn = fundId
    ? async () => {
        const series = await filterApi.getFundTimeseries(fundId, user?.userId, vintage);
        return series;
      }
    : getFundsTimeseriesByFundId;

  return useQuery(['fundTimeseries', fundId, vintage], queryFn, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 1000 * 60 * 60,
    enabled: Boolean(fundId),
  });
}

export default useFundTimeseriesQuery;
