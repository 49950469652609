import React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import { Box } from 'src/components';
import draw from './draw';
import { BLUE } from 'src/config/colors';

const Container = styled(Box)`
  position: relative;
  height: ${props => props.height || '100%'};

  .x-axis .tick {
    text {
      fill: #808080;
      font-size: 10px;
      transform: translateY(3px);
    }

    line {
      color: ${BLUE};
    }
  }

  .quartile-label,
  .x-axis .label,
  .y-axis .label {
    fill: #808080;
    text-align: center;
    text-anchor: middle;
    font-size: 12px;
    shape-rendering: crispEdges;
  }
  .quartile-label {
    fill: ${BLUE};
    text-transform: uppercase;
  }

  .y-axis .tick line {
    opacity: 0.25;
  }

  .domain {
    opacity: 0;
  }
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

const Message = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FundSummaryScatter = ({
  data,
  fundId,
  metric,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  className,
  targetRef,
}) => {
  const id = useRef(uniqueId());

  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  const subjectFunds = data.filter(d => d.fundId === fundId);
  const isTooNew = subjectFunds.some(f => f.vintage >= new Date().getUTCFullYear() - 3);
  const isIrr = metric.key === 'irr';
  const isIrrelevant = isTooNew && isIrr;

  useEffect(() => {
    draw({
      id: id.current,
      data,
      fundId,
      metric,
      width,
      height,
      margin: { top: 25, right: 30, bottom: 40, left: 70 },
      mouseEventRefs,
    });
  }, [data, fundId, metric, width, height, mouseEventRefs]);

  if (isIrrelevant) {
    return (
      <Message>
        IRR values for the first three years of a fund are not meaningful and therefore not considered useful for
        benchmarking
      </Message>
    );
  }

  return (
    <Container ref={targetRef} className={className} expanded={null}>
      <Svg id={id.current} width={width} height={height} />
    </Container>
  );
};

FundSummaryScatter.defaultProps = {
  expanded: false,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

FundSummaryScatter.propTypes = {
  data: PropTypes.array.isRequired,
  fundId: PropTypes.string.isRequired,
  metric: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(React.forwardRef((props, ref) => <FundSummaryScatter {...props} targetRef={ref} />));
