import React from 'react';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow } from '@material-ui/core';
import { TooltipArrowLeft, TooltipContent, TooltipCloseIcon, TooltipHeader } from 'src/components';

const bulletCss = css`
  margin: 8px 0;
  padding: 0;
`;

const termCss = css`
  font-weight: 500;
  margin-right: 10px;
`;

const highlightedCss = css`
  font-weight: 500;
  font-style: italic;
`;

const noteCss = css`
  font-style: italic;
`;

function FilterControlsTooltip({ open, anchorEl, onClose }) {
  return (
    <Popper
      style={{
        zIndex: 100,
        maxWidth: 600,
      }}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="right"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0,15',
        },
        arrow: {
          enabled: true,
          element: '.filters-info-arrow',
        },
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          <div
            css={`
              overflow: auto;
            `}
          >
            <TooltipArrowLeft className="filters-info-arrow" />
            <TooltipContent padding="20px" color="#000" maxWidth="400px" fontSize="12px">
              <TooltipHeader>
                <h3
                  css={`
                    margin: 5px 0;
                    padding: 0;
                  `}
                >
                  Filter Criteria
                </h3>
                <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
              </TooltipHeader>
              <div>
                <p css={bulletCss}>
                  <span css={termCss}>SYSTEM DEFAULT:</span> The system default is the broadest criteria of
                  benchmarking. It benchmarks funds to other funds of the same{' '}
                  <span css={highlightedCss}>Core Strategy</span> and
                  <span css={highlightedCss}>Vintage</span>. As in all vintage selections, the system automatically
                  includes pre and post vintages (see Vintages below for detail). All regions and all fund sizes are
                  automatically included.
                </p>
                <p css={bulletCss}>
                  <span css={termCss}>STRATEGY:</span> Consists of 6 <span css={highlightedCss}>Core Strategies</span>,
                  each of which have their own <span css={highlightedCss}>Sub-strategies</span> for a total of 16.
                </p>
                <p css={bulletCss}>
                  <span css={termCss}>VINTAGE:</span> <span css={highlightedCss}>Vintage</span> is the year when a fund
                  first draws capital, although there can be exceptions. <span css={highlightedCss}>Vintage</span>{' '}
                  includes the vintage immediately before and after to reflect the high overlap of capital deployment
                  between the three vintages.
                </p>
                <p css={bulletCss}>
                  <span css={termCss}>REGION:</span> Regions are broken into six core geographical areas. Many funds
                  invest in more than these areas and so may have a <span css={highlightedCss}>Region I</span> and{' '}
                  <span css={highlightedCss}>Region II</span> attribute, where{' '}
                  <span css={highlightedCss}>Region I</span> is the core market the fund invests in, and{' '}
                  <span css={highlightedCss}>Region II</span> is its secondary market.{' '}
                  <span css={noteCss}>Note: the system default includes all regions for benchmarking purposes.</span>
                </p>
                <p css={bulletCss}>
                  <span css={termCss}>SIZE:</span> <span css={highlightedCss}>Size</span> reflects the size of the fund
                  in terms of commitments at final close. These are grouped into four categories.
                  <span css={noteCss}>Note: the system default includes all sizes for benchmarking purposes.</span>
                </p>
              </div>
            </TooltipContent>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

FilterControlsTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};

FilterControlsTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default FilterControlsTooltip;
