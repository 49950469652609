import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconRadarChart(props) {
  return (
    <Svg viewBox="0 0 50 62.5" {...props}>
      <path d="M39.85,47.71h-29.7L0.98,19.47L25,2.01l24.02,17.46L39.85,47.71z M12.33,44.71h25.34l7.83-24.1L25,5.72L4.5,20.61   L12.33,44.71z" />
    </Svg>
  );
}
