function DocumentNotFoundError(doc) {
  this.message = `Document not found: ${doc?.ref?.path}`;
  this.code = 'NOT_FOUND';
}

DocumentNotFoundError.prototype = new Error();

function UnauthorizedError() {
  this.message = 'You must sign in to continue';
  this.code = 'UNAUTHORIZED';
}

UnauthorizedError.prototype = new Error();

function ViolatesPresenceError(args = {}) {
  const keys = Object.keys(args).join(', ');
  const entries = JSON.stringify(args);

  this.message = `Document values for [${keys}] must be present, but instead found: ${entries}`;
  this.code = 'NOT_A_PRESENT';
}

ViolatesPresenceError.prototype = new Error();

function ViolatesNumericalError(fields = {}) {
  const keys = Object.keys(fields).join(', ');
  const values = Object.values(fields)
    .map(val => typeof val)
    .join(', ');

  this.message = `Document values for [${keys}] must be numbers, but instead found: [${values}]`;
  this.code = 'NOT_A_NUMBER';
}

ViolatesNumericalError.prototype = new Error();

export { DocumentNotFoundError, UnauthorizedError, ViolatesPresenceError, ViolatesNumericalError };
