/* eslint-disable no-param-reassign */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchFundManagerFundsInMarketSizePending = createAction('FETCH_FUND_MANAGER_FUNDS_IN_MARKET_SIZE_PENDING');
const fetchFundManagerFundsInMarketSizeError = createAction('FETCH_FUND_MANAGER_FUNDS_IN_MARKET_SIZE_ERROR');
const fetchFundManagerFundsInMarketSizeSuccess = createAction('FETCH_FUND_MANAGER_FUNDS_IN_MARKET_SIZE_SUCCESS');

export function fetchFundManagerFundsInMarketSize({ fundManagerId }) {
  return async function (dispatch) {
    dispatch(fetchFundManagerFundsInMarketSizePending());
    try {
      const fundManagerFundsInMarketSize = await filterApi.getFundManagerFundsInMarketSize({ fundManagerId });
      dispatch(fetchFundManagerFundsInMarketSizeSuccess({ fundManagerFundsInMarketSize, fundManagerId }));
    } catch (error) {
      dispatch(fetchFundManagerFundsInMarketSizeError({ error: error.message }));
    }
  };
}

const initialState = {
  fetching: false,
  fetched: false,
  fundManagerId: null,
  error: null,
  all: null,
};

export default createReducer(initialState, {
  [fetchFundManagerFundsInMarketSizePending]: state => {
    state.fetching = true;
  },
  [fetchFundManagerFundsInMarketSizeError]: (state, action) => {
    const { error } = action.payload;
    state.error = error;
    state.fetching = false;
    console.error(error);
  },
  [fetchFundManagerFundsInMarketSizeSuccess]: (state, action) => {
    const { fundManagerFundsInMarketSize, fundManagerId } = action.payload;
    state.error = null;
    state.fetching = false;
    state.fetched = true;
    state.fundManagerId = fundManagerId;
    state.all = fundManagerFundsInMarketSize;
  },
});
