import { select, extent, axisLeft, scaleLinear, easeCubicInOut } from 'd3';
import { lookup } from 'src/lookup';
import { indexName } from 'src/formatters';
import { FUND_PME_ERRORS } from '@fundfilter/core/src/constants';

const TRANSITION_DURATION = 500;
const ABOVE_MEDIAN_COLOR = '#00cbffaa';
const BELOW_MEDIAN_COLOR = '#ff5c00aa';
const QUARTILE_RANKING_COLOR = '#ffffff';
const Y_AXIS_PAD = 20;
const errorMessages = {
  [FUND_PME_ERRORS.fund.incompleteHistory]: 'Insufficient Cash Flow History',
  [FUND_PME_ERRORS.fund.insufficientHistory]: 'Insufficient Cash Flow History - Too Young',
  [FUND_PME_ERRORS.benchmark.incompleteHistory]: 'Insufficient Benchmark History',
};

function handleEvent(ref) {
  return function (_, d) {
    ref.current(this, d);
  };
}

function getFundValue(index, calcs) {
  return function (fund) {
    return fund?.pmeValues?.find(p => p.indexName === index)?.[calcs];
  };
}

function getFundError(index) {
  return function (fund) {
    return fund?.pmeValues?.find(p => p.indexName === index)?.error;
  };
}

function getRectPoints({ x = 0, y = 0, width = 48, height = 22 }) {
  return [
    [x - width / 2, y - height / 2],
    [x - width / 2 + width, y - height / 2],
    [x - width / 2 + width, y - height / 2 + height],
    [x - width / 2, y - height / 2 + height],
    [x - width / 2 - 10, y - height / 2 + height / 2],
  ]
    .map(loc => loc.join(','))
    .join(' ');
}

function getRectColor(calcs) {
  return function ({ value }) {
    switch (calcs.key) {
      case 'ksPmeValue':
        return value < 1 ? BELOW_MEDIAN_COLOR : ABOVE_MEDIAN_COLOR;
      case 'daPmeValue':
        return value < 0 ? BELOW_MEDIAN_COLOR : ABOVE_MEDIAN_COLOR;
      default:
        return ABOVE_MEDIAN_COLOR;
    }
  };
}

function isInsufficient({ value, insufficientData }) {
  return insufficientData || typeof value !== 'number';
}

function isError({ error }) {
  return Boolean(error);
}

function getRectLabelValue(calcs) {
  return function ({ value }) {
    if (typeof value !== 'number') return null;

    switch (calcs.key) {
      case 'ksPmeValue':
        return `${value.toFixed(2)}x`;
      case 'daPmeValue':
        return `${(value * 100).toFixed(2)}%`;
      default:
        return value.toFixed(2);
    }
  };
}

function drawFundPMEIndexChart({ id, fund, peers, calcs, size, margin, mouseEventRefs }) {
  if (!size.width || !size.height) return;

  const funds = peers[fund.fundId] ?? [];

  const pmeIndexes = fund?.pmeValues?.map(v => v.indexName) ?? [];
  const pmeIndexCount = pmeIndexes.length;
  const pmeIndexValues = pmeIndexes.map((index, i) => ({
    index,
    value: getFundValue(index, calcs.key)(fund),
    error: getFundError(index)(fund),
    insufficientData: fund?.pmeValues[i]?.insufficientData,
  }));

  const { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd } = mouseEventRefs;

  const root = select(`#${id}`)
    .selectAll('.root')
    .data([funds])
    .join('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;

  const offset = calcs.key === 'daPmeValue' ? 0.01 : 0;

  const yExtent = extent(pmeIndexValues, v => v.value);

  const xScale = scaleLinear().domain([0, pmeIndexCount]).range([20, svgWidth]);
  const yScale = scaleLinear()
    .domain([yExtent[0] - offset, yExtent[1] + offset])
    .range([svgHeight - 40, Y_AXIS_PAD + 40]);

  const yAxis = axisLeft(yScale)
    .ticks(6)
    .tickSize(-svgWidth)
    .tickFormat(d => {
      return calcs.key === 'daPmeValue' ? (d * 100).toFixed(2) : d.toFixed(2);
    });

  const indexWidth = xScale(1) - xScale(0) - 26;

  const t = root.transition().duration(TRANSITION_DURATION).ease(easeCubicInOut);

  root
    .selectAll('.y-axis')
    .data([yScale])
    .join('g')
    .attr('class', 'y-axis')
    .style('shape-rendering', 'crispEdges')
    .transition(t)
    .call(yAxis)
    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', '#808080'))
    .call(g => g.selectAll('.domain').attr('opacity', '0'));

  root
    .selectAll('.y-axis-label')
    .data([lookup.financialMetric.pme.labelChart])
    .join('text')
    .attr('class', 'y-axis-label')
    .attr('transform', 'rotate(-90)')
    .attr('dy', '1em')
    .style('fill', '#808080')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .style('font-family', 'Roboto')
    .attr('y', 0 - margin.left)
    .attr('x', 0 - svgHeight / 2)
    .transition(t)
    .text(lookup.financialMetric.pme.labelChart);

  root
    .selectAll('.title')
    .data([0])
    .join('text')
    .attr('class', 'title')
    .style('fill', '#808080')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '12px')
    .style('font-family', 'Roboto')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('y', 5)
    .attr('x', svgWidth / 2)
    .transition(t)
    .text("Fund's Relative Performance to");

  root
    .selectAll('.index-background')
    .data(pmeIndexes)
    .join(
      enter =>
        enter
          .append('rect')
          .attr('class', 'index-background')
          .attr('x', (_, i) => xScale(i))
          .attr('y', 20)
          .attr('rx', 10)
          .attr('width', indexWidth)
          .attr('opacity', 0)
          .style('fill', 'rgba(255,255,255,0.03)')
          .text(indexName)
          .call(e =>
            e
              .transition(t)
              .attr('opacity', 1)
              .attr('x', (_, i) => xScale(i))
              .attr('width', indexWidth)
              .attr('height', svgHeight - 30)
          ),
      update =>
        update.call(u =>
          u
            .transition(t)
            .attr('opacity', 1)
            .attr('x', (_, i) => xScale(i))
            .attr('width', indexWidth)
            .attr('height', svgHeight - 30)
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('height', 0).remove())
    );

  root
    .selectAll('.index-name')
    .data(pmeIndexes)
    .join(
      enter =>
        enter
          .append('text')
          .attr('class', 'index-name')
          .attr('x', (_, i) => xScale(i))
          .attr('y', 36)
          .attr('dx', indexWidth / 2)
          .attr('opacity', 0)
          .style('fill', '#ffffff')
          .attr('font-size', '12px')
          .text(indexName)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .call(e =>
            e
              .transition(t)
              .text(indexName)
              .attr('opacity', 1)
              .attr('dx', indexWidth / 2)
              .attr('x', (_, i) => xScale(i))
              .attr('y', 36)
          ),
      update =>
        update.call(u =>
          u
            .transition(t)
            .text(indexName)
            .attr('opacity', 1)
            .attr('dx', indexWidth / 2)
            .attr('x', (_, i) => xScale(i))
            .attr('y', 36)
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).remove())
    );

  root
    .selectAll('.rect')
    .data(pmeIndexValues)
    .join(
      enter =>
        enter
          .append('polygon')
          .attr('class', 'rect')
          .attr('points', (d, i) => {
            const x = xScale(i) + indexWidth / 2;
            const y = yScale(d.value);
            return getRectPoints({ x, y });
          })
          .attr('opacity', 0)
          .attr('cursor', 'pointer')
          .attr('fill', getRectColor(calcs))
          .on('mouseover', handleEvent(itemOver))
          .on('mouseout', handleEvent(itemOut))
          .on('click', handleEvent(itemClick))
          .on('touchstart', handleEvent(itemTouchStart))
          .on('touchend', handleEvent(itemTouchEnd))
          .call(e =>
            e
              .transition(t)
              .attr('opacity', d => (isError(d) ? 0 : 1))
              .attr('points', (d, i) => {
                const x = xScale(i) + indexWidth / 2;
                const y = yScale(d.value);
                return getRectPoints({ x, y });
              })
          ),
      update =>
        update.call(u =>
          u
            .on('mouseover', handleEvent(itemOver))
            .on('mouseout', handleEvent(itemOut))
            .on('click', handleEvent(itemClick))
            .on('touchstart', handleEvent(itemTouchStart))
            .on('touchend', handleEvent(itemTouchEnd))
            .transition(t)
            .attr('fill', getRectColor(calcs))
            .attr('opacity', d => (isInsufficient(d) ? 0 : 1))
            .attr('points', (d, i) => {
              const x = xScale(i) + indexWidth / 2;
              const y = yScale(d.value);
              return getRectPoints({ x, y });
            })
        ),
      exit =>
        exit.call(ex =>
          ex
            .transition(t)
            .attr('opacity', 0)
            .attr('points', (d, i) => {
              const x = xScale(i) + indexWidth / 2;
              const y = yScale(d.value);
              return getRectPoints({ x, y });
            })
            .remove()
        )
    );

  root
    .selectAll('.index-value')
    .data(pmeIndexValues)
    .join(
      enter =>
        enter
          .append('text')
          .attr('class', 'index-value')
          .attr('dx', (_, i) => xScale(i) + indexWidth / 2)
          .attr('dy', 0)
          .attr('opacity', 0)
          .attr('font-size', '12px')
          .attr('fill', QUARTILE_RANKING_COLOR)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('pointer-events', 'none')
          .text(getRectLabelValue(calcs))
          .call(e =>
            e
              .transition(t)
              .text(getRectLabelValue(calcs))
              .attr('opacity', d => (isInsufficient(d) ? 0 : 1))
              .attr('dx', (_, i) => xScale(i) + indexWidth / 2)
              .attr('dy', d => yScale(d.value) ?? 0)
          ),
      update =>
        update.call(u =>
          u
            .transition(t)
            .text(getRectLabelValue(calcs))
            .attr('opacity', d => (isInsufficient(d) ? 0 : 1))
            .attr('dx', (_, i) => xScale(i) + indexWidth / 2)
            .attr('dy', d => yScale(d.value) ?? 0)
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('dy', 0).remove())
    );

  root
    .selectAll('.is-error')
    .data(pmeIndexValues)
    .join(
      enter =>
        enter
          .append('foreignObject')
          .attr('class', 'is-error')
          .attr('x', (_, i) => xScale(i) + indexWidth / 2 - 70)
          .attr('y', 0)
          .attr('width', 140)
          .attr('height', 70)
          .style('font', "14px 'Helvetica Neue'")
          .style('background-color', 'transparent')
          .style('border-radius', '6px')
          .html(d => {
            return `<p style="padding-inline: 1em;">${errorMessages[d.error]}</p>`;
          })
          .call(e =>
            e
              .transition(t)
              .attr('opacity', d => (isInsufficient(d) ? 1 : 0))
              .attr('x', (_, i) => xScale(i) + indexWidth / 2 - 70)
              .attr('y', svgHeight / 2 - 15)
          ),
      update =>
        update.call(u =>
          u
            .transition(t)
            .attr('opacity', d => (isInsufficient(d) ? 1 : 0))
            .attr('x', (_, i) => xScale(i) + indexWidth / 2 - 70)
            .attr('y', svgHeight / 2 - 15)
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('y', 0).remove())
    );
}

export default drawFundPMEIndexChart;
