import React from 'react';
import PropTypes from 'prop-types';
import { ButtonExpandIcon, ButtonShrinkIcon } from '../..';

export default function ButtonExpandToggle({ expanded, onClick, ...rest }) {
  return expanded ? (
    <ButtonShrinkIcon title="Shrink" onClick={onClick} {...rest} />
  ) : (
    <ButtonExpandIcon title="Expand" onClick={onClick} {...rest} />
  );
}

ButtonExpandToggle.defaultProps = {
  expanded: false,
  onClick: () => {},
};

ButtonExpandToggle.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
};
