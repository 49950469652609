const BLUE = '#009cc3';
const LIGHT_BLUE = '#00abd4';
const DARK_BLUE = '#00659a';
const ORANGE = '#ee5701';
const MAGENTA = '#AD48F8';
const PURPLE = '#6601cc';

const LINE_WIDTH = '0.25em';

const SUBJECT_LINE_COLOR = LIGHT_BLUE;
const FORECAST_LINE_COLOR = MAGENTA;
const SUBJECT_DISTRIBUTION_BAR_COLOR = LIGHT_BLUE;
const SUBJECT_CONTRIBUTION_BAR_COLOR = DARK_BLUE;
const FORECAST_DISTRIBUTION_BAR_COLOR = MAGENTA;
const FORECAST_CONTRIBUTION_BAR_COLOR = PURPLE;
const NAV_LABEL_COLOR = ORANGE;
const MILESTONE_ACCENT_COLOR = LIGHT_BLUE;
const FAILED_BREAKEVEN_SUBJECT_LINE_COLOR = LIGHT_BLUE;
const FAILED_BREAKEVEN_FORECAST_LINE_COLOR = MAGENTA;
const FAILED_BREAKEVEN_ACCENT_COLOR = ORANGE;

export {
  BLUE,
  LIGHT_BLUE,
  ORANGE,
  SUBJECT_LINE_COLOR,
  FORECAST_LINE_COLOR,
  SUBJECT_DISTRIBUTION_BAR_COLOR,
  SUBJECT_CONTRIBUTION_BAR_COLOR,
  FORECAST_DISTRIBUTION_BAR_COLOR,
  FORECAST_CONTRIBUTION_BAR_COLOR,
  NAV_LABEL_COLOR,
  LINE_WIDTH,
  MILESTONE_ACCENT_COLOR,
  FAILED_BREAKEVEN_SUBJECT_LINE_COLOR,
  FAILED_BREAKEVEN_FORECAST_LINE_COLOR,
  FAILED_BREAKEVEN_ACCENT_COLOR,
};
