import React from 'react';
import { useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { sort } from '@fundfilter/core/src/utils/array';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import { sm } from 'src/config/layout';
import draw from './draw';

const SORT_KEYS = {
  NUMERIC_DESC: 'sort:numeric:desc',
  NUMERIC_ASC: 'sort:numeric:asc',
  ALPHA_DESC: 'sort:alpha:desc',
  ALPHA_ASC: 'sort:alpha:asc',
};

const Container = styled.div`
  height: ${props => props.height};
  padding: ${props => props.padding};
  position: relative;
`;

const VisualizationRoot = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.expanded ? 'repeat(10, minmax(0, 1fr))' : 'repeat(4, minmax(0, 1fr))')};
  grid-auto-rows: 110px;
  padding-right: 5px;
  gap: 4px;

  .box {
    position: relative;
    cursor: pointer;
    padding: 4px 8px;
    color: #fff;
    width: 100%;
    height: 110px;

    &:hover {
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25), 0 0 7px rgba(47, 47, 47, 1);
    }
  }
  .content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.3;
  }

  .name {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }

  .value {
    font-size: 14px;
    font-weight: 500;
  }

  .user-fund-indicator {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: #ffd857;
  }

  .box.-valid {
    .value::after {
      content: ' ${p => p.$metricLabel}';
      text-transform: uppercase;
      font-size: 0.75em;
    }
  }

  .box.-invalid {
    .text {
      opacity: 0.5;
    }

    .value {
      font-size: 12px;
      font-weight: 300;
    }
  }

  @media (min-width: ${sm.BREAKPOINT}px) {
    grid-template-columns: ${props => (props.expanded ? 'repeat(10, minmax(0, 1fr))' : 'repeat(5, minmax(0, 1fr))')};
  }
`;

function BookmarkedFundsGrid({
  funds,
  fundsHistory,
  metric,
  timeRange,
  expanded,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  customSort,
  ...rest
}) {
  const id = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  const containerRef = useRef(null);

  const customSortFn = useCallback(
    function (a, b) {
      const nameB = b.name.toUpperCase();
      const nameA = a.name.toUpperCase();

      if (customSort === SORT_KEYS.ALPHA_ASC) return nameA.localeCompare(nameB);
      if (customSort === SORT_KEYS.ALPHA_DESC) return nameB.localeCompare(nameA);

      if (customSort === SORT_KEYS.NUMERIC_ASC) {
        const deltaA = Number.isFinite(a.delta) ? a.delta : Number.POSITIVE_INFINITY;
        const deltaB = Number.isFinite(b.delta) ? b.delta : Number.POSITIVE_INFINITY;
        return deltaA - deltaB;
      }

      if (customSort === SORT_KEYS.NUMERIC_DESC) {
        const deltaA = Number.isFinite(a.delta) ? a.delta : Number.NEGATIVE_INFINITY;
        const deltaB = Number.isFinite(b.delta) ? b.delta : Number.NEGATIVE_INFINITY;
        return deltaB - deltaA;
      }

      return 1;
    },
    [customSort]
  );

  const seriesByFundId = useMemo(() => {
    return funds.reduce((acc, fund) => {
      const history = fundsHistory.find(item => item.fundId === fund.fundId);

      return { ...acc, [fund.fundId]: history?.items || [] };
    }, {});
  }, [funds, fundsHistory]);

  const data = useMemo(() => {
    return funds.map(fund => {
      const series = sort((a, b) => a.date.localeCompare(b.date))(seriesByFundId[fund.fundId]);

      const currentIndex = series.length - 1;
      const currentPeriod = series[currentIndex];
      const previousIndex = currentIndex - timeRange.value / 3;
      const previousPeriod = series[previousIndex];
      const delta = currentPeriod?.[metric.key] - previousPeriod?.[metric.key];

      return { ...fund, series, delta };
    });
  }, [funds, metric.key, seriesByFundId, timeRange]);

  useEffect(() => {
    if (!funds) return;
    if (!fundsHistory) return;

    draw({
      id: id.current,
      data: sort(customSortFn)(data),
      mouseEventRefs,
    });
  }, [customSortFn, data, funds, fundsHistory, mouseEventRefs]);

  return (
    <Container ref={containerRef} {...rest}>
      <VisualizationRoot expanded={expanded} id={id.current} $metricLabel={metric.key} />
    </Container>
  );
}

BookmarkedFundsGrid.defaultProps = {
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

BookmarkedFundsGrid.propTypes = {
  funds: PropTypes.array.isRequired,
  metric: PropTypes.object.isRequired,
  timeRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

BookmarkedFundsGrid.constants = { SORT_KEYS };

export default BookmarkedFundsGrid;
