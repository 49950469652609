import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import Chart from './Chart';
import Tooltip from './Tooltip';

function FundSummaryScatter({
  data,
  fundId,
  metric,
  onItemOver,
  onItemOut,
  onItemClick: _onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
  targetRef,
}) {
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipTargetEl, setTooltipTargetEl] = useState(null);

  const onItemClick = useCallback(function (target, d) {
    setTooltipTargetEl(target);
    setTooltipData(d);
  }, []);

  useEffect(() => {
    setTooltipData(null);
  }, [fundId]);

  return (
    <>
      <Chart
        ref={targetRef}
        data={data}
        fundId={fundId}
        metric={metric}
        onItemOver={onItemOver}
        onItemOut={onItemOut}
        onItemClick={onItemClick}
        onItemTouchStart={onItemTouchStart}
        onItemTouchEnd={onItemTouchEnd}
        width={width}
        height={height}
      />
      <Tooltip
        fund={tooltipData}
        onClose={() => setTooltipData(null)}
        parentEl={targetRef.current}
        targetEl={tooltipTargetEl}
      />
    </>
  );
}

FundSummaryScatter.defaultProps = {
  expanded: false,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
  width: 0,
  height: 0,
};

FundSummaryScatter.propTypes = {
  data: PropTypes.array.isRequired,
  fundId: PropTypes.string.isRequired,
  metric: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default withResizeDetector(FundSummaryScatter);
