import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDebounce } from 'src/hooks';
import { lg } from 'src/config/layout';
import { ToggleButtons, CashflowDataVisualization } from 'src/components';
import usePortfolioForecastData from './usePortfolioForecastData';
import ForecastItems from './ForecastItems';
import usePortfolioItems from './usePortfolioItems';
import { useGet as useGetPortfolio } from 'src/services/filter-api/userPortfolios/queries';
import { useUpdate as useUpdatePortfolio } from 'src/services/filter-api/userPortfolios/mutations';
import ChartGridSection from './ChartGridSection';

const { VIEWS } = CashflowDataVisualization.CONSTANTS;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 20px;
  height: 100%;

  @media (min-width: ${lg.BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
  }
`;

const ForecastItemsSection = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

function Portfolio({ setPanelControls, portfolioId, onChangePortfolio, onClickAddFund }) {
  const [view, setView] = useState(VIEWS.CHART);
  const getPortfolio = useGetPortfolio(portfolioId);
  const [portfolioItems, { isFetching }] = usePortfolioItems({
    portfolioId,
  });

  const { data: portfolioForecastData, isFetching: isFetchingForecasts } = usePortfolioForecastData(portfolioItems);

  useEffect(() => {
    setPanelControls(
      <div
        css={`
          flex: 1;
          display: flex;
          justify-content: flex-end;
          padding-right: 18px;
        `}
      >
        <ToggleButtons
          sizeVariant="small"
          value={view}
          options={[VIEWS.CHART, VIEWS.TABLE]}
          onChange={event => setView(event.target.value)}
        />
      </div>
    );
  }, [setPanelControls, view]);

  const updatePortfolio = useUpdatePortfolio().mutate;

  const handleChangeNavTarget = useDebounce(value => {
    if (!portfolioId) return;
    updatePortfolio({
      portfolioId: portfolioId,
      navTarget: value,
    });
  }, 1000);

  return (
    <Layout>
      <ForecastItemsSection>
        <ForecastItems
          view={view}
          isFetchingItems={isFetching}
          isFetchingForecasts={isFetchingForecasts}
          items={portfolioItems}
          portfolioId={portfolioId}
          onChangePortfolio={onChangePortfolio}
          onChangeNavTarget={handleChangeNavTarget}
          onClickAddFund={onClickAddFund}
        />
      </ForecastItemsSection>

      <ChartGridSection
        isFetching={isFetchingForecasts}
        portfolioForecastData={portfolioForecastData}
        view={view}
        navTarget={getPortfolio?.data?.navTarget}
        portfolioItems={portfolioItems}
      />
    </Layout>
  );
}

Portfolio.defaultProps = {
  funds: [],
  selectedTags: [],
  setSelectedTags: () => {},
};

Portfolio.propTypes = {
  funds: PropTypes.array,
  selectedTags: PropTypes.array,
  setSelectedTags: PropTypes.func,
  portfolioId: PropTypes.string,
  onChangePortfolio: PropTypes.func,
};

export default Portfolio;
