import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconPrint(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M76.99976,26.1344V9.50244a2.0001,2.0001,0,0,0-2-2h-50a2.0001,2.0001,0,0,0-2,2v16.632c1.26123-.21368,2.59912-.40662,4-.58264V11.50244h46V25.55176C74.40063,25.72778,75.7384,25.92072,76.99976,26.1344Z" />
      <path d="M49.99976,35.86414c17.68408,0,28.17871-1.56739,34.40722-3.80835C77.84436,29.85413,67.21924,28.36414,50,28.36414c-17.21948,0-27.84473,1.49005-34.40723,3.69171C21.82117,34.29681,32.3158,35.86414,49.99976,35.86414Z" />
      <path d="M88.39258,33.71448c-6.46167,3.11822-17.97315,5.14959-38.39282,5.14959-20.41944,0-31.93079-2.03131-38.39258-5.14947C5.00317,37.15186,5,41.59607,5,45.014V72.079a3.285,3.285,0,0,0,3.285,3.285h8.7273l4.23285-20.54071a.5962.5962,0,0,1,.58392-.47589H78.17676a.5962.5962,0,0,1,.58392.47583l4.23346,20.54077H91.715A3.285,3.285,0,0,0,95,72.079V45.014C95,41.596,94.99683,37.15179,88.39258,33.71448ZM83,47.3974a3.23752,3.23752,0,1,1,3.23755-3.23749A3.23749,3.23749,0,0,1,83,47.3974Z" />
      <path d="M75.50629,58.84778a.6268.6268,0,0,0-.61389-.50031H25.114a.6268.6268,0,0,0-.61389.50031L17.97314,90.51752a1.485,1.485,0,0,0,.42994,1.38,2.19563,2.19563,0,0,0,1.54.6H80.06323a2.19564,2.19564,0,0,0,1.54-.6,1.485,1.485,0,0,0,.42993-1.38ZM22.32324,89.10748l5.63989-27.37H72.04321l5.6399,27.37Z" />
    </Svg>
  );
}
