import { sum } from 'src/utils/math';

function stackValues(arr) {
  const columns = [...new Set(arr.map(d => d.x))];
  const result = columns.flatMap(column => {
    const group = arr.filter(item => item.x === column);

    return group.map((item, index) => {
      const prev = group.slice(0, index);
      const yStart = sum(prev, { key: 'y' });
      const yEnd = item.y + yStart;

      return { ...item, yStart, yEnd };
    });
  });

  return result;
}

export default stackValues;
