import React from 'react';
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFundsTableColumnPresets, useFundPeerGroupFiltersMap } from 'src/hooks';
import { TreeviewCheckbox, VirtualizedGridTable, UserFundBenchmarkTableIndicator } from 'src/components';
import BookmarkPopover from 'src/components/molecules/FundBookmarkControl/Popover';
import FundNameCell from './FundNameCell';

const UNSELECTED_MESSAGE =
  'is deactivated as it currently is not part of the selected peer group. To include this fund for comparison you can adjust the peer group filters using the blue "Filters" button above.';
const NO_METRIC_DATA_MESSAGE =
  'is deactivated as it does not contain data for the selected metric. You can choose to compare with a different metric using the "TVPI" and "IRR" buttons above.';
const OUTLIER_MESSAGE =
  'is deactivated as it is considered a statistical outlier. You can choose to include outlier funds using the "Show Outliers" checkbox above.';

function assignDisabledStatus(metric, showOutliers = false) {
  return function (fund) {
    const disabledUnmetFilter = !fund.meetsFilterCriteria;
    const disabledNodata = !Number.isFinite(fund[metric]);
    const disabledOutlier = !showOutliers && fund[`${metric}Outlier`];
    const disabled = disabledUnmetFilter || disabledNodata || disabledOutlier;
    const className = disabled ? 'disabled' : '';
    const disabledReason = disabledUnmetFilter
      ? UNSELECTED_MESSAGE
      : disabledNodata
      ? NO_METRIC_DATA_MESSAGE
      : disabledOutlier
      ? OUTLIER_MESSAGE
      : '';

    return { ...fund, disabled, disabledReason, className };
  };
}

function CompareToPeersTable({ funds, narrowTable, onClickCompareCheckbox, metric, showOutliers }) {
  const { columns: columnPresets, tableStateReducer, tableCss, bookmarkPopoverState } = useFundsTableColumnPresets();
  const fundPeerGroupFilters = useFundPeerGroupFiltersMap();

  const columns = useMemo(
    () => [
      {
        Header: 'Compare',
        id: 'compare',
        accessor: row => row,
        Cell: ({ value }) => (
          <TreeviewCheckbox
            css={`
              &.MuiCheckbox-root {
                color: currentColor;
              }
            `}
            checked={!!value.color}
            onClick={onClickCompareCheckbox(value)}
          />
        ),
        width: '4.5em',
        minDisplayWidth: 0,
      },
      {
        Header: '',
        id: 'user-fund-benchmark',
        accessor: row => row,
        Cell: ({ value: fund }) => (fundPeerGroupFilters?.[fund?.fundId] ? <UserFundBenchmarkTableIndicator /> : null),
        width: '13px',
        minDisplayWidth: 0,
        disableSortBy: true,
      },
      {
        Header: 'Fund Name',
        id: 'name',
        accessor: row => row,
        Cell: FundNameCell,
        width: 'minmax(10em, 2fr)',
        minDisplayWidth: 0,
      },
      ...columnPresets.filter(column =>
        ['vintage', 'size', 'strategy', 'subStrategy', 'dpi', 'rvpi', 'irr', 'tvpi', 'calledPct', 'save'].includes(
          column.id
        )
      ),
    ],
    [onClickCompareCheckbox, columnPresets, fundPeerGroupFilters]
  );

  const [hiddenColumnIds, setHiddenColumnIds] = useState([]);

  useEffect(() => {
    const hideAllExcept = ['compare', 'user-fund-benchmark', 'name', 'vintage', 'save'];
    const columnIds = columns.map(c => c.id).filter(id => !hideAllExcept.includes(id));
    setHiddenColumnIds(narrowTable ? columnIds : []);
  }, [narrowTable, columns]);

  const data = useMemo(() => {
    return [...funds].map(assignDisabledStatus(metric, showOutliers)).sort((a, b) => {
      if (a.subjectFund) return -1;
      if (b.subjectFund) return 1;
      if (a.disabled && !b.disabled) return 1;
      if (b.disabled && !a.disabled) return -1;
      return a.name.localeCompare(b.name);
    });
  }, [funds, metric, showOutliers]);

  return (
    <>
      <VirtualizedGridTable
        columns={columns}
        hiddenColumnIds={hiddenColumnIds}
        data={data}
        stateReducer={tableStateReducer}
        css={`
          ${tableCss};

          .disabled {
            color: #fff4;
            /* pointer-events: none; */
          }

          .disabled .compare {
            color: transparent;
          }
        `}
      />
      <BookmarkPopover {...bookmarkPopoverState} />
    </>
  );
}

CompareToPeersTable.defaultProps = {
  narrowTable: true,
};

CompareToPeersTable.propTypes = {
  onClickCompareCheckbox: PropTypes.func,
  narrowTable: PropTypes.bool,
};

export default CompareToPeersTable;
