import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorMessage } from 'src/components';
import Bugsnag from '@bugsnag/js';
import { formatComponentStack } from '@bugsnag/plugin-react';

const RetryButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: white;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: `${error}`,
    };
  }

  componentDidCatch(error, info) {
    if (info && info.componentStack) {
      info.componentStack = formatComponentStack(info.componentStack);
    }

    Bugsnag.notify(error, function (event) {
      event.addMetadata('react', info);
    });
  }

  handleRetry() {
    this.setState((state, _props) => {
      return { ...state, hasError: false };
    });
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { children, message, allowRetry, ...rest } = this.props;

    if (hasError) {
      return (
        <div {...rest}>
          <ErrorMessage>
            {message ? <>{message}</> : `[${errorMessage}]`}
            {allowRetry && (
              <>
                {' '}
                <RetryButton onClick={() => this.handleRetry()}>Retry?</RetryButton>
              </>
            )}
          </ErrorMessage>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  children: null,
  message: '',
  allowRetry: false,
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  message: PropTypes.string,
  allowRetry: PropTypes.bool,
};

export default ErrorBoundary;
