import { useEffect, useRef } from 'react';

export default function useMouseEventRefs({ onItemOver, onItemOut, onItemClick, onItemTouchStart, onItemTouchEnd }) {
  const itemOver = useRef();
  const itemOut = useRef();
  const itemClick = useRef();
  const itemTouchStart = useRef();
  const itemTouchEnd = useRef();

  useEffect(() => {
    itemOver.current = onItemOver;
    itemOut.current = onItemOut;
    itemClick.current = onItemClick;
    itemTouchStart.current = onItemTouchStart;
    itemTouchEnd.current = onItemTouchEnd;
  }, [onItemOver, onItemOut, onItemClick, onItemTouchStart, onItemTouchEnd]);

  return { itemOver, itemOut, itemClick, itemTouchStart, itemTouchEnd };
}
