import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconCurveHistory(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M90 86.098V90H10V10h3.902v58.8L44 38.722l12.439 12.293 23.6-23.6-14.439.897-.273-4.497 22.936-1.444-1.434 22.956-4.507-.293.907-14.429-26.761 26.771-3.219-3.171-9.229-9.132L13.902 75.19v10.908z" />
    </Svg>
  );
}
