import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import IconInfo from '../IconInfoOutlined';

const Button = styled(IconButton)`
  /* color: #7d7d7d; */

  .MuiSvgIcon-root {
    width: 0.625em;
    height: 0.625em;
  }
`;

const ButtonInfo = props => {
  return (
    <Button size="small" {...props}>
      <IconInfo />
    </Button>
  );
};

ButtonInfo.defaultProps = {};

ButtonInfo.propTypes = {};

export default ButtonInfo;
