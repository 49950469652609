import React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuItem as MenuItemBase } from '@material-ui/core';
import TextField from '../TextField';

const DropdownRoot = styled(TextField)`
  margin: 0;
  .MuiSelect-root {
    font-size: 11px;
    padding-top: 3px;
    padding-bottom: 2px;
    width: ${props => props.width};
  }
  .MuiFormLabel-root {
    font-size: 11px;
    text-transform: uppercase;
    margin-top: -6px;
    &.Mui-focused {
      margin-top: unset;
    }
    &.MuiFormLabel-filled {
      margin-top: unset;
    }
  }
  svg {
    right: 0px;
  }
`;

const MenuItem = styled(MenuItemBase)`
  font-size: 11px;
`;

function Dropdown(props) {
  const { value, items, label, itemFormat, itemKeyProperty, onChange, className } = props;

  const handleChange = useCallback(
    function (event) {
      const selectedValue = event.target.value;
      if (!itemKeyProperty) return onChange(selectedValue);

      const selectedItem = items.find(item => item[itemKeyProperty] === selectedValue);
      onChange(selectedItem);
    },
    [items, onChange, itemKeyProperty]
  );

  const currentValue = (itemKeyProperty ? value?.[itemKeyProperty] : value) ?? '';

  return (
    <DropdownRoot
      label={label}
      select
      margin="dense"
      value={currentValue}
      onChange={handleChange}
      className={className}
    >
      {items.map(item => (
        <MenuItem
          key={itemKeyProperty ? item[itemKeyProperty] : item}
          value={itemKeyProperty ? item[itemKeyProperty] : item}
        >
          {itemFormat(item)}
        </MenuItem>
      ))}
    </DropdownRoot>
  );
}

Dropdown.defaultProps = {
  label: '',
  onChange: () => {},
  itemFormat: item => item,
  value: null,
};

Dropdown.propTypes = {
  value: PropTypes.any,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  itemFormat: PropTypes.func,
  onChange: PropTypes.func,
  itemKeyProperty: PropTypes.string,
};

export default Dropdown;
