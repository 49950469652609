// import { useQuery } from 'react-query';
// import { filterApi } from 'src/services';
import { useCurrentSubscription, useDefaultPaymentMethod } from 'src/hooks';

function useStripeCustomer() {
  const currentSubscription = useCurrentSubscription();
  const defaultPaymentMethod = useDefaultPaymentMethod();

  const data = {
    currentSubscription: currentSubscription.data,
    defaultPaymentMethod: defaultPaymentMethod.data,
  };

  const isLoading = [currentSubscription, defaultPaymentMethod].some(query => query.isLoading);

  const isError = [currentSubscription, defaultPaymentMethod].some(query => query.isError);

  const error = currentSubscription.error || defaultPaymentMethod.error;

  async function refetch() {
    await currentSubscription.refetch();
    await defaultPaymentMethod.refetch();
  }

  return { data, isLoading, isError, error, refetch };
}

export default useStripeCustomer;
