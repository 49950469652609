import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolioNews } from '../store/portfolio-news';

function usePortfolioNews({ fundManagerIds }) {
  const dispatch = useDispatch();
  const portfolioNews = useSelector(state => state.news.portfolio);

  return useMemo(() => {
    if (!fundManagerIds) return;
    if (!portfolioNews) return;
    if (portfolioNews.fetching) return;
    if (portfolioNews.fetched) return portfolioNews.articles;
    dispatch(fetchPortfolioNews({ fundManagerIds }));
  }, [dispatch, fundManagerIds, portfolioNews]);
}

export default usePortfolioNews;
