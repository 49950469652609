import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import IconDownload from '../IconDownload';

const Button = styled(IconButton)`
  color: #7d7d7d;
  .MuiSvgIcon-root {
    width: 0.75em;
    height: 0.75em;
  }
`;

const ButtonShareIcon = props => {
  return (
    <Button size="small" {...props}>
      <IconDownload />
    </Button>
  );
};

ButtonShareIcon.defaultProps = {};

ButtonShareIcon.propTypes = {};

export default ButtonShareIcon;
