import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNewsContent } from '../store/news-content';

export default function useNewsContent({ articleId }) {
  const dispatch = useDispatch();
  const allContent = useSelector(state => state.news.content.byId);
  const [content, setContent] = useState();

  useEffect(() => {
    if (!articleId) return;
    if (allContent[articleId]) return;

    dispatch(fetchNewsContent({ articleId }));
  }, [allContent, articleId, dispatch]);

  useEffect(() => {
    if (!allContent[articleId]) return;
    if (!allContent[articleId].fetched) return;
    if (!allContent[articleId].content) {
      return setContent({ ...allContent[articleId], error: 404 });
    }

    setContent(allContent[articleId]);
  }, [allContent, articleId]);

  return content;
}
