import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Menu, Button } from '@material-ui/core';
import { publicIndexes } from './public-indexes';

function PublicIndexSelector({ selectedIndex, enabledIndexes, onSelectedIndexChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClickListItem(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuItemClick(index) {
    setAnchorEl(null);
    onSelectedIndexChange(index);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Button onClick={handleClickListItem}>PME+</Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.values(publicIndexes).map(index => (
          <MenuItem
            key={index.name}
            disabled={enabledIndexes && !enabledIndexes.find(enabled => enabled === index.name)}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index)}
          >
            {index.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

PublicIndexSelector.defaultProps = {
  showAllFundsLink: false,
  onSelectedIndexChange: () => {},
};

PublicIndexSelector.propTypes = {
  selectedIndex: PropTypes.object,
  enabledIndexes: PropTypes.array.isRequired,
  onSelectedIndexChange: PropTypes.func,
};

export { PublicIndexSelector };
