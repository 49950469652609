function clamp(min, max) {
  return function (num) {
    const lowerInBounds = Math.max(num, min);
    return Math.min(lowerInBounds, max);
  };
}

function sequence(min = 0, max = 0) {
  const dist = Math.abs(max - min);
  const length = Number.isFinite(dist) ? dist + 1 : 0;
  return Array.from({ length }, (_, i) => min + i);
}

export { clamp, sequence };
