import { createReducer, createAction } from '@reduxjs/toolkit';
import { filterApi } from '../services';

const fetchPriorFirmEmployeesError = createAction('FETCH_PRIOR_FIRM_EMPLOYEES_ERROR');
const fetchPriorFirmEmployeesPending = createAction('FETCH_PRIOR_FIRM_EMPLOYEES_PENDING');
const fetchPriorFirmEmployeesSuccess = createAction('FETCH_PRIOR_FIRM_EMPLOYEES_SUCCESS');

export function fetchPriorFirmEmployees({ fundManagerId }) {
  return async function (dispatch, getState) {
    const { byId } = getState().employee.priorFirm;
    if (byId[fundManagerId]) return;
    dispatch(fetchPriorFirmEmployeesPending({ fundManagerId }));
    try {
      const employees = await filterApi.getFundManagerFormerEmployees({ fundManagerId });
      dispatch(fetchPriorFirmEmployeesSuccess({ employees, fundManagerId }));
    } catch (error) {
      dispatch(fetchPriorFirmEmployeesError({ error: error.message, fundManagerId }));
    }
  };
}

const initialState = {
  byId: {},
};

export default createReducer(initialState, {
  [fetchPriorFirmEmployeesPending]: (state, action) => {
    const { fundManagerId } = action.payload;
    const obj = state.byId[fundManagerId] || {};
    obj.fetching = true;
    state.byId[fundManagerId] = obj;
  },
  [fetchPriorFirmEmployeesError]: (state, action) => {
    const { error, fundManagerId } = action.payload;
    const obj = state.byId[fundManagerId] || {};
    obj.error = error;
    obj.fetching = false;
    state.byId[fundManagerId] = obj;
    console.error(error);
  },
  [fetchPriorFirmEmployeesSuccess]: (state, action) => {
    const { employees, fundManagerId } = action.payload;
    state.byId[fundManagerId] = {
      employees,
      fetched: true,
      fetching: false,
      error: null,
    };
  },
});
