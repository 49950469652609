import React from 'react';
import PropTypes from 'prop-types';
import ButtonArrowRight from '../ButtonArrowRight';

function ButtonArrowLeft({ flipped, ...rest }) {
  return <ButtonArrowRight flipped={flipped} {...rest} />;
}

ButtonArrowLeft.defaultProps = {
  flipped: true,
};

ButtonArrowLeft.propTypes = {
  flipped: PropTypes.bool,
};

export default ButtonArrowLeft;
