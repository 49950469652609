import styled from 'styled-components';
import IconClose from '../IconClose';

export default styled(IconClose)`
  margin: ${props => props.margin};
  color: ${props => props.color || props.theme.palette.grey[500]};
  cursor: pointer;
  width: 14px !important;
  &:hover {
    color: ${props => props.hoverColor || props.theme.palette.grey[600]};
  }
`;
