import React from 'react';
import { CssBaseline, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { themeDark, themeLight } from '.';
import { GlobalStyles } from './global-styles';

export const withTheme = (Component, lightOrDark) => () => {
  const theme = lightOrDark === 'light' ? themeLight : themeDark;
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline>
            <GlobalStyles />
            <Component />
          </CssBaseline>
        </StylesProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default withTheme;
