import { db } from 'src/config/firebase';
import { DocumentNotFoundError } from 'src/services/filter-api/errors';

const collection = db.collection('fundManagerLocations');

async function get(fundManagerId) {
  const docId = fundManagerId;
  const doc = await collection.doc(docId).get();
  if (!doc.exists) throw new DocumentNotFoundError(doc);
  return doc.data();
}

export { collection, get };
