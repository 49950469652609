import React from 'react';
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import { setEmployeeBookmark } from 'src/store/employee-bookmarks';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { useFundManagerCurrentEmployees, useSchoolEmployees, usePriorFirmEmployees, useLayoutActions } from 'src/hooks';
import { AppPanel } from 'src/components';
import {
  createFundManagerRootNode,
  createSchoolRootNode,
  createSchoolHierarchy,
  createTitleHierarchy,
  createPriorFirmHierarchy,
  createFundManagerBySchoolHierarchy,
  createFundManagerByPriorFirmHierarchy,
} from './hierarchy';
import FundManager from './FundManager';
import UserFundManager from './UserFundManager';
import { VIEW_STATE, NODE_TYPE } from './constants';

export function FundManagerTeamVisualization({ fundManager, view, setView, wideTable, setWideTable }) {
  const { isExpanded } = useContext(AppPanel.Context);
  const { fundManagerId } = fundManager;
  const dispatch = useDispatch();
  const [fundManagerTeamHierarchy, setFundManagerTeamHierarchy] = useState(null);
  const [tableEmployees, setTableEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedHierarchyNode, setSelectedHierarchyNode] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedPriorFirm, setSelectedPriorFirm] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);

  const fundManagerEmployees = useFundManagerCurrentEmployees({
    fundManagerId,
  });
  const schoolEmployees = useSchoolEmployees({
    schoolId: selectedSchool && selectedSchool.schoolId,
  });
  const priorFirmEmployees = usePriorFirmEmployees({
    fundManagerId: selectedPriorFirm && selectedPriorFirm.fundManagerId,
  });
  const { expandPanel } = useLayoutActions();
  const location = useLocation();

  useEffect(() => {
    setSelectedEmployeeId(null);
    setSelectedHierarchyNode(null);
    setSelectedSchool(null);
    setSelectedPriorFirm(null);
    setView((location.state || {}).display || VIEW_STATE.employeeByTitle);
  }, [fundManagerId, location.state, setView]);

  useEffect(() => {
    if (!isExpanded && wideTable) {
      setWideTable(false);
    }
  }, [isExpanded, wideTable, setWideTable]);

  useEffect(() => {
    switch (view) {
      case VIEW_STATE.employeeByTitle:
        if (fundManagerEmployees) {
          const rootNode = createFundManagerRootNode(fundManager);
          rootNode.children = createTitleHierarchy(
            fundManagerEmployees,
            isExpanded,
            selectedEmployeeId,
            selectedHierarchyNode
          );
          setTableEmployees(
            fundManagerEmployees.map(e => ({
              ...e,
              isSelected: selectedEmployeeId === e.employeeId,
            }))
          );
          setFundManagerTeamHierarchy(rootNode);
        }
        break;
      case VIEW_STATE.employeeBySchool:
        if (fundManagerEmployees) {
          const rootNode = createFundManagerRootNode(fundManager);
          rootNode.children = createSchoolHierarchy(
            fundManagerEmployees,
            isExpanded,
            selectedEmployeeId,
            selectedHierarchyNode
          );
          setTableEmployees(
            fundManagerEmployees.map(e => ({
              ...e,
              isSelected: selectedEmployeeId === e.employeeId,
            }))
          );
          setFundManagerTeamHierarchy(rootNode);
        }
        break;
      case VIEW_STATE.employeeByPriorFirm:
        if (fundManagerEmployees) {
          const rootNode = createFundManagerRootNode(fundManager);
          rootNode.children = createPriorFirmHierarchy(
            fundManagerEmployees,
            isExpanded,
            selectedEmployeeId,
            selectedHierarchyNode
          );
          setTableEmployees(
            fundManagerEmployees.map(e => ({
              ...e,
              isSelected: selectedEmployeeId === e.employeeId,
            }))
          );
          setFundManagerTeamHierarchy(rootNode);
        }
        break;
      case VIEW_STATE.schoolByFundManager:
        if (schoolEmployees && selectedSchool) {
          const rootNode = createSchoolRootNode(selectedSchool);
          rootNode.children = createFundManagerBySchoolHierarchy(schoolEmployees);
          setFundManagerTeamHierarchy(rootNode);
          setTableEmployees(schoolEmployees);
        }
        break;
      case VIEW_STATE.priorFirmByFundManager:
        if (priorFirmEmployees && selectedPriorFirm) {
          const rootNode = createFundManagerRootNode(selectedPriorFirm);
          rootNode.children = createFundManagerByPriorFirmHierarchy(priorFirmEmployees);
          setFundManagerTeamHierarchy(rootNode);
          setTableEmployees(priorFirmEmployees);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, isExpanded, fundManagerEmployees, schoolEmployees, selectedEmployeeId, selectedHierarchyNode]);

  function toggleWideTable() {
    setWideTable(!wideTable);
  }

  function handleSvgClick() {
    expandPanel();
  }

  function handleHierarchyItemClick(_event, { data }) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundManagerTeamWheel);
    if (data.type === NODE_TYPE.title) {
      setSelectedHierarchyNode(data.id);
      setSelectedEmployeeId(null);
      setView(VIEW_STATE.employeeByTitle);

      if (!isExpanded) expandPanel();
    }

    if (data.type === NODE_TYPE.school) {
      setSelectedHierarchyNode(data.id);
      setSelectedEmployeeId(null);
      if (data.isSelected) {
        setSelectedSchool({ schoolId: data.key, name: data.label });
        setView(VIEW_STATE.schoolByFundManager);
      } else {
        setView(VIEW_STATE.employeeBySchool);
      }

      if (!isExpanded) expandPanel();
    }

    if (data.type === NODE_TYPE.priorFirm) {
      setSelectedHierarchyNode(data.id);
      setSelectedEmployeeId(null);
      if (data.isSelected) {
        /**
         * setSelectedPriorFirm({ fundManagerId: data.key, name: data.label });
         * setView(VIEW_STATE.priorFirmByFundManager);
         */
      } else {
        setView(VIEW_STATE.employeeByPriorFirm);
      }

      if (!isExpanded) expandPanel();
    }

    if (data.type === NODE_TYPE.fundManager) {
      const display = {
        [VIEW_STATE.schoolByFundManager]: VIEW_STATE.employeeBySchool,
        [VIEW_STATE.priorFirmByFundManager]: VIEW_STATE.employeeByPriorFirm,
      }[view];

      navigate(`/managers/${data.key}`, {
        state: { display },
      });
    }

    if (data.type === NODE_TYPE.employee) {
      setSelectedEmployeeId(data.id);
    }
  }

  function handleTableRowClick(_event, d) {
    const { data } = d;
    const { currentParentTitleName, currentTitleName, lastParentSchoolName, employeeId } = data;
    if (view === VIEW_STATE.employeeByTitle) {
      setSelectedHierarchyNode(currentParentTitleName || currentTitleName);
    } else {
      setSelectedHierarchyNode(lastParentSchoolName);
    }
    setSelectedEmployeeId(employeeId);
    expandPanel();
  }

  function handleTableColumnClick(e, { column, data }) {
    if (column.key === 'save') {
      e.preventDefault();
      e.stopPropagation();
      dispatch(
        setEmployeeBookmark({
          employeeId: data.employeeId,
          bookmarked: !data.bookmarked,
        })
      );
    }
  }

  function handleTableRowMouseOver(e, { data }) {
    e.preventDefault();
    e.stopPropagation();
    if (!isExpanded) return;

    setTooltipAnchorEl(e.currentTarget);
    setTooltipData(data);
    setTooltipOpen(true);
  }

  function handleTableRowMouseOut(e) {
    e.preventDefault();
    e.stopPropagation();

    setTooltipOpen(false);
    setTooltipAnchorEl(null);
  }

  function handleWideTableToggle() {
    toggleWideTable();
    expandPanel();
  }

  function handleTableListDefCreated(listDef) {
    if (view === VIEW_STATE.schoolByFundManager && wideTable) return;
    // delete the currentFundManagerName column
    listDef.splice(1, 1);
  }

  if (!fundManager) return null;

  if (fundManager.userId) return <UserFundManager fundManager={fundManager} />;

  return (
    <FundManager
      fundManager={fundManager}
      fundManagerEmployees={fundManagerEmployees}
      fundManagerTeamHierarchy={fundManagerTeamHierarchy}
      wideTable={wideTable}
      handleWideTableToggle={handleWideTableToggle}
      tableEmployees={tableEmployees}
      handleTableRowClick={handleTableRowClick}
      handleTableRowMouseOver={handleTableRowMouseOver}
      handleTableRowMouseOut={handleTableRowMouseOut}
      handleTableColumnClick={handleTableColumnClick}
      handleTableListDefCreated={handleTableListDefCreated}
      expanded={isExpanded}
      tableTooltipOpen={tooltipOpen}
      tableTooltipData={tooltipData}
      tableTooltipAnchorEl={tooltipAnchorEl}
      handleSvgClick={handleSvgClick}
      handleHierarchyItemClick={handleHierarchyItemClick}
    />
  );
}
FundManagerTeamVisualization.CONSTANTS = { VIEW_STATE };

FundManagerTeamVisualization.defaultProps = {};

FundManagerTeamVisualization.propTypes = {
  fundManager: PropTypes.object.isRequired,
};

export default FundManagerTeamVisualization;
