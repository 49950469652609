import React from 'react';
import { useEffect, useRef } from 'react';
import { useTable, useSortBy } from 'react-table';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import { useScrollbarWidth } from 'src/hooks';
import { IconSortUp, IconSortDown } from 'src/components';

const ROW_HEIGHT = 26;

const TableLayout = styled.div`
  display: block;
  border-spacing: 0;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 4px);
  height: 100%;
`;

const TableHeader = styled.div``;

const TableBody = styled.div`
  margin-right: -${p => p.$scrollBarWidth}px;
`;

const Tr = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-columns: ${p => p.$gridTemplateColumns};
  background-color: ${p => p.$striped && 'rgba(255, 255, 255, 0.03)'};

  &:hover {
    background-color: #14343d;
    color: white;
  }
`;

const Td = styled.div`
  color: currentColor;
  border: none;
  margin: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  white-space: nowrap;
  overflow: hidden;

  font-size: 0.6875rem;
  line-height: ${ROW_HEIGHT}px;

  &::after {
  }
`;

const Th = styled(Td)`
  opacity: 0.7;
  font-size: 0.625rem;
  text-transform: uppercase;
`;

const IconSort = styled(IconSortUp)`
  &.MuiSvgIcon-root {
    width: 1.25em;
    height: 1.25em;
    margin-left: 0.5em;
    font-size: inherit;
    vertical-align: text-top;
  }
`;

function Table({
  columns,
  hiddenColumns,
  data,
  height,
  stateReducer,
  initialSortBy,
  onChangeRows,
  gridTemplateColumns,
}) {
  const listRef = useRef();
  const scrollBarWidth = useScrollbarWidth(listRef);

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns, state } = useTable(
    {
      columns,
      data,
      useControlledState: stateReducer,
      initialState: {
        sortBy: initialSortBy,
      },
      autoResetRowState: false,
    },
    useSortBy
  );

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (!onChangeRows) return;
    const exportData = rows.map(row => {
      return columns.reduce((acc, column) => {
        const key = typeof column.accessor === 'string' ? column.accessor : column.id;

        const header = typeof column.Header === 'string' ? column.Header : key;

        const formatter = typeof column.ExportCell === 'function' ? column.ExportCell : ({ value: x }) => x;
        const value = formatter({ value: row.values[key] });

        return {
          ...acc,
          [header]: value,
        };
      }, {});
    });

    onChangeRows(exportData);
  }, [rows, onChangeRows, columns]);

  return (
    <TableLayout {...getTableProps()}>
      <TableHeader>
        {headerGroups.map(headerGroup => {
          const { key: groupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <Tr key={groupKey} {...headerGroupProps} $gridTemplateColumns={gridTemplateColumns}>
              {headerGroup.headers.map(column => {
                const { isSorted, isSortedDesc } = column;
                const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());

                return (
                  <Th key={columnKey} {...columnProps} id={columnKey} className={column.className || column.id}>
                    {column.render('Header')}
                    {isSorted && <IconSort as={isSortedDesc && IconSortDown} />}
                  </Th>
                );
              })}
            </Tr>
          );
        })}
      </TableHeader>

      <TableBody $scrollBarWidth={scrollBarWidth} {...getTableBodyProps()}>
        <div
          css={`
            height: 100%;
            background: cyan;
          `}
        ></div>
        <FixedSizeList
          outerRef={listRef}
          height={Math.max(0, height - ROW_HEIGHT)}
          itemCount={rows.length}
          itemSize={ROW_HEIGHT}
          itemData={{ state }}
        >
          {({ index, style }) => {
            const row = rows[index];
            prepareRow(row);
            const { color, className: rowClassName } = row.original;

            const rowProps = row.getRowProps({
              style: {
                ...style,
                backgroundColor: `${color}47`,
                color,
              },
            });

            return (
              <Tr
                {...rowProps}
                $gridTemplateColumns={gridTemplateColumns}
                $striped={index % 2 === 0}
                className={rowClassName}
              >
                {row.cells.map(cell => {
                  const { key, ...cellProps } = cell.getCellProps();
                  return (
                    <Td key={key} {...cellProps} id={key} className={cell.column.className || cell.column.id}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          }}
        </FixedSizeList>
      </TableBody>
    </TableLayout>
  );
}

export default Table;
