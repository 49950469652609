import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconHeart(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d=" M 86.5 45.25 Q 87.6 38.9 85.15 32.85 82.65 26.75 77.45 23 71.85 19 64.65 19 60.25 19.05 56.25 20.75 52.2 22.5 49.15 25.65 46.05 22.5 42 20.8 37.95 19.1 33.55 19.1 29.15 19.1 25.1 20.8 21 22.5 17.95 25.65 14.75 28.9 13.1 33.05 11.45 37.05 11.45 41.4 11.45 45.75 13.1 49.8 14.75 53.95 17.95 57.15 L 47.55 87.05 Q 48.2 87.7 49.15 87.7 50.05 87.7 50.7 87.05 L 80.35 57.05 Q 85.35 52.05 86.5 45.25 Z" />
    </Svg>
  );
}
