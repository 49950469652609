import styled, { createGlobalStyle } from 'styled-components';
import { sm, md, lg, xl } from 'src/config/layout';
import { layout } from 'src/config/colors';

const GlobalLayoutStyles = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    min-height: 100%;
    overflow-x: hidden;
    color: ${layout.body.TEXT_COLOR};
    background-color: ${layout.body.BACKGROUND_COLOR};
  }

  #root, #router {
    display: contents;
    background-color: inherit;
  }
`;

const Layout = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  background-color: inherit;
`;

const PrimaryNavArea = styled.nav`
  color: ${layout.primaryNav.TEXT_COLOR};
  background-color: ${layout.primaryNav.BACKGROUND_COLOR};

  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${sm.PRIMARY_NAV}px;
  z-index: 100;

  @media (min-width: ${md.BREAKPOINT}px) {
    top: 0;
    bottom: ${p => (p.$ticker ? md.TICKER : 0)}px;
    width: ${md.PRIMARY_NAV}px;
    height: unset;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    bottom: ${p => (p.$ticker ? lg.TICKER : 0)}px;
    width: ${lg.PRIMARY_NAV}px;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    bottom: ${p => (p.$ticker ? xl.TICKER : 0)}px;
    width: ${xl.PRIMARY_NAV}px;
  }
`;

const SecondaryNavArea = styled.div`
  color: ${layout.secondaryNav.TEXT_COLOR};
  background-color: ${layout.secondaryNav.BACKGROUND_COLOR};

  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${sm.SECONDARY_NAV}px;
  z-index: 100;

  padding: ${sm.MARGIN}px;

  @media (min-width: ${md.BREAKPOINT}px) {
    left: ${md.PRIMARY_NAV}px;
  }

  @media (min-height: ${md.V_BREAKPOINT}px) {
    height: ${md.SECONDARY_NAV}px;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    left: ${lg.PRIMARY_NAV}px;
    height: ${lg.SECONDARY_NAV}px;
  }

  @media (min-height: ${lg.V_BREAKPOINT}px) {
    height: ${lg.SECONDARY_NAV}px;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    left: ${xl.PRIMARY_NAV}px;
  }

  @media (min-height: ${xl.V_BREAKPOINT}px) {
    height: ${xl.SECONDARY_NAV}px;
  }
`;

const TickerArea = styled.div`
  color: ${layout.newsTicker.TEXT_COLOR};
  background-color: ${layout.newsTicker.BACKGROUND_COLOR};

  display: ${p => (p.$hide ? 'none' : 'initial')};
  overflow: hidden;
  position: fixed;
  bottom: ${sm.PRIMARY_NAV}px;
  left: 0;
  right: 0;
  height: ${sm.TICKER}px;
  z-index: 100;

  @media (min-width: ${md.BREAKPOINT}px) {
    height: ${md.TICKER}px;
    bottom: 0;
    left: 0;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    height: ${lg.TICKER}px;
    left: 0;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    height: ${xl.TICKER}px;
    left: 0;
  }
`;

const ContentArea = styled.div`
  color: ${layout.contentArea.TEXT_COLOR};
  background-color: ${layout.contentArea.BACKGROUND_COLOR};

  display: block;
  width: 100%;
  min-height: 100%;

  padding-top: ${sm.SECONDARY_NAV + sm.MARGIN}px;
  padding-bottom: ${p => (p.$ticker ? sm.PRIMARY_NAV + sm.TICKER + sm.MARGIN : sm.PRIMARY_NAV + sm.MARGIN)}px;
  padding-left: ${sm.MARGIN}px;
  padding-right: ${sm.MARGIN}px;

  @media (min-width: ${md.BREAKPOINT}px) {
    padding-top: ${md.SECONDARY_NAV + md.MARGIN}px;
    padding-bottom: ${p => (p.$ticker ? md.TICKER + md.MARGIN : md.MARGIN)}px;
    padding-left: ${md.PRIMARY_NAV + md.MARGIN}px;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    padding-top: ${lg.SECONDARY_NAV + lg.MARGIN}px;
    padding-bottom: ${p => (p.$ticker ? lg.TICKER + lg.MARGIN : lg.MARGIN)}px;
    padding-left: ${lg.PRIMARY_NAV + lg.MARGIN}px;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    padding-top: ${xl.SECONDARY_NAV + xl.MARGIN}px;
    padding-bottom: ${p => (p.$ticker ? xl.TICKER + xl.MARGIN : xl.MARGIN)}px;
    padding-left: ${xl.PRIMARY_NAV + xl.MARGIN}px;
  }

  @media (min-height: ${md.V_BREAKPOINT}px) {
    padding-top: ${md.SECONDARY_NAV + md.MARGIN}px;
  }

  @media (min-height: ${lg.V_BREAKPOINT}px) {
    padding-top: ${lg.SECONDARY_NAV + lg.MARGIN}px;
  }

  @media (min-height: ${xl.V_BREAKPOINT}px) {
    padding-top: ${xl.SECONDARY_NAV + xl.MARGIN}px;
  }
`;

const Grid = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  gap: ${sm.MARGIN}px;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  grid-auto-rows: auto;

  @media (min-width: ${md.BREAKPOINT}px) {
    gap: ${md.MARGIN}px;
    grid-template-rows: auto;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(12, minmax(0, 1fr));
    gap: ${lg.MARGIN}px;
    grid-auto-rows: auto;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    gap: ${xl.MARGIN}px;
  }
`;

export { GlobalLayoutStyles, Layout, PrimaryNavArea, SecondaryNavArea, TickerArea, ContentArea, Grid };
