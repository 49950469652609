import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MenuItem, Menu, List, ListItem, ListItemText, Radio } from '@material-ui/core';
import { IconTag } from 'src/components';

const StyledList = styled(List)`
  padding: 0;

  .MuiListItem-root {
    border: 1px solid rgba(255, 255, 255, 0.23);
    font-weight: 300;
  }

  .MuiListItem-dense {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .MuiListItemText-primary,
  .MuiListItemText-secondary {
    font-size: 1em;
    font-weight: 300;
  }

  .MuiListItemText-secondary {
    opacity: 0.5;
  }
`;

const StyledMenu = styled(Menu).attrs({
  keepMounted: true,
  variant: 'menu',
})`
  .MuiPaper-root {
    font-size: 0.8125rem;
    background-color: #313131;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: #a4a4a4;
  font-weight: 300;
  padding: 0.307692308em 20px;
  font-size: inherit;

  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: transparent;
    color: white;
  }
`;

const MenuSectionHeader = styled(MenuItem).attrs({
  disabled: true,
})`
  text-transform: capitalize;
  color: white;
  font-size: 1em;

  &.MuiListItem-root.Mui-disabled {
    opacity: 1;
  }
`;

const CheckElement = styled.span`
  padding: 0;
  margin-right: 9px;
`;

function CategorizedMultiselect({ className, label, icon, header, value, displayValue, items, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledList className={className} component="nav" dense>
        <ListItem button onClick={handleClickListItem}>
          <ListItemText
            css={`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.MuiListItemText-root {
                display: flex;
              }

              .MuiListItemText-secondary {
                margin-left: 0.25em;
                max-width: 6em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            `}
            primary={displayValue ? `${label}: ` : label}
            secondary={displayValue}
          />
          <ArrowDropDownIcon />
        </ListItem>
      </StyledList>
      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {header && <MenuSectionHeader>{header}</MenuSectionHeader>}

        {items.map(item => {
          const isSelected = value.includes(item);

          return (
            <StyledMenuItem
              key={item}
              selected={isSelected}
              onClick={() => onChange(isSelected ? 'deselect' : 'select', item)}
            >
              <CheckElement
                as={
                  {
                    radio: Radio,
                    tag: IconTag,
                  }[icon]
                }
                color={isSelected ? 'primary' : 'inherit'}
                variation={!isSelected ? 'outline' : ''}
                checked={isSelected}
              />{' '}
              {item}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
}

CategorizedMultiselect.defaultProps = {
  icon: 'radio',
};

CategorizedMultiselect.propTypes = {
  children: PropTypes.any,
  categories: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.oneOf(['radio', 'tag']),
};

export default CategorizedMultiselect;
