import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import { cloneDeep } from 'src/lib/lodash';
import { upperCase } from '../../../formatters';
import BaseVirtualTable from '../VirtualizedTable';

const listDefNarrow = [
  {
    width: 120,
    sortable: true,
    headerStyle: { padding: '0 0 0 12px' },
    cellStyle: { padding: '0 0 0 12px' },
    property: 'name',
    key: 'name',
    label: upperCase('name'),
    isNumeric: false,
  },
  {
    width: 120,
    sortable: true,
    property: 'location',
    key: 'location',
    label: upperCase('location'),
    isNumeric: false,
  },
  {
    width: 200,
    sortable: true,
    property: 'title',
    key: 'title',
    label: upperCase('position'),
    isNumeric: false,
  },
];

const VirtualizedTable = styled(BaseVirtualTable)`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  padding-top: 10px;
`;

function FundManagerJoinersAndLeaversTable({
  data,
  theme: _theme,
  onListDefCreated,
  onColumnClick,
  onRowClick,
  ...rest
}) {
  const [localData, setLocalData] = useState(null);
  const [listDef, setListDef] = useState(null);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    const listDefClone = cloneDeep(listDefNarrow);
    onListDefCreated(listDefClone);
    // give the parent component a chance to update the listDef
    setListDef(listDefClone);
  }, [onListDefCreated]);

  // eslint-disable-next-line no-shadow
  function handleColumnClick(e, { column, data, index }) {
    onColumnClick(e, { column, data, index });
  }

  // eslint-disable-next-line no-shadow
  function handleRowClick(e, { data, index }) {
    onRowClick(e, { data, index });
  }

  return (
    <VirtualizedTable
      data={localData}
      listDef={listDef}
      onColumnClick={handleColumnClick}
      onRowClick={handleRowClick}
      rowColor="#afafaf"
      {...rest}
    />
  );
}

FundManagerJoinersAndLeaversTable.defaultProps = {
  onListDefCreated: () => {},
  onColumnClick: () => {},
  onRowClick: () => {},
};

FundManagerJoinersAndLeaversTable.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  onListDefCreated: PropTypes.func,
  onColumnClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default withTheme(FundManagerJoinersAndLeaversTable);
