import React from 'react';
import { useCallback, useContext, useRef, useState, useEffect } from 'react';
import { useTimeoutFn } from 'react-use';
import styled, { css, createGlobalStyle } from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import { uniqueId } from 'src/utils';
import { sm, md, lg, xl, animation } from 'src/config/layout';
import { useViewportSize } from 'src/hooks';
import { AppLayout, ErrorBoundary } from 'src/components';
import Context from './Context';
import ControlBar from './ControlBar';
import Loading from './Loading';
import ImageDownloadDialog from './ImageDownloadDialog';

function getWidthSpan(spanX) {
  return {
    full: 2,
    half: 1,
    1: 1,
    2: 2,
  }[spanX];
}

function getHeightSpan(spanY) {
  return {
    full: 12,
    half: 6,
    third: 4,
    quarter: 3,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
  }[spanY];
}

const preventScrollCss = css`
  body {
    overflow: hidden !important;
  }
`;

const GlobalStyleOverrides = createGlobalStyle`
  ${p => p.$preventScroll && preventScrollCss};
`;

const GridSpace = styled.section`
  display: table;
  height: 100%;
  width: 100%;
  opacity: ${p => (p.$isCollapsed ? 0 : 1)};
  transition: opacity ${animation.TIMING}ms ${animation.EASING};
  background-color: ${p => (p.$isExpanded ? 'transparent' : '#141414')};

  position: relative;

  @media (min-height: ${md.V_BREAKPOINT}px) {
    min-height: ${p => p.$viewportHeight - sm.MARGIN * 2 - sm.PRIMARY_NAV - sm.SECONDARY_NAV}px;

    @media (min-width: ${md.BREAKPOINT}px) {
      min-height: ${p => p.$viewportHeight - sm.MARGIN * 2 - sm.SECONDARY_NAV}px;
    }
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    min-height: ${p => p.$minHeight}px;
    max-height: ${p => p.$maxHeight}px;

    grid-column-end: span ${p => getWidthSpan(p.$spanX)};
    grid-row-end: span ${p => getHeightSpan(p.$spanY)};
  }
`;

function getExpandedWidth(mq) {
  return function () {
    const offset = {
      sm: sm.MARGIN * 2,
      md: md.PRIMARY_NAV + md.MARGIN * 2,
      lg: lg.PRIMARY_NAV + lg.MARGIN * 2,
      xl: xl.PRIMARY_NAV + xl.MARGIN * 2,
    }[mq];

    return `calc(100vw - ${offset}px)`;
  };
}

function getExpandedHeight(mq) {
  function getTickerHeight(bool) {
    if (!bool) return 0;
    return {
      sm: sm.TICKER,
      md: md.TICKER,
      lg: lg.TICKER,
      xl: xl.TICKER,
    }[mq];
  }

  return function (props) {
    // const { $isRenderingTicker } = props;
    const ticker = getTickerHeight(props.$isRenderingTicker);

    const offset = {
      sm: sm.PRIMARY_NAV + sm.SECONDARY_NAV + sm.MARGIN * 2 + ticker,
      md: md.SECONDARY_NAV + md.MARGIN * 2 + ticker,
      lg: lg.SECONDARY_NAV + lg.MARGIN * 2 + ticker,
      xl: xl.SECONDARY_NAV + xl.MARGIN * 2 + ticker,
    }[mq];

    return `${window.innerHeight - offset}px`;
  };
}

const expandedFrame = css`
  z-index: 99;

  top: ${p => -p.$offset.top}px;
  left: ${p => -p.$offset.left}px;

  width: ${getExpandedWidth('sm')} !important;
  height: ${getExpandedHeight('sm')} !important;

  @media (min-width: ${md.BREAKPOINT}px) {
    width: ${getExpandedWidth('md')} !important;
  }

  @media (min-width: ${lg.BREAKPOINT}px) {
    width: ${getExpandedWidth('lg')} !important;
  }

  @media (min-width: ${xl.BREAKPOINT}px) {
    width: ${getExpandedWidth('xl')} !important;
  }

  @media (min-height: ${md.V_BREAKPOINT}px) {
    height: ${getExpandedHeight('md')} !important;
  }

  @media (min-height: ${lg.V_BREAKPOINT}px) {
    height: ${getExpandedHeight('lg')} !important;
  }

  @media (min-height: ${xl.V_BREAKPOINT}px) {
    height: ${getExpandedHeight('xl')} !important;
  }
`;

const disableScrollBar = css`
  & * {
    overflow: hidden !important;
  }
`;

function adaptSize(p) {
  return {
    style: {
      width: p.$width,
      height: p.$height,
    },
  };
}

const AdaptiveFrame = styled.div.attrs(adaptSize)`
  background-color: ${p => (p.$isExpanded ? '#141414' : 'inherit')};
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  overflow: hidden;

  transition-property: z-index, top, left, width, height;
  transition-duration: ${animation.TIMING}ms;
  transition-timing-function: ${animation.EASING};

  ${p => p.$isExpanded && expandedFrame};

  display: grid;
  gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  max-width: ${p => Number.isFinite(p.$maxWidth) && p.$maxWidth}px;
  max-height: ${p => Number.isFinite(p.$maxHeight) && p.$maxHeight}px;
`;

const PanelContent = styled.div`
  overflow: hidden;
  padding: 10px 18px;
  display: grid;

  ${p => p.isDownloading && disableScrollBar};
`;

function PanelComponent({
  className,
  width,
  height,
  targetRef,
  spanX,
  spanY,
  minHeight,
  maxHeight,
  title,
  isExpanded: isExpandedProp,
  expandable,
  downloadable,
  tutorial,
  controls,
  children,
  isLoading,
  onChangeExpanded,
  expandedWidth,
  expandedHeight,
  adaptiveFrameCss,
  controlBarCss,
  panelContentCss,
  onClickDownload,
  ...rest
}) {
  const { current: panelId } = useRef(uniqueId());
  const { expandedId, setExpandedId, expansionDisabled, gridRef, isRenderingTicker, contentWidth, contentHeight } =
    useContext(AppLayout.Context);
  const isExpanded = expandedId === panelId;
  const isCollapsed = Boolean(expandedId) && !isExpanded;
  const [offset, setOffset] = useState({});
  const [tutorialOpen, setTutorialOpen] = useState();
  const [isCapturingPreview, setIsCapturingPreview] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [titleOverride, setTitleOverride] = useState();
  const [controlsOverride, setControlsOverride] = useState();
  const { height: viewportHeight } = useViewportSize();

  const toggleExpanded = useCallback(
    function (event) {
      event.preventDefault();
      setExpandedId(isExpanded ? null : panelId);
      onChangeExpanded(!isExpanded);
    },
    [setExpandedId, isExpanded, panelId, onChangeExpanded]
  );

  const onTutorialOpen = useCallback(() => setTutorialOpen(true), [setTutorialOpen]);
  const onTutorialClose = useCallback(() => setTutorialOpen(false), [setTutorialOpen]);

  const [, cancelPrevExpandingStateUpdate, resetExpandingState] = useTimeoutFn(() => {
    setIsExpanding(false);
  }, animation.TIMING);

  useEffect(
    function () {
      if (!isExpanded) return;

      setIsExpanding(true);
      cancelPrevExpandingStateUpdate();
      resetExpandingState();
    },
    [isExpanded, setIsExpanding, cancelPrevExpandingStateUpdate, resetExpandingState]
  );

  useEffect(
    function () {
      if (!targetRef?.current) return;

      setOffset({
        top: targetRef.current.offsetTop,
        left: targetRef.current.offsetLeft,
      });
    },
    [targetRef, gridRef, width, height]
  );

  useEffect(
    function () {
      if (!isExpanded) return;
      window.scrollTo(0, 0);
    },
    [isExpanded]
  );

  useEffect(
    function () {
      setExpandedId(isExpandedProp ? panelId : null);
    },
    [isExpandedProp, panelId, setExpandedId]
  );

  useEffect(
    function () {
      onChangeExpanded(isExpanded);
    },
    [onChangeExpanded, isExpanded]
  );

  const shouldRenderContents = Boolean(width) && Boolean(height);

  return (
    <>
      <GlobalStyleOverrides $preventScroll={isExpanded} />
      <GridSpace
        {...rest}
        ref={targetRef}
        $spanX={spanX}
        $spanY={spanY}
        $minHeight={minHeight}
        $maxHeight={maxHeight}
        $viewportHeight={viewportHeight}
        $isExpanded={isExpanded}
        $isCollapsed={isCollapsed}
        className={className}
      >
        {shouldRenderContents && (
          <AdaptiveFrame
            $width={width}
            $height={height}
            $isExpanded={isExpanded}
            $isCollapsed={isCollapsed}
            $offset={offset}
            $isRenderingTicker={isRenderingTicker}
            $maxWidth={expandedWidth}
            $maxHeight={expandedHeight}
            css={adaptiveFrameCss}
          >
            <ControlBar
              css={controlBarCss}
              title={titleOverride ?? title}
              expandable={expandable && !isCollapsed && !expansionDisabled}
              onClickExpand={toggleExpanded}
              tutorial={tutorial}
              tutorialOpen={tutorialOpen}
              downloadable={downloadable}
              onClickDownload={onClickDownload ? onClickDownload : () => setIsCapturingPreview(true)}
              onTutorialOpen={onTutorialOpen}
              onTutorialClose={onTutorialClose}
              isExpanded={isExpanded}
              isDownloading={isCapturingPreview}
            >
              {controlsOverride || controls}
            </ControlBar>
            <PanelContent isDownloading={isCapturingPreview} css={panelContentCss}>
              <ErrorBoundary>
                <Context.Provider
                  value={{
                    panelId,
                    isExpanded,
                    isExpanding,
                    isCollapsed,
                    isCapturingPreview,
                    setTitle: setTitleOverride,
                    setControls: setControlsOverride,
                  }}
                >
                  <Loading isLoading={isLoading}>{children}</Loading>
                </Context.Provider>
              </ErrorBoundary>
            </PanelContent>
          </AdaptiveFrame>
        )}
      </GridSpace>

      {isCapturingPreview && (
        <ImageDownloadDialog
          fileName={title}
          open={isCapturingPreview}
          onClose={() => setIsCapturingPreview(false)}
          width={contentWidth}
          height={contentHeight}
        >
          {children}
        </ImageDownloadDialog>
      )}
    </>
  );
}

PanelComponent.defaultProps = {
  width: 0,
  height: 0,
  onChangeExpanded: () => {},
};

export default withResizeDetector(PanelComponent, {
  refreshMode: 'debounce',
  refreshRate: 100,
});
