import { useQuery, useQueries } from 'react-query';
import queryKeyFactory from '../queryKeyFactory';
import * as client from './client';

const queryKeys = queryKeyFactory(client.collection.id);

const defaultOptions = {
  enabled: true,
  refetchOnWindowFocus: false,
};

function useGetMany(fundManagerIds = [], { enabled: optEnabled, ...options } = {}) {
  const enabled = optEnabled === undefined ? fundManagerIds.length > 0 : optEnabled;

  return useQueries(
    fundManagerIds.map(id => {
      return {
        queryKey: queryKeys.detail(id),
        queryFn: async () => {
          return await client.get(id);
        },
        ...defaultOptions,
        ...options,
        enabled,
      };
    })
  );
}

function useGet(fundManagerId, { enabled: optEnabled, ...options } = {}) {
  const enabled = optEnabled === undefined ? Boolean(fundManagerId) : optEnabled;
  const queryKey = queryKeys.detail(fundManagerId);
  const queryFn = async () => await client.get(fundManagerId);
  return useQuery({
    queryKey,
    queryFn,
    ...defaultOptions,
    ...options,
    enabled,
  });
}

export { queryKeys, useGet, useGetMany };
