/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMedia } from 'react-use';
import { withTheme } from '@material-ui/core';
import { cloneDeep } from 'src/lib/lodash';
import { upperCase, currency, abbreviations, multiple, percent } from 'src/formatters';
import { sm } from 'src/config/layout';
import BaseVirtualTable from '../VirtualizedTable';
import { IconClose, IconPencil, FundBookmarkControl } from 'src/components';

const multiple2 = multiple(2);
const percent1 = percent();
const millions = currency(abbreviations.million)();

const listDefNarrow = [
  {
    width: 210,
    sortable: true,
    headerStyle: { padding: '0 12px 0 18px' },
    cellStyle: { padding: '0 12px 0 12px' },
    property: 'name',
    key: 'name',
    label: upperCase('fund name'),
    isNumeric: false,
    component: data => (
      <div
        css={`
          position: relative;
        `}
      >
        {data.userId && (
          <div
            css={`
              position: absolute;
              left: -6px;
              top: 5px;
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background-color: #e1b72a;
            `}
          />
        )}
        <span
          css={`
            padding-left: 6px;
          `}
        >
          {data.name}
        </span>
      </div>
    ),
  },
  {
    width: 40,
    key: 'edit',
    headerStyle: { textAlign: 'center', padding: '0 0 0 0' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 0',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => (
      <IconPencil
        data={data}
        css={`
          position: relative;
          top: 2px;
          cursor: pointer;
          &:hover {
            color: #fff;
          }
          width: 20px;
          height: 20px;
          color: #e1b72a;
          visibility: ${data => (data.data.userId ? 'visible' : 'hidden')};
        `}
      />
    ),
  },
  {
    width: 70,
    sortable: true,
    property: 'vintage',
    key: 'vintage',
    label: upperCase('vintage'),
    align: 'right',
    isNumeric: true,
    minDisplayWidth: sm.BREAKPOINT,
  },
  {
    width: 40,
    key: 'bookmark',
    headerStyle: { textAlign: 'center', padding: '0 0 0 12px' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 12px',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => {
      return <FundBookmarkControl fundId={data.fundId} title={data.name} />;
    },
  },
  {
    width: 30,
    key: 'remove',
    headerStyle: { textAlign: 'center', padding: '0 0 0 0' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 0',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => {
      if (!data.fundId.startsWith('u_')) return null;
      return (
        <IconClose
          data={data}
          css={`
            color: #afafaf;
            width: 10px;
            height: 10px;
            opacity: 0.2;
            position: relative;
            top: 2px;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          `}
        />
      );
    },
  },
];

const listDefWide = [
  {
    width: 210,
    sortable: true,
    headerStyle: { padding: '0 12px 0 18px' },
    cellStyle: { padding: '0 12px 0 12px' },
    property: 'name',
    key: 'name',
    label: upperCase('fund name'),
    isNumeric: false,
    component: data => (
      <div
        css={`
          position: relative;
        `}
      >
        {data.userId && (
          <div
            css={`
              position: absolute;
              left: -6px;
              top: 5px;
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background-color: #e1b72a;
            `}
          />
        )}
        <span
          css={`
            padding-left: 6px;
          `}
        >
          {data.name}
        </span>
      </div>
    ),
  },
  {
    width: 40,
    key: 'edit',
    headerStyle: { textAlign: 'center', padding: '0 0 0 0' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 0',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => {
      return (
        <IconPencil
          data={data}
          css={`
            position: relative;
            top: 2px;
            cursor: pointer;
            &:hover {
              color: #fff;
            }
            width: 20px;
            height: 20px;
            color: #e1b72a;
            visibility: ${data => (data.data.userId ? 'visible' : 'hidden')};
          `}
        />
      );
    },
  },
  {
    width: 70,
    sortable: true,
    property: 'vintage',
    key: 'vintage',
    label: upperCase('vintage'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 80,
    sortable: true,
    headerStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    cellStyle: { textAlign: 'right', padding: '0 12px 0 0' },
    property: 'size',
    key: 'size',
    formatter: millions,
    label: upperCase('fund size'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 50,
    sortable: true,
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    property: 'dpi',
    key: 'dpi',
    formatter: x => multiple2(x),
    label: upperCase('dpi'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 50,
    sortable: true,
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    property: 'rvpi',
    key: 'rvpi',
    formatter: x => multiple2(x),
    label: upperCase('rvpi'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 50,
    sortable: true,
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    property: 'irr',
    key: 'irr',
    formatter: x => percent1(x),
    label: upperCase('irr'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 50,
    sortable: true,
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    property: 'tvpi',
    key: 'tvpi',
    formatter: x => multiple2(x),
    label: upperCase('tvpi'),
    align: 'right',
    isNumeric: true,
  },
  {
    width: 40,
    key: 'bookmark',
    headerStyle: { textAlign: 'center', padding: '0 0 0 12px' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 12px',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => {
      return <FundBookmarkControl fundId={data.fundId} title={data.name} />;
    },
  },
  {
    width: 30,
    key: 'remove',
    headerStyle: { textAlign: 'center', padding: '0 0 0 0' },
    cellStyle: {
      textAlign: 'center',
      padding: '0 0 0 0',
      cursor: 'pointer',
    },
    align: 'right',
    component: data => {
      if (!data.fundId.startsWith('u_')) return null;
      return (
        <IconClose
          data={data}
          css={`
            color: #afafaf;
            width: 10px;
            height: 10px;
            opacity: 0.2;
            position: relative;
            top: 2px;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          `}
        />
      );
    },
  },
];

const VirtualizedTable = styled(BaseVirtualTable)`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  padding: 10px;
`;

function BookmarkedFundsTable({
  data,
  selectedId,
  wideTable,
  enableHover,
  listDefNarrow,
  listDefWide,
  rowHoverColor,
  rowHoverBackgroundColor,
  onListDefCreated,
  onColumnClick,
  onRowClick,
  ...rest
}) {
  const isWideScreen = useMedia(`(min-width: ${sm.BREAKPOINT}px)`);
  const [listDef, setListDef] = useState(null);

  useEffect(() => {
    const listDefClone = cloneDeep(wideTable && isWideScreen ? listDefWide : listDefNarrow);
    // give the parent component a chance to update the listDef
    onListDefCreated(listDefClone);
    setListDef(listDefClone);
  }, [onListDefCreated, listDefNarrow, listDefWide, wideTable, isWideScreen]);

  function handleColumnClick(e, { column, data, index }) {
    onColumnClick(e, { column, data, index });
  }

  function getRowBackgroundColor(item) {
    return item.fundId === selectedId ? '#0d8bab' : undefined;
  }

  function getRowColor(item) {
    return item.fundId === selectedId ? '#fff' : '#afafaf';
  }

  function handleRowClick(e, { data, index }) {
    onRowClick(e, { data, index });
  }

  return (
    <VirtualizedTable
      {...rest}
      data={data}
      listDef={listDef}
      onColumnClick={handleColumnClick}
      onRowClick={handleRowClick}
      enableHover={enableHover}
      rowColor={getRowColor}
      rowBackgroundColor={getRowBackgroundColor}
      rowHoverColor={rowHoverColor}
      rowHoverBackgroundColor={rowHoverBackgroundColor}
    />
  );
}

BookmarkedFundsTable.defaultProps = {
  selectedId: null,
  expanded: false,
  wideTable: false,
  components: {},
  enableHover: false,
  listDefNarrow,
  listDefWide,
  rowColor: null,
  rowBackgroundColor: null,
  rowHoverColor: '#fff',
  rowHoverBackgroundColor: '#0a5f75',
  onListDefCreated: () => {},
  onColumnClick: () => {},
  onRowClick: () => {},
};

BookmarkedFundsTable.propTypes = {
  data: PropTypes.array.isRequired,
  selectedId: PropTypes.string,
  components: PropTypes.object,
  theme: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  wideTable: PropTypes.bool,
  enableHover: PropTypes.bool,
  listDefNarrow: PropTypes.array,
  listDefWide: PropTypes.array,
  rowColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowHoverBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onListDefCreated: PropTypes.func,
  onColumnClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default withTheme(BookmarkedFundsTable);
