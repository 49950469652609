import React from 'react';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';
import IconContent from '../IconContent';

const StyledButtonRounded = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 3px;
    left: 2px;
  }
`;

const ButtonContent = props => {
  return (
    <StyledButtonRounded startIcon={<IconContent />} {...props}>
      Content
    </StyledButtonRounded>
  );
};

ButtonContent.defaultProps = {};

ButtonContent.propTypes = {};

export default ButtonContent;
