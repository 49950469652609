import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { md } from 'src/config/layout';
import { useCurrentUser } from 'src/hooks';
import { AppLayout, ErrorBoundary } from 'src/components';
import UserProfileForm from './UserProfileForm';
import UserPasswordForm from './UserPasswordForm';
import UserDeleteForm from './UserDeleteForm';
import SubscriptionPlans from './SubscriptionPlans';
import PaymentMethods from './PaymentMethods';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CreditCardForm from './CreditCardForm';
import ConvertAnonymousForm from './ConvertAnonymousForm';
import routes from 'src/config/routes';
import { navigate } from '@reach/router';
import { useGet as useGetAuth } from 'src/services/filter-api/authentication';
import Badge from 'src/components/atoms/Badge';
import Typography from 'src/components/atoms/Typography';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Panel = styled.section`
  background-color: #262626;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  @media (min-width: ${md.BREAKPOINT}px) {
    grid-column-end: span 2;
    grid-row-end: span 6;
  }
`;

const Layout = styled.div`
  color: #d0d0d0;
  font-size: 14px;
  font-weight: 300;

  @media (min-width: ${md.BREAKPOINT}px) {
    display: flex;
    padding: 28px 0;
  }
`;

const Section = styled.section`
  padding: 20px;
  border-radius: 6px;

  section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5em;
  }

  & > form + form,
  & > section + section {
    margin-top: 3em;
  }

  section h3 {
    margin: 0;
  }

  section header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  section footer {
    display: grid;
    gap: 0.5em;
    grid-auto-flow: column;
    justify-content: start;
  }

  @media (min-width: ${md.BREAKPOINT}px) {
    padding: 36px;
    flex: 1;
  }
`;

const SectionTitle = styled.h1`
  /* color: #4d4d4d; */
  font-size: 28px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 14px;

  line-height: 1;
`;

function AccountPage() {
  const { isAnonymousExpired } = useGetAuth();
  const currentUser = useCurrentUser();
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [anonymousDialogOpen, setAnonymousDialogOpen] = useState(false);

  useEffect(() => {
    if (!currentUser?.isAnonymous) return;
    setAnonymousDialogOpen(true);
  }, [currentUser]);

  return (
    <Elements stripe={stripePromise}>
      <Dialog
        open={Boolean(anonymousDialogOpen)}
        onClose={(_evt, reason) => {
          if (reason === 'backdropClick') return;
          setPaymentDialogOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <Badge color="secondary" variant="dot" invisible={!currentUser?.isAnonymous}>
            Complete your account setup
          </Badge>
        </DialogTitle>
        <DialogContent>
          {currentUser && (
            <div
              css={`
                section {
                  display: grid;
                  grid-template-columns: 2fr 1fr;
                  gap: 1.5em;
                  padding-bottom: 1.5rem;
                }
              `}
            >
              <>
                <section>
                  <Typography color="secondary">
                    Your guest account access {isAnonymousExpired ? 'has expired' : 'will expire soon'}. Add a password
                    to your account to continue using FundFilter. We&apos;ll ask you to verify your email address it in
                    the next step.
                  </Typography>
                  <span />
                </section>
                <span />
              </>
              <ConvertAnonymousForm
                user={currentUser}
                onCancel={
                  isAnonymousExpired
                    ? undefined
                    : () => {
                        setAnonymousDialogOpen(false);
                        navigate(routes.MARKET_FILTER);
                      }
                }
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={paymentDialogOpen}
        onClose={(_evt, reason) => {
          if (reason === 'backdropClick') return;
          setPaymentDialogOpen(false);
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Add A Payment Method</DialogTitle>
        <DialogContent>
          <CreditCardForm onCancel={() => setPaymentDialogOpen(false)} onSuccess={() => setPaymentDialogOpen(false)} />
        </DialogContent>
      </Dialog>
      <AppLayout>
        <Panel>
          <Layout>
            {currentUser && (
              <Section>
                <header>
                  <SectionTitle>Profile</SectionTitle>
                </header>

                <UserProfileForm user={currentUser} />
                <UserPasswordForm user={currentUser} />
                <UserDeleteForm user={currentUser} />
              </Section>
            )}

            <Section>
              <header>
                <SectionTitle>Plans &amp; Payment</SectionTitle>
              </header>
              <ErrorBoundary>
                <SubscriptionPlans />
              </ErrorBoundary>
              <ErrorBoundary>
                <PaymentMethods
                  onClickNew={() => setPaymentDialogOpen(true)}
                  onClickCancel={() => setPaymentDialogOpen(false)}
                />
              </ErrorBoundary>
            </Section>
          </Layout>
        </Panel>
      </AppLayout>
    </Elements>
  );
}

export default AccountPage;
