import { useMemo } from 'react';
import useFilterSelectedIds from './useFilterSelectedIds';

export default function useFilteredFunds({ funds, strategies, vintages, regions, secondRegions, sizes }) {
  const selectedStrategies = useFilterSelectedIds(strategies, 'strategyId');
  const selectedRegions = useFilterSelectedIds(regions, 'regionId');
  const selectedSecondRegions = useFilterSelectedIds(secondRegions, 'regionId');
  const selectedSizes = useFilterSelectedIds(sizes, 'sizeId');
  const selectedVintages = useFilterSelectedIds(vintages, 'vintageId');

  return useMemo(
    () =>
      funds
        ?.filter(f => !strategies || selectedStrategies.includes(f.subStrategyId))
        ?.filter(f => !vintages || selectedVintages.includes(f.vintage))
        ?.filter(f => !sizes || selectedSizes.includes(f.sizeId))
        ?.filter(f => !regions || selectedRegions.includes(f.mainRegionId))
        ?.filter(
          f =>
            !secondRegions ||
            [
              selectedSecondRegions.length === 0,
              selectedSecondRegions.includes(f.otherRegionId ?? f.mainRegionId),
              selectedSecondRegions.includes(f.mainRegionId),
            ].some(Boolean)
        ) ?? [],
    [
      funds,
      strategies,
      vintages,
      sizes,
      regions,
      secondRegions,
      selectedStrategies,
      selectedVintages,
      selectedRegions,
      selectedSecondRegions,
      selectedSizes,
    ]
  );
}
