import { customAlphabet } from 'nanoid';

const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

function nanoid(size = 20) {
  return customAlphabet(ALPHABET, size)();
}

export { nanoid };
export default nanoid;
