import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.span`
  display: flex;
`;

const HeadText = styled.span`
  flex: 0 1 auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TailText = styled.span`
  flex: 0 0 auto;
`;

function Truncator({ placeholder, value, keepLast = 0 }) {
  if (!value) return placeholder;
  const head = value.slice(0, value.length - keepLast);
  const tail = value.slice(value.length - keepLast);

  return (
    <Layout>
      <HeadText>{head}</HeadText>
      {tail && <TailText>{tail}</TailText>}
    </Layout>
  );
}

Truncator.defaultProps = {
  placeholder: '-',
};

Truncator.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
};

export default Truncator;
