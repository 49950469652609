import axios from 'axios';

// TODO: use a cloud function as a proxy
const apiUrl = 'https://api.agolo.com/nlp/v0.2/summarize';
const apiKey = 'f3d6f1a0409d449d8a3f2f253bf5cb90';

async function summarize({ textBlocks, articleUrls, summaryType, summaryLength }) {
  const payload = {
    summary_length: summaryLength,
  };

  if (summaryType === 1) {
    payload.articles = textBlocks.map(text => ({ text }));
  }

  if (summaryType === 2) {
    payload.articles = articleUrls.map(url => ({ url }));
  }

  const options = {
    headers: { 'content-type': 'application/json', 'Ocp-Apim-Subscription-Key': apiKey },
  };
  const summary = await axios.post(apiUrl, payload, options);
  return summary.data;
}

export const agoloSummarizer = { summarize };
