import React from 'react';
import styled from 'styled-components';
import IconRadarChart from '../IconRadarChart';
import ButtonRounded from '../ButtonRounded';

const StyledButtonRounded = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 2px;
    left: 1px;
  }
`;

const ButtonRadar = props => {
  return (
    <StyledButtonRounded startIcon={<IconRadarChart />} {...props}>
      Radar
    </StyledButtonRounded>
  );
};

ButtonRadar.defaultProps = {};

ButtonRadar.propTypes = {};

export default ButtonRadar;
