import { ViolatesPresenceError, ViolatesNumericalError } from 'src/services/filter-api/errors';

async function validatesPresence(fields) {
  if (Object.values(fields).every(Boolean)) return true;
  throw new ViolatesPresenceError(fields);
}

function validatesAsNumberical(fields) {
  if (Object.values(fields).every(Number.isFinite)) return true;
  throw new ViolatesNumericalError(fields);
}

export { validatesPresence, validatesAsNumberical };
