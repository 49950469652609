import { useQueryClient, useMutation } from 'react-query';
import { queryKeys } from './queries';
import * as client from './client';

function useCreate() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async data => {
    const targetFundId = await client.create(data);
    return { targetFundId, ...data };
  };
  return useMutation({
    mutationFn: async data => await mutationFn(data),
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useUpdate() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async ({ targetFundId, ...data }) => {
    const docId = await client.update(targetFundId, data);
    return docId;
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

function useDelete() {
  const queryClient = useQueryClient();
  const queryKey = queryKeys.all();
  const mutationFn = async targetFundId => {
    try {
      return await client.destroy(targetFundId);
    } catch (error) {
      if (error.code === 'permission-denied') return;
      throw error;
    }
  };
  return useMutation({
    mutationFn,
    onMutate() {},
    onSettled() {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

export { useCreate, useUpdate, useDelete };
