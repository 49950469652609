import React from 'react';
import { AppLayout, AppPanel, DashboardTutorials } from 'src/components';

function TutorialsPage() {
  return (
    <AppLayout>
      <AppPanel spanX="full" spanY="full" title="Tutorials">
        <DashboardTutorials />
      </AppPanel>
    </AppLayout>
  );
}

export default TutorialsPage;
