import React from 'react';
import { SvgIcon } from '@material-ui/core';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  width: ${props => props.width};
  height: ${props => props.height};
`;

export default function IconInfo(props) {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <path d="M50,4.8c-24.9,0-45,20.1-45,45c0,24.9,20.1,45,45,45s45-20.1,45-45C95,24.9,74.9,4.8,50,4.8z M44.3,25.2h9.7v8.2h-9.7V25.2z   M59.7,73.5H40.2v-5.7h5.3V44.9h-5.3v-5.7h14.2v28.6h5.3V73.5z" />
    </Svg>
  );
}
