import React from 'react';
import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { nanoid } from 'src/utils/nanoid';
import { Select as MuiSelect, MenuItem, InputLabel, FormControl } from '@material-ui/core';

const enhancedSelectCss = css`
  margin: 0;
  font-size: 1em;

  fieldset {
    border-radius: 0;
  }
`;

function EnhancedSelect({
  className,
  items,
  label,
  value,
  onChange,
  renderValue,
  renderItemKey,
  renderItemValue,
  renderItemLabel,
  itemDisabled,
  disabled,
}) {
  const { current: id } = useRef(nanoid());

  const labelId = `${id}-label`;

  const renderItem = useCallback(
    function (item) {
      const itemKey = renderItemKey ? renderItemKey(item) : item;
      const itemValue = renderItemValue ? renderItemValue(item) : item;
      const itemLabel = renderItemLabel ? renderItemLabel(item) : item;

      return (
        <MenuItem key={itemKey} value={itemValue} disabled={itemDisabled(item)}>
          {itemLabel}
        </MenuItem>
      );
    },
    [renderItemKey, renderItemValue, renderItemLabel, itemDisabled]
  );

  return (
    <FormControl className={className} variant="outlined" margin="dense" css={enhancedSelectCss} disabled={disabled}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect labelId={labelId} id={id} value={value} renderValue={renderValue} onChange={onChange}>
        {items.map(renderItem)}
      </MuiSelect>
    </FormControl>
  );
}

EnhancedSelect.defaultProps = {
  itemDisabled: () => false,
};

EnhancedSelect.propTypes = {
  itemDisabled: PropTypes.func,
};

export default EnhancedSelect;
