import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from 'src/config/colors';
import { filterApi } from 'src/services';
import { useCurrentUser, useDefaultPaymentMethod } from 'src/hooks';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import FormSubmitButton from './FormSubmitButton';
import FormCancelButton from './FormCancelButton';

const CreditCardFields = styled.div`
  padding: 1.5rem 0;
`;

const StripeCardElement = styled(CardElement)`
  padding: 0.75em 0.5em;
  border: 1px solid #fff5;
  color: white;

  &.StripeElement--webkit-autofill {
    background-color: transparent !important;
  }
`;

const stripeElementOptions = {
  style: {
    base: {
      iconColor: '#fff',
      color: '#fff',
      fontWeight: '400',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fff',
        backgroundColor: 'transparent',
      },
      '::placeholder': {
        color: '#fff5',
      },
    },
    invalid: {
      iconColor: colors.message.error.TEXT_COLOR,
      color: colors.message.error.TEXT_COLOR,
    },
  },
};

const Footer = styled.footer`
  padding: 1.5rem 0;
`;

function CreditCardForm({ onComplete, onChange, onError, onCancel, onSuccess, errorMessage }) {
  const stripe = useStripe();
  const elements = useElements();
  const { stripeCustomerId: customerId } = useCurrentUser();

  const { refetch: refetchDefaultPaymentMethod } = useDefaultPaymentMethod();
  const [fields, setFields] = useState({
    error: null,
    complete: false,
  });
  const [isProcessing, setIsProcessing] = useState(false);

  function handleChange(event) {
    setFields(event);
    onChange(event, elements.getElement(CardElement));
    onComplete(event.complete, elements.getElement(CardElement));
    onError(event.error);
  }

  function handleClickCancel() {
    setFields({});
    onCancel();
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!stripe) return;
    if (!elements) return;

    setIsProcessing(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    try {
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) throw error;

      await filterApi.stripeAttachPaymentMethod({
        paymentMethodId: paymentMethod.id,
        customerId,
      });

      await refetchDefaultPaymentMethod();
      onSuccess();
    } catch (error) {
      console.error(error);
      setFields({ error: error });
    }

    setIsProcessing(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <CreditCardFields>
        <StripeCardElement
          onChange={handleChange}
          onReady={element => element.focus()}
          options={stripeElementOptions}
        />

        {(errorMessage || fields.error) && (
          <p
            role="alert"
            css={`
              color: ${colors.message.error.TEXT_COLOR};
            `}
          >
            {errorMessage || fields.error?.message}
          </p>
        )}
      </CreditCardFields>
      <Footer>
        <FormSubmitButton
          type="submit"
          disabled={!fields.complete || fields.error || isProcessing}
          $isLoading={isProcessing}
        >
          Submit
        </FormSubmitButton>
        <FormCancelButton onClick={handleClickCancel} />
      </Footer>
    </form>
  );
}

CreditCardForm.defaultProps = {
  onComplete: () => {},
  onError: () => {},
  onChange: () => {},
  onCancel: () => {},
  onSuccess: () => {},
};

CreditCardForm.propTypes = {
  errorMessage: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CreditCardForm;
