import React from 'react';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Folder as IconFolderClosed, FolderOpen as IconFolderOpen } from '@material-ui/icons';
import { Button, TextField } from 'src/components';
import ListItem from './ListItem';

const Layout = styled.div``;

const Header = styled.header`
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #ffffff22;

  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0 1em;
`;

const List = styled.ul`
  max-height: ${2.285714286 * 5.5}em;
  overflow-y: auto;
  list-style: none;
  padding: 0;
`;

const Footer = styled.footer`
  padding: 0.75em 1em 0.75em 3em;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const SearchFilterInput = styled(TextField)`
  .MuiInputBase-root {
    font-size: inherit;

    input {
      padding: 0.5625em 1em;
    }
  }
`;

function SelectPortfolioControlList({
  items,
  selectedItemId,
  itemSearchKey,
  onClickItem,
  onClickCreateItem,
  onClickUpdateItem,
  onClickDeleteItem,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  const searchIsUnique = useMemo(() => {
    if (!searchQuery) return false;
    if (!items) return true;
    return items.every(item => item[itemSearchKey].toLowerCase() !== searchQuery.toLowerCase());
  }, [itemSearchKey, items, searchQuery]);

  const filteredItems = useMemo(() => {
    if (!items) return [];
    if (!searchQuery) return items;

    return items.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [items, searchQuery]);

  return (
    <Layout>
      <Header>
        <h3>Select A Portfolio</h3>
      </Header>

      <List>
        {filteredItems.map(item => {
          const { name, portfolioId } = item;
          const isSelected = selectedItemId === portfolioId;
          const isLocked = item.default;

          return (
            <ListItem
              key={portfolioId}
              value={portfolioId}
              label={name}
              selected={isSelected}
              locked={isLocked}
              icon={<IconFolderOpen />}
              selectedIcon={<IconFolderClosed />}
              onSelect={() => onClickItem(portfolioId)}
              onSave={data => onClickUpdateItem(portfolioId, data)}
              onDelete={() => onClickDeleteItem(portfolioId)}
            />
          );
        })}
      </List>

      <Footer>
        <SearchFilterInput
          value={searchQuery}
          onChange={evt => setSearchQuery(evt.target.value)}
          placeholder="Search or Create Portfolio"
        />
        <Button
          disabled={!searchIsUnique}
          onClick={async function () {
            if (!searchIsUnique) return;
            await onClickCreateItem(searchQuery);
            setSearchQuery('');
          }}
        >
          Create
        </Button>
      </Footer>
    </Layout>
  );
}

SelectPortfolioControlList.defaultProps = {
  onClickItem: () => {},
  onClickCreateItem: () => {},
  itemSearchKey: 'name',
};

SelectPortfolioControlList.propTypes = {
  children: PropTypes.any,
};

export default SelectPortfolioControlList;
