import React from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFund, useFundManager } from 'src/hooks';
import { AppPanel, FundFundManagerSummaryVisualization } from 'src/components';

function FundManagerSummaryPanel({ spanX, spanY, fundId, minHeight }) {
  const fund = useFund({ fundId }) || {};
  const fundManager =
    useFundManager({
      fundManagerId: fund ? fund.fundManagerId : null,
    }) || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const title = useMemo(() => {
    if (isExpanded) return `${fundManager.name} - News`;
    return 'Fund Manager';
  }, [fundManager.name, isExpanded]);

  return (
    <AppPanel
      spanX={spanX}
      spanY={spanY}
      minHeight={minHeight}
      title={title}
      onChangeExpanded={setIsExpanded}
      expandable
    >
      <FundFundManagerSummaryVisualization isLoading={fundManager.fetching} fundManager={fundManager} />
    </AppPanel>
  );
}

FundManagerSummaryPanel.defaultProps = {};

FundManagerSummaryPanel.propTypes = {
  children: PropTypes.any,
};

export default FundManagerSummaryPanel;
