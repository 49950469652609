import paco from 'pako';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState == null) {
      return undefined;
    }
    return JSON.parse(paco.inflate(serializedState, { to: 'string' }));
  } catch (err) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    const deflated = paco.deflate(serializedState, { to: 'string' });
    localStorage.setItem('state', deflated);
  } catch {
    // ignore write errors
  }
};

export const stateStorage = {
  loadState,
  saveState,
};
