import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundsInMarketCounts } from '../store/funds-in-market-counts';

export default function useFundsInMarketCounts() {
  const dispatch = useDispatch();
  const fundsInMarketCounts = useSelector(state => state.fundsInMarket.counts);
  const [counts, setCounts] = useState(null);

  useEffect(() => {
    if (!fundsInMarketCounts.fetched && !fundsInMarketCounts.fetching) {
      dispatch(fetchFundsInMarketCounts());
    }
  }, [dispatch, fundsInMarketCounts.fetched, fundsInMarketCounts.fetching]);

  useEffect(() => {
    if (fundsInMarketCounts.fetched) {
      setCounts(fundsInMarketCounts.all);
    }
  }, [fundsInMarketCounts.all, fundsInMarketCounts.fetched]);

  return counts;
}
