import { useState } from 'react';

const fullScreenCapableBrowser = [
  document.documentElement.requestFullScreen,
  document.documentElement.mozRequestFullScreen,
  document.documentElement.webkitRequestFullScreen,
].some(n => typeof n === 'function');

function getFullScreenState() {
  return [document.fullScreenElement, document.mozFullScreen, document.webkitIsFullScreen].some(Boolean);
}

const enterFullScreen = function () {
  const { requestFullScreen, mozRequestFullScreen, webkitRequestFullScreen } = document.documentElement;

  if (requestFullScreen) return document.documentElement.requestFullScreen();
  if (mozRequestFullScreen) return document.documentElement.mozRequestFullScreen();
  if (webkitRequestFullScreen) return document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
};

const exitFullScreen = function () {
  const { cancelFullScreen, mozCancelFullScreen, webkitCancelFullScreen } = document;

  if (cancelFullScreen) return document.cancelFullScreen();
  if (mozCancelFullScreen) return document.mozCancelFullScreen();
  if (webkitCancelFullScreen) return document.webkitCancelFullScreen();
};

function useBrowserFullScreen() {
  const [fullScreen, setFullScreen] = useState(getFullScreenState());

  if (!fullScreenCapableBrowser) return [false, undefined];

  const toggleFullScreen = function () {
    const currentlyFullScreen = getFullScreenState();
    currentlyFullScreen ? exitFullScreen() : enterFullScreen();
    setFullScreen(!currentlyFullScreen);
  };

  return [fullScreen, toggleFullScreen];
}

export default useBrowserFullScreen;
