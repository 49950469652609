/* eslint-disable no-use-before-define */
//#region imports
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResizeDetector from 'react-resize-detector';
import { select, axisBottom, axisLeft, curveMonotoneX, area, line, scaleLinear, scaleBand, easeCubicInOut } from 'd3';
import { uniqueId } from '../../../utils';
import { Box } from '../..';
import { useMouseEventRefs } from '../../../hooks';
//#endregion

//#region constants
const transitionDuration = 500;
const legendRectWidthHeight = 12;
const legendLabelPad = 5;
const legendItemWidth = 120;
const showCounts = false;
const joinerColor = 'rgba(6, 195, 243, 0.41)';
const leaverColor = '#ff5c00';
//#endregion

//#region styles
const Container = styled(Box)`
  height: ${props => props.height || '100%'};
`;
//#endregion

function create({ id, margin }) {
  const root = select(`#${id}`)
    .append('g')
    .attr('class', 'root')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  root.append('g').attr('class', 'x-axis').style('shape-rendering', 'crispEdges');

  root.append('g').attr('class', 'y-axis').style('shape-rendering', 'crispEdges');

  // add X axis label
  root
    .append('text')
    .attr('class', 'x-axis-label')
    .style('fill', '#505050')
    .style('text-align', 'center')
    .style('text-anchor', 'middle')
    .style('font-size', '9px')
    .style('font-family', 'Roboto')
    .text('SENIOR HEADCOUNT');

  root
    .append('line')
    .attr('class', 'x-axis-line')
    .style('color', '#2e2e2e')
    .attr('stroke', '#fff')
    .attr('opacity', 0.1)
    .attr('stroke-width', 1);

  const legend = root.append('g').attr('class', 'legend');

  legend
    .append('rect')
    .attr('class', 'leaver-rect')
    .attr('width', legendRectWidthHeight)
    .attr('height', legendRectWidthHeight)
    .attr('fill', leaverColor);

  legend
    .append('text')
    .attr('class', 'leaver-text')
    .attr('fill', leaverColor)
    .attr('x', legendRectWidthHeight + legendLabelPad)
    .attr('y', '1.1em')
    .attr('font-size', '9px')
    .text('SENIOR LEAVER');

  legend
    .append('rect')
    .attr('x', legendItemWidth)
    .attr('class', 'joiner-rect')
    .attr('width', legendRectWidthHeight)
    .attr('height', legendRectWidthHeight)
    .attr('fill', joinerColor);

  legend
    .append('text')
    .attr('class', 'joiner-text')
    .attr('fill', joinerColor)
    .attr('x', legendItemWidth + legendRectWidthHeight + legendLabelPad)
    .attr('y', '1.1em')
    .attr('font-size', '9px')
    .text('SENIOR JOINER');

  return root;
}

//#endregion

function draw({ id, joinersAndLeavers, size, margin, expanded, mouseEventRefs }) {
  //#region create the root object
  let root = select(`#${id} .root`);

  if (!root.node()) {
    root = create({ id, margin });
  }
  //#endregion

  //#region set the dimensions and margins of the graph
  const svgWidth = size.width - margin.left - margin.right;
  const svgHeight = size.height - margin.top - margin.bottom;
  const plotBaseline = svgHeight * (expanded ? 0.75 : 0.66);
  const areaTopPad = expanded ? 60 : 30;
  //#endregion

  //#region create the transition
  const t = root.transition().duration(transitionDuration).ease(easeCubicInOut);
  //#endregion

  //#region transform the data to something we can use
  const dataByYear = Object.keys(joinersAndLeavers).map(year => ({
    year,
    joiners: joinersAndLeavers[year].joiners,
    leavers: joinersAndLeavers[year].leavers,
    all: joinersAndLeavers[year].all,
  }));
  //#endregion

  //#region y-axis

  // create the y-scale
  const y = scaleLinear()
    .domain([
      0,
      Math.max(...dataByYear.map(item => item.joiners.length), ...dataByYear.map(item => item.leavers.length)),
    ])
    .range([0, svgHeight - plotBaseline]);

  // used to position the "SENIOR HEADCOUNT" label
  const maxY = Math.max(...dataByYear.map(item => item.all.length));
  const xForMaxY = dataByYear.find(item => item.all.length === maxY).year;

  const yAll = scaleLinear().domain([0, maxY]).range([plotBaseline, areaTopPad]);

  // add the X axis
  const yAxis = axisLeft(yAll).ticks(4).tickSize(0);

  root
    .select('.y-axis')
    .attr('transform', `translate(${margin.left / 3},0)`)
    .transition(t)
    .attr('opacity', expanded ? 1 : 0)
    .call(yAxis)
    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', '#808080'))
    .call(g => g.selectAll('.domain').attr('opacity', 0));

  //#endregion

  //#region x-axis

  // create the X axis
  const x = scaleBand().domain(Object.keys(joinersAndLeavers)).range([0, svgWidth]).padding(0.5);

  // add the X axis
  const xAxis = axisBottom(x).tickSize(expanded ? -svgHeight + 50 : -svgHeight);

  // position the x-axis
  root
    .select('.x-axis')
    .attr('transform', `translate(0,${svgHeight})`)
    .transition(t)
    .call(xAxis)
    .call(g => g.selectAll('line').attr('stroke', '#2e2e2e'))
    .call(g => g.selectAll('text').attr('fill', '#808080'))
    .call(g => g.selectAll('.domain').attr('opacity', 0));

  // position the x-axis label
  root
    .select('.x-axis-label')
    .transition(t)
    .attr('x', x(xForMaxY))
    .attr('y', yAll(maxY) - 10);

  // position the x-axis-line
  root
    .select('.x-axis-line')
    .transition(t)
    .attr('x1', margin.left / 3)
    .attr('x2', svgWidth + margin.left / 3 - margin.right / 3)
    .attr('y1', plotBaseline)
    .attr('y2', plotBaseline);

  //#endregion

  //#region position the legend
  root
    .select('.legend')
    .transition(t)
    .attr(
      'transform',
      `translate(${svgWidth / 2 - legendItemWidth + legendLabelPad + legendRectWidthHeight},${
        svgHeight + margin.bottom / 2
      })`
    );
  //#endregion

  //#region draw the area plot

  const all = root.selectAll('.area').data([dataByYear], d => d.year);

  all.join(
    enter =>
      enter
        .append('path')
        .attr('class', 'area')
        .attr('fill-opacity', 0)
        .attr('fill', '#fff')
        .attr(
          'd',
          area()
            .curve(curveMonotoneX)
            .x(d => x(d.year) + x.bandwidth() / 2)
            .y0(plotBaseline)
            .y1(plotBaseline)
        )
        .call(e =>
          e
            .transition(t)
            .attr('fill-opacity', 0.05)
            //.attr('stroke-opacity', 0.15)
            .attr(
              'd',
              area()
                .curve(curveMonotoneX)
                .x(d => x(d.year) + x.bandwidth() / 2)
                .y0(d => yAll(d.all.length))
                .y1(plotBaseline)
            )
        ),
    update =>
      update.call(u =>
        u.transition(t).attr(
          'd',
          area()
            .curve(curveMonotoneX)
            .x(d => x(d.year) + x.bandwidth() / 2)
            .y0(d => yAll(d.all.length))
            .y1(plotBaseline)
        )
      ),
    exit => exit.call(ex => ex.transition(t).remove())
  );
  //#endregion

  //#region add the line at the top of the area

  const allLine = root.selectAll('.line').data([dataByYear], d => d.year);

  allLine.join(
    enter =>
      enter
        .append('path')
        .attr('class', 'line')
        .attr('fill', 'none')
        .attr('stroke', '#fff')
        .attr('opacity', 0.1)
        .attr('stroke-width', 1)
        .attr(
          'd',
          line()
            .curve(curveMonotoneX)
            .x(d => x(d.year) + x.bandwidth() / 2)
            .y(plotBaseline)
        )
        .call(e =>
          e.transition(t).attr(
            'd',
            line()
              .curve(curveMonotoneX)
              .x(d => x(d.year) + x.bandwidth() / 2)
              .y(d => yAll(d.all.length))
          )
        ),
    update =>
      update.call(u =>
        u.transition(t).attr(
          'd',
          line()
            .curve(curveMonotoneX)
            .x(d => x(d.year) + x.bandwidth() / 2)
            .y(d => yAll(d.all.length))
        )
      ),
    exit => exit.call(ex => ex.transition(t).remove())
  );

  //#endregion

  //#region draw the plot points
  const allPoints = root.selectAll('.point').data(dataByYear, d => d.year);

  allPoints.join(
    enter =>
      enter
        .append('circle')
        .attr('class', 'point')
        .attr('cx', d => x(d.year) + x.bandwidth() / 2)
        .attr('cy', 0)
        .attr('r', 4)
        .attr('opacity', 0)
        .attr('stroke', 'none')
        .attr('fill', '#fff')
        .call(e =>
          e
            .transition(t)
            .attr('opacity', 0.1)
            .attr('cy', d => yAll(d.all.length))
        ),
    update =>
      update.call(u =>
        u
          .transition(t)
          .attr('cx', d => x(d.year) + x.bandwidth() / 2)
          .attr('cy', d => yAll(d.all.length))
      ),
    exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('cy', 0).remove())
  );

  //#endregion

  //#region add the joiners bars
  const joiners = root.selectAll('.joiner').data(dataByYear, d => d.year);

  joiners.join(
    enter =>
      enter
        .append('rect')
        .attr('class', 'joiner')
        .attr('height', 0)
        .attr('x', d => x(d.year))
        .attr('width', x.bandwidth())
        .attr('fill', joinerColor)
        .on('mouseover', handleJoinersMouseOver)
        .on('mouseout', handleJoinersMouseOut)
        .on('click', handleJoinersClick)
        .on('touchstart', handleJoinersTouchStart)
        .on('touchend', handleJoinersTouchEnd)
        .call(e =>
          e
            .transition(t)
            .attr('x', d => x(d.year))
            .attr('width', x.bandwidth())
            .attr('y', d => plotBaseline - y(d.joiners.length))
            .attr('height', d => y(d.joiners.length))
        ),
    update =>
      update.call(u =>
        u
          .on('mouseover', handleJoinersMouseOver)
          .on('mouseout', handleJoinersMouseOut)
          .on('click', handleJoinersClick)
          .on('touchstart', handleJoinersTouchStart)
          .on('touchend', handleJoinersTouchEnd)
          .transition(t)
          .attr('x', d => x(d.year))
          .attr('width', x.bandwidth())
          .attr('y', d => plotBaseline - y(d.joiners.length))
          .attr('height', d => y(d.joiners.length))
      ),
    exit => exit.call(ex => ex.transition(t).attr('height', 0).attr('y', plotBaseline).remove())
  );

  //#endregion

  //#region add the leavers bars
  const leavers = root.selectAll('.leaver').data(dataByYear, d => d.year);

  leavers.join(
    enter =>
      enter
        .append('rect')
        .attr('class', 'leaver')
        .attr('fill', leaverColor)
        .attr('x', d => x(d.year))
        .attr('width', x.bandwidth())
        .on('mouseover', handleLeaversMouseOver)
        .on('mouseout', handleLeaversMouseOut)
        .on('click', handleLeaversClick)
        .on('touchstart', handleLeaversTouchStart)
        .on('touchend', handleLeaversTouchEnd)
        .call(e =>
          e
            .transition(t)
            .attr('x', d => x(d.year))
            .attr('width', x.bandwidth())
            .attr('y', plotBaseline)
            .attr('height', d => y(d.leavers.length))
        ),
    update =>
      update.call(u =>
        u
          .on('mouseover', handleLeaversMouseOver)
          .on('mouseout', handleLeaversMouseOut)
          .on('click', handleLeaversClick)
          .on('touchstart', handleLeaversTouchStart)
          .on('touchend', handleLeaversTouchEnd)
          .transition(t)
          .attr('x', d => x(d.year))
          .attr('width', x.bandwidth())
          .attr('y', plotBaseline)
          .attr('height', d => y(d.leavers.length))
      ),
    exit => exit.call(ex => ex.transition(t).attr('height', 0).attr('y', plotBaseline).remove())
  );

  //#endregion

  //#region add labels if enabled for debugging

  if (showCounts) {
    const allLabels = root.selectAll('.all-label').data(dataByYear, d => d.year);

    allLabels.join(
      enter =>
        enter
          .append('text')
          .attr('class', 'all-label')
          .attr('x', d => x(d.year) + x.bandwidth() / 2)
          .attr('y', 0)
          .attr('opacity', 1)
          .attr('fill', '#fff')
          .text(d => `A ${d.all.length - d.leavers.length}`)
          .call(e => e.transition(t).attr('y', d => plotBaseline - yAll(d.all.length - d.leavers.length))),
      update =>
        update.call(u =>
          u
            .transition(t)
            .attr('x', d => x(d.year) + x.bandwidth() / 2)
            .attr('y', d => plotBaseline - yAll(d.all.length - d.leavers.length))
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('y', 0).remove())
    );

    const joinerLabels = root.selectAll('.joiner-label').data(dataByYear, d => d.year);

    joinerLabels.join(
      enter =>
        enter
          .append('text')
          .attr('class', 'joiner-label')
          .attr('x', d => x(d.year) + x.bandwidth() / 2)
          .attr('y', 0)
          .attr('opacity', 1)
          .attr('fill', '#fff')
          .text(d => `J ${d.joiners.length}`)
          .call(e => e.transition(t).attr('y', d => plotBaseline - yAll(d.joiners.length))),
      update =>
        update.call(u =>
          u
            .transition(t)
            .attr('x', d => x(d.year) + x.bandwidth() / 2)
            .attr('y', d => plotBaseline - yAll(d.joiners.length))
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('y', 0).remove())
    );

    const leaverPoints = root.selectAll('.leaver-label').data(dataByYear, d => d.year);

    leaverPoints.join(
      enter =>
        enter
          .append('text')
          .attr('class', 'leaver-label')
          .attr('x', d => x(d.year) + x.bandwidth() / 2)
          .attr('y', 0)
          .attr('opacity', 1)
          .attr('fill', '#fff')
          .text(d => `L ${d.leavers.length}`)
          .call(e => e.transition(t).attr('y', d => plotBaseline + yAll(d.leavers.length))),
      update =>
        update.call(u =>
          u
            .transition(t)
            .attr('x', d => x(d.year) + x.bandwidth() / 2)
            .attr('y', d => plotBaseline + yAll(d.leavers.length))
        ),
      exit => exit.call(ex => ex.transition(t).attr('opacity', 0).attr('y', 0).remove())
    );
  }

  //#endregion

  //#region mouse event handlers

  function handleJoinersMouseOver(d) {
    mouseEventRefs.itemOver.current(this, d, 'joiners');
  }

  function handleJoinersMouseOut(d) {
    mouseEventRefs.itemOut.current(this, d, 'joiners');
  }

  function handleJoinersClick(d) {
    mouseEventRefs.itemClick.current(this, d, 'joiners');
  }

  function handleJoinersTouchStart(d) {
    mouseEventRefs.itemTouchStart.current(this, d, 'joiners');
  }

  function handleJoinersTouchEnd(d) {
    mouseEventRefs.itemTouchEnd.current(this, d, 'joiners');
  }

  function handleLeaversMouseOver(d) {
    mouseEventRefs.itemOver.current(this, d, 'leavers');
  }

  function handleLeaversMouseOut(d) {
    mouseEventRefs.itemOut.current(this, d, 'leavers');
  }

  function handleLeaversClick(d) {
    mouseEventRefs.itemClick.current(this, d, 'leavers');
  }

  function handleLeaversTouchStart(d) {
    mouseEventRefs.itemTouchStart.current(this, d, 'leavers');
  }

  function handleLeaversTouchEnd(d) {
    mouseEventRefs.itemTouchEnd.current(this, d, 'leavers');
  }

  //#endregion
}

function FundManagerJoinersAndLeavers({
  joinersAndLeavers,
  expanded,
  onClick,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  ...rest
}) {
  const id = useRef(uniqueId());
  const [size, setSize] = useState(null);
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });
  const containerRef = useRef(null);
  const visCreated = useRef(false);
  const margin = { top: 20, right: 30, bottom: 40, left: 30 };

  useEffect(() => {
    if (visCreated.current && joinersAndLeavers) {
      draw({
        id: id.current,
        joinersAndLeavers,
        size,
        margin,
        expanded,
        mouseEventRefs,
        onItemOver,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinersAndLeavers, expanded]);

  function onResize(width, height) {
    setSize({ width, height });
    draw({
      id: id.current,
      joinersAndLeavers,
      size: { width, height },
      margin,
      expanded,
      mouseEventRefs,
      onItemOver,
    });
    visCreated.current = true;
  }

  return (
    <Container ref={containerRef} {...rest}>
      {size && (
        <svg
          id={id.current}
          onClick={onClick}
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            cursor: expanded ? 'auto' : 'pointer',
          }}
        />
      )}
      <ResizeDetector handleWidth handleHeight onResize={onResize} targetDomEl={containerRef.current} />
    </Container>
  );
}

FundManagerJoinersAndLeavers.defaultProps = {
  expanded: false,
  onClick: () => {},
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

FundManagerJoinersAndLeavers.propTypes = {
  joinersAndLeavers: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default FundManagerJoinersAndLeavers;
