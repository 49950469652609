import React from 'react';
import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { extent, min, max } from 'd3';
import { lookup } from 'src/lookup';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import {
  useFilteredFunds,
  useAllFunds,
  useLayoutActions,
  useHasSubscription,
  useMarketFundPerformanceFilter,
} from 'src/hooks';
import { AppPanel, SubscriptionPaywall, FundPerformanceTooltip, FilterControls, FundsTable } from 'src/components';
import PlotView from './PlotView';

const xAxisLimits = {
  tvpi: [0, 3],
  irr: [-0.25, 0.5],
};

const Layout = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

function FundPerformanceVisualization({
  showListView,
  showPlotView,
  showFilterControls,
  setShowFilterControls,
  forceTooltipsClose,
}) {
  const hasSubscription = useHasSubscription();
  const unfilteredScatterFunds = useAllFunds({
    excludeLatestThreeYears: true,
    excludeNullTvpi: false,
    excludeNullIrr: false,
  });
  const [showFullScale, setShowFullScale] = useState(false);
  const {
    defaultRegionsFilter,
    defaultSecondRegionsFilter,
    defaultVintagesFilter,
    defaultSizesFilter,
    defaultStrategiesFilter,
    regionsFilter,
    secondRegionsFilter,
    vintagesFilter,
    sizesFilter,
    strategiesFilter,
    setRegionsFilter,
    setSecondRegionsFilter,
    setVintagesFilter,
    setSizesFilter,
    setStrategiesFilter,
  } = useMarketFundPerformanceFilter();

  const selectedStrategy = (strategiesFilter || []).find(s => Boolean(s.selected && s.parentId === null)) || {};

  const [benchmark, setBenchmark] = useState(lookup.financialMetric.tvpi);
  const [chartTooltipOpen, setChartTooltipOpen] = useState(false);
  const [chartTooltipAnchorEl, setChartTooltipAnchorEl] = useState(null);
  const [chartTooltipData, setChartTooltipData] = useState(null);

  const { expandPanel } = useLayoutActions();
  const { isExpanded } = useContext(AppPanel.Context);

  useEffect(() => {
    if (showListView && hasSubscription) expandPanel();
  }, [showListView, expandPanel, hasSubscription]);

  useEffect(() => {
    if (showFilterControls) expandPanel();
  }, [showFilterControls, expandPanel]);

  const scatterplotFunds = useFilteredFunds({
    funds: useAllFunds({
      excludeLatestThreeYears: false,
      excludeNullTvpi: benchmark.key === 'tvpi',
      excludeNullIrr: benchmark.key === 'irr',
      excludeNullSize: true,
    }),
    strategies: strategiesFilter,
    vintages: vintagesFilter,
    regions: regionsFilter,
    secondRegions: secondRegionsFilter,
    sizes: sizesFilter,
  });

  const enableShowFullScale = useMemo(() => {
    return scatterplotFunds.some(f => {
      return f[benchmark.key] < xAxisLimits[benchmark.key][0] || f[benchmark.key] > xAxisLimits[benchmark.key][1];
    });
  }, [benchmark.key, scatterplotFunds]);

  const extents = useMemo(() => {
    const defaultExtents = {
      x: [0, 0],
      y: [0, 0],
    };

    if (!strategiesFilter) return defaultExtents;
    if (!unfilteredScatterFunds) return defaultExtents;
    if (!scatterplotFunds) return defaultExtents;
    if (!scatterplotFunds.length > 2) return defaultExtents;

    const isFullScale = showFullScale || !enableShowFullScale;

    const xAbsMin = Math.min(
      min(scatterplotFunds, d => d[benchmark.key]),
      xAxisLimits[benchmark.key][0]
    );

    const xAbsMax = Math.max(
      max(scatterplotFunds, d => d[benchmark.key]),
      xAxisLimits[benchmark.key][1]
    );

    const x = isFullScale ? [xAbsMin, xAbsMax] : xAxisLimits[benchmark.key];
    const y = extent(scatterplotFunds, d => d.size);

    return {
      x: x.map(d => (Number.isFinite(d) ? d : 0)),
      y: y.map(d => (Number.isFinite(d) ? d : 0)),
    };
  }, [benchmark.key, enableShowFullScale, scatterplotFunds, showFullScale, strategiesFilter, unfilteredScatterFunds]);

  function closeTooltips() {
    setChartTooltipOpen(false);
    setChartTooltipAnchorEl(null);
  }

  useEffect(() => {
    closeTooltips();
  }, [forceTooltipsClose]);

  useEffect(() => {
    closeTooltips();
  }, [isExpanded]);

  const showChartTooltip = useCallback((node, d) => {
    closeTooltips();
    const boundingClientRect = node.getBoundingClientRect();
    setChartTooltipData(d);
    setChartTooltipAnchorEl({
      clientWidth: boundingClientRect.width,
      clientHeight: boundingClientRect.height,
      getBoundingClientRect: () => boundingClientRect,
    });
    setChartTooltipOpen(true);
  }, []);

  function handleClickAway(e) {
    if (!e.target.classList.contains('point')) {
      closeTooltips();
    }
  }

  function handleViewFund(fund) {
    navigate(`/funds/${fund.fundId}`);
  }

  function handleFullScaleChange(evt) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterFundPerformanceFullScale);
    setShowFullScale(evt.target.checked);
  }

  function handleMetricChange(metric) {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.marketFilterFundPerformanceMetric);
    setBenchmark(metric);
  }

  if (!scatterplotFunds) return null;
  if (!strategiesFilter) return null;
  if (!vintagesFilter) return null;
  if (!regionsFilter) return null;
  if (!secondRegionsFilter) return null;

  return (
    <>
      <Layout>
        <FilterControls
          open={showFilterControls}
          setOpen={setShowFilterControls}
          defaultRegionsFilter={defaultRegionsFilter}
          regionsFilter={regionsFilter}
          setRegionsFilter={setRegionsFilter}
          defaultSecondRegionsFilter={defaultSecondRegionsFilter}
          secondRegionsFilter={secondRegionsFilter}
          setSecondRegionsFilter={setSecondRegionsFilter}
          defaultSizesFilter={defaultSizesFilter}
          sizesFilter={sizesFilter}
          setSizesFilter={setSizesFilter}
          defaultStrategiesFilter={defaultStrategiesFilter}
          strategiesFilter={strategiesFilter}
          setStrategiesFilter={setStrategiesFilter}
          defaultVintagesFilter={defaultVintagesFilter}
          vintagesFilter={vintagesFilter}
          setVintagesFilter={setVintagesFilter}
        />

        {showPlotView && (
          <PlotView
            enableShowFullScale={enableShowFullScale}
            showFullScale={!enableShowFullScale || showFullScale}
            handleFullScaleChange={handleFullScaleChange}
            selectedStrategyName={selectedStrategy.strategyName}
            benchmark={benchmark}
            handleMetricChange={handleMetricChange}
            scatterplotFunds={scatterplotFunds}
            extents={extents}
            handleItemClick={showChartTooltip}
          />
        )}

        {showListView && (
          <SubscriptionPaywall>
            <FundsTable
              vintages={vintagesFilter}
              strategies={strategiesFilter}
              sizes={sizesFilter}
              regions={regionsFilter}
              secondRegions={secondRegionsFilter}
            />
          </SubscriptionPaywall>
        )}
      </Layout>
      <FundPerformanceTooltip
        onClickAway={handleClickAway}
        fund={chartTooltipData}
        open={chartTooltipOpen}
        anchorEl={chartTooltipAnchorEl}
        onClose={closeTooltips}
        onViewFund={handleViewFund}
      />
    </>
  );
}

FundPerformanceVisualization.defaultProps = {};

FundPerformanceVisualization.propTypes = {
  showListView: PropTypes.bool,
  showPlotView: PropTypes.bool,
  filtersOpen: PropTypes.bool,
  setFiltersOpen: PropTypes.func,
  forceTooltipsClose: PropTypes.object,
};

export default FundPerformanceVisualization;
