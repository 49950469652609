import React from 'react';
import styled from 'styled-components';
import ButtonRounded from '../ButtonRounded';
import IconStrategy from '../IconStrategy';

const StyledButtonRounded = styled(ButtonRounded)`
  .MuiButton-label {
    position: relative;
    top: -1px;
    left: -2px;
  }
  .MuiSvgIcon-root {
    position: relative;
    top: 2px;
    left: 2px;
  }
`;

const ButtonStrategy = props => {
  return (
    <StyledButtonRounded startIcon={<IconStrategy />} {...props}>
      Strategy
    </StyledButtonRounded>
  );
};

ButtonStrategy.defaultProps = {};

ButtonStrategy.propTypes = {};

export default ButtonStrategy;
