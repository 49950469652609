import React from 'react';
import { Popper, Grow } from '@material-ui/core';
import {
  TooltipContent,
  TooltipHeader,
  TooltipRow,
  TooltipTitle,
  TooltipData,
  TooltipLabel,
  TooltipArrowLeft,
} from 'src/components';
import { useEmployeePosition, useEmployeeSchoolNames, useEmployeePriorFirm } from './hooks';

function FundManagerTeamTableTooltip({ data, open, anchorEl }) {
  const employeePosition = useEmployeePosition(data);
  const employeePriorExps = useEmployeePriorFirm(data);
  const employeeSchoolNames = useEmployeeSchoolNames(data);

  return (
    <Popper
      style={{ zIndex: 100, maxWidth: 300 }}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="right"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0,15',
        },
        arrow: {
          enabled: true,
          element: '.fundmanager-employee-arrow',
        },
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          <div>
            <TooltipArrowLeft className="fundmanager-employee-arrow" />
            <TooltipContent>
              <TooltipHeader>
                <TooltipTitle>{data?.name}</TooltipTitle>
              </TooltipHeader>
              <TooltipRow>
                <TooltipLabel>POSITION</TooltipLabel>
                <TooltipData>{employeePosition}</TooltipData>
              </TooltipRow>
              {employeePriorExps.length > 0 && (
                <TooltipRow>
                  <TooltipLabel>EXPERIENCE</TooltipLabel>
                  <TooltipData>
                    {employeePriorExps.map((exp, i) => (
                      <div key={i}>{exp.fundManagerName}</div>
                    ))}
                  </TooltipData>
                </TooltipRow>
              )}
              {employeeSchoolNames.length > 0 && (
                <TooltipRow>
                  <TooltipLabel>SCHOOLS</TooltipLabel>
                  <TooltipData>
                    {employeeSchoolNames.map((name, i) => (
                      <div key={i}>{name}</div>
                    ))}
                  </TooltipData>
                </TooltipRow>
              )}
            </TooltipContent>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

export default FundManagerTeamTableTooltip;
