import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getStrategyTimeseries } from 'src/services/filter-api';

const QUERY_OPTS = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  staleTime: 1000 * 60 * 60,
};

function useStrategyTimeseriesQuery({
  strategyId,
  type = 'mean',
  quartile,
  truncate = [0, 12 * 4],
  highlight = true,
} = {}) {
  const id = [strategyId, type, quartile].filter(Boolean).join('_');

  const query = useQuery(
    ['strategyTimeseries', strategyId, type, quartile],
    () => getStrategyTimeseries(id),
    QUERY_OPTS
  );

  return useMemo(() => {
    if (!query.data) return query;
    if (truncate === false) return query;

    const series = query.data.series
      .filter(d => {
        if (!truncate) return true;
        return d.q >= truncate[0] && d.q < truncate[1];
      })
      .map(d => {
        if (highlight === false) return d;
        if (highlight === true) return { ...d, highlight: true };
        return d.q >= highlight[0] && d.q <= highlight[1];
      });

    return {
      ...query,
      data: {
        ...query.data,
        series,
      },
    };
  }, [highlight, query, truncate]);
}

export default useStrategyTimeseriesQuery;
