import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';

function TopQuartileVisibliltyToggle({ checked, onCheckChange }) {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={() => onCheckChange(!checked)} color="default" />}
      label="Top Quartile"
    />
  );
}

TopQuartileVisibliltyToggle.defaultProps = {
  onCheckChange: () => {},
};

TopQuartileVisibliltyToggle.propTypes = {
  checked: PropTypes.bool,
  onCheckChange: PropTypes.func,
};

export { TopQuartileVisibliltyToggle };
