import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    labels: {
      searchPlaceholder: 'search funds and managers',
      oneDay: '1D',
      oneMonth: '1M',
      oneQuarter: '1Q',
      threeMonths: '3M',
      sixMonths: '6M',
      threeQuarters: '3Q',
      oneYear: '1Y',
      threeYears: '3Y',
      fiveYears: '5Y',
      irr: 'irr',
      tvpi: 'tvpi',
      tvpix: 'TVPI (x)',
      netIrrPct: 'Net IRR (%)',
      pme: 'Over / Under Performance Multiple',
      median: 'median',
      mean: 'mean',
      backToAllStrategies: 'back to all strategies',
      viewAll: 'view all',
      fund: 'fund',
      funds: 'funds',
      fundSize: 'fund size',
    },
  },
});
