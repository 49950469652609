import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MenuItem, Menu, List, ListItem, ListItemText, Radio } from '@material-ui/core';
import { IconTag } from 'src/components';

const compactCss = css`
  .MuiListItem-root {
    padding: 1px 0px 1px 8px;
    border-radius: 3px;
  }
  .MuiListItemText-root {
    margin: 0;
  }
  .MuiInputBase-root {
    height: 28px;
  }
`;

const StyledList = styled(List)`
  padding: 0;

  .MuiListItem-root {
    border: 1px solid rgba(255, 255, 255, 0.23);
    font-weight: 300;
  }

  .MuiListItem-dense {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .MuiListItemText-primary,
  .MuiListItemText-secondary {
    font-size: 1em;
    font-weight: 300;
  }

  .MuiListItemText-secondary {
    opacity: 0.5;
  }

  ${p => p.$compact && compactCss};
`;

const StyledMenu = styled(Menu).attrs({
  keepMounted: true,
  variant: 'menu',
})`
  .MuiPaper-root {
    font-size: 0.8125rem;
    background-color: #313131;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: #a4a4a4;
  font-weight: 300;
  padding: 0.307692308em 20px;
  font-size: inherit;

  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: transparent;
    color: white;
  }
`;

const MenuSectionHeader = styled(MenuItem).attrs({
  disabled: true,
})`
  /* margin-top: 1.5em; */
  text-transform: capitalize;
  color: white;
  font-size: 1em;

  &.MuiListItem-root.Mui-disabled {
    opacity: 1;
  }
`;

const CheckElement = styled.span`
  padding: 0;
  margin-right: 9px;
`;

function CategorizedMultiselect({
  className,
  icon,
  label,
  header,
  value,
  renderLabel,
  displayValue,
  items,
  onChange,
  variant,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = newValue => {
    onChange(newValue);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledList className={className} component="nav" dense $compact={variant === 'compact'}>
        <ListItem button onClick={handleClickListItem}>
          <ListItemText
            css={`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.MuiListItemText-root {
                display: flex;
              }

              .MuiListItemText-secondary {
                margin-left: 0.25em;
                max-width: 7em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            `}
            primary={displayValue ? `${label}: ` : label}
            secondary={displayValue}
          />
          <ArrowDropDownIcon />
        </ListItem>
      </StyledList>
      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {header && <MenuSectionHeader>{header}</MenuSectionHeader>}

        {items.map(item => {
          const isSelected = value === item;

          return (
            <StyledMenuItem key={item} selected={isSelected} onClick={() => handleMenuItemClick(item)}>
              <CheckElement
                as={
                  {
                    radio: Radio,
                    tag: IconTag,
                  }[icon]
                }
                color={isSelected ? 'primary' : 'default'}
                variation={!isSelected ? 'outline' : ''}
                checked={isSelected}
              />{' '}
              {renderLabel ? renderLabel(item) : item}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
}

CategorizedMultiselect.defaultProps = {
  icon: 'radio',
};

CategorizedMultiselect.propTypes = {
  children: PropTypes.any,
  categories: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.oneOf(['radio', 'tag']),
};

export default CategorizedMultiselect;
