import PropTypes from 'prop-types';
import { isFunction } from 'src/lib/lodash';

function valueOf(funcOrString, item) {
  return isFunction(funcOrString) ? funcOrString(item) : funcOrString;
}

const valueOfPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string]);

const refPropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);

const stringSort = (property, isDescending) => (aValue, bValue) => {
  const a = isDescending ? aValue[property] : bValue[property];
  const b = isDescending ? bValue[property] : aValue[property];
  if (b > a) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const numericSort = (property, isDescending) => (aValue, bValue) => {
  let a = parseFloat(aValue[property]);
  let b = parseFloat(bValue[property]);
  if (isNaN(a)) {
    a = 0;
  }
  if (isNaN(b)) {
    b = 0;
  }
  return isDescending ? b - a : a - b;
};

export { valueOf, valueOfPropType, refPropType, stringSort, numericSort };
