import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFundManagerId } from 'src/store/last-selected';
import { AppLayout } from 'src/components';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import SummaryPanel from './SummaryPanel';
import FundRaisingPanel from './FundRaisingPanel';
import TeamPanel from './TeamPanel';
import NewsPanel from './NewsPanel';

function FundManagersPage({ fundManagerId }) {
  const dispatch = useDispatch();
  const lastSelectedFundManagerId = useSelector(state => state.lastSelected.fundManagerId);

  const currentFundManagerId = fundManagerId || lastSelectedFundManagerId;

  useEffect(() => {
    if (currentFundManagerId.startsWith('u_')) return;
    dispatch(setFundManagerId({ fundManagerId: currentFundManagerId }));
  }, [dispatch, currentFundManagerId]);

  useEffect(() => {
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.fundManager);
  }, []);

  return (
    <AppLayout>
      <SummaryPanel spanX="half" spanY="full" minHeight={875} fundManagerId={currentFundManagerId} />
      <NewsPanel spanX="half" spanY="third" minHeight={240} fundManagerId={currentFundManagerId} />
      <FundRaisingPanel spanX="half" spanY="third" minHeight={240} fundManagerId={currentFundManagerId} />
      <TeamPanel spanX="half" spanY="third" minHeight={240} fundManagerId={currentFundManagerId} />
    </AppLayout>
  );
}

export default FundManagersPage;
