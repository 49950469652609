import { groupBy } from 'src/lib/lodash';
import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFundsFinancialHistory } from 'src/store/fund-financial-history';
import { useFundPeers } from 'src/hooks';

function sum(a, b) {
  return parseFloat(a) + parseFloat(b);
}

function getMeanValue(group, key) {
  if (!group) return;

  const mean =
    group
      .map(x => Number.parseFloat(x[key]))
      .filter(Number.isFinite)
      .reduce(sum, 0) / group.length;

  return mean;
}

function getBenchmarkHistory(fundsHistories) {
  const fundHistoryByDate = groupBy(fundsHistories.map(x => x.items).flat(), 'date');

  return Object.keys(fundHistoryByDate)
    .sort((a, b) => (a > b ? -1 : 1))
    .map(date => {
      const items = fundHistoryByDate[date].filter(Boolean);
      if (!items) return null;

      return {
        date,
        calledAmount: getMeanValue(items, 'calledAmount'),
        calledPct: getMeanValue(items, 'calledPct'),
        commitmentAmount: getMeanValue(items, 'commitmentAmount'),
        distributedAmount: getMeanValue(items, 'distributedAmount'),
        dpi: getMeanValue(items, 'dpi'),
        irr: getMeanValue(items, 'irr'),
        remainingValue: getMeanValue(items, 'remainingValue'),
        rvpi: getMeanValue(items, 'rvpi'),
        tvpi: getMeanValue(items, 'tvpi'),
        tvpiChange: getMeanValue(items, 'tvpiChange'),
      };
    });
}

/**
 * A benchmark is like a fund, but with average values
 * for all metrics. The financial history of a benchmark
 * fund is filtered so that it only includes the data
 * points with dates that match that of the subject fund's
 * financial history.
 */
function useFundBenchmarkHistory({ fundId }) {
  const dispatch = useDispatch();
  const peers = useFundPeers({ fundId });
  const fundPeerIds = useMemo(() => {
    if (!peers) return [];
    return peers.map(p => p.fundId);
  }, [peers]);
  const allFundFinancialHistories = useSelector(state => state.fund.financialHistory.byId) || {};

  const histories = fundPeerIds
    .filter(peerFundId => peerFundId !== fundId)
    .map(id => allFundFinancialHistories[id])
    .map(item => item || { fetching: true })
    .filter(x => !x.error);

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    if (fundPeerIds.length < 1) return;

    setFetching(histories.some(item => item && item.fetching));
  }, [fundPeerIds, histories]);

  useEffect(() => {
    if (fundPeerIds.length < 1) return;

    dispatch(
      fetchFundsFinancialHistory({
        fundIds: fundPeerIds,
      })
    );
  }, [dispatch, fundPeerIds]);

  return { items: getBenchmarkHistory(histories), fetching };
}

export default useFundBenchmarkHistory;
