import React from 'react';
import { css } from 'styled-components';
import { navigate, Match } from '@reach/router';
import { IconFunnel, IconCoinStack, IconIdCard, IconFire, IconBookmark } from '../..';

const iconCss = css`
  margin-bottom: 4px;
`;

const ListItemCss = css`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => (props.selected ? '#fff' : '#999')};
  &:hover {
    background-color: #4f4f4f;
  }
`;

const labelCss = css`
  font-size: 11px;
`;

const NavItem = ({ path, exact, children, ...rest }) => (
  <Match path={exact ? path : `${path}/*`}>
    {({ match }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div button onClick={() => navigate(path)} selected={!!match} {...rest} css={ListItemCss}>
        {children}
      </div>
    )}
  </Match>
);

export default function BottomNav(props) {
  return (
    <div
      {...props}
      css={`
        display: flex;
        height: 60px;
        background-color: #3f3f3f;
        justify-content: center;
        border-top: 1px solid #666;
      `}
    >
      <NavItem path="/" exact>
        <IconFunnel css={iconCss} />
        <span css={labelCss}>Markets</span>
      </NavItem>
      <NavItem path="/funds">
        <IconCoinStack css={iconCss} />
        <span css={labelCss}>Funds</span>
      </NavItem>
      <NavItem path="/managers">
        <IconIdCard css={iconCss} />
        <span css={labelCss}>Managers</span>
      </NavItem>
      <NavItem path="/news">
        <IconFire css={iconCss} />
        <span css={labelCss}>News</span>
      </NavItem>
      <NavItem path="/saved">
        <IconBookmark css={iconCss} />
        <span css={labelCss}>Saved</span>
      </NavItem>
    </div>
  );
}
