import { useEffect } from 'react';
import { useGet as useGetUser, useUpdate as useUpdateUser } from 'src/services/filter-api/user';
import { useGet as useGetAuth } from 'src/services/filter-api/authentication';

function useCurrentUser() {
  const auth = useGetAuth();
  const { data: user, ...query } = useGetUser(auth.uid);
  const updateUser = useUpdateUser().mutate;

  /**
   * This is necessary because the when the user changes
   * their email address, they are sent a 'revoke' email,
   * which reverts their email address back to the old one.
   * This process, however, is invisible to our application,
   * so we need some way of keeping the auth record and the
   * user record in sync. Oddly, Firebase Authentication does
   * not provide an `update` function, only a `delete`
   * and `create` function.
   */
  useEffect(() => {
    if (!user) return;
    if (!auth.isAuthenticated) return;
    if (!auth.isVerified) return;
    if (user.email === auth.email) return;

    updateUser({ userId: user.userId, email: auth.email });
  }, [auth, user, updateUser]);

  // Stripe customer is created in a background function,
  // so we'll need a way to reload the user if it hasn't
  // been created yet.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!query.isSuccess) return;
      if (user.stripeCustomerId) return;
      query.refetch();
    }, 2000);

    return () => clearTimeout(timer);
  }, [query, user]);

  return user;
}

export default useCurrentUser;
