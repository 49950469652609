import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Popper, Grow } from '@material-ui/core';
import { TooltipArrowLeft, TooltipContent, TooltipCloseIcon, TooltipHeader } from 'src/components';
import BookmarkSharpIcon from '@material-ui/icons/BookmarkSharp';

const paragraph = css`
  color: #888;
  line-height: 1.2;
  margin: 0;
`;

const BookmarkIcon = styled(BookmarkSharpIcon)`
  width: 15px;
  height: 15px;
`;

function CreatePortfolioTooltip({ open, anchorEl, onClose }) {
  return (
    <Popper
      style={{
        zIndex: 100,
        maxWidth: 600,
      }}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="right"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0,15',
        },
        arrow: {
          enabled: true,
          element: '.create-portfolio-info-arrow',
        },
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          <div
            css={`
              overflow: auto;
            `}
          >
            <TooltipArrowLeft className="create-portfolio-info-arrow" />
            <TooltipContent padding="20px" color="#000" maxWidth="100px" fontSize="12px">
              <TooltipHeader>
                <h3
                  css={`
                    margin: 5px 0;
                    padding: 0;
                  `}
                >
                  Creating a Portfolio
                </h3>
                <TooltipCloseIcon margin="0 0 0 8px" onClick={onClose} />
              </TooltipHeader>
              <div
                css={`
                  width: 200px;
                `}
              >
                <p css={paragraph}>
                  To create a portfolio, look for a bookmark icon <BookmarkIcon /> and click. Look for the ‘Search or
                  Create Portfolio’ box on the bottom of pop up.
                </p>
              </div>
            </TooltipContent>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

CreatePortfolioTooltip.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};

CreatePortfolioTooltip.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default CreatePortfolioTooltip;
