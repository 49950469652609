import { useMemo } from 'react';

function getChildren({ parent, collection, fk, pk, type, labelKey, selectedId, selectedType, selectedParentId }) {
  return collection.reduce((acc, item) => {
    const parentId = item[fk];
    const id = item[pk];
    const label = item[labelKey];
    const isSelected = selectedType === type && selectedId === id;

    if (parentId !== parent.fundManagerId) return acc;
    if (parentId !== selectedParentId) return acc;

    return [...acc, { type, label, id, parentId, isSelected }];
  }, []);
}

function useDashboardHierarchy({ funds, employees, fundManagers, selectedType, selectedId, selectedParentId }) {
  const rootManagers = useMemo(() => {
    if (!fundManagers) return [];
    return fundManagers.map(manager => {
      const managerEmployees = getChildren({
        type: 'employee',
        fk: 'currentFundManagerId',
        pk: 'employeeId',
        labelKey: 'name',
        parent: manager,
        collection: employees,
        selectedId,
        selectedType,
        selectedParentId,
      });

      const managerFunds = getChildren({
        type: 'fund',
        fk: 'fundManagerId',
        pk: 'fundId',
        labelKey: 'name',
        parent: manager,
        collection: funds,
        selectedId,
        selectedType,
        selectedParentId,
      });

      const managerChildren = [...managerEmployees, ...managerFunds].reverse();

      return {
        type: 'manager',
        label: manager.name,
        id: manager.fundManagerId,
        parentId: manager.fundManagerId,
        count: managerChildren.length,
        children: managerChildren,
        isSelected: manager.fundManagerId === selectedParentId,
      };
    });
  }, [employees, fundManagers, funds, selectedId, selectedParentId, selectedType]);

  return useMemo(() => {
    if (!employees) return;
    if (!fundManagers) return;
    if (!funds) return;

    return {
      label: 'Saved Items',
      id: 1,
      type: 'root',
      isSelected: false,
      children: rootManagers,
    };
  }, [employees, fundManagers, funds, rootManagers]);
}

export default useDashboardHierarchy;
