import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, ErrorMessage } from 'src/components';
import { message } from 'src/config/colors';
import { navigate } from '@reach/router';
import routes from 'src/config/routes';
import AuthLayout from 'src/components/templates/AuthLayout';
import * as auth from 'src/services/filter-api/authentication';

const Layout = styled.section`
  padding: 2em;
  width: calc(100vw - 2em);
  max-width: 360px;
`;

const Footer = styled.footer`
  min-height: 2.5em;
  font-size: 14px;
  margin-top: 7px;
`;

const SuccessMessage = styled(ErrorMessage)`
  color: ${message.success.TEXT_COLOR};
`;

const RetryMessage = styled(ErrorMessage)`
  button {
    color: white;
    text-decoration: underline;
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

function UserEmailVerificationaPage() {
  const [success, setSuccess] = useState(false);
  const { isVerified, isVerificationSent, error } = auth.useGet({
    poll: true,
  });
  const sendVerification = auth.useSendEmailVerification().mutate;

  useEffect(() => {
    if (!isVerificationSent) sendVerification();
  }, [sendVerification, isVerificationSent]);

  useEffect(() => {
    if (isVerified) navigate(routes.MARKET_FILTER);
  }, [isVerified]);

  const handleSubmit = useCallback(
    function () {
      sendVerification();
      setSuccess({
        message: (
          <>
            A new verification email has been sent. <i>Note: this email supersedes all other verification emails</i>
          </>
        ),
      });
    },
    [sendVerification]
  );

  return (
    <AuthLayout>
      <AuthLayout.Card>
        <Layout>
          <h3>Verify your Email</h3>
          <p>
            A verification email has been sent to you, open that email and click the link to verify your email address.
          </p>
          <p>
            Once your email has been verified this screen will automatically close and you can start using the
            application.
          </p>
          <p>
            If you did not receive and email please check your SPAM folder or you can generate a new email by clicking
            the button below.
          </p>
          <Button variant="contained" color="primary" $block onClick={handleSubmit}>
            Resend Verification Email
          </Button>
          <Footer>
            {success && <SuccessMessage>{success.message}</SuccessMessage>}

            {error && (
              <div>
                <RetryMessage>
                  Something went wrong. If you did not receive a verification email you can retry using the button
                  above, or{' '}
                  <button className="action" onClick={() => navigate(routes.USER_SIGN_IN)}>
                    try signing in again
                  </button>
                </RetryMessage>
              </div>
            )}
          </Footer>
        </Layout>
      </AuthLayout.Card>
    </AuthLayout>
  );
}

export default UserEmailVerificationaPage;
