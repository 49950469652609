import React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { uniqueId } from 'src/utils';
import { useMouseEventRefs } from 'src/hooks';
import draw from './draw';

const Layout = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.714285714em;

  svg .label {
    font-size: 10px;
  }
`;

const ChartSection = styled.div`
  flex: 1;
  flex-basis: 100%;
  position: relative;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;

function FundPMEIndexChart({
  className,
  fund,
  calcs,
  peers,
  targetRef,
  onItemOver,
  onItemOut,
  onItemClick,
  onItemTouchStart,
  onItemTouchEnd,
  width,
  height,
}) {
  const { current: id } = useRef(uniqueId());
  const mouseEventRefs = useMouseEventRefs({
    onItemOver,
    onItemOut,
    onItemClick,
    onItemTouchStart,
    onItemTouchEnd,
  });

  useEffect(
    function () {
      draw({
        id,
        fund,
        calcs,
        peers,
        size: { width, height },
        margin: { top: 0, right: 20, bottom: 0, left: 40 },
        mouseEventRefs,
      });
    },
    [id, fund, calcs, peers, width, height, mouseEventRefs]
  );

  return (
    <Layout className={className}>
      <ChartSection ref={targetRef}>
        <Svg id={id} width={width} height={height} />
      </ChartSection>
    </Layout>
  );
}

FundPMEIndexChart.defaultProps = {
  width: 0,
  height: 0,
  onItemOver: () => {},
  onItemOut: () => {},
  onItemClick: () => {},
  onItemTouchStart: () => {},
  onItemTouchEnd: () => {},
};

FundPMEIndexChart.propTypes = {
  fund: PropTypes.object.isRequired,
  peers: PropTypes.object.isRequired,
  calcs: PropTypes.object.isRequired,
  onItemOver: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemTouchStart: PropTypes.func,
  onItemTouchEnd: PropTypes.func,
};

export default withResizeDetector(FundPMEIndexChart);
