import * as d3 from 'd3';
import { unique } from 'src/utils/array';

function getXAxis({ data, formatter, range, ticks }) {
  const domain = unique(
    data
      .map(d => {
        if (!d.date) return null;
        return d.date;
      })
      .filter(Boolean)
  );

  const width = Math.max(range[1] - range[0], 0);
  const maxWidth = domain.length * 14;
  const labelsEveryNYears = ticks ?? Math.max(Math.round((maxWidth / width) ** 2), 1);

  const xScale = d3.scaleUtc().domain(d3.extent(domain)).range(range);

  const tickFormat = (date, idx) => {
    if (typeof formatter === 'function') {
      return formatter(date, idx * labelsEveryNYears);
    }
    return date.getFullYear() + 1;
  };

  const xAxisLabels = d3
    .axisBottom(xScale)
    .ticks(d3.utcYear.every(labelsEveryNYears))
    .tickFormat(tickFormat)
    .tickSize(0)
    .tickPadding(8);

  const xAxisLargeTicks = d3
    .axisBottom(xScale)
    .ticks(d3.utcYear.every(1))
    .tickFormat(() => '')
    .tickSize(9)
    .tickPadding(8);

  const xAxisSmallTicks = d3
    .axisBottom(xScale)
    .ticks(d3.utcMonth.every(3))
    .tickFormat(() => '')
    .tickSize(3)
    .tickPadding(8);

  return {
    xAxisSmallTicks,
    xAxisLargeTicks,
    xAxisLabels,
    xScale,
  };
}

export default getXAxis;
