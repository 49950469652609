const USAGE_TRACKING_TOPICS = {
  searchFund: 'search.fund',
  searchFundManager: 'search.fund-manager',
  marketFilter: 'market-filter',
  marketFilterFundPerformanceFilter: 'market-filter.fund-performance.filter',
  marketFilterFundPerformancePlot: 'market-filter.fund-performance.plot',
  marketFilterFundPerformanceList: 'market-filter.fund-performance.list',
  marketFilterFundPerformanceMetric: 'market-filter.fund-performance.metric',
  marketFilterFundPerformanceFullScale: 'market-filter.fund-performance.full-scale',
  marketFilterStrategyBreakdownDrilledDown: 'market-filter.strategy-breakdown.drilled-down',
  marketFilterStrategyBreakdownList: 'market-filter.strategy-breakdown.list',
  marketFilterStrategyPerformanceList: 'market-filter.strategy-performance.list',
  marketFilterStrategyPerformanceMetric: 'market-filter.strategy-performance.metric',
  marketFilterStrategyPerformanceTime: 'market-filter.strategy-performance.time',
  marketFilterFundRaisingList: 'market-filter.fund-raising.list',
  marketFilterFundRaisingDataSet: 'market-filter.fund-raising.data-set',
  fund: 'fund',
  fundTimeRange: 'fund.time-range',
  fundMetric: 'fund.metric',
  fundPeerCompare: 'fund.peer.compare',
  fundPeerMetric: 'fund.peer.metric',
  fundPeerDownload: 'fund.peer.download',
  fundPeerVintage: 'fund.peer.vintage',
  fundPeerPlot: 'fund.peer.plot',
  fundPeerRadar: 'fund.peer.radar',
  fundPeerBar: 'fund.peer.bar',
  fundForecastAcceptTos: 'fund.forecast.accept.tos',
  fundFundManagerSelect: 'fund.fund-manager.select',
  fundNews: 'fund.news',
  fundManager: 'fund-manager',
  fundManagerDownload: 'fund-manager.download',
  fundManagerNews: 'fund-manager.news',
  fundManagerMetric: 'fund-manager.metric',
  fundManagerStrategy: 'fund-manager.strategy',
  fundManagerVintage: 'fund-manager.vintage',
  fundManagerFundSelect: 'fund-manager.fund.select',
  fundManagerFundAdd: 'fund-manager.fund.add',
  fundManagerTeamDataSet: 'fund-manager.team.data-set',
  fundManagerTeamWheel: 'fund-manager.team.wheel',
  cashflowForecastAcceptTos: 'cashflow.forecast.accept.tos',
  news: 'news',
  newsSummaryLength: 'news-summary-length',
  newsPrint: 'news-print',
  newsCopy: 'news-copy',
  saved: 'saved',
  savedNews: 'saved.fund.news',
  savedFundAdd: 'saved.fund.add',
  savedFundMetric: 'saved.fund.metric',
  savedFundPlot: 'saved.fund.plot',
  savedFundList: 'saved.fund.list',
  savedFundTimeRange: 'saved.fund.time-range',
  savedFollowingWheel: 'saved.following.wheel',
  savedFollowingDataSet: 'saved.following.data-set',
  userSubscribed: 'user.subscribed',
  appAcceptGeneralTos: 'app.accept.tos',
  userLogin: 'user.login',
  userCreated: 'user.created',
};

module.exports = USAGE_TRACKING_TOPICS;
