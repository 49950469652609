import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundManagerNames } from '../store/fund-manager-names';

export default function useFundManagerNames() {
  const dispatch = useDispatch();
  const fundManagerNames = useSelector(state => state.fundManager.names);

  useEffect(() => {
    if (!fundManagerNames.fetched && !fundManagerNames.fetching) {
      dispatch(fetchFundManagerNames());
    }
  }, [dispatch, fundManagerNames.fetched, fundManagerNames.fetching]);

  return fundManagerNames;
}
